
export const STOP_MORGAN_NOISE = {
  "action": "NOISE_PLAYBACK",
  "type": "mdx_plugin_aqt_automotive",
  "payload": {
    "operation": "NOISE_PLAYBACK",
    "action": "STOP"
  }
}

export const DEFAULT_PLUGINS = [
    "mdx_plugin_aqt_tools",
    "mdx_plugin_avs_audio",
    "mdx_plugin_aqt_audio_recorder",
    "mdx_plugin_aqt_audio_stitcher"
]
