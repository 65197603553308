import { createSlice } from '@reduxjs/toolkit'
import { API_STATUS } from '../../../constants';

export const testListUISlice = createSlice({
  name: 'testListOperationSlice',
  initialState: {
    testExecuteStatus: API_STATUS.INIT,
    initiateAddNewTest: false,
    initiateDeleteTest: false,
    initiateExecuteTest: false,
    error: null,
  },
  reducers: {
    setStatus: (state, action) => {
      const { status, error } = action.payload;
      state.testExecuteStatus = status;
      state.error = error;
    },
    setInitiateAddNewTest: (state, action) => {
      const executionStatus = action.payload;
      state.initiateAddNewTest = executionStatus;
    },
    setInitiateTestExecution: (state, action) => {
      const executionStatus = action.payload;
      state.initiateExecuteTest = executionStatus;
    },
    setInitiateDeleteTest: (state, action) => {
      const executionStatus = action.payload;
      state.initiateDeleteTest = executionStatus;
    }
  },
})

export const { setStatus, setInitiateDeleteTest, setInitiateTestExecution, setInitiateAddNewTest } = testListUISlice.actions;
export const testListUISelector = (state) => state.testListUi;


export default testListUISlice.reducer
