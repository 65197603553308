import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { getDUTs } from './controller';
import ld from 'lodash';
import {
  getErrorTextForDropdown, shouldDisableDropdown,
  convertDUTListFormat, getErrorText, isValidDeviceConfig
} from './Util';

import {
  getCommonSelect, getDUTsLoadingMessage,
  getEmailNotificationField, getInputField
} from './uiUtils';
import { onChangeInput, onInput, setCheckedStateInTestParams } from './uiActionsUtils';

import { isA4PCDevice, logToConsole, isProdEnvironment } from '../../Util';
import AppConstants from '../../_Constants/AppConstants';
import { generateDUTListWithOnlineOfflineStatus } from './TestGroupInfoHelpers';
import { sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';

const REFRESH_DSN_LABEL = 'Refresh';
const ADD_DSN_LABEL = 'Add';

class TestGroupInfo extends React.Component {
  state = {
    //TODO: deal with dutsInUse for FAR Custom later
    //dutsInUse: new Array(AppConstants.MAX_OPTIONAL_DUTS + 1),
    addDUTManually: false,
    dutListLoading: false, // indicator to see whether currently dut is loading
    dutListLoaded: false, // indicator to see whether duts are loaded
    showAmazonId: false, // indicator to show whether amazon Id is being shown
    lockFields: false // Indicator to disable the input fields based on the condition from NewRun/index.js
  }

  /**
   * Gets Customer ID field to render
   * Many operations during test suite select event, can not use getSelectFieldWithDefaultOption
   */
  getCustomerIdField = () => {
    const { selectedTestGroupInfo, whitelistedCustomerIds } = this.props.params;
    let currentCustomerId = selectedTestGroupInfo ? selectedTestGroupInfo.customerId : AppConstants.EMPTY;
    return (
      this.getSelectField('customerId', 'Customer ID', 'Customer ID of the DUT registered account',
        whitelistedCustomerIds,
        currentCustomerId ? selectedTestGroupInfo.customerId : AppConstants.EMPTY)
    );
  }

  /**
  * Gets DSN field to render
  */
  getDSNField = () => {
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack } = this.props.params;
    if (!ld.isEmpty(selectedTestGroupInfo) && selectedTestGroupInfo.customerId !== AppConstants.EMPTY &&
      this.state.dutListLoaded && // !dutListLoading, difference
      !this.state.addDUTManually) {
      return (
        <AWSUI.ColumnLayout columns={2} borders='none'>
          <div>
            {this.getSelectField('dsn', 'Device Serial Number', 'Device Serial Number of the DUT',
              selectedTestGroupInfo.dutList,
              selectedTestGroupInfo.dsn ? selectedTestGroupInfo.dsn : AppConstants.EMPTY)}
            <AWSUI.Button variant='link' icon='refresh' text={REFRESH_DSN_LABEL}
              onClick={() => this.getDUTsListForced(selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack)}>
            </AWSUI.Button>
            {!this.checkCurrentDUTListEmpty(selectedTestGroupInfo.dutList) && (
              <AWSUI.Tooltip className='awsui-util-status-info'
                text={AppConstants.DUT_TOOLTIP_MESSAGE} size='small' position='top'>
                <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            )
            }
            {this.checkCurrentDUTListEmpty(selectedTestGroupInfo.dutList) && (
              <AWSUI.Button className='awsui-util-ml-s' variant='link' icon='add-plus' text={ADD_DSN_LABEL}
                onClick={this.addDUTManually}>
              </AWSUI.Button>)
            }
          </div>
        </AWSUI.ColumnLayout>
      )
    }
    if (selectedTestGroupInfo.customerId !== AppConstants.EMPTY &&
      this.state.dutListLoading &&
      !this.state.addDUTManually) {
      return (
        <AWSUI.ColumnLayout columns={2} borders='none'>
          <div>
            {getDUTsLoadingMessage(AppConstants.LOADING_DUTS)}
          </div>
        </AWSUI.ColumnLayout>
      )
    }
  }

  /**
   * Gets Amazon ID field to render
   */
  getAmazonIdField = () => {
    const { selectedTestGroupInfo } = this.props.params;
    let optionalParams = {};
    optionalParams.addDUTManually = this.state.addDUTManually;
    if ((selectedTestGroupInfo.dsn !== AppConstants.EMPTY &&
      this.state.showAmazonId) || this.state.addDUTManually) {
      return (
        this.getInputField('deviceTypeId', 'Amazon ID (Optional)', 'Amazon ID of the DUT', 600, optionalParams)
      )
    }
    return (
      <div></div>
    )
  }

  /**
   * Gets A4PC device config
   */
  getDeviceConfig = () => {
    const { selectedTestGroupInfo } = this.props.params;
    if (isA4PCDevice(selectedTestGroupInfo.deviceTypeId)) {
      const { deviceConfig = AppConstants.EMPTY } = selectedTestGroupInfo;
      return (
        this.getInputFieldOptional('deviceConfig', 'A4PC Device Config (Optional)', 'Provide s3 path of A4PC device config', deviceConfig)
      )
    }
    return (
      <div></div>
    )
  }

  /**
   * TODO: fulfil logic of isDsnInputField
   * Method to add DUT manually through text field when no DUTs are available in
   * dropdown for selected customer ID
   */
  addDUTManually = () => {
    this.setState({
      ...this.state,
      addDUTManually: true,
      showAmazonId: true,
      /* testParams: {
        ...this.state.testParams,
        dsn: AppConstants.EMPTY,
        isDsnInputField: true,
        showAmazonId: true
      }, */
      error: {
        ...this.state.error,
        dsn: false
      }
    })
  }

  /**
   * Interactive UI interface to retrieve testGroupInfo
   * related to Information Section to get Test Group Info
   */
  getTestGroupInfoSection = () => {
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack, lockFields } = this.props.params;
    return (
      <div>
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {this.getCustomerIdField()}
            {this.getInputField('buildInfo', AppConstants.FIRMWARE, 'Provide firmware information for the DUT')}
            {this.getDSNField()}
            {this.getAmazonIdField()}
            {this.state.addDUTManually && (
              this.getInputField('dsn', 'Device Serial Number', 'Device Serial Number of the DUT')
            )}
            {this.getDeviceConfig()}
          </div>
        </AWSUI.ColumnLayout>
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {this.getInputField('testName', 'Test Group Name', 'Provide a name for this group of tests')}
            {this.getLabInfo()}
            {this.getA4PCAQTCompanionAppConfirmation()}
            {getEmailNotificationField(selectedTestGroupInfo, selectedTestGroupInfoCallBack, lockFields)}
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  getLabInfo = () => {
    const { labsInAccount, selectedTestGroupInfo } = this.props.params;
    let currentSelectedLabId = ld.isEmpty(selectedTestGroupInfo) ? AppConstants.EMPTY : selectedTestGroupInfo.labId;

    //Filter the labs that are not in use, lock = 0  => lab not in use
    let labsNotInUse = labsInAccount.filter(function (el) {
      return (el.lock == 0)
    });

    return this.getSelectFieldWithDefaultOption('labId', 'Lab', 'Lab to run the test',
      labsNotInUse,
      currentSelectedLabId ? currentSelectedLabId : AppConstants.EMPTY);
  }

  /**
   * When there's only 1 option, choose that option as selected
   * TODO: see whether this can be extracted out
   * For DSN options as following format for dsnOnline status, do not use this function
   *
   * dutList":[{"label":"Status Unknown",
   *             "options":[{"id":"G090U610905607D6",
   *                          "label":"shiEchoDot3",
   *                          "type":"A32DOYMUN6DTXA","name":"shiEchoDot3","deviceUnderTestId":"ae72ef50-2886-47fe-b2df-1c2600aed9d9","tags":["G090U610905607D6"]},
   *                 {"id":"G090U610905606AS","label":"EN_US_DUT","type":"ECHO_DOT_V3","name":"EN_US_DUT","deviceUnderTestId":"e4446df3-4f67-43c0-98af-51372cb0a983",
   *                 "tags":["G090U610905606AS"] }]
   *           }]
   * @param {*} id     eg. 'customerId'
   * @param {*} label
   * @param {*} hintText
   * @param {*} options   options is array, not object when using this function
   * @param {*} selected  selected value
   */
  getSelectFieldWithDefaultOption = (id, label, hintText, options, selected) => {
    if (options.length > 1 || options.length === 0) {
      // put options.length == 0 to stress when there's no option, error text will be triggered
      // by lower level getSelectedField
      return this.getSelectField(id, label, hintText, options, selected);
    } else if (options.length === 1) {
      // there's only one option to select from, if customer do not select, no event will happen
      // so update the field with the only option.
      const {selectedTestGroupInfo, selectedTestGroupInfoCallBack,
        selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack} = this.props.params;
      if (!selectedTestGroupInfo[id]) {
        const identifier = 'TestGroupInfo, getSelectFieldWithDefaultOption, ' + id;
        let updatedSelectedTestGroupInfo = selectedTestGroupInfo;
        updatedSelectedTestGroupInfo[id] = options[0].id;
        sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfo, selectedTestGroupInfoCallBack);

        let updatedSelectedTestGroupInfoError = selectedTestGroupInfoError;
        updatedSelectedTestGroupInfoError[id] = false;
        sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
      }
      return this.getSelectField(id, label, hintText, options, options[0].id);
    }
  }

  /**
   * Get select options within TestGroupInfo Section
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   */
  getSelectField = (id, label, hintText, options, selected) => {
    const {selectedTestGroupInfo, selectedTestGroupInfoError, lockFields} = this.props.params;
    let shouldDisable = shouldDisableDropdown(id, selectedTestGroupInfoError[id], selectedTestGroupInfo.dutList,
      this.state.dutListLoading);
    shouldDisable = shouldDisable || lockFields;
    let errorText = getErrorTextForDropdown(id, selectedTestGroupInfoError[id], selectedTestGroupInfo.dutList, shouldDisable);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelectDevice);
  }

  /**
   * Loads DSNs for selected customer ID
   * Once Cid is selected, this function needs to be executed
   * @param customerId Customer ID selected in customer ID dropdown
   */
  loadDUTs = async (customerId) => {
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack } = this.props.params;
    let updatedTestGroupInfo = selectedTestGroupInfo;
    updatedTestGroupInfo.customerId = customerId; // upon selection, this cid should be updated
    await this.getDUTsList(updatedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack, false);

    const identifier = 'TestGroupInfo loadDUTs';
    let updatedSelectedTestGroupInfo = selectedTestGroupInfo;
    updatedSelectedTestGroupInfo.dsn = AppConstants.EMPTY;
    updatedSelectedTestGroupInfo.deviceUnderTestId = AppConstants.EMPTY;
    updatedSelectedTestGroupInfo.deviceTypeId = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfo, selectedTestGroupInfoCallBack);

    let updatedSelectedTestGroupInfoError = selectedTestGroupInfoError;
    updatedSelectedTestGroupInfoError.dsn = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);

    this.setState({
      addDUTManually: false,
      showAmazonId: false
    });
  }

  /**
   * TODO: can move to helper function or utility function
   * Check dutList empty
   * @return true: if dutList is empty
   *         false: if dutList not empty
   */
  checkCurrentDUTListEmpty = (dutList) => {
    if (!dutList || dutList.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Retrieves DUTs for a customerIDs with forceRefresh = true (Retrieves from DMS)
   * @returns List of DUTs for customer ID
   */
  getDUTsListForced = (selectedTestGroupInfo, selectedTestGroupInfoCallBack,
    selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack) => {
    this.setState({
      dutListLoading: true,
      dutListLoaded: false,
      addDUTManually: false,
    });
    const identifier = 'TestGroupInfo getDUTsListForced';
    let updatedSelectedTestGroupInfo = selectedTestGroupInfo;
    updatedSelectedTestGroupInfo.dsn = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfo, selectedTestGroupInfoCallBack);

    let updatedSelectedTestGroupInfoError = selectedTestGroupInfoError;
    updatedSelectedTestGroupInfoError.dsn = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
    return this.getDUTsList(selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack, true);
  }

  //TODO: move this function into helper
  /**
   * Retrieves DUTs for a customer ID
   * button will show label and description
   * @param customerId Customer ID for which DUTs needs to be retrieved
   * @param forceRefresh Whether to fetch DUTs from DMS or DB
   * @returns List of DUTs for customer ID
   */
  getDUTsList = async (selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack, forceRefresh) => {
    let customerId = selectedTestGroupInfo.customerId;

    let response = await getDUTs(customerId, forceRefresh);

    const isProdEnv = isProdEnvironment();


    if (isProdEnv) {
      const dutsListWithoutNamespace = ld.filter(response.duts, (dut) => { if (dut.namespace === null) { return dut } });
      const isForceRefreshRequired = !forceRefresh && (response.duts.length === 0 || dutsListWithoutNamespace.length !== 0);

      // no dsns found in DB if response.length is 0, probably cid is new; so perform forceRefresh
      // or perform a forceRefresh if there is a dut without a namespace
      if (isForceRefreshRequired) {
        response = await getDUTs(customerId, true);
      }
    }

    logToConsole("transforming device online status of the duts");
    return this.getSortedDUTListByOnlineStatus(response, selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);

  }

  /**
   * Transform & Return the list of duts into sorted list of duts
   * based on online status
   * @param {*} dutResponse
   * @param {*} selectedTestGroupInfo
   * @param {*} selectedTestGroupInfoCallBack
   * @param {*} selectedTestGroupInfoError
   * @param {*} selectedTestGroupInfoErrorCallBack
   */
  getSortedDUTListByOnlineStatus = (dutResponse, selectedTestGroupInfo, selectedTestGroupInfoCallBack, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack) => {
    let dutListIn = generateDUTListWithOnlineOfflineStatus(dutResponse);
    this.setState({
      dutListLoaded: true, // no matter whether returned list is empty or not, dut list is loaded
      dutListLoading: false,
    });
    const identifier = 'TestGroupInfo getDUTsList';
    let updatedTestGroupInfo = selectedTestGroupInfo;
    updatedTestGroupInfo.dutList = dutListIn;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedTestGroupInfo, selectedTestGroupInfoCallBack);

    let updatedSelectedTestGroupInfoError = selectedTestGroupInfoError;
    updatedSelectedTestGroupInfoError.dsn = dutListIn.length === 0;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
    return { dutList: dutListIn };
  }

  //TODO: rename this when possible
  // check logic in origin onChangeSelect
  onChangeSelectDevice = (id, event) => {
    // reflect selection on selection box
    const {selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack} = this.props.params;
    // reflect general selection
    const identifier = 'TestGroupInfo onChangeSelectDevice ' + id;
    let updatedSelectedTestGroupInfo = selectedTestGroupInfo;
    updatedSelectedTestGroupInfo[id] = event.detail.selectedOption.id;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfo, selectedTestGroupInfoCallBack);

    let updatedSelectedTestGroupInfoError = selectedTestGroupInfoError;
    updatedSelectedTestGroupInfoError[id] = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);

    if (id === 'customerId' && event.detail.selectedOption.id !== AppConstants.EMPTY) {
      // let it select cid
      // what's the use of customerAccountEmail ?
      let customerAccountEmail = event.detail.selectedOption.label;
      customerAccountEmail = customerAccountEmail.split(" ")[0];
      this.loadDUTs(event.detail.selectedOption.id);
    }
    // Set Device Type field for selected DSN
    if (id === 'dsn' && event.detail.selectedOption.id !== AppConstants.EMPTY) {
      this.setDeviceInfo(selectedTestGroupInfo.dutList, event.detail.selectedOption.id);
    }
  }

 /**
  * Sets device type for the selected DSN
  * deviceUnderTestId and dsn are different
  */
  setDeviceInfo = (dutList, dsn) => {
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack} = this.props.params;
    let deviceTypeId = AppConstants.EMPTY;
    let deviceName = AppConstants.UNKNOWN;
    let deviceUnderTestId = AppConstants.EMPTY;
    let namespace = AppConstants.EMPTY;
    let showAmazonId = false;
    if (dutList && dutList.length > 0 && dsn) {
      let dutArray = [];
      dutArray = convertDUTListFormat(dutList);
      for (var i = 0; i < dutArray.length; i++) {
        if (dutArray[i].id === dsn) {
          deviceUnderTestId = dutArray[i].deviceUnderTestId;
          deviceName = dutArray[i].label;
          if (dutArray[i].type) {
            deviceTypeId = dutArray[i].type;
            // eslint-disable-next-line no-prototype-builtins
            showAmazonId = !AppConstants.DUT_TYPE_MAP.hasOwnProperty(deviceTypeId);
          }
          if (dutArray[i].namespace) {
            namespace = dutArray[i].namespace;
          }
          break;
        }
      }
    }
    if (deviceTypeId === AppConstants.EMPTY) {
      showAmazonId = true;
    }

    let updatedSelectedTestGroupInfo = selectedTestGroupInfo;
    updatedSelectedTestGroupInfo.deviceTypeId = deviceTypeId;
    updatedSelectedTestGroupInfo.deviceName = deviceName;
    updatedSelectedTestGroupInfo.deviceUnderTestId = deviceUnderTestId;
    updatedSelectedTestGroupInfo.namespace = namespace;
    const identifier = 'TestGroupInfo setDeviceInfo for dsn: ' + dsn;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedTestGroupInfo, selectedTestGroupInfoCallBack);

    this.setState({
      showAmazonId: showAmazonId
    });

  }

  /**
   * Gets A4PC device config
   */
  getA4PCAQTCompanionAppConfirmation = () => {
    const {selectedTestGroupInfo, selectedTestGroupInfoCallBack, otherParams, lockFields} = this.props.params;
    console.log("Other parms " + JSON.stringify(this.props.params))
    if (isA4PCDevice(selectedTestGroupInfo.deviceTypeId)) {
      console.log("Inside getA4PCAQTCompanionAppConfirmation state parameters")
      return (
        <AWSUI.FormField>
          <div style={{maxWidth: 600}}>
            <div className='awsui-util-container'>
              <AWSUI.Checkbox
                label={'AQT Companion app enabled in DUT?'}
                disabled={lockFields}
                checked={ld.get(selectedTestGroupInfo, 'aqtA4PCCompanionAppEnabled', false)}
                onChange={event => {
                  setCheckedStateInTestParams(event, "aqtA4PCCompanionAppEnabled", selectedTestGroupInfo, selectedTestGroupInfoCallBack)
                }}/>
            </div>
          </div>
        </AWSUI.FormField>
      )
    }
  }

  // getInputField original
  // getFirmware Info, etc
  // get buildInfo, Firmware information
  getInputField = (id, label, hintText, maxWidth = 600, optionalParams = null) => {
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack, lockFields } = this.props.params;
    let value = selectedTestGroupInfo[id];
    if (lockFields) {
      return this.getDisabledInputField(id, label, hintText, maxWidth, value)
    } else {
      return getInputField(id, label, hintText, maxWidth, selectedTestGroupInfo, selectedTestGroupInfoCallBack,
        selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack, this.onChangeInput, this.onInput, optionalParams
      );
    }

  }

  /**
   * Disabled Input field with locked input
   * @param fieldId Index of the current field. - 'dsn'
   * @param fieldLabel This will be either of dsn, amazonId and buildInfo
   * @param hintText - Eg: "Device Serial Number of the DUT"
   * @param value - such as Actual value of dsn
   * @return Returns disabled input field for dsn, amazonId or buildInfo depending on
   * parameters passed to the method
   */
  getDisabledInputField = (fieldId, fieldLabel, hintText, maxWidth, value) => {
    return (
      <AWSUI.FormField
        label={ fieldLabel }
        hintText={ hintText }
      >
        <div style={{ maxWidth: maxWidth }}>
          <AWSUI.Input
            value={ value }
            disabled= { true }
          ></AWSUI.Input>
        </div>
      </AWSUI.FormField>
    );
  }

  /**
   * Gets input field for optional DUTs
   * @param fieldId Index of the current field. - 'dsn'
   * @param fieldLabel This will be either of dsn, amazonId and buildInfo
   * @param hintText - Eg: "Device Serial Number of the DUT"
   * @param value - such as Actual value of dsn
   * @param index - optional dut index value.
   * @return Returns input field for dsn, amazonId or buildInfo depending on
   * parameters passed to the method
   */
  getInputFieldOptional = (fieldId, fieldLabel, hintText, value, index) => {
    let isError = false;

    const { selectedTestGroupInfo } = this.props.params;
    // Check if A4PC path is valid
    if (fieldId === 'deviceConfig' && !isValidDeviceConfig(value, selectedTestGroupInfo.dsn)) {
      isError = true;
    }

    return (
      <AWSUI.FormField
        label={ fieldLabel }
        hintText={ hintText }
        errorText={ getErrorText(fieldId, isError, value) }
      >
        <div style={{ maxWidth: 600 }}>
          <AWSUI.Input
            value={ value }
            onChange={ event => {
                this.onChangeInputOptional(fieldId, event, index)
            }}
          ></AWSUI.Input>
        </div>
      </AWSUI.FormField>
    );
  }

  // when AWS component Input is updated
  // original onChangeInput
  onChangeInput = (id, event) => {
    const source = 'TestGroupInfo, onChangeInput';
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack } = this.props.params;
    return onChangeInput(id, event, source,
      selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
  }

  /**
   * Update the state when the value is not empty
   * @param {fieldId} id Optional input field identifier
   * @param {object} event
   */
  onChangeInputOptional = (id, event) => {
    if (!event || !event.detail.value) return;

    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack } = this.props.params;

    selectedTestGroupInfoCallBack({
      ...selectedTestGroupInfo,
      [id]: event.detail.value
    });
  }

  // operation on InputField
  onInput = (id, event) => {
    const source = 'TestGroupInfo onInput';
    const { selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack } = this.props.params;
    return onInput(id, event, source,
      selectedTestGroupInfo, selectedTestGroupInfoCallBack,
      selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
  }

  render() {
    return (
      <div>
        <AWSUI.FormSection header='Test Group Information'>
          {
            this.getTestGroupInfoSection()
          }
        </AWSUI.FormSection>
      </div>
    );
  }
}

export default TestGroupInfo;
