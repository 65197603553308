// Auth constants
export const SET_ENVIRONMENT = 'action/SET_ENVIRONMENT';
export const SET_SESSION = 'action/SET_SESSION';

export const FETCH_ALLOW_LIST_PENDING = 'action/FETCH_ALLOW_LIST_PENDING';
export const FETCH_ALLOW_LIST_SUCCESS = 'action/FETCH_ALLOW_LIST_SUCCESS';
export const FETCH_ALLOW_LIST_FAILURE = 'action/FETCH_ALLOW_LIST_FAILURE';

export default {
  SET_ENVIRONMENT,
  SET_SESSION,
  FETCH_ALLOW_LIST_PENDING,
  FETCH_ALLOW_LIST_SUCCESS,
  FETCH_ALLOW_LIST_FAILURE,
};
