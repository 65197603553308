import React from 'react';
import CustomizeOptionsSubPage from '../CustomizeOptionsSubPage';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';
import AppConstants from "../../../_Constants/AppConstants";
import {isEmpty} from "lodash";
import {logToConsole} from "../../../Util";

class MobileVoiceRobustness extends React.Component {

  render() {
    const {
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack
    } = this.props.params;
    return (
      <div>
        <TestTypeScenarioOptions
          allowedTypes={[0, 3]}
          showDelayConfig={AppConstants.CUSTOMIZED === selectedScenario.testType}
          showAdminValidation={AppConstants.CUSTOMIZED === selectedScenario.testType}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
          {/* These components are specific to Mobile test */}
          {!isEmpty(selectedScenario) &&
          (<CustomizeOptionsSubPage params={{
            selectedScenario: selectedScenario,
            selectedScenarioCallBack: selectedScenarioCallBack,
            piLocations: AppConstants.trainedMobileLocation,
            selectedScenarioError: selectedScenarioError,
            scenarioErrorCallBack: scenarioErrorCallBack
          }}/>)
          }
        </TestTypeScenarioOptions>
      </div>
    )
  }
}
export default MobileVoiceRobustness;
