import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../services/client';
import { API_STATUS, URL_MAPS } from '../../constants';
import { getUrl } from '../../services/utils';

export const fetchDevices = createAsyncThunk('api/dut', async (req) => {
  let deviceUrl = getUrl(URL_MAPS.DUT);
  const customerId = {
    customerId: req.cid
  }
  const refreshParam = req.refresh === true ? "&forceRefresh=true" : "&forceRefresh=false"  
  const url = `${deviceUrl}/?q=${JSON.stringify(customerId)}${refreshParam}`;
  const response = await client.get(url)
  return {
    cId: req.cid,
    data: response.data
  };
})

export const deviceUnderTestSlice = createSlice({
  name: 'deviceUnderTest',
  initialState: {
    status: API_STATUS.INIT,
    dutList: {},
    error: null
  },
  reducers: {

  },
  extraReducers:(builder) => {
    builder
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state['status'] = API_STATUS.SUCCESS;
        const {cId, data} = action.payload;
        if(Array.isArray(data)) {
          state.dutList[cId] = data;
        } else {
          state.dutList[cId] = [];
        }
        state['error'] = null;
      })
      .addCase(fetchDevices.pending, (state) => {
        state['status'] = API_STATUS.PENDING;
        state['error'] = null;
      })
      .addCase(fetchDevices.rejected, (state) => {
        state['status'] = API_STATUS.ERROR;
        state['error'] = "Failed to fetch devices list";
      })
  }
})

export const dutListSelector = (state) => state.devicesList;

export default deviceUnderTestSlice.reducer;
