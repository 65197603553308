import constants from '../constants/newRun';

/**
 * Sets Validation result for new run scenario
 * @param {Object} environment
 */
export function setValidationResult(scenarioErrors) {
  return (dispatch) => {
    return dispatch({
      type: constants.VALIDATE_NEW_RUN_PARAMS,
      payload: scenarioErrors
    });
  }
}

/**
 * Reset Validation result for new run scenario
 * @param {Object} environment
 */
export function resetValidationResult() {
  return (dispatch) => {
    return dispatch({
      type: constants.RESET_VALIDATION_RESULT,
    });
  }
}
