export const EXPANDABLE_COLUMNS = [{
    id: 'functionalTestCaseName',
    cell: item => item.functionalTestCaseName,
    minWidth: '300px',
    allowLineWrap: false
  }];
   
  export const FUNCTIONAL_UTTERANCES_COLUMNS = [{
    id: 'questionPlayed',
    header: () => 'Question Played',
    cell: item => item.questionPlayed,
    minWidth: '300px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dutHeard',
    header: () => 'DUT Heard',
    cell: item => item.dutHeard,
    minWidth: '300px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dutResponded',
    header: () => 'Alexa Cloud Response',
    cell: item => item.dutResponded,
    minWidth: '300px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'comments',
    header: () => 'Comments',
    cell: item => item.comments,
    minWidth: '200px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'backToTestMetrics',
    header: () => '',
    cell: item => item.backToTestMetrics,
    minWidth: '200px',
    allowLineWrap: true,
    resizableColumns: true
  }];