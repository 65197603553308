import {
  FormField,
  RadioGroup,
  Table,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSelection,
  TableWrapLines,
} from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import ld from "lodash";

function ScenariosTable({
  items = [],
  defaultItem = [],
  columnDefinitions = [],
  onClickHandler = (e) => {
    console.log(e);
  },
}) {
  const [currentItem, setCurrentItem] = useState(defaultItem);

  useEffect(() => {
    if (items.length > 0) console.log(">>table creation w", items, defaultItem);
    setCurrentItem(defaultItem);
  }, [defaultItem]);

  const itemSelectHandler = (e) => {
    const selectedItem = ld.get(e, "detail.selectedItems", defaultItem);
    setCurrentItem(selectedItem);
    console.log(">>set cur item", selectedItem);
    onClickHandler(selectedItem);
  };

  if (items.length > 0)
    return (
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={items}
        features={["pagination", "selection"]}
        header={<h2>Scenarios</h2>}
        // resizableColumns={true}
        stickyHeader={true}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No scenarios</b>
            </div>
          </div>
        }
      >
        <TablePagination pageSize={5} openEnd={false} />
        <TableSelection
          selectionType="single"
          selectedItems={currentItem}
          trackBy="id"
          keepSelection={true}
          onSelectionChange={itemSelectHandler}
        ></TableSelection>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 2, label: "2 items" },
              { value: 4, label: "4 items" },
              { value: 6, label: "6 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap cell content, disable to truncate."
          />
          <FormField
            stretch={true}
            className="awsui-table-custom-preference"
            label="View as"
          >
            <RadioGroup
              value="table"
              items={[
                { value: "table", label: "Table" },
                { value: "cards", label: "Cards" },
              ]}
            />
          </FormField>
        </TablePreferences>
      </Table>
    );
  return null;
}

export default ScenariosTable;
