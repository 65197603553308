import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Button, FormField, Badge
} from "@amzn/awsui-components-react";
import "./FileUpload.css";
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {logToConsole} from "./index";

const MESSAGES = {
    COMMON_ERROR_MSG: "Failed to upload file",
    ERROR_READ: "Failed to read file"
};

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.fileSize = null;
    this.state = {
        uploaded: false,
        error: null,
        file: {}
    };
  }
  hiddenFileInput = React.createRef();

  handleFileUpload = function(uploadedFile) {
    logToConsole("handleFileUpload():Uploaded File name: " + uploadedFile.name);
    this.setState({
      file: uploadedFile,
      errorMessage: undefined
    });
    if (this.props.onFileUpload) {
      this.props.onFileUpload(uploadedFile)
    }
  }

  handleReset = () => {
    this.setState({ file: undefined, errorMessage: undefined });
  };

  render() {
    return (
      <FormField
          id="fileUpload"
          label={this.props.label}
          description={this.props.description}
          hintText={this.props.hintText}
      >
        <input
            ref={this.hiddenFileInput}
            id= {this.props.id}
            type="file"
            hidden
            multiple={false}
            accept={this.props.fileType}
            onChange={(event) => this.handleFileUpload(event.target.files[0])}
        />
        <Button
            icon="upload"
            id="choseFileButton"
            formAction="none"
            onClick={() => {
                this.hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                this.hiddenFileInput.current.click();
            }}
        >
            Browse
        </Button>
        {
          (this.state.file.name) && (
            <span className="awsui-util-pl-m">
              <Badge color="green">
                {this.state.file.name}
              </Badge>
            </span>
          )
        }
        {
          !(this.state.file.name) 
          && (this.props.params.fileName) && (
            <span className="awsui-util-pl-m">
              <Badge color="green">
                {this.props.params.fileName}
              </Badge>
            </span>
          )
        }
        {
          this.props.params.downloadURL && (
            <IconButton color='default' variant='fab' aria-label='download'
              onClick={ (event) => {
                window.open(this.props.params.downloadURL);
              }}
            >
             <CloudDownloadIcon />
            </IconButton>
          )
        }
      </FormField>
    );
  }
}

UploadFile.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    parseData: PropTypes.bool,
    onFileUpload: PropTypes.func,
    fileType: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    hintText: PropTypes.string,
};

UploadFile.defaultProps = {
    id: "FileUpload",
    fileType: "csv",
    disabled: false,
    className: "",
    label: "Upload File",
    description: "Select the files",
    hintText: "Upload File",
};

export default UploadFile;
