import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import {
  getDisablePlaybackSkillOption,
  getUseCustomNoiseFilesOption
} from '../uiUtils';
import AppConstants from '../../../_Constants/AppConstants';
import GeneralAutomotiveTestTypeSelection from './GeneralAutomotiveTestTypeSelection';


class Automotive extends React.Component {

  getCustomOptions = (selectedScenario, selectedScenarioCallBack) => {
    return (
      <div>
        {
          getDisablePlaybackSkillOption(selectedScenario, selectedScenarioCallBack)
        }
      </div>
    )
  }

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return (
      <div>
        { <GeneralAutomotiveTestTypeSelection params={{
            selectedScenario: selectedScenario,
            selectedScenarioCallBack: selectedScenarioCallBack,
            selectedScenarioError: selectedScenarioError,
            scenarioErrorCallBack: scenarioErrorCallBack,
            piLocations: AppConstants.automotiveCompanionLocation,
          }} />
        }
      </div>
    )
  }
}

export default Automotive;
