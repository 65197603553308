const API_PREFIX = 'api';
const THINGS = 'things';
const LABS = 'labs';
const CUSTOM_SCENARIOS = 'customscenarios';
const SCENARIOS = 'scenarios';

const executeActionOnThing = (labId, raspiId, action) =>
  `${API_PREFIX}/${LABS}/${labId}/${THINGS}/${raspiId}/${action}`;

const getLabThings = (labId) =>
  `${API_PREFIX}/${LABS}/${labId}/${THINGS}`;

const customScenarios = () =>
  `${API_PREFIX}/${CUSTOM_SCENARIOS}`;

const getAllowList = () =>
  `${API_PREFIX}/${SCENARIOS}`;

export default {
  executeActionOnThing,
  getLabThings,
  customScenarios,
  getAllowList
}
