export const SecurityTestConstants = {
  // Map containing styles & Icons to display header status
  HEADER_STATUS_MAP: {
    'complete': {
      testStatusClass: 'awsui-util-status-positive nowrap-style',
      testStatusIcon: 'status-positive',
      variant: 'normal',
      testStatusTextStyle: 'awsui-util-ml-xs security-completed-test-status'
    },
    'inprogress': {
      testStatusClass: 'awsui-util-status-info nowrap-style',
      testStatusIcon: 'status-in-progress',
      variant: 'normal',
      testStatusTextStyle: 'awsui-util-ml-xs security-inprogress-test-status'
    }
  },

  STATUS_DISPLAY: {
    'complete': 'Complete',
    'inprogress': 'In Progress'
  }
}

export const TEST_TYPES = {
  PORT_SCANNER: 'PORT_SCANNER',
  PACKET_CAPTURE_ANALYSIS: 'PACKET_CAPTURE_ANALYSIS',
  MAN_IN_THE_MIDDLE_DETECTION: 'MAN_IN_THE_MIDDLE_DETECTION'
};
