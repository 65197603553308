
export const ACTION_INSTALL_PLUGIN = 'install_plugin';
export const ACTION_UPDATE_HOTSPOT = 'update_plugin';

export const DEFAULT_PAYLOAD = {}
export const PAYLOAD_INSTALL_PLUGIN = {
  "pluginName": "mdx_plugin_aqt_hotspot"
}

export const AUTOMOTIVE_PLUGIN_INSTALL = {
  "plugins": ['mdx_plugin_aqt_automotive']
}

export const QUAL_RF_INSTALL_PLUGIN = {
  "plugins": ["mdx_plugin_aqt_hotspot", "mdx_plugin_acoustics_gen2", "mdx_plugin_acoustics_tool_gen2"]
}

export const GET_HOTSPOT_CONFIG_PAYLOAD = {
  'action': 'GET_HOTSPOT_CONFIG',
  'payload': {},
  'type': 'mdx_plugin_aqt_hotspot'
}

export const CREATE_HOTSPOT_PAYLOAD = {
  "action": "CREATE_HOTSPOT",
  "type": "mdx_plugin_aqt_hotspot",
  "payload": {
    "ssid": "AQTHotspot-22331",
    "passphrase": "rpi@7578"
  }
}

export const START_HOTSPOT_PAYLOAD = {
  "action": "START_HOTSPOT",
  "type": "mdx_plugin_aqt_hotspot",
  "payload": {
  }
}

export const STOP_HOTSPOT_PAYLOAD = {
  "action": "STOP_HOTSPOT",
  "type": "mdx_plugin_aqt_hotspot",
  "payload": {
  }
}

export const REBOOT_PI_PAYLOAD = {
  "action": "REBOOT_PI",
  "type": "mdx_plugin_aqt_hotspot",
  "payload": {
  }
}

export const GET_CONNECTED_DEVICES_PAYLOAD = {
  "action": "GET_CONNECTED_DEVICES",
  "type": "mdx_plugin_aqt_hotspot",
  "payload": {
  }
}

export const HOTSPOT_STATUS_NONE = 'NO STATUS';
export const HOTSPOT_STATUS_ON = 'ON';
export const HOTSPOT_STATUS_OFF = 'OFF';

export const ACK_TIMEOUT = 10000;
export const ACTION_TIMEOUT = 120000;
export const MQTT_TIMEOUT = 50000;

export const OFFLINE_MESSAGE = "Raspberry Pi seems to be offline. Kindly bring up the Raspberry Pi online inorder to configure the hotspot.";
export const PLUGIN_INSTALL_REQUIRED = "Please install AQT Hotspot software to setup Wifi Hotspot on the Raspberry Pi";

export const PLUGIN_INSTALLED = "200"
export const PLUGIN_NOT_INSTALLED = "plugin -mdx_plugin_aqt_hotspot- not installed"
export const PLUGIN_NOT_CONFIGURED = "Unable to parse the config file"
export const PLUGIN_ERROR = "Config file not found"

export const RASPI_ONLINE = "online";
export const RASPI_OFFLINE = "offline";
export const PLUGIN_INSTALL_DEFAULT_STATE = 'Raspberry Pi is Offline';
export const PLUGIN_INSTALL_SETUP = 'Ready to Install';
export const PLUGIN_INSTALL_IN_PROGRESS = 'Installing & Configuring Hotspot Plugin';
export const PLUGIN_UPDATE_CONFIG_IN_PROGRESS = 'Updating SSID/Passphrase';
export const PLUGIN_UPDATE_CONFIG_FAILED = 'Failed to update SSID/Passphrase';
export const PLUGIN_UPDATE_CONFIG_SUCCESS = 'Successfully updated SSID/Passphrase';
export const PLUGIN_UPDATE_IN_PROGRESS = 'Updating Hotspot Plugin';
export const PLUGIN_INSTALL_REBOOT = 'Rebooting Raspberry Pi';
export const PLUGIN_INSTALL_COMPLETE = 'Fetching Hotspot Config';
export const PLUGIN_INSTALL_SHOW_CONFIGS = 'Displaying Hotspot Config';
export const PLUGIN_START_HOTSPOT = 'Starting hotspot';
export const PLUGIN_STOP_HOTSPOT = 'Terminating hotspot';

export const WIFI_BAND_RANGE =[
  { value: "bg", label: '2.4 GHz' },
  { value: "a", label: '5 GHz' }
]
export const BG_CHANNEL_RANGE =[
  { value: "1", label: '1' },
  { value: "6", label: '6' },
  { value: "11", label: '11' }
]
export const A_CHANNEL_RANGE =[
  { value: "36", label: '36' },
  { value: "48", label: '48' },
  { value: "149", label: '149' },
  { value: "165", label: '165' }
]
export const INTERFACE =[
    { value: "wlx", label: 'Techkey USB' }
]
