
export const CUSTOM_TEST_UTTERANCES_COLUMNS = [{
    id: 'utterancePlayedTranscript',
    header: () => 'Utterance',
    cell: item => item.utterancePlayedTranscript,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dutResponse',
    header: () => 'Cloud Response',
    cell: item => item.dutResponse,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'status',
    header: () => 'Validation Result',
    cell: item => item.status,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'expecterAsr',
    header: () => 'Expected ASR',
    cell: item => item.expectedAsr,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'expecterResponse',
    header: () => 'Expected Response',
    cell: item => item.expectedTts,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }];