export const CARD_DEFINITION = {
  header: item => ( item.getHeader(item.options, item.id) ),
  sections: [
    {
      id: item => item.id,
      content: item => ( item.getFeeds(item.id) )
    }
  ]
};

export const CARD_PER_ROW = [
  {
    "cards": 1
  },
  {
    "minWidth": 500,
    "cards": 1
  }
]