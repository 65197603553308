import React from 'react'
import { Provider } from 'react-redux';
import store from './stores/store';
import TestList  from './pages/newRun/testList';
import CreateTest  from './pages/newRun/createTest';
import localeStrings from './assets/translations/en.json';
import {IntlProvider} from "react-intl";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppWrapper from './app';

const App = () => {
  let match = useRouteMatch();
  return (
    <IntlProvider locale='en' messages={localeStrings} >
      <Provider store={store}>
        <AppWrapper>
          <Switch>
            <Route path={`${match.path}/run/create`}>
              <CreateTest />
            </Route>
            <Route path={`${match.path}/run`}>
              <TestList />
            </Route>
          </Switch>
        </AppWrapper>
      </Provider>
    </IntlProvider>
  )
}

export default App;
