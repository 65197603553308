/**
  * This file contains helper methods for Functional Scenario
  */
 import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';

 /**
  * Gets value for Functional Custom test case checkbox
  * @param id ID of the checkbox
  * @param functionalTestCases Current list of functional test cases
  * @returns true or false based on if checkbox is checked/ unchecked
  */
 export function getValueForFunctionalTestCasesCheckbox(id, functionalTestCases) {
   if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
     || FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING.hasOwnProperty(id) ) {

     let functionalTestCasesForId = FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
       ? FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING[id]
       : FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING[id];

     let totalExpectedTestCases = functionalTestCasesForId.length;
     let testCasesMatched = 0;
     for (let i = 0; i < functionalTestCasesForId.length; i++) {
       if (functionalTestCases.includes(functionalTestCasesForId[i]['id'])) {
         testCasesMatched += 1;
       }
     }
     return totalExpectedTestCases === testCasesMatched;
   }
   return functionalTestCases.includes(id);
 }

 /**
  * Sets value for Functional Custom test case Checkbox
  * @param id ID of the checkbox
  * @param event Event generated when checkbox is checked/ unchecked
  * @param functionalTestCases Current list of functional test cases
  * @returns Updated list of functional test cases
  */
 export function setValueForFunctionalTestCasesCheckbox(id, event, functionalTestCases) {
   if (event.detail.checked) {
     if (!functionalTestCases.includes(id)) {
       if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
         || FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING.hasOwnProperty(id)) {
         let functionalTestCasesToCheck = FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
           ? FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING[id]
           : FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING[id];
         for (let i = 0; i < functionalTestCasesToCheck.length; i++) {
           if (!functionalTestCases.includes(functionalTestCasesToCheck[i]['id'])) {
             functionalTestCases.push(functionalTestCasesToCheck[i]['id']);
           }
         }
       } else {
         functionalTestCases.push(id);
       }
     }
   } else if (!event.detail.checked) {
     if (functionalTestCases.includes(id)) {
       let elementIndex = functionalTestCases.indexOf(id);
       if (elementIndex > -1) {
         functionalTestCases.splice(elementIndex, 1);
       }
     } else if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
                || FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING.hasOwnProperty(id)) {
       let functionalTestCasesToUnCheck =  FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING.hasOwnProperty(id)
         ? FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING[id]
         : FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING[id];
       for (let i = 0; i < functionalTestCasesToUnCheck.length; i++) {
         let elementIndex = functionalTestCases.indexOf(functionalTestCasesToUnCheck[i]['id']);
         if (elementIndex > -1) {
           functionalTestCases.splice(elementIndex, 1);
         }
       }
     }
   }
   return functionalTestCases;
 }

 /**
  * Sets value for expandable section
  * @param id ID of the expandable section
  * @param event Event generated when expandable section is expanded/ collapsed
  * @param expandedSections Current list of expandable sections
  * @returns Updated list of expandable sections
  */
 export function setValueForExpandedSection(id, event, expandedSections) {
   if (event.detail.expanded && !expandedSections.includes(id)) {
     expandedSections.push(id);
   } else {
     if (expandedSections.includes(id)) {
       let elementIndex = expandedSections.indexOf(id);
       if (elementIndex > -1) {
         expandedSections.splice(elementIndex, 1);
       }
     }
   }
   return expandedSections;
 }
