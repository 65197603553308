/**
 * This file contains utility methods for Stability Live Feed for Live Run Page
 */

import { STABILITY_LIVE_SUMMARY_COLUMNS } from './StabilityLiveRunSummaryTableConfig';
import { STABILITY_LIVE_UTTERANCE_COLUMNS } from './StabilityLiveRunUtteranceTableConfig';
import { getLoadingMessage, logToConsole } from '../../../Util';
import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import LiveRunStyleConstants from '../../../_Constants/LiveRunStyleConstants';

/**
 * Stability Util method to load Stability Test Ongoing Summary Table from StabilityPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadStabilityLiveSummaryTableData(payload) {
    let result = [];
    try {
        let devices = payload.simulationEnvironment.devices;

        for (let i = 0; i < devices.length; i++) {
          let device = devices[i];
          let dsn = device.deviceSerialNumber;
          
          // load utterance result
          let utteranceResult;
          let isTimeBoundUtterance = payload.timeBoundUtterance;
          if (isTimeBoundUtterance) {
            utteranceResult = payload.dsnToTimeBasedUtteranceResultMap[dsn];
          } else {
            utteranceResult = payload.dsnToAdminUtteranceResultMap[dsn];
          }
          
          let utterance = utteranceResult.asrText;

          // load timebound summary details
          
          let totalAttempts = 0;
          let passCount = 0;
          let failCount = 0;
          let errorCount = 0;
          let passRate = 0;
          let offlineCount = 0;
          let passRateTextDisplay = '0%';
          
          if (isTimeBoundUtterance) {
            totalAttempts = utteranceResult.totalCountSoFar;
            passCount  = utteranceResult.passedCount;
            failCount = utteranceResult.failedCount;
            errorCount = utteranceResult.errorCount;
            passRate = utteranceResult.passRate;
            offlineCount = utteranceResult.deviceOfflineCount;
            passRateTextDisplay = getPassRateText(passRate);
          }

          result.push({
            utterance: utterance,
            totalAttempts: totalAttempts,
            passCount: passCount,
            failCount: failCount,
            passRate: passRateTextDisplay,
            offlineCount: offlineCount
          });
        }
    } catch (ex) {
        logToConsole(JSON.stringify(ex));
    }
    return result;
}

/**
 * Stability Util method to load Stability Test Utterance Result Table from StabilityPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadStabilityLiveUtteranceTableData(payload) {
  let result = [];
  try {
      let devices = payload.simulationEnvironment.devices;

      for (let i = 0; i < devices.length; i++) {
        let device = devices[i];
        let dsn = device.deviceSerialNumber;
        
        // load utterance result
        let utteranceResult;
        let isTimeBoundUtterance = payload.timeBoundUtterance;
        if (isTimeBoundUtterance) {
          utteranceResult = payload.dsnToTimeBasedUtteranceResultMap[dsn];
        } else {
          utteranceResult = payload.dsnToAdminUtteranceResultMap[dsn];
        }
        
        let utterance = utteranceResult.asrText;
        let actualResponse = utteranceResult.actualResponse === undefined ? AppConstants.NOT_APPLICABLE : utteranceResult.actualResponse;
        let actualIntent = utteranceResult.actualIntent;
        let resultStatus = getResultStatusText(utteranceResult.resultStatus);
        let isDeviceOnline = utteranceResult.deviceOnlineStatus === 'ONLINE';
        let deviceStatus = getOnlineOfflineStatusText(isDeviceOnline);

        result.push({
          utterance: utterance,
          actualResponse: actualResponse, 
          actualIntent: actualIntent, 
          resultStatus: resultStatus, 
          deviceStatus: deviceStatus
        });
      }
  } catch (ex) {
      logToConsole(JSON.stringify(ex));
  }
  return result;
}

/**
 * Method to get display friendly text for device online offline status.
 * @param isDeviceOnline device online or not
 * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
 */
export function getOnlineOfflineStatusText(isDeviceOnline) {
    if (isDeviceOnline === undefined) {
        return AppConstants.UNAVAILABLE;
    }

    let styleMapEntry = LiveRunStyleConstants.DEVICE_ONLINE_OFFLINE_STATUS[isDeviceOnline];
    let deviceStatusText = styleMapEntry.displayText;
    
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
            <div className={ styleMapEntry.deviceStatusClass }>
              <AWSUI.Icon name={ styleMapEntry.deviceStatusIcon }
                  variant={ styleMapEntry.variant }/>
                <span className = { styleMapEntry.deviceStatusTextStyle }>
                  { deviceStatusText }
                </span>
            </div>
            <div>
              {
                (!isDeviceOnline) && 
                (
                  <AWSUI.Tooltip text={ AppConstants.DEVICE_OFFLINE_MESSAGE } size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                )
             }
            </div>
            <div />
            <div />
          </div>
      </AWSUI.ColumnLayout>
    );
}

/**
 * Method to get display friendly text for utterance result status.
 * @param resultStatus utterance result status
 * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
 */
export function getResultStatusText(resultStatus) {
  if (resultStatus === undefined) {
      return AppConstants.UNAVAILABLE;
  }

  let styleMapEntry = LiveRunStyleConstants.UTTERANCE_RESULT_STATUS[resultStatus];
  let deviceStatusText = styleMapEntry.displayText;
  
  return (
    <AWSUI.ColumnLayout columns={ 1 } borders='none'>
      <div data-awsui-column-layout-root='true'>
          <div className={ styleMapEntry.deviceStatusClass }>
            <AWSUI.Icon name={ styleMapEntry.deviceStatusIcon }
                variant={ styleMapEntry.variant }/>
              <span className = { styleMapEntry.deviceStatusTextStyle }>
                { deviceStatusText }
              </span>
          </div>
        </div>
    </AWSUI.ColumnLayout>
  );
}

/**
 * Method to get display friendly text for device total wake counts
 * @param passRate - wake counts so stability
 * @returns Display friendly text
 */
export function getPassRateText(passRate) {
    // Undefined check is necessary here because if passRate is 0 then (!passRate) translates into true.
    if ( passRate === undefined ) {
        return AppConstants.UNAVAILABLE;
    }

    let styleMapEntry = LiveRunStyleConstants.PASS_RATE[passRate >= AppConstants.MAX_STABILITY_ALLOWED_PASS_RATE ? true : false];
    
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
            <div className={ styleMapEntry.wakeClass }>
                <span className = { styleMapEntry.wakeTextStyle }>
                  { passRate + '%' }
                </span>
            </div>
            <div>
              {
                (passRate < AppConstants.MAX_STABILITY_ALLOWED_PASS_RATE) && 
                (
                  <AWSUI.Tooltip text={ AppConstants.STABILITY_DEVICE_FAIL_MESSAGE } size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                )
             }
            </div>
            <div />
            <div />
          </div>
      </AWSUI.ColumnLayout>
    );
}

/**
 * Method to get Stability Last Measure timestamp Text to display
 * @param - payload - Stability payload 
 * @returns - loading message
 */
export function getStabilityTimeElapsedMessage(payload) {
    let runningMsg = '';
    let isTimeBoundUtterance = payload.timeBoundUtterance;
    if (isTimeBoundUtterance) {
      let devices = payload.simulationEnvironment.devices;
      let dsn = devices[0].deviceSerialNumber;
      let utteranceResult = payload.dsnToTimeBasedUtteranceResultMap[dsn];
      runningMsg = 'Elapsed Time: ' + utteranceResult.elapsedTime;
    }
    return getLoadingMessage(runningMsg);
}

/**
 * Method to get New Stability Live Run Table
 */
export function getStabilityLiveRunTable(state) {
    if (state &&
        state.testStats.hasOwnProperty('payload')) {
        let payload = state.testStats.payload;
        // Load the Stability data from payload
        let stabilityLiveUtteranceData = loadStabilityLiveUtteranceTableData(payload);
        let stabilityLiveSummaryData = loadStabilityLiveSummaryTableData(payload);

        let liveTableContainer = (
            <div>
            { getLoadingMessage('Live Utterance Result') }
            <div>
                <AWSUI.Table
                columnDefinitions={ STABILITY_LIVE_UTTERANCE_COLUMNS }
                items={ stabilityLiveUtteranceData }
                >
                </AWSUI.Table>
            </div>
            { getLoadingMessage('Ongoing Stability Test Summary') }
            <div>
                <AWSUI.Table
                columnDefinitions={ STABILITY_LIVE_SUMMARY_COLUMNS }
                items={ stabilityLiveSummaryData }
                >
                </AWSUI.Table>
            </div>
            { getStabilityTimeElapsedMessage(payload) }
            </div>
            )
        return liveTableContainer;
    }

    return getLoadingMessage(state.testStatus == AppConstants.RASPI_RESOURCES_SYNCING ?
        AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE : AppConstants.RETRIEVING_DATA_MESSAGE);
}