import React from "react";
import { logToConsole } from "../../Util";
import AWSUI from "@amzn/awsui-components-react";
import {
  getHotspotConfigs,
  convertStrToJson,
  getConnectedDevices,
} from "../Labs/controller";
import { PLUGIN_INSTALLED } from "../../_Constants/AQTHotspotConstants";
import { fetchRasPis } from "../RasPiTable/controller";
import Wifi from "@material-ui/icons/Wifi";
import "./HotspotOptions.css";

export default class HotspotOptions extends React.Component {
  constructor(props) {
    super(props);
    logToConsole("Hotspot Options", this.props);

    this.state = {
      isLoading: true, // should render loader on true
      isLoadingConnectedDevices: true, // should render loader while fetching connected devices
      locations: [], // list of hotspot-enabled locations
      showWifiHotspot: false, // should show wifi hotspot panel
      curLocation: null, // current raspi location
      connectedDevices: [], // list of connected devices for the current location
    };
  }

  componentDidMount() {
    this.getHotspotEnabledLocations();
  }

  /**
   * Fetches hotspot config for the raspi
   */
  getHotspots = async (rasPi) => {
    const companyId = rasPi.thingName.split('-').slice(0, 5).join('-');
    const rasPiName = rasPi.thingName.replace(companyId + '-', '');
    let response;
    try {
      response = await getHotspotConfigs(companyId, rasPiName);
    } catch (err) {
      logToConsole("Exception caught while fetching hotspot config", err);
    }
    if (response && 'status' in response && response.status === PLUGIN_INSTALLED) {
      logToConsole(
        "hotspot response: ",
        convertStrToJson(response.data.payload)
      );
      const hotspotConfig = convertStrToJson(response.data.payload);
      return Promise.resolve({
        id: rasPi.id,
        label: rasPiName,
        hotspotName: hotspotConfig.message.ssid,
        config: hotspotConfig.message,
        companyId,
      });
    } else {
      logToConsole("no data for hotspot config: ", response);
      return Promise.resolve(null);
    }
  };

  /**
   * fetches hotspot-enabled locations data
   */
  getHotspotEnabledLocations = async () => {
    let locations = [];
    fetchRasPis(this.props.labId).then(async (rasPis) => {
      const promises = rasPis.map(this.getHotspots);
      try {
        await Promise.all(promises).then((results) => {
          locations = results.filter(function (el) {
            return el != null;
          });
          logToConsole(" Completed lookup for hotspots in the lab", locations);
        });
        await this.fetchConnectedDevices(locations[0]); // fetches connected devices to the current hotspot-enabled location
      } catch (err) {
        logToConsole("Exception while fetching hotspot-enabled raspi locations", err);
      }
      this.setState({
        locations,
        isLoading: false,
        showWifiHotspot: locations && locations.length > 0,
        curLocation: locations && locations[0],
      });
    });
  };

  /**
   * handler to handle locations dropdown event
   * change
   */
  showHotspot = (event) => {
    this.setState({ isLoadingConnectedDevices: true });
    const newLocation = event.detail.selectedOption;
    const status =
      newLocation && newLocation.config && newLocation.config != null;
    logToConsole("updated location - hotspot detected: ", status);
    this.setState({ showWifiHotspot: status, curLocation: newLocation });
    this.fetchConnectedDevices(newLocation);
  };

  /**
   * Fetches connected devices to the hotspot
   */
  fetchConnectedDevices = async (location) => {
    let devices = [];

    // reset run test button to be disabled
    this.props.onDeviceOptionSelect(location, null);

    if (location && location.companyId && location.label) {
      logToConsole("Getting connected devices for location : ", location);
      let devicesResponse;
      try {
        devicesResponse = await getConnectedDevices(
          location.companyId,
          location.label
        );
      } catch (err) {
        logToConsole('Exception caught while fetching connected devices', err);
      }
      if (devicesResponse && 'data' in devicesResponse) {
        logToConsole('Connected Devices: ', devicesResponse.data);
        devicesResponse.data.forEach((device) => {
          if (device &&
            'name' in device &&
            'mac_addr' in device &&
            'ip' in device) {
            devices.push({
              label: device.name,
              macAddr: device.mac_addr,
              description: device.ip,
              value: device.ip,
            });
          }
        });
      }
    }
    this.setState({
      connectedDevices: devices,
      isLoading: false,
      isLoadingConnectedDevices: false,
    });
    logToConsole("Scanned devices => ", devices);
  };

  /**
   * handler to handle refresh button click
   * event
   */
  refreshWifi = (event) => {
    const { isLoadingConnectedDevices, curLocation } = this.state;
    if (!isLoadingConnectedDevices) {
      logToConsole("Refreshing connected devices");
      this.setState({ isLoadingConnectedDevices: true });
      this.fetchConnectedDevices(curLocation);
    }
  };

  /**
   * renders hotspot-enabled locations dropdown
   */
  renderLabLocationsDropdown = () => {
    const { isLoading } = this.state;
    const options = this.state.locations;
    const selectedOption = options[0];
    return (
      <div className="awsui-grid">
        <div className="awsui-row">
          <div className="col-6">
            <AWSUI.FormField
              label="Location"
              description="Raspberry pi location"
              secondaryControl={
                isLoading ? (
                  <div></div>
                ) : (
                    <AWSUI.Button
                      icon="refresh"
                      onClick={this.refreshWifi}
                    ></AWSUI.Button>
                  )
              }
            >
              <AWSUI.Select
                options={options}
                placeholder={isLoading ? "Loading locations" : ""}
                empty="No Locations"
                selectedOption={selectedOption}
                selectedLabel="Hotspot"
                statusType={isLoading ? "loading" : "finished"}
                loadingText="Detecting for hotspot-enabled locations in the Lab"
                loading={isLoading}
                onChange={this.showHotspot}
              ></AWSUI.Select>
            </AWSUI.FormField>
            <br />
          </div>
        </div>
      </div>
    );
  };

  /**
   * render hotspot panel
   */
  renderWifiHotspot = () => {
    const { curLocation, isLoadingConnectedDevices } = this.state;
    return (
      <div className="awsui-grid">
        <div className="awsui-row">
          <div className="col-4">
            <div className="awsui-util-font-size-1 awsui-util-label wifiName">
              <Wifi /> &nbsp; <b>{curLocation.hotspotName}</b>
            </div>
            <br />
            {isLoadingConnectedDevices
              ? this.renderLoader()
              : this.renderConnectedDevices()}
          </div>
        </div>
      </div>
    );
  };

  /**
   * handler to handle on connected device
   * change event
   */
  onDeviceOptionChange = (event) => {
    const { curLocation, connectedDevices } = this.state;
    logToConsole(event.detail.value);
    const selectedOption = connectedDevices.filter(function (el) {
      return el.value === event.detail.value;
    });
    logToConsole(selectedOption);
    if (selectedOption && selectedOption.length > 0) {
      this.props.onDeviceOptionSelect(curLocation, selectedOption[0]);
    }
  };

  /**
   * Renders connected devices to the hotspot
   * as radio options
   */
  renderConnectedDevices = () => {
    const { connectedDevices } = this.state;
    logToConsole(connectedDevices);
    logToConsole(connectedDevices && connectedDevices.length > 0);
    return (
      <div className="awsui-util-ml-m">
        <AWSUI.Tooltip text="Devices conneted to this Hotspot">
          <AWSUI.RadioGroup
            items={connectedDevices}
            onChange={this.onDeviceOptionChange}
            className="awsui-util-f-l"
          />
        </AWSUI.Tooltip >
      </div >
    );
  };

  /**
   * show loader on action
   */
  renderLoader = () => {
    return (
      <div className="awsui-util-t-c">
        <AWSUI.Spinner size="large" />
        <br />
        <div className="awsui-util-font-size-2">
          Scanning for Connected devices
        </div>
      </div>
    );
  };

  render() {
    const {
      isLoading,
      showWifiHotspot,
      connectedDevices,
      isLoadingConnectedDevices,
    } = this.state;
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Hotspot Options</h2>
          <div className="awsui-util-container-header-description">
            Connected devices
          </div>
        </div>
        <div>
          {this.renderLabLocationsDropdown()}
          {showWifiHotspot && this.renderWifiHotspot()}
          {/* TODO: Improve the below logic by handling the alerts inside its own render components*/}
          {showWifiHotspot || isLoading ? (
            <div></div>
          ) : (
              <AWSUI.Alert header="No Hotspot-enabled locations found">
                Hotspot must be configured for at least one location. Please
                navigate to the Labs page to set one up.
              </AWSUI.Alert>
            )}
          {!isLoading &&
            !isLoadingConnectedDevices &&
            showWifiHotspot &&
            (connectedDevices === null || connectedDevices.length < 1) ? (
              <AWSUI.Alert header="No devices found">
                Kindly configure your device to use the hotspot and re-scan for
                devices connected to this hotspot
              </AWSUI.Alert>
            ) : (
              <div></div>
            )}
        </div>
      </div>
    );
  }
}
