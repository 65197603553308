import { Wizard } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TestSuiteSelection from './testConfiguration';
import LabConfiguration from './labConfiguration';
import PreviewConfiguration from './previewConfiguration';
import { cancelTestCreation, submitTest } from '../../../../stores/newRun/acm/testCreationMiddleware';
import { uiStepSelector } from '../../../../stores/newRun/acm/uiSlice';
import { useHistory } from 'react-router-dom';
import { validateAndPass } from '../../../../stores/newRun/acm/validationHandlerMiddleware';


export default () => {
  const intl = useIntl();

  const history = useHistory();

  const {step, submit} = useSelector(uiStepSelector);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(submit) {
      history.push('/v2/run/create')
    }
  }, [submit])

  const steps = [
    {
      title: intl.formatMessage({id: "TEST_CONFIGURATION"}),
      content: () => <TestSuiteSelection />
    },
    {
      title: intl.formatMessage({id: "LAB_CONFIGURATION"}),
      content: () => <LabConfiguration />
    },
    {
      title: intl.formatMessage({id: "REVIEW_AND_CREATE"}),
      content: () => <PreviewConfiguration />
    }
  ]
  const i18nStrings = {
    stepNumberLabel: stepNumber => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
      `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: intl.formatMessage({id: "CANCEL"}),
    previousButton: intl.formatMessage({id: "PREVIOUS"}),
    nextButton: intl.formatMessage({id: "NEXT"}),
    submitButton: intl.formatMessage({id: "SUBMIT"}),
  }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(submitTest());
    history.push('/v2/run/');
  }

  const onNext = (e) => {
    const {requestedStepIndex} = e.detail;
    e.preventDefault();
    dispatch(validateAndPass(requestedStepIndex));

  }

  const onPrevious = (e) => {
    e.preventDefault();
    const {requestedStepIndex} = e.detail;
    dispatch(validateAndPass(requestedStepIndex));
  }

  const onWizardCancel = () => {
    dispatch(cancelTestCreation());
    history.push("/v2/run");
  }

  return (
    <Wizard
      steps={steps}
      activeStepIndex={step}
      i18nStrings={i18nStrings}
      onSubmitButtonClick={onSubmit}
      onPreviousButtonClick={onPrevious}
      onNextButtonClick={onNext}
      onStepNavigationClick={onNext}
      onCancelButtonClick={onWizardCancel}
    ></Wizard>

  );
}
