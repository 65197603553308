import React, { Component } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { getLoadingMessage, logToConsole } from '../../../Util';
import AppConstants from '_Constants/AppConstants';
import Containers from './Containers';
import { sleep } from '../../../Container/Labs/controller';
import { checkDeviceStatus } from './controller';
import ld, { isNil } from 'lodash';
import * as packet_capture from "./payload_v3";

class PacketCaptureAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgressTest: null,
      isLoading: false,
      deviceOFFCheck: 'PENDING',
      deviceONCheck: 'PENDING',
      criticalMsg: false
    };
  }

  /**
   * render loader message
   */
  renderLoadingMessage() {
    const { testStatus } = this.props;
    const loadingMessage = testStatus === AppConstants.RASPI_RESOURCES_SYNCING
      ? AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE
      : AppConstants.RETRIEVING_DATA_MESSAGE;
    return getLoadingMessage(loadingMessage);
  }

  /**
   * render download button
   * @param {*} s3DownloadURL
   */
  renderDownloadButton(s3DownloadURL) {
    if (!s3DownloadURL) return;
    return (
      <AWSUI.Button
        variant='primary'
        icon='download'
        target='_blank'
        href={s3DownloadURL}
      >
        {`Report`}
      </AWSUI.Button>
    );
  }

  /**
   * render header for each test section
   * @param {*} label
   * @param {*} status
   * @param {*} s3DownloadURL
   */
  getHeader = (label, status, s3DownloadURL) => {
    const classMap = {
      PENDING: 'awsui-util-status-negative',
      FAILED: 'awsui-util-status-negative',
      RUNNING: 'awsui-util-status-positive',
      INPROGRESS: 'awsui-util-status-positive',
      COMPLETED: 'awsui-util-status-positive',
    };
    const defaultClass = 'awsui-util-status-positive';

    const color = classMap[status] || defaultClass;

    return (
      <AWSUI.ColumnLayout columns={4} borders="none">
        <div className="awsui-util-mb-xs" data-awsui-column-layout-root="true">
          <h3 className="test-category-text-style">{label}</h3>
          <div />
          <div />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>
              <span>{`Status:`}</span>
              <span className={color}>{` ${status}`}</span>
            </div>
            <div>{this.renderDownloadButton(s3DownloadURL)}</div>
          </div>
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  handleChange = (type) => {
    return (event) => {
      this.setState({
        [type]: event.detail.expanded
      })
    };
  }

  renderTestDetails = (test) => {
    const { type } = test;
    const testdetailsContainerMap = {
      PROTOCOLS_INFO: Containers.ProtocolsInfo,
      TLS_VALIDATION: Containers.TLSValidation,
      CHIPHER_SUITE_VALIDATION: Containers.CipherSuitesDetection,
      AMAZON_ATS_CERT_VALIDATION: Containers.ATSCertDetection,
      DNS_ENDPOINTS_INFO: Containers.DNSEndpointsInfo,
      MDNS_ENDPOINTS_INFO: Containers.DNSEndpointsInfo,
      ENDPOINTS_BY_VOLUME: Containers.DNSEndpointsVolumeInfo,
      ENDPOINTS_BY_IP: Containers.DNSEndpointsByIP,
      UTTERANCE_VALIDATION: Containers.UtteranceValidationTest,
      PLAIN_TEXT_SECRETS_DETECTION: Containers.PlainTextSecrets,
      MITM_DETECTION: Containers.MITMDetection,
    };

    // If the test type isn't supported, log it
    if (!testdetailsContainerMap[type]) {
      logToConsole('Error', `Test type ${type} isn't supported`);
      return;
    }

    const TestDetailsContainer = testdetailsContainerMap[type];

    return (<TestDetailsContainer {...test} />);
  }

  renderTestSections(payload) {
    if (!payload || !payload.tests) return;

    const tests = payload.tests || [];
    return tests.map(test => {
      const { label, state = {}, types, s3DownloadURL } = test;
      const sections = Object.keys(types).map(type => {
        const testDetails = types[type];
        const status = testDetails.state.status;
        const isTestRunning = status && status === 'RUNNING';
        return (
          <AWSUI.ExpandableSection
            key={type}
            header={
              this.getHeader(
                testDetails.label || type,
                status,
                testDetails.s3DownloadURL
              )
            }
            variant="container"
            onChange={this.handleChange(type)}
            expanded={!!isTestRunning || !!this.state[type]}
          >
            {this.renderTestDetails(testDetails)}
          </AWSUI.ExpandableSection>
        )
      });
      return (
        <div className="awsui-util-container" key={label}>
          <div className="awsui-util-container-header">
            <h2>{this.getHeader(label, state.status, s3DownloadURL)}</h2>
          </div>
          <div>{sections}</div>
        </div>
      );
    });
  }


  /**
   * Render Device status check based on redis payloads
   * @param {*} payload
   */
  renderFeeds = (payload) => {

    const deviceONCheck = ld.get(payload, 'tests[0].data.deviceCheck.deviceONCheck', 'PENDING');
    const deviceOFFCheck = ld.get(payload, 'tests[0].data.deviceCheck.deviceOFFCheck', 'PENDING')
    const message = ld.get(payload, 'tests[0].data.deviceCheck.message', undefined)

    if (this.state.deviceOFFCheck !== deviceOFFCheck) {
      this.setState(
        {
          deviceOFFCheck,
          isLoading: deviceOFFCheck !== 'FAILED' && deviceOFFCheck !== 'COMPLETED',
          loadingMessage: message,
          criticalMsg: !isNil(message)
        }
      );
    }

    if (this.state.deviceONCheck !== deviceONCheck && this.state.deviceOFFCheck === 'COMPLETED') {
      this.setState(
        {
          deviceONCheck,
          isLoading: deviceONCheck !== 'FAILED' && deviceONCheck !== 'COMPLETED',
          loadingMessage: message,
          criticalMsg: !isNil(message)
        }
      );
    }


    if (deviceOFFCheck !== "COMPLETED") {
      return this.renderDeviceCheck(AppConstants.POWER_OFF_DESC, "OFF");
    } else if (deviceONCheck !== "COMPLETED") {
      return this.renderDeviceCheck(AppConstants.POWER_ON_DESC, "ON")
    } else {
      return this.renderTestSections(payload); // render test results sections
    }
  }

  /**
   * show loader
   */
  renderLoader = () => {
    return (
      <div className="awsui-util-t-c">
        <AWSUI.Spinner size="large" />
      </div>
    )
  }

  /**
   * device status check handler
   * send a msg to scenario requesting for device online status
   * @param {*} status
   */
  requestDeviceStatusCheck = status => async (event) => {
    const jobId = ld.get(this.props, 'testDetails.labJobId', '');
    console.log(event, status);
    const loadingMessage = "Detecting device state";
    this.setState({ isLoading: true, loadingMessage, criticalMsg: false });
    await checkDeviceStatus(jobId, "Security", { "requestDeviceState": status });
  }


  /**
   * render device status check on/off pane
   * @param {*} description
   * @param {*} status
   */
  renderDeviceCheck(description, status) {
    const { isLoading, loadingMessage, criticalMsg } = this.state;

    const header = ld.get(this.props.testStats, 'payload.tests[0].label', '');

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>{header}</h2>
        </div>
        <div className="awsui-util-t-c">
          <div className="awsui-util-pv-xxl">
            <h2>{description}</h2>
          </div>
          <div className="awsui-util-pv-l">
            {
              !isLoading ?
                (<AWSUI.Button
                  variant="primary"
                  onClick={this.requestDeviceStatusCheck(status)}>
                  Continue
                </AWSUI.Button>) :
                this.renderLoader()
            }
          </div>
          <div className={
            !criticalMsg ? "awsui-util-status-info" : "awsui-util-status-negative"
          }>
            {loadingMessage}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { payload } = this.props.testStats || {};

    if (!payload) return this.renderLoadingMessage();

    return (
      <div>
        <div className='awsui-util-mt-xl awsui-util-mb-xl awsui-util-ml-xl awsui-util-mr-xl'>
          <div>
            {this.renderFeeds(payload)}
            {/*{this.renderFeeds(packet_capture.default)}*/}
          </div>
        </div>
      </div>
    );
  }
}

export default PacketCaptureAnalysis;
