/**
  * This file contains helper methods to generate Custom Options to be sent to Scenario
  */
 import AppConstants from '../../_Constants/AppConstants';
 import CustomOptionsConstants from '../../_Constants/CustomOptionsConstants';
 import { getNoiseLocation } from './Util';
 import { logToConsole } from '../../Util';

  /**
   * Method to generate Custom options for a given scenario & test types for non-Customized test
   * @param testSuite Test Suite
   * @param scenarioType Scenario Type
   * @param testType Test type
   * @returns custom options map
   */
  export function generateCustomOptions(testSuite, scenarioType, testType, trainersList) {
    let customOptions = {};
    let schemeDefList = [];
    if (scenarioType && testType) {
      let locationMap = getCustomOptionsMap(scenarioType);
      let noiseTypes = getNoiseTypes(scenarioType);
      let noiseLocation = getNoiseLocation(testSuite);
      Object.keys(locationMap).forEach(i => {
        if (locationMap[i].display !== noiseLocation
              && locationMap[i].display !== AppConstants.NOT_APPLICABLE) {
          // Only for CT HATS location, we want to send it as 0.9 m 90 deg in
          // Custom options
          if (locationMap[i].display === AppConstants.CT_HATS_LOCATION) {
            locationMap[i].display = AppConstants.LOCATION_THREE_NINETY;
          }
          let actorMap = {};
          let actorValues = [];
          for (var j = 0; j < noiseTypes.length; j++) {
            let noiseMap = {};
            let noiseValue = [];
            if (scenarioType === AppConstants.VOICE_ROBUSTNESS) {
              if (noiseTypes[j] !== AppConstants.NOISE_TYPE_SILENCE) {
                noiseValue = AppConstants.NOISE_LEVELS_MOBILE_VOICE_ROBUSTNESS;
              }
            } else {
              noiseValue.push(getUtteranceDirectoryName(scenarioType, testType, locationMap[i].display));
            }
            if (scenarioType === AppConstants.TRAINED_MOBILE && noiseTypes[j] === AppConstants.NOISE_TYPE_SILENCE) {
              noiseValue.push(CustomOptionsConstants.IAR_DIRECTORY + getTestTypeForIARDirectory(testType));
            }
            noiseMap[noiseTypes[j]] = noiseValue;
            actorValues.push(noiseMap);
          }
          actorMap[locationMap[i].display] = actorValues;
          schemeDefList.push(actorMap);
        }
      });
      customOptions[CustomOptionsConstants.SCHEME_DEF_KEY] = schemeDefList;
    }
    if ((scenarioType === AppConstants.TRAINED_MOBILE || scenarioType === AppConstants.VOICE_ROBUSTNESS ) && trainersList && trainersList.length > 0
          && schemeDefList && schemeDefList.length > 0) {
      customOptions[CustomOptionsConstants.MOBILE_TRAINERS_KEY] = trainersList;
    }
    return customOptions;
  }

  /**
   * Method to get Custom options for customized test
   * @param event Event whether checkbox is checked or not
   * @param actor Location
   * @param noise Noise Type (For Mobile voice robustness noise is list of selected noises)
   * @param scenarioType Scenario Type
   * @param testType Test Type
   * @param customOptions Current value of custom options
   * @param trainersList List of trainers for Mobile Voice Training scenario
   * @return Custom options based on what checkboxes are marked as checked
   */
  export function generateCustomOptionsForCustomized(event, actor, noise, scenarioType, testType,
      customOptions, trainersList) {
    let currCustomOptions = Object.assign({}, customOptions);
    let schemeDefList = [];
    if ( scenarioType === AppConstants.VOICE_ROBUSTNESS ) {
      schemeDefList = updateCustomOptionsForMultiSelection(actor, noise, currCustomOptions);
      if (schemeDefList.length === 0) {
        currCustomOptions= {};
      } else {
        currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY] = schemeDefList;
      }
    } else {
      if (event.detail.checked) {
        currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY] = updateCustomOptionsChecked(actor, noise, scenarioType,
          testType, currCustomOptions, schemeDefList);
      } else {
        let schemeDefList = updateCustomOptionsUnchecked(actor, noise, currCustomOptions);
        if (schemeDefList.length === 0) {
          currCustomOptions = {};
        } else {
          currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY] = schemeDefList;
        }
      }
    }
    if ((scenarioType === AppConstants.TRAINED_MOBILE || scenarioType === AppConstants.VOICE_ROBUSTNESS) && trainersList && trainersList.length > 0
          && schemeDefList && schemeDefList.length > 0) {
      currCustomOptions[CustomOptionsConstants.MOBILE_TRAINERS_KEY] = trainersList;
    }
    return currCustomOptions;
  }

  /**
   * Gets true or false value for current custom options checkbox
   * @param customOptionValue Current value for Custom Options
   * @param noise Current noise type
   * @return true or false value for current checkbox
   */
  export function getValueForCheckbox(customOptionValues, noise) {
    var checked = false;
    if (customOptionValues) {
      for (var i = 0; i < customOptionValues.length; i++) {
        let currentNoiseType = Object.keys(customOptionValues[i])[0];
        if (currentNoiseType === noise) {
          checked = true;
          break;
        }
      }
    }
    return checked;
  }

  /**
   * Gets custom options sorted based on specific order of noise types
   * @param customOptions Current value for custopm options
   * @param scenarioType Scenario type
   * @param customizedTestType Type of Customized test --> Standard, Quick or Demo
   * @returns Custom options sorted based on noise types
   */
  export function sortCustomOptions(customOptions, scenarioType, customizedTestType) {
    let noiseTypesList = getNoiseTypes(scenarioType);
    let schemeDefList = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
    let sortedSchemeDefList = [];
    if (schemeDefList && schemeDefList.length > 0) {
      for (var j = 0; j < schemeDefList.length; j++) {
        let currentActorMap = schemeDefList[j];
        let currentActor = Object.keys(currentActorMap)[0];
        let currentNoiseList = schemeDefList[j][currentActor];
        var ordering = {}; // map for efficient lookup of sortIndex
        for(var i = 0; i < noiseTypesList.length; i++) {
          ordering[noiseTypesList[i]] = i;
        }
        let sortedNoiseList  = currentNoiseList.sort( function(a, b) {
          return (ordering[Object.keys(a)[0]] - ordering[Object.keys(b)[0]]);
        });
        // Set utterance directory for Custom options based on type of Customized test
        if ( scenarioType !== AppConstants.VOICE_ROBUSTNESS) {
          let utteranceDirectory =
            CustomOptionsConstants.UTTERANCE_DIRECTORY_MAP[scenarioType][customizedTestType][currentActor];
          let customSubTestType = customizedTestType;

          if (customizedTestType.includes('_')){
            customSubTestType = customizedTestType.split('_')[1];
            customSubTestType = customSubTestType.charAt(0) + customSubTestType.slice(1).toLowerCase()
          }
          for (var k = 0; k < sortedNoiseList.length; k++) {
            let currentNoiseEntry = sortedNoiseList[k];
            let currentNoise = Object.keys(currentNoiseEntry)[0];
            currentNoiseEntry[currentNoise][0] = utteranceDirectory;
            // Append IAR directory if it's Mobile Voice Training scenario + Silence
            if (scenarioType === AppConstants.TRAINED_MOBILE && currentNoise === AppConstants.NOISE_TYPE_SILENCE) {
              currentNoiseEntry[currentNoise][1] = CustomOptionsConstants.IAR_DIRECTORY + customSubTestType;
            }
            sortedNoiseList[k] = currentNoiseEntry;
          }
        }
        currentActorMap[currentActor] = sortedNoiseList;
        sortedSchemeDefList.push(currentActorMap);
      }
    }
    if (schemeDefList) {
      customOptions[CustomOptionsConstants.SCHEME_DEF_KEY] = sortedSchemeDefList;
    }
    // Append Customized test type in custom options
    customOptions['customizedTestType'] = customizedTestType;
    return customOptions;
  }

  /**
   * Method to maintain backward compatibility to keep old custom options for a customized test as it is
   * @param customOptions Custom options map
   * @returns Modified custom options map appended with old custom option values
   */
  export function appendOldCustomOptions(customOptions) {
    if (customOptions && Object.keys(customOptions).length > 0) {
      Object.keys(CustomOptionsConstants.REVERSE_ACTOR_MAP).forEach(i => {
        let currentActorKey = CustomOptionsConstants.REVERSE_ACTOR_MAP[i];
        if (customOptions.hasOwnProperty(currentActorKey)) {
          delete customOptions[currentActorKey];
        }
      });
      let schemeDefList = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
      if (schemeDefList && schemeDefList.length > 0) {
        for (var j = 0; j < schemeDefList.length; j++) {
          let currentActorMap = schemeDefList[j];
          let currentActor = Object.keys(currentActorMap)[0];
          let currentNoiseList = schemeDefList[j][currentActor];
          let newNoiseList = [];
          for (var k = 0; k < currentNoiseList.length; k++) {
            let currentNoiseValue = Object.keys(currentNoiseList[k])[0];
            let newNoiseValue = CustomOptionsConstants.REVERSE_NOISE_MAP[currentNoiseValue];
            newNoiseList.push(newNoiseValue);
          }
          let newActor = CustomOptionsConstants.REVERSE_ACTOR_MAP[currentActor];
          customOptions[newActor] = newNoiseList;
        }
      }
    }
    return customOptions;
  }

  /**
   * Gets noise values list for a specific actor
   * @param customOptions Custom options map
   * @param actor Actor to look up
   * @returns List of noise values for actor
   */
  export function getNoiseValuesForActor(customOptions, actor) {
    let schemeDefList = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
    let noiseValues = [];
    for (var i = 0; schemeDefList && i < schemeDefList.length; i++) {
      let currentActor = Object.keys(schemeDefList[i])[0];
      if(currentActor === actor) {
        noiseValues = schemeDefList[i][actor];
        break;
      }
    }
    return noiseValues;
  }

  /**
   * Updates current custom options when new noise type is checked in UI
   * @param actor Current actor
   * @param noise Noise type needs to be checked
   * @param scenarioType Scenario type
   * @param testType Test type
   * @param currCustomOptions Current custom options
   * @param schemeDefList Value for schemeDef in current custom options
   * @returns Updated schemeDef list
   */
  function updateCustomOptionsChecked(actor, noise, scenarioType, testType, currCustomOptions,
    schemeDefList) {
      let actorValues = [];
      let actorIndex = -1;
      if (Object.keys(currCustomOptions).length > 0) {
        schemeDefList = currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
        if (!schemeDefList) {
          schemeDefList = [];
        }
        for (var i = 0; schemeDefList && i < schemeDefList.length; i++) {
          let currentActor = Object.keys(schemeDefList[i])[0];
          if(currentActor === actor) {
            actorIndex = i;
            actorValues = schemeDefList[i][actor];
            break;
          }
        }
      }
      let actorMap = {};
      let noiseMap = {};
      let noiseValue = [];
      noiseValue.push(getUtteranceDirectoryName(scenarioType, testType, actor));
      if (scenarioType === AppConstants.TRAINED_MOBILE && noise === AppConstants.NOISE_TYPE_SILENCE) {
        noiseValue.push(CustomOptionsConstants.IAR_DIRECTORY + getTestTypeForIARDirectory(testType));
      }
      noiseMap[noise] = noiseValue;
      if (actorIndex === -1) {
        actorValues.push(noiseMap);
        actorMap[actor] = actorValues;
        schemeDefList.push(actorMap);
      } else {
        schemeDefList.splice(actorIndex, 1);
        actorValues.push(noiseMap);
        actorMap[actor] = actorValues;
        schemeDefList.push(actorMap);
      }
    return schemeDefList;
  }

  /**
   * Updates current custom options when noise type is unchecked in UI
   * @param actor Current actor
   * @param noise Noise type to uncheck
   * @param currCustomOptions Current custom options
   * @returns Updated schemeDef list
   */
  function updateCustomOptionsUnchecked(actor, noise, currCustomOptions) {
    let schemeDefList = [];
    if (Object.keys(currCustomOptions).length > 0) {
      let actorValues = [];
      let actorIndex = -1;
      let actorMap = {};
      schemeDefList = currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
      for (var i = 0; schemeDefList && i < schemeDefList.length; i++) {
        let currentActor = Object.keys(schemeDefList[i])[0];
        if(currentActor === actor) {
          actorIndex = i;
          actorValues = schemeDefList[i][actor];
          break;
        }
      }
      for (var j = 0;  j < actorValues.length; j++) {
        let currentNoiseType = Object.keys(actorValues[j])[0];
        if (currentNoiseType === noise) {
          actorValues.splice(j,1);
          break;
        }
      }
      schemeDefList.splice(actorIndex, 1);
      if (actorValues.length > 0) {
        actorMap[actor] = actorValues;
        schemeDefList.push(actorMap);
      }
    }
    return schemeDefList;
  }

/**
 * Updates current custom options when noise type is selected or unselected in UI
 * @param actor Current actor
 * @param noiseTypeList for selected noises with noise levels
 * @param currCustomOptions Current custom options
 * @returns Updated schemeDef list
 */
  function updateCustomOptionsForMultiSelection(actor, noiseTypeList, currCustomOptions){
    let schemeDefList = [];
    if (Object.keys(currCustomOptions).length > 0) {
      schemeDefList = currCustomOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
      let actorIndex;
      for (let i = 0; schemeDefList && i < schemeDefList.length; i++) {
        let currentActor = Object.keys(schemeDefList[i])[0];
        if (currentActor === actor) {
          actorIndex = i;
          break;
        }
      }
      schemeDefList.splice(actorIndex, 1);
    }
    let actorMap = {};
    let noiseVolumes = {};
    for (const noiseVal of noiseTypeList){
      // Split the noise and volume details
      let noiseName = noiseVal.substring(0, noiseVal.indexOf(" ("));
      // For volumeless noise (silence, for example) use full name
      if (!noiseName) {
        noiseName = noiseVal;
      }
      let volStr = noiseVal.substring(noiseVal.indexOf("(")+1, noiseVal.indexOf(")"));
      // Add noise to list, if it doesn't already exist
      if(!noiseVolumes[noiseName]) {
        logToConsole("volStr is empty for " + noiseVal);
        noiseVolumes[noiseName] = [];
      }
      // Add volume, if applicable
      if(volStr) {
        logToConsole("should have pushed " + volStr + " to " + noiseName);
        noiseVolumes[noiseName].push(volStr);
      }
    }

    let actorValues = []
    for (const key in noiseVolumes){
      const map = {};
      map[key] = noiseVolumes[key];
      actorValues.push(map);
    }
    actorMap[actor] = actorValues;
    schemeDefList.push(actorMap);
    return schemeDefList;
  }

  /**
   * Method to get location map based  on scenario type
   * @param scenarioType Scenario type
   * @return Returns location map for scenario type
   */
  function getCustomOptionsMap(scenarioType) {
    if (scenarioType === AppConstants.NEARFIELD) {
      return AppConstants.nearFieldLocation;
    } else if (scenarioType === AppConstants.TRAINED_MOBILE
      || scenarioType === AppConstants.VOICE_ROBUSTNESS
      || scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
      return AppConstants.trainedMobileLocation;
    } else if (scenarioType === AppConstants.CT_WAKE_AND_RESPONSE) {
      return AppConstants.closeTalkLocation;
    } else if (scenarioType === AppConstants.FARFIELD){
      return AppConstants.farFieldLocation;
    } else if (AppConstants.AUTOMOTIVE_TEST_TYPES.includes(scenarioType)) {
      return AppConstants.automotiveCompanionLocation;
    } else {
      logToConsole("Scenario Type" + scenarioType + "May not be supported");
      return null;
    }
  }

  /**
   * Method to get Noise types based on scenario
   * @param scenarioType Type of scenario
   * @returns List of noise types for a scenario
   */
  function getNoiseTypes(scenarioType) {
    if (scenarioType === AppConstants.TRAINED_MOBILE) {
      return AppConstants.NOISE_TYPES_MOBILE_VOICE_TRAINING;
    } else if (scenarioType === AppConstants.VOICE_ROBUSTNESS) {
      return AppConstants.NOISE_TYPES_MOBILE_VOICE_ROBUSTNESS;
    } else if (scenarioType === AppConstants.CT_WAKE_AND_RESPONSE) {
      return AppConstants.NOISE_TYPES_CLOSE_TALK;
    } else if (AppConstants.AUTOMOTIVE_TEST_TYPES.includes(scenarioType)) {
      return AppConstants.NOISE_TYPES_AUTOMOTIVE;
    }
    return AppConstants.NOISE_TYPES;
  }

  /**
   * Method to get directory name to play utterances from
   * @param scenarioType Scenario Type
   * @param testType Test Type
   * @param actor Location name
   * @return Name of directory to play utterances from
   */
  function getUtteranceDirectoryName(scenarioType, testType, actor) {
    let testTypeMap = CustomOptionsConstants.UTTERANCE_DIRECTORY_MAP[scenarioType];
    if (testTypeMap) {
      let actorMap = testTypeMap[testType];
      if (actorMap) {
        return actorMap[actor];
      }
    }
    return AppConstants.EMPTY;
  }

  /**
   * Returns Test type name for IAR & RSR directory for trained mobile
   * @param testType Test type
   * @return Test type name to generate IAR & RSR directory name
   */
  function getTestTypeForIARDirectory(testType) {
    if (testType) {
      if (testType === AppConstants.STANDARD || testType === AppConstants.CUSTOMIZED) {
        return AppConstants.STANDARD.charAt(0) + AppConstants.STANDARD.slice(1).toLowerCase();
      }
      return testType.charAt(0) + testType.slice(1).toLowerCase();
    }
    return AppConstants.EMPTY;
  }
