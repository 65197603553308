export const STABILITY_LIVE_SUMMARY_COLUMNS = [{
  id: 'utterance',
  header: () => 'Utterance',
  cell: item => item.utterance,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'totalAttempts',
  header: () => 'Total Attempts',
  cell: item => item.totalAttempts,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'passCount',
  header: () => 'Pass Count',
  cell: item => item.passCount,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'failCount',
  header: () => 'Fail Count',
  cell: item => item.failCount,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'offlineCount',
  header: () => 'Offline Count',
  cell: item => item.offlineCount,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
},  {
  id: 'passRate',
  header: () => 'Pass Rate',
  cell: item => item.passRate,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];