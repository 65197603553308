import AppConstants from '_Constants/AppConstants';

/**
 * Get allowed actors for each scenario
 * @param {object} scenario has all test specific information
 */
export function getAllowedActors(scenario) {
  const { qual, scenarioType, testSuite, testType } = scenario;

  const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
  const category = testSuiteInfo.CLASSIFICATION[scenarioType];

  const scenarioSPLMapping = {
    SILENCE: [],
    DEVICE_PLAYBACK: [{
      type: 'DUT',
      key: 'DUT',
      /**
       * For calibrating DUT, we need a speaker to play the utterance
       * Admin utterances are played using this default speech speaker
       * This location will be used for both starting and stopping the calibration
       */
      location: testSuiteInfo.DEFAULT_SPEECH_LOCATION
    }],
    NOISE: [{
      type: 'Noise',
      key: 'Noise',
      location: testSuiteInfo.DEFAULT_NOISE_LOCATION
    }],
  };

  const { DEFAULT_SPEECH_LOCATION } = testSuiteInfo;

  let speechRaspiLocations = [DEFAULT_SPEECH_LOCATION];

  if (testType === AppConstants.CUSTOMIZED) {
    const locations = qual.locations || {};
    speechRaspiLocations = Object.keys(locations).filter(loc => locations[loc]);
  }

  if (!scenarioSPLMapping[category]) return [];

  speechRaspiLocations.forEach(location => {
    scenarioSPLMapping[category].push({
      type: 'Speech',
      key: `Speech-${location}`,
      location
    })
  });

  return scenarioSPLMapping[category];
}

/**
 * Checks if spl options are selected and report errors
 * @param {object} scenario has all test specific information
 * @param {function} saveScenarioState will save the state of the updated scenario
 */
export function isValidQualTestOptions(scenario, saveScenarioState) {
  const { qual = {}, testSuite, scenarioType } = scenario;

  // exit if it isn't qual or scenario type is WWDD (No SPL settings)
  if (testSuite !== AppConstants.QUAL_SCENARIO_ID || scenarioType === 'QUAL_WWDD') return true;

  const actors = getAllowedActors(scenario);

  if (!actors || actors.length === 0) return false;

  const newSPL = {
    ...qual.spl,
  };

  let isValid = true;
  actors.forEach(({ key }) => {
    if (!newSPL[key]) {
      newSPL[key] = {
        error: AppConstants.DEFAULT_DROPDOWN_ERROR
      };
      isValid = false;
    }
  });

  saveScenarioState({
    ...scenario,
    qual: {
      ...qual,
      spl: newSPL
    }
  });

  return isValid;
}

export default {
  getAllowedActors,
  isValidQualTestOptions,
}
