/**
 * Component to render Mobile metrics (currently just the voice enrollment robustness test case)
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import { isObject, has } from 'lodash';
import { getTextOrLoading, getLoadingMessage, getTime, getTimeForDisplay, renderLabelElement } from '../../../Util';

/**
 * Component to render Mobile test metrics
 */
class MobileTestMetrics extends React.Component {

  componentWillMount() {
    this.renderMobileVERobustnessTestMetrics();
  }

  /**
   * Calculate and generate display values
   */
  enrollmentFeedDisplayVal = () => {

    let eFeed = isObject(this.props.params.testStats.enrollmentFeed) ?
      this.props.params.testStats.enrollmentFeed : {};

    // Initialize display variable and assign pre-defined values
    let displayVal = {};
    displayVal.currentTrainedVoice = has(eFeed, 'currentTrainedVoice') ? eFeed.currentTrainedVoice : AppConstants.EMPTY;
    displayVal.activeNoise = has(eFeed, 'activeNoise') ? eFeed.activeNoise : AppConstants.EMPTY;
    displayVal.startTime = has(eFeed, 'startTime') ? getTimeForDisplay(eFeed.startTime) : AppConstants.EMPTY;
    displayVal.elapsedTime = has(eFeed, 'elapsedTime') ? eFeed.elapsedTime : AppConstants.EMPTY;

    // Calculations
    let completedCount = has(eFeed, 'completedCount') ? eFeed.completedCount : [];
    let totalNum = has(eFeed, 'totalNum') ? eFeed.totalNum : [];
    let completedPercentage = has(eFeed, 'completedPercentage') ? eFeed.completedPercentage : [];

    if (completedCount && totalNum && completedPercentage) {
      displayVal.countDisplay = completedCount.toString() + " / " + totalNum.toString() + " (" + completedPercentage + "%)";
      if (completedCount > 0) {
        let elapsedH = parseInt(String(displayVal.elapsedTime).replace(/[0-9]*\smin.*/, ''));
        let elapsedM = parseInt(String(displayVal.elapsedTime).replace(/[0-9]*\sh,\s/, ''));
        let totalMin = elapsedM;
        if (!isNaN(elapsedH)) {
          totalMin += (elapsedH * 60)
        }
        let avgTrainingDurationSec = Math.round((totalMin * 60) / completedCount);
        let minRemaining = Math.round((totalNum - completedCount) * avgTrainingDurationSec / 60);
        let minPluralization = (minRemaining != 1) ? "s" : AppConstants.EMPTY;
        displayVal.eta = String(minRemaining) + " minute" + minPluralization;
      }
    } else if (totalNum){
      displayVal.countDisplay = "0 / " + totalNum.toString() + " (0%)";
    }

    return displayVal;
  };


  /**
   * Renders test metrics feed for Voice Enrollment Robustness
   */
  renderMobileVERobustnessTestMetrics = () => {
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let countDisplay, startTime, currentTrainedVoice, elapsedTime, activeNoise, eta;
    let testIsComplete = false;
    if (isDataAvailable) {
      countDisplay = this.enrollmentFeedDisplayVal().countDisplay;
      startTime = this.enrollmentFeedDisplayVal().startTime;
      currentTrainedVoice = this.enrollmentFeedDisplayVal().currentTrainedVoice;
      elapsedTime = this.enrollmentFeedDisplayVal().elapsedTime;
      activeNoise = this.enrollmentFeedDisplayVal().activeNoise;
      eta = this.enrollmentFeedDisplayVal().eta;
      testIsComplete = has(this.props.params.testStats, 'timeRemaining') ? this.props.params.testStats.timeRemaining == 'Finished' : false;
    }

    if (testIsComplete) {
      return (getLoadingMessage(AppConstants.TEST_COMPLETED_MESSAGE));
    } else {
      return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
          <AWSUI.ColumnLayout columns={ 4 } borders='all'>
            <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
              { renderLabelElement('Training Round ', '(Completed / Total)') }
              { getTextOrLoading(countDisplay) }
              { renderLabelElement('Start Time', AppConstants.EMPTY) }
              { getTextOrLoading(startTime) }
              { renderLabelElement('Current Trainer ', AppConstants.EMPTY) }
              { getTextOrLoading(currentTrainedVoice) }
              { renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
              { getTextOrLoading(elapsedTime) }
              { renderLabelElement('Background Noise', AppConstants.EMPTY) }
              { getTextOrLoading(activeNoise) }
              { renderLabelElement('ETA ', '(Total Estimated Time Remaining)') }
              { getTextOrLoading(eta) }
            </div>
          </AWSUI.ColumnLayout>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {
          this.renderMobileVERobustnessTestMetrics()
        }
      </div>
    );
  }
}

export default MobileTestMetrics;
