import React from 'react';
import { isEmpty } from 'lodash';
import CustomizeOptionsSubPage from '../CustomizeOptionsSubPage';
import AppConstants from '../../../_Constants/AppConstants';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';

/**
 * Use for acoustic Near Field and Far Field tests
 */
class GeneralAcousticTestTypeSelection extends React.Component {

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack, piLocations } = this.props.params;
    return (
      <div>
        <TestTypeScenarioOptions
          allowedTypes={[0, 1, 2, 3]}
          showDelayConfig={AppConstants.CUSTOMIZED === selectedScenario.testType}
          showAdminValidation={AppConstants.CUSTOMIZED === selectedScenario.testType}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
          {/* These components are specific to Acoustic NearField/FarField test */}
          {!isEmpty(selectedScenario) &&
            (<CustomizeOptionsSubPage params={{
              selectedScenario: selectedScenario,
              selectedScenarioCallBack: selectedScenarioCallBack,
              piLocations: piLocations,
              selectedScenarioError: selectedScenarioError,
              scenarioErrorCallBack: scenarioErrorCallBack
            }} />)
          }
        </TestTypeScenarioOptions>
      </div>
    );
  }
}

export default GeneralAcousticTestTypeSelection;
