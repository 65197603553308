import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ld from 'lodash';
import { sendMQTTMessage } from '../../../Container/Labs/controller';

/**
 * send mqtt message to scenario
 * @param {*} scenarioInstanceId
 * @param {*} scenarioType
 * @param {*} payload
 */
export function checkDeviceStatus(scenarioInstanceId, scenarioType, payload) {
  // hardcoding the wait time to 3000 since the
  // response of the device online status would
  // be updated on the redis
  return sendMQTTMessage(scenarioInstanceId, scenarioType, payload, 3000)
    .then(response => {
      console.log(response);
      return new Promise(resolve => {
        resolve(true);
      });
    });
}
