import React from "react";
import {
  StatusIndicator,
  ExpandableSection,
  CodeBlock
} from '@amzn/awsui-components-react';

const MAX_CSV_FIELD_SIZE = 10
export const validationErrorType = {
  CSV_HEADER_INVALID: "Invalid header",
  CSV_NO_ENTRIES_FOUND: "File is empty",
  CSV_TRANSCRIPT_ERROR: "Transcript is invalid",
  CSV_FILENAME_ERROR: "Transcript is invalid",
  CSV_VALIDATION_ERROR: "CSV_VALIDATION_ERROR",
  CSV_MINIMUM_FIELDS_ERROR: "Mandatory fields are missing",
  CSV_MAXIMUM_FIELDS_ERROR: "Number of fields should not be more than ",
  CSV_MAX_FILE_SIZE: "File too Big, please select a file less than 4MB"
}

export function validateTranscriptsCsv(parseResult, file) {
  const validationResult = {
    errorMessage: [],
    data: null,
    isSuccess: true
  }
  // File size Validation
  if (file.size === 0) {
    validationResult.errorMessage.push({line: 1, errorType: validationErrorType['CSV_NO_ENTRIES_FOUND']})
    validationResult.isSuccess = false
  } else if (Math.round((file.size / 1024)) >= 4096) {
    validationResult.errorMessage.push({line: 1, errorType: validationErrorType['CSV_MAX_FILE_SIZE']})
    validationResult.isSuccess = false
  }

  // Data Validation
  if (parseResult) {
    const parsedData = parseResult.data
      .map
      ((row, index) => {
        if (!Array.isArray(row)) {
          validationResult.errorMessage.push({line: index + 1, errorType: validationErrorType.CSV_VALIDATION_ERROR})
          validationResult.isSuccess = false
        }
        if (row.length === 0) {
          validationResult.errorMessage.push({line: index + 1, errorType: validationErrorType.CSV_MINIMUM_FIELDS_ERROR})
          validationResult.isSuccess = false
        }
        if (row.length > MAX_CSV_FIELD_SIZE) {
          validationResult.errorMessage.push({
            line: index + 1, errorType: validationErrorType.CSV_MAXIMUM_FIELDS_ERROR + MAX_CSV_FIELD_SIZE
          })
          validationResult.isSuccess = false
        }
      });
    validationResult.data = parseResult.data;
    return validationResult;
  }
}

export function displayValidationResult(file, validationResults, errorMessage) {
  const results = validationResults && validationResults.length;

  const filename = (file && file.name) || "Unknown File";
  return (
    (results.errorMessage || results.state !== "SUCCESS") &&
    (
      <div>
        <div id="statusIndicatorSuccess">
          <StatusIndicator
            type="error"
            message={results.errorMessage[0]}
          />
          &nbsp;
          {filename}
        </div>
        <ExpandableSection
          header= "Error Records"
        >
          <CodeBlock
            readOnly={true}
            code={"Populate error results here"}
            rows={Math.min(results.length + 1, 50)}
          />
        </ExpandableSection>
      </div>
    )
  );
}
