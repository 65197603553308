import AppConstants from "../../../_Constants/AppConstants";
import ApiConstants from '../../../_Constants/ApiConstants';
/**
 * Decide for runExperiments API, go through v1 or v2 API
 * v2 API will automatically update pi software and resource in prod
 * in alpha, beta, v1, v2 are the same state machine
 * @param {*} isAutoSyncEnabled
 * @param {*} testSuite
 */
export function getExperimentPrefix(isAutoSyncEnabled, testSuite) {
  let returnAPI = ApiConstants.EXPERIMENT_V1;
  if (enableV2Api(isAutoSyncEnabled, testSuite)) {
    returnAPI = ApiConstants.EXPERIMENT_V2;
  }
  return returnAPI;
}

/**
 * Decide for runExperiments API, which stateMachineType it is.
 * Based on state machines defined below
 * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationService/blobs/d7c0047e69d39a09895265fe02baa6f50d27569f/--/src/config/config.js#L55
 * @param {*} isAutoSyncEnabled
 * @param {*} testSuite
 */
 export function getStateMachineType(isAutoSyncEnabled, testSuite) {
  let stateMachineType = "PhysicalMDXSimulationStateMachineArn"; // By default go through v1 state machine
  if (enableV2Api(isAutoSyncEnabled, testSuite)) {
      // when auto resource sync is needed, go through v2 state machine.
    stateMachineType = "PhysicalMDXSimulationStateMachineV2Arn";
  }
  return stateMachineType;
}

function enableV2Api(isAutoSyncEnabled, testSuite) {
  if ((testSuite === AppConstants.TEST_SUITES.ACOUSTIC.ID
    || testSuite === AppConstants.TEST_SUITES.CLOSE_TALK.ID
    || testSuite === AppConstants.TEST_SUITES.MUSIC.ID
    || testSuite === AppConstants.TEST_SUITES.FUNCTIONAL.ID
    || testSuite === AppConstants.TEST_SUITES.AUTO_LOCAL_SEARCH.ID
    || testSuite === AppConstants.TEST_SUITES.AVS_SECURITY.ID
    || testSuite === AppConstants.TEST_SUITES.STABILITY.ID
    || testSuite === AppConstants.TEST_SUITES.QUAL.ID
    || testSuite === AppConstants.TEST_SUITES.UPL.ID
    || testSuite === AppConstants.TEST_SUITES.MOBILE.ID
    || testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID
    || testSuite === AppConstants.TEST_SUITES.AUTOMOTIVE.ID)
    && isAutoSyncEnabled) {
    return true;
  }
   return false;
}
