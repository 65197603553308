import AppConstants from '../../_Constants/AppConstants';

export const LABCONTROL_DEFINITION = [{
    'text': 'Ping All Pis',
    'id': AppConstants.rasPiAction.STATE.id,
    'disabled': false
  }, {
    'text': 'Stop Audio',
    'id': AppConstants.rasPiAction.STOP.id,
    'disabled': false
  }, {
    'text': 'Update Software',
    'id': AppConstants.rasPiAction.UPDATE.id,
    'disabled': false
}];
  