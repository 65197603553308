import AppConstants from './AppConstants';
import { getStage } from 'newSystem/services/utils';
import { STAGE_TYPES } from 'newSystem/constants';

const ALL_LOCALES = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE]

const FAR_PLUS_LOCALE = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE_FAR_PLUS]

 const FAR_PLUS_LOCALE_PROD = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE_FAR_PLUS_PROD]

const MUSIC_SKILLS_LOCALES = [{
    id: 'all',
    label: 'Select/Deselect All',
 },
 ...AppConstants.MARKETPLACE_MUSIC]

const CLOSE_TALK_LOCALES = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE_CLOSE_TALK]

const TRAINED_MOBILE_LOCALES = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE_TRAINED_MOBILE]

const EN_US_LOCALE = [{
    id: 'all',
    label: 'Select/Deselect All'
 },
 ...AppConstants.MARKETPLACE_FUNCTIONAL]

const ACOUSTIC_ALPHA_BETA_OPTIONS = {
    id: 'ACOUSTIC',
    label: 'Acoustic',
    scenarioTypes: {
        'AC_FAR_FIELD': {
            label: 'Far Field/ Near Field',
            locales: ALL_LOCALES
        },
        'AC_WWDD_QUICK_TEST': {
            label: 'WWDD',
            locales: ALL_LOCALES
        },
        'AC_FAR': {
            label: 'FAR',
            locales: ALL_LOCALES
        },
        'AC_FAR_PLUS': {
            label: 'FAR - Plus',
            locales: FAR_PLUS_LOCALE
        }
    }
}

const ACOUSTIC_PROD_OPTIONS = {
    id: 'ACOUSTIC',
    label: 'Acoustic',
    scenarioTypes: {
        'AC_FAR_FIELD': {
            label: 'Far Field/ Near Field',
            locales: ALL_LOCALES
        },
        'AC_WWDD_QUICK_TEST': {
            label: 'WWDD',
            locales: ALL_LOCALES
        },
        'AC_FAR': {
            label: 'FAR',
            locales: ALL_LOCALES
        },
        'AC_FAR_PLUS': {
            label: 'FAR - Plus',
            locales: FAR_PLUS_LOCALE_PROD
        }
    }
}

const SyncResourceConstants = {
    LOCALES_ALL: 'all',
    LOCALES_ALL_LABEL: 'Select/Deselect All',
    EXPANDED: 'expanded',
    LABEL: 'label',
    SELECTED: 'selected',
    MAX_LOCATIONS: 5,
    ERROR_NO_DATA: 'Failed to retrieve data for this lab',
    GET_MANIFEST_ERROR: 'Could not retrieve the list of resources for this location. Click Refresh to retry.',
    GET_MANIFEST_ERROR_OVERVIEW: 'Could not retrieve the list of resources for this location. Expand the location and click Refresh button in the "Resources Status" tab to retry',
    MANIFEST_EMPTY_ERROR: 'Failed to retrieve resource files for selected options.',
    RETRIEVING_MANIFESTS: 'Retrieving resources ... It could take a few seconds to a minute. Click Refresh button below if it takes longer than expected.',
    LOCATIONS_OFFLINE_ERROR: 'Cannot initiate resource download since one or more selected locations are offline. Please re-connect the Pis, reload the page and try again.',
    LOCATION_OFFLINE_ERROR: 'Resource download is disabled for this location since it seems to be offline. Please re-connect the Pi, reload the page and try again.',
    SUBMIT_SYNC_INFO: 'Submitting resources for download ...',
    SYNC_OPTIONS_ERROR: 'Please select at least one locale',
    LOCATIONS_ERROR: 'Please select at least one location',
    MANIFEST_BASE_URL: "https://dtzsgmefzdeac.cloudfront.net/ManifestFiles/",
    IN_PROGRESS: 'sync_inprogress',
    FAILED: 'failed',
    COMPLETE: 'complete',
    SYNC_MANIFEST_ERROR: 'Please select at least one manifest file for download.',
    LAB_LOCKED_ERROR: 'Lab seems to be locked. Cannot submit resources for download.',
    SYNC_NOT_IN_PROGRESS_MESSAGE: 'There is no resource download in progress for this location.',
    SYNC_DATA_MISSING_MESSAGE: 'Sync history either doesn’t exist, requiring the Pi to be sync’d for the first time, or there was an error while attempting to retrieve the data.',
    SYNC_RESOURCES_TAB_ID: '_sync_resources',
    SYNC_DETAILS_TAB_ID: '_sync_details',
    TEST_IN_PROGRESS_TEXT: 'Test in progress',
    AVAILABLE_TEXT: 'Available',
    LAB_LOCKED: 1,
    LAB_AVAILABLE: 0,
    GET_LAB_STATUS_TIMEOUT: 3000,
    MAX_NUM_LOCATIONS: 5,
    MAX_WAIT_TIME: 10,
    OVERALL_LOADING_STATE: 'loading',
    FAILED_LOADING_STATE: 'failed_loading',
    MESSAGE_RETRIEVING: 'Retrieving ...',
    MESSAGE_FAILED_RETRIEVING: 'Sync History Unavailable',
    MESSAGE_MANIFEST_DATA_UNAVAILABLE: 'Resources Data Unavailable',
    ERROR_NO_LOCATON_DATA: 'Failed retrieving locations. Resources cannot be submitted for download',
    RETRIEVING_AUTO_SYNC_MESSAGE: 'Retrieving information ... It could take a few seconds to minutes.',

    // Action and body to get latest state of manifests for a particular location
    ACTION_GET_MANIFESTS_STATE: 'get_manifest_state',
    GET_MANIFESTS_STATE_BODY: {
        "mqttPayload": {
            "action": "get_manifest_state",
            "payload": {
                "type": "mdxirs",
                "payload": {}
            }
        }
    },
    ACK_TIMEOUT: 10000,
    ACTION_TIMEOUT: 10000,

    OVERALL_STATE_IN_PROGRESS: 'inprogress',
    OVERALL_STATE_FAILED: 'failed',
    OVERALL_STATE_COMPLETE: 'complete',

    // States for detailed progress of resources
    SYNC_STATES: {
        'sync_inprogress': {
            label: 'In Progress',
            className: 'blue-color-text'
        },
        'queued': {
            label: 'Queued',
            className: 'grey-color-text'
        },
        'failed': {
            label: 'Failed',
            className: 'red-color-text'
        },
        'complete': {
            label: 'Completed',
            className: 'green-color-text'
        }
    },

    // States for overall progress of resources
    OVERALL_SYNC_STATES: {
        'inprogress': {
            label: 'In Progress',
            class: 'awsui-util-status-info nowrap-style',
            icon: 'status-in-progress',
            variant: 'normal',
            textStyle: 'awsui-util-ml-xs sync-running-test-status'
        },
        'failed': {
            label: 'Failed',
            class: 'awsui-util-status-negative nowrap-style',
            icon: 'status-negative',
            variant: 'normal',
            textStyle: 'awsui-util-ml-xs sync-failed-test-status'
        },
        'complete': {
            label: 'Completed',
            class: 'awsui-util-status-positive nowrap-style',
            icon: 'status-positive',
            variant: 'normal',
            textStyle: 'awsui-util-ml-xs sync-passed-test-status'
        },
        'loading': {
            label: 'Retrieving ...',
            class: 'awsui-util-status-info nowrap-style',
            icon: 'status-info',
            variant: 'normal',
            textStyle: 'awsui-util-ml-xs sync-pending-test-status'
        },
        'failed_loading': {
            label: 'Sync History Unavailable',
            class: 'awsui-util-status-negative nowrap-style',
            icon: 'status-negative',
            variant: 'subtle',
            textStyle: 'awsui-util-ml-xs sync-pending-test-status'
        }
    },

    // Map containing styles & Icons to display sync resource status
    TEST_STATUS_MAP: {
        'Completed': {
          class: 'awsui-util-status-positive nowrap-style',
          icon: 'status-positive',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-passed-test-status'},
        'Failed': {
          class: 'awsui-util-status-negative nowrap-style',
          icon: 'status-negative',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-failed-test-status'},
        'In Progress': {
          class: 'awsui-util-status-info nowrap-style',
          icon: 'status-in-progress',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-running-test-status'},
        'Queued': {
          class: 'awsui-util-status-info nowrap-style',
          icon: 'status-pending',
          variant: 'subtle',
          textStyle: 'awsui-util-ml-xs sync-pending-test-status'}
    },

    // Map containing styles & Icons for sync resource overall status
    RESOURCE_SYNC_STATUS_MAP: {
        'complete': {
          class: 'awsui-util-status-positive nowrap-style',
          icon: 'status-positive',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-passed-test-status',
          text: 'Completed'},
        'failed': {
          class: 'awsui-util-status-negative nowrap-style',
          icon: 'status-negative',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-failed-test-status',
          text: 'Failed'},
        'inprogress': {
          class: 'awsui-util-status-info nowrap-style',
          icon: 'status-in-progress',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-running-test-status',
          text: 'In Progress'}
    },

    // Map containing styles & Icons for status of manifest files on particular location
    MANIFEST_STATUS_MAP: {
        'in_sync': {
          class: 'awsui-util-status-positive',
          icon: 'status-positive',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-passed-test-status',
          text: 'Up-to-Date w/ Prior Sync Selection'},
        'out_of_sync': {
          class: 'awsui-util-status-negative',
          icon: 'status-negative',
          variant: 'normal',
          textStyle: 'awsui-util-ml-xs sync-failed-test-status',
          text: 'Out of Sync w/ Prior Sync Selection'},
        'retrieving': {
            class: 'awsui-util-status-pending',
            icon: 'status-pending',
            variant: 'subtle',
            textStyle: 'awsui-util-ml-xs light-grey-color-text',
            text: 'Retrieving ...'}
    },

    SYNC_PROGRESS_SORT_ORDER: ['In Progress', 'Completed', 'Failed', 'Queued'],

    TEST_SUITE_MAPPING: {
        'AVSCertSimulation': 'ACOUSTIC',
        'AVSCertMusic': 'MUSIC',
        'AQTFunctional': 'FUNCTIONAL',
        'AVSCertCloseTalk': 'CLOSE_TALK',
        'AVSCertMobile': 'MOBILE',
        'AVSSecurityTools': 'AVSSecurityTools',
        'AQTScenarioAutoLocalSearch': 'AUTO_LOCAL_SEARCH',
        'AQTQualScenario': 'QUAL'
    },

    TEST_SUITE_REVERSE_MAP: {
        'ACOUSTIC': {
            suiteId: 'AVSCertSimulation',
            label: 'Acoustic'
        },
        'MUSIC': {
            suiteId: 'AVSCertMusic',
            label: 'Music'
        },
        'FUNCTIONAL': {
            suiteId: 'AQTFunctional',
            label: 'Functional'
        },
        'CLOSE_TALK': {
            suiteId: 'AVSCertCloseTalk',
            label: 'Close Talk'
        },
        'MOBILE': {
            suiteId: 'AVSCertMobile',
            label: 'Mobile'
        },
        'AUTO_LOCAL_SEARCH': {
            suiteId: 'AQTScenarioAutoLocalSearch',
            label: 'Auto Local Search'
        },
        'QUAL': {
            suiteId: 'AQTQualScenario',
            label: 'Qualification Test Scenario'
        }
    },

    SYNC_RESOURCE_OPTIONS: {
        'AVSCertSimulation' : getStage() === STAGE_TYPES.PROD ? ACOUSTIC_PROD_OPTIONS : ACOUSTIC_ALPHA_BETA_OPTIONS,
        'AVSCertMusic': {
            id: 'MUSIC',
            label: 'Music',
            scenarioTypes: {
                'MUSIC_MSK': {
                    label: 'Music Skills',
                    locales: MUSIC_SKILLS_LOCALES
                },
                'MUSIC_IHRT_CUSTOM': {
                    label: 'iHeartRadio Custom',
                    locales: EN_US_LOCALE
                },
                'MUSIC_IHRT_LIVE': {
                    label: 'iHeartRadio Live',
                    locales: EN_US_LOCALE
                },
                'MUSIC_TUNEIN': {
                    label: 'TuneIn - Full (Radio + podcast)',
                    locales: MUSIC_SKILLS_LOCALES
                },
                'MUSIC_TUNEIN_RADIO': {
                    label: 'TuneIn - Radio',
                    locales: MUSIC_SKILLS_LOCALES
                },
                'MUSIC_TUNEIN_PODCAST': {
                    label: 'TuneIn - Podcast',
                    locales: MUSIC_SKILLS_LOCALES
                },
                'MUSIC_AMAZON_MUSIC': {
                    label: 'Amazon Music',
                    locales: MUSIC_SKILLS_LOCALES
                },
                // 'MUSIC_PANDORA': {
                //     label: 'Pandora',
                //     locales: EN_US_LOCALE
                // },
                'AUDIBLE': {
                    label: 'Audible',
                    locales: MUSIC_SKILLS_LOCALES
                },
                'KINDLE': {
                    label: 'Kindle',
                    locales: MUSIC_SKILLS_LOCALES
                }
            }
        },
        'AQTFunctional': {
            id: 'FUNCTIONAL',
            label: 'Functional',
            scenarioTypes: {
                'FUNC_ALL': {
                    label: 'Standard/ Custom',
                    locales: EN_US_LOCALE
                },
                'AUTO_FUNC_ALL': {
                    label: 'Automotive Functional',
                    locales: EN_US_LOCALE
                },
                'FUNC_NAV_ALL': {
                  label: 'Automotive Navigation',
                  locales: EN_US_LOCALE
              }
            }
        },
        'AVSCertCloseTalk': {
            id: 'CLOSE_TALK',
            label: 'Close Talk',
            scenarioTypes: {
                'CT_WAKE_AND_RESPONSE': {
                    label: 'Wake & Response Accuracy',
                    locales: CLOSE_TALK_LOCALES
                },
                'AC_WWDD_QUICK_TEST': {
                    label: 'WWDD',
                    locales: CLOSE_TALK_LOCALES
                },
                'AC_FAR': {
                    label: 'FAR',
                    locales: CLOSE_TALK_LOCALES
                }
            }
        },
        'AVSCertMobile': {
            id: 'MOBILE',
            label: 'Mobile',
            scenarioTypes: {
                'AC_TRAINED_MOBILE': {
                    label: 'Wake & Response Accuracy',
                    locales: TRAINED_MOBILE_LOCALES
                },
                'AC_VOICE_ENROLLMENT_ROBUSTNESS': {
                    label: 'Voice Enrollment Robustness',
                    locales: TRAINED_MOBILE_LOCALES
                },
                'AC_WWDD_QUICK_TEST': {
                    label: 'WWDD',
                    locales: ALL_LOCALES
                },
                'AC_FAR': {
                    label: 'FAR',
                    locales: ALL_LOCALES
                },
                'MOBILE_FUNCTIONAL': {
                    label: 'Functional',
                    locales: EN_US_LOCALE
                }
            }
        },
        'AQTScenarioAutoLocalSearch': {
            id: 'AUTO_LOCAL_SEARCH',
            label: 'Auto Local Search',
            scenarioTypes: {
                'SMOKE': {
                    label: 'Smoke/ Full',
                    locales: EN_US_LOCALE
                }
            }
        },
        'AVSSecurityTools': {
          id: 'SECURITY',
          label: 'Security Test',
          scenarioTypes: {
            PACKET_CAPTURE_ANALYSIS: {
              label: 'Packet Capture & Analysis',
              locales: EN_US_LOCALE
            },
            MITM: {
              label: 'Man in the middle analysis',
              locales: EN_US_LOCALE
            }
          }
        },
        'AQTQualScenario': {
          id: 'QUAL',
          label: 'Qual Test',
          scenarioTypes: {
            QUAL_SILENCE:{
               label: 'Silence',
               locales: EN_US_LOCALE
            },
            QUAL_DEVICE_PLAYBACK:{
               label: 'Device Playback',
               locales: EN_US_LOCALE
            },
            QUAL_NOISE_PINK:{
               label: 'Noise - Pink',
               locales: EN_US_LOCALE
            },
            QUAL_NOISE_PUB:{
               label: 'Noise - Pub',
               locales: EN_US_LOCALE
            },
            QUAL_NOISE_ROCK:{
               label: 'Noise - Rock',
               locales: EN_US_LOCALE
            },
            QUAL_NOISE_CAFE:{
               label: 'Noise - Cafe',
               locales: EN_US_LOCALE
            },
            QUAL_NOISE_NEWS:{
               label: 'Noise - News',
               locales: EN_US_LOCALE
            },
            QUAL_WWDD:{
               label: 'WWDD',
               locales: EN_US_LOCALE
            }
          }
        }
    }
  }

  export default SyncResourceConstants;
