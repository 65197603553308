// TODO: Make this file generic and improve logic
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import MusicTestTypeSelection from './MusicSuiteTestType';
import FunctionalSubScenarioSelection from './FunctionalSubScenarioSelection';
import { isMusicScenario } from './ScenarioSelectionHelpers';
import QualCustomizedOptions from './Qual';
import MobileVoiceTraining from './Mobile/MobileVoiceTraining';
import MobileVoiceRobustness from './Mobile/MobileVoiceRobustness';
import MobileFunctional from './Mobile/MobileFunctional';
import WakeResponseAccuracy from './CloseTalk/WakeResponseAccuracy';
import Acoustic from './Acoustic';
import CustomTest from './CustomTest';
import AutoLocalSearch from './Auto_Local_Search';
import Automotive from './Automotive';

/**
 * Returns Customized Scenario options based on selected testSuite and scenarioType
 * @return subScenario UI
 */
class CustomScenarioOptions extends PureComponent {
  static propTypes = {
    testSuite: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired
  }

  getCustomScenarioOptions() {
    const { testSuite, params } = this.props;
    const { selectedScenario: { scenarioType }, labsInAccount } = params;
    if (testSuite === AppConstants.TEST_SUITES.ACOUSTIC.ID) {
      return (
        <Acoustic params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
          otherParams: params.otherParams, // TODO: only pass deviceMapping there
          updateOtherParamsCallBack: params.updateOtherParamsCallBack,
          whitelistedCustomerIds: params.whitelistedCustomerIds,
          dutsInUse: params.dutsInUse,
          updateNewRunStateParameterCallBack: params.updateNewRunStateParameterCallBack,
          primaryDSNSelected: params.primaryDSNSelected,
          lockFields: params.lockFields
        }} />
      );
    } else if (testSuite === MusicConstants.MUSIC_SCENARIO_ID &&
      isMusicScenario(scenarioType)) {
      return (
        <MusicTestTypeSelection params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
          selectedTestGroupInfo: params.selectedTestGroupInfo,
          otherParams: params.otherParams, // TODO: only pass deviceMapping there
          updateOtherParamsCallBack: params.updateOtherParamsCallBack,
          dutsInUse: params.dutsInUse,
          updateNewRunStateParameterCallBack: params.updateNewRunStateParameterCallBack,
          primaryDSNSelected: params.primaryDSNSelected
        }} />
      );
    } else if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return (
        <FunctionalSubScenarioSelection params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
        }} />
      );
    } else if (testSuite === AppConstants.QUAL_SCENARIO_ID) {
      return (
        <QualCustomizedOptions
          labId={params.labId}
          labsInAccount={params.labsInAccount}
          selectedScenario={params.selectedScenario}
          selectedScenarioCallBack={params.selectedScenarioCallBack}
          selectedScenarioError={params.selectedScenarioError}
          scenarioErrorCallBack={params.scenarioErrorCallBack}
        />
      );
    } else if (testSuite === AppConstants.MOBILE_SUITE_ID &&
      scenarioType === AppConstants.TRAINED_MOBILE) {
      return (
        < MobileVoiceTraining params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
        }} />
      )
    } else if (testSuite === AppConstants.MOBILE_SUITE_ID &&
      scenarioType === AppConstants.VOICE_ROBUSTNESS) {
      return (
        < MobileVoiceRobustness params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
        }} />
      )
    } else if (testSuite === AppConstants.MOBILE_SUITE_ID &&
      scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
      return (
        < MobileFunctional params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
        }} />
      )
    } else if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID &&
      scenarioType === AppConstants.CLOSE_TALK_SCENARIO_TYPE[0].id) {
      return (
        < WakeResponseAccuracy params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
          lockFields: params.lockFields
        }} />
      )
    } else if (testSuite === AppConstants.TEST_SUITES.AUTO_LOCAL_SEARCH.ID) {
      return (
        <AutoLocalSearch params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
        }} />
      )
    } else if (testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) {
      let currentLab = null;
      if (labsInAccount) {
        currentLab = labsInAccount.find( function( labInfo ){
          return labInfo.id === params.labId;
        } );
      }
      return (
        <CustomTest
          currentLab = {currentLab}
          labId={params.labId}
          selectedScenario={params.selectedScenario}
          selectedScenarioCallBack={params.selectedScenarioCallBack}
        />
      );
    } else if (testSuite === AppConstants.TEST_SUITES.AUTOMOTIVE.ID) {
      return (
        <Automotive params={{
          selectedScenario: params.selectedScenario,
          selectedScenarioCallBack: params.selectedScenarioCallBack,
          selectedScenarioError: params.selectedScenarioError,
          scenarioErrorCallBack: params.scenarioErrorCallBack,
          otherParams: params.otherParams, // TODO: only pass deviceMapping there
          updateOtherParamsCallBack: params.updateOtherParamsCallBack,
          whitelistedCustomerIds: params.whitelistedCustomerIds,
          dutsInUse: params.dutsInUse,
          updateNewRunStateParameterCallBack: params.updateNewRunStateParameterCallBack,
          primaryDSNSelected: params.primaryDSNSelected,
          lockFields: params.lockFields
        }} />
      );
    }
  }

  render() {
    const { selectedScenario } = this.props.params;

    if (!selectedScenario.scenarioType) return (<div></div>);

    return (
      <div>{this.getCustomScenarioOptions()}</div>
    );
  }
}

export default CustomScenarioOptions;
