import {getSession, logToConsole, networkError} from "../../Util";
import AppConstants from "../../_Constants/AppConstants";
import {aqtStore} from "../../Components/State/Store";
import axios from "axios";
import ApiConstants from "../../_Constants/ApiConstants";
import {withHeader} from "../../Auth";

/**
 * Method to retrieve banner messages response from controller.
 */
export function getBannerMessages() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get( controllerEndpoint + ApiConstants.GET_BANNER_MESSAGE,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      logToConsole("Response from banner API" , response);
      if (response.hasOwnProperty('data')) {
        return { bannerMessages: response.data ? response.data : {} };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error);
      return { error: AppConstants.NETWORKERR };
    });
}
