/**
 * Component to render Custom Test test cases
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import { CUSTOM_TEST_UTTERANCES_COLUMNS } from './CustomTestUtterancesTableConfig';
import { getTextOrLoading, renderLabelElement, getLoadingMessage } 
  from '../../../Util';
import CustomTestConstants from '../../../_Constants/CustomTestConstants';

/**
 * Component to render Custom Test test cases
 * Displays Test Metrics & Utterance details for 10 utterances
 */
class CustomTestMetrics extends React.Component {

  componentWillMount() {
    this.loadUtterancesData();
  }


  /**
   * Gets status for utterance in utterance details table
   */
  getStatus = (status) => {
    if (status && CustomTestConstants.UTTERANCE_STATUS_MAP.hasOwnProperty(status)) {
      return (
        <div>
        <AWSUI.Icon name={ CustomTestConstants.UTTERANCE_STATUS_MAP[status].testStatusIcon }
          variant={ CustomTestConstants.UTTERANCE_STATUS_MAP[status].variant }/>
            <span className = { CustomTestConstants.UTTERANCE_STATUS_MAP[status].testStatusTextStyle }>
              { CustomTestConstants.UTTERANCE_STATUS_MAP[status].testStatusValue }
            </span>
        </div>
      )
    }
    return (
      <span>
        { AppConstants.NOT_APPLICABLE }
      </span>
    )
  }

  /**
   * Renders utterance data for up to 10 utterances in tabular format
   */
  loadUtterancesData = () => {
    let testStats = this.props.params.testStats;
    let testConfig = testStats.simulationOptions ? testStats.simulationOptions.testConfig : {};
    let isDeviceVirtualAssistantAlexa = testConfig && testConfig.dutType 
      && testConfig.dutType.toUpperCase() === CustomTestConstants.ALEXA;
    let utterancesData = [];
    let utteranceResult = testStats.aqtUtteranceResult;
    if (utteranceResult) {
      utterancesData.push({
        utterancePlayedTranscript : utteranceResult.asrText,
        dutResponse : isDeviceVirtualAssistantAlexa ? utteranceResult.actualResponse : utteranceResult.transcribeText,
        status : this.getStatus(utteranceResult.resultStatus),
        expectedAsr: utteranceResult.expectedAsrTextPattern,
        expectedTts: utteranceResult.expectedResponsePattern
      });
    }
    return utterancesData;
  }

  /**
   * Renders test metrics feed for Custom Test scenario
   */
  getCustomTestMetrics = (testStats) => {
    let utteranceResult = testStats.aqtUtteranceResult;
    let testConfig = testStats.simulationOptions ? testStats.simulationOptions.testConfig : {};
    let isDeviceVirtualAssistantAlexa = testConfig && testConfig.dutType 
      && testConfig.dutType.toUpperCase() === CustomTestConstants.ALEXA;
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Current Utterance ', AppConstants.EMPTY) } 
            { getTextOrLoading(utteranceResult.id) } 
            { renderLabelElement('Total Utterance Count ',AppConstants.EMPTY) }
            { getTextOrLoading(utteranceResult.totalUtterancesCount) }
            {
              (
                renderLabelElement('DUT Status ', AppConstants.EMPTY)
              )
            }
            {
              (
                <span>
                  { isDeviceVirtualAssistantAlexa && utteranceResult ? utteranceResult.deviceOnlineStatus : AppConstants.NOT_APPLICABLE }
                </span>
              )
            }
            { renderLabelElement('ETA ', '(Total Estimated Time Remaining)') } 
            { getTextOrLoading(testStats.eta) }  
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  render() {
    let testStats = this.props.params.testStats;
    let utteranceDataColumns = CUSTOM_TEST_UTTERANCES_COLUMNS;

    let utterancesData = this.loadUtterancesData();
    return (
      utterancesData && utterancesData.length > 0 ? (
        <div>
          {
            this.getCustomTestMetrics(testStats)
          }
          <h3 className='awsui-util-mt-l awsui-util-ml-m utterance-data-header-style'>
            <b>
              Utterance Data
            </b>
          </h3>
          <div className='awsui-util-mt-l'>
            <AWSUI.Table
              columnDefinitions={ utteranceDataColumns }
              items={ utterancesData } 
            >
            </AWSUI.Table>
          </div>
        </div>
      ) : (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
          ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
        )
    );
  }
}

CustomTestMetrics.propTypes = {
  onTabChange: PropTypes.func.isRequired
};

export default CustomTestMetrics;