import React, { PureComponent } from 'react';
import AWSUI from '@amzn/awsui-components-react';

class Grid extends PureComponent {

  render() {
    const { keyValuePairs } = this.props;
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            {
              keyValuePairs.map(({ label, value }) => {
                return (
                  <React.Fragment>
                    <div className='awsui-util-label'>
                      <b>{label}</b>
                    </div>
                    <div className='awsui-util-label'>
                      {value}
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>

        </AWSUI.ColumnLayout>
      </div>
    );
  }
}

export default Grid;
