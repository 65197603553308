import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../services/client';
import { API_STATUS, URL_MAPS } from '../../constants';
import { getUrl } from '../../services/utils';


export const fetchPis = createAsyncThunk('labs/fetchPis', async (labId) => {
  const url = `${getUrl(URL_MAPS.LAB)}/${labId}/things`;
  const response = await client.get(url);
  return response.data;
})

export const piSlice = createSlice({
  name: 'piList',
  initialState: {
    status: API_STATUS.INIT,
    pis: [],
    error: null
  },
  reducers: {
    updatePiWithCustomNames: (state, action) => {
      const preferences = action.payload.preference;
      if (preferences) {
          const actorMappingPreference = JSON.parse(preferences).actor_mapping_preference;
          if(actorMappingPreference)
          state.pis.map(pi=> {
            pi.name = actorMappingPreference[pi.id].name || pi.name;
          })
      }
    }
  },
  extraReducers:(builder) => {
    builder
      .addCase(fetchPis.fulfilled, (state, action) => {
        state['status'] = API_STATUS.SUCCESS;
        state['pis'] = Array.isArray(action.payload) ? action.payload: [];
        state['error'] = null;
      })
      .addCase(fetchPis.pending, (state) => {
        state['status'] = API_STATUS.PENDING;
        state['error'] = null;
      })
      .addCase(fetchPis.rejected, (state, action) => {
        state['status'] = API_STATUS.ERROR;
        state['error'] = "Failed to fetch pi information";
      })
  }
})

export const { updatePiWithCustomNames } = piSlice.actions;

export const piSelector = (state) => state.piList;

export default piSlice.reducer;
