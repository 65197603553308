import React from 'react'
import Results from '@amzn/open-automation-kit-ui/dist/oakRun';
import { aqtStore } from 'Components/State/Store';

export default () => {
  return (
    <div>
      <Results
        jwtToken={aqtStore.getState().session.idToken.jwtToken}
        environment={aqtStore.getState().environment}
      />
    </div>
  );
}
