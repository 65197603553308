export const STABILITY_LIVE_UTTERANCE_COLUMNS = [{
  id: 'utterance',
  header: () => 'Utterance',
  cell: item => item.utterance,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'actualResponse',
  header: () => 'Device Response',
  cell: item => item.actualResponse,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'actualIntent',
  header: () => 'Response Intent',
  cell: item => item.actualIntent,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'resultStatus',
  header: () => 'Utterance Result',
  cell: item => item.resultStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'deviceStatus',
  header: () => 'Device Status',
  cell: item => item.deviceStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];