import constants from '../constants/auth';

const initialState = {
  session: {},
  environment: {},
  allowList: undefined,
  loadingAllowList: false,
  error: undefined
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_ENVIRONMENT:
      return {
        ...state,
        environment: payload
      };
    case constants.SET_SESSION:
      return {
        ...state,
        session: payload
      };
    case constants.FETCH_ALLOW_LIST_PENDING:
      return {
        ...state,
        loadingAllowList: true
      };
    case constants.FETCH_ALLOW_LIST_SUCCESS:
      let resp = payload;
      if(typeof(payload) === "string") {
        try {
          resp = JSON.parse(payload)
        } catch (e) {
          resp = {}
        }
      }
      return {
        ...state,
        allowList: resp,
        loadingAllowList: false
      };
    case constants.FETCH_ALLOW_LIST_FAILURE:
      return {
        ...state,
        error: payload,
        loadingAllowList: false
      };
    default:
      return state;
  }
};

export default reducer;
