import React, {Component} from 'react';
import AWSUI from '@amzn/awsui-components-react';
import {getLoadingMessage, getTextOrLoading, getUtteranceContainerHeader, isTestCompleted} from "../../../Util";
import AppConstants from "../../../_Constants/AppConstants";
import ld from "lodash";
import './styles.css'


class Automotive extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Render utterance key - value for utterance played, device heard and device responded
   * @param label
   * @param value
   * @param status
   * @param showAction
   * @returns {JSX.Element}
   */
  renderUtteranceKeyValue = (label, value, status = 'info', showAction = false) => {
    const textStatus = 'status-' + status;
    const keyItem = 'awsui-util-' + textStatus;
    const response = ld.isEmpty(value) || ld.isUndefined(value) ? getTextOrLoading() : value
    return (
      <div className="f-container-col">
        <div className={`f-item utteranceText ${keyItem}`}>
          <AWSUI.Icon className='awsui-util-mr-s' name={textStatus}/>
          {label}
        </div>
        <div className='f-item utteranceFont'>
          {response} {showAction && this.renderCurrentAction()}
        </div>
      </div>
    );
  }

  /**
   * Render Footer info
   * @param text
   * @returns {JSX.Element}
   */
  renderFooterInfo = (text) => {
    if (!ld.isUndefined(text) && !(text === 'undefined')) {
      return (
        <div className='footerText'>
          {/*<AWSUI.Icon className='awsui-util-mr-s' name='status-info'/>*/}
          Currently playing Noise: {text}
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  /**
   * Returns Footer of current noise scenario
   * @returns {JSX.Element}
   */
  getFooter = () => {
    const {noiseFeeds} = this.props;
    const activityFeed = ld.get(noiseFeeds, 'payload.currentScenario');
    return (
      <div className='awsui-util-p-xs'>
        <h5 className='awsui-util-ml-xs awsui-util-p-xs awsui-util-status-info'>
          {this.renderFooterInfo(activityFeed)}
        </h5>
      </div>
    );
  }

  /**
   * Returns the appropriae icon for the live actions from scenario
   * @param action
   * @returns {JSX.Element|*}
   */
  getIcon = (action = "success") => {
    const {commonFeeds} = this.props;
    let raspiAction = ld.get(commonFeeds, 'payload.uiFeed');
    if (!ld.isEmpty(raspiAction)) {
      if (raspiAction.toUpperCase().includes('FAILED')) {
        return (<AWSUI.Icon variant="error" name="status-negative"/>);
      } else if (raspiAction.toUpperCase().includes('COMPLETED') || raspiAction.toUpperCase().includes('COMPLETE') || raspiAction.toUpperCase().includes('PLAYED')) {
        return (<AWSUI.Icon variant="success" name="status-positive"/>);
      } else if (raspiAction.toUpperCase().includes('VALIDATING')) {
        return (<AWSUI.Icon variant="link" name="status-info"/>);
      } else if (raspiAction.toUpperCase().includes('PLAYING')) {
        return (<AWSUI.Icon variant="link" name="status-pending"/>);
      }
    } else {
      return getTextOrLoading();
    }
  }

  /**
   * Render current actions being executed by scenario
   * @returns {JSX.Element}
   */
  renderCurrentAction = () => {
    const {utteranceFeeds} = this.props;
    let raspiAction = ld.get(utteranceFeeds, 'payload.uiFeed');
    return (
      <div className="currentAction awsui-util-d-i">
        <div className="awsui-util-d-i"> {this.getIcon()} </div>
        <div className="awsui-util-d-i"> {raspiAction} </div>
      </div>
    )
  }

  /**
   * Render notifications coming from Redis
   * @param notification
   * @returns {JSX.Element}
   */
  renderNotification = (notification) => {
    return (
      <div className="f-container-center">
        <div className="f-item">
          <AWSUI.Icon size="normal" variant="link" name="status-pending"/>
        </div>
        <div className="f-item notificationFont">
          {notification}
        </div>
      </div>
    )
  }

  /**
   * check if AutoSync is in progress
   * @returns {boolean}
   */
  isAutoSyncInProgress = () => {
    const {testStatus} = this.props;
    return AppConstants.RASPI_RESOURCES_SYNCING === testStatus.toLowerCase();
  }

  /**
   * Renders if job is complete
   * @returns {JSX.Element}
   */
  renderJobCompletionIfApplicable = () => {
    const {testStatus} = this.props;
    const percentage = isTestCompleted(testStatus.toLowerCase());
    if (percentage === true) {
      // test is complete
      return (
        <div className='notification-style'> {AppConstants.JOB_COMPLETED} </div>
      );
    }
  }

  /**
   * Renders Test Queued update, utterance info
   * @returns {JSX.Element}
   */
  showUtterances = () => {
    const {commonFeeds, testStatus} = this.props;
    const notification = ld.get(commonFeeds, 'payload.notification');
    const action = ld.get(commonFeeds, 'payload.action');
    const isTestComplete = isTestCompleted(testStatus.toLowerCase());

    if (!isTestComplete && (ld.isEmpty(action) || ld.isUndefined(action))) {
      let notification = AppConstants.JOB_QUEUED_MESSAGE;
      if (this.isAutoSyncInProgress() === true) {
        notification = AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE;
      }
      // test is yet to start
      return (
        <div className='Aligner'>
          <div className='notification-style'> {notification} </div>
        </div>
      );
    } else if (action === 'SPEECH_SYNTHESIZER_ROBO' || action === 'NOISE_PLAYBACK_ROBO') {
      return (
        <div className='Aligner'>
          <div
            className='notification-style'> {!ld.isEmpty(notification) && this.renderNotification(notification)} </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='Aligner-default'>
            {this.renderJobCompletionIfApplicable()}
            {this.renderUtteranceInfoContainer()}
          </div>
        </div>
      );
    }
  }

  /**
   * Render Utterances played, device heard and device responded with live ubuntu actions
   * @returns {JSX.Element}
   */
  renderUtteranceInfoContainer = () => {
    const METRICSSTATUS = {0: 'negative', 1: 'positive', 2: 'info'};
    const {utteranceFeeds, commonFeeds} = this.props;
    const utterancePlayed = ld.get(utteranceFeeds, 'payload.utterancePlayed');
    const deviceHeard = ld.get(utteranceFeeds, 'payload.deviceHeard');
    let deviceResponded = ld.get(utteranceFeeds, 'payload.deviceResponded');
    const action = ld.get(commonFeeds, 'payload.action');
    const isRarSuccess = ld.get(utteranceFeeds, 'payload.rar') === 1;

    let scoreFrr = 'info';
    let scoreRar = 'info';
    let scoreHar = 'info';

    if (ld.get(utteranceFeeds, 'payload.uiFeed') === 'validation complete') {
      scoreFrr = METRICSSTATUS[ld.get(utteranceFeeds, 'payload.frr', 2)];
      scoreRar = METRICSSTATUS[ld.get(utteranceFeeds, 'payload.rar', 2)];
      scoreHar = METRICSSTATUS[ld.get(utteranceFeeds, 'payload.har', 2)];
    }

    let passScore = scoreHar || scoreRar;

    if ( isRarSuccess && (ld.isEmpty(deviceResponded) || ld.isUndefined(deviceResponded))) {
      if (ld.isEmpty(deviceHeard) || ld.isUndefined(deviceResponded)) {
        deviceResponded = '.'
      }
    }

    return (
      <div className='utteranceContainer utteranceText'>
        {!ld.isEmpty(utterancePlayed) && this.renderUtteranceKeyValue("Utterance Played", utterancePlayed, 'info', true)}
        {!ld.isEmpty(utterancePlayed) && this.renderUtteranceKeyValue("Device Heard", deviceHeard, scoreFrr)}
        {!ld.isEmpty(utterancePlayed) && this.renderUtteranceKeyValue("Device Responded", deviceResponded, passScore)}
      </div>

    )
  }

  render() {
    return (
      <div className="awsui-util-container">
        <div>{this.showUtterances()}</div>
        {/*<div className="awsui-util-container-footer">{this.getFooter()}</div>*/}
      </div>
    );
  }
}

export default Automotive;
