import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';

import AppConstants from '_Constants/AppConstants';
import {getScenariosWithResetCustomOptions} from '../../ScenarioSelectionHelpers';
import {sendRawInfoToNewRun} from "../../NewRunDataTransferUtils";

/**
 * Generic component to load test categories with radio button based on the given props
 * test categories: ALEXA HANDS-FREE VALIDATION, DEVICE LOCK HANDS-FREE VALIDATION, APP VERIFICATION
 */
class TestCategory extends Component {
  static propTypes = {
    /**
     * Its an array of testCategories that needs to be selected.
     * eg: [0] will render ALEXA HANDS-FREE VALIDATION
     * Please refer AppConstants for all the testCategories that we support, as below
     * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/b108f80e1cf6bd9a5c7eed39037d5c394421cb51/--/src/_Constants/AppConstants.js
     */
    allowedTypes: PropTypes.arrayOf(PropTypes.number),
    selectedScenario: PropTypes.object.isRequired,
    selectedScenarioCallBack: PropTypes.func.isRequired,
    selectedScenarioError: PropTypes.object.isRequired,
    scenarioErrorCallBack: PropTypes.func.isRequired
  };

  setTestCategoriesRadioButtonValues(index, selectedScenarios) {
    let updatedScenarios = selectedScenarios;
    let testCategoriesArrIn = [false, false, false];
    testCategoriesArrIn[index] = true;
    updatedScenarios.testCategoryArr = testCategoriesArrIn;
    return updatedScenarios;
  }

  /**
   * Setup test category related state parameters
   * Applies to scenarios with TestCategory selection
   * @param {*} scenarioType  may not needed
   * @param {*} testCategory
   */
  setTestCategoryState(scenarioType, testCategory, selectedScenarios, scenarioSelectCallBackFun, selectedScenarioError, scenarioErrorCallBack) {
    let updatedScenarios = getScenariosWithResetCustomOptions(selectedScenarios, true);
    let updatedScenariosError = selectedScenarioError;
    let index = 0;
    switch (testCategory) {
      case AppConstants.ALEXA_HANDS_FREE_VERIFICATION:
        index = 0;
        break;
      case AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION:
        index = 1;
        updatedScenarios.testType = AppConstants.CUSTOMIZED;
        break;
      case AppConstants.APP_VERIFICATION:
        index = 2;
        break;
      default:
        break;
    }

    updatedScenarios = this.setTestCategoriesRadioButtonValues(index, updatedScenarios);

    updatedScenariosError.testCategoryArr = false;
    updatedScenarios.testCategory = testCategory;

    sendRawInfoToNewRun(updatedScenarios, scenarioSelectCallBackFun);
    sendRawInfoToNewRun(updatedScenariosError, scenarioErrorCallBack);
  }

  /**
   * On selection, update the test category
   */
  handleOnChangeTestCategory = (event) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props;
    const testCategory = event.detail.value;
    return this.setTestCategoryState(
      selectedScenario.scenarioType,
      testCategory,
      selectedScenario,
      selectedScenarioCallBack,
      selectedScenarioError,
      scenarioErrorCallBack
    );
  }

  render() {
    const { selectedScenario, selectedScenarioError, allowedTypes } = this.props;

    let testCategories = AppConstants.TEST_CATEGORIES.filter(type => allowedTypes.includes(type.index))
    const options = []
    testCategories.map(item => {
      options.push({
        "label": item.label,
        "value": item.type
      })
    })

    const errorText = selectedScenarioError.testCategoryArr
      ? AppConstants.TEST_CATEGORIES_ERROR
      : AppConstants.EMPTY;

    return (
      <AWSUI.FormField
        errorText={errorText}
        label={<h3>Test Category</h3>}
        className='awsui-util-m-l'
      >
        <AWSUI.ColumnLayout columns={4} className='awsui-util-p-l'>
          <AWSUI.RadioGroup
            data-awsui-column-layout-root='true'
            value={selectedScenario.testCategory}
            onChange={this.handleOnChangeTestCategory}
            items={options}
          >
          </AWSUI.RadioGroup>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }
}

export default TestCategory;
