import React from 'react';
import { isEmpty } from 'lodash';
import CustomizeOptionsSubPage from '../CustomizeOptionsSubPage';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';
import ld from 'lodash';
/**
 * Use for Automotive Near custom option selections
 */
class GeneralAutomotiveTestTypeSelection extends React.Component {

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack, piLocations } = this.props.params;
    return (
      <div>
        <TestTypeScenarioOptions
          allowedTypes={[0, 1, 2]}
          showDelayConfig={false}
          showAdminValidation={true}
          showRetry={true}
          showDisableSkill={true}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
          {/* These components are specific to Automotive customized options */}
          {!isEmpty(selectedScenario) &&
            (<CustomizeOptionsSubPage params={{
              selectedScenario: selectedScenario,
              selectedScenarioCallBack: selectedScenarioCallBack,
              piLocations: piLocations,
              selectedScenarioError: selectedScenarioError,
              scenarioErrorCallBack: scenarioErrorCallBack
            }} />)
          }
        </TestTypeScenarioOptions>
      </div>
    );
  }
}

export default GeneralAutomotiveTestTypeSelection;
