import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { Switch, Route } from "react-router-dom";
import MENU_ITEMS from './MenuConfig';
import NewRun from '../NewRun';
import LiveRun from '../LiveRun';
import Results from '../Results';
import ReleaseNotes from '../ReleaseNotes';
import customTests from '../CustomTest';
import Labs from '../Labs';
import { logToConsole } from '../../Util';
import { getLatestReleaseNotes } from '../ReleaseNotes/releaseNotesUtil';
import AppConstants from '../../_Constants/AppConstants';
import NavBar from './NavBar';
import {fetchBannerMessages, getLatestBannerMessages} from "../Banner/bannerUtil";
import NotFound from 'Container/ErrorPages/NotFound';
import CreateTest from '../../newSystem/index';
import axios from 'axios';
import { aqtStore } from '../../Components/State/Store';
import ApiConstants from '../../_Constants/ApiConstants';
import { withHeader } from '../../Auth';

class Main extends React.Component {

  state = {
    activeTabId : 'newRun',
    releaseNotes: {},
    bannerMessages:[],
    hasAcm: false,
    isNewRunV2Enabled: false,
  }

  constructor(props) {
    super(props);
    this.fetchScenarios();
  }

  componentDidMount() {
    this.getReleaseNotes();
    this.getBannerMessages();
  }

  fetchScenarios() {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    axios.get(
      `${controllerEndpoint}${ApiConstants.GET_WHITELISTED_SCENARIOS}`,
      withHeader(aqtStore.getState().session.idToken.jwtToken)
    )
    .then(scenarios => {
      this.setState(() => ({
        hasAcm: scenarios.data.find(({name})=> name === "AQTUDTV2"),
        hasWWA: scenarios.data.find(({name})=> name === "AQTUDTV2WWA"),
        isNewRunV2Enabled: scenarios.data.filter(({name}) => name === "AQTCustomScenario")
      }))
    })
  }

  /**
   * Method to retrieve Release Notes from controller.
   */
  getReleaseNotes = () => {
    return Promise.resolve(getLatestReleaseNotes().then(response => {
      if (AppConstants.UNKNOWN_RELEASE_NOTES !== JSON.stringify(response)) {
        logToConsole('Release notes from utility ' + JSON.stringify(response));
        this.setState({
          releaseNotes: response,
          releaseNotesVersion: response[0].releasenotes.version
        });
      } else {
        logToConsole('Error retrieving release notes from controller');
      }
    }));
  }

  /**
   * Method to get timestamp for latest software release & maximum duration for which
   * notification should be displayed in the UI
   */
  getReleaseNotificationDetails = () => {
    let releaseNotesFromController = this.state.releaseNotes;
    let releaseNotesRecord;
    if (releaseNotesFromController && releaseNotesFromController.length > 0) {
      releaseNotesRecord = releaseNotesFromController[0];
    }
    // By default, assign 24 hour duration to display new software release notification
    let notificationDetails = {
      'timestamp': -1,
      'notificationDuration': AppConstants.ONE_DAY_DURATION
    }
    if (releaseNotesRecord) {
      if (releaseNotesRecord.hasOwnProperty('timestamp')) {
        notificationDetails.timestamp = releaseNotesRecord.timestamp;
      }
      if (releaseNotesRecord.hasOwnProperty('releasenotes')) {
        let releaseNotes = releaseNotesRecord.releasenotes;
        if (releaseNotes.hasOwnProperty('notificationDuration')) {
          notificationDetails.notificationDuration = releaseNotes.notificationDuration;
        }
      }
    }
    return notificationDetails;
  }

  /**
   * Method to get any informative or error messages and display on portal.
   */
   getBannerMessages = async () => {
     const response = await fetchBannerMessages();
     if (response != null) {
       logToConsole('Banner messages = ' + JSON.stringify(response));
       this.setState({
           bannerMessages: getLatestBannerMessages(response)
         }
       );
     } else {
       logToConsole('Error retrieving banner messages from controller');
     }
     // poll banner messages every 15 minutes
     setTimeout(this.getBannerMessages, 15 * 60 * 1000)
   }

  /**
   * Method to decide whether to display notification about new software update.
   */
  shouldDisplayReleaseNotification = () => {
    let displayNotification = false;
    let notificationDetails = this.getReleaseNotificationDetails();
    if (notificationDetails.timestamp !== -1) {
      // Get current time in local time zone
      let currentDate = new Date();
      let currentTimeMillis = currentDate.getTime();
      // Compute the offset in milliseconds from UTC
      let offset = currentDate.getTimezoneOffset();
      let offsetMillis = offset * 60000;
      // Compute the current time in UTC
      let currentUtcTimeMillis = currentTimeMillis + offsetMillis;
      let timeDiff = currentUtcTimeMillis - notificationDetails.timestamp;

      // Display Flash message for software update for duration specified in release notes
      // JSON or 24 hours by default
      if (timeDiff <= notificationDetails.notificationDuration) {
        displayNotification = true;
      }
    }
    return displayNotification;
  }

  handleTabChange = (tabId) => {
    if (!tabId) return;

    this.setState({
      activeTabId: tabId
    });
  }

  /**
   * Function to allow children page to retrieve release note version
   */
  retrieveReleaseNotesVersion = () => {
    logToConsole("Passing state " + JSON.stringify(this.state.releaseNotesVersion));
    return this.state.releaseNotesVersion
  }


  render() {
    const menus = [...MENU_ITEMS];
    if(this.state.hasAcm || this.state.isNewRunV2Enabled || this.state.hasWWA) {
      menus.splice(2, 0, {
        icon: 'refresh',
        label: 'OAK Run',
        path: '/v2/run/draft',
        id: 'newRunV2',
        component: <CreateTest />
      })
    }

    return (
      <div>
        {
          <AWSUI.Flashbar items = {this.state.bannerMessages}/>
        }
        {
          this.shouldDisplayReleaseNotification() && (
            <AWSUI.Flash
              dismissible={true}
              content={ AppConstants.NEW_SOFTWARE_UPDATE_MESSAGE }/>
          )
        }
        <NavBar
          menuItems={menus}
          activeTabId={this.state.activeTabId}
          handleTabChange={this.handleTabChange}
        />
        <div style={{ padding: '20px' }}>
          <Switch>
            <Route path='/labs' component={Labs} />
            <Route path='/' exact>
              <NewRun
                releaseNotesVersionCallBack={this.retrieveReleaseNotesVersion}
              />
            </Route>
            <Route path='/liveRuns' component={LiveRun} />
            <Route path='/results' component={Results} />
            <Route path='/customTests' component={customTests} />
            <Route path='/releases' component={ReleaseNotes} />
            <Route path='/v2' component={CreateTest} />
            <Route render={() => <NotFound />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Main;
