export const RASPI_MAPPING_TABLE_COLUMNS = [{
    id: 'location',
    header: () => 'Location',
    cell: item => item.location,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'name',
    header: () => 'Name',
    cell: item => item.name,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }];