import AppConstants from '../../_Constants/AppConstants';

const initialAqtState = {
  session: {},
  environment: {}
};

export function aqtStates(state = initialAqtState, action) {
  switch (action.type) {
    case AppConstants.ENVIRONMENT:
      return Object.assign({}, state, {
        environment: action.environment
      });
    case AppConstants.SESSION:
      return Object.assign({}, state, {
        session: action.session
      });
    default:
      return state;
  }
}

const initialLiveFeedState = {
  liveFeed: {}
};

export function liveFeedStates(state = initialLiveFeedState, action) {
  switch (action.type) {
    case AppConstants.LIVEFEED:
      return { liveFeed: Object.assign({}, state.liveFeed, action.liveFeed) };
    default:
      return state;
  }
}

const initialState = {};

export function storageStates(state = initialState, inState) {
  switch (inState.type) {
    case AppConstants.SAVE:
      return Object.assign({}, state, {
        [inState.name]: inState.inputState
      });
    case AppConstants.DELETE:
      let currState = Object.assign({}, state);
      delete currState[inState.name];
      return currState;
    default:
      return state;
  }
}