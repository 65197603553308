import {addNewTestInTestList} from "../testList/testListMiddleware";
import {reset as clearTestInitSlice} from "../testInitSlice";
import {reset as clearScenarioSlice} from "../acm/scenarioSelectionSlice";
import {reset as clearLabConfigSlice} from "../acm/labConfigSlice";
import {reset as clearUiSlice} from "../acm/uiSlice";
import {reset as clearTestSuitesSlice} from "../scenariosSlice";
import {reset as clearDeviceAttributeSlice} from "../wwa/deviceAttributesSlice";

export const submitTest = () => (dispatch, getState) => {
  const scenarioSelectionState = getState().acmScenarioSlection;
  const labConfigState = getState().acmLabConfig;
  const testInitState = getState().testInit;
  const testMapping = getState().testMapping;
  const testOpts = getState().testOpts;
  const wwaScenarioSelection = getState().wwaScenarioSelection;

  dispatch(addNewTestInTestList({
    ...scenarioSelectionState,
    ...labConfigState,
    testMapping: testMapping,
    ...testOpts,
    ...wwaScenarioSelection,
    testSuite: testInitState.testSuite
  }));

  dispatch(cancelTestCreation());
}

export const cancelTestCreation = () => (dispatch) => {
  dispatch(clearTestInitSlice());
  dispatch(clearScenarioSlice());
  dispatch(clearLabConfigSlice());
  dispatch(clearDeviceAttributeSlice());
  dispatch(clearUiSlice());
  dispatch(clearTestSuitesSlice());
}
