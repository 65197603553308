/**
 * Component to display test details
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '_Constants/AppConstants';
import MusicConstants from '_Constants/MusicConstants';
import FunctionalTestCases from '_Constants/FunctionalTestCasesConstants';
import {
  getAmazonIdToDisplay,
  getCustomOptionsDisplayMap,
  getDisplayNameForId, getFunctionalScenarios,
  getFunctionalTestCases,
  getFunctionalTestCasesCutomOptions,
  getMobileNoiseDisplayMap,
  getMobileScenarios,
  getScenarioTypeToDisplay,
  getTestTypeDisplay,
  getTrainers,
  isA4PCDevice,
  renderLabelElement
} from '../../../Util';
import SecurityTestDetails from './Security';
import ld from 'lodash';
import {AutomotiveScenarioTypes} from "../../../_Constants/AutomotiveConstants";

/**
 * Component which displays following test details:
 * 1. Customer ID
 * 2. Device Serial Number
 * 3. Build Info
 * 4. Locale
 * 5. Test Type
 * 6. Scenario Type
 * 7. Custom Options
 * 8. Amazon ID
 */
class TestDetails extends React.Component {

  /**
   * Render custom options (schema def)
   * @returns {JSX.Element|string}
   */
  getCustomTestOptions = () => {
    const isAutomotiveScenario = AutomotiveScenarioTypes.includes(this.props.params.scenarioType);
    let customOptionsDisplay = getCustomOptionsDisplayMap(this.props.params.customOptions);
    if (isAutomotiveScenario) {
      return (
        <div className='awsui-util-spacing-v-s'>
          {Object.keys(customOptionsDisplay).map(option => {
            return (<div key={option}> {customOptionsDisplay[option]} </div>)
          })}
        </div>
      );
    } else {
      return AppConstants.NOT_APPLICABLE;
    }
  }

  /**
   * Gets custom options in display friendly format
   * @param options Custom options
   * @param testSuite Test Suite
   * @return Returns custom options list in display friendly format
   */
  customOptPretty(options, testSuite) {
    let customOptionsDisplay = getCustomOptionsDisplayMap(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(customOptionsDisplay).map(option => {
           let optionDisplay = option;
           // If it's a Close Talk Scenario, display 0.9 m 90 deg as HATS
           if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID && option === AppConstants.LOCATION_THREE_NINETY) {
              optionDisplay = AppConstants.CT_HATS_LOCATION;
           } else if (testSuite === AppConstants.MOBILE_SUITE_ID) {
              return (<div key={ option } > { customOptionsDisplay[option] } </div>)
           }
           return (<div key={ option } > { optionDisplay + ' - ' + customOptionsDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of trainers in display friendly format
   * @param options Custom options
   * @return Returns list of trainers in display friendly format
   */
  getTrainersPretty(options) {
    let trainersDisplay = getTrainers(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div> { trainersDisplay } </div>
      </div>
    );
  }

  /**
   * Creates a nice display format for mobile noise types
   * @param options Custom options
   * @return noise list for mobile in display-friendly format
   */
  createMobileNoiseDisplay(options) {
    let mobileNoiseDisplay = getMobileNoiseDisplayMap(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(mobileNoiseDisplay).map(noise => {
            let displayName = noise;
            return (<div key={ noise } > { displayName + " " + mobileNoiseDisplay[noise] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of Functional Test cases for Functional Custom scenario in display friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getFunctionalTestCasesPretty(testParams) {
    let functionalTestCasesDisplay = getFunctionalTestCases(testParams);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(functionalTestCasesDisplay).map(option => {
           return (<div key={ option } > { option + ' ==> ' + functionalTestCasesDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of Functional Test cases for Mobile Functional Custom scenario in display friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getMobileFunctionalTestCasesPretty(options) {
    let mobileFunctionalTestCasesDisplay = getFunctionalTestCasesCutomOptions(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div>
          { mobileFunctionalTestCasesDisplay[0] }
          { mobileFunctionalTestCasesDisplay.slice(1).map(option => {return ', ' + option}) }
        </div>
      </div>
    );
  }

  /**
   * Method used to display 'Scenario Type' label based on Test Suite
   * @param testSuite Test Suite
   * @return Scenario Type label based on type of test suite
   */
  getTestTypeLabelToDisplay = (testSuite) => {
    if (AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY.hasOwnProperty(testSuite)) {
      return AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY[testSuite];
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets Scenario type for display based on input scenario type
   * @param scenarioType Scenario type
   * @param testSuite Test Suite
   * @param config Current job config information if applicable
   * @return Customer facing scenario type
   */
  getScenarioTypeToDisplay(scenarioType, testSuite, config) {
    if (scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
      return getDisplayNameForId(getMobileScenarios(), scenarioType)
    }
    if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return getDisplayNameForId(getFunctionalScenarios(), scenarioType)
    }
    return getScenarioTypeToDisplay(scenarioType, testSuite, config);
  }

  /**
   * Gets Device virtual assistant to display
   * @param testSuite Test Suite
   * @param customOptions Custom options
   * @returns Device Virtual Assistant
   */
  getDeviceVirtualAssistant = (testSuite, customOptions) => {
    if (testSuite
         && testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
         && customOptions) {
      return getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS,
        customOptions.deviceVirtualAssistant);
    }
    return AppConstants.NOT_APPLICABLE;
  }
  /**
   * Renders test details feeds for Acoustic
   */
  renderAcousticTestDetailsFeeds = () => {
    const { customOptions = {} } = this.props.params;
    const customize = ((this.props.params.scenarioType === FunctionalTestCases.FUNC_CUSTOM
      || this.props.params.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM) ||
      (this.props.params.scenarioType === AppConstants.MOBILE_FUNCTIONAL &&
        this.props.params.testType === AppConstants.CUSTOMIZED ||
        this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION));
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Login ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { renderLabelElement('Locale', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { renderLabelElement(this.getTestTypeLabelToDisplay(this.props.params.testSuite), AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.getScenarioTypeToDisplay(this.props.params.scenarioType,
                this.props.params.testSuite, this.props.params.config)) }
            { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.dsn) }
            {
              <>
                <div>
                  { renderLabelElement('Test Type', AppConstants.EMPTY) }
                </div>
                <div>
                  { renderLabelElement(AppConstants.EMPTY,
                    getTestTypeDisplay(this.props.params.testType, this.props.params.scenarioType, this.props.params.testSuite)) }
                </div>
              </>
            }
            { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            {
              isA4PCDevice(this.props.params.customOptions.amazonId) &&
                ld.get(this.props.params.customOptions, 'a4pcConfig.aqtA4PCCompanionAppEnabled', false) &&
              renderLabelElement("Wakeword Mode", AppConstants.EMPTY)
            }
            {
              isA4PCDevice(this.props.params.customOptions.amazonId) &&
              ld.get(this.props.params.customOptions, 'a4pcConfig.aqtA4PCCompanionAppEnabled', false) &&
              renderLabelElement(AppConstants.EMPTY,
                ld.get(this.props.params.customOptions, 'a4pcConfig.a4pcWakewordModel', AppConstants.EMPTY))
            }
            { renderLabelElement('Lab ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
            { renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.deviceName) }
            { renderLabelElement('Email Notification ', AppConstants.EMPTY) }
            { this.props.params.sendCompletionEmail && renderLabelElement(AppConstants.EMPTY, 'Enabled') }
            { !this.props.params.sendCompletionEmail && renderLabelElement(AppConstants.EMPTY, 'Disabled') }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              renderLabelElement('Proxy Enabled', AppConstants.EMPTY)
            }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              renderLabelElement(AppConstants.EMPTY, `${customOptions.isProxyEnabled}`)
            }
            {/*If MOBILE_FUNCTIONAL: */}
            {/*  - display "Test Category" */}
            { this.props.params.scenarioType === AppConstants.MOBILE_FUNCTIONAL ?
              (
                <>
                  { renderLabelElement('Test Category', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                    getDisplayNameForId(AppConstants.TEST_CATEGORIES, this.props.params.testCategory)) }
                </>
              ) : (
                // Else:
                /* If CUSTOMIZED:
                  *    - display "Noise Selection" for Mobile Voice Robustness tests (VOICE_ROBUSTNESS)
                  *    - for all other tests, display "Custom Options"
                  * Else (not CUSTOMIZED):
                  *    - display "Custom Options" as not applicable
                  */
              this.props.params.testType === AppConstants.CUSTOMIZED ? (
                this.props.params.scenarioType === AppConstants.VOICE_ROBUSTNESS ? (
                  <>
                    { renderLabelElement('Noise Selection', AppConstants.EMPTY) }
                    { renderLabelElement(AppConstants.EMPTY, this.createMobileNoiseDisplay(
                      this.props.params.customOptions, this.props.params.testSuite)) }
                  </>
                ) : (
                  <>
                    { renderLabelElement('Custom Options', AppConstants.EMPTY) }
                    { renderLabelElement(AppConstants.EMPTY, this.customOptPretty(this.props.params.customOptions,
                      this.props.params.testSuite)) }
                  </>
              )) : (
                <>
                  { renderLabelElement('Custom Options', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getCustomTestOptions()) }
                </>
              )
              )}
            {
              /* Display "Trainers" list for scenarios applicable to training */
              this.props.params.testType === AppConstants.CUSTOMIZED &&
                AppConstants.SCENARIOS_WITH_TRAINERS.includes(this.props.params.scenarioType) &&
                (
                  <>
                    { renderLabelElement('Trainers', AppConstants.EMPTY) }
                    { renderLabelElement(AppConstants.EMPTY,
                      this.getTrainersPretty(this.props.params.customOptions)) }
                  </>
                )
            }
            { this.props.params.testType === AppConstants.CUSTOMIZED &&
              this.props.params.customizedTestType !== AppConstants.EMPTY &&
              this.props.params.scenarioType !== AppConstants.MOBILE_FUNCTIONAL &&
              renderLabelElement('Custom Sub-Test Type', AppConstants.EMPTY) }
            { this.props.params.testType === AppConstants.CUSTOMIZED &&
              this.props.params.customizedTestType !== AppConstants.EMPTY &&
              this.props.params.scenarioType !== AppConstants.MOBILE_FUNCTIONAL &&

              renderLabelElement(AppConstants.EMPTY,
                    getDisplayNameForId(AppConstants.CUSTOMIZED_SCENARIOS, this.props.params.customizedTestType)) }
            { (this.props.params.scenarioType === FunctionalTestCases.FUNC_CUSTOM ||
              this.props.params.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ||
              (this.props.params.scenarioType === AppConstants.MOBILE_FUNCTIONAL &&
                this.props.params.testType === AppConstants.CUSTOMIZED ||
                this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION)) && (
              <>
                { renderLabelElement('Functional Test Cases', AppConstants.EMPTY) }
                { ( this.props.params.scenarioType === FunctionalTestCases.FUNC_CUSTOM
                    || this.props.params.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ) ?
                  (
                    renderLabelElement(AppConstants.EMPTY, this.getFunctionalTestCasesPretty(this.props.params))
                  ) : (
                    renderLabelElement(AppConstants.EMPTY, this.getMobileFunctionalTestCasesPretty(this.props.params.customOptions))
                  )
                }
              </>
            )}
            {/*If scenarioType is MOBILE_FUNCTIONAL and TestCategory is DEVICE_LOCK_HANDS_FREE_VERIFICATION: */}
            {/*  - display "Lock Type"*/}
            { (this.props.params.scenarioType === AppConstants.MOBILE_FUNCTIONAL &&
              this.props.params.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) && (
              <>
                { renderLabelElement('Lock Type', AppConstants.EMPTY) }
                { renderLabelElement(AppConstants.EMPTY,
                  getDisplayNameForId(AppConstants.LOCK_TYPES, customOptions.lockType)) }
              </>
            ) }
            { (AppConstants.FAR_TEST_TYPES.includes(this.props.params.scenarioType) ||
              this.props.params.testSuite === AppConstants.STABILITY_SCENARIO_ID  ||
                this.props.params.testSuite === AppConstants.UPL_SCENARIO_ID) &&
              renderLabelElement('Start Time (UTC)', AppConstants.EMPTY) }
            { (AppConstants.FAR_TEST_TYPES.includes(this.props.params.scenarioType) ||
              this.props.params.testSuite === AppConstants.STABILITY_SCENARIO_ID ||
                this.props.params.testSuite === AppConstants.UPL_SCENARIO_ID) &&
              renderLabelElement(AppConstants.EMPTY, this.props.params.startTime.replace('T', ' ')) }          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details feeds without TestType, which also includes StartTime as a field.
   * This is to be used for scenarios which do not have any test types, such as UPL, Stability.
   */
  renderTestDetailsFeedsWithoutTestType = () => {
    const { customOptions = {} } = this.props.params;
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Login ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { renderLabelElement('Locale', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { renderLabelElement(this.getTestTypeLabelToDisplay(this.props.params.testSuite), AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getScenarioTypeToDisplay(this.props.params.scenarioType,
                this.props.params.testSuite)) }
            { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.dsn) }
            { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            { renderLabelElement('Lab ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
            { renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.deviceName) }
            { renderLabelElement('Email Notification ', AppConstants.EMPTY) }
            { this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { renderLabelElement(AppConstants.EMPTY, 'Enabled') }
                  </div>
                )}
            { !this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { renderLabelElement(AppConstants.EMPTY, 'Disabled') }
                  </div>
                )}
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              renderLabelElement('Proxy Enabled', AppConstants.EMPTY)
            }
            {
              customOptions.hasOwnProperty('isProxyEnabled') &&
              (
                <div>{renderLabelElement(AppConstants.EMPTY, `${customOptions.isProxyEnabled}`)}</div>
              )
            }
            {
                <div>
                   { renderLabelElement('Start Time (UTC)', AppConstants.EMPTY) }
                </div>
            }
            {
                <div>
                  { renderLabelElement(AppConstants.EMPTY, this.props.params.startTime.replace('T', ' ')) }
                </div>
            }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details feeds for Auto Local Search scenario
   */
  renderAutoLocalSearchTestDetailsFeeds = () => {
    return (
        <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Login ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { renderLabelElement('Locale', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { renderLabelElement(this.getTestTypeLabelToDisplay(this.props.params.testSuite), AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getScenarioTypeToDisplay(this.props.params.scenarioType,
                this.props.params.testSuite)) }
            { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.dsn) }
            { renderLabelElement('Device Virtual Assistant', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.getDeviceVirtualAssistant(this.props.params.testSuite, this.props.params.customOptions)) }
            { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            { renderLabelElement('Lab ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
            { renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.deviceName) }
            { renderLabelElement('Email Notification ', AppConstants.EMPTY) }
            { this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { renderLabelElement(AppConstants.EMPTY, 'Enabled') }
                  </div>
                )}
            { !this.props.params.sendCompletionEmail &&
                (
                  <div>
                    { renderLabelElement(AppConstants.EMPTY, 'Disabled') }
                  </div>
                )}
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details feeds for Music
   */
  renderMusicTestDetailsFeeds = () => {
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Login ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.ownerEmail) }
            { renderLabelElement('Locale', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.MARKETPLACE,
              this.props.params.marketPlace)) }
            { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.customerId) }
            { renderLabelElement(MusicConstants.MUSIC_SCENARIO_LABEL, AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getDisplayNameForId(MusicConstants.MSP_TYPE, this.props.params.scenarioType)) }
            { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                this.props.params.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : this.props.params.dsn) }
            { renderLabelElement('Test Type', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getTestTypeDisplay(this.props.params.testType, this.props.params.scenarioType, this.props.params.testSuite)) }
            { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY,
                getAmazonIdToDisplay(this.props.params.deviceType, this.props.params.scenarioType)) }
            { renderLabelElement('Device Name ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.deviceName) }
            { renderLabelElement('Lab ', AppConstants.EMPTY) }
            { renderLabelElement(AppConstants.EMPTY, this.props.params.labName) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test details
   */
  render() {
    if (!this.props.params.testSuite
          || this.props.params.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
          || this.props.params.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      // Acoustic or Functional case
      return this.renderAcousticTestDetailsFeeds();
    } else if (this.props.params.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
      // Music case
      return this.renderMusicTestDetailsFeeds();
    } else if (this.props.params.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return this.renderAutoLocalSearchTestDetailsFeeds();
    } else if ([AppConstants.UPL_SCENARIO_ID, AppConstants.STABILITY_SCENARIO_ID].includes(this.props.params.testSuite)) {
      return this.renderTestDetailsFeedsWithoutTestType();
    } else if (this.props.params.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
      return (
        <SecurityTestDetails
          params={this.props.params}
        />
      );
    }
    // Default: Return Acoustic feeds when test suite is none of Acoustic, Functional or Music
    return this.renderAcousticTestDetailsFeeds();
  }
}

TestDetails.propTypes = {
  params: PropTypes.object.isRequired
};

export default TestDetails;
