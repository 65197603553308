import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';
import { getErrorTextForDropdown } from './Util';
import { getCommonSelect } from './uiUtils';
import { sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';
import AppConstants from '../../_Constants/AppConstants';
import {
  generateCustomOptionsForCustomized, getValueForCheckbox,
  getNoiseValuesForActor
} from './CustomOptionsGeneratorUtil';
import { AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS_TABLE_CONFIG, AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS } from './Automotive/AutomotiveCustomOptionsDetailsTableConfig';
import { getTrainedUsers , getNoisesWithLevels } from './Mobile/Utils';
import { isMobileScenario, isVoiceRobustnessScenario, logToConsole } from '../../Util';

class CustomizeOptionsSubPage extends React.Component {

  updateCustomSelection = (event, actor, noise) => {
    const { selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack, whitelistedScenarioIds } = this.props.params;
    let dumpTrainedUsers = []; // will be useful for mobile
    let currCustomOptions = generateCustomOptionsForCustomized(event, actor, noise, selectedScenario.scenarioType,
      selectedScenario.testType, selectedScenario.testOptions.customOptions,
      dumpTrainedUsers);
    let updatedScenario = selectedScenario;
    let updatedScenarioError = selectedScenarioError;
    updatedScenario.testOptions.customOptions = currCustomOptions;
    updatedScenario.testOptions.Smoke = false;
    updatedScenario.testOptions.testSuite = selectedScenario.testSuite;
    updatedScenario.testOptions.scenarioType = selectedScenario.scenarioType;
    updatedScenarioError.testOptions = false;
    if (AppConstants.AUTOMOTIVE_TEST_TYPES.includes(selectedScenario.scenarioType)) {
      this.updateAutomotiveCustomSelection(updatedScenario)
    }
    sendRawInfoToNewRun(updatedScenario, selectedScenarioCallBack);
    sendRawInfoToNewRun(updatedScenarioError, scenarioErrorCallBack);
  }

  updateAutomotiveCustomSelection = (updatedScenario) => {
    const { selectedScenario } = this.props.params;
    if (selectedScenario.testType !== 'CUSTOMIZED') {
      updatedScenario.testOptions.customizedTestType = 'CUSTOM_'+selectedScenario.testType;
      updatedScenario.customizedTestType = 'CUSTOM_'+selectedScenario.testType;
    } else {
      updatedScenario.testOptions.customizedTestType = selectedScenario.testType;
      updatedScenario.customizedTestType = selectedScenario.testType;
    }
  }

  /**
   * TODO: apply this logic to other test suites.
   * checkbox operation or multiselection option on customized selection
   * @param {*} event
   * @param {*} actor
   * @param {*} noise
   */
  onChangeCheck = (event, actor, noise) => {
    this.updateCustomSelection(event,actor,noise);
  }

  /**
   * Applies for dropdowns such as Test Suite
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   */
  getSelectField = (id, label, hintText, options, selected, idError) => {
    let shouldDisable = false;
    let dumpList = []; // dumpList doesn't have meanings for now for non dsn selection, check function for details
    let errorText = getErrorTextForDropdown(id, idError, dumpList, shouldDisable);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelect);
  }

  // Actions when selection box item is selected.
  // send data to newRun page
  onChangeSelect = (id, event) => {
    const { selectedScenario, selectedScenarioCallBack } = this.props.params;
    let updatedScenario = selectedScenario;
    updatedScenario[id] = event.detail.selectedOption.id;
    if (AppConstants.AUTOMOTIVE_TEST_TYPES.includes(selectedScenario.scenarioType)) {
      updatedScenario.testOptions.customizedTestType = event.detail.selectedOption.id;
    }
    sendRawInfoToNewRun(updatedScenario, selectedScenarioCallBack);
  }

  /**
   * For Mobile Voice Training usage
   * Method which selects one or more trainers from "Trainers" dropdown for
   * Mobile Voice training customized test
   * @param event Event which selects or deselects multiple trainers
   * @param {*} params selectedScenario where trainedUsers is a field
   * @param {*} updateParamsCallBack callback function to update params.trainedUsers
   * @param {*} paramsError
   * @param {*} updateParamsErrorCallBack
   */
  onChangeMultiSelect = (event, params, updateParamsCallBack,
                         paramsError, updateParamsErrorCallBack) => {
    const identifier = 'CustomizeOptionsSubPage, onChangeMultiSelect';
    let updatedParams = params;
    updatedParams.trainedUsers = event.detail.selectedIds;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);
    let updatedParamsError = paramsError;
    updatedParamsError.trainedUsers = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
  }

  /**
   * For Mobile Voice Enrollment Robustness usage
   * Method which selects one or more noises from "NoiseTypes" dropdown
   * @param event which selects or deselects multiple noises
   * @param {*} locations to send noise pi location
   * @param {*} params selectedScenario where noiseSelection is a field
   * @param {*} updateParamsCallBack callback function to update params.noiseSelection
   * @param {*} paramsError
   * @param {*} updateParamsErrorCallBack
   */

  onChangeMultiSelectNoises = (event, locations,  params, updateParamsCallBack,
                               paramsError, updateParamsErrorCallBack) => {

    const identifier = 'CustomizeOptionsSubPage, onChangeMultiSelect';
    let updatedParams = params;
    updatedParams.noiseSelection = event.detail.selectedIds;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);
    let updatedParamsError = paramsError;
    updatedParamsError.noiseSelection = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
    this.updateCustomSelection(event, locations, updatedParams.noiseSelection );
  }

  /**
   *
   * @param {*} locations
   * @param {*} selectedScenario
   * @param {*} selectedScenarioCallBack
   * @param {*} selectedScenarioError
   */
  getCustomOptions = (locations, selectedScenario, selectedScenarioCallBack,
    selectedScenarioError, scenarioErrorCallBack) => {
    let numLocation = Object.keys(locations).length - 1;
    let customizedTestType = isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.customizedTestType;
    let currentError = isEmpty(selectedScenarioError) ? false : selectedScenarioError.testOptions;
    let currentCustomizedTestTypeError = isEmpty(selectedScenarioError) ? false : selectedScenarioError.customizedTestType;
    const errorText = currentError ? AppConstants.CUSTOM_OPTIONS_ERROR : AppConstants.EMPTY;
    return (
      <AWSUI.FormField
        errorText={errorText}
        label={<h3>Custom Options</h3>}
        className='awsui-util-m-l'
      >
        { !(AppConstants.AUTOMOTIVE_TEST_TYPES.includes(selectedScenario.scenarioType) &&
          (selectedScenario.testType === AppConstants.STANDARD ||
            selectedScenario.testType === AppConstants.QUICK ||
            selectedScenario.testType === AppConstants.DEMO)) &&
          this.getSelectField('customizedTestType', AppConstants.EMPTY, 'Select the type of Custom test to run',
            AppConstants.CUSTOMIZED_SCENARIOS,
            customizedTestType ? customizedTestType : AppConstants.CUSTOM_STANDARD, currentCustomizedTestTypeError)
        }
        <AWSUI.ColumnLayout columns={2} borders='vertical' className='awsui-util-p-l'>
          <div data-awsui-column-layout-root='true'>
            {!AppConstants.AUTOMOTIVE_TEST_TYPES.includes(selectedScenario.scenarioType) &&
             [...Array(numLocation).keys()].map(i => {
               return (
                 <div key={i}>{this.getCustomOptField(locations[i + 1], selectedScenario)}</div>
               )
            })}
            {
              selectedScenario.scenarioType === AppConstants.TRAINED_MOBILE &&
              (
                <AWSUI.FormField
                  label={<h4><b>Trainers</b></h4>}
                  hintText='Select one or more trainers from the dropdown'
                  errorText={selectedScenarioError.trainedUsers ? AppConstants.EMPTY_TRAINERS_ERROR : AppConstants.EMPTY}
                >
                  <div style={{ maxWidth: 600 }}>
                    <AWSUI.Multiselect
                      enableFiltering={true}
                      selectedIds={selectedScenario.trainedUsers}
                      options={getTrainedUsers(selectedScenario)}
                      onChange={event => { this.onChangeMultiSelect(event, selectedScenario, selectedScenarioCallBack,
                        selectedScenarioError, scenarioErrorCallBack) }}
                      keepOpen={true}
                    ></AWSUI.Multiselect>
                  </div>
                </AWSUI.FormField>
              )
            }
            {
              AppConstants.AUTOMOTIVE_TEST_TYPES.includes(selectedScenario.scenarioType) &&
              (
                <div>{this.getAutomotiveCustomOptField(locations[0], selectedScenario)}</div>
              )
            }
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }

  getCustomOptionsForVoiceRobustness = (locations, selectedScenario, selectedScenarioCallBack,
                                        selectedScenarioError, scenarioErrorCallBack) => {

    let numLocation = Object.keys(locations).length - 1;
    let currentError = isEmpty(selectedScenarioError) ? false : selectedScenarioError.testOptions;
    const errorText = currentError ? AppConstants.CUSTOM_OPTIONS_ERROR : AppConstants.EMPTY;
    return (
      <AWSUI.FormField
        errorText={errorText}
        label={<h3>Custom Options</h3>}
        className='awsui-util-m-l'
      >
        <AWSUI.ColumnLayout columns={2} borders='vertical' className='awsui-util-p-l'>
          <div data-awsui-column-layout-root='true'>
            {[...Array(numLocation).keys()].map(i => {
              return (
                <div key={i}>{this.getCustomOptFieldForVoiceRobustness(locations[i + 1], selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack)}</div>
              )
            })}
            {
              selectedScenario.scenarioType === AppConstants.VOICE_ROBUSTNESS &&
              (
                <AWSUI.FormField
                  label={<h4><b>Trainers</b></h4>}
                  hintText='Select one or more trainers from the dropdown'
                  errorText={selectedScenarioError.trainedUsers ? AppConstants.EMPTY_TRAINERS_ERROR : AppConstants.EMPTY}
                >
                  <div style={{ maxWidth: 800 }}>
                    <AWSUI.Multiselect
                      enableFiltering={true}
                      selectedIds={selectedScenario.trainedUsers}
                      options={getTrainedUsers(selectedScenario)}
                      onChange={event => { this.onChangeMultiSelect(event, selectedScenario, selectedScenarioCallBack,
                        selectedScenarioError, scenarioErrorCallBack) }}
                      keepOpen={true}
                    />
                  </div>
                </AWSUI.FormField>
              )
            }
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }

  getCustomOptFieldForVoiceRobustness = (location, selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack ) => {
    return(
      <AWSUI.FormField
        label={<h4><b>Noise Types</b></h4>}
        hintText='Select one or more noises from the dropdown'
        errorText={selectedScenarioError.noiseSelection ? AppConstants.EMPTY_NOISES_ERROR : AppConstants.EMPTY}
      >
        <div style={{ maxWidth: 800 }}>
          <AWSUI.Multiselect
            enableFiltering={true}
            selectedIds={selectedScenario.noiseSelection}
            options={getNoisesWithLevels()}
            onChange={event => {
              this.onChangeMultiSelectNoises(event, location.display, selectedScenario, selectedScenarioCallBack,
                selectedScenarioError, scenarioErrorCallBack)
            }}
            keepOpen={true}
          />
        </div>
      </AWSUI.FormField>
    )
  }

  /**
   * TODO: don't have to support so many scenarios, or extract it out to a public function.
   * TODO: may need to support mobile scenarios
   * @param {} location
   * @param {*} selectedScenario
   */
  getCustomOptField = (location, selectedScenario) => {
    // For Close Talk scenario, 0.9 m 90 deg is displayed as HATS in the UI
    let displayLabel = selectedScenario.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID ?
      AppConstants.CT_HATS_LOCATION:
      location.display;
    let customOptions = selectedScenario.testOptions.customOptions;
    let noiseValuesForLocation = getNoiseValuesForActor(customOptions, location.display);
    return (
      <AWSUI.FormField
        label={<h4>{displayLabel}</h4>}
      >
        <AWSUI.ColumnLayout columns={2} className='awsui-util-container'>
          <div data-awsui-column-layout-root='true' className='awsui-util-pb-xxl'>
            <div>
              <div className='awsui-util-pb-m'>
                <AWSUI.FormField
                  label={<b>Silence</b>}
                />
              </div>
              <AWSUI.Checkbox
                label={AppConstants.noiseTypesMap[AppConstants.SILENCE]}
                checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.SILENCE])}
                onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.SILENCE]) }} />
            </div>
            <div>
              <div className='awsui-util-pb-m'>
                <AWSUI.FormField
                  label={<b>Noise Types</b>}
                />
              </div>

              {!isVoiceRobustnessScenario(selectedScenario.scenarioType) && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.DEVICE]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.DEVICE])}
                  onChange={event => {
                    this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.DEVICE]) }} />)
              }
              {selectedScenario.scenarioType !== AppConstants.CT_WAKE_AND_RESPONSE && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.KITCHEN]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.KITCHEN])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.KITCHEN]) }} />)
              }
              {selectedScenario.scenarioType !== AppConstants.CT_WAKE_AND_RESPONSE && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.LAPTOP]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.LAPTOP])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.LAPTOP]) }} />)
              }
              {selectedScenario.scenarioType === AppConstants.CT_WAKE_AND_RESPONSE && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.PINK]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.PINK])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.PINK]) }} />)
              }
              {selectedScenario.scenarioType === AppConstants.CT_WAKE_AND_RESPONSE && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.ROCK]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.ROCK])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.ROCK]) }} />)
              }
              {isMobileScenario(selectedScenario.scenarioType) && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.CAR]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.CAR])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.CAR]) }} />)
              }
              {isMobileScenario(selectedScenario.scenarioType) && (
                <AWSUI.Checkbox
                  label={AppConstants.noiseTypesMap[AppConstants.PUB]}
                  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.noiseTypesMap[AppConstants.PUB])}
                  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.noiseTypesMap[AppConstants.PUB]) }} />)
              }
            </div>
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    )
  }

/**
 * Display Automotive Custom Test Options
 * @param {} location
 * @param {*} selectedScenario
 */
getAutomotiveCustomOptField = (location, selectedScenario) => {

  let displayLabel = location.display;

  let customOptions = selectedScenario.testOptions.customOptions;
  let noiseValuesForLocation = getNoiseValuesForActor(customOptions, location.display);

  return (
    <AWSUI.FormField
      label={<h4>{displayLabel}</h4>}
    >
      <AWSUI.ColumnLayout columns={2} className='awsui-util-container'>
        <div data-awsui-column-layout-root='true' className='awsui-util-pb-xxl'>
          <div>
            <div className='awsui-util-pb-m'>
              <AWSUI.FormField
                label={<b>Custom Options</b>}
              />
            </div>
            <AWSUI.Checkbox
              label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_TRAFFIC_LIGHT_MODE']}
              checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_TRAFFIC_LIGHT_MODE'])}
              onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_TRAFFIC_LIGHT_MODE']) }} />
            {/*<AWSUI.Checkbox*/}
            {/*  label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED']}*/}
            {/*  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED'])}*/}
            {/*  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED']) }} />*/}
            <AWSUI.Checkbox
              label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_DEVICE_PLAYBACK']}
              checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_DEVICE_PLAYBACK'])}
              onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_DEVICE_PLAYBACK']) }} />
          </div>
          <div>
            <div className='awsui-util-pb-m'>
              <AWSUI.FormField
                label={<b></b>}
              />
            </div>
            {/*<AWSUI.Checkbox*/}
            {/*  label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED']}*/}
            {/*  checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED'])}*/}
            {/*  onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED']) }} />*/}
            <AWSUI.Checkbox
              label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN']}
              checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN'])}
              onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN']) }} />
            <AWSUI.Checkbox
              label={AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED_DEVICE_PLAYBACK']}
              checked={getValueForCheckbox(noiseValuesForLocation, AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED_DEVICE_PLAYBACK'])}
              onChange={event => { this.onChangeCheck(event, location.display, AppConstants.automotiveCustomOptions['AUTOMOTIVE_HIGH_SPEED_DEVICE_PLAYBACK']) }} />
          </div>
        </div>
        <div>
          <AWSUI.Table
            columnDefinitions={AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS_TABLE_CONFIG}
            items={AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS}
          >
          </AWSUI.Table>
      </div>
      </AWSUI.ColumnLayout>
    </AWSUI.FormField>
    )
  }

  /**
   * For Mobile Voice Training usage
   * Method which selects one or more trainers from "Trainers" dropdown for
   * Mobile Voice training customized test
   * @param event Event which selects or deselects multiple trainers
   * @param {*} params selectedScenario where trainedUsers is a field
   * @param {*} updateParamsCallBack callback function to update params.trainedUsers
   * @param {*} paramsError
   * @param {*} updateParamsErrorCallBack
   */
  onChangeMultiSelect = (event, params, updateParamsCallBack,
    paramsError, updateParamsErrorCallBack) => {
    const identifier = 'CustomizeOptionsSubPage, onChangeMultiSelect';
    let updatedParams = params;
    updatedParams.trainedUsers = event.detail.selectedIds;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);
    let updatedParamsError = paramsError;
    updatedParamsError.trainedUsers = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
  }

  //TODO: need to handle error text properly
  render() {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack, piLocations } = this.props.params;
    return (
      <div>
        {
        (selectedScenario.farFieldCustomOptionsSelected === true ||
         selectedScenario.nearFieldCustomOptionsSelected === true ||
         selectedScenario.trainedMobileCustomOptionsSelected === true ||
         selectedScenario.closeTalkCustomOptionsSelected === true ||
         selectedScenario.automotiveCustomOptionsSelected === true ) && (
          this.getCustomOptions(piLocations, selectedScenario, selectedScenarioCallBack,
            selectedScenarioError, scenarioErrorCallBack))
        }
        {
          selectedScenario.mobileVoiceRobustnessCustomOptionsSelected === true && (
            this.getCustomOptionsForVoiceRobustness(piLocations, selectedScenario, selectedScenarioCallBack,
            selectedScenarioError, scenarioErrorCallBack))
        }
      </div>
    );
  }
}

export default CustomizeOptionsSubPage;


