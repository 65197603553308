/**
 * Component to render Functional Utterances for Alexa Cloud Validation
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import FunctionalTestCases from '../../../_Constants/FunctionalTestCasesConstants';
import { FUNCTIONAL_EXPANDABLE_COLUMNS } from './FunctionalTestMetricsTableConfig';
import { FUNCTIONAL_UTTERANCES_COLUMNS } from './FunctionalUtterancesTableConfig';
import './FunctionalLiveFeedStyles.css';
import { getOverallTestStatusList, getCompletedTestsNum, getCompletedTestsStats,
  getOverallEta, getTestStatusForValidationType, getUtterancesForValidationType,
  getInProgressTestNameDisplay, getUtteranceStatus, getExpandableSectionContent, setValueForExpandedSection,
  getFunctionalTestMetrics, getValueForUtteranceFeed }
  from './FunctionalLiveFeedUtil';
import { getLoadingMessage } from '../../../Util';

/**
 * Component to render Functional Utterances under new Utterances tab
 */
class FunctionalUtteranceDetails extends React.Component {
  state = {
    expandedSections: [],
    elapsedSeconds: 0
  };

  componentWillMount() {
    // While loading the component, check if it was navigated through clicking "Utterances"
    // button in Test metrics tab & if so, add apporpriate expandable control to list of
    // expanded sections
    if (this.props.params.utteranceDetailsTestName !== AppConstants.EMPTY) {
        let expandedSectionsTemp = this.state.expandedSections;
        expandedSectionsTemp.push(this.props.params.utteranceDetailsTestName);
        this.setState({
            expandedSections: expandedSectionsTemp
        });
    }
    this.loadFunctionalUtterancesData();
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ elapsedSeconds: this.state.elapsedSeconds + 1 }), 1000);
  }

  /**
   * Gets the expandable section for Functional test case
   * @param functionalTestCaseName Name of Functional Test case
   * @param cloudValidationStatus Cloud validation status for  the test case
   * @param utterancesData Utterances Data for the test case
   * @returns Expandable section for test case
   */
  getExpandableSectionForTestCase = (functionalTestCaseName, cloudValidationStatus, utterancesData) => {
    let isCategoryExpanded = this.state.expandedSections.includes(functionalTestCaseName);
    return (
      <AWSUI.ExpandableSection
        id = { functionalTestCaseName }
        header={
          getExpandableSectionContent(functionalTestCaseName, cloudValidationStatus, isCategoryExpanded)
        }
        expanded={ isCategoryExpanded }
        onChange={ event => { this.onSectionExpanded(functionalTestCaseName, event) } }
      >
       <AWSUI.Table
        columnDefinitions={ FUNCTIONAL_UTTERANCES_COLUMNS }
        items={ utterancesData }
        variant='borderless'
        >
       </AWSUI.Table>
      </AWSUI.ExpandableSection>
    )
  }

  /**
   * Event handler for expanding/ collapsing Functional test case section
   */
  onSectionExpanded = (id, event) => {
    let expandedSectionsIn = setValueForExpandedSection(id, event, this.state.expandedSections);
    this.setState({
      expandedSections: expandedSectionsIn
    });
    this.loadFunctionalUtterancesData();
  }

  /**
   * Renders test metrics feeds for Functional
   */
  renderFunctionalTestMetricsFeeds = () => {
    const isDataAvailable = Object.keys(this.props.params).length > 0;
    let completedTestsStats, startTime, elapsedTime, eta;
    let currentFunctionalTest = AppConstants.UNAVAILABLE;
    let questionPlayed = AppConstants.RETRIEVING;
    let dutHeard = AppConstants.RETRIEVING;
    let dutResponded = AppConstants.RETRIEVING;
    let scoreFrr;
    let scoreRar;
    let labJobId = AppConstants.EMPTY;
    let utteranceValidationType = AppConstants.EMPTY;
    let utteranceStatus = FunctionalTestCases.FAILED;

    if (isDataAvailable) {
      startTime = this.props.params.startTime;
      elapsedTime = this.props.params.elapsedTime;
      labJobId = this.props.params.labJobId;

      let functionalStatusList = getOverallTestStatusList(this.props.params.functionalTestCases);
      let completedTestsNum = getCompletedTestsNum(functionalStatusList);
      completedTestsStats = getCompletedTestsStats(completedTestsNum, functionalStatusList);
      eta = getOverallEta(this.props.params.avgTestTime, completedTestsNum, this.props.params.functionalTestCases);
      currentFunctionalTest = getInProgressTestNameDisplay(this.props.params.currentFunctionalTest);
      utteranceValidationType = this.props.params.utteranceValidationType;
      utteranceStatus = this.props.params.utteranceStatus;

      if (this.props.params.testStats && Object.keys(this.props.params.testStats).length > 0) {
        let currentUtteranceData = getValueForUtteranceFeed(labJobId + FunctionalTestCases.UTTERANCE_DATA_KEY,
          this.props.params.questionPlayed,
          this.props.params.dutHeard,
          this.props.params.dutResponded,
          this.props.params.scoreFrr,
          this.props.params.scoreRar,
          this.props.params.utteranceStatus,
          this.props.params.utteranceAttribute
        );

        questionPlayed = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.QUESTION_PLAYED] :
          AppConstants.RETRIEVING;
        dutHeard = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_HEARD] :
          AppConstants.RETRIEVING;
        dutResponded = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_RESPONDED] :
          AppConstants.RETRIEVING;
        scoreFrr = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.FRR_KEY] :
          AppConstants.RETRIEVING;
        scoreRar = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.RAR_KEY] :
          AppConstants.RETRIEVING;
        utteranceStatus = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.STATUS_KEY] :
          AppConstants.RETRIEVING;
      }
    }

    return (
      getFunctionalTestMetrics(completedTestsStats, currentFunctionalTest, startTime, questionPlayed, elapsedTime,
        dutHeard, eta, dutResponded, scoreFrr, scoreRar, utteranceValidationType, utteranceStatus)
    );
  }

  /**
   * Gets the back button to navigate back to Test Metrics tab
   * @param index Index of utterance -> Button will be displayed only once next to 1st utterance
   * @returns Back button which can be clicked to navigate to test metrics tab
   */
  getBackButton = (index) => {
    if (index === 0) {
      return (
        <div>
        <AWSUI.Button
          variant='normal'
          icon='caret-left'
          text='Test Metrics'
          onClick={ () => this.handleBackButtonClicked() }/>
        </div>
      )
    }
    return (<div></div>)
  }

  /**
   * Handler which sends data (Test Metrics tab) to parent FeedsContainer so that
   * Test Metrics tab can be navigated on clicking the Back button
   */
  handleBackButtonClicked = () => {
    this.props.onBackButtonClicked('testMetricsTab');
  }

  /**
   * Gets text to display in comments section, if any
   * @returns Comments text in display friendly format
   */
  getCommentsText = (utteranceValidationType, status, scoreFrr, questionPlayed) => {
    let commentsTextPre = AppConstants.EMPTY;
    let failedStatusText = AppConstants.EMPTY;
    let commentsTextPost = AppConstants.EMPTY;
    let questionPlayedText = AppConstants.EMPTY;
    if (utteranceValidationType !== FunctionalTestCases.UTTERANCE_REGEX_TYPE_VALIDATION
          && scoreFrr === 1
          && (status === FunctionalTestCases.FAILED || status === FunctionalTestCases.ERROR)) {
            commentsTextPre = 'DUT ';
            failedStatusText = 'Failed ';
            commentsTextPost = ' to invoke "';
            questionPlayedText = questionPlayed + '"';
          }
    return (
        <span className='utterance-comments-style'>
          { commentsTextPre }
          <span className='utterance-comments-failed-status-style'>
            <b>
              { failedStatusText }
            </b>
          </span>
          { commentsTextPost + questionPlayedText }
        </span>
    )
  }

  /**
   * Loads the functional utterances data
   * @returns Data for functional utterances in tabular format
   */
  loadFunctionalUtterancesData = () => {
    let functionalTestsDataIn = [];
    // Read functional test cases JSON from params
    let functionalTestsFromJson = this.props.params.functionalTestCases;

    if (functionalTestsFromJson && functionalTestsFromJson.length > 0) {
      // Iterate over list of functional test cases
      for (let i = 0; i < functionalTestsFromJson.length; i++) {
        let currentTestCase = functionalTestsFromJson[i];
        if (currentTestCase) {
          let functionalTestCaseName = AppConstants.UNKNOWN;
          // Get a display friendly test case name
          if (FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY.hasOwnProperty(currentTestCase.testCaseName)) {
            functionalTestCaseName = FunctionalTestCases.FUNCTIONAL_TEST_CASES_DISPLAY[currentTestCase.testCaseName];
          }
          if (FunctionalTestCases.FUNCTIONAL_NAV_TEST_CASES_DISPLAY.hasOwnProperty(currentTestCase.testCaseName)) {
            functionalTestCaseName = FunctionalTestCases.FUNCTIONAL_NAV_TEST_CASES_DISPLAY[currentTestCase.testCaseName];
          }
          // Retrieve following information for the test:
          // 1. Cloud Validation Status
          // 2. List of utterances
          let testStatusData = currentTestCase.testCaseData;
          let cloudValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let utterances = getUtterancesForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let utterancesData = [];

          if (utterances) {
              let utteranceNumber = 0;
              for (let j = 0; j < utterances.length; j++) {
                let questionPlayed = utterances[j].questionPlayed ? utterances[j].questionPlayed : AppConstants.UNAVAILABLE;
                // If this is an utterance to be ignored, skip it & don't display in UI
                if (utterances[j].hasOwnProperty('utteranceAttribute')
                      && utterances[j].utteranceAttribute === FunctionalTestCases.UTTERANCE_IGNORE) {
                  continue;
                }
                utteranceNumber += 1;
                let dutHeard = utterances[j].dutHeard ? utterances[j].dutHeard : AppConstants.UNAVAILABLE;
                let dutResponded = utterances[j].dutResponded ? utterances[j].dutResponded : AppConstants.UNAVAILABLE;
                questionPlayed = utteranceNumber + '. ' + questionPlayed;

                // Retrieve Utterance validation type for current utterance
                let utterValidationInfo = utterances[j].utterValidationInfo ? utterances[j].utterValidationInfo : {};
                let utteranceValidationType = AppConstants.EMPTY;
                if (utterValidationInfo
                      && Object.keys(utterValidationInfo).length > 0
                      && utterValidationInfo.hasOwnProperty('utterValidationType')) {
                         utteranceValidationType = utterValidationInfo['utterValidationType'];
                }

                utterancesData.push({
                    questionPlayed: getUtteranceStatus(questionPlayed, utterances[j].status, 0,
                      FunctionalTestCases.QUESTION_PLAYED, utteranceValidationType),
                    dutHeard: getUtteranceStatus(dutHeard, utterances[j].status, utterances[j].scoreFalseRejectionRate,
                      FunctionalTestCases.DUT_HEARD, utteranceValidationType),
                    dutResponded: getUtteranceStatus(dutResponded, utterances[j].status, utterances[j].scoreResponseAccuracyRate,
                      FunctionalTestCases.DUT_RESPONDED, utteranceValidationType),
                    comments: this.getCommentsText(utteranceValidationType, utterances[j].status, utterances[j].scoreFalseRejectionRate,
                      utterances[j].questionPlayed),
                    backToTestMetrics: this.getBackButton(j)
                })
              }
          }

          functionalTestsDataIn.push({
            functionalCategory: this.getExpandableSectionForTestCase(functionalTestCaseName, cloudValidationStatus, utterancesData)
          });
        }
      }
    }
    return functionalTestsDataIn;
  }

  render() {
    // Refresh functional stats table upon every render
    let functionalTestsData = this.loadFunctionalUtterancesData();
    return (
        functionalTestsData && functionalTestsData.length > 0 ? (
          <div>
            {
              this.renderFunctionalTestMetricsFeeds()
            }
            <div>
              <AWSUI.Table
                columnDefinitions={ FUNCTIONAL_EXPANDABLE_COLUMNS }
                items={ functionalTestsData }
              >
              </AWSUI.Table>
            </div>
          </div>
        ) : (
          this.state.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
            ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
        )
    );
  }
}

FunctionalUtteranceDetails.propTypes = {
  onBackButtonClicked: PropTypes.func.isRequired
};

export default FunctionalUtteranceDetails;
