import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';

import AppConstants from '_Constants/AppConstants';
import {getScenariosWithResetCustomOptions} from '../../ScenarioSelectionHelpers';
import {sendRawInfoToNewRun} from "../../NewRunDataTransferUtils";

/**
 * Generic component to load lock types with radio button based on the given props
 * lock types: PIN LOCK, SWIPE LOCK
 */
class LockType extends Component {
  static propTypes = {
    /**
     * Its an array of lockTypes that needs to be selected.
     * eg: [0] will render PIN LOCK
     * Please refer AppConstants for all the lockTypes that we support, as below
     * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/b108f80e1cf6bd9a5c7eed39037d5c394421cb51/--/src/_Constants/AppConstants.js
     */
    allowedTypes: PropTypes.arrayOf(PropTypes.number),
    selectedScenario: PropTypes.object.isRequired,
    selectedScenarioCallBack: PropTypes.func.isRequired,
    selectedScenarioError: PropTypes.object.isRequired,
    scenarioErrorCallBack: PropTypes.func.isRequired
  };

  setLockTypesRadioButtonValues(index, selectedScenarios) {
    let updatedScenarios = selectedScenarios;
    let lockTypesArrIn = [false, false];
    lockTypesArrIn[index] = true;
    updatedScenarios.lockTypeArr = lockTypesArrIn;
    return updatedScenarios;
  }

  /**
   * Setup lock type related state parameters
   * Applies to scenarios with LockType selection
   * @param {*} scenarioType  may not needed
   * @param {*} lockType
   */
  setLockTypeState(scenarioType, lockType, selectedScenarios, scenarioSelectCallBackFun, selectedScenarioError, scenarioErrorCallBack) {
    let updatedScenarios = getScenariosWithResetCustomOptions(selectedScenarios, false);
    let updatedScenariosError = selectedScenarioError;
    let index = 0;
    switch (lockType) {
      case AppConstants.PIN_LOCK:
        index = 0;
        break;
      case AppConstants.SWIPE_LOCK:
        index = 1;
        break;
      default:
        break;
    }

    updatedScenarios = this.setLockTypesRadioButtonValues(index, updatedScenarios);

    updatedScenariosError.lockTypeArr = false;
    updatedScenarios.lockType = lockType;

    sendRawInfoToNewRun(updatedScenarios, scenarioSelectCallBackFun);
    sendRawInfoToNewRun(updatedScenariosError, scenarioErrorCallBack);
  }

  /**
   * On selection, update the lock type
   */
  handleOnChangeLockType = (lockType) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props;
    return (event) => {
      if (!event) return;

      this.setLockTypeState(
        selectedScenario.scenarioType,
        lockType,
        selectedScenario,
        selectedScenarioCallBack,
        selectedScenarioError,
        scenarioErrorCallBack
      );
    }
  }

  render() {
    const { selectedScenario, selectedScenarioError, allowedTypes } = this.props;
    let currentLockTypeArr = [];
    if (isEmpty(selectedScenario)) {
      currentLockTypeArr = [false, false];
    } else {
      currentLockTypeArr = selectedScenario.lockTypeArr;
    }

    let lockTypes = AppConstants.LOCK_TYPES.filter(type => allowedTypes.includes(type.index))
    const options = lockTypes.map(item => (
      <div
        key={`type-${item.index}`}
      >
        <AWSUI.RadioButton
          label={item.label}
          checked={currentLockTypeArr[item.index]}
          onChange={this.handleOnChangeLockType(item.type)}
        />
      </div>
    ));

    const errorText = selectedScenarioError.lockTypeArr
      ? AppConstants.LOCK_TYPES_ERROR
      : AppConstants.EMPTY;

    return (
      <AWSUI.FormField
        errorText={errorText}
        label={<h3>Lock Type</h3>}
        className='awsui-util-m-l'
      >
        <AWSUI.ColumnLayout columns={4} className='awsui-util-p-l'>
          <div data-awsui-column-layout-root='true'>
            {options}
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }
}

export default LockType;
