export const FAR_LIVE_DATA_COLUMNS = [{
  id: 'customerId',
  header: () => 'Customer Id',
  cell: item => item.customerId,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'dsn',
  header: () => 'Device Serial Number',
  cell: item => item.dsn,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'deviceName',
  header: () => 'Device Name',
  cell: item => item.deviceName,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'deviceStatus',
  header: () => 'Device Status',
  cell: item => item.deviceStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'falseWakeCounts',
  header: () => 'False Wake Counts',
  cell: item => item.falseWakeCounts,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];