
const CustomTestConstants = {
    CUSTOM_TEST_RASPI_CONFIGS : [{"label": "1 Pi Configuration","id": "1"}, {"label": "2 Pi Configuration","id": "2"}, {"label": "3 Pi Configuration","id": "3"}],
    CUSTOM_TEST_DUT_OPTIONS: [{"label": "1","id": "1"}, {"label": "2","id": "2"}, {"label": "3","id": "3"}],
    CUSTOM_TEST_RASPI_NAMES: [{"id": "1", "name": "Pi1", "label": "Pi1"}, {"id": "2", "name": "Pi2", "label": "Pi2"}, {"id": "3", "name": "Pi3", "label": "Pi3"}],
    CUSTOM_TEST_DUT_TYPES: [{"id": "Alexa", "label": "Amazon-Alexa"}, {"id": "Google", "label": "Google Assistant"}, {"id": "Siri", "label": "Apple-Siri"}, {"id": "Bixby", "label": "Samsung-Bixby"}, {"id": "Cortana", "label": "Microsoft-Cortana"}, {"id": "Other", "label": "Other"}],

    CUSTOM_TEST_COLUMNS: [{
        id: 'name',
        header: 'Test Name',
        cell: item => item.name,
        minWidth: '300px',
        allowLineWrap: true,
        sortingField: "name"
    },
    {
        id: 'state',
        header: 'Status',
        cell: item => item.state,
        minWidth: '200px',
        sortingField: "state"
    },
    {
        id: 'updatedAt',
        header: 'Last Updated',
        cell: item => item.updatedAt,
        minWidth: '200px',
        sortingField: "updatedAt"
    },
    {
        id: 'viewOrEdit',
        header: 'View/Modify',
        cell: item => ( item.viewOrEditButton(item) ),
        minWidth: '30px'
    },
    {
        id: 'delete',
        header: 'Delete',
        cell: item => ( item.deleteButton(item) ),
        minWidth: '30px'
    },
    {
        id: 'summary',
        header: 'Summary',
        cell: item => ( item.summaryButton(item) ),
        minWidth: '30px'
    },
    {
        id: 'logs',
        header: 'Logs',
        cell: item => ( item.logsButton(item) ),
        minWidth: '30px'
    }],

    CUSTOM_TEST_SORTABLE_COLUMNS: [
        {id: 'name', field: 'name'},
      ],

    WIZARD_RASPI_NAME_TABLE_COLUMNS: [{
        id: 'Raspi',
        header: () => 'Raspi',
        cell: item => ( item.id ),
        minWidth: '20px',
        allowLineWrap: false
      }, {
        id: 'Name',
        header: () => 'Custom Name',
        cell: item => ( item.name ),
        minWidth: '50px',
        allowLineWrap: false
      }],

    VALIDATION_SUMMARY_COLUMNS: [{
        id: 'validation',
        header: 'Validation',
        cell: item => item.label,
        allowLineWrap: true
    },
    {
        id: 'value',
        header: 'Data',
        cell: item => item.value,
        allowLineWrap: true
    }],

    REF_MIC_STATUS_UNAVAILABLE: 'Unavailable',
    REF_MIC_STATUS_ONLINE: true,
    REF_MIC_STATUS_OFFLINE: false,
    STATUS_PENDING: ['IN_PROGRESS', 'QUEUED'],
    ALEXA: 'ALEXA',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    TRANSCRIBE_RESPONSE_MESSAGE: 'Responses from reference mic recordings may be delayed by a few minutes',
    RESPONSE_PENDING_MESSAGE: 'All utterances have been played. Waiting for the response for some of the utterances',

    // Map containing styles & Icons to display utterance status
    UTTERANCE_STATUS_MAP: {
    'SUCCESS': {
        testStatusClass: 'awsui-util-status-positive wrap-text-style',
        testStatusIcon: 'status-positive',
        variant: 'success',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-passed-test-status',
        testStatusValue: 'Succeeded'},
    'FAILURE': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style',
        testStatusIcon: 'status-negative',
        variant: 'error',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-failed-test-status',
        testStatusValue: 'Failed'},
        'ERROR': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style',
        testStatusIcon: 'status-warning',
        variant: 'warning',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-failed-test-status',
        testStatusValue: 'Error'},
    'IN_PROGRESS': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-in-progress',
        variant: 'link',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-running-test-status',
        testStatusValue: 'In Progress'},
    'QUEUED': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-pending',
        variant: 'link',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-pending-test-status',
        testStatusValue: 'Queued'},
    'TIME_OUT': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style',
        testStatusIcon: 'status-stopped',
        variant: 'subtle',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-timeout-test-status',
        testStatusValue: 'Timed Out'}
    },

    // Mapping to display reference mic status on Live run page
    REF_MIC_STATUS: {
    true: {
        displayText: 'Online',
        displayStyle: 'ref-mic-online-style'
    },
    false: {
        displayText: 'Offline',
        displayStyle: 'ref-mic-offline-style'
    },
    'Unavailable': {
        displayText: 'Retrieving ...',
        displayStyle: 'ref-mic-unavailable-style'
    }
    }
}

export default CustomTestConstants;
