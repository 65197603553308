import React from "react";
import AWSUI from "@amzn/awsui-components-react";

import AppConstants from "../../../../_Constants/AppConstants";
import {
  getAlexaHandsFreeVerificationTestCases,
  getAppVerificationTestCases,
  getDeviceLockHandsFreeVerificationTestCases
} from "../Utils";
import {sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier} from "../../NewRunDataTransferUtils";
import FunctionalTestCases from "../../../../_Constants/FunctionalTestCasesConstants";
import {setValueForFunctionalTestCasesCheckbox} from "../../FunctionalUtil";
import {getSelectedValuForTestSuite} from "../../../Labs/SyncResourceUtil";

class MobileFunctionalTestCasesSelection extends React.Component {

  onChangeMultiSelectTestCases = (event, params, updateParamsCallBack,
                                  paramsError, updateParamsErrorCallBack) => {
    let updatedParams = params;
    updatedParams.functionalTestCases = event.detail.selectedIds;
    sendRawInfoToNewRun(updatedParams, updateParamsCallBack);
    let updatedParamsError = paramsError;
    updatedParamsError.functionalTestCases = false;
    sendRawInfoToNewRun(updatedParamsError, updateParamsErrorCallBack);
  }

  /**
   * Event handler for checking/ unchecking Functional test case checkboxes
   * @param {*} id
   * @param {*} event
   * @param {*} params
   * @param {*} updateParamsCallBack
   * @param {*} paramsError
   * @param {*} updateParamsErrorCallBack
   */
  onChangeCheckFunctional = (id, event, params, updateParamsCallBack,
                             paramsError, updateParamsErrorCallBack) => {
    let functionalTestCases = setValueForFunctionalTestCasesCheckbox(
      id, event, params.functionalTestCases);
    let updatedParams = params;
    updatedParams.functionalTestCases = functionalTestCases;
    sendRawInfoToNewRun(updatedParams, updateParamsCallBack);
    // update error field
    if (updatedParams.functionalTestCases.length > 0) {
      let updatedParamsError = paramsError;
      updatedParamsError.functionalTestCase = false;
      sendRawInfoToNewRun(updatedParamsError, updateParamsErrorCallBack);
    }
  };

  /**
   * Renders Mobile Functional Test cases for Alexa Hands-Free Verification or App Verification
   */
  getCustomTestCasesFieldMobileFunctional = (selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack) => {
    const testCases = selectedScenario.testCategory === AppConstants.ALEXA_HANDS_FREE_VERIFICATION ?
      getAlexaHandsFreeVerificationTestCases() : getAppVerificationTestCases();
    return(
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root="true">
          <AWSUI.FormField
            label={<h4><b>Test Cases</b></h4>}
            hintText='Select one or more test cases from the dropdown'
            errorText={
              selectedScenarioError.functionalTestCases
                ? FunctionalTestCases.FUNC_CUSTOM_ERROR
                : AppConstants.EMPTY
            }
          >
            <div style={{ maxWidth: 600 }}>
              <AWSUI.Multiselect
                enableFiltering={true}
                selectedIds={selectedScenario.functionalTestCases}
                options={testCases}
                onChange={event => {
                  this.onChangeMultiSelectTestCases(event, selectedScenario, selectedScenarioCallBack,
                    selectedScenarioError, scenarioErrorCallBack)
                }}
                keepOpen={true}
              />
            </div>
          </AWSUI.FormField>
        </div>
      </AWSUI.ColumnLayout>
    )
  };

  /**
   * Renders Mobile Functional Test cases for Device Lock
   */
  getDeviceLockTestCasesField = (selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack) => {
    const testCases = getDeviceLockHandsFreeVerificationTestCases();
    const errorText = selectedScenarioError.functionalTestCases
      ? FunctionalTestCases.FUNC_CUSTOM_ERROR
      : AppConstants.EMPTY
    return(
      <AWSUI.FormField
        label={<h4><b>Test Cases</b></h4>}
        hintText='Select one or more test cases'
        errorText={errorText}
      >
        <AWSUI.ColumnLayout columns={4} className='awsui-util-p-l'>
          <div
            data-awsui-column-layout-root="true"
            className="awsui-util-pb-s"
          >
            {testCases.map(testCase => {
              return (
                <AWSUI.Checkbox
                  label={testCase.label}
                  checked={
                    selectedScenario.functionalTestCases.includes(testCase.label)
                  }
                  onChange={(event) => {
                    this.onChangeCheckFunctional(
                      testCase.label,
                      event,
                      selectedScenario,
                      selectedScenarioCallBack,
                      selectedScenarioError,
                      scenarioErrorCallBack
                    );
                  }}
                />
              );
            })}
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    )
  };

  render() {
    const {
      selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack
    } = this.props.params;
    return (
      <div>
        {(selectedScenario.scenarioType === AppConstants.MOBILE_FUNCTIONAL
          && ((selectedScenario.mobileFunctionalCustomOptionsSelected
              && this.getCustomTestCasesFieldMobileFunctional(selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack))
            || (selectedScenario.lockType && selectedScenario.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION  &&
              this.getDeviceLockTestCasesField(selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack)))
        )}
      </div>
    );
  }
}

export default MobileFunctionalTestCasesSelection;
