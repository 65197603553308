import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';
import { getAutoSyncResourcesField, getProxySettings, getDisablePlaybackSkill } from '../uiUtils';
import AppConstants from '../../../_Constants/AppConstants';
import GeneralAcousticTestTypeSelection from './GeneralAcousticTestTypeSelection';
import FARCustomSubpage from '../FARCustomSubscenarioSelection';
import WakewordTestTypeSelection from "../Wakeword/WakewordTestTypeSelection";

class Acoustic extends React.Component {

  getCheckboxOptionsForAcoustic = (selectedScenario, selectedScenarioCallBack, lockFields) => {
    return (
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root='true'>
          {getAutoSyncResourcesField(selectedScenario.isAutoSyncEnabled,
              selectedScenario, selectedScenarioCallBack, lockFields)}
          {this.showProxyOption(selectedScenario.scenarioType) &&
            getProxySettings(selectedScenario.isProxyEnabled,
              selectedScenario, selectedScenarioCallBack)
          }
          {
            [AppConstants.FARFIELD, AppConstants.NEARFIELD].some((element) => element === selectedScenario.scenarioType) &&
            getDisablePlaybackSkill(selectedScenario, selectedScenarioCallBack)
          }
        </div>
      </AWSUI.ColumnLayout>
    )
  }

  /**
   * Determine whether proxy option is available for customers
   * For now, within Acoustic test suites, proxy option is not shown for FAR scenarios
   * @param {*} scenarioType
   * @return true if proxy options is available
   */
  showProxyOption = (scenarioType) => {
    return (
      !isEmpty(scenarioType) &&
      !(AppConstants.FAR_TEST_TYPES.includes(scenarioType)) &&
      !(scenarioType === AppConstants.WAKEWORD)
    );
  }

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      whitelistedCustomerIds, primaryDSNSelected,
      dutsInUse, updateNewRunStateParameterCallBack,
      lockFields } = this.props.params;
    return (
      <div>
        {this.getCheckboxOptionsForAcoustic(selectedScenario, selectedScenarioCallBack, lockFields)}
        { selectedScenario.scenarioType === AppConstants.NEARFIELD &&
          <GeneralAcousticTestTypeSelection params={{
            selectedScenario: selectedScenario,
            selectedScenarioCallBack: selectedScenarioCallBack,
            selectedScenarioError: selectedScenarioError,
            scenarioErrorCallBack: scenarioErrorCallBack,
            piLocations: AppConstants.nearFieldLocation,
          }} />
        }
        { selectedScenario.scenarioType === AppConstants.WAKEWORD &&
         <WakewordTestTypeSelection params= {{
           selectedScenario: selectedScenario,
           selectedScenarioCallBack: selectedScenarioCallBack,
           selectedScenarioError: selectedScenarioError,
           scenarioErrorCallBack: scenarioErrorCallBack,
         }} />}
        { selectedScenario.scenarioType === AppConstants.FARFIELD &&
          <GeneralAcousticTestTypeSelection params={{
            selectedScenario: selectedScenario,
            selectedScenarioCallBack: selectedScenarioCallBack,
            selectedScenarioError: selectedScenarioError,
            scenarioErrorCallBack: scenarioErrorCallBack,
            piLocations: AppConstants.farFieldLocation,
          }} />
        }
        { selectedScenario.scenarioType === AppConstants.FAR_CUSTOM &&
          <FARCustomSubpage params={{
            selectedScenario: selectedScenario,
            selectedScenarioCallBack: selectedScenarioCallBack,
            selectedScenarioError: selectedScenarioError,
            scenarioErrorCallBack: scenarioErrorCallBack,
            otherParams: otherParams, // TODO: only pass deviceMapping there
            updateOtherParamsCallBack: updateOtherParamsCallBack,
            whitelistedCustomerIds: whitelistedCustomerIds,
            dutsInUse: dutsInUse,
            updateNewRunStateParameterCallBack: updateNewRunStateParameterCallBack,
            primaryDSNSelected: primaryDSNSelected,
          }} />
        }
      </div>
    )
  }
}

export default Acoustic;
