import React, { Component } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { Link, withRouter } from "react-router-dom";

class NavBar extends Component {
  handleChange = (id) => {
    return (event) => {
      if (!event) return;
      this.props.handleTabChange(id);
    }
  }

  render() {
    const { menuItems, location } = this.props;
    return (
      <div>
        <nav>
          <ul class="awsui-tabs-header awsui-util-container-header">
            {
              menuItems.map(item => {
                const isActive = item.path === location.pathname;
                const activeTabClass = isActive ? 'awsui-tabs-tab-active' : '';
                return (
                  <li class="awsui-tabs-tab" key={item.id}>
                    <Link
                      to={item.path}
                      class={`${activeTabClass} awsui-tabs-tab-link`}
                      onClick={this.handleChange(item.id)}
                    >
                      <span className="awsui-tabs-tab-label">
                        <span>
                          <AWSUI.Icon className='awsui-util-mr-xs' name={item.icon} />
                          <span>{item.label}</span>
                        </span>
                      </span>
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(NavBar);
