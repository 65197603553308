import React from "react";
import AWSUI from "@amzn/awsui-components-react";

import FunctionalTestCases from "../../../_Constants/FunctionalTestCasesConstants";
import AppConstants from "../../../_Constants/AppConstants";
import {getMultiSelectFunctionalTestCases} from "../Util";
import {sendRawInfoToNewRun} from "../NewRunDataTransferUtils";

class WakewordCustomizedOptions extends React.Component {

  onChangeMultiSelectTestCases = (event, params, updateParamsCallBack) => {
    let updatedParams = params;
    updatedParams.functionalTestCases = event.detail.selectedIds;
    sendRawInfoToNewRun(updatedParams, updateParamsCallBack);
  }

  /**
   * Renders Functional Test cases for Wakeword
   */
  getWakewordTestFunctional = (selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack) => {
    const wakewordTestCases = FunctionalTestCases.WAKEWORD_TEST_CASES.map(
      item => {
        const match = FunctionalTestCases.WAKEWORD_TEST_CASES_MAPPING.find(obj => obj.id === item);
        return match ? match.label : null;
      });

    const multiWakewordTestCases = getMultiSelectFunctionalTestCases(wakewordTestCases);
    return(
        <div data-awsui-column-layout-root="true">
          <AWSUI.FormField
            label={<h4><b>Test Cases</b></h4>}
            hintText='Select one or more test cases from the dropdown'
            errorText={
              selectedScenarioError.functionalTestCases
                ? FunctionalTestCases.FUNC_CUSTOM_ERROR
                : AppConstants.EMPTY
            }
          >
            <div style={{ maxWidth: 600 }}>
              <AWSUI.Multiselect
                enableFiltering={true}
                selectedIds={selectedScenario.functionalTestCases}
                options={multiWakewordTestCases}
                onChange={event => {
                  this.onChangeMultiSelectTestCases(event, selectedScenario, selectedScenarioCallBack)
                }}
                keepOpen={true}
              />
            </div>
          </AWSUI.FormField>
        </div>
    )
  };

  render() {
    const {
      selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack
    } = this.props.params;
    return (
      <div>
        { selectedScenario.scenarioType === AppConstants.WAKEWORD &&
          selectedScenario.acousticWakewordCustomOptionsSelected &&
          this.getWakewordTestFunctional(
            selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack)}
      </div>
    );
  }
}

export default WakewordCustomizedOptions;
