import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../_Constants/AppConstants';
import { isEmpty } from 'lodash';
import {
  get3PDASettings,
  getCommonSelect,
  getDUTsLoadingMessage,
  getInputField,
  getInputFieldOptional,
  getProxySettings
} from './uiUtils';
import { onChangeInput, onInput } from './uiActionsUtils';
import { setTestTypeState } from './ScenarioSelectionHelpers';
import {
  getAmazonIdForDut,
  getDeviceNameForDut,
  getErrorText,
  getErrorTextForDropdown,
  shouldDisableDropdown
} from "./Util";
import {
  generateDeviceMapping,
  refreshDUTList
} from "./MappingGeneratorUtil";
import {
  logToConsole,
  validateInputOptional
} from "../../Util";
import {
  sendRawInfoToNewRunWithIdentifier,
  sendRawInfoToNewRunWithStateId
} from "./NewRunDataTransferUtils";
import { getDUTs } from "./controller";
import { generateDUTListWithOnlineOfflineStatus } from "./TestGroupInfoHelpers";

class MusicTestTypeSelection extends React.Component {

  state = {
    additionalDutListLoading: new Array(AppConstants.MAX_OPTIONAL_DUTS).fill(false),
    addAdditionalDUTManually: new Array(AppConstants.MAX_OPTIONAL_DUTS).fill(false),
  }

  /**
   * TODO: apply for similar refactor to latest getTestTypes, same as in GeneralAcousticTestTypeSelection
   * Renders Standard & Smoke test types for Music scenario
   * getTestTypesMusic
   */
  getTestTypes = (selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack) => {
    let currentTestTypeArr = [];
    if (isEmpty(selectedScenario)) {
      currentTestTypeArr = [false, false, false, false];
    } else {
      currentTestTypeArr = selectedScenario.testTypeArr;
    }
    return (
      <AWSUI.FormField
        errorText={selectedScenarioError.testTypeArr ? AppConstants.TEST_TYPES_ERROR : AppConstants.EMPTY}
        label={<h3>Test Type</h3>}
        className='awsui-util-m-l'
      >
        <AWSUI.ColumnLayout columns={4} className='awsui-util-p-l'>
          <div data-awsui-column-layout-root='true'>
            <div>
              <AWSUI.RadioButton
                label='Standard'
                checked={currentTestTypeArr[0]}
                onChange={event => { this.setTestTypeState(AppConstants.STANDARD, selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack) }}
              />
            </div>
            <div>
              <AWSUI.RadioButton
                label='Smoke'
                checked={currentTestTypeArr[1]}
                onChange={event => { this.setTestTypeState(AppConstants.QUICK, selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack) }}
              />
            </div>
            <div></div><div></div>
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }

  /**
   * Applies to scenarios with TestType selection
   * @param {*} testType
   * @param {*} selectedScenarios
   * @param {*} scenarioSelectCallBackFun
   * @param {*} selectedScenarioError
   * @param {*} scenarioErrorCallBack
   */
  setTestTypeState = (testType, selectedScenarios, scenarioSelectCallBackFun, selectedScenarioError, scenarioErrorCallBack) => {
    setTestTypeState(selectedScenarios.scenarioType, testType, selectedScenarios, scenarioSelectCallBackFun, selectedScenarioError, scenarioErrorCallBack);
  }

  getEmailInput = (selectedScenario) => {
    return (
      <div>
        {
          (selectedScenario.scenarioType === 'MUSIC_IHRT_CUSTOM' || selectedScenario.scenarioType === 'MUSIC_IHRT_LIVE') &&
          (
            this.getInputField(AppConstants.iHeartEmailFieldId, 'iHeart Email Address', 'Provide iHeart Email Address used for testing')
          )
        }
      </div>
    );
  }

  // when AWS component Input is updated
  // original onChangeInput
  onChangeInput = (id, event) => {
    const source = 'MusicSuiteTestType, onChangeInput';
    const { selectedScenario, selectedScenarioCallBack,
      otherParams, selectedScenarioError, scenarioErrorCallBack, updateOtherParamsCallBack } = this.props.params;
    // If 3pda test, update the device mapping to pass the secondary firmware information to the 3pda device
    if (selectedScenario.is3PDAEnabled === true) {
      let inputValue = event.detail.value;
      otherParams.deviceMapping = generateDeviceMapping(otherParams.deviceMapping, id, 1, inputValue);
    }
    return onChangeInput(id, event, source,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack);
  }

  // operation on InputField
  onInput = (id, event) => {
    const source = 'MusicSuiteTestType, onInput';
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return onInput(id, event, source,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack);
  }

  // iheartRadio email address
  getInputField = (id, label, hintText, maxWidth = 600) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return getInputField(id, label, hintText, maxWidth,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
      this.onChangeInput,
      this.onInput);
  }

  /**
   * Display Utterance Injection Check Box between Test Suite, scenario Type selection, and Test Type selection.
   */
  getUtteranceInjectionCheckBox = (isProxyEnabled, selectedScenario, selectedScenarioCallBack) => {
    if (selectedScenario.marketPlace === "en_US") {
      return (
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {
              getProxySettings(isProxyEnabled, selectedScenario, selectedScenarioCallBack, false, 'Enable Utterance Injection')
            }
          </div>
        </AWSUI.ColumnLayout>
      )
    } else {
      // This is required to reset proxy flag for other marketplaces, otherwise internally the flag will be sent to scenario as selected earlier.
      selectedScenario.isProxyEnabled = false;
    }
  }

  /**
   * Display 3PDA/Mic-less Check Box below Test Suite.
   */
  get3PDACheckbox = (is3PDAEnabled, selectedScenario, selectedScenarioCallBack) => {
    return (
      <div data-awsui-column-layout-root='true'>
        {
          get3PDASettings(is3PDAEnabled, selectedScenario, selectedScenarioCallBack, false, 'Enable Mic-less/PCA')
        }
      </div>
    )
  }

  /**
   * TODO: Move to Utils as this method is shared with FARCustomSubscenario
   * Gets Additional Device field for 3PDA/mic-less test
   * @return Returns a field for the optional device:
   * 1. CID dropdown
   * 2. DSN dropdown or input field depending on if DUT list for CID is non-empty or not
   * 3. Amazon ID dropdown or input field depending on DSN is dropdown or not
   * 4. Build Info input field
   */
  getOptionalDevices = (selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack) => {
    if (selectedScenario.is3PDAEnabled === true) {
      return (
        <AWSUI.FormField
          label={<h2>Mic-less/PCA Device</h2>}
          className='awsui-util-m-l'
        >
          <div>
            {
              this.getDUTInformationSectionOptional(1)
            }
          </div>
          {
            selectedScenarioError.deviceMappingError !== AppConstants.EMPTY && (
              <div align='left' className='awsui-util-mt-l awsui-util-mb-m awsui-util-ml-l red-color-text'>
              <span>
                <AWSUI.Icon variant='error' name='status-warning'></AWSUI.Icon>
                <span className='awsui-util-ml-xs'>{selectedScenarioError.deviceMappingError}</span>
              </span>
              </div>
            )
          }
        </AWSUI.FormField>
      );
    }
  }

  /**
   * TODO: Move to Utils as this method is shared with FARCustomSubscenario
   * Single unit of each dut list field
   * @param {*} index
   */
  getDUTInformationSectionOptional = (index) => {
    const { selectedScenario, otherParams, selectedTestGroupInfo, dutsInUse } = this.props.params;

    let dutKey = 'DUT_' + index;
    let dutMapping = otherParams.deviceMapping;
    let dut = dutMapping.hasOwnProperty(dutKey) ? dutMapping[dutKey] : {};
    let customerId = selectedTestGroupInfo.customerId;
    let dsn = AppConstants.EMPTY;
    let amazonId = AppConstants.EMPTY;
    let buildInfo = AppConstants.EMPTY;
    let dutList = selectedScenario.additionalDutList[index - 1] != null ? selectedScenario.additionalDutList[index - 1] : [];
    if (dut !== undefined && Object.keys(dut).length > 0) {
      dsn = dut.hasOwnProperty('dsn') ? dut['dsn'] : AppConstants.EMPTY;
      amazonId = dut.hasOwnProperty('deviceType') ? dut['deviceType'] : getAmazonIdForDut(dutList, dsn);
      buildInfo = dut.hasOwnProperty('buildInfo') ? dut['buildInfo'] : AppConstants.EMPTY;
    }
    return (
      <div>
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {this.getInputField('buildInfo', AppConstants.FIRMWARE, 'Provide firmware information for the Mic-less/PCA Device',
              buildInfo, index, dutList)}
            {customerId !== AppConstants.EMPTY &&
            !this.state.additionalDutListLoading[index - 1] &&
            !this.state.addAdditionalDUTManually[index - 1] && (
              <AWSUI.ColumnLayout columns={2} borders='none'>
                <div>
                  {this.getSelectFieldOptional('dsn', 'Device Serial Number', 'Device Serial Number of the Mic-less/PCA Device',
                    dutList,
                    dsn, index, dutList, customerId, dutsInUse)}
                </div>
              </AWSUI.ColumnLayout>)
            }
            {customerId !== AppConstants.EMPTY &&
            this.state.additionalDutListLoading[index - 1] &&
            !this.state.addAdditionalDUTManually[index - 1] && (
              <AWSUI.ColumnLayout columns={2} borders='none'>
                <div>
                  {getDUTsLoadingMessage(AppConstants.LOADING_DUTS)}
                </div>
              </AWSUI.ColumnLayout>
            )
            }
            {
              customerId !== AppConstants.EMPTY &&
              this.state.addAdditionalDUTManually[index - 1] && (
                this.getInputFieldOptional('dsn', 'Device Serial Number', 'Device Serial Number of the Mic-less/PCA Device', dsn, index)
              )
            }
            {
              customerId !== AppConstants.EMPTY &&
              (dsn !== AppConstants.EMPTY ||
                this.state.addAdditionalDUTManually[index - 1]) && (
                this.getInputFieldOptional('deviceType', 'Amazon ID (Optional)', 'Amazon ID of the Mic-less/PCA Device', amazonId, index)
              )
            }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * TODO: Move to Utils as this method is shared with FARCustomSubscenario
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   * @param {*} index
   * @param {*} dutList: only apply for dsn select
   * @param {*} dutsInUse: only apply for dsn select
   */
  getSelectFieldOptional = (id, label, hintText, options, selected, index, dutList, customerId, dutsInUse = null) => {
    let isError = false;
    if (id === 'dsn') {
      isError = dutList && dutList.length > 0 ? false : true;
      options = refreshDUTList(options, index, dutsInUse);
      if (dutList.length === 0) {
        this.updateDuts(customerId, index);
      }
      logToConsole('AQTLog - DUTList for device ' + index + ' = ' + JSON.stringify(options));
    }
    let shouldDisable = shouldDisableDropdown(id, isError, dutList, this.state.additionalDutListLoading[index - 1]);
    let errorText = getErrorTextForDropdown(id, isError, dutList);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelectOptional, index);
  }

  /**
   * Used to update the DUT list in Scenario Selection based on the customer ID provided in Test Group Information
   * @param {*} id
   * @param {*} index
   */
  updateDuts = (customerId, index) => {
    const { selectedScenario, selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      dutsInUse, updateNewRunStateParameterCallBack } = this.props.params;
    const identifier = 'MusicSubpage, onChangeSelectOptional';
    let updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'customerId', index, customerId);
    let updatedDutsInUse = dutsInUse;

    updatedDutsInUse[index] = null;
    this.loadOptionalDUTs(customerId, index, selectedScenario);

    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    sendRawInfoToNewRunWithStateId(identifier, updatedDutsInUse, 'dutsInUse', updateNewRunStateParameterCallBack);

    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * TODO: Move to Utils as this method is shared with FARCustomSubscenario
   * Handle select logic for 3PDA/micless DUT and Customer ID selection dropdowns
   * @param {*} id
   * @param {*} event
   * @param {*} index
   */
  onChangeSelectOptional = (id, event, index) => {
    const { selectedScenario, selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      dutsInUse, updateNewRunStateParameterCallBack } = this.props.params;
    const identifier = 'MusicSubpage, onChangeSelectOptional';
    let selected = event.detail.selectedOption.id;
    let updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, id, index, selected);
    let updatedDutsInUse = dutsInUse;
    if (id === 'dsn') {
      let dutList = selectedScenario.additionalDutList[index - 1];
      let amazonId = getAmazonIdForDut(dutList, selected);
      let deviceName = getDeviceNameForDut(dutList, selected);
      updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'deviceType', index, amazonId);
      updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'deviceName', index, deviceName);
      updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'is3PDADevice', index, selectedScenario.is3PDAEnabled);
      updatedDutsInUse[index] = selected;
    }
    if (id === 'customerId') {
      updatedDutsInUse[index] = null;
      this.loadOptionalDUTs(selected, index, selectedScenario);
    }

    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    sendRawInfoToNewRunWithStateId(identifier, updatedDutsInUse, 'dutsInUse', updateNewRunStateParameterCallBack);

    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * Gets input field for optional 3PDA/mic-less Device
   * @param fieldId id of the current field. - 'dsn'
   * @param fieldLabel This will be either of dsn, amazonId and buildInfo
   * @param hintText - Eg: "Device Serial Number of the DUT"
   * @param value - such as Actual value of dsn
   * @param index - optional dut index value.
   * @return Returns input field for dsn, amazonId or buildInfo depending on
   * parameters passed to the method
   */
  getInputFieldOptional = (fieldId, fieldLabel, hintText, value, index) => {
    let isError = validateInputOptional(fieldId, value);
    let disabled = false;
    if (fieldId === 'deviceTypeId') {
      disabled = !this.state.addAdditionalDUTManually[index - 1];
    }
    let errorText = getErrorText(fieldId, isError, value);

    return getInputFieldOptional(fieldId, fieldLabel, hintText, 600, value, disabled, errorText, this.onChangeInputOptional,
      index);
  }

  /**
   * TODO: Move to Utils as this method is shared with FARCustomSubscenario
   * Handle input value logic for 3PDA Custom
   * @param {*} id
   * @param {*} event
   * @param {*} index
   */
  onChangeInputOptional = (id, event, index) => {
    const { selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack } = this.props.params;
    const identifier = 'MusicSubpage, onChangeInputOptional';
    let inputValue = event.detail.value;
    let updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, id, index, inputValue);
    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * Loads DSNs for selected customer ID for 3PDA/mic-less
   * @param customerId Customer ID selected in customer ID dropdown for optional DUT
   * @param index Index of the optional DUT e.g. for Nth device, it would be N
   * @param selectedScenario  get additionalDutList information
   */
  loadOptionalDUTs = (customerId, index, selectedScenario) => {
    this.getOptionalDUTsList(customerId, false, index);
    // Retry fetching DSNs with forceRefresh=true in case first attempt gives empty list
    // suspect logic in old UI was wrong
    let dutList = selectedScenario.additionalDutList[index - 1];
    if (!dutList || dutList.length === 0) {
      this.getOptionalDUTsList(customerId, true, index);
    }
  }


  /**
   * Retrieves DUTs for Customer ID for optional devices for 3PDA/Mic-less test.
   * @param customerId Customer ID for which DUTs needs to be retrieved
   * @param forceRefresh Whether to fetch DUTs from DMS or DB
   * @param index Index of the optional DUT
   * @returns List of DUTs for customer ID
   */
  getOptionalDUTsList = (customerId, forceRefresh, index) => {
    const { selectedScenario, selectedScenarioCallBack } = this.props.params;
    const identifier = 'MusicSubpage, getOptionalDUTsList';
    let additionalDutListLoading = this.state.additionalDutListLoading;
    additionalDutListLoading[index - 1] = true;
    this.setState({
      ...this.state,
      additionalDutListLoading: additionalDutListLoading
    })
    return Promise.resolve(getDUTs(customerId, forceRefresh).then(dutResponse => {
      let dutListIn = generateDUTListWithOnlineOfflineStatus(dutResponse);
      let additionalDutList = selectedScenario.additionalDutList;
      additionalDutList[index - 1] = dutListIn;
      additionalDutListLoading[index - 1] = false;
      this.setState({
        ...this.state,
        additionalDutListLoading: additionalDutListLoading
      })
      let updatedSelectedScenario = selectedScenario;
      updatedSelectedScenario.additionalDutList = additionalDutList;
      sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenario, selectedScenarioCallBack);
      return additionalDutList;
    }));
  }

  /**
   * When Primary DSN is not selected, display this message for reminder.
   */
  getAddPrimaryDeviceMessage = (selectedScenario) => {
    if (selectedScenario.is3PDAEnabled === true) {
      return (
        <span>
        <b>
          {AppConstants.ADD_PRIMARY_DSN_REMINDER}
        </b>
      </span>);
    }
  }

  //TODO: need to handle error text properly
  render() {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack, primaryDSNSelected} = this.props.params;
    return (
      <div>
        <div className='awsui-util-mt-m'>
          {this.getUtteranceInjectionCheckBox(selectedScenario.isProxyEnabled, selectedScenario, selectedScenarioCallBack)}
          {this.get3PDACheckbox(selectedScenario.is3PDAEnabled, selectedScenario, selectedScenarioCallBack)}
          {primaryDSNSelected && this.getOptionalDevices(selectedScenario, selectedScenarioCallBack, selectedScenarioError)}
          <div align="center"
               className="awsui-util-mt-xl awsui-util-mb-xl ref-mic-consent-message">
            {!primaryDSNSelected && this.getAddPrimaryDeviceMessage(selectedScenario)}
          </div>
          {this.getTestTypes(selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack)}
          {this.getEmailInput(selectedScenario)}
        </div>
      </div>
    );
  }
}

export default MusicTestTypeSelection;
