// TODO: add comments to the following functions
// send ids and values to newRun page
// send CID to newRun page
/**
 * @param {*} id    eg, 'customerId'
 * @param {*} value eg, 'XXXXXX'
 * @param info, eg, passed original state value
 * @param {*} callback callback function to send data
 */
export function sendTestGroupInfoToNewRun(id, value, info, callback) {
  let fieldMap = new Map();
  fieldMap.set(id, value);
  sendMultipleTestGroupInfoToNewRun(fieldMap, info, callback);
}

// send multiple ids to newRun page
/**
 * @param fieldMap, map of id, value, eg. customerId, XXXXX
 *  {*} id    id is array
 *  {*} value  so is value
 * @param info, eg, passed original state value
 * @param {*} callback callback function to send data
 */
export function sendMultipleTestGroupInfoToNewRun(fieldMap, info, callback) {
  let currentSelectedInfo = info;
  for (let entry of fieldMap) {
    currentSelectedInfo[entry[0]] = entry[1];
  }
  callback(currentSelectedInfo);
}

export function sendRawInfoToNewRun(rawInfo, callback) {
  callback(rawInfo);
}

export function sendRawInfoToNewRunWithIdentifier(identifier, rawInfo, callback) {
  callback(rawInfo, identifier);
}

/**
 *
 * @param {*} identifier Indicate where is the update from
 * @param {*} rawInfo new state parameter value, eg, 'false'
 * @param {*} id state parameter to be updated, eg, 'primaryDSNSelected'
 * @param {*} callback callback function to actually update the parameter
 */
export function sendRawInfoToNewRunWithStateId(identifier, rawInfo, id, callback) {
  callback(rawInfo, id, identifier);
}
