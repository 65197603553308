import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { logToConsole } from "../../../Util";
import ld from 'lodash';

/**
 * Helper function to wrap cell content with external link
 * @param {str} type
 * @param {str} label
 */
const generateExternalResourceLink = (type, label) => {
  if (!label) return;
  let id = label;
  let baseLink = 'https://www.cvedetails.com/cve';
  if (type === 'cwe') {
    baseLink = 'https://www.cvedetails.com/cwe-details';
    id = label.split('-').pop();
  }

  return (
    <div>
      <span>{label}&nbsp;</span>
      <a href={`${baseLink}/${id}`} target='_blank' rel="noopener noreferrer">
        <AWSUI.Icon className='awsui-util-mr-xs' name='external' />
      </a>
    </div>
  );
}

/**
 * Helper function to highlight cell content based on severity of the error
 * @param {str} key
 */
const formatCell = (key) => {
  const severityColorMap = {
    high: '#ff5d64',
    medium: '#dd6b10',
    low: '#687078',
    info: '#0073bb'
  };
  return (item) => {
    if (key === 'severity') {
      if (!item[key] || !severityColorMap[item[key].toLowerCase()]) return item[key];
      const level = item[key].toLowerCase();
      return (<span style={{ color: severityColorMap[level] }}>{item[key]}</span>);
    } else if (['cve', 'cwe'].includes(key)) {
      return generateExternalResourceLink(key, item[key]);
    }
    return item[key];
  }
}

/**
 * Helper function to map status codes with default error messages
 * @param {int} statusCode
 */
const errorText = (statusCode) => {
  const statusCodeMapping = {
    100: {
      type: 'QUEUED',
      alertType: 'info',
      message: 'Test is queued. Please check back later',
    },
    101: {
      type: 'RUNNING',
      alertType: 'info',
      message: 'Test is running. Please check back later',
    },
    102: {
      type: 'SKIPPED',
      alertType: 'info',
      message: 'Test is skipped. Please check report for more details',
    },
    201: {
      type: 'NO_CONTENT',
      alertType: 'info',
      message: 'Test ran. No content available',
    },
    500: {
      type: 'FAILURE',
      alertType: 'error',
      message: 'Test failed to run'
    },
    501: {
      type: 'FAIL_NO_RESPONSE',
      alertType: 'error',
      message: 'No response error when a device does not respond to ping or the target port is closed'
    },
    502: {
      type: 'TIMEOUT',
      alertType: 'error',
      message: 'Test is timed out. Ran more than 20 minutes'
    }
  }

  const defaultError = {
    alertType: 'info',
    message: 'Test status unknown. Please check back later'
  };

  return statusCodeMapping[statusCode] || defaultError;
}

/**
 * Helper function to parse the string to integer
 * @param {str} str
 * @param {int} defaultValue
 */
const tryParseInt = (str, defaultValue) => {
  const radix = 10;
  const parsedInt = parseInt(str, radix);
  if (isNaN(parsedInt)) return defaultValue;

  return parsedInt;
}

/**
 * Boolean function to check if the test is success
 * For successful test, status code will be 200
 * @param {object} state
 * eg:
 * state: {
 *  statusCode,
 *  message
 * }
 */
const isTestSuccess = (state = {}) => {
  if (!state.statusCode) return false;
  // status codes for cases when test is completed and successful
  return state.statusCode === 200;
}

/**
 * Show an alert in case the test is unsuccessful
 * @param {object} state
 * eg:
 * state: {
 *  statusCode,
 *  message
 * }
 */
const renderErrorState = (state = {}) => {
  const error = errorText(state.statusCode);
  const header = state.message || error.message;
  const type = state.alertType || 'info';
  return (
    <AWSUI.Alert type={type} dismissible={false} header={header} />
  );
}

/**
 * Lists all the open ports
 * @param props
 */
export const PortDetection = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No open ports found!`}
        />
      );
    }

    const columnHeaderMap = {
      serviceName: 'Service Name',
      portId: 'Port',
      version: 'Version',
      productName: 'Product',
      vulnsCount: 'Vulnerabilities Found'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
        header={<h2 className="test-category-text-style">Open ports</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Detects the OS version on the PI
 * @param props
 */
export const OSDetection = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    if (!data || !data.type) {
      return (
        <AWSUI.Alert
          type="info"
          dismissible={false}
          header={state.message || `Couldn't detect OS!`}
        />
      );
    }

    const formatHeader = (headerText) => {
      return (<span style={{ fontSize: 18 }}>{headerText}</span>)
    }

    const cardDefinition = {
      sections: [
        {
          id: 'name',
          header: formatHeader('Name'),
          content: item => item.name || 'N/A'
        },
        {
          id: 'macAddress',
          header: formatHeader('MAC Address'),
          content: item => item.macAddress || 'N/A'
        },
        {
          id: 'hostName',
          header: formatHeader('Host Name'),
          content: item => item.hostName || 'N/A'
        },
        {
          id: 'type',
          header: formatHeader('OS Matches'),
          content: item => {
            const osMatches = Object.keys(item.type)
              .map(os => `${os} (${item.type[os]}%)`)
              .join('\n');
            return (<pre>{osMatches}</pre>);
          }
        },
        {
          id: 'fingerprint',
          header: formatHeader('Operating System Fingerprint'),
          content: item => item.fingerprint && (<pre>{item.fingerprint.join('\n')}</pre>)
        }
      ]
    };
    const cardsPerRow = [{ cards: 1 }];
    return (
      <AWSUI.Cards
        cardDefinition={cardDefinition}
        items={[data]}
        cardsPerRow={cardsPerRow}
      />
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

/**
 * Lists all the files that are accessible via HTTP
 * @param props
 */
export const HTTPEnumeration = (props) => {
  const { label, data = {}, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    const columnHeaderMap = {
      port: 'Port',
      path: 'Path',
      status: 'Status',
      type: 'Type'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        header: columnHeaderMap[key],
        cell: item => item[key]
      }));

    /**
     * @data
     * {
     *    'http://10.5.5.3:80/': [
     *      ...
     *      {'path': 'robots.txt/', 'redirect': null, 'status': "200"},
     *      {'path': 'secret', 'redirect': 'http://192.168.0.174/secret/', 'status': "301"}
     *    ]
     * }
     */
    const httpPorts = Object.keys(data);
    if (httpPorts.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No files found`}
        />
      );
    }

    return httpPorts.map((location, index) => {
      const list = data[location];
      // If No open ports detected
      if (!list || list.length === 0) {
        const message = `No files detected at this location ${location}!`;
        return (
          <AWSUI.Alert type="success" dismissible={false} header={message} />
        );
      }

      const files = list.filter(file => file.status < 500).map(file => ({
        port: location,
        path: file.path,
        status: tryParseInt(file.status, 500),
        type: file.redirect ? 'Folder' : 'File'
      }));
      const message = `Detected ${list.length} file(s). For more info kindly check the report.`;
      return (
        <div key={`key-${index}`}>
          <AWSUI.Alert type="error" dismissible={false} header={message} />
          <br />
          <AWSUI.Table
            columnDefinitions={columnDefinitions}
            items={files}
            variant="default"
            header={<h2 className="test-category-text-style">{`List of files`}</h2>}
          >
            <AWSUI.TableSorting sortableColumns={sortableColumns} />
          </AWSUI.Table>
        </div>
      );
    });
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

/**
 * Results from bruteforce tests to see if there are any accounts that has default
 * or weak passwords and list those accounts
 * @param props
 */
export const BruteforceTest = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No vulnerable accounts detected!`}
        />
      );
    }

    const header = `${data.length} unsecured account(s) detected`;
    const accounts = data.map(({ login }) => login);
    const content = `Following account(s) [ ${accounts.join(', ')} ] are using default or weak passwords`;
    return (
      <AWSUI.Alert type="error" dismissible={false} header={header}>
        {content}
      </AWSUI.Alert>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

/**
 * Lists different types of vulnerabilities
 * @param props
 */
export const SSLTest = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No vulnerabilities detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No vulnerabilities found!`}
        />
      );
    }

    const columnHeaderMap = {
      id: 'ID',
      // ip: 'IP',
      // port: 'Port',
      severity: 'Severity',
      finding: 'Findings',
      cwe: "CWE",
      cve: "CVE",
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    return data.map((vulnerability, index) => {
      const { label, info } = vulnerability;
      if (!info || info.length === 0) return (<div></div>);

      const availableColumns = new Set();
      info.forEach(item => Object.keys(item).forEach(key => availableColumns.add(key)));

      // Build column definition based on the header map
      const columnDefinitions = Object.keys(columnHeaderMap)
        .filter(key => availableColumns.has(key))
        .map(key => ({
          id: key,
          header: columnHeaderMap[key],
          cell: formatCell(key)
        }));

      return (
        <div key={`key-${index}`}>
          <AWSUI.Table
            loadingText="Loading resources"
            columnDefinitions={columnDefinitions}
            items={info}
            variant="default"
            header={<h2 className="test-category-text-style">{label}</h2>}
          >
            <AWSUI.TableSorting sortableColumns={sortableColumns} />
          </AWSUI.Table>
          <br />
        </div>
      );
    });

  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for Protocols info from Packet Capture & Analysis test
 * @param {*} props
 */
export const ProtocolsInfo = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No protocols detected!`}
        />
      );
    }

    const columnHeaderMap = {
      protocol: 'Protocol Name',
      frames: 'Frames',
      bytes: 'Bytes'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">Capture Protocols</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for DNS Endpoints from Packet Capture & Analysis test
 * @param {*} props
 */
export const DNSEndpointsInfo = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="info"
          dismissible={false}
          header={state.message || `No endpoints detected!`}
        />
      );
    }

    const columnHeaderMap = {
      endpoint: 'Endpoints',
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">DNS Endpoints</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for DNS Enpoints by IP for Packet Capture & Analysis test
 * @param {*} props
 */
export const DNSEndpointsByIP = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="info"
          dismissible={false}
          header={state.message || `No endpoints detected!`}
        />
      );
    }

    const columnHeaderMap = {
      ip_src: 'IP Source',
      ip_dst: 'IP Destination',
      frame_src: 'Frame source',
      bytes_src: 'Bytes source',
      frame_dst: 'Frame destination',
      bytes_dst: 'Bytes destination',
      frames_total: 'Total Frames',
      frames_bytes: 'Frame size',
      relative_start: 'Relative Start',
      duration: 'Duration'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">DNS Endpoints</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

// TODO: need to remove the below once the payload is defined after scenario development
// if the payload for both DNSEnpoints by Volume & IP is same.
/**
 * Parse & render response for DNS Endpoints by Volume for Packet Capture & Analysis test
 * @param {*} props
 */
export const DNSEndpointsVolumeInfo = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="info"
          dismissible={false}
          header={state.message || `No endpoints detected!`}
        />
      );
    }

    const columnHeaderMap = {
      ip_src: 'IP Source',
      ip_dst: 'IP Destination',
      frame_src: 'Frame source',
      bytes_src: 'Bytes source',
      frame_dst: 'Frame destination',
      bytes_dst: 'Bytes destination',
      frames_total: 'Total Frames',
      frames_bytes: 'Frame size',
      relative_start: 'Relative Start',
      duration: 'Duration'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">DNS Endpoints</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for utterance validation - applicable for both Packet Capture Analysis & MITM test
 * @param {*} props
 */
export const UtteranceValidationTest = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="error"
          dismissible={false}
          header={state.message || `Utterance validation failed to run!`}
        />
      );
    }

    const columnHeaderMap = {
      asrText: "ASR Text",
      ttsResponse: "Alexa's Response",
      timestamp: "Timestamp",
      status: "Validation status"
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">DNS Endpoints</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for weak cipher suite detection for Packet Capture & Analysis test
 * @param {*} props
 */
export const CipherSuitesDetection = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No Vulnerable Cipher Suites detected!`}
        />
      );
    }

    const columnHeaderMap = {
      name: 'Cipher Suites'
    };

    const sortableColumns = Object.keys(columnHeaderMap)
      .map(key => ({
        id: key,
        field: key
      }));

    // Build column definition based on the header map
    const columnDefinitions = Object.keys(columnHeaderMap).map(key => ({
      id: key,
      header: columnHeaderMap[key],
      cell: item => item[key] ? `${item[key]}` : ''
    }));

    return (
      <AWSUI.Table
        loadingText="Loading Vulnerable Cipher Suites"
        columnDefinitions={columnDefinitions}
        items={data}
        variant="default"
      // header={<h2 className="test-category-text-style">Vulnerable Cipher Suites</h2>}
      >
        <AWSUI.TableSorting sortableColumns={sortableColumns} />
      </AWSUI.Table>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <div>
        Unable to parse test results
      </div>
    );
  }
}

/**
 * Parse & render response for TLS validation from Packet Capture & Analysis test
 * @param {*} props
 */
export const TLSValidation = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    let tlsVersions = [];
    if (data && data.length !== 0) {
      for (let k in data) {
        if (data[k] === true) {
          tlsVersions.push(k);
        }
      }
    }

    // If No open ports detected
    if (!data || data.length === 0 || tlsVersions.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `No vulnerable TLS versions detected!`}
        />
      );
    }



    const header = `${tlsVersions.length} unsecured TLS versions detected`;
    const content = `Following TLS versions - [ ${tlsVersions.join(', ')} ] detected`;
    return (
      <AWSUI.Alert type="error" dismissible={false} header={header}>
        {content}
      </AWSUI.Alert>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

/**
 * Parse & render response for ATS Cert detection from Packet Capture & Analysis test
 * @param {*} props
 */
export const ATSCertDetection = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    let tlsVersions = [];
    if (data && data.length !== 0) {
      for (let k in data) {
        if (data[k] === true) {
          tlsVersions.push(k);
        }
      }
    }

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `Valid Amazon ATS Root certificaes found!`}
        />
      );
    }

    const header = "";
    const content = data.validCert === true ? "Valid Amazon ATS Root certificaes found" : "Valid Amazon ATS Root certificates not found";
    const statusType = data.validCert === true ? "success" : "error"
    return (
      <AWSUI.Alert type={statusType} dismissible={false} header={header}>
        {content}
      </AWSUI.Alert>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

/**
 * Parse & render response for plain text secrets from Packet Capture & Analysis test
 * @param {*} props
 */
export const PlainTextSecrets = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    let tlsVersions = [];
    if (data && data.length !== 0) {
      for (let k in data) {
        if (data[k] === true) {
          tlsVersions.push(k);
        }
      }
    }

    // If No open ports detected
    if (!data || data.length === 0) {
      return (
        <AWSUI.Alert
          type="success"
          dismissible={false}
          header={state.message || `Valid Amazon ATS Root certificaes found!`}
        />
      );
    }

    const header = "";
    const content = data.unencrypted_secrets_found === true ? "Unencrypted Secrets found" : "Unencrypted Secrets were not found";
    const statusType = data.unencrypted_secrets_found === true ? "error" : "success"
    return (
      <AWSUI.Alert type={statusType} dismissible={false} header={header}>
        {content}
      </AWSUI.Alert>
    );
  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}


/**
 * Parse & render response for Man in the middle attack detection
 * @param {*} props
 */
export const MITMDetection = (props) => {
  const { label, data, state } = props;
  try {
    // in case of unsuccessful test
    if (!isTestSuccess(state)) return renderErrorState(state);

    const mitmDetection = ld.get(data, 'mitmDetection', undefined);
    let alertType, alertMsg;

    switch (mitmDetection) {
      case false:
        alertType = "success";
        alertMsg = "Device is not susceptible to man-in the middle attacks "
        break;
      case true:
        alertType = "error";
        alertMsg = "Unknown Certificate detected! Device has been detected to be susceptible to man-in-the-middle attacks";
        break;
      default:
        alertType = "error";
        alertMsg = "Unable to detect for Man in the middle attack";

    }

    return (
      <AWSUI.Alert
        type={alertType}
        dismissible={false}
        header={state.message || alertMsg}
      />
    );


  } catch (ex) {
    logToConsole(`Failed to parse ${label} data`, ex);
    return (
      <AWSUI.Alert type="warning" dismissible={false}>
        Unable to parse test results
      </AWSUI.Alert>
    );
  }
}

export default {
  PortDetection,
  OSDetection,
  BruteforceTest,
  SSLTest,
  HTTPEnumeration,
  ProtocolsInfo,
  DNSEndpointsInfo,
  DNSEndpointsVolumeInfo,
  DNSEndpointsByIP,
  TLSValidation,
  CipherSuitesDetection,
  ATSCertDetection,
  PlainTextSecrets,
  UtteranceValidationTest,
  MITMDetection
};
