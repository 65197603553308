import { createStore } from 'redux';
import { aqtStates, liveFeedStates, storageStates } from './Reducers.js';

export const aqtStore = createStore(
  aqtStates
);

export const liveFeedStore = createStore(
  liveFeedStates
);

export const stateStore = createStore(
  storageStates
);