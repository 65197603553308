/**
 * This file contains utility methods for FAR Live Feed for Live Run Page
 */

import { FAR_LIVE_DATA_COLUMNS } from './FARLiveRunDataTableConfig';
import { getLoadingMessage, logToConsole } from '../../../Util';
import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import FARConstants  from '../../../_Constants/FARConstants';

/**
 * FAR Util method to load Live Run Table data for all dsns from FarPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadFARLiveTableData(payload) {
    let result = [];
    try {
        let devices = payload.simulationEnvironment.devices;

        for (let i = 0; i < devices.length; i++) {
        let device = devices[i];
        let dsn = device.deviceSerialNumber;

        // load false wake counts
        let deviceTotalWakeCountsForUI = payload.deviceTotalWakeCountsForUI;
        let falseWakeCounts = 0;
        if (deviceTotalWakeCountsForUI && Object.keys(deviceTotalWakeCountsForUI).length > 0 &&
            deviceTotalWakeCountsForUI.hasOwnProperty(dsn)) {
                falseWakeCounts = deviceTotalWakeCountsForUI[dsn];
        }

        // load device online offline status
        let isDeviceOnline = true;
        let offlineDevicesSet = payload.offlineDevicesSet;
        if (offlineDevicesSet && offlineDevicesSet.length > 0 &&
            offlineDevicesSet.includes(dsn)) {
                isDeviceOnline = false;
        }

        let deviceStatus = getOnlineOfflineStatusText(isDeviceOnline);
        let falseWakeCountsDisplay = getFalseWakeCountsText(falseWakeCounts);
        result.push({
            customerId: device.customerId,
            dsn: dsn,
            deviceName: device.deviceName,
            deviceStatus: deviceStatus,
            falseWakeCounts: falseWakeCountsDisplay
        });
        }
    } catch (ex) {
        logToConsole(JSON.stringify(ex));
    }
    return result;
}

/**
 * Method to get display friendly text for device online offline status.
 * @param isDeviceOnline device online or not
 * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
 */
export function getOnlineOfflineStatusText(isDeviceOnline) {
    if (isDeviceOnline === undefined) {
        return AppConstants.UNAVAILABLE;
    }

    let styleMapEntry = FARConstants.DEVICE_ONLINE_OFFLINE_STATUS[isDeviceOnline];
    let deviceStatusText = styleMapEntry.displayText;
    
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
            <div className={ styleMapEntry.deviceStatusClass }>
              <AWSUI.Icon name={ styleMapEntry.deviceStatusIcon }
                  variant={ styleMapEntry.variant }/>
                <span className = { styleMapEntry.deviceStatusTextStyle }>
                  { deviceStatusText }
                </span>
            </div>
            <div>
              {
                (!isDeviceOnline) && 
                (
                  <AWSUI.Tooltip text={ AppConstants.DEVICE_OFFLINE_MESSAGE } size='small' position='right' className='awsui-util-ml-xxl tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                )
             }
            </div>
            <div />
            <div />
          </div>
      </AWSUI.ColumnLayout>
    );
}

/**
 * Method to get display friendly text for device total wake counts
 * @param wakeCounts - wake counts so far
 * @returns Display friendly text
 */
export function getFalseWakeCountsText(wakeCounts) {
    // Undefined check is necessary here because if wakeCounts is 0 then (!wakeCounts) translates into true.
    if ( wakeCounts === undefined ) {
        return AppConstants.UNAVAILABLE;
    }

    let styleMapEntry = FARConstants.WAKE_COUNTS[wakeCounts > AppConstants.MAX_FAR_ALLOWED_WAKES ? false : true];
    
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
            <div className={ styleMapEntry.wakeClass }>
                <span className = { styleMapEntry.wakeTextStyle }>
                  { wakeCounts }
                </span>
            </div>
            <div>
              {
                (wakeCounts > AppConstants.MAX_FAR_ALLOWED_WAKES) && 
                (
                  <AWSUI.Tooltip text={ AppConstants.FAR_DEVICE_FAIL_MESSAGE } size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                    <AWSUI.Icon name='status-info'></AWSUI.Icon>
                  </AWSUI.Tooltip>
                )
             }
            </div>
            <div />
            <div />
          </div>
      </AWSUI.ColumnLayout>
    );
}

/**
 * Util method to get Last Date Time as string from Java Serialized LocalDateTime 
 * @param - payload
 * @returns - datetime in string
 */
export function getLastMeasureTimeFromPayload(localDateTime) {
    let result;
    if (localDateTime && localDateTime.date && localDateTime.time) {
        result = (localDateTime.date.month < 10 ? '0' : '') + localDateTime.date.month + '/' +
                 (localDateTime.date.day < 10 ? '0' : '') + localDateTime.date.day + '/' +
                 localDateTime.date.year + ' ' +
                 (localDateTime.time.hour < 10 ? '0' : '') + localDateTime.time.hour + ':' +
                 (localDateTime.time.minute < 10 ? '0' : '') + localDateTime.time.minute + ':' +
                 (localDateTime.time.second < 10 ? '0' : '') + localDateTime.time.second + ' UTC';
    }
    return result;
}


/**
 * Method to get FAR Running message on the header
 * @param - payload
 * @returns - loading message
 */
export function getFARRunningMessage(payload) {
    let runningMsg = 'Currently Playing audio file ' + (payload.chunkId + 1) + ' of ' + payload.totalChunks + '.';

    // if the last chunk measure is completed then show test completed.
    if (payload.chunkId === payload.totalChunks) {
      runningMsg = "FAR Test Completed!"
    } else if (payload.offlineDevicesSet.length === payload.simulationEnvironment.devices.length) {
      // All Devices are offline.
      runningMsg = "All devices are detected Offline or Muted!";
    }
    return getLoadingMessage(runningMsg);
}

/**
 * Method to get FAR Last Measure timestamp Text to display
 * @param - payload - FAR payload 
 * @returns - loading message
 */
export function getFARLastMeasureMessage(payload) {
    let runningMsg = 'Last device check was performed at ' + getLastMeasureTimeFromPayload(payload.lastMeasureTimestamp);
    return getLoadingMessage(runningMsg);
}

/**
 * Method to get New FAR Live Run Table
 */
export function getFARLiveRunTable(state) {
    if (state &&
        state.testStats.hasOwnProperty('payload')) {
        let payload = state.testStats.payload;
        // Load the FAR data from payload
        let farLiveDSNData = loadFARLiveTableData(payload);

        let liveTableContainer = (
            <div>
            { getFARRunningMessage(payload) }
            <div>
                <AWSUI.Table
                columnDefinitions={ FAR_LIVE_DATA_COLUMNS }
                items={ farLiveDSNData }
                >
                </AWSUI.Table>
            </div>
            { getFARLastMeasureMessage(payload) }
            </div>
            )
        return liveTableContainer;
    }

    return getLoadingMessage(state.testStatus == AppConstants.RASPI_RESOURCES_SYNCING ?
        AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE : AppConstants.RETRIEVING_DATA_MESSAGE);
}