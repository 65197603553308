import {
  Alert,
  Button,
  ColumnLayout,
  FormSection,
  Multiselect,
  Select,
  Tiles,
} from "@amzn/awsui-components-react";
import { testInitSelector } from "newSystem/stores/newRun/testInitSlice";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  CATEGORY_SCENARIO_MAP,
  TESTID_DEVICE_MAP,
} from "../../../../constants";
import {
  customizationSelector,
  scenarioCategoryErrorSelector,
  scenarioSliceSelector,
  SCENARIO_TYPE,
  setScenarioType,
} from "../../../../stores/newRun/acm/scenarioSelectionSlice";
import {
  addCategoryScenarioMap,
  handleScenarioTypeSelection,
  removeCategoryScenarioMap,
  updateCategoryScenario,
} from "../../../../stores/newRun/acm/testCreationMiddleware";
import { fetchManifestUrl } from "../../../../stores/newRun/manifestFetcher";
import { setTestOpt } from "../../../../stores/newRun/testOptsSlice";

export default () => {
  const testInit = useSelector(testInitSelector);
  const scenarioType = useSelector(scenarioSliceSelector);
  const errors = useSelector(scenarioCategoryErrorSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScenarioType(SCENARIO_TYPE.STANDARD));
    // TODO: This needs to be updated as per standard or custom selection;
    // TODO: These urls has to be generated from CustomTestAssetGenerator Step Fn.
    const audioFilesS3Suffix =
      "https://dtzsgmefzdeac.cloudfront.net/" +
      testInit.testSuite.audioFilesUri.split("/").slice(3, 6).join("/") +
      "/";
    const labDependenciesS3Path =
      "https://dtzsgmefzdeac.cloudfront.net/" +
      testInit.testSuite.labDependenciesUri.split("/").slice(3).join("/");
    const s3FeaturePath = testInit.testSuite.testDefinitionUri;
    const labDependenciesUri = testInit.testSuite.labDependenciesUri;
    const features = [testInit.testSuite.mapping[0].tag];
    fetchManifestUrl(labDependenciesS3Path).then((manifestUrl) => {
      dispatch(
        setTestOpt({
          audioFilesS3Suffix,
          manifestUrl,
          s3FeaturePath,
          features,
          labDependenciesUri,
        })
      );
    });
  }, []);

  const handleCustomization = (e) => {
    dispatch(handleScenarioTypeSelection(e.detail.value));
  };
  const items = [
    // {
    //   "label": <FormattedMessage id="CUSTOM" />,
    //   "description": <FormattedMessage id="RUN_CUSTOMIZED_TESTS" />,
    //   "value": SCENARIO_TYPE.CUSTOM
    // },
    {
      label: <FormattedMessage id="STANDARD" />,
      description: <FormattedMessage id="RUN_STANDARD_TESTS" />,
      value: SCENARIO_TYPE.STANDARD,
    },
  ];

  return (
    <FormSection header={<FormattedMessage id="SCENARIO_SELECTION" />}>
      <ColumnLayout>
        <Tiles
          value={scenarioType}
          onChange={handleCustomization}
          items={items}
        ></Tiles>
      </ColumnLayout>
      {scenarioType === SCENARIO_TYPE.CUSTOM ? (
        <CategoryScenarioMap errors={errors} />
      ) : null}
    </FormSection>
  );
};

const CategoryScenarioMap = ({ errors }) => {
  const customizedScenarios = useSelector(customizationSelector);
  let errView;

  const dispatch = useDispatch();
  const onCatScenarioUpdate = ({ category, scenarios, index }) => {
    dispatch(
      updateCategoryScenario({
        category,
        scenarios,
        index,
      })
    );
  };

  const onRemove = ({ index }) => {
    dispatch(
      removeCategoryScenarioMap({
        index,
      })
    );
  };

  const onAdd = () => {
    dispatch(addCategoryScenarioMap());
  };

  const MAX_CATEGORIES = Object.keys(CATEGORY_SCENARIO_MAP).length;

  const CATEGORY_LIST = Object.keys(CATEGORY_SCENARIO_MAP);

  const selectedCategories = customizedScenarios.map(
    ({ category }) => category
  );
  const catScenarioView = customizedScenarios.map(
    ({ category, scenarios }, index) => {
      const categoryOptions = CATEGORY_LIST.filter(
        (cat) => !selectedCategories.includes(cat) && cat !== category
      );

      const scenarioOptions = CATEGORY_SCENARIO_MAP[category] || [];
      return (
        <DrawCategoryScenarioRow
          key={index}
          categoryOptions={categoryOptions}
          scenarioOptions={scenarioOptions}
          index={index}
          selectedCategory={category}
          selectedScenarios={scenarios || []}
          onCatAndScenarioChange={onCatScenarioUpdate}
          canAdd={
            MAX_CATEGORIES !== index + 1 &&
            index === customizedScenarios.length - 1
          }
          onAdd={onAdd}
          canRemove={customizedScenarios.length > 1}
          onRemove={onRemove}
        />
      );
    }
  );

  if (errors && typeof errors["categoryScenarioMap"] === "object") {
    errView = Object.entries(errors["categoryScenarioMap"]).map((entry) => {
      const index = entry[0];
      const errorString = entry[1];
      return (
        <Alert
          visible={true}
          key={index}
          className={"awsui-util-mt-s"}
          type="error"
        >
          {`${errorString} at index ${index}`}
        </Alert>
      );
    });
  }

  return (
    <div>
      <div className="awsui-util-mt-xxl">{errView}</div>
      <div className="awsui-grid awsui-util-mt-xxl">
        <div className="awsui-row">
          <div className="col-4">
            <FormattedMessage id="SELECT_CATEGORY" />
          </div>
          <div className="col-5">
            <FormattedMessage id="SELECT_SCENARIO" />
          </div>
          <div className="col-3"></div>
        </div>
        {catScenarioView}
      </div>
    </div>
  );
};

const DrawCategoryScenarioRow = ({
  categoryOptions,
  scenarioOptions,
  selectedCategory,
  index,
  selectedScenarios,
  onCatAndScenarioChange,
  canAdd,
  onAdd,
  canRemove,
  onRemove,
}) => {
  const handleCategoryChange = (e) => {
    const category = e.detail.selectedId;
    onCatAndScenarioChange({ category, index });
  };

  const handleScenarioSelection = (e) => {
    const scenarioOptions = e.detail.selectedOptions.map(({ id }) => id);
    onCatAndScenarioChange({
      category: selectedCategory,
      scenarios: scenarioOptions,
      index,
    });
  };

  const handleAdd = () => {
    onAdd({
      category: "",
      scenario: [],
    });
  };

  const handleRemove = () => {
    onRemove({
      category: selectedCategory,
      index,
    });
  };

  return (
    <div className="awsui-row">
      <div className="col-4">
        <Select
          options={categoryOptions.map((category) => ({
            label: category,
            id: category,
          }))}
          onChange={handleCategoryChange}
          selectedOption={{ label: selectedCategory, id: selectedCategory }}
          selectedLabel="Selected"
        />
      </div>
      <div className="col-5">
        <Multiselect
          disabled={!selectedCategory}
          options={scenarioOptions.map(({ scenarioId }) => ({
            label: TESTID_DEVICE_MAP[scenarioId]["scenario"],
            id: scenarioId,
          }))}
          onChange={handleScenarioSelection}
          selectedOptions={selectedScenarios.map((scenarioId) => ({
            label: TESTID_DEVICE_MAP[scenarioId]["scenario"],
            id: scenarioId,
          }))}
          selectedLabel="Selected"
          keepOpen={false}
        />
      </div>
      <div className="col-3">
        {canRemove ? (
          <Button onClick={handleRemove}>
            <FormattedMessage id="REMOVE" />
          </Button>
        ) : null}
        {canAdd ? (
          <Button onClick={handleAdd}>
            <FormattedMessage id="ADD" />
          </Button>
        ) : null}
      </div>
    </div>
  );
};
