import axios from 'axios';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import { aqtStore } from '../../Components/State/Store';
import { withHeader } from '../../Auth';
import { networkError, getSession, logToConsole } from '../../Util';
import { isEmpty } from 'lodash';
import {get_lab_dependency_url, get_sub_type_for_test_options} from '../../Util/AQTTestOptionsParser';
import { getExperimentPrefix, getStateMachineType } from './Utilities/controllerUtils';
import ld from 'lodash';

/**
 * Method which validates mapping submitted from UI
 * @param testParams Test parameters submitted from New run page
 * @param skipValidation Map for which validation can be ignored
 * @returns true or false based on if mapping is valid or invalid
 */
function validateMapping(testParams, skipValidation) {
  let isValid = true;
  if (testParams && testParams.mapping && Object.keys(testParams.mapping).length >= 4) {
    Object.keys(testParams.mapping).forEach(map => {
      if (map !== skipValidation && isEmpty(testParams.mapping[map])) {
        isValid = false;
      }
    });
  } else {
    isValid = false;
  }
  return isValid;
}

export function executeTest(testParams, additionalParams) {
  logToConsole("Current testParam is " + JSON.stringify(testParams));
  logToConsole("Current additionalParams is " + JSON.stringify(additionalParams));
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }

  // For certain test suites such as Functional, we need not validate entire mapping.
  // For example, noiseMapping can be empty
  let skipValidation = testParams.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID
    || MusicConstants.MUSIC_SCENARIO_ID
    || AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID ? 'noiseMapping'
    : AppConstants.EMPTY;

  // Quick fix to not allow job submission on empty mapping
  if (testParams && validateMapping(testParams, skipValidation)) {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    let dut;
    let jobDefinitionVersion;
    let body = {};
    if (testParams.isDsnInputField) {
      body = {
        typeName: testParams.deviceTypeId,
        typeId: testParams.deviceTypeId,
        dsn: testParams.dsn,
        customerId: testParams.customerId,
        buildInfo: testParams.buildInfo
      }
    }

    let scenarioId = testParams.scenarioId;

    // For FAR: Hijack ScenarioId for AVSCertFAR and replace it with AVSCertSimulation
    /* TODO: Until Controller supports multi scenario ids for Test Sequencing, FAR will use AVSCertSimulation
    if (AppConstants.FAR_TEST_TYPES.includes(testParams.testType)) {
      let farScenarioId = testParams.farScenarioId;
      if ( farScenarioId && farScenarioId !== AppConstants.EMPTY) {
        scenarioId = farScenarioId;
      } else {
        logToConsole('Looks like we were not able to get FAR Scenario Id, This will kick off the OLD FAR report and will cause problem.');
      }
    }*/

    return createOrUpdateDUT(body)
      .then(dutResponse => {
        if (!dutResponse.hasOwnProperty('error')) {
          dut = dutResponse;
          let devicesUnderTestId = Object.keys(dut).length > 1 ? dut.id : testParams.deviceUnderTestId;
          let body = {
            testName: testParams.testName,
            devicesUnderTestId: devicesUnderTestId,
            labId: testParams.labId,
            mapping: testParams.mapping,
            scenarioId: scenarioId
          }
          logToConsole('DUT : ' + JSON.stringify(dut));
          return axios.post(controllerEndpoint +
              ApiConstants.POST_JOB_DEF_SIMPLIFIED,
              body,
              withHeader(aqtStore.getState().session.idToken.jwtToken))
            .catch(error => {
              networkError(error, executeTest.name);
              throw error;
            });
        } else {
          throw dut.error;
        }
      })
      .then(jobDefResponse => {
        if (jobDefResponse.hasOwnProperty('data')) {
          jobDefinitionVersion = jobDefResponse['data'];
          let body = {
            jobDefinitionVersionId: jobDefinitionVersion.id,
            sendCompletionEmail: testParams.sendCompletionEmail,
            scenarioOptions: {
              testName : jobDefinitionVersion.scenarioName,
              deviceType: testParams.dutType,
              /** DeviceTypeId tells about the application */
              deviceTypeId: testParams.deviceTypeId,
              /** deviceConfig will provide the path for A4PC device config */
              deviceConfig: testParams.deviceConfig,
              /**
               * testSubmissionTimestamp is the time when user submits test
               * from the New Run page.
               * Timestamp is timezone independent
               */
              testSubmissionTimestamp: Date.now(),
              marketPlace: testParams.marketPlace,
              testType: testParams.testType,
              testOptions: testParams.testOptions,
              userPreferenceMapping: testParams.userPreferenceMapping,
              releaseVersionNumber: additionalParams.releaseNotesVersion,

              subType: get_sub_type_for_test_options(testParams.testOptions.testSuite,
                  testParams.testOptions.scenarioType, testParams.testType, testParams.marketPlace)
            }
          }
          logToConsole('Job Definition : ' + JSON.stringify(body));

          // Enable V2 API for test suites requiring auto sync
          if (testParams.testSuite && ((testParams.testSuite === MusicConstants.MUSIC_SCENARIO_ID
               || testParams.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID
               || testParams.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
               || testParams.testSuite === AppConstants.SECURITY_SCENARIO_ID
               || testParams.testSuite === AppConstants.STABILITY_SCENARIO_ID
               || testParams.testSuite === AppConstants.QUAL_SCENARIO_ID
               || testParams.testSuite === AppConstants.UPL_SCENARIO_ID
               || testParams.testSuite === AppConstants.MOBILE_SUITE_ID
               || testParams.testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID
               || ((testParams.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID ||
                    testParams.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID)
                   && testParams.isAutoSyncEnabled)))) {
            return axios.post(controllerEndpoint +
                ApiConstants.POST_JOB_SUBMIT_URL_WITH_AUTO_SYNC,
                body,
                withHeader(aqtStore.getState().session.idToken.jwtToken))
                .catch(error => {
                  networkError(error, executeTest.name);
                  throw error;
                });
          } else {
            return axios.post(controllerEndpoint +
                ApiConstants.POST_JOB_SUBMIT_URL,
                body,
                withHeader(aqtStore.getState().session.idToken.jwtToken))
                .catch(error => {
                  networkError(error, executeTest.name);
                  throw error;
                });
          }
        } else {
          logToConsole('Job Definition ERR : ' + JSON.stringify(jobDefResponse));
          throw AppConstants.SERVERERR;
        }
      })
      .then(jobDefinition => {
        logToConsole('Job Id : ', jobDefinition.data.id);
        if (jobDefinition.hasOwnProperty('data')) {
          return jobDefinition.data.id;
        } else {
          throw AppConstants.SERVERERR;
        }
      })
      .catch(error => {
        let errorMessage = error;
        if (error !== AppConstants.SERVERERR) {
          networkError(error, executeTest.name);
          errorMessage = AppConstants.NETWORKERR;
        }
        return new Promise(resolve => {
          resolve({
            error: errorMessage
          })
        });
      });
  } else {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.VALIDATIONERR
      })
    });
  }
}

export function getWhitelistedCustomerIds() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
      ApiConstants.GET_CID_WHITELIST,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(cidResponse => {
      if (cidResponse.hasOwnProperty('data')) {
        logToConsole('Get CID Whitelist : ' + JSON.stringify(cidResponse.data));
        return { cid: cidResponse.data ? cidResponse.data : [] };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, getWhitelistedCustomerIds.name);
      return { error: AppConstants.NETWORKERR };
    });
}

export function getDUTs(customerId, forceRefresh) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let queryParams = AppConstants.EMPTY;
  if (customerId) {
    queryParams = '?q={"customerId": "' + customerId + '"}' +
    '&forceRefresh=' + forceRefresh;
  }
  return axios.get(controllerEndpoint +
      ApiConstants.POST_DUT +
      queryParams,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(dutResponse => {
      if (dutResponse.hasOwnProperty('data')) {
        logToConsole('Get DUT : ' + JSON.stringify(dutResponse.data));
        // online data in first order here
        return { duts: dutResponse.data ? dutResponse.data : [] };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, getDUTs.name);
      return { error: AppConstants.NETWORKERR };
    });
}

export function createOrUpdateDUT(dutDetails) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (Object.keys(dutDetails).length === 0) {
    // When DSN dropdown contains values,  no need to create or updated DUT
    return new Promise(resolve => {
      resolve({
        data: {}
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    let updateDUT = false;
    return getDUTs()
      .then(dutResponse => {
        dutResponse.duts.some(dut => {
          return dut.dsn === dutDetails.dsn && (updateDUT = dut);
        });
        return updateDUT;
      })
    .then(updateDUT => {
      if (updateDUT) {
        logToConsole('Updating DUT');
        return axios.put(controllerEndpoint +
          ApiConstants.POST_DUT + '/' +
          updateDUT.id,
          dutDetails,
          withHeader(aqtStore.getState().session.idToken.jwtToken))
        .then(updateDUTResponse => {
          if (updateDUTResponse.hasOwnProperty('data')) {
            return updateDUTResponse.data;
          } else {
            return { error: AppConstants.SERVERERR };
          }
        })
        .catch(error => {
          networkError(error, createOrUpdateDUT.name);
          return { error: AppConstants.NETWORKERR };
        });
      } else {
        logToConsole('Creating DUT');
        return axios.post(controllerEndpoint +
          ApiConstants.POST_DUT,
          dutDetails,
          withHeader(aqtStore.getState().session.idToken.jwtToken))
        .then(dutResponse => {
          if (dutResponse.hasOwnProperty('data')) {
            return dutResponse.data;
          } else {
            return { error: AppConstants.SERVERERR };
          }
        })
        .catch(error => {
          networkError(error, createOrUpdateDUT.name);
          return { error: AppConstants.NETWORKERR };
        });
      }
    });
  }
}

/**
 * Submit test options to the POST Experiment api.
 * This will add test options in sequence to the associated experiment id which will be launched later
 * either sequentially or parallel based on the requirements.
 * @param {string} experimentId
 * @param {} variantBody
 */
export function postVariantToExperiment(experimentId, variantBody) {
  let ENDPOINT = aqtStore.getState().environment.controllerEndpoint;
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise((resolve, reject) => {
      reject(new Error(AppConstants.SESSIONERR));
    });
  }
  return axios.post(ENDPOINT +
    //  '/api/experiments/variants
    ApiConstants.GET_EXPERIMENT + '/' + experimentId + '/variants',
    variantBody,
    withHeader(aqtStore.getState().session.idToken.jwtToken)).then(response => {
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty('data')) {
      logToConsole('TestLog - Variants submitted successfully. Data = ' + JSON.stringify(response.data));
      return { data: response.data }
    } else {
      logToConsole('TestLog - Error submitting variants. Error = ' + JSON.stringify(response));
      return {error: AppConstants.SERVERERR}
    }
  }).catch(error => {
    // Handle post variants error
    logToConsole('TestLog - Exception submitting variants:' + error);
    if (error.message.includes('failed with status code 403')) {
      return {error: AppConstants.JOB_QUEUE_LOAD_ERROR}
    } else {
      return { error: AppConstants.NETWORK_ERROR }
    }
  });
}

/**
 * Helper function to wait for the array of promises to resolve or reject and gives back the response
 * @param promises
 * @returns {*}
 */
function waitPromiseArrayToComplete(promises) {
  return Promise.all(promises).then((values) => {
    return values;
  }).catch((err) => {
    logToConsole('Error resolving one of the promises ' + JSON.stringify(err));
    return { error: AppConstants.SERVERERR }
  });
}

/**
 * Function to call run api of the experiment to start the test sequence.
 * @param experimentId
 * @param optionsBody
 * @param runExperimentApiPrefix
 * @returns {Promise<AxiosResponse<any>>}
 */
export function submitExperimentRun(experimentId, optionsBody, runExperimentApiPrefix= ApiConstants.EXPERIMENT_V1) {
  let ENDPOINT = aqtStore.getState().environment.controllerEndpoint;
  return axios.post(ENDPOINT +
    runExperimentApiPrefix + '/' + experimentId + '/runs',
    optionsBody,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
        if (response.hasOwnProperty('data')) {
          logToConsole('TestLog - Sequential jobs submitted successfully. Data = ' + JSON.stringify(response.data));
          return {data: response.data}
        } else {
          logToConsole('TestLog - Error submitting sequential jobs.');
          throw new Error(AppConstants.SERVERERR);
        }
      }
    )
    .catch(error => {
      logToConsole('TestLog - Exception submitting sequential jobs.' + JSON.stringify(error));
      networkError(error, submitExperimentRun.name);
      throw error;
    });
}

/**
 * Runs an experiement which will contain one or more jobs which can be run sequentially
 * Each experient will contain list of variants where each variant stands for single job
 * Submitting single experiement will result in submitting one or more jobs at the same time
 * which will be executed sequentially
 */
export function runExperiment(testParams, additionalParams) {
  const JOB_QUEUE_SIZE_EXCEEDED =
    `Cannot sequence more than ${AppConstants.JOB_SEQUENCE_LIMIT} tests. Please limit the number of tests and retry.`;
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }

  // If list of scenarios to run is empty, return error
  if(testParams.variantJson && testParams.variantJson.length === 0) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  } else if (testParams.variantJson && testParams.variantJson.length > AppConstants.JOB_SEQUENCE_LIMIT) {
    return new Promise(resolve => {
      resolve({
        error: JOB_QUEUE_SIZE_EXCEEDED
      })
    });
  }

  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let dut;
  let body = {};
  if (testParams.isDsnInputField) {
    body = {
      typeName: testParams.deviceTypeId,
      typeId: testParams.deviceTypeId,
      dsn: testParams.dsn,
      customerId: testParams.customerId,
      buildInfo: testParams.buildInfo
    }
  }

  // APIs are executed in the following order:
  // 1. POST /api/experiments --> Returns unique experiemnt ID for this experiment
  // 2. POST /api/experiments/<experimentId>/variants --> Submits one or more variants (jobs) at the same
  //    time
  // 3. POST /api/experiments/<experimentId>/runs --> Runs one or more submitted variants in sequential way
  return createOrUpdateDUT(body)
  .then(dutResponse => {
    if (!dutResponse.hasOwnProperty('error')) {
      dut = dutResponse;
      logToConsole('TestLog - DUT : ' + JSON.stringify(dut));
      // Generate unique experiment name
      let currentDate = new Date();
      let currentTime = currentDate.getTime();
      let body = {
        name: 'testExperiment_' + currentTime,
        scenarioId: testParams.scenarioId
      };
      // 1. Invoke POST /api/experiments which will return unique experiment ID
      return axios.post(controllerEndpoint +
        ApiConstants.GET_EXPERIMENT,
        body,
        withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(response => {
        if (response.hasOwnProperty('data')) {
          let experimentData = response.data;
          if(experimentData.hasOwnProperty('id')) {
            let experimentId = experimentData.id;
            // 2. Invoke POST /api/experiments/<experimentId>/variants API to submit one or more variants (jobs)
            //    at the same time
            logToConsole('TestLog - Invoking variant API to submit multiple jobs');
            // Generate JSON to invoke variant API
            let variants = testParams.variantJson;
            let addVariantToExperiment = [];
            for(let index = 0; variants && index < variants.length; index++) {
              let variant  = variants[index];
              let options = {
                testName: variant.calculatedTestName,
                deviceType: testParams.deviceType,
                /** DeviceTypeId tells about the application */
                deviceTypeId: testParams.deviceTypeId,
                /** deviceConfig will provide the path for A4PC device config */
                deviceConfig: testParams.deviceConfig,
                /**
                 * testSubmissionTimestamp is the time when user submits test
                 * from the New Run page.
                 * Timestamp is timezone independent
                 */
                testSubmissionTimestamp: Date.now(),
                marketPlace: variant.marketPlace,
                locale: variant.scenarioType === AppConstants.WAKEWORD ? variant.marketPlace : null,
                testType: variant.testType,
                testOptions: variant.testOptions,
                executionOrder: index,
                releaseVersionNumber: additionalParams.releaseNotesVersion,
                // subType is used for auto resource sync
                subType: get_sub_type_for_test_options(variant.testOptions.testSuite,
                  variant.testOptions.scenarioType, variant.testType, variant.marketPlace),
                labDependenciesUri: get_lab_dependency_url(variant.scenarioType, testParams.labDependenciesUri)
              };

              /** 
               * FAR+: Add the locale and testSuite to options to map the inputs of new run to OAK run
               * New run input doesn't has locale field and testSuite is present under testOptions
               * OAK sample input: {\"testName\":\"test\",\"locale\":\"ja_JP\",\"testSuite\":\"ACOUSTIC\"..}
               */
              if (variant.scenarioType === AppConstants.FAR_PLUS) {
                options.locale = variant.marketPlace;
                options.testSuite = variant.testOptions.testSuite;
              }
              // Generate mapping for variant
              let customMapping = {
                deviceMapping: variant.deviceMapping, // TODO: make it testParams.mapping.deviceMapping
                actorMapping: variant.mapping.actorMapping,
                noiseMapping: variant.mapping.noiseMapping
              };
              let stateMachineType = getStateMachineType(testParams.isAutoSyncEnabled, testParams.testSuite);
              let variantBody = {
                name: 'testVariant_' + currentTime + '_' + index,
                labId: testParams.labId,
                devicesUnderTestId: Object.keys(dut).length > 1 ? dut.id : testParams.deviceUnderTestId,
                customMapping: customMapping,
                options: options,
                order: index,
                stateMachineType: stateMachineType,
              }
              // Call Post experiment variant api for each test in the sequence
              addVariantToExperiment.push(postVariantToExperiment(experimentId, variantBody));
            }
            logToConsole(' TestLog - Number of Variants submitted = ' + addVariantToExperiment.length);
            // Wait for all Variants to submit
            return Promise.all(addVariantToExperiment).then((promResponse) => {
              logToConsole("Response block of all fulfilled promises = " + promResponse);
              // eslint-disable-next-line no-prototype-builtins
              if(promResponse) {
                ld.forEach(promResponse, function(value) {
                  if (value.hasOwnProperty('error')) {
                    return {error: AppConstants.SERVERERR}
                  }
                });
                // 3. Invoke POST /api/experiments/<experimentId>/runs API to run submitted variants (jobs) sequentially
                let seqBody = {
                  launchSequentially: true,
                  sendCompletionEmail: testParams.sendCompletionEmail,
                };
                // TODO: go through v1 controller can also go through v2 state machine, remove it shortly.
                let runExperimentApiPrefix = getExperimentPrefix(testParams.isAutoSyncEnabled, testParams.testSuite);
                return submitExperimentRun(experimentId, seqBody, runExperimentApiPrefix);
              } else {
                // Handle post variants error
                logToConsole('TestLog - Exception fulfilling promises: ');
                return {error: AppConstants.SERVERERR}
              }
            }).catch(error => {
              // Handle post variants error
              logToConsole('TestLog - Exception submitting variants: ' + JSON.stringify(error));
              if (error.message.includes('failed with status code 403')) {
                return {error: AppConstants.JOB_QUEUE_LOAD_ERROR}
              } else {
                networkError(error, 'queueVariants');
                return {error: AppConstants.SERVERERR}
              }
            });
          } else {
            logToConsole('TestLog - Error retrieving experiment ID');
            return { error: AppConstants.SERVERERR }
          }
        } else {
          logToConsole('TestLog - Error submitting experiment');
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        logToConsole('TestLog - Exception submitting an experiment');
        return { error: AppConstants.NETWORKERR };
      });
    } else {
      logToConsole('TestLog - Error getting DUT information');
      throw dut.error;
    }
  }).catch(error => {
    logToConsole('TestLog - Exception getting DUT information');
    networkError(error, createOrUpdateDUT.name);
    return { error: AppConstants.NETWORKERR };
  });
}

/**
 * Get raspi details (things) for the given lab
 * @param {String} labId
 */
export function getThingsForLab(labId) {
  // TODO: this should be handled in a better way!
  if (getSession() === AppConstants.SESSIONERR) {
    return Promise.resolve({
      error: AppConstants.SESSIONERR
    });
  }

  if (!labId) {
    return Promise.reject({
      error: 'Missing labId'
    })
  }

  const { environment, session } = aqtStore.getState();

  const host = environment.controllerEndpoint;
  const { VIEW_GROUP_LIST_URL, THINGS } = ApiConstants;
  const api = `${host}${VIEW_GROUP_LIST_URL}/${labId}/${THINGS}`;
  return axios.get(api, withHeader(session.idToken.jwtToken));
}
