import { createSlice } from '@reduxjs/toolkit'

const getInitialState = () => ({
  deviceAttributes: {
    irBlasters: false,
    dimmable: false
  }
});

export const deviceAttributesSlice = createSlice({
  name: 'deviceAttributes',
  initialState: getInitialState(),
  reducers: {
    setDeviceAttributes: (state, action) => {
      state.deviceAttributes = {...action.payload};
    },
    reset: () => getInitialState()
  }
})

export const {
  setDeviceAttributes,
  reset
} = deviceAttributesSlice.actions



export const deviceAttributesSliceSelector = (state) => {
  return state.wwaScenarioSelection.deviceAttributes;
}

export default deviceAttributesSlice.reducer
