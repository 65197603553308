import React from "react";
import AWSUI from "@amzn/awsui-components-react";
import { getRefMicConsentMessage } from "./uiUtils";
import {
  getValueForFunctionalTestCasesCheckbox,
  setValueForFunctionalTestCasesCheckbox,
  setValueForExpandedSection,
} from "./FunctionalUtil";

import AppConstants from "../../_Constants/AppConstants";
import FunctionalTestCases from "../../_Constants/FunctionalTestCasesConstants";

import { setCheckedStateInTestParams } from "./uiActionsUtils";
import { sendRawInfoToNewRunWithIdentifier } from "./NewRunDataTransferUtils";
class FunctionalSubScenarioSelection extends React.Component {

  /**
   * Renders Functional Test cases for Custom Functional scenario
   */
  getCustomOptFieldFunctional = () => {
    const {
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
    } = this.props.params;

    let mapping, label

    if (selectedScenario.scenarioType === FunctionalTestCases.FUNC_CUSTOM) {
      mapping = FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING;
      label = "Functional Test Cases";
    } else if (selectedScenario.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM) {
      mapping = FunctionalTestCases.FUNCTIONAL_AUTOMOTIVE_TEST_CASES_MAPPING;
      label = "Functional Test Cases";
    } else {
      mapping = FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING;
      label = "Auto Navigation Functional Test Cases";

    }
    return (
      <AWSUI.FormField
        label={<h3>{label}</h3>}
        errorText={
          selectedScenarioError.functionalTestCases
            ? FunctionalTestCases.FUNC_CUSTOM_ERROR
            : AppConstants.EMPTY
        }
      >
        {Object.keys(mapping).map(
          (functionalTestCaseCategory) => {
            return (
              <AWSUI.ExpandableSection
                header={functionalTestCaseCategory}
                expanded={selectedScenario.expandedSections.includes(
                  functionalTestCaseCategory
                )}
                onChange={(event) => {
                  this.onSectionExpanded(
                    functionalTestCaseCategory,
                    event,
                    selectedScenario.expandedSections,
                    "expandedSections",
                    selectedScenario,
                    selectedScenarioCallBack
                  );
                }}
              >
                <AWSUI.ColumnLayout
                  columns={4}
                  className="awsui-util-container"
                >
                  <div
                    data-awsui-column-layout-root="true"
                    className="awsui-util-pb-s"
                  >
                    <AWSUI.Checkbox
                      label={"All"}
                      checked={getValueForFunctionalTestCasesCheckbox(
                        functionalTestCaseCategory,
                        selectedScenario.functionalTestCases
                      )}
                      onChange={(event) => {
                        this.onChangeCheckFunctional(
                          functionalTestCaseCategory,
                          event,
                          selectedScenario,
                          selectedScenarioCallBack,
                          selectedScenarioError,
                          scenarioErrorCallBack
                        );
                      }}
                    />
                    {mapping[
                      functionalTestCaseCategory
                    ].map((functionalTestCase) => {
                      return (
                        <AWSUI.Checkbox
                          label={functionalTestCase["label"]}
                          checked={getValueForFunctionalTestCasesCheckbox(
                            functionalTestCase["id"],
                            selectedScenario.functionalTestCases
                          )}
                          onChange={(event) => {
                            this.onChangeCheckFunctional(
                              functionalTestCase["id"],
                              event,
                              selectedScenario,
                              selectedScenarioCallBack,
                              selectedScenarioError,
                              scenarioErrorCallBack
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </AWSUI.ColumnLayout>
              </AWSUI.ExpandableSection>
            );
          }
        )}
      </AWSUI.FormField>
    );
  };



  /**
   * TODO: maybe make it public
   * Event handler for expanding/ collapsing Expandable sections - used by Functional
   * Custom Scenario & FAR Custom scenario
   * Use Case 1. in Functional, when Custom functional scenario is selected
   * @param {*} id
   * @param {*} event
   * @param {*} expandedSections
   * @param {*} stateId looks like always 'expandedSections' for now, applies for FAR Custom as well
   * @param {*} params
   * @param {*} updateParamsCallBack
   */
  onSectionExpanded = (id, event, expandedSections, stateId,
    params, updateParamsCallBack) => {
    const identifier = "FunctionTestSubScenarioSelection, onSectionExpanded";
    expandedSections = setValueForExpandedSection(id, event, expandedSections);
    let updatedParams = params;
    updatedParams[stateId] = expandedSections;
    sendRawInfoToNewRunWithIdentifier(
      identifier,
      updatedParams,
      updateParamsCallBack
    );
  };

  /**
   * Event handler for checking/ unchecking Functional test case checkboxes
   * @param {*} id
   * @param {*} event
   * @param {*} params
   * @param {*} updateParamsCallBack
   * @param {*} paramsError
   * @param {*} updateParamsErrorCallBack
   */
  onChangeCheckFunctional = (id, event, params, updateParamsCallBack,
    paramsError, updateParamsErrorCallBack) => {
    const identifier = 'FunctionalSubScenarioSelection, onChangeCheckFunctional';
    let functionalTestCases = setValueForFunctionalTestCasesCheckbox(
      id, event, params.functionalTestCases);
    let updatedParams = params;
    updatedParams.functionalTestCases = functionalTestCases;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);
    // update error field
    if (updatedParams.functionalTestCases.length > 0) {
      let updatedParamsError = paramsError;
      updatedParamsError.functionalTestCase = false;
      sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
    }
  };

  /**
  * Display Checkbox to select whether the device is Multimodal or not for Functional Test Suite.
  * @param {*} selectedScenario
  * @param {*} selectedScenarioCallBack
  */
  getMultimodalDeviceCheckbox = (selectedScenario, selectedScenarioCallBack) => {
    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.Checkbox label='This test is for Multimodal device (DUT with a display).'
              checked={selectedScenario.isMultimodalDevice}
              onChange={event => { setCheckedStateInTestParams(event, "isMultimodalDevice",
              selectedScenario, selectedScenarioCallBack) }} />
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  render() {
    const {
      selectedScenario, selectedScenarioCallBack
    } = this.props.params;
    return (
      <div>
        <div>
          {
            // multimodel checkbox for functional test suite
            this.getMultimodalDeviceCheckbox(selectedScenario, selectedScenarioCallBack)
          }
          {(selectedScenario.scenarioType === FunctionalTestCases.FUNC_CUSTOM  ||
            selectedScenario.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM ||
            selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM) &&
            this.getCustomOptFieldFunctional()}
        </div>
        <div
          align="center"
          className="awsui-util-mt-xl awsui-util-mb-xl ref-mic-consent-message"
        >
          {getRefMicConsentMessage()}
        </div>
      </div>
    );
  }
}

export default FunctionalSubScenarioSelection;
