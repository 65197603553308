import { Button, Cards, ColumnLayout } from "@amzn/awsui-components-react";
import { CardsPagination } from "@amzn/awsui-components-react/polaris";
import React from "react";
import CPane from "./ColumnPane";
import "./styles.css";

function InfoContainer({ name, panes }) {
  return (
    <div className="awsui-util-container">
      {/* <div className="awsui-util-container-header">
        <h2>{name}</h2>
      </div> */}
      <ColumnLayout columns={panes.length} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {panes.map((pane) => {
            return <CPane keyPairs={pane} />;
          })}
        </div>
      </ColumnLayout>
    </div>
  );
}

function CardContainer({ deviceDef, devices }) {
  return (
    <Cards
      cardDefinition={deviceDef}
      items={devices}
      features={["pagination"]}
      cardsPerRow={[
        {
          cards: 6,
          // minWidth: 700,
        },
      ]}
      loadingText="Loading resources"
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-xs awsui-util-mb-xs">
            <b>No devices</b>
          </div>
        </div>
      }
    >
      <CardsPagination pageSize={6} />
    </Cards>
  );
}

export { InfoContainer, CardContainer };
