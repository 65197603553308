import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import { onSectionExpanded } from '../uiActionsUtils';
import { generateDUTListWithOnlineOfflineStatus } from '../TestGroupInfoHelpers';
import { logToConsole, validateInputOptional } from '../../../Util';
import {
  generateDeviceMapping, deleteDUTFromDevicesMap, refreshDUTList,
  deleteValueFromDevicesMap, getValueFromDevicesMap
} from '../MappingGeneratorUtil';
import { getDUTs } from '../controller';
import {
  getErrorText, getErrorTextForDropdown, shouldDisableDropdown,
  getAmazonIdForDut, getDeviceNameForDut
} from '../Util';

import { getCommonSelect, getDUTsLoadingMessage, getInputFieldOptional } from '../uiUtils';
import { sendRawInfoToNewRunWithIdentifier, sendRawInfoToNewRunWithStateId } from '../NewRunDataTransferUtils';

const REFRESH_DSN_LABEL = 'Refresh';
const ADD_DSN_LABEL = 'Add';

class FARCustomSubpage extends React.Component {

  state = {
    additionalDutListLoading: new Array(AppConstants.MAX_OPTIONAL_DUTS).fill(false),
    addAdditionalDUTManually: new Array(AppConstants.MAX_OPTIONAL_DUTS).fill(false),
  }

  /**
   * TODO: Move to Utils as this method is shared with MusicSuiteTestType
   * Gets 4 optional Devices fields for FAR Custom type test
   * @return Returns 4 fields for each optional device:
   * 1. CID dropdown
   * 2. DSN dropdown or input field depending on if DUT list for CID is non-empty or not
   * 3. Amazon ID dropdown or input field depending on DSN is dropdown or not
   * 4. Build Info input field
   */
  getOptionalDevices = (selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack) => {
    return (
      <AWSUI.FormField
        label={<h2>Additional DUTs (Optional)</h2>}
        className='awsui-util-m-l'
      >
        <div>
          {[...Array(AppConstants.MAX_OPTIONAL_DUTS).keys()].map(i => {
            let id = 'DUT_' + (i + 1);
            let header = 'DUT-' + (i + 1);
            return (
              <AWSUI.ExpandableSection
                key={id} // get rid of warning of unqiue key prop
                id={id}
                header={header}
                expanded={selectedScenario.expandedSectionsFARCustom.includes(i + 1)}
                onChange={event => {
                  onSectionExpanded(i + 1, event,
                    selectedScenario.expandedSectionsFARCustom, 'expandedSectionsFARCustom', selectedScenario, selectedScenarioCallBack)
                }}
              >
                {
                  this.getDUTInformationSectionOptional(i + 1)
                }
              </AWSUI.ExpandableSection>
            )
          })}
        </div>
        {
          selectedScenarioError.deviceMappingError !== AppConstants.EMPTY && (
            <div align='left' className='awsui-util-mt-l awsui-util-mb-m awsui-util-ml-l red-color-text'>
              <span>
                <AWSUI.Icon variant='error' name='status-warning'></AWSUI.Icon>
                <span className='awsui-util-ml-xs'>{selectedScenarioError.deviceMappingError}</span>
              </span>
            </div>
          )
        }
      </AWSUI.FormField>
    );
  }

  /**
   * TODO: Move to Utils as this method is shared with MusicSuiteTestType
   * Single unit of each dut list field
   * @param {*} index
   */
  getDUTInformationSectionOptional = (index) => {
    const { selectedScenario, otherParams,
      whitelistedCustomerIds, dutsInUse } = this.props.params;

    let dutKey = 'DUT_' + index;
    let dutMapping = otherParams.deviceMapping;
    let dut = dutMapping.hasOwnProperty(dutKey) ? dutMapping[dutKey] : {};
    let customerId = AppConstants.EMPTY;
    let dsn = AppConstants.EMPTY;
    let amazonId = AppConstants.EMPTY;
    let buildInfo = AppConstants.EMPTY;
    let dutList = selectedScenario.additionalDutList[index - 1] != null ? selectedScenario.additionalDutList[index - 1] : [];
    if (dut !== undefined && Object.keys(dut).length > 0) {
      customerId = dut.hasOwnProperty('customerId') ? dut['customerId'] : AppConstants.EMPTY;
      dsn = dut.hasOwnProperty('dsn') ? dut['dsn'] : AppConstants.EMPTY;
      amazonId = dut.hasOwnProperty('deviceType') ? dut['deviceType'] : getAmazonIdForDut(dutList, dsn);
      buildInfo = dut.hasOwnProperty('buildInfo') ? dut['buildInfo'] : AppConstants.EMPTY;
    }
    return (
      <div>
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {this.getSelectFieldOptional('customerId', 'Customer ID', 'Customer ID of the DUT registered account',
              whitelistedCustomerIds,
              customerId, index)}
            {this.getInputFieldOptional('buildInfo', AppConstants.FIRMWARE, 'Provide firmware information for the DUT',
              buildInfo, index, dutList)}
            {customerId !== AppConstants.EMPTY &&
              !this.state.additionalDutListLoading[index - 1] &&
              !this.state.addAdditionalDUTManually[index - 1] && (
                <AWSUI.ColumnLayout columns={2} borders='none'>
                  <div>
                    {this.getSelectFieldOptional('dsn', 'Device Serial Number', 'Device Serial Number of the DUT',
                      dutList,
                      dsn, index, dutList, dutsInUse)}
                    <AWSUI.Button variant='link' icon='refresh' text={REFRESH_DSN_LABEL}
                      onClick={
                        () => this.getDUTsListForced(index)
                      }
                    >
                    </AWSUI.Button>
                    {dutList.length > 0 && (
                      <AWSUI.Tooltip className='awsui-util-status-info'
                        text={AppConstants.DUT_TOOLTIP_MESSAGE} size='small' position='top'>
                        <AWSUI.Icon name='status-info'></AWSUI.Icon>
                      </AWSUI.Tooltip>
                    )
                    }
                    {dutList.length === 0 && (
                      <AWSUI.Button className='awsui-util-ml-s' variant='link' icon='add-plus' text={ADD_DSN_LABEL}
                        onClick={event => {
                          this.addOptionalDUTManually(index)
                        }}>
                      </AWSUI.Button>)
                    }
                  </div>
                </AWSUI.ColumnLayout>)
            }
            {customerId !== AppConstants.EMPTY &&
              this.state.additionalDutListLoading[index - 1] &&
              !this.state.addAdditionalDUTManually[index - 1] && (
                <AWSUI.ColumnLayout columns={2} borders='none'>
                  <div>
                    {getDUTsLoadingMessage(AppConstants.LOADING_DUTS)}
                  </div>
                </AWSUI.ColumnLayout>
              )
            }
            {
              customerId !== AppConstants.EMPTY &&
              this.state.addAdditionalDUTManually[index - 1] && (
                this.getInputFieldOptional('dsn', 'Device Serial Number', 'Device Serial Number of the DUT', dsn, index)
              )
            }
            {
              customerId !== AppConstants.EMPTY &&
              (dsn !== AppConstants.EMPTY ||
                this.state.addAdditionalDUTManually[index - 1]) && (
                this.getInputFieldOptional('deviceType', 'Amazon ID (Optional)', 'Amazon ID of the DUT', amazonId, index)
              )
            }
          </div>
        </AWSUI.ColumnLayout>
        {
          this.getResetDUTButton(index)
        }
      </div>
    );
  }

  /**
   * TODO: rename function, put more comments
   * TODO: Move to Utils as this method is shared with MusicSuiteTestType
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   * @param {*} index
   * @param {*} dutList: only apply for dsn select
   * @param {*} dutsInUse: only apply for dsn select
   */
  getSelectFieldOptional = (id, label, hintText, options, selected, index, dutList, dutsInUse = null) => {
    let isError = false;
    if (id === 'dsn') {
      isError = dutList && dutList.length > 0 ? false : true;
      options = refreshDUTList(options, index, dutsInUse);
      logToConsole('AQTLog - DUTList for device ' + index + ' = ' + JSON.stringify(options));
    }
    let shouldDisable = shouldDisableDropdown(id, isError, dutList, this.state.additionalDutListLoading[index - 1]);
    let errorText = getErrorTextForDropdown(id, isError, dutList);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelectOptional, index);
  }

  /**
   * TODO: verify whether deviceConfig is needed for FAR Custom
   * Gets input field for optional DUTs
   * @param fieldId id of the current field. - 'dsn'
   * @param fieldLabel This will be either of dsn, amazonId and buildInfo
   * @param hintText - Eg: "Device Serial Number of the DUT"
   * @param value - such as Actual value of dsn
   * @param index - optional dut index value.
   * @return Returns input field for dsn, amazonId or buildInfo depending on
   * parameters passed to the method
   */
  getInputFieldOptional = (fieldId, fieldLabel, hintText, value, index) => {
    let isError = validateInputOptional(fieldId, value);
    let disabled = false;
    if (fieldId === 'deviceTypeId') {
      disabled = !this.state.addAdditionalDUTManually[index - 1];
    }
    let errorText = getErrorText(fieldId, isError, value);

    return getInputFieldOptional(fieldId, fieldLabel, hintText, 600, value, disabled, errorText, this.onChangeInputOptional,
      index);
  }

  /**
   * Handle select logic for FAR Custom
   * TODO: Move to Utils as this method is shared with MusicSuiteTestType
   * @param {*} id
   * @param {*} event
   * @param {*} index
   */
  onChangeSelectOptional = (id, event, index) => {
    const { selectedScenario, selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      dutsInUse, updateNewRunStateParameterCallBack } = this.props.params;
    const identifier = 'FARCustomSubpage, onChangeSelectOptional';
    let selected = event.detail.selectedOption.id;
    let updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, id, index, selected);
    let updatedDutsInUse = dutsInUse;
    if (id === 'dsn') {
      let dutList = selectedScenario.additionalDutList[index - 1];
      let amazonId = getAmazonIdForDut(dutList, selected);
      let deviceName = getDeviceNameForDut(dutList, selected);
      updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'deviceType', index, amazonId);
      updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, 'deviceName', index, deviceName);
      updatedDutsInUse[index] = selected;
    }
    if (id === 'customerId') {
      updatedDutsInUse[index] = null;
      this.loadOptionalDUTs(selected, index, selectedScenario);
    }

    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    sendRawInfoToNewRunWithStateId(identifier, updatedDutsInUse, 'dutsInUse', updateNewRunStateParameterCallBack);

    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * Handle input value logic for FAR Custom
   * @param {*} id
   * @param {*} event
   * @param {*} index
   */
  onChangeInputOptional = (id, event, index) => {
    const { selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack } = this.props.params;
    const identifier = 'FARCustomSubpage, onChangeInputOptional';
    let inputValue = event.detail.value;
    let updatedDeviceMapping = generateDeviceMapping(otherParams.deviceMapping, id, index, inputValue);
    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * Loads DSNs for selected customer ID for optioanl DUTs for FAR Custom test
   * @param customerId Customer ID selected in customer ID dropdown for optional DUT
   * @param index Index of the optional DUT e.g. for Nth device, it would be N
   * @param selectedScenario  get additionalDutList information
   */
  loadOptionalDUTs = (customerId, index, selectedScenario) => {
    this.getOptionalDUTsList(customerId, false, index);
    // Retry fetching DSNs with forceRefresh=true in case first attempt gives empty list
    // suspect logic in old UI was wrong
    let dutList = selectedScenario.additionalDutList[index - 1];
    if (!dutList || dutList.length === 0) {
      this.getOptionalDUTsList(customerId, true, index);
    }
  }

  /**
   * Retrieves DUTs for Customer ID for optional devices for FAR Custom test.
   * @param customerId Customer ID for which DUTs needs to be retrieved
   * @param forceRefresh Whether to fetch DUTs from DMS or DB
   * @param index Index of the optional DUT
   * @returns List of DUTs for customer ID
   */
  getOptionalDUTsList = (customerId, forceRefresh, index) => {
    const { selectedScenario, selectedScenarioCallBack } = this.props.params;
    const identifier = 'FARCustomSubpage, getOptionalDUTsList';
    let additionalDutListLoading = this.state.additionalDutListLoading;
    additionalDutListLoading[index - 1] = true;
    this.setState({
      ...this.state,
      additionalDutListLoading: additionalDutListLoading
    })
    return Promise.resolve(getDUTs(customerId, forceRefresh).then(dutResponse => {
      let dutListIn = generateDUTListWithOnlineOfflineStatus(dutResponse);
      let additionalDutList = selectedScenario.additionalDutList;
      additionalDutList[index - 1] = dutListIn;
      additionalDutListLoading[index - 1] = false;
      this.setState({
        ...this.state,
        additionalDutListLoading: additionalDutListLoading
      })
      let updatedSelectedScenario = selectedScenario;
      updatedSelectedScenario.additionalDutList = additionalDutList;
      sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenario, selectedScenarioCallBack);
      return additionalDutList;
    }));
  }

  /**
   * Method to add Optional DUT manually when DUT list is empty - applicable to
   * FAR Custom scenrio
   */
  addOptionalDUTManually = (index) => {
    let addAdditionalDUTManually = this.state.addAdditionalDUTManually;
    addAdditionalDUTManually[index - 1] = true;
    this.setState({
      ...this.state,
      addAdditionalDUTManually: addAdditionalDUTManually
    });
  }

  /**
  * Gets Reset button which clears selection of Optional DUT for FAR Custom scenario when clicked
  */
  getResetDUTButton = (index) => {
    return (
      <div align='center' className='awsui-util-mt-xl'>
        <AWSUI.Button variant='normal' icon='undo' text={'Reset DUT'}
          onClick={event => {
            this.resetOptionalDevice(index)
          }}>
        </AWSUI.Button>
      </div>
    );
  }

  /**
   * Method to reset all fields (CID, DSN, Amazon ID and Build Info) of optional DUT for FAR Custom test
   * Such device is excluded from selection when user hits "Submit" button
   * @param index Index of the DUT to be reset
   */
  resetOptionalDevice = (index) => {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      dutsInUse, updateNewRunStateParameterCallBack } = this.props.params;
    const identifier = 'FARCustomSubpage, resetOptionalDevice';
    let dutKey = 'DUT_' + index;
    let updatedDeviceMapping = deleteDUTFromDevicesMap(otherParams.deviceMapping, dutKey)
    let additionalDutList = selectedScenario.additionalDutList;
    let additionalDutListLoading = this.state.additionalDutListLoading;
    let updatedDutsInUse = dutsInUse;
    additionalDutList[index - 1] = [];
    additionalDutListLoading[index - 1] = false;
    updatedDutsInUse[index] = null;

    // update newRun page global state parameters
    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    sendRawInfoToNewRunWithStateId(identifier, updatedDutsInUse, 'dutsInUse', updateNewRunStateParameterCallBack);
    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
    let updatedSelectedScenario = selectedScenario;
    updatedSelectedScenario.additionalDutList = additionalDutList;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenario, selectedScenarioCallBack);

    // update local parameters
    this.setState({
      ...this.state,
      additionalDutListLoading: additionalDutListLoading,
    });
  }

  /**
   * refresh dsn
   * need to reset current deviceMap(remove selected one), update dutsInUse, update local state statusf
   * @param {*} index
   */
  getDUTsListForced = (index) => {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
      otherParams, updateOtherParamsCallBack,
      dutsInUse, updateNewRunStateParameterCallBack } = this.props.params;

    const identifier = 'FARCustomSubpage, getDUTsListForced with index: ' + index;

    let dutKey = 'DUT_' + index;
    let customerId = getValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'customerId');
    // reset device related field value
    let updatedDeviceMapping = deleteValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'dsn');
    updatedDeviceMapping = deleteValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'buildInfo');
    updatedDeviceMapping = deleteValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'amazonId');
    updatedDeviceMapping = deleteValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'deviceName');
    updatedDeviceMapping = deleteValueFromDevicesMap(otherParams.deviceMapping, dutKey, 'deviceType');
    let additionalDutList = selectedScenario.additionalDutList;
    let additionalDutListLoading = this.state.additionalDutListLoading;
    let addAdditionalDUTManually = this.state.addAdditionalDUTManually;
    let updatedDutsInUse = dutsInUse;
    additionalDutList[index - 1] = [];
    addAdditionalDUTManually[index - 1] = false;
    additionalDutListLoading[index - 1] = false;
    updatedDutsInUse[index] = null;

    // update newRun page global state parameters
    let updatedOtherParams = otherParams;
    updatedOtherParams.deviceMapping = updatedDeviceMapping;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
    sendRawInfoToNewRunWithStateId(identifier, updatedDutsInUse, 'dutsInUse', updateNewRunStateParameterCallBack);
    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
    let updatedSelectedScenario = selectedScenario;
    updatedSelectedScenario.additionalDutList = additionalDutList;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenario, selectedScenarioCallBack);

    // update local parameters
    this.setState({
      ...this.state,
      additionalDutListLoading: additionalDutListLoading,
      addAdditionalDUTManually: addAdditionalDUTManually
    });

    return this.getOptionalDUTsList(customerId, true, index);
  }

  /**
   * When Primary DSN is not selected, display this message for reminder.
   */
  getAddPrimaryDeviceMessage = () => {
    return (
      <span>
        <b>
          {AppConstants.ADD_PRIMARY_DSN_REMINDER}
        </b>
      </span>);
  }

  render() {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack, primaryDSNSelected } = this.props.params;
    return (
      <div>
        <div className='awsui-util-mt-m'>
          {primaryDSNSelected &&
            this.getOptionalDevices(selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack)}
        </div>
        <div align="center"
          className="awsui-util-mt-xl awsui-util-mb-xl ref-mic-consent-message">
          {!primaryDSNSelected &&
            this.getAddPrimaryDeviceMessage()}
        </div>
      </div>
    );
  }
}

export default FARCustomSubpage
