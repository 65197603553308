export const AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS = [{
    id: 'utterancePlayedTranscript',
    header: () => 'Utterance',
    cell: item => item.utterancePlayedTranscript,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dutResponse',
    header: () => 'Response (Reference Mic Recording)',
    cell: item => item.dutResponse,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'status',
    header: () => 'Utterance Played',
    cell: item => item.status,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'transcribeStatus',
    header: () => 'Transcribe Status',
    cell: item => item.transcribeStatus,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }];

  export const AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS_ALEXA = [{
    id: 'utterancePlayedTranscript',
    header: () => 'Utterance',
    cell: item => item.utterancePlayedTranscript,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dutResponse',
    header: () => 'Cloud Response',
    cell: item => item.dutResponse,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'status',
    header: () => 'Utterance Played',
    cell: item => item.status,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'utteranceStatus',
    header: () => 'DUT Response Status',
    cell: item => item.utteranceStatus,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }];