import React from 'react';
import { connect } from 'react-redux';
import AWSUI from '@amzn/awsui-components-react';
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import { aqtStore } from '../../Components/State/Store';
import { setSession } from '../../Components/State/Actions';
import { isEmpty } from 'lodash';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';
import authActions from 'redux/actions/auth';

const styles = {
  autoMargin: {
    margin: 'auto'
  },
  headColors: {
    color: '#eee',
    backgroundColor: '#232f3e'
  }
}

class HeadBar extends React.Component {

  state = {
    signedIn: false
  }

  // TODO: Remoe this after App refactor
  componentDidMount() {
    this.unsubscribe = aqtStore.subscribe(this.aqtStateHandler);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  aqtStateHandler = () => {
    // TODO: Temporary sign out till this file mess is refactored
    const userSessionObj = aqtStore.getState().session;
    if (!isEmpty(userSessionObj) && !isEmpty(userSessionObj.accessToken)) {
      if (!isEmpty(userSessionObj.accessToken.payload)) {
        this.userName = userSessionObj.accessToken.payload.username;
      } else {
        this.userName = userSessionObj.username;
      }
      this.setState({ signedIn: true });
    }
  }

  /**
   * Logout compoenent for the head bar
   */
  getLogoutComponent = (userName=AppConstants.EMPTY) => {
    return (
      <div className='awsui-util-action-stripe-group awsui-util-m-n'>
        <div style={ styles.autoMargin } >
          <AWSUI.Badge className='awsui-util-m-s'
                       content={ userName } />
          <span onMouseOver={ e => { e.currentTarget.style.color = "#eb5f07" } }
                onMouseOut={ e => { e.currentTarget.style.color = "#eee" } }
                onClick={ () => { this.handleSignOut() } }>
            <AWSUI.Icon className='awsui-util-mr-xs' name='unlocked' />
              <span className='awsui-util-mr-l'>
                Logout
              </span>
          </span>
        </div>
      </div>
    );
  }

  /**
   * Function to trigger sign out is moved here from LogoutBar
   */
  handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        aqtStore.dispatch(setSession({}));
        this.props.setSession({});
        this.setState({ signedIn: false });
       })
      .catch(error => {
        logToConsole('Error signing out : ' + JSON.stringify(error));
      });
  };

  render() {
    return (
      <div style={ styles.headColors } className='awsui-util-container-header'>
        <div className='awsui-util-action-stripe awsui-util-m-n'>
          <Link to='/'>
            <div className='awsui-util-action-stripe-title'>
              <img className='awsui-util-ml-s awsui-util-mt-xs alexa-logo-img-style'
                  src='Resources/images/alexa_logo_transparent.png' alt=''/>
              <span>
                <h3
                  className='awsui-util-ml-s alexa-headbar-text-style'
                  style={{ color: '#eeeeee'}}
                >
                  Alexa Qualification Tool
                </h3>
              </span>
            </div>
          </Link>
          { this.state.signedIn && (this.getLogoutComponent(this.userName))}
        </div>
      </div>
    );
  }
}

export default connect(s => s, {
  setSession: authActions.setSession
})(HeadBar);
