import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { Redirect } from "react-router-dom";
import { runExperiment } from './controller';
import { validateTestRunPayload } from './validators';
import { isEmpty } from 'lodash';
import { stateStore } from '../../Components/State/Store';
import { deleteState } from '../../Components/State/Actions';
import {
  generateCustomOptions, sortCustomOptions, appendOldCustomOptions
} from './CustomOptionsGeneratorUtil';
import CustomOptionsConstants from '../../_Constants/CustomOptionsConstants';
import MobileConstants from "../../_Constants/MobileConstants";
import { saveExecutionState, constructTestParamsFromSelectedParameters
} from './Util';
import { logToConsole } from '../../Util';
import {
  generateVariantJsonFromTestParamsArray
} from './MappingGeneratorUtil';
import { sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier,
  sendRawInfoToNewRunWithStateId } from './NewRunDataTransferUtils';
import AppConstants from '../../_Constants/AppConstants';
import FARConstants from '../../_Constants/FARConstants';
import NewRunStateSaveConstants from './NewRunStateSaveConstants';
import { onAddTestClickValidate } from './ValidateStateBeforeAddTest';
import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';

const Add_Test_Preview_LABEL = 'Add Test Preview';
const Run_Test_Group_LABEL = 'Run Test Group';
const IDENTIFIER = 'TestRunButtons';
class TestRunButtons extends React.Component {
  state = {
    redirect: false
  };

  isJobLimitExceeded = () => {
    const { testParamsArray } = this.props.params;
    return testParamsArray && testParamsArray.length >= AppConstants.JOB_SEQUENCE_LIMIT
  }

  //submitTestOptions original
  // submit current selected scenario to add in jobList
  addTestPreview = async () => {
    const { selectedTestGroupInfo,
            selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack,
            selectedScenario, selectedScenarioCallBack,
            selectedScenarioError, scenarioErrorCallBack,
            otherParams, updateOtherParamsCallBack,
            pageDisplayParams, updatePageDisplayParamsCallBack,
            testParams, updateTestParamsCallBack, updateNewRunStateParameterCallBack,
            otherParamsError} = this.props.params;
    if (selectedScenario.scenarioType !== AppConstants.WAKEWORD) {
      this.setCustomOptionsBeforeSubmit(selectedScenario, selectedScenarioCallBack)}
    if (selectedScenario.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      this.setFunctionalCustomOptionsBeforeSubmit(selectedScenario, selectedScenarioCallBack);
    }
    if (selectedScenario.scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
      this.setMobileFunctionalCustomOptionsBeforeSubmit(selectedScenario, selectedScenarioCallBack);
    }
    if (selectedScenario.scenarioType === AppConstants.WAKEWORD) {
      this.setWakewordFunctionalCustomOptionsBeforeSubmit(selectedScenario, selectedScenarioCallBack)
    }
    const { isValid, params } = await onAddTestClickValidate(
      selectedTestGroupInfo,
      selectedTestGroupInfoError,
      selectedTestGroupInfoErrorCallBack,
      selectedScenario,
      selectedScenarioCallBack,
      selectedScenarioError,
      scenarioErrorCallBack,
      otherParams,
      updateOtherParamsCallBack,
      otherParamsError,
      updateNewRunStateParameterCallBack
    );
    if (!isValid) return;
    // construct testParams here
    logToConsole("constructTestParamsFromSelectedParameters conducting");
    // only update synced values here
    /**
     * Use updated scenario values to construct testParams since we are modifying it in
     * onAddTestClickValidate method.
     */
    constructTestParamsFromSelectedParameters(selectedTestGroupInfo, params.selectedScenario,
      testParams, updateTestParamsCallBack, otherParams);

    let updatedPageDisplayParams = pageDisplayParams;
    updatedPageDisplayParams.runTest = false;
    updatedPageDisplayParams.testOptionsDisplay = true;
    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + 'addTest', updatedPageDisplayParams, updatePageDisplayParamsCallBack);
    // originally was saving TESTPARAMS
    saveExecutionState(NewRunStateSaveConstants.SELECTED_TEST_GROUP_INFO, selectedTestGroupInfo);
    saveExecutionState(NewRunStateSaveConstants.SELECTED_SCENARIO, selectedScenario);
    saveExecutionState(NewRunStateSaveConstants.OTHER_PARAMS, otherParams);
    saveExecutionState(NewRunStateSaveConstants.PAGE_DISPLAY_PARAMS, pageDisplayParams);
    saveExecutionState(NewRunStateSaveConstants.TEST_PARAMS, testParams);
  }

  /**
   * Method to set custom options before hitting 'Submit' button
   */
  setCustomOptionsBeforeSubmit = (selectedScenario, selectedScenarioCallBack) => {
    let customOptionsToSubmit = {};
    if (selectedScenario.testType !== AppConstants.CUSTOMIZED &&
        selectedScenario.testSuite !== AppConstants.AUTOMOTIVE_SCENARIO_ID) {
      // non customized case
      if (AppConstants.SCENARIO_WITH_TEST_TYPES.includes(selectedScenario.scenarioType)) {
        customOptionsToSubmit = generateCustomOptions(selectedScenario.testSuite, selectedScenario.scenarioType,
          selectedScenario.testType, AppConstants.TRAINED_MOBILE_USERS);
      }
    } else {
      // Sort custom options before submitting so that we maintain order for noise types
      // while scenario is being played
      // For Automotive Scenario, both Standard and Demo are customized test types so use testType as customized testtype.
      let customizedTestType = selectedScenario.customizedTestType;
      customOptionsToSubmit = sortCustomOptions(selectedScenario.testOptions.customOptions,
        selectedScenario.scenarioType, customizedTestType);
      // Append old-styled custom options to maintain backward compatibility
      customOptionsToSubmit = appendOldCustomOptions(customOptionsToSubmit);
      if (selectedScenario.scenarioType === AppConstants.TRAINED_MOBILE) {
        let trainedUsers = selectedScenario.trainedUsers;
        // Sort the list of trained users before submitting the test
        if (trainedUsers) {
          trainedUsers.sort();
        }
        customOptionsToSubmit[CustomOptionsConstants.MOBILE_TRAINERS_KEY] = trainedUsers;
      }
      if (selectedScenario.scenarioType === AppConstants.VOICE_ROBUSTNESS) {
        let trainedUsers = selectedScenario.trainedUsers;
        let noiseTypes = selectedScenario.noiseSelection;
        // Sort the list of trained users before submitting the test
        if (trainedUsers) {
          trainedUsers.sort();
        }
        customOptionsToSubmit[CustomOptionsConstants.MOBILE_TRAINERS_KEY] = trainedUsers;
        customOptionsToSubmit[CustomOptionsConstants.MOBILE_NOISES_KEY] = noiseTypes;
      }
    }
    // update the state to new run page
    let updatedScenario = selectedScenario;
    updatedScenario.testOptions.customOptions = customOptionsToSubmit;

    // Add the features field to testoptions for FAR+ OAK run
    if (selectedScenario.scenarioType === AppConstants.FAR_PLUS) {
      let marketplace = selectedScenario.marketPlace;
      let featuresToSubmit = new Array(FARConstants.FAR_MARKETPLACE_FEATURE_MAPPING[marketplace].feature);
      updatedScenario.testOptions.features = featuresToSubmit;
      updatedScenario.testOptions.labDependenciesUri = AppConstants.FAR_PLUS_LAB_DEPENDENCIES_URL;
    }
    sendRawInfoToNewRun(updatedScenario, selectedScenarioCallBack);
  }

  /**
   * Method to set custom options for Functional scenario before hitting 'Submit' button
   */
  setFunctionalCustomOptionsBeforeSubmit = (selectedScenario, selectedScenarioCallBack) => {
    const identifier = 'TestRun, setFunctionalCustomOptionsBeforeSubmit';
    let customOptionsToSubmit = {};
    if (selectedScenario.scenarioType === FunctionalTestCases.FUNC_CUSTOM
      || selectedScenario.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM
      || selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM) {
      customOptionsToSubmit['functionalTestCases'] = selectedScenario.functionalTestCases;
    } else if(selectedScenario.scenarioType === FunctionalTestCases.AUTO_FUNC_ALL){
      customOptionsToSubmit['functionalTestCases'] = FunctionalTestCases.FUNCTIONAL_AUTOMOTIVE_TEST_CASES_STANDARD;
    } else if(selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_ALL){
      customOptionsToSubmit['functionalTestCases'] = FunctionalTestCases.FUNCTIONAL_NAV_TEST_CASES_STANDARD;
    } else {
      customOptionsToSubmit['functionalTestCases'] = FunctionalTestCases.FUNCTIONAL_TEST_CASES_STANDARD;
    }
    let updatedScenario = selectedScenario;
    updatedScenario.testOptions.customOptions = customOptionsToSubmit;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedScenario, selectedScenarioCallBack);
  }
  /**
   * Method to set custom options for Mobile Functional scenario before hitting 'Submit' button
   */
  setMobileFunctionalCustomOptionsBeforeSubmit = (selectedScenario, selectedScenarioCallBack) => {
    const identifier = 'TestRun, setMobileFunctionalCustomOptionsBeforeSubmit';
    let customOptionsToSubmit = {};
    // If test type is an option and Standard test type is chosen:
    // - Add all test cases in the test category
    // Else:
    // - Add test cases that were selected by user
    if (selectedScenario.testType && selectedScenario.testType === AppConstants.STANDARD) {
      if (selectedScenario.testCategory === AppConstants.ALEXA_HANDS_FREE_VERIFICATION) {
        customOptionsToSubmit['functionalTestCases'] = MobileConstants.ALEXA_HF_VERIFICATION_STANDARD_TEST_CASES;
      } else if (selectedScenario.testCategory === AppConstants.APP_VERIFICATION_STANDARD_TEST_CASES) {
        customOptionsToSubmit['functionalTestCases'] = MobileConstants.APP_VERIFICATION_STANDARD_TEST_CASES;
      }
    } else{
      customOptionsToSubmit['functionalTestCases'] = selectedScenario.functionalTestCases;
    }
    customOptionsToSubmit['lockType'] = selectedScenario.lockType;
    let updatedScenario = selectedScenario;
    updatedScenario.testOptions.customOptions = customOptionsToSubmit;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedScenario, selectedScenarioCallBack);
  }

  /**
   * Method to set custom options for Wakeword scenario before hitting the submit button
   */
  setWakewordFunctionalCustomOptionsBeforeSubmit(selectedScenario, selectedScenarioCallBack) {
    const identifier = 'TestRun, setWakewordFunctionalCustomOptionsBeforeSubmit'
    let customOptionsToSubmit = {}
    let featuresToSubmit = {}
    // if Standard test type is chosen
    // - add all the test cases in test Category
    // else:
    // - add selected test cases by user
    if (selectedScenario.testType && selectedScenario.testType === AppConstants.STANDARD) {
      customOptionsToSubmit['functionalTestCases'] = FunctionalTestCases.WAKEWORD_TEST_CASES.map(
        item => {
          const match = FunctionalTestCases.WAKEWORD_TEST_CASES_MAPPING.find(obj => obj.id === item) ;
          return match ? match.label : null;
        });
      // wakeword scenario utilizes features from OAK
      featuresToSubmit =  FunctionalTestCases.WAKEWORD_TEST_CASES.map(
        item => {
          const match = FunctionalTestCases.WAKEWORD_TEST_CASES_MAPPING.find(obj => obj.id === item);
          // wakeword scenario for multi-locale is suffixed with selected locale (e.g. wwe1-en_US)
          return match ? match.features + "-" + selectedScenario.marketPlace : null;
        });
    } else {
      customOptionsToSubmit['functionalTestCases'] = selectedScenario.functionalTestCases;
      // wakeword scenario utilizes features from OAK
      featuresToSubmit = selectedScenario.functionalTestCases.map(
        item => {
          const match = FunctionalTestCases.WAKEWORD_TEST_CASES_MAPPING.find(obj => obj.label === item);
          // wakeword scenario for multi-locale is suffixed with selected locale (e.g. wwe1-en_US)
          return match ? match.features + "-" + selectedScenario.marketPlace : null;
        }
      )

    }
    let updatedScenario = selectedScenario;
    updatedScenario.testOptions.customOptions = customOptionsToSubmit;
    updatedScenario.testOptions.features = featuresToSubmit;
    updatedScenario.testOptions.labDependenciesUri = AppConstants.WAKEWORD_LAB_DEPENDENCIES_URL;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedScenario, selectedScenarioCallBack);
  }

  removeStateParams = (labId) => {
    stateStore.dispatch(deleteState(labId));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.TEST_PARAMS));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.TEST_PARAMS_ARRAY));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.SELECTED_TEST_GROUP_INFO));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.SELECTED_SCENARIO));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.OTHER_PARAMS));
    stateStore.dispatch(deleteState(NewRunStateSaveConstants.PAGE_DISPLAY_PARAMS));
  }

  // TODO: may need to save state for testSubmitArray as well.
  // run group of tests
  runTestGroup = () => {
    // testSubmitParams needs to be constructed from here
    const { testParamsArray, testParams, pageDisplayParams, updatePageDisplayParamsCallBack,
    confirm, updateNewRunStateParameterCallBack} = this.props.params;
    logToConsole(JSON.stringify(testParams));
    // releaseNotesVersion will be used in all below tests
    let additionalParams = {};
    additionalParams.releaseNotesVersion = this.props.releaseNotesVersionCallBack();

    //Prepare for things that is going to be passed to runExperiment
    let testSubmitParams = generateVariantJsonFromTestParamsArray(testParamsArray);
    let updatedPageDisplayParams = pageDisplayParams;
    updatedPageDisplayParams.runTestGroup = false;
    sendRawInfoToNewRunWithIdentifier('TestRun runTestGroup', updatedPageDisplayParams, updatePageDisplayParamsCallBack);

    runExperiment(testSubmitParams, additionalParams).then(response => {
      // eslint-disable-next-line no-prototype-builtins
      if (response && !response.hasOwnProperty('error')) {
        //Removing the Smoke progress/Callback. Need to test in different synchronization conditions
        this.removeStateParams(testParams.labId);
        this.setState({
          redirect: true
        });
      } else {
        // if submit error can still click 'run test group' button
        let updatedPageDisplayParams = pageDisplayParams;
        updatedPageDisplayParams.runTestGroup = true;
        sendRawInfoToNewRunWithIdentifier('TestRun runTestGroup', updatedPageDisplayParams, updatePageDisplayParamsCallBack);
        let updatedConfirm = confirm;
        updatedConfirm = {
          pop: true,
          title: 'Test Submission Failed',
          message: 'Test submission failed with error : ' + response.error,
          callback: () => { }
        };
        sendRawInfoToNewRunWithStateId(IDENTIFIER + ", runTestGroup",
        updatedConfirm, 'confirm', updateNewRunStateParameterCallBack);
      }
    });

  }

  /**
   * runs a series of payload checks to determine
   * if "Run Test" button can be enabled
   *
   * @returns
   */
  shouldDisableRunTest = () => {
    const {testParamsArray} = this.props.params;
    return !validateTestRunPayload(testParamsArray);
  }

  render() {
    const { selectedScenario, pageDisplayParams } = this.props.params;
    const addButtonToolTip = "Click this button to get preview before adding selected test into test queue. Max Limit " + AppConstants.JOB_SEQUENCE_LIMIT
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/liveRuns',
            params: { lazyLoadMillis: 5000 }
          }}
        />
      );
    }

    return (
      <div>
        {!isEmpty(selectedScenario) && (
          <AWSUI.ColumnLayout columns={2} borders="vertical" className='awsui-util-p-l'>
            <div className="awsui-util-pb-l" style={{ textAlign: 'center' }}>
              <AWSUI.Tooltip text={addButtonToolTip} size='small' position='top'
                className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Button variant='primary' text={Add_Test_Preview_LABEL}
                  onClick={async () => {await this.addTestPreview()}} disabled={this.isJobLimitExceeded()}>
                </AWSUI.Button>
              </AWSUI.Tooltip>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AWSUI.Tooltip text='Click this button to execute all tests in test group.' size='small' position='top'
                className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Button variant='primary' text={Run_Test_Group_LABEL} disabled={this.shouldDisableRunTest()}
                  onClick={this.runTestGroup}>
                </AWSUI.Button>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        )}
      </div>

    );
  }
}

TestRunButtons.propTypes = {
  releaseNotesVersionCallBack: PropTypes.func,
  selectedTestGroupInfoCallBack: PropTypes.func,
  selectedScenarioCallBack: PropTypes.func,
  scenarioErrorCallBack: PropTypes.func,
  updateOtherParamsCallBack: PropTypes.func,
  updatePageDisplayParamsCallBack: PropTypes.func,
  updateTestParamsCallBack: PropTypes.func,
};
export default TestRunButtons;
