import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';
import { aqtStore } from '../../Components/State/Store';
import { setCheckedStateInTestParams, resetToEmptyObject } from './uiActionsUtils';
import {
  getErrorText, getAmazonIdToDisplay, getErrorTextUtteranceInput
} from './Util';
import { sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';
import './common-styles.css'
/**
 * This Utilities includes common UI components that is resuable throughout NewRun Page.
 */


/**
   * Gets the informational text when test is paused
   * @param message Text message to be displayed when test is paused
   * @param status Type of message -> Info in this case
   * @returns UI element containing informational message
   */

export function getDUTsLoadingMessage(message, status = 'info') {
  const textStatus = 'status-' + status;
  const messageStyle = 'awsui-util-ml-l awsui-util-p-xs awsui-util-' + textStatus;
  return (
    <div className='awsui-util-mt-xxl'>
      <h5 className={messageStyle}>
        <AWSUI.Icon className='awsui-util-mr-s' name={textStatus} />
        {message}
      </h5>
    </div>
  );
}

/**
 * Reusable select component that used for select
 *
 * @param {} id     field id, eg, 'customerId'
 * @param {*} label
 * @param {*} hintText
 * @param {*} options  options to be selected from
 * @param {*} selected
 * @param {*} shouldDisable sometimes selection box needs to be disabled
 * @param {*} errorText
 * @param {*} changeCallBack call back function to be called once customer selected something
 * @param {*} index optional, only needed for FAR Custom currently
 * @param {*} otherProps optional, send additional props to customize Select component
 */
export function getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, changeCallBack, index = null, otherProps = {}) {
  let isCidOrDsnDropdown = id === 'customerId' || id === 'dsn';
  logToConsole("Common Select ID: " + id);
  return (
    <AWSUI.FormField
      label={label}
      hintText={hintText}
      errorText={errorText}
    >
      <div style={{ maxWidth: 600 }}>
        {
          isCidOrDsnDropdown ? (
            <AWSUI.Select
              options={options}
              selectedId={selected}
              disabled={shouldDisable}
              onChange={event => { changeCallBack(id, event, index) }}
              enableFiltering={true}
              filteringLabel='label'
              placeholder={AppConstants.EMPTY}
              triggerVariant="option"
              { ...otherProps }
            >
            </AWSUI.Select>
          ) : (
              <AWSUI.Select
                options={options}
                selectedId={selected}
                disabled={shouldDisable}
                onChange={event => { changeCallBack(id, event, index) }}
                enableFiltering={true}
                filteringLabel='label'
                placeholder={AppConstants.EMPTY}
                { ...otherProps }
              >
              </AWSUI.Select>
            )
        }
      </div>
    </AWSUI.FormField>
  );
}

/**
 * Email notification checkbox
 * @param {*} params group of state params, eg, selectedTestGroupInfo
 * @param {*} paramsUpdateCallBack callback function to update params, eg selectedTestGroupInfoCallBack
 * @param {*} lockFields Control the checkbox disabled or not, true for disabled, false for not disabled
 */
export function getEmailNotificationField(params, paramsUpdateCallBack, lockFields) {
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
          <AWSUI.Checkbox label={"Enable Email Notification to [" + aqtStore.getState().session.accessToken.payload.username + "]"}
            disabled={lockFields}
            checked={params.sendCompletionEmail}
            onChange={event => { setCheckedStateInTestParams(event, "sendCompletionEmail", params, paramsUpdateCallBack) }} />
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Reusable input field that is taking input from customers, eg, Firwmare, Test Name input
 * @param {*} id  eg testName
 * @param {*} label label of input, eg 'Test Group Name'
 * @param {*} hintText eg 'Provide a name for this test'
 * @param {*} maxWidth
 * @param {*} params params where this input field event need to update, eg, selectedTestGroupInfo
 * @param {*} updateParamsCallBack callback function to update params, eg, selectedTestGroupInfoCallBack
 * @param {*} paramsError errors related to params selection, eg, selectedTestGroupInfoError
 * @param {*} updateParamsErrorCallBack callback function to update paramsError, eg, selectedTestGroupInfoErrorCallBack
 * @param {*} onChangeInputCallBack callback function to handle event for AWSUI Input, onChange operation
 * @param {*} onInputCallBack callback function to handle event for AWSUI Input, onInput operation
 * @param {*} optionalParams additional parameters need to be passed to control InputField, use in case of handling 'deviceTypeId'
 */
export function getInputField(id, label, hintText, maxWidth = 600,
  params, updateParamsCallBack, paramsError, updateParamsErrorCallBack,
  onChangeInputCallBack, onInputCallBack,
  optionalParams = null) {
  let currentInputValue = params[id];
  let errorText = getErrorText(id, paramsError[id], currentInputValue);
  // If it's input field for From & To utterance range, do additional validation to
  // validate range of utterances
  if ((id === 'toTranscriptNum' || id === 'fromTranscriptNum') && !paramsError[id]) {
    errorText = getErrorTextUtteranceInput(currentInputValue);
  }
  const identifier = 'uiUtils getInputField : ' + id;
  return (
    <AWSUI.FormField
      label={label}
      hintText={hintText}
      errorText={errorText}
    >
      <div style={{ maxWidth: maxWidth }}>
        <AWSUI.Input
          value={(id === 'deviceTypeId' && optionalParams !== null && !optionalParams.addDUTManually) ? getAmazonIdToDisplay(params.deviceTypeId)
            : params[id]}
          disabled={id === 'deviceTypeId' && optionalParams !== null && !optionalParams.addDUTManually}
          onChange={event => {
            if (event.detail.value !== AppConstants.EMPTY) {
              onChangeInputCallBack(id, event)
            }
          }}
          onFocus={
            () => {
              /* this.setState(
                {
                  error:
                  {
                    ...this.state.error,
                    [id]: false,
                    //TODO: looks like this is for auto local search, in scenarioSelection part
                    transcriptRangeError: id === 'toTranscriptNum' || id === 'fromTranscriptNum' ?
                      AppConstants.EMPTY :
                      this.state.error.transcriptRangeError
                  }
                }) */
              let updatedParamsError = paramsError;
              updatedParamsError[id] = false;
              sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
            }
          }
          onInput={event => {
            onInputCallBack(id, event);
          }}
          placeholder= {"Enter value for " + id}
        ></AWSUI.Input>
      </div>
    </AWSUI.FormField>
  );
}

/**
 * Reusable input field that is taking input from customers, eg, Firwmare, Test Name input
 * @param {*} id  eg testName
 * @param {*} label label of input, eg 'Test Group Name'
 * @param {*} hintText eg 'Provide a name for this test'
 * @param {*} maxWidth
 * @param {*} value such as Actual value of dsn
 * @param {*} disabled
 * @param {*} errorText
 * @param {*} onChangeInputCallBack callback function to handle event for AWSUI Input, onChange operation
 * @param {*} index optional, only needed for FAR Custom currently
 */
export function getInputFieldOptional(id, label, hintText, maxWidth = 600,
  value, disabled, errorText,
  onChangeInputCallBack,
  index = null) {

  return (
    <AWSUI.FormField
      label={label}
      hintText={hintText}
      errorText={errorText}
    >
      <div style={{ maxWidth: maxWidth }}>
        <AWSUI.Input
          value={value}
          disabled={disabled}
          onChange={event => {
            if (event.detail.value !== AppConstants.EMPTY) {
              onChangeInputCallBack(id, event, index)
            }
          }}
          placeholder= {"Enter value for " + id}
        ></AWSUI.Input>
      </div>
    </AWSUI.FormField>
  );
}

/**
   * Gets consent message about audio recording while functional scenario is running
   */
export function getRefMicConsentMessage() {
  return (
    <span>
      <b>
        {AppConstants.REF_MIC_CONSENT_MESSAGE}
      </b>
    </span>
  )
}

/**
 * Display when NewRun page data is not loaded properly
 * @param {*} loadingFields
 * @param {*} loadErr
 * @param {*} labIdList
 * @param {*} customerIdList
 */
export function getFieldEmptyOrErr(loadingFields, loadErr, labIdList, customerIdList) {
  if (loadingFields) {
    return (<div align='center'><CircularProgress size={50} /></div>)
  } else if (loadErr) {
    return (<AWSUI.Alert
      header='Cannot retrieve some fields at this time'
      content={loadErr}
      type='error'
    ></AWSUI.Alert>)
  } else if (labIdList.length <= 0) {
    getFieldEmpty('labs');
  } else if (customerIdList.length <= 0) {
    getFieldEmpty('whitelisted cids');
  }
}

function getFieldEmpty(field) {
  let headText = 'No ' + field + 'can be retrieved for the account';
  let contentText = 'Please make sur there are ' + field + 'associated with the account.';
  return (<AWSUI.Alert
    header={headText}
    content={contentText}
    type='error'
  ></AWSUI.Alert>)
}

/**
 * Basic ui module for auto sync resource check box
 * @param {*} checkedState - state parameter to indicate whether this box is checked or not
 * @param {*} params - params where this state parameter is located, in this case, selectedScenario
 * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
 * @param {*} disabled
 */
export function getAutoSyncResourcesField(checkedState, params, paramsUpdateCallBack, disabled=false) {
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
          <AWSUI.ColumnLayout columns={2} borders='none'>
            <div data-awsui-column-layout-root='true'>
              <AWSUI.Checkbox className='blue-color-text'
                label={<b>Auto download required resources</b>}
                disabled={disabled}
                checked={checkedState}
                onChange={event => {
                  setCheckedStateInTestParams(event, "isAutoSyncEnabled",
                    params, paramsUpdateCallBack)
                }} />
              <AWSUI.Tooltip text='Check this box to enable auto-configuring raspi with required audio and software plugins to run current test.' size='small' position='top'
                className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Method to display checkbox to enable/disable proxy settings
 * @param {*} checkedState - state parameter to indicate whether this box is checked or not
 * @param {*} params - params where this state parameter is located, in this case, selectedScenario
 * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
 * @param {*} disabled
 */
export function getProxySettings(checkedState, params, paramsUpdateCallBack, disabled=false,
  checkboxLabel='Enable Proxy Settings') {
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
          <AWSUI.ColumnLayout columns={2} borders='none'>
            <div data-awsui-column-layout-root='true'>
              <AWSUI.Checkbox label={checkboxLabel}
                disabled={disabled}
                checked={checkedState}
                onChange={event => {
                  setCheckedStateInTestParams(event, "isProxyEnabled",
                    params, paramsUpdateCallBack)
                }} />
              <AWSUI.Tooltip text='Check this box to enable proxy settings. Refer to AQT documentation for
                      further details' size='small' position='top'
                className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Method to display checkbox to enable/disable 3PDA/Mic-less
 * @param {*} checkedState - state parameter to indicate whether this box is checked or not
 * @param {*} params - params where this state parameter is located, in this case, selectedScenario
 * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
 * @param {*} disabled
 */
export function get3PDASettings(checkedState, params, paramsUpdateCallBack, disabled=false,
                                 checkboxLabel='Enable 3PDA/Mic-less Settings') {
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
            <div data-awsui-column-layout-root='false'>
              <AWSUI.Checkbox label={checkboxLabel}
                              disabled={disabled}
                              checked={checkedState}
                              onChange={event => {
                                setCheckedStateInTestParams(event, "is3PDAEnabled",
                                  params, paramsUpdateCallBack)
                              }} />
              <AWSUI.Tooltip text='Check this box to enable a Mic-less/PCA device.' size='small' position='top'
                             className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Method to Add new check-box in-order to force pre-process speech and noise audio files
 * @param {*} checkedState - state parameter to indicate whether this box is checked or not
 * @param {*} params - params where this state parameter is located, in this case, selectedScenario
 * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
 * @param {*} disabled
 */
export function getUseCustomNoiseFilesOption(checkedState, params, paramsUpdateCallBack, disabled = false) {
  return (
    <div className='f-container'>
      <div className='f-item'>
        <AWSUI.Checkbox label='Use Custom Noises'
                        disabled={disabled}
                        checked={checkedState}
                        onChange={event => {
                          setCheckedStateInTestParams(event, "useCustomNoises",
                            params, paramsUpdateCallBack)
                        }}/>
      </div>
      <div className='f-item'>
        <AWSUI.Tooltip text='Place custom noise files in the specified path in the ubuntu machine' size='small'
                       position='top'
                       className='awsui-util-no-margin tooltip-inner'>
          <AWSUI.Icon name='status-info'></AWSUI.Icon>
        </AWSUI.Tooltip>
      </div>
    </div>
  )
}


/**
 * Option to disable Skill and Enable Happy song (Handle this option in Scenario to switch)
 * @param {*} selectedScenario
 * @param {*} selectedScenarioCallBack
 */
export function getDisablePlaybackSkillOption(selectedScenario, selectedScenarioCallBack) {
  let tooltipText = 'Check this box to disable the AQT device playback skill. Device playback will use Amazon Music instead.'
  return (
    <AWSUI.FormField>
      <div style={{maxWidth: 600}}>
        <div className='awsui-util-container'>
          <AWSUI.ColumnLayout columns={2} variant="default">
            <div data-awsui-column-layout-root='true'>
              <AWSUI.Checkbox
                className='blue-color-text'
                label={<b>Disable AQT skill for DUT playback</b>}
                checked={selectedScenario.disablePlaybackSkill}
                onChange={event => {
                  setCheckedStateInTestParams(event, "disablePlaybackSkill",
                    selectedScenario, selectedScenarioCallBack)
                }}/>
              <AWSUI.Tooltip text={tooltipText}
                             className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Option to disable Skill and Enable Happy song (Handle this option in Scenario to switch)
 * @param {*} selectedScenario
 * @param {*} selectedScenarioCallBack
 */
export function getDisablePlaybackSkill(selectedScenario, selectedScenarioCallBack) {
  let tooltipText = 'Check this box to disable the AQT device playback skill. Device playback will use Amazon Music instead.'
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
          <AWSUI.ColumnLayout columns={2} variant="default">
            <div data-awsui-column-layout-root='true'>
              <AWSUI.Checkbox
                label={'Disable AQT skill for DUT playback'}
                checked={selectedScenario.disablePlaybackSkill}
                onChange={event => {
                  setCheckedStateInTestParams(event, "disablePlaybackSkill",
                    selectedScenario, selectedScenarioCallBack)
                }} />
              <AWSUI.Tooltip text={tooltipText}
                             className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * Method to display checkbox to enable/ disable proxy settings
 * @param {*} checkedState - state parameter to indicate whether this box is checked or not
 * @param {*} params - params where this state parameter is located, in this case, selectedScenario
 * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
 * @param {*} disabled
 */
export function fallBackToUtterancePlay(checkedState, params, paramsUpdateCallBack, disabled=false) {
  return (
    <AWSUI.FormField>
      <div style={{ maxWidth: 600 }}>
        <div className='awsui-util-container'>
          <AWSUI.ColumnLayout columns={3} borders='none'>
            <div data-awsui-column-layout-root='true'>
              <AWSUI.Checkbox label='Fall Back Pi Utterance Play'
                disabled={disabled}
                checked={checkedState}
                onChange={event => {
                  setCheckedStateInTestParams(event, "fallBackToUtterancePlay",
                    params, paramsUpdateCallBack)
                }} />
              <AWSUI.Tooltip text='Check this box to enable fall back to utterance play. Refer to AQT documentation for
                      further details' size='small' position='top'
                className='awsui-util-no-margin tooltip-inner'>
                <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    </AWSUI.FormField>
  )
}

/**
 * UI Module to pop when test submission failed
 * @param {*} confirm
 * @param {*} _mounted
 * @param {*} updateConfirmCallBack
 */
export function getConfirmation(confirm, _mounted, updateConfirmCallBack) {
  return (
    <AWSUI.Modal
      content={confirm.message}
      visible={confirm.pop}
      header={confirm.title}
      expandToFit={true}
      footer={<span className='awsui-util-f-r'>
        <AWSUI.Button variant='link' text='Cancel'
          onClick={() => {
            confirm.callback(false);
            _mounted && resetToEmptyObject('confirm', updateConfirmCallBack);
          }}>
        </AWSUI.Button>
        <AWSUI.Button variant='primary' text='Ok'
          onClick={() => {
            confirm.callback(true);
            _mounted && resetToEmptyObject('confirm', updateConfirmCallBack);
          }}>
        </AWSUI.Button>
      </span>}
    ></AWSUI.Modal>
  )
}

/**
 * Show Modal utility
 * @returns {*}
 */
export function showModal(modalTitle, description) {
  return (
    <AWSUI.Modal
      visible={true}
      closeAriaLabel="Close modal"
      size="medium"
      header= { modalTitle }
    >
      { description }
    </AWSUI.Modal>
  )
}
