import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import ld from 'lodash';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCustomScenarios } from 'redux/actions/customTest';
import {
  getErrorTextForDropdown, getFilteredLocaleOptions, getIdForScenario, getMusicTestTypesBasedOnLocale,
  getFilteredLocationOptions
} from './Util';

import {getAcousticFunctionalScenarios, getFunctionalScenarios, getMobileScenarios, isA4PCDevice} from '../../Util';
import { getCommonSelect } from './uiUtils';
import { sendRawInfoToNewRun } from './NewRunDataTransferUtils';
import CustomScenarioOptions from './SubScenarioSelection';
import { resetCustomOptionsState, resetTestOptions } from './ScenarioSelectionHelpers';
import AppConstants from '../../_Constants/AppConstants';
import AutoLocalSearchTestConstants from '../../_Constants/AutoLocalSearchTestConstants';
import FunctionalTestCases from "../../_Constants/FunctionalTestCasesConstants";
import { getStage } from "../../newSystem/services/utils";
import { STAGE_TYPES } from "../../newSystem/constants";

class ScenarioSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createCustomTest: false
    };
  }

  componentDidMount() {
    // Fetch custom scenarios that are validated and available
    this.props.fetchCustomScenarios({ state: 'AVAILABLE' });
  }

  // TODO: finish the operations currently in comments, add them to SubScenarioSelection page.
  // Add in testType selection as well
  selectScenario = () => {
    const { selectedScenario } = this.props.params;
    let currentSelectedTestSuite = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.testSuite;
    let currentSelectedScenarioType = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.scenarioType;
    let currentSelectedLocale = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.marketPlace;

    return (
      <div>
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root='true'>
            {this.getTestSuite()}
            {
              currentSelectedTestSuite &&
              this.getSelectFieldForScenarioType(currentSelectedTestSuite, currentSelectedScenarioType)
            }
            {currentSelectedScenarioType && this.getLocaleField()}

            {currentSelectedLocale && (selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ||
              selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_ALL) && this.getSpoofedLocationField()}
            {this.getA4PCWakewordModel()}
            {/*
              deal with multiModel shortly
              {
                this.state.testParams.scenarioType === AppConstants.MULTIMODAL_SCENARIO_ID && (
                  this.getInputField('multiModalCustomCommand', 'Custom Command',
                    'Provide a custom command for multimodal scenario')
                )
              } */
            }
          </div>
          <div key={selectedScenario.scenarioType}>
            <CustomScenarioOptions
              testSuite={currentSelectedTestSuite}
              params={this.props.params}
            />
          </div>
        </AWSUI.ColumnLayout>

      </div>
    );
  }

  getTestSuite = () => {
    const { whitelistedScenarioIds, selectedScenario, lockFields } = this.props.params;
    let currentTestSuite = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.testSuite;
    return this.getSelectField('testSuite', 'Test Suite', 'Select the Test Suite to run',
      whitelistedScenarioIds.length > 0 ? whitelistedScenarioIds :
        AppConstants.WHITELISTED_SCENARIOS,
      currentTestSuite ? currentTestSuite : AppConstants.EMPTY, lockFields)
  }

  getSpoofedLocationField = () => {
    const {selectedScenario} = this.props.params;
    // Call getFilteredLocationOptions to display the applicable locations for the selected locale
    let currentSelectedLocale = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.marketPlace;
    let dropDownOptions = getFilteredLocationOptions(selectedScenario, currentSelectedLocale); // Use conditions to
    let currentSelectedLocation = ld.isEmpty(selectedScenario) ? AppConstants.NOT_APPLICABLE : selectedScenario.spoofedLocation;
    return this.getSelectField(AppConstants.LOCATIONS_ID, AppConstants.LOCATIONS_LABEL,
      'Location to be tested', dropDownOptions, currentSelectedLocation);
  }

  getLocaleField = () => {
    const {selectedScenario, selectedTestGroupInfo, lockFields} = this.props.params;
    // Call getFilteredLocaleOptions to display the applicable locale for the selected testSuite/scenarioType
    const companyId = this.props.params.otherParams.companyId;
    let dropDownOptions = getFilteredLocaleOptions(companyId, selectedScenario); // Use conditions to
    let currentSelectedLocale = ld.isEmpty(selectedScenario) ? AppConstants.EMPTY : selectedScenario.marketPlace;
    let disableField = selectedScenario.lockMarketPlace ? selectedScenario.lockMarketPlace : lockFields;
    if (isA4PCDevice(selectedTestGroupInfo.deviceTypeId) &&
      ld.get(selectedTestGroupInfo, 'aqtA4PCCompanionAppEnabled', false)) {
      disableField = false;
    }
    return this.getSelectField(AppConstants.LOCALE_ID, AppConstants.LOCALE_LABEL,
      'Locale to be tested', dropDownOptions, currentSelectedLocale, disableField);
  }

  /**
   * Get wakeword Model for A4PC device category
   */
  getA4PCWakewordModel = () => {
    const { selectedScenario, selectedScenarioCallBack, selectedTestGroupInfo} = this.props.params;
    if (isA4PCDevice(selectedTestGroupInfo.deviceTypeId) &&
        !ld.isEmpty(selectedScenario[AppConstants.LOCALE_ID]) &&
        ld.get(selectedTestGroupInfo, 'aqtA4PCCompanionAppEnabled', false)) {
      return (
        <AWSUI.FormField
          label="Wakeword Mode"
          hintText="Select Wakeword Mode"
        >
          <AWSUI.RadioGroup
            value={selectedScenario.a4pcWakewordModel}
            items={AppConstants.A4PC_WAKEWORD_OPTIONS}
            onChange={({detail}) => {
              selectedScenario.a4pcWakewordModel = detail.value;
              sendRawInfoToNewRun(selectedScenario, selectedScenarioCallBack);
            }}>
          </AWSUI.RadioGroup>
        </AWSUI.FormField>
      );
    }
  }

  /**
   * Method to decide whether to have dropdown for 'Scenario Type' (for Acoustic) or 'MSP/ MSK' (for Music)
   * @return Returns 'Scenario Type' dropdown when test suite is selected
   */
  getSelectFieldForScenarioType = (testSuite, scenarioType) => {
    const {selectedScenario, whitelistedScenarioIds} = this.props.params;
    const selectedField = scenarioType ? scenarioType : AppConstants.EMPTY;
    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
    const hintText = `Select the ${testSuiteInfo.LABEL} to run`;
    // TODO: might need to adjust this again based on release
    const oldUdtTestsDtFilter = new Date(Date.parse("2022-12-31"));
    let scenarioTypes = testSuiteInfo.SCENARIO_TYPE;
    let selectProps = {};
    if (testSuite === AppConstants.ACOUSTIC_SCENARIO_ID) {
      var isFarPlusAllowlisted = false;
      whitelistedScenarioIds.forEach((scenario) => {
        if (scenario && scenario.id === "FAR_PLUS") {
          isFarPlusAllowlisted = true;
        }
      })
      scenarioTypes = getAcousticFunctionalScenarios(isFarPlusAllowlisted)
    } else if (testSuite === AppConstants.TEST_SUITES.MUSIC.ID && selectedScenario.marketPlace) {
      scenarioTypes = getMusicTestTypesBasedOnLocale(selectedScenario.marketPlace)
    } else if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
      scenarioTypes = getFunctionalScenarios();
    } else if (testSuite === AppConstants.MOBILE_SUITE_ID) {
      scenarioTypes = getMobileScenarios();
    } else if (testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) {
      const { loading, scenarios } = this.props.customTest;
      const statusType = 'finished';
      if (loading) {
        selectProps['loadingText'] = 'Loading custom scenarios';
        selectProps['statusType'] = 'pending';
      } else selectProps['statusType'] = statusType;

      scenarioTypes = scenarios
        .map((scenario) => ({
          ...scenario,
          // TODO: Check if we can save config as json in mysqldb
          config: scenario.config ? JSON.parse(scenario.config) : {},
          label: scenario.name,
        }));
    }
    return this.getSelectField('scenarioType', testSuiteInfo.LABEL, hintText, scenarioTypes, selectedField, false, selectProps);
  }

  /**
   * Applies for dropdowns such as Test Suite
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   */
  getSelectField = (id, label, hintText, options, selected, shouldDisable = false, selectProps = {}) => {
    const { selectedScenarioError } = this.props.params;
    let dumpList = []; // dumpList doens't have meanings for now for non dsn selection, check function for details
    let errorText = getErrorTextForDropdown(id, selectedScenarioError[id], dumpList, shouldDisable);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelectScenario, null, selectProps);
  }

  /**
   * Action function when above dropdowns are browsed and selected
   * @param {*} id
   * @param {*} event
   */
  onChangeSelectScenario = (id, event) => {
    const { selectedScenario, selectedScenarioError, selectedScenarioCallBack, scenarioErrorCallBack,
      whitelistedScenarioIds } = this.props.params;
    let testTypesArrIn = [false, false, false, false];
    let testCategoryArrIn = [false, false, false];
    let lockTypesArrIn = [false, false];
    let updatedScenario = selectedScenario;
    updatedScenario.lockMarketPlace = false;
    let updatedScenarioError = selectedScenarioError;
    updatedScenarioError[id] = false;
    // reflect general selection
    // Need to reset some fields
    // when testSuite, scenarioType or testType is selected, testOptions needs to be reset
    if (id === 'testSuite' || id === 'scenarioType' || id === 'testType' || id === 'testCategory' || id === 'lockType') {
      resetCustomOptionsState(updatedScenario, selectedScenarioCallBack,
        selectedScenarioError, scenarioErrorCallBack);
      resetTestOptions(updatedScenario, selectedScenarioCallBack);
      this.props.resetValidationResult();
      updatedScenario.testTypeArr = testTypesArrIn;
      updatedScenario.testCategoryArr = testCategoryArrIn;
      updatedScenario.lockTypeArr = lockTypesArrIn;
      updatedScenario.trainedUsers = [];
      updatedScenario.noiseSelection = [];
      updatedScenario.disableAdminCheck = false;
      updatedScenario.retryEnabled = false;
      //updatedScenario.deviceMapping = [];
      // no deviceMapping, looks like deviceMapping is from FAR custom
      //https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/mainline/--/src/Container/NewRun/MappingGeneratorUtil.js#L83
    }
    if (id === 'testSuite') {
      updatedScenario.testType = AppConstants.EMPTY;
      //updatedScenario.testCategory = AppConstants.EMPTY;
      //updatedScenario.lockType = AppConstants.EMPTY;
      updatedScenario.deviceVirtualAssistant = AppConstants.EMPTY;
      updatedScenario.fromTranscriptNum = AutoLocalSearchTestConstants.ZERO;
      updatedScenario.toTranscriptNum = AutoLocalSearchTestConstants.ZERO;
      updatedScenario.isUtteranceRangeSet = false;
      updatedScenario.scenarioType = AppConstants.EMPTY;
      updatedScenario.marketPlace = AppConstants.EMPTY;
      updatedScenario.is3PDAEnabled = false;
      updatedScenario.isProxyEnabled = false;
      updatedScenario.useCustomNoises = false;
      updatedScenario.disablePlaybackSkill = false;
      updatedScenario.scenarioId = getIdForScenario(event.detail.selectedOption.id, whitelistedScenarioIds);
      updatedScenarioError.transcriptRangeError = AppConstants.EMPTY;
    }

    if (id === 'scenarioType') {
      updatedScenario.testType = AppConstants.EMPTY;
      //updatedScenario.testCategory = AppConstants.EMPTY;
      //updatedScenario.lockType = AppConstants.EMPTY;
      updatedScenario.fromTranscriptNum = AutoLocalSearchTestConstants.ZERO;
      updatedScenario.toTranscriptNum = AutoLocalSearchTestConstants.ZERO;
      updatedScenario.spoofedLocation = AppConstants.NOT_APPLICABLE;
      updatedScenario.isUtteranceRangeSet = false;
      updatedScenario.disablePlaybackSkill = false;
      if (selectedScenario.testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) {
        const selectedOption = event.detail.selectedOption;
        if (selectedOption.id === 'CREATE_NEW_SCENARIO') {
          this.setState({
            createCustomTest: true
          })
        } else {
          updatedScenario.marketPlace = selectedOption.config ? selectedOption.config.marketPlace : AppConstants.EMPTY;
          updatedScenario.lockMarketPlace = true;
          updatedScenario.testType = AppConstants.STANDARD;
          updatedScenario.customTestOptions = {
            ...updatedScenario.customTestOptions,
            ...selectedOption
          };
        }
      }
    }

    updatedScenario[id] = event.detail.selectedOption.id;

    const stage = getStage();
    // Wakeword scenario and FAR+ will be running through OAK, so mapping scenario to AQTUDTV2
    if (id === "scenarioType" && updatedScenario.testSuite ===  AppConstants.ACOUSTIC_SCENARIO_ID) {
      if (updatedScenario.scenarioType === AppConstants.WAKEWORD || updatedScenario.scenarioType === AppConstants.FAR_PLUS) {
        updatedScenario.scenarioId = "70bf486e-b465-4ff1-9a6e-dcaf5d796ff1"; // prod
        if (stage === STAGE_TYPES.ALPHA) {
          updatedScenario.scenarioId = "66fc2b2d-edc0-4b07-912f-39bc9e5e440c"
        } else if (stage === STAGE_TYPES.BETA) {
          updatedScenario.scenarioId = "3a591589-1702-468f-890b-6a461109c7c8";
        }
      }
      else {
        // case when the user selected a OAK type scenarioID but later decided to go back from the
        // preview page and selected other scenarioType, we would need to reset the scenarioId
        updatedScenario.scenarioId = getIdForScenario(updatedScenario.testSuite, whitelistedScenarioIds);
      }
    }
    sendRawInfoToNewRun(updatedScenario, selectedScenarioCallBack);
    sendRawInfoToNewRun(updatedScenarioError, scenarioErrorCallBack);
  }

  render() {
    const { createCustomTest = false } = this.state;
    if (createCustomTest) {
      return (
        <Redirect
          push
          to={{
            pathname: '/customTests'
          }}
        />
      )
    }

    return (
      <AWSUI.FormSection header='Scenario Selection'>
        {/* select test suite and scenario type */}
        {this.selectScenario()}
      </AWSUI.FormSection>
    );
  }
}

export default connect(({ customTest }) => ({ customTest }), {
  fetchCustomScenarios
})(ScenarioSelection);
