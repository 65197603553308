/**
 * Component to render progress bar for Custom Test scenario
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import '../LiveFeedProgressBar.css';
import CustomTestConstants from '../../../_Constants/CustomTestConstants';
import { isRasPiResourceSyncUpdateInProgress } from '../../../Util';
import AutoSyncProgressBar from '../AutoSync/AutoSyncProgressBar';

// Progress bar filler component to display progress in green color
const Filler = (props) => {
  let fillerId = props.labJobId ? 'filler_' + props.labJobId : 'filler';
  return ( 
    <div className='filler' id={ fillerId } style={{ width: `${ props.percentage }%`}}/>
  )
}
 
// Progress bar component to display actual progress bar
const ProgressBar = (props) => {
  return (
    <div className='progress-bar'>
      <Filler percentage={ props.percentage }/>
    </div>
  )
}
 
/**
 * Component to display progress bar consisting of following states:
 * 1. Started
 * 2. In progress (with % of progress)
 * 3. Completed
 */
class CustomTestProgressBar extends React.Component {

  /**
   * Decides whether to display 'In progress' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayInProgressState = (testStatus) => {
    return AppConstants.IN_PROGRESS_STATES.includes(testStatus) || 
       AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
    * Decides whether to display 'Completed' state or not
    * @param testStatus Current status of test
    * @return Returns true only if test is completed.
    */
  shouldDisplayCompletedState = (testStatus) => {
    return AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
   * Gets text to display when test is in progress
   * @param testStatus Status of test
   * @param inProgressPercentage Percentage test case has completed
   * @return Text whether test is loading or In progress with percentage
   */
  getInProgressText = (testStatus, inProgressPercentage) => {
    let isTestInProgress = this.shouldDisplayInProgressState(testStatus);
    let isTestCompleted = this.shouldDisplayCompletedState(testStatus);
    if (isTestCompleted) {
      return 'In Progress (100%)';
    }
    if (isTestInProgress) {
      if (!inProgressPercentage || inProgressPercentage === 0) {
        return 'In Progress';
      } else {
        return 'In Progress (' + inProgressPercentage.toFixed(1) + '%)';
      }
    }
    // Return In Progress by default when testStatus does not match anything from controller
    return 'In Progress';
  }

  /**
   * Gets the percentage progress to display in progress bar
   * @param testStatus Current status of test
   * @param inProgressPercentage Percentage calculated base on current utterance/ total utterances (for In progress state)
   * @return Percentage value based on specific state & inProgressPercentage
   */
  getPercentage = (testStatus, inProgressPercentage) => {
    
    if (AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_COMPLETED_WIDTH;
    } else if (AppConstants.IN_PROGRESS_STATES.includes(testStatus)
      || inProgressPercentage > 0) {
      return inProgressPercentage;
    } else {
      return AppConstants.PROGRESS_BAR_STARTED_WIDTH;
    }

  }

  /**
   * Method to get display friendly text for reference mic status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
   */
  getRefMicStatusText = (refMicStatus) => {
    if (refMicStatus !== undefined && CustomTestConstants.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return CustomTestConstants.REF_MIC_STATUS[refMicStatus].displayText;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      CustomTestConstants.REF_MIC_STATUS[CustomTestConstants.REF_MIC_STATUS_UNAVAILABLE].displayText:
      CustomTestConstants.REF_MIC_STATUS[CustomTestConstants.REF_MIC_STATUS_OFFLINE].displayText;
  }
 
  /**
   * Method to get color coding & style for ref mic based on its status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Color coding & style for reference mic text based on its status
   */
  getRefMicDisplayStyle = (refMicStatus) => {
    if (refMicStatus !== undefined && CustomTestConstants.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return CustomTestConstants.REF_MIC_STATUS[refMicStatus].displayStyle;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      CustomTestConstants.REF_MIC_STATUS[CustomTestConstants.REF_MIC_STATUS_UNAVAILABLE].displayStyle:
      CustomTestConstants.REF_MIC_STATUS[CustomTestConstants.REF_MIC_STATUS_OFFLINE].displayStyle;
  }

  /**
   * Method to render reference mic status
   * @param refMicStatus Reference mic status from payload
   * @returns Reference Mic UI element to render
   */
  getReferenceMic = (refMicStatus) => {
    // Retrieve reference mic status & style
    let referenceMicStatusText = this.getRefMicStatusText(refMicStatus);
    let referenceMicDisplayStyle = this.getRefMicDisplayStyle(refMicStatus);
    referenceMicDisplayStyle += ' awsui-util-ml-s';

    return (
      <div align='center' className='awsui-util-mt-xxl'>
        <h3 className='awsui-util-ml-xl awsui-util-mt-l awsui-util-mb-l ref-mic-status-style'>
          <span>
            {
              refMicStatus === CustomTestConstants.REF_MIC_STATUS_ONLINE && (
                <span>
                  <span className='ref-mic-recording-braces-style'>
                    [&nbsp;
                      </span>
                        <span className='ref-mic-recording-text-style'>
                          Rec
                        </span>
                        <b className='ref-mic-recording-dot-style'>
                          .
                        </b>
                      <span className='ref-mic-recording-braces-style'>
                    &nbsp;]
                  </span>
                </span>
               )
             }
             <b> Reference Mic Status: </b>
              <span className={ referenceMicDisplayStyle }>
              <AWSUI.Icon name='microphone'/>
              <span className='awsui-util-ml-xs'>
                { referenceMicStatusText }
              </span>
            </span>
          </span>
        </h3>
      </div>
    )
  }

  // Renders progress bar
  render() {
    let testStatus = this.props.params.testStatus ? this.props.params.testStatus.toLowerCase() : AppConstants.EMPTY;
    let percentage = this.props.params.testStats.testProgressPercent ? this.props.params.testStats.testProgressPercent : 0;
    let refMicStatus = this.props.params.testStats.testProgressPercent ? 
      this.props.params.testStats.testProgressPercent > 0 : CustomTestConstants.REF_MIC_STATUS_UNAVAILABLE;
    let isCompleted = this.shouldDisplayCompletedState(testStatus);

    return (
      <div>
        <div className='breadcrumb-style'>
          <AWSUI.BreadcrumbGroup>
            <AWSUI.BreadcrumbItem text='Started'/>
            <AWSUI.BreadcrumbItem text={ this.getInProgressText(testStatus, percentage) }/>
            { isCompleted && (
              <AWSUI.BreadcrumbItem text='Completed'/>
            )}
          </AWSUI.BreadcrumbGroup>
        </div>
        {
          (percentage >  0) && this.getReferenceMic(refMicStatus)
        }
        {
          isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <div className='breadcrumb-style'>
              <AutoSyncProgressBar params={{ labJobId: this.props.params.labJobId,
                scenarioType: this.props.params.scenarioType,
                testSuite: this.props.params.testSuite,
                customOptions: this.props.params.customOptions,
                marketPlace: this.props.params.marketPlace,
                testConfig: this.props.params.config}} />
            </div>
          )
        }
        {
          !isRasPiResourceSyncUpdateInProgress(testStatus) && (
            <ProgressBar percentage={ this.getPercentage(testStatus, percentage) }
            labJobId={ this.props.params.labJobId }/>
          )
        }
      </div>
    )
  }
}
 
CustomTestProgressBar.propTypes = {
  params: PropTypes.object.isRequired
};
 
export default CustomTestProgressBar;