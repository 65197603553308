import constants from '../constants/newRun';

const initialState = {
  scenarioErrors: {
    customTestOptions: {}
  },
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let response = payload;
  if(typeof(payload) === "string") {
    try {
      response = JSON.parse(payload)
    } catch (e) {
      response = {};
    }
  }
  switch (type) {
    case constants.RESET_VALIDATION_RESULT:
      return initialState;
    case constants.VALIDATE_NEW_RUN_PARAMS:
      return {
        ...state,
        isValid: response.isValid,
        scenarioErrors: {
          ...state.scenarioErrors,
          ...response
        }
      };
    default:
      return state;
  }
};

export default reducer;
