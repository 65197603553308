import { createSlice } from '@reduxjs/toolkit'


export const validate = (state) => {
  let error = {};
  if(!state.testSuite) {
    error['testSuite'] = 'TestSuite cannot be empty';
  }

  return Object.keys(error).length ? error : null;
}

const getInitialState = () => ({
  testSuite: null,
  id: null,
  error: null,
})

export const testInitSlice = createSlice({
  name: 'testInit',
  initialState: getInitialState(),
  reducers: {
    setTestSuite: (state, action) => {
      const testSuite = action.payload;
      state.testSuite = testSuite;
    },
    reset: () => getInitialState(),
    setError: (state, action) => {
      state.error = action.payload;
    },
  }
});

export const { setTestSuite, reset, setError } = testInitSlice.actions

export const testInitSelector = (state) => state.testInit

export default testInitSlice.reducer
