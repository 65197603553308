import { createSlice, nanoid } from '@reduxjs/toolkit'
import { LOCALE_LIST } from '../../../constants';


// TODO: Check if below string values could be inferred from intl
export const validate = (state) => {
  const error = {};
  if(!state) {
    throw new Error("Please pass valid state information")
  }
  const {locale, enableEmailNotification, customizedScenarios, scenarioType, deviceAttributes} = state;

  if(!state.name || !state.name.trim() || state.name.match(/\W/g)) {
    error['name'] = 'Name cannot be empty or have special characters';
  }

  if(!locale) {
    error['locale'] = 'Please select a locale';
  }
  if(typeof(enableEmailNotification) !== 'boolean') {
    error['enableEmailNotification'] = 'Enable email notification not selected';
  }

  if(!scenarioType) {
    error['scenarioType'] = "Please select a scenarioType";
  }

  if(scenarioType !== SCENARIO_TYPE.STANDARD && scenarioType !== SCENARIO_TYPE.CUSTOM && scenarioType !== SCENARIO_TYPE.WWA) {
    error['scenarioType'] = "Please select a valid scenario type";
  }

  if(scenarioType === SCENARIO_TYPE.CUSTOM && Array.isArray(customizedScenarios) && customizedScenarios.length > 0) {
    let errorObj = customizedScenarios.reduce(( errObj, catScenarioMap, index) => {
      if(!catScenarioMap || typeof(catScenarioMap) !== "object") {
        errObj[index] = "Invalid scenario";
        return errObj
      }
      const { category, scenarios} = catScenarioMap;
      if(!category) {
        errObj[index] = "Invalid Category";
        return errObj
      }
      if(!Array.isArray(scenarios) || scenarios.length === 0) {
        errObj[index] = `No scenario selected for the category ${category}`
        return errObj
      }
      return errObj;

    }, {})

    if (Object.keys(errorObj).length > 0 ) {
      error['categoryScenarioMap'] = errorObj;
    }

  }

  if(scenarioType === SCENARIO_TYPE.WWA && Array.isArray(customizedScenarios) && customizedScenarios.length > 0) {
    let errorObj = customizedScenarios.reduce(( errObj, deviceCatTestPlanMap, index) => {
      if(!deviceCatTestPlanMap || typeof(deviceCatTestPlanMap) !== "object") {
        errObj[index] = "Invalid scenario";
        return errObj
      }
      const { category: deviceCategory, scenarios: testPlanInStore} = deviceCatTestPlanMap;
      if(!deviceCategory) {
        errObj[index] = "Invalid Device Category";
        return errObj
      }
      if(!Array.isArray(testPlanInStore) || testPlanInStore.length === 0) {
        errObj[index] = `No test plan selected for the device category ${deviceCategory}`
        return errObj
      }
      return errObj;

    }, {})

    if (Object.keys(errorObj).length > 0 ) {
      error['categoryScenarioMap'] = errorObj;
    }

  }

  return Object.keys(error).length ? error : null;
}


export const SCENARIO_TYPE = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
  WWA: 'wwa'
}


const EMPTY_CUSTOMIZATION = {
  category: null,
  scenarios: []
}

const getInitialState = () => ({
  scenarioType: SCENARIO_TYPE.CUSTOM,
  name: null,
  locale: LOCALE_LIST[0],
  enableEmailNotification: false,
  error: null,
  customizedScenarios: [
    EMPTY_CUSTOMIZATION
  ]
});

export const scenarioSelectionSlice = createSlice({
  name: 'scenearioSelection',
  initialState: getInitialState(),
  reducers: {
    setCategoryAndScenario: (state, action) => {
      const {category, scenarios, index} = action.payload;
      if(state.scenarioType === SCENARIO_TYPE.STANDARD) {
        return;
      }

      const catScenObj = {
        category,
        scenarios
      }
      if(index > -1) {
        state.customizedScenarios[index] = catScenObj
      }
    },
    setLocale: (state, action) => {
      const locale = action.payload;
      state.locale = locale;

    },
    setId: (state, action) => {
      const id = action.payload;
      if(!id) {
        state.id = nanoid();
      } else {
        state.id = id;
      }
    },
    setName: (state, action) => {
      const name = action.payload;
      state.name = name;
    },
    setEnableEmailNotification: (state, action) => {
      const enableEmailNotification = action.payload;
      state.enableEmailNotification = enableEmailNotification;
    },
    setScenarioType: (state, action) => {
      const scenarioType = action.payload;
      const prevScenarioType = state.scenarioType;

      if(scenarioType === SCENARIO_TYPE.STANDARD &&
        (prevScenarioType === SCENARIO_TYPE.CUSTOM || prevScenarioType === SCENARIO_TYPE.WWA)) {
        state.scenarioType = SCENARIO_TYPE.STANDARD;
        state.customizedScenarios = [
          EMPTY_CUSTOMIZATION
        ];
      }
      else if(scenarioType === SCENARIO_TYPE.CUSTOM) {
        state.scenarioType = SCENARIO_TYPE.CUSTOM;
      } else if (scenarioType === SCENARIO_TYPE.WWA) {
        state.scenarioType = SCENARIO_TYPE.WWA;
      }
    },
    removeCategory: (state, action) => {
      const {index} = action.payload;
      if(state.customizedScenarios.length === 1 && index === 0) {
        state.customizedScenarios = [EMPTY_CUSTOMIZATION];
      } else {
        state.customizedScenarios.splice(index, 1);

      }
    },
    addNewCustomization: (state) => {
      state.customizedScenarios.push(EMPTY_CUSTOMIZATION);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    reset: () => getInitialState()
  },
})

export const {
  setCategoryAndScenario,
  removeCategory,
  addNewCustomization,
  setId,
  setName,
  setScenarioType,
  setLocale,
  setEnableEmailNotification,
  setError,
  reset,
} = scenarioSelectionSlice.actions


export const customizationSelector = (state) => state.acmScenarioSlection.customizedScenarios;
export const scenarioSliceSelector = (state) => state.acmScenarioSlection.scenarioType;
export const scenarioCategoryErrorSelector = (state) => state.acmScenarioSlection.error;
export const testSuiteConfigSelector = (state) => state.acmScenarioSlection;


export default scenarioSelectionSlice.reducer
