import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import { getTestTypeToDisplay, getDisplayNameForId, renderLabelElement,
  getDisplayFieldForCustomFar, getCustomOptionsDisplayMap, getDisplayFieldForCheckBox,
  getTrainers, getAmazonIdToDisplay, getFunctionalTestCases, getFunctionalScenarios, isA4PCDevice, logToConsole
} from '../../../Util';
import { isValidDeviceConfig } from '../Util';
import { isEmpty } from 'lodash';
import FunctionalTestCases from '../../../_Constants/FunctionalTestCasesConstants';
import { FAR_CUSTOM_DUT_TABLE_COLUMNS } from '../FARCustomDUTTableConfig';

/**
 * Similar as TestOptionsDisplay, except for security tests
 */
class Details extends React.Component {

  /**
   * Gets custom options in display friendly format
   * @param options Custom options
   * @param testSuite Test Suite
   * @return Returns custom options list in display friendly format
   */
  customOptPretty(options, testSuite) {
    let customOptionsDisplay = getCustomOptionsDisplayMap(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(customOptionsDisplay).map(option => {
           let optionDisplay = option;
           // If it's a Close Talk Scenario, display 0.9 m 90 deg as HATS
           if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID && option === AppConstants.LOCATION_THREE_NINETY) {
             optionDisplay = AppConstants.CT_HATS_LOCATION;
           }
           return (<div key={ option } > { optionDisplay + ' - ' + customOptionsDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of trainers in display friendly format
   * @param options Custom options
   * @return Returns list of trainers in display friendly format
   */
  getTrainersPretty(options) {
    let trainersDisplay = getTrainers(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div> { trainersDisplay } </div>
      </div>
    );
  }

  /**
   * Gets the list of Functional Test cases for Functional Custom scenario in display friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getFunctionalTestCasesPretty(testParams) {
    let functionalTestCasesDisplay = getFunctionalTestCases(testParams);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(functionalTestCasesDisplay).map(option => {
           return (<div key={ option } > { option + ' ==> ' + functionalTestCasesDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Render SPL settings in display friendly format
   * @param options SPL Setting
   * @param testSuite Test Suite
   * @return Returns SPL settings
   */
  renderSPLSettings(testSuite, scenarioType, spl) {
    if (testSuite !== AppConstants.QUAL_SCENARIO_ID || scenarioType === 'QUAL_WWDD' || !spl) return;

    const measuringUnit = spl.measuringUnit;

    const settings = Object.keys(spl)
      .filter(k => spl[k] && k !== 'measuringUnit')
      .map(key =>
        (
          <div key={key}>
            {
              key === 'dut'
                ? (<div>{`${key.toUpperCase()}: ${spl[key]} ${measuringUnit}`}</div>)
                : (
                  Object.keys(spl[key])
                  .map(loc => (<div>{`${key.toUpperCase()} (${loc}): ${spl[key][loc]} ${measuringUnit}`}</div>))
                )
            }
          </div>
        )
      );

    if (!settings) return;

    return (
      <div>
        <div className='awsui-util-label'><b>SPL Settings</b></div>
        <div className={`awsui-util-label`}>
          <div className='awsui-util-spacing-v-s'>{settings}</div>
        </div>
      </div>
    );
  }

  /**
   * Method used to display Scenario Type (for Acoustic) or MSP/ MSP (for Music) on Test options page
   * @param testSuite To decide whether to display scenario Type or MSP/ MSK
   * @param scenarioType Scenario Type for Acoustic/ MSP or MSK for Music
   * @param testType Test type for Acoustic
   * @return Name of Scenario Type for Acoustic or MSP/ MSK for music
  */
  getTestTypeToDisplay = (testSuite, scenarioType, testType) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_TYPE, scenarioType);
    }
    if ([AppConstants.SECURITY_SCENARIO_ID, AppConstants.UPL_SCENARIO_ID, AppConstants.STABILITY_SCENARIO_ID].includes(testSuite)) {
      return getTestTypeToDisplay(testSuite, scenarioType, AppConstants.EMPTY);
    } else if (testSuite !== AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return getTestTypeToDisplay(testSuite, scenarioType, testType);
    } else {
      return getDisplayNameForId(getFunctionalScenarios(), scenarioType);
    }
  }

  /**
   * Method used to display 'Scenario Type' label based on Test Suite
   * @param testSuite Test Suite
   * @return Scenario Type label based on type of test suite
   */
  getTestTypeLabelToDisplay = (testSuite) => {
    if (AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY.hasOwnProperty(testSuite)) {
      return AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY[testSuite];
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets Device Virtual Assistant label to display
   */
  getDeviceVirtualAssistanLabelToDisplay = (testSuite) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return AppConstants.DEVICE_VIRTUAL_ASSISTANT_LABEL;
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets range of utterances to be played for Auto Local Search Full test scenario
   */
  getUtteranceRangeToDisplay = (fromTranscripts, toTranscripts) => {
    if (fromTranscripts && toTranscripts) {
      return fromTranscripts + ' to ' + toTranscripts;
    }
  }

  /**
   * Gets value for selected Device Virtual Assistant from New Run page
   */
  getDeviceVirtualAssistant = (testSuite, deviceVirtualAssistant) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS, deviceVirtualAssistant);
    }
    return AppConstants.EMPTY;
  }

  /**
   * TODO: verify for usage
   * Method to display test options for Security scenario
   */
  renderTestOptionsForSecurity = (params) => {
    const {testParams} = params;
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
            <div className='awsui-util-action-stripe-group'>
              <AWSUI.Button text='Back' variant='primary' onClick={ () => { params.back() } } />
            </div>
          </div>
        </div>
        <div>
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div></div>
              <div align='center'>
                { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      testParams.testSuite)) }
              </div>
              <div></div>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    );
  }

  /**
   * Generates table containing DUT Information for FAR Custom test
   * Table contains Customer ID, DSN, Amazon ID, Device Name & Build Information
   */
  getFARCustomDUTTable = (params) => {
    const { testParams } = params;
    let deviceMapping = testParams.deviceMapping;
    logToConsole('TestLog - DUT mapping inside Test options page = ' + JSON.stringify(deviceMapping));
    let dutTableDataIn = [];
    Object.keys(deviceMapping).forEach(function(dutKey) {
      let dut = deviceMapping[dutKey];
      dutTableDataIn.push({
        customerId: dut.hasOwnProperty('customerId') ? dut['customerId'] : AppConstants.EMPTY,
        deviceName: dut.hasOwnProperty('deviceName') ? dut['deviceName'] : AppConstants.EMPTY,
        dsn: dut.hasOwnProperty('dsn') ? dut['dsn'] : AppConstants.EMPTY,
        amazonId: dut.hasOwnProperty('amazonId') ? dut['amazonId'] : AppConstants.EMPTY,
        buildInfo: dut.hasOwnProperty('buildInfo') ? dut['buildInfo'] : AppConstants.EMPTY,
      });
    });
    return (
      <div align='center'>
        <AWSUI.FormField
          label={ <h4>DUT Information</h4> }
          className='awsui-util-m-l'
      >
          <AWSUI.Table
            columnDefinitions={ FAR_CUSTOM_DUT_TABLE_COLUMNS }
            items={ dutTableDataIn }
          >
          </AWSUI.Table>
        </AWSUI.FormField>
      </div>
    );
  }

  /**
   * render helpful text if DUT is an A4PC device.
   * @param {Object} params
   */
  renderDeviceConfigInfo = (params = {}) => {
    const { deviceConfig, deviceTypeId, dsn } = params;
    if (!isA4PCDevice(deviceTypeId)) return (<div></div>);

    let label;
    let errorClass = 'awsui-util-status-negative'

    // Show the message here too, to the let the user know that he is submitting the wrong file
    if (!isValidDeviceConfig(deviceConfig, dsn)) {
      label = AppConstants.INVALID_DEVICE_CONFIG;
    } else if (deviceConfig === AppConstants.EMPTY) {
      label = AppConstants.MISSING_DEVICE_CONFIG;
    } else {
      label = 'Provided'
      errorClass = ''
    }

    return (
      <div>
        <div className='awsui-util-label'><b>A4PC Device Config</b></div>
        <div className={`awsui-util-label ${errorClass}`}>{ label }</div>
      </div>
    );
  }

  /**
   * Renders default Test Options for most of the scenarios except FAR Custom, Security and Music.
   */
  renderDefault = (params) => {
    const { testParams } = params;
    const { testOptions } = testParams;
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
          <div className='awsui-util-action-stripe-group'>
            <AWSUI.Button text='Back' variant='primary' onClick={ () => { params.back() } } />
          </div>
        </div>
        </div>
        <div>
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, testParams.customerId) }
                </div>
                <div>
                  { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                      testParams.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : testParams.dsn) }
                </div>
                <div>
                  { renderLabelElement('Device Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                      testParams.scenarioType === AppConstants.FAR_CUSTOM ?
                        AppConstants.CUSTOM : testParams.deviceName) }
                </div>
                <div>
                  { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                      getAmazonIdToDisplay(testParams.deviceTypeId, testParams.scenarioType)) }
                </div>
                { this.renderDeviceConfigInfo(testParams) }
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement(AppConstants.FIRMWARE, AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                     getDisplayFieldForCustomFar(testParams.buildInfo, testParams.scenarioType)) }
                </div>
                {
                  testParams.testSuite !== AppConstants.SECURITY_SCENARIO_ID &&
                  (
                    <div>
                      {renderLabelElement('Locale', AppConstants.EMPTY)}
                      {renderLabelElement(AppConstants.EMPTY,
                        getDisplayNameForId(AppConstants.MARKETPLACE, testParams.marketPlace))}
                    </div>
                  )
                }
                <div>
                   { renderLabelElement('Email Notification', AppConstants.EMPTY) }
                   { renderLabelElement(AppConstants.EMPTY,
                       getDisplayFieldForCheckBox(testParams.sendCompletionEmail)) }
                </div>
                {
                  (testParams.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID ||
                    testParams.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) && (
                      <div>
                        { renderLabelElement('Auto-sync resources', AppConstants.EMPTY) }
                        { renderLabelElement(AppConstants.EMPTY,
                            getDisplayFieldForCheckBox(testParams.isAutoSyncEnabled)) }
                      </div>
                  )
                }
                <div>
                { renderLabelElement('Lab Name', AppConstants.EMPTY) }
                  <div> {
                    params.labs.map(lab => {
                      return lab.id === testParams.labId ? lab.label : ''
                    })
                   }
                   </div>
                </div>
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Test Group Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, testParams.testName) }
                </div>
                <div>
                  { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      testParams.testSuite)) }
                </div>
                <div>
                  { renderLabelElement(this.getTestTypeLabelToDisplay(testParams.testSuite), AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getTestTypeToDisplay(testParams.testSuite,
                      testParams.scenarioType, testParams.testType)) }
                </div>
                {this.renderSPLSettings(testParams.testSuite, testParams.scenarioType, testOptions.spl)}
                <div>
                  { renderLabelElement(this.getDeviceVirtualAssistanLabelToDisplay(testParams.testSuite), AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getDeviceVirtualAssistant(testParams.testSuite,
                      testParams.deviceVirtualAssistant)) }
                </div>
                {
                  testParams.isUtteranceRangeSet &&
                  testParams.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID &&
                  testParams.scenarioType === AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_FULL && (
                  <div>
                    { renderLabelElement('Utterances Range', AppConstants.EMPTY) }
                    { this.getUtteranceRangeToDisplay(testParams.fromTranscriptNum, testParams.toTranscriptNum) }
                  </div>
                  )
                }
                { testParams.testType === AppConstants.CUSTOMIZED &&
                  testParams.testSuite !== AppConstants.QUAL_SCENARIO_ID && (
                  <div>
                     { renderLabelElement('Custom Options', AppConstants.EMPTY) }
                    <div> { this.customOptPretty(testParams.testOptions.customOptions, testParams.testSuite) } </div>
                  </div>
                )}
                { testParams.testType === AppConstants.CUSTOMIZED &&
                  testParams.scenarioType === AppConstants.TRAINED_MOBILE && (
                  <div>
                     { renderLabelElement('Trainers', AppConstants.EMPTY) }
                    <div> { this.getTrainersPretty(testParams.testOptions.customOptions) } </div>
                  </div>
                )}
                { testParams.testType === AppConstants.CUSTOMIZED &&
                  testParams.testSuite !== AppConstants.QUAL_SCENARIO_ID &&
                  testParams.customizedTestType !== AppConstants.EMPTY && (
                  <div>
                     { renderLabelElement('Custom Sub-Test Type', AppConstants.EMPTY) }
                    <div> { getDisplayNameForId(AppConstants.CUSTOMIZED_SCENARIOS, testParams.customizedTestType) } </div>
                  </div>
                )}
                { (testParams.scenarioType === FunctionalTestCases.FUNC_CUSTOM
                  || testParams.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM) && (
                  <div>
                     { renderLabelElement('Functional Test Cases', AppConstants.EMPTY) }
                    <div> { this.getFunctionalTestCasesPretty(testParams) } </div>
                  </div>
                )}
              </div>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    );
  }

  /**
   * Renders Test Options for FAR Custom Scenario
   */
  renderFARCustom = (params) => {
    const { testParams } = params;
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
          <div className='awsui-util-action-stripe-group'>
            <AWSUI.Button text='Back' variant='primary' onClick={ () => { params.back() } } />
          </div>
        </div>
        </div>
        <div>
          <div align='center'>
            {
              this.getFARCustomDUTTable(params)
            }
          </div>
          <AWSUI.FormField
            label={ <h4>Other Fields</h4> }
            className='awsui-util-m-l'
           >
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement(this.getTestTypeLabelToDisplay(testParams.testSuite), AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getTestTypeToDisplay(testParams.testSuite,
                      testParams.scenarioType, testParams.testType)) }
                </div>
                <div>
                  { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      testParams.testSuite)) }
                </div>
                { this.renderDeviceConfigInfo(params.selectedTestGroupInfo) }
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Test Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, testParams.testName) }
                </div>
                <div>
                  { renderLabelElement('Locale', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                      getDisplayNameForId(AppConstants.MARKETPLACE, testParams.marketPlace)) }
                </div>
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Lab Name', AppConstants.EMPTY) }
                    <div> {
                      params.labs.map(lab => {
                      return lab.id === testParams.labId ? lab.label : ''
                    })
                  }
                  </div>
                </div>
                <div>
                   { renderLabelElement('Email Notification', AppConstants.EMPTY) }
                   { renderLabelElement(AppConstants.EMPTY,
                       getDisplayFieldForCheckBox(testParams.sendCompletionEmail)) }
                </div>
              </div>
            </div>
          </AWSUI.ColumnLayout>
          </AWSUI.FormField>
        </div>
      </div>
    );
  }

  renderDetails = () => {
    const { params } = this.props;
    if (isEmpty(params.testParams)) {
      return;
    }
    // For FAR Custom, render Table of DUT Information & other fields.
    if (params.testParams.scenarioType === AppConstants.FAR_CUSTOM) {
      return this.renderFARCustom(params);
    }
    return this.renderDefault(params);
  }

  render() {
    return (
      <div className='awsui-row'>
        <div className='col-12'>
          <AWSUI.ColumnLayout>
            <div data-awsui-column-layout-root='true'>
              <div>
                {this.renderDetails()}
              </div>
            </div>
          </AWSUI.ColumnLayout>
        </div>
      </div>
    )
  }
}

export default Details;
