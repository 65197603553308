import '@amzn/awsui-components-react/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import HeadBar from './Components/HeadBar';
import FootBar from './Components/FootBar';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import createStore from './redux/store';

const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div id="container">
        <div id="headbar">
          <div className='awsui'><HeadBar /></div>
        </div>
        <div id="app-container" className='awsui'><App /></div>
        <div id="footbar">
          <div className='awsui'><FootBar /></div>
        </div>
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
);

unregister();
