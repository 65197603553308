import AppConstants from '../../_Constants/AppConstants';
import { validateInput } from '../../Util';
import { sendRawInfoToNewRunWithIdentifier, sendRawInfoToNewRunWithStateId } from './NewRunDataTransferUtils';
import { setValueForExpandedSection } from "./FunctionalUtil";

/**
 * This Utilities includes common UI component actions that is resuable throughout NewRun Page.
 */

const IDENTIFIER = 'uiActionsUtils';
// when AWS component Input is updated
// original onChangeInput
/**
 * Use together with AWSUI Input ui component
 * onChange Event
 * @param {*} id id  eg testName
 * @param {*} event
 * @param {*} source place where this call is initialized, eg TestGroupInfo: onChangeInput
 * @param {*} params params where this input field event need to update, eg, selectedTestGroupInfo
 * @param {*} updateParamsCallBack callback function to update params, eg, selectedTestGroupInfoCallBack
 * @param {*} paramsError errors related to params selection, eg, selectedTestGroupInfoError
 * @param {*} updateParamsErrorCallBack callback function to update paramsError, eg, selectedTestGroupInfoErrorCallBack
 */
export function onChangeInput(id, event, source,
  params, updateParamsCallBack,
  paramsError, updateParamsErrorCallBack) {
  const identifier = IDENTIFIER + ' onChangeInput from: ' + source + ' ' + id;
  let error = validateInput(id, event.detail.value);
  let updatedParams = params;
  updatedParams[id] = event.detail.value;
  sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);

  let updatedParamsError = paramsError;
  updatedParamsError[id] = error;
  sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);
}

/**
 * Use together with AWSUI Input ui component
 * onInput event
 * @param {*} id id  eg testName
 * @param {*} event
 * @param {*} source place where this call is initialized, eg TestGroupInfo: onChangeInput
 * @param {*} params params where this input field event need to update, eg, selectedTestGroupInfo
 * @param {*} updateParamsCallBack callback function to update params, eg, selectedTestGroupInfoCallBack
 * @param {*} paramsError errors related to params selection, eg, selectedTestGroupInfoError
 * @param {*} updateParamsErrorCallBack callback function to update paramsError, eg, selectedTestGroupInfoErrorCallBack
 */
export function onInput(id, event, source,
  params, updateParamsCallBack,
  paramsError, updateParamsErrorCallBack) {
  const identifier = IDENTIFIER + ' onInput from: ' + source + ' ' + id;
  if (paramsError[id] || event.detail.value === AppConstants.EMPTY) {
    let updatedParamsError = paramsError;
    updatedParamsError[id] = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, updateParamsErrorCallBack);

    let updatedParams = params;
    updatedParams[id] = AppConstants.EMPTY;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, updateParamsCallBack);
  }
}

/**
 * TODO: make setValueForExpandedSection from FunctionUtils out to common helper/utility
 * Event handler for expanding/ collapsing Expandable sections - used by Functional
 * Custom Scenario & FAR Custom scenario
 * Use Case 1. in Functional, when Custom functional scenario is selected
 * @param {*} id
 * @param {*} event
 * @param {*} expandedSections
 * @param {*} stateId looks like always 'expandedSections' for now, applies for FAR Custom as well
 * @param {*} params
 * @param {*} updateParamsCallBack
 */
export function onSectionExpanded(id, event, expandedSections, stateId,
  params, updateParamsCallBack) {
  const identifier = IDENTIFIER + ' , onSectionExpanded';
  expandedSections = setValueForExpandedSection(id, event, expandedSections);
  let updatedParams = params;
  updatedParams[stateId] = expandedSections;
  sendRawInfoToNewRunWithIdentifier(
    identifier,
    updatedParams,
    updateParamsCallBack
  );
};

/**
 * Action function for checkbox in UI.
 * Update NewRun page state parameter when checkbox is checked/unchecked.
 * @param {*} event
 * @param {*} fieldName - eg scenarioId
 * @param {*} fields - fields where fieldName exist in, eg, selectedScenario
 * @param {*} updateFieldsCallBack - eg selectedScenarioCallBack
 */
export function setCheckedStateInTestParams(event, fieldName, fields, updateFieldsCallBack) {
  if ( fieldName === "mobileVoiceTrainingIsManual" && !event.detail.checked) {
    fields.isDevice1PSV = event.detail.checked
  }
  let newFields = fields;
  newFields[fieldName] = event.detail.checked;
  updateFieldsCallBack(newFields, fieldName);
}

/**
 * Reset NewRun Page state parameter to empty object
 * @param {*} id eg, 'confirm'
 * @param {*} updateObjCallBack eg, updateConfirmCallBack
 */
export function resetToEmptyObject(id, updateObjCallBack) {
  let identifier = IDENTIFIER + ' , resetToEmptyObject, ' + id;
  let updatedObj = {};
  sendRawInfoToNewRunWithStateId(identifier, updatedObj,
    id, updateObjCallBack);
}
