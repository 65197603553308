const FunctionalTestCases = {
    FUNC_CUSTOM: 'FUNC_CUSTOM',
    AUTO_FUNC_ALL:'AUTO_FUNC_ALL',
    AUTO_FUNC_CUSTOM: 'AUTO_FUNC_CUSTOM',
    FUNC_ALL: 'FUNC_ALL',
    FUNC_NAV_ALL: 'FUNC_NAV_ALL',
    FUNC_NAV_CUSTOM: 'FUNC_NAV_CUSTOM',
    FUNC_Location_Error: 'Please select at least one location',
    FUNC_CUSTOM_ERROR: 'Please select at least one test case',
    FAILED: 'FAILED',
    ERROR: 'ERROR',
    SUCCEEDED: 'SUCCEEDED',
    SUCCESS: 'SUCCESS',
    IN_PROGRESS: 'IN_PROGRESS',
    EVALUATING_RESULT: 'EVALUATING_RESULT',
    QUEUED: 'QUEUED',
    SKIPPED: 'SKIPPED',
    IGNORED: 'IGNORED',
    COMPLETED: 'COMPLETED',
    FUNCTIONAL_TEST_CASES_KEY: 'functionalTestCases',
    TEST_CASES_KEY: 'testCases',
    STATUS_KEY: 'status',
    FUNCTIONAL_LABEL: 'Functional ',
    ALEXA_CLOUD_VALIDATION: 'ALEXA_CLOUD_VALIDATION',
    REF_MIC_VALIDATION: 'REF_MIC_VALIDATION',
    REF_MIC_SPEECH_AUDIO_VALIDATION: 'REF_MIC_SPEECH_AUDIO_VALIDATION',
    STATUS_NOT_APPLICABLE: 'NOT_APPLICABLE',
    INFO: 'INFO',
    INFO_OVERALL: 'INFO_OVERALL',
    QUESTION_PLAYED: 'questionPlayed',
    QUESTION_PLAYED_OVERALL: 'questionPlayedOverall',
    DUT_HEARD: 'dutHeard',
    DUT_RESPONDED: 'dutResponded',
    FRR_KEY: 'scoreFrr',
    RAR_KEY: 'scoreRar',
    UTTERANCE_DATA_KEY: '_UtteranceData',
    UTTERANCE_REGEX_TYPE_VALIDATION: 'CLOUD_REGEX_VALIDATION',
    INTENT_TYPE_SUCCESS_RESPONSE: '(Action Response was Accurate)',
    REF_MIC_IN_PROGRESS_MESSAGE: 'Cloud validation for all tests is completed... However, Audio Recording Validation for some of the tests is still pending, '
      + ' which may take a few more minutes to complete.',
    UTTERANCE_NORMAL: 'NORMAL',
    UTTERANCE_IGNORE: 'IGNORE_FROM_VISIBILITY',
    REF_MIC_STATUS_UNAVAILABLE: 'Unavailable',
    REF_MIC_STATUS_ONLINE: true,
    REF_MIC_STATUS_OFFLINE: false,

    // Map used to display utterance status text in Utterance Details tab
    UTTERANCE_DETAILS_DISPLAY_TEXT: {
      'QUEUED': 'Queued',
      'SKIPPED': 'Skipped',
      'IGNORED': 'Ignored'
    },

    // Utterance statuses for utterances which are not run
    UTTERANCE_INCOMPLETE_STATUS: ['QUEUED', 'SKIPPED', 'IGNORED'],

    // Test statuses for pending validation
    PENDING_VALIDATION_STATUS: ['QUEUED', 'IN_PROGRESS', 'EVALUATING_RESULT'],

    // Map to display Overall Validation reason when result of Alexa Cloud Validation &
    // Ref Mic validation is conflicting
    OVERALL_VALIDATION_REASON_MAP: {
      'OVERALL_SUCCEEDED_CLOUD_SUCCEEDED_REF_MIC_FAILED' : 'Passed based on Alexa Cloud Validation',
      'OVERALL_SUCCEEDED_CLOUD_SUCCEEDED_REF_MIC_ERROR' : 'Passed based on Alexa Cloud Validation',
      'OVERALL_SUCCEEDED_CLOUD_FAILED_REF_MIC_SUCCEEDED' : 'Passed based on Audio Recording Validation',
      'OVERALL_SUCCEEDED_CLOUD_ERROR_REF_MIC_SUCCEEDED' : 'Passed based on Audio Recording Validation',
      'OVERALL_FAILED_CLOUD_SUCCEEDED_REF_MIC_FAILED' : 'Failed based on Audio Recording Validation',
      'OVERALL_FAILED_CLOUD_FAILED_REF_MIC_SUCCEEDED' : 'Failed based on Alexa Cloud Validation',
      'OVERALL_FAILED_CLOUD_SUCCEEDED_REF_MIC_ERROR' : 'Failed based on Audio Recording Validation',
      'OVERALL_FAILED_CLOUD_ERROR_REF_MIC_SUCCEEDED' : 'Failed based on Alexa Cloud Validation'
    },

    // Map containing each category & list of functional tests under it
    FUNCTIONAL_TEST_CASES_MAPPING: {
      'Alarm': [
        { id: 'SetAlarm', label: 'Set Alarm' },
        { id: 'SetAlarmMultiTurn', label: 'Alarm - Multi Turn' },
        { id: 'SetAlarmCancel', label: 'Alarm - Cancel' },
        { id: 'SetAlarmBargeIn', label: 'Alarm - Barge In' }
      ],
      'Barge In': [
        { id: 'BargeIn', label: 'Barge In' }
      ],
      'Cloud Based WW Verification': [
        { id: 'WWVerification', label: 'Cloud Based WW Verification' }
      ],
      'Echo Cancellation': [
        { id: 'EchoCancellation', label: 'Acoustic Echo Cancellation' }
      ],
      'Equalizer': [
        { id: 'EqualizerAdjustBass', label: 'Equalizer Control - Adjust Bass' },
        { id: 'EqualizerAdjustTreble', label: 'Equalizer Control - Adjust Treble' }
      ],
      'Flash Briefing': [
        { id: 'FlashBriefing', label: 'Flash Briefing' }
      ],
      'Notifications': [
        { id: 'ReadNotification', label: 'Notifications - Read' },
        { id: 'NotificationBargeIn', label: 'Notifications - Barge In' }
      ],
      'Reminder': [
        { id: 'SetReminderMultiTurn', label: 'Reminder - Multi Turn' }
      ],
      'Shopping ': [
        { id: 'AddToShoppingList', label: 'Add To Shopping List' }
      ],
      'Sports Update': [
        { id: 'SportsUpdate', label: 'Sports Update' }
      ],
      'Timer': [
        { id: 'SetTimer', label: 'Set Timer' },
        { id: 'SetNamedTimer', label: 'Set Named Timer' },
        { id: 'SetTimerBargeIn', label: 'Set Timer - Barge In' },
        { id: 'ActiveTimerBargeIn', label: 'Active Timer - Barge In' }
      ],
      'Volume': [
        { id: 'VolumeUp', label: 'Volume Up' },
        { id: 'VolumeDown', label: 'Volume Down' },
        { id: 'Mute', label: 'Mute' },
        { id: 'Unmute', label: 'Unmute' }
      ]
    },

  // Map containing each category & list of functional tests under it
    FUNCTIONAL_AUTOMOTIVE_TEST_CASES_MAPPING: {
    'Alarm': [
      { id: 'SetAlarm', label: 'Set Alarm' },
      { id: 'SetAlarmMultiTurn', label: 'Alarm - Multi Turn' },
      { id: 'SetAlarmCancel', label: 'Alarm - Cancel' },
      { id: 'SetAlarmBargeIn', label: 'Alarm - Barge In' }
    ],
    'Barge In': [
      { id: 'BargeIn', label: 'Barge In' }
    ],
    'Cloud Based WW Verification': [
      { id: 'WWVerification', label: 'Cloud Based WW Verification' }
    ],
    'Echo Cancellation': [
      { id: 'EchoCancellation', label: 'Acoustic Echo Cancellation' }
    ],
    'Flash Briefing': [
      { id: 'FlashBriefing', label: 'Flash Briefing' }
    ],
    'Notifications': [
      { id: 'ReadNotification', label: 'Notifications - Read' },
      { id: 'NotificationBargeIn', label: 'Notifications - Barge In' }
    ],
    'Reminder': [
      { id: 'SetReminderMultiTurn', label: 'Reminder - Multi Turn' }
    ],
    'Shopping ': [
      { id: 'AddToShoppingList', label: 'Add To Shopping List' }
    ],
    'Sports Update': [
      { id: 'SportsUpdate', label: 'Sports Update' }
    ],
    'Timer': [
      { id: 'SetTimer', label: 'Set Timer' },
      { id: 'SetNamedTimer', label: 'Set Named Timer' },
      { id: 'SetTimerBargeIn', label: 'Set Timer - Barge In' },
      { id: 'ActiveTimerBargeIn', label: 'Active Timer - Barge In' }
    ],
    'Volume': [
      { id: 'VolumeUp', label: 'Volume Up' },
      { id: 'VolumeDown', label: 'Volume Down' },
      { id: 'Mute', label: 'Mute' },
      { id: 'Unmute', label: 'Unmute' }
    ]
  },

  // Map containing each category & list of nav functional tests under it
    FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING: {
      'Location Interaction': [
        { id: 'CurrentLocation', label: 'Current Location' }
      ],
      'Navigate': [
        { id: 'MultiTurnNearestPOI', label: 'Multi-Turn [Nearest POI chain]' },
        { id: 'MultiTurnPOI', label: 'Multi-Turn [POI chain]' },
        { id: 'City', label: 'City' },
        { id: 'State', label: 'State' },
        { id: 'PointOfInterest', label: 'Point of Interest' },
        { id: 'CustomPOI', label: 'Custom POI' }
      ],
      'Map Interaction': [
        { id: 'ZoomInAndZoomOut', label: 'ZoomIn & ZoomOut' },
        { id: 'MuteAndUnMute', label: 'Mute & UnMute' },
        { id: 'RoutePreview', label: 'Route Preview' },
        { id: 'RecentTrip', label: 'Recent Trip' },
        { id: 'AlternateRoute', label: 'Alternate Route' }
      ],
      'WayPoint': [
        { id: 'AddRemoveUserFavorites', label: 'Add/remove User Favorites' },
        { id: 'AddRemoveMultiplePOI', label: 'Add/Remove Multiple POI' }
      ],
      'Exit Navigation': [
        { id: 'ExitNavigation', label: 'Exit Navigation' }
      ]
  },
    // wakeword test cases
    WAKEWORD_TEST_CASES: [
      'WakewordDiagnosticInformation',
      'WakewordPreroll',
      'WakewordAudioInitiator'
    ],

    WAKEWORD_TEST_CASES_MAPPING: [
      {
        id: "WakewordDiagnosticInformation",
        label: "Wakeword Diagnostic Information",
        features: "@wwe1"
      },
      {
        id: "WakewordPreroll",
        label: "Wakeword 500ms pre-roll",
        features: "@wwe2",
      },
      {
        id: "WakewordAudioInitiator",
        label: "Wakeword Audio Initiator",
        features: "@wwe3",
      }
    ],

    // Map containing mapping from test case ID to display friendly test case name
    FUNCTIONAL_TEST_CASES_DISPLAY: {
      'SetAlarm': 'Set Alarm',
      'SetAlarmMultiTurn': 'Alarm - Multi Turn',
      'SetAlarmCancel': 'Alarm - Cancel',
      'SetAlarmBargeIn': 'Alarm - Barge In',
      'SetTimer': 'Set Timer',
      'SetNamedTimer': 'Set Named Timer',
      'SetTimerBargeIn': 'Set Timer - Barge In',
      'ActiveTimerBargeIn': 'Active Timer - Barge In',
      'SetTimerMultimodal': 'Set Timer (Multimodal)',
      'SetNamedTimerMultimodal': 'Set Named Timer (Multimodal)',
      'SetTimerBargeInMultimodal': 'Set Timer - Barge In (Multimodal)',
      'ActiveTimerBargeInMultimodal': 'Active Timer - Barge In (Multimodal)',
      'SetReminderMultiTurn': 'Reminder - Multi Turn',
      'VolumeUp': 'Volume Up',
      'VolumeDown': 'Volume Down',
      'Mute': 'Mute',
      'Unmute': 'Unmute',
      'ReadNotification': 'Notifications - Read',
      'NotificationBargeIn': 'Notifications - Barge In',
      'FlashBriefing': 'Flash Briefing',
      'BargeIn': 'Barge In',
      'AddToShoppingList': 'Add To Shopping List',
      'SportsUpdate': 'Sports Update',
      'EqualizerAdjustBass': 'Equalizer Control - Adjust Bass',
      'EqualizerAdjustTreble': 'Equalizer Control - Adjust Treble',
      'WWVerification': 'Cloud Based WW Verification',
      'EchoCancellation': 'Acoustic Echo Cancellation'
    },

   // Map containing mapping from test case ID to display friendly test case name
    FUNCTIONAL_NAV_TEST_CASES_DISPLAY: {
      'CurrentLocation': 'Current Location',
      'MultiTurnNearestPOI': 'Multi-Turn [Nearest POI chain]',
      'MultiTurnPOI': 'Multi-Turn [POI chain]',
      'City': 'City',
      'State': 'State',
      'PointOfInterest': 'Point of Interest',
      'CustomPOI': 'Custom POI',
      'ZoomInAndZoomOut': 'ZoomIn & ZoomOut',
      'MuteAndUnMute': 'MuteAndUnMute',
      'RoutePreview': 'Route Preview',
      'RecentTrip': 'Recent Trip',
      'AlternateRoute': 'Alternate Route',
      'AddRemoveUserFavorites': 'Add/remove User Favorites',
      'AddRemoveMultiplePOI': 'Add/Remove Multiple POI',
      'ExitNavigation': 'Exit Navigation'
    },

  // List of Functional tests to execute for Functional Standard scenario
    FUNCTIONAL_NAV_TEST_CASES_STANDARD: [
      'CurrentLocation',
      'MultiTurnNearestPOI',
      'MultiTurnPOI',
      'City',
      'State',
      'PointOfInterest',
      'CustomPOI',
      'ZoomInAndZoomOut',
      'MuteAndUnMute',
      'RoutePreview',
      'RecentTrip',
      'AlternateRoute',
      'AddRemoveUserFavorites',
      'AddRemoveMultiplePOI',
      'ExitNavigation'
    ],

    // List of Functional tests to execute for Functional Standard scenario
    FUNCTIONAL_TEST_CASES_STANDARD: [
      'SetAlarm',
      'SetAlarmMultiTurn',
      'SetAlarmCancel',
      'SetAlarmBargeIn',
      'SetTimer',
      'SetNamedTimer',
      'SetTimerBargeIn',
      'ActiveTimerBargeIn',
      'SetReminderMultiTurn',
      'VolumeUp',
      'VolumeDown',
      'Mute',
      'Unmute',
      'ReadNotification',
      'NotificationBargeIn',
      'FlashBriefing',
      'BargeIn',
      'AddToShoppingList',
      'SportsUpdate',
      'EqualizerAdjustBass',
      'EqualizerAdjustTreble',
      'WWVerification',
      'EchoCancellation'
    ],

    FUNCTIONAL_AUTOMOTIVE_TEST_CASES_STANDARD: [
      'SetAlarm',
      'SetAlarmMultiTurn',
      'SetAlarmCancel',
      'SetAlarmBargeIn',
      'SetTimer',
      'SetNamedTimer',
      'SetTimerBargeIn',
      'ActiveTimerBargeIn',
      'SetReminderMultiTurn',
      'VolumeUp',
      'VolumeDown',
      'Mute',
      'Unmute',
      'ReadNotification',
      'NotificationBargeIn',
      'FlashBriefing',
      'BargeIn',
      'AddToShoppingList',
      'SportsUpdate',
      'WWVerification',
      'EchoCancellation'
    ],

    // Map containing mapping from category ID to display friendly category name
    FUNCTIONAL_TEST_CATEGORY_DISPLAY: {
      'Volume': 'Volume',
      'Alarm': 'Alarm',
      'Notifications': 'Notifications',
      'Shopping': 'Shopping',
      'Reminder': 'Reminder',
      'Timer': 'Timer',
      'BargeIn': 'Barge In',
      'FlashBriefing': 'Flash Briefing',
      'SportsUpdate': 'Sports Update',
      'Equalizer': 'Equalizer',
      'WWVerification': 'Cloud Based WW Verification',
      'EchoCancellation': 'Acoustic Echo Cancellation'
    },

    // Map containing mapping from category ID to display friendly category name
    FUNCTIONAL_NAV_TEST_CATEGORY_DISPLAY: {
      'LocationInteraction': 'Location Interaction',
      'Navigate': 'Navigate',
      'MapInteraction': 'Map Interaction',
      'WayPoint': 'WayPoint',
      'ExitNavigation': 'Exit Navigation',
    },

    TEST_STATUS_VALUES: {
      SUCCEEDED: 'Passed',
      FAILED: 'Failed',
      ERROR: 'Error',
      IN_PROGRESS: 'Running',
      EVALUATING_RESULT: 'Running',
      QUEUED: 'Queued',
      NOT_APPLICABLE: 'N/A',
      SKIPPED: 'Skipped',
      IGNORED: 'Ignored'
    },

    COMPLETED_STATES: ['SUCCEEDED', 'FAILED', 'ERROR', 'COMPLETED'],

    // Map containing styles & Icons to display functional test status
    TEST_STATUS_MAP: {
      'Passed': {
        testStatusClass: 'awsui-util-status-positive nowrap-style',
        testStatusIcon: 'status-positive',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-passed-test-status'},
      'Failed': {
        testStatusClass: 'awsui-util-status-negative nowrap-style',
        testStatusIcon: 'status-negative',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-failed-test-status'},
      'Error': {
        testStatusClass: 'awsui-util-status-negative nowrap-style',
        testStatusIcon: 'status-warning',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-error-test-status'},
      'Running': {
        testStatusClass: 'awsui-util-status-info nowrap-style',
        testStatusIcon: 'status-in-progress',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-running-test-status'},
      'Queued': {
        testStatusClass: 'awsui-util-status-info nowrap-style',
        testStatusIcon: 'status-pending',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-pending-test-status'},
      'N/A': {
        testStatusClass: 'awsui-util-status-normal nowrap-style',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-not-applicable-test-status'}
    },

    // Map containing styles & Icons to display functional test case utterances
    UTTERANCE_STATUS_MAP: {
      'SUCCEEDED': {
        testStatusClass: 'awsui-util-status-positive wrap-text-style',
        testStatusIcon: 'status-positive',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-passed-test-status'},
      'FAILED': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style',
        testStatusIcon: 'status-negative',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-failed-test-status'},
      'IN_PROGRESS': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-in-progress',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-running-test-status'},
      'QUEUED': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-pending',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-pending-test-status'},
      'INFO': {
        testStatusClass: 'wrap-text-style',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs'},
      'INFO_OVERALL': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-info',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs'},
      'NOT_APPLICABLE': {
        testStatusClass: 'awsui-util-status-normal wrap-text-style',
        testStatusIcon: 'status-warning',
        variant: 'subtle',
        testStatusTextStyle: 'awsui-util-ml-xs functional-not-applicable-test-status'},
      'SKIPPED': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-stopped',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-pending-test-status'},
      'IGNORED': {
        testStatusClass: 'awsui-util-status-info wrap-text-style',
        testStatusIcon: 'status-stopped',
        variant: 'normal',
        testStatusTextStyle: 'awsui-util-ml-xs functional-pending-test-status'}
    },

    // Mapping for FRR & RAR score
    UTTERANCE_METRIC_MAPPING: {
      0: 'FAILED',
      1: 'SUCCEEDED'
    },

    // Mapping to display reference mic status on Live run page
    REF_MIC_STATUS: {
      true: {
        displayText: 'Online',
        displayStyle: 'ref-mic-online-style'
      },
      false: {
        displayText: 'Offline',
        displayStyle: 'ref-mic-offline-style'
      },
      'Unavailable': {
        displayText: 'Retrieving ...',
        displayStyle: 'ref-mic-unavailable-style'
      }
    }
  }

  export default FunctionalTestCases;
