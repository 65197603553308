import {
  validateInput, validateInputOptional, logToConsole, skipValidateBasedOnTestInformation, isA4PCDevice
}
  from '../../Util';
import AppConstants from '_Constants/AppConstants';
import MusicConstants from '_Constants/MusicConstants';
import Utilities from './Utilities';
import QualHelpers from './Qual/Helpers';
import CustomTestHelpers from './CustomTest/Helpers';
import CustomOptionsConstants from '../../_Constants/CustomOptionsConstants';
import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';
import AutoLocalSearchTestConstants from '../../_Constants/AutoLocalSearchTestConstants';
import { generateMapping } from './TestRunHelpers';
import { get_resources_required_by_location } from '../../Util/AQTTestOptionsParser';
import { sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier, sendRawInfoToNewRunWithStateId } from './NewRunDataTransferUtils';
import {
  getErrorTextUtteranceRange, isInputOptional
} from './Util';
import { validateDeviceMapping } from './MappingGeneratorUtil';
import ld from 'lodash';
import MobileConstants from "../../_Constants/MobileConstants";

const MIN_KEYS_IN_CUSTOM_OPTIONS = 4; // what's the use? maybe validate customOptions key length

/**
 * onSmokeClickValidate original
 * TODO: validate for all test types, fill in parameters to be submitted to test run
 * onSmokeClickValidate
 * @param {*} selectedTestGroupInfo
 * @param {*} selectedTestGroupInfoError
 * @param {*} selectedTestGroupInfoErrorCallBack
 * @param {*} selectedScenario
 * @param {*} selectedScenarioCallBack
 * @param {*} selectedScenarioError
 * @param {*} scenarioErrorCallBack
 * @param {*} otherParams
 * @param {*} updateOtherParamsCallBack
 * @param {*} otherParamsError
 * @param {*} updateNewRunStateParameterCallBack
 */
export async function onAddTestClickValidate(selectedTestGroupInfo,
  selectedTestGroupInfoError,
  selectedTestGroupInfoErrorCallBack,
  selectedScenario,
  selectedScenarioCallBack,
  selectedScenarioError,
  scenarioErrorCallBack,
  otherParams,
  updateOtherParamsCallBack,
  otherParamsError,
  updateNewRunStateParameterCallBack) {
  // Generate mapping
  // need to wait until it's finished
  await generateMapping(selectedTestGroupInfo, selectedScenario, otherParams, updateOtherParamsCallBack);
  let submit = true;
  const identifier = 'ValidateStateBeforeAddTest, onAddTestClickValidate';
  if (selectedScenario.testSuite === AppConstants.MOBILE_SUITE_ID &&
    selectedScenario.scenarioType === AppConstants.MOBILE_FUNCTIONAL &&
    selectedScenario.testCategory === MobileConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) {

    let enabled = selectedScenario.functionalTestCases.indexOf("Responds while Locked Enabled");
    let disabled = selectedScenario.functionalTestCases.indexOf("Responds while Locked Disabled");

    if (selectedScenario.lockType === "PIN_LOCK") {
      if (enabled != -1) {
        selectedScenario.functionalTestCases[enabled] = "Pin-Lock Respond On";
      }
      if (disabled != -1) {
        selectedScenario.functionalTestCases[disabled] = "Pin-Lock Respond Off";
      }
    }
    if (selectedScenario.lockType === "SWIPE_LOCK") {
      if ( enabled != -1) {
        selectedScenario.functionalTestCases[enabled] = "Swipe-Lock Respond On";
      }
      if (disabled != -1) {
        selectedScenario.functionalTestCases[disabled] = "Swipe-Lock Respond Off";
      }
    }

  }
  /**
   * Modify the updatedScenario object and update state object in NewRun at the end.
   */
  let updatedScenario = selectedScenario;
  let updatedScenarioSelectionError = selectedScenarioError;
  updatedScenario.testOptions.resourcesRequired = get_resources_required_by_location(
    selectedScenario.testSuite,
    selectedScenario.scenarioType, selectedScenario.testType,
    selectedScenario.testOptions.customOptions, selectedScenario.marketPlace
  );
  updatedScenario.testOptions.scenarioType = selectedScenario.scenarioType;
  updatedScenario.testOptions.customOptions.deviceName = selectedTestGroupInfo.deviceName;
  updatedScenario.testOptions.customOptions.amazonId = selectedTestGroupInfo.deviceTypeId;
  updatedScenario.testOptions.customOptions.musiciHeartEmailAddr = selectedScenario.musiciHeartEmailAddr;
  updatedScenario.testOptions.testSuite = selectedScenario.testSuite;

  if ((AppConstants.SCENARIO_WITH_TEST_TYPES.includes(selectedScenario.scenarioType)
      || MusicConstants.MUSIC_SCENARIOS.includes(selectedScenario.scenarioType))
    && selectedScenario.testCategory !== AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION
    && !selectedScenario.testTypeArr.includes(true)){
    submit = false;
    updatedScenarioSelectionError.testTypeArr = true;
  }

  //TODO: deal with FAR custom later
  if (selectedScenario.scenarioType === AppConstants.FAR_CUSTOM) {
    submit = validateDeviceMappingHelper(otherParams, otherParamsError, updateNewRunStateParameterCallBack,
      selectedScenarioError, scenarioErrorCallBack);
  }

  if (selectedScenario.testSuite === AppConstants.QUAL_SCENARIO_ID) {
    submit = QualHelpers.isValidQualTestOptions(selectedScenario, selectedScenarioCallBack);
    if (submit) {
      updatedScenario = {
        ...updatedScenario,
        testOptions: {
          ...updatedScenario.testOptions,
          spl: Utilities.getSPLSettings(updatedScenario)
        }
      };
    }
  }

  if (selectedScenario.testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) {
    const { isValid, errors = {} } = CustomTestHelpers.validateActorLabMapping(selectedScenario.customTestOptions);
    submit = isValid;
    updatedScenarioSelectionError.customTestOptions = errors;
    if (submit) {
      const options = updatedScenario.customTestOptions;
      updatedScenario = {
        ...updatedScenario,
        testOptions: {
          ...updatedScenario.testOptions,
          config: {
            ...options.config,
            actorLabMapping: options.actorLabMapping,
            testDefinitionUri: options.testDefinitionUri,
            id: options.id,
            name: options.name,
            description: options.description
          },
        },
        labDependenciesUri: options.labDependenciesUri
      };
    }
  }

  // If it's a Customized test, Custom options should contain at lest one more key
  // besides just customizedTestType
  if ((selectedScenario.farFieldCustomOptionsSelected
    || selectedScenario.nearFieldCustomOptionsSelected
    || selectedScenario.trainedMobileCustomOptionsSelected
    || selectedScenario.closeTalkCustomOptionsSelected
    || selectedScenario.automotiveCustomOptionsSelected
      || selectedScenario.mobileFunctionalCustomOptionsSelected)
    && Object.keys(selectedScenario.testOptions.customOptions).length < MIN_KEYS_IN_CUSTOM_OPTIONS) {
    submit = false;
    updatedScenarioSelectionError.testOptions = true;
  }

  // Validate testCategory and testType fields for Mobile Functional scenario
  if (selectedScenario.scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
    if (!selectedScenario.testCategoryArr.includes(true)) {
      submit = false;
      updatedScenarioSelectionError.testCategoryArr = true;
    }
    if (selectedScenario.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION
      && !selectedScenario.lockTypeArr.includes(true)) {
      submit = false;
      updatedScenarioSelectionError.lockTypeArr = true;
    }
    updatedScenario.testOptions['testCategory'] = selectedScenario.testCategory;
    updatedScenario.testOptions['testType'] = selectedScenario.testType;
  }

  // set testType if not set
  if (!AppConstants.SCENARIO_WITH_TEST_TYPES.includes(selectedScenario.scenarioType)
    && !MusicConstants.MUSIC_SCENARIOS.includes(selectedScenario.scenarioType)) {
    let testType = !selectedScenario.testType ? AppConstants.STANDARD : selectedScenario.testType;
    let isAcousticSuite = selectedScenario.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID;
    let isCloseTalkSuite = selectedScenario.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID;
    let isMobileSuite = selectedScenario.testSuite === AppConstants.MOBILE_SUITE_ID;
    let isAutoLocalSearchSuite = selectedScenario.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID;
    // Only if scenario type is FAR, FAR Custom or WWDD, assign test type same
    // as scenario type
    if (isAcousticSuite || isCloseTalkSuite || isMobileSuite || isAutoLocalSearchSuite) {
      testType = selectedScenario.scenarioType;
    }
    updatedScenario.testType = testType;
  }

  let whetherToSubmitBasedOnFieldValidation = validateEachField(selectedTestGroupInfo,
    selectedTestGroupInfoError,
    selectedScenario,
    selectedScenarioError,
    otherParams,
    updateOtherParamsCallBack,
    scenarioErrorCallBack,
    selectedTestGroupInfoErrorCallBack,
    selectedScenarioCallBack);

  if (! whetherToSubmitBasedOnFieldValidation) {
    submit = false;
  }

  // additional verifications for trained mobile
  if (selectedScenario.trainedMobileCustomOptionsSelected) {
    let trainersListEmpty = false;
    let schemeDefEmpty = false;
    if (selectedScenario.trainedUsers.length === 0) {
      submit = false;
      trainersListEmpty = true;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!selectedScenario.testOptions.customOptions.hasOwnProperty(CustomOptionsConstants.SCHEME_DEF_KEY)) {
      submit = false;
      schemeDefEmpty = true;
    }
    updatedScenarioSelectionError.trainedUsers = trainersListEmpty;
    updatedScenarioSelectionError.testOptions = schemeDefEmpty;
  }

  if ( selectedScenario.mobileVoiceRobustnessCustomOptionsSelected ) {
    let trainersListEmpty = false;
    let noisesListEmpty = false;
    let schemeDefEmpty = false;
    if (selectedScenario.trainedUsers.length === 0 || selectedScenario.noiseSelection.length === 0) {
      submit = false;
      trainersListEmpty = true;
      noisesListEmpty = true;

    }
    // eslint-disable-next-line no-prototype-builtins
    if (!selectedScenario.testOptions.customOptions.hasOwnProperty(CustomOptionsConstants.SCHEME_DEF_KEY)) {
      submit = false;
      schemeDefEmpty = true;
    }
    updatedScenarioSelectionError.trainedUsers = trainersListEmpty;
    updatedScenarioSelectionError.testOptions = schemeDefEmpty;
    updatedScenarioSelectionError.noiseSelection = noisesListEmpty;
  }

  if (selectedScenario.scenarioType === FunctionalTestCases.FUNC_CUSTOM
    || selectedScenario.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM
    || selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM
    || selectedScenario.mobileFunctionalCustomOptionsSelected
    || selectedScenario.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) {
    let isFunctionalTestCasesEmpty = selectedScenario.functionalTestCases.length === 0;
    if (isFunctionalTestCasesEmpty) {
      submit = false;
    }
    updatedScenarioSelectionError.functionalTestCases = isFunctionalTestCasesEmpty;
  }

  // check if at least one of Test cases selected for wakeword in case of customized options
  if (selectedScenario.acousticWakewordCustomOptionsSelected) {
    let isFunctionalTestCasesEmpty = selectedScenario.functionalTestCases.length === 0;
    if (isFunctionalTestCasesEmpty) {
      submit = false;
    }
    updatedScenarioSelectionError.functionalTestCases = isFunctionalTestCasesEmpty;
  }

  // Music Test Suite specific operations
  if (selectedScenario.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
    // For Music test suite, Quick test is nothing but Smoke test
    if (selectedScenario.testType === AppConstants.QUICK) {
      updatedScenario.testType = AppConstants.SMOKE;
    }
    // Enabling proxy option for music test suite
    let isProxyEnabled = selectedScenario.isProxyEnabled;
    updatedScenario.testOptions.customOptions.isProxyEnabled = isProxyEnabled;
    // Enabling 3PDA option for music test suite
    let is3PDAEnabled = selectedScenario.is3PDAEnabled;
    updatedScenario.testOptions.customOptions.is3PDAEnabled = is3PDAEnabled;
    // If it is a 3PDA test, do not allow the user to "Add test" if they did not enter firmware (buildInfo) or select a 3PDA device
    if (is3PDAEnabled) {
      if(!selectedScenario.buildInfo) {
        submit = false;
        selectedScenarioError.deviceMappingError = AppConstants.MISSING_BUILD_INFO_ERROR_MICLESS;
      }
      else{
        selectedScenarioError = AppConstants.EMPTY;
      }
      for (var dutKey in otherParams.deviceMapping) {
        var dutValues = otherParams.deviceMapping[dutKey];
        if (Object.keys(dutValues).length > 0) {
          if (!dutValues.hasOwnProperty('dsn')){
            submit = false;
            selectedScenarioError.deviceMappingError = AppConstants.MISSING_DSN_ERROR_MICLESS;
          }
        }
      }
    }
  }

  // Morgan - Add isProcessAudioFiles
  if (selectedScenario.testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID) {
    updatedScenario.testOptions.customOptions.useCustomNoises = selectedScenario.useCustomNoises;
  }

  // If the test suite is Security, only fields needs to be validated are:
  // 1. CID
  // 2. DSN
  // 3. labId
  // 4. Test Name
  if (selectedScenario.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
    updatedScenario.marketPlace = AppConstants.MARKETPLACE_EN_US;
    submit = selectedTestGroupInfo.customerId !== AppConstants.EMPTY
      && selectedTestGroupInfo.dsn !== AppConstants.EMPTY
      && selectedTestGroupInfo.labId !== AppConstants.EMPTY
      && selectedTestGroupInfo.testName !== AppConstants.EMPTY;
  }

  // TODO: deal with multimodal validation shortly
  // TODO: POC Only. Multimodal Scenario Type is not public yet.
  // Currently in AQT If this is Multimodal functional scenario, send "Custom Command" in Custom Options
  /* if (selectedScenario.scenarioType === AppConstants.MULTIMODAL_SCENARIO_ID) {
    let multiModalCustomCommand = this.state.testParams.multiModalCustomCommand;
    this.setState({
      testParams: {
        ...this.state.testParams,
        multiModalCustomCommand: AppConstants.EMPTY,
        testOptions: {
          ...selectedScenario.testOptions,
          customOptions: {
            ...selectedScenario.testOptions.customOptions,
            multiModalCustomCommand: multiModalCustomCommand,
            functionalTestCases: []
          }
        }
      }
    });
  } */

  // If this is functional test, send "isMultimodalDevice" in Custom Options
  if (selectedScenario.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
    let isMultimodalDevice = selectedScenario.isMultimodalDevice;
    let updatedScenario = selectedScenario;
    updatedScenario.testOptions.customOptions.isMultimodalDevice = isMultimodalDevice;
    // If this is Navigation test, send "Spoofed Location" in Custom Options
   if (selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ||
    selectedScenario.scenarioType === FunctionalTestCases.FUNC_NAV_ALL) {
     updatedScenario.testOptions.customOptions.spoofedLocation = selectedScenario.spoofedLocation;
   }
    sendRawInfoToNewRunWithIdentifier(identifier, updatedScenario, selectedScenarioCallBack);
  }

  // TODO: Auto Sync Resource should be Test group wise
  // If this is Acoustic scenario, send proxy & auto-sync settings value in custom options
  // Acoustic Specific operations
  if (selectedScenario.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID) {
    let isProxyEnabled = selectedScenario.isProxyEnabled;
    let isAutoSyncEnabled = selectedScenario.isAutoSyncEnabled;

    updatedScenario.testOptions.customOptions.isProxyEnabled = isProxyEnabled;
    updatedScenario.testOptions.customOptions.isAutoSyncEnabled = isAutoSyncEnabled;
    updatedScenario.testOptions.customOptions.disablePlaybackSkill = selectedScenario.disablePlaybackSkill;
  }

  // If this is UDT scenario, send proxy value in custom options
  if (selectedScenario.testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) {
    let isProxyEnabled = selectedScenario.isProxyEnabled;
    let isAutoSyncEnabled = selectedScenario.isAutoSyncEnabled;
    updatedScenario.testOptions.customOptions.isAutoSyncEnabled = isAutoSyncEnabled;
    updatedScenario.testOptions.customOptions.isProxyEnabled = isProxyEnabled;
    // Update values for UDT
    if (selectedScenario.hasOwnProperty('fallBackToUtterancePlay')) {
      updatedScenario.testOptions.customOptions.fallBackToUtterancePlay = selectedScenario.fallBackToUtterancePlay;
    }
  }

  // If this is a Customized test, set Delay between utterances
  if (
    selectedScenario.testType === AppConstants.CUSTOMIZED &&
    selectedScenario.isDelayBetweenUtterancesChecked &&
    selectedScenario.delayBetweenUtterances > 0
  ) {
    const delayBetweenUtterances = selectedScenario.delayBetweenUtterances;
    updatedScenario.delayBetweenUtterances = delayBetweenUtterances;
    updatedScenario.testOptions.customOptions.delayBetweenUtterances = delayBetweenUtterances;
  }

  updatedScenario.testOptions.customOptions.deviceVirtualAssistant = selectedScenario.deviceVirtualAssistant;
  updatedScenario.testOptions.customOptions.disableAdminCheck = selectedScenario.disableAdminCheck;
  updatedScenario.testOptions.customOptions.retryEnabled = selectedScenario.retryEnabled;
  updatedScenario.testOptions.customOptions.disablePlaybackSkill = selectedScenario.disablePlaybackSkill;

  // If this is the Mobile Voice Training scenario, send manual vs automated voice training settings in payload
  // set disablePlaybackSkill in custom Options
  if (selectedScenario.testSuite === AppConstants.MOBILE_SUITE_ID &&
    selectedScenario.scenarioType === AppConstants.TRAINED_MOBILE) {
    let mobileVoiceTrainingIsManual = selectedScenario.mobileVoiceTrainingIsManual;
    updatedScenario.testOptions.customOptions.mobileVoiceTrainingIsManual = mobileVoiceTrainingIsManual;
    updatedScenario.testOptions.customOptions.disablePlaybackSkill = selectedScenario.disablePlaybackSkill;
    updatedScenario.testOptions.customOptions.isDevice1PSV = selectedScenario.isDevice1PSV;
  }

  // Validate range of utterances if this is Auto Local Search Full test scenario &
  // custom utterance range checkbox is checked
  if (selectedScenario.isUtteranceRangeSet &&
    selectedScenario.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID &&
    selectedScenario.scenarioType === AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_FULL) {
    let errorTextUtteranceRange = getErrorTextUtteranceRange(selectedScenario.fromTranscriptNum,
      selectedScenario.toTranscriptNum);
    submit = submit && errorTextUtteranceRange === AppConstants.EMPTY;
    updatedScenarioSelectionError.transcriptRangeError = errorTextUtteranceRange;
  }

  // If this is the Auto Local Search test suite & scenario is Full, then set from and to range
  // for utterances to be played in custom options
  if (submit
    && selectedScenario.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
    && selectedScenario.scenarioType === AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_FULL) {
    updatedScenario.testOptions.customOptions.fromTranscriptNum = selectedScenario.fromTranscriptNum;
    updatedScenario.testOptions.customOptions.toTranscriptNum = selectedScenario.toTranscriptNum;
  }

  /**
   * Add WakewordModel to the testOptions if it is A4PC device
   */
  if (isA4PCDevice(selectedTestGroupInfo.deviceTypeId)) {
    updatedScenario.testOptions.customOptions.a4pcConfig = {
      'a4pcWakewordModel': ld.get(selectedScenario, 'a4pcWakewordModel', AppConstants.EMPTY),
      'aqtA4PCCompanionAppEnabled': ld.get(selectedTestGroupInfo, 'aqtA4PCCompanionAppEnabled', false),
      'a4pcDeviceConfigS3URL': ld.get(selectedTestGroupInfo, 'deviceConfig', AppConstants.EMPTY)
    }
  }

  sendRawInfoToNewRun(updatedScenario, selectedScenarioCallBack);
  sendRawInfoToNewRun(updatedScenarioSelectionError, scenarioErrorCallBack);

  logToConsole('TestLog - SelectedScenarios before submit = ' + JSON.stringify(selectedScenario));
  logToConsole('TestLog - selectedTestGroupInfo before submit = ' + JSON.stringify(selectedTestGroupInfo));
  logToConsole('TestLog - selectedScenarioError before submit = ' + JSON.stringify(selectedScenarioError));
  logToConsole('TestLog - otherParams before submit = ' + JSON.stringify(otherParams));
  return {
    isValid: submit,
    params: {
      selectedScenario: updatedScenario
    }
  };
}

// TODO: FAR Custom
/**
 * Validates device mapping before submitting the test
 * @return Returns true or false based on device mapping is valid or not
 * looks like redundant with MappingGeneratorUtil
 */
function validateDeviceMappingHelper(otherParams, otherParamsError, updateNewRunStateParameterCallBack,
  selectedScenarioError, scenarioErrorCallBack ) {
  const identifier = 'ValidateStateBeforeAddTest, validateDeviceMappingHelper';
  let deviceMapping = otherParams.deviceMapping;
  let submit = true;
  for (var dutKey in deviceMapping) {
    var dutKeyValue = deviceMapping[dutKey];
    for (var dutFieldKey in dutKeyValue) {
      var invalidInput = validateInputOptional(dutFieldKey, dutKeyValue[dutFieldKey]);
      if (invalidInput) {
        submit = false;
        this.setState({
          error: { ...this.state.error, [dutKey + '_' + dutFieldKey]: AppConstants.INPUTERR_INVALID_INPUT }
        });
        let updatedOtherParamsError = otherParamsError;
        updatedOtherParamsError[dutKey + '_' + dutFieldKey] = AppConstants.INPUTERR_INVALID_INPUT;
        sendRawInfoToNewRunWithStateId(identifier, updatedOtherParamsError, 'otherParamsError', updateNewRunStateParameterCallBack);
      }
    }
  }

  let errorMessage = validateDeviceMapping(deviceMapping);
  let updatedSelectedScenarioError = selectedScenarioError;
  updatedSelectedScenarioError.deviceMappingError = errorMessage;
  sendRawInfoToNewRunWithIdentifier(identifier, updatedSelectedScenarioError, scenarioErrorCallBack);
  return (errorMessage === AppConstants.EMPTY) ? submit : false;
}

/**
 * TODO: remove unused fields
 * Mainly deal with the errors during scenario selection
 * Hold for debugging in case other situations needs to be validated as well.
 * @param {*} selectedTestGroupInfo
 * @param {*} selectedScenario
 * @param {*} selectedScenarioError
 * @param {*} otherParams
 * @param {*} updateOtherParamsCallBack
 * @param {*} scenarioErrorCallBack
 * @param {*} selectedScenarioCallBack
 */
function validateEachField(selectedTestGroupInfo,
  selectedTestGroupInfoError,
  selectedScenario,
  selectedScenarioError,
  otherParams,
  updateOtherParamsCallBack,
  scenarioErrorCallBack,
  selectedTestGroupInfoErrorCallBack,
  selectedScenarioCallBack) {

  let testGroupInfoSlectionOK = validateFieldsOfObject(selectedTestGroupInfo, selectedScenario.testSuite,
    selectedScenario.scenarioType, selectedTestGroupInfoError, selectedTestGroupInfoErrorCallBack);
  let scenarioSelectionOK = validateFieldsOfObject(selectedScenario, selectedScenario.testSuite,
    selectedScenario.scenarioType, selectedScenarioError, scenarioErrorCallBack);
  return testGroupInfoSlectionOK && scenarioSelectionOK;
}

function validateFieldsOfObject(fields, testSuite, scenarioType, error, errorCallBack) {
  let readyForSubmit = true;
  let identifier = 'ValidateStateBeforeAddTest: validateFieldsOfObject';
  Object.keys(fields).forEach(key => {
    if (typeof fields[key] === 'string') {
      if (!skipValidateBasedOnTestInformation(key, testSuite, scenarioType)) {
        let isInputInvalid = isInputOptional(key, testSuite, scenarioType)
          ? validateInputOptional(key, fields[key])
          : validateInput(key, fields[key]);
        if (isInputInvalid) {
          readyForSubmit = false;
          let updatedError = error;
          updatedError[key] = true;
          sendRawInfoToNewRunWithIdentifier(identifier, updatedError, errorCallBack);
        }
      }
    }
  });
  return readyForSubmit;
}
