import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
    fontSize: '12px'
  },
};

class FootBar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.root } align='center'>
        <Typography variant='caption' className={ classes.flex }>
          © {new Date().getFullYear()}, Amazon.com, Inc. or its affiliates
        </Typography>
      </div>
    );
  }
}

FootBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FootBar);
