export const AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS_TABLE_CONFIG = [
{
  id: 'speedLevel',
  header: () => 'Speed Level',
  cell: item => item.speedLevel,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'northAmericaRegion',
  header: () => 'North America Region',
  cell: item => item.northAmericaRegion,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, /*{
  id: 'europe',
  header: () => 'Europe',
  cell: item => item.europe,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'asia',
  header: () => 'Asia',
  cell: item => item.asia,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}*/];

export const AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS = [];

AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS.push({
  speedLevel: 'Medium',
  northAmericaRegion: '45 MPH',
  europe: 'TBD',
  asia: 'TBD'
});

AUTOMOTIVE_CUSTOM_OPTIONS_DETAILS.push({
  speedLevel: 'High',
  northAmericaRegion: '75 MPH',
  europe: 'TBD',
  asia: 'TBD'
});
