import { createSlice } from '@reduxjs/toolkit'
import { TEST_LIST_STORAGE } from '../../../constants'
import { getState } from '../../../services/localStorage'


const getInitialState = () => getState(TEST_LIST_STORAGE) || [];

export const testListSlice = createSlice({
  name: 'testsList',
  initialState: {
    tests: getInitialState()
  },
  reducers: {
    addTest: (state, action) => {
      const {id} = action.payload;
      const index = state.tests.findIndex(test => test.id === id);
      if(index > -1) {
        state.tests[index] = action.payload;
      } else {
        state.tests.push({...action.payload});
      }
    },
    deleteTest: (state) => {
      const filteredTests = state.tests.filter(test => test.isSelected === false);
      state.tests = filteredTests;
    },
    setSelectedTests: (state, action) => {
      const selectedTests = action.payload;
      if(Array.isArray(selectedTests)) {
        const selectedTestIds = selectedTests.map(tests => tests.id)
        const updatedTest = state.tests.map(test => {
          const {id} = test;
          test.isSelected = false;
          if(selectedTestIds.includes(id)) {
            test.isSelected = true;
          }
          return test;
        });

        state.tests = updatedTest;
      }
    },
  },
})

export const { addTest, deleteTest, setSelectedTests } = testListSlice.actions

export const testList = (state) => state.testList.tests
export const selectedTests = (state) => {
  let selectedTests = []
  if(Array.isArray(state.testList.tests)) {
    selectedTests = state.testList.tests.filter(
      test => test.isSelected
    )
  }
  return selectedTests;
}

export default testListSlice.reducer
