import * as React from 'react';
import { logToConsole } from '../../Util';
import CustomTestConstants from '../../_Constants/CustomTestConstants';
import { Icon, Table } from '@amzn/awsui-components-react';
import _ from 'lodash';

/**
 * Validates custom test wizard data.
 * @param {*} customTestWizard
 * @param {*} audioFile
 * @param {*} audioFilesGetUrl
 * @param {*} testDefinitionFile
 * @param {*} testDefinitionGetUrl
 */
export function validateCustomTestData(stateData) {
    let {
      customTestWizard,
      audioFile,
      audioFilesGetUrl,
      testDefinitionFile,
      testDefinitionGetUrl,
      transcriptValidationResult
    } = stateData;
    let validationMessage = '';
    let errorCount = 0;

    try {

      // Test must have a name
      if (!customTestWizard.name || customTestWizard.name.length == 0) {
        validationMessage += ' User defined test name is required.'
        errorCount++;
      }

      // Test definition is required
      if (testDefinitionFile == undefined && testDefinitionGetUrl == undefined) {
        validationMessage += ' .csv file containing the test definition sequence is required.'
        errorCount++;
      }

      // For non-polly audio files must be provided
      if (!customTestWizard.autoSynthesizeAudio &&
        (_.isNil(audioFile) && _.isEmpty(audioFilesGetUrl))) {
        validationMessage += ' .zip file containing test audio files is required.'
        errorCount++;
      }

      // For polly both wake word and delay must be specified
      if (customTestWizard.autoSynthesizeAudio && 
        (!customTestWizard.wwDelay || !customTestWizard.wakeWord)) {
        validationMessage += ' Wake word and wake word delay must be defined for speech auto synthesis.'
        errorCount++;
      }

      // If Locale is not selected for polly
      if (customTestWizard.autoSynthesizeAudio && !customTestWizard.marketPlace) {
        validationMessage += ' Locale must be selected.'
        errorCount++;
      }

      // Noise and speech locations must be different
      if (customTestWizard.actorCount > 1 && customTestWizard.noiseFile && customTestWizard.noiseFile.length > 0
          && customTestWizard.noiseLocationPi == customTestWizard.speechLocationPi) {
            validationMessage += ' Noise pi must be different from speech pi.'
            errorCount++;
        }
    
      // Noise location must be chosen if noise file is input
      if (customTestWizard.actorCount > 1 && ((customTestWizard.noiseFile && !customTestWizard.noiseLocationPi)
          || (!customTestWizard.noiseFile && customTestWizard.noiseLocationPi)) ) {
            validationMessage += ' Noise location and noise file are required to play noise.'
            errorCount++;
        }

      if (transcriptValidationResult && !transcriptValidationResult.isSuccess) {
        validationMessage += ' Transcript Files has errors. Please fix it and upload the file'
        errorCount++;
      }

    } catch(error) {
      logToConsole("Error validating custom test wizard input");
    }

    let validationResult = {
      isValid: errorCount == 0,
      customTestWizardAlert: {
        header: 'Validation Result',
        content: errorCount > 0 ? validationMessage : 'Validation successful',
        type: errorCount > 0 ? 'error' : 'success'
        }
    }

    return validationResult;
  }

  /**
   * Retrieves custom test records and initializes state with key, value with customTestId being key
   * corresponding data as value.
   */
  export function getCustomTestRecords(customScenarios, viewOrEditButton, deleteCustomTestButton, summaryButton, logsButton) {
    let customTestRecords = [];
    if (customScenarios && customScenarios.data) {
      let data = customScenarios.data;
      Object.keys(data).forEach(customTestId => {
        let customTest = data[customTestId];
        customTest.viewOrEditButton = viewOrEditButton;
        customTest.deleteButton = deleteCustomTestButton;
        customTest.summaryButton = summaryButton;
        customTest.logsButton = logsButton;
        customTestRecords.push(customTest);
      });
    }
    return customTestRecords;
  }

  /**
   * Gets pop over summary table to view
   * @param {object} customTestData custom test model object
   * @param {object} validationResults validation result JSON 
   */
  export function getPopOverContent(customTestData, validationResults) {
    if (validationResults && validationResults.hasOwnProperty([customTestData.id])
        && !validationResults[customTestData.id].isLoading
        && validationResults[customTestData.id].validationDetails) {
      
        let validationDetails = validationResults[customTestData.id].validationDetails;
        let summaryInfo = [{
          label: "Status",
          value: validationDetails.success ? "Success" : "Failed"
        },
        {
          label: "Total",
          value: validationDetails.total ? validationDetails.total : 0
        },
        {
          label: "Failed",
          value: validationDetails.failed ? validationDetails.failed : 0
        },
        {
          label: "Missing File Names",
          value: validationDetails.missingFileNames ? validationDetails.missingFileNames : 0
        },
        {
          label: "Missing ASR",
          value: validationDetails.missingAsrTexts ? validationDetails.missingAsrTexts : 0
        },
        {
          label: "Missing Audio",
          value: validationDetails.missingAudioFiles ? validationDetails.missingAudioFiles : 0
        },
        {
          label: "Messages",
          value: validationDetails.messages ? validationDetails.messages : "None"
        }];

      return (
        <Table variant="borderless" 
          columnDefinitions={ CustomTestConstants.VALIDATION_SUMMARY_COLUMNS }
          items={summaryInfo}>
        </Table>
      )
      
    } else {
      return (
        <span className="awsui-util-status-info">
          <Icon variant="link" name="status-info" /> Validation details not available.
        </span>
      )
    }
  }
