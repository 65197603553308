export const DeviceOnlineStatus = {
    ONLINE: "ONLINE",
    OFFLINE: "OFFLINE",
    UNKNOWN: "UNKNOWN"
}

export const DeviceOnlineStatusDisplay = {
    ONLINE: "Online",
    OFFLINE: "Offline",
    UNKNOWN: "Status Unknown"
}