import { configureStore } from '@reduxjs/toolkit'
import taskListSlice from './newRun/testList/testListSlice';
import labConfigSlice from './newRun/acm/labConfigSlice';
import testInitSlice from './newRun/testInitSlice';
import scenarioSelection from './newRun/acm/scenarioSelectionSlice';
import deviceAttributesSlice from './newRun/wwa/deviceAttributesSlice';
import labSlice from './newRun/labSlice';
import customerIdSlice from './newRun/customerIdSlice';
import piSlice from './newRun/piSlice';
import createTestUiSlice from './newRun/acm/uiSlice';
import deviceListSlice from './newRun/deviceListSlice';
import scenariosSlice from './newRun/scenariosSlice';
import testListUiSlice from './newRun/testList/testListUiSlice';
import testMappingSlice from './newRun/mappingSlice';
import testOptsSlice from './newRun/testOptsSlice';

const reducerConfig = {
  testList: taskListSlice,
  acmLabConfig: labConfigSlice,
  acmScenarioSlection: scenarioSelection,
  wwaScenarioSelection: deviceAttributesSlice,
  testInit: testInitSlice,
  labList: labSlice,
  customerIds: customerIdSlice,
  piList: piSlice,
  testMapping: testMappingSlice,
  createTestUi: createTestUiSlice,
  devicesList: deviceListSlice,
  scenariosList: scenariosSlice,
  testListUi: testListUiSlice,
  testOpts: testOptsSlice,
}

export default configureStore({
  reducer: reducerConfig,
})


export const setupStore = preloadedState => {
  return configureStore({
    reducer: reducerConfig,
    preloadedState
  })
}
