import React, { PureComponent } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import './index.css';

class NotFound extends PureComponent {
  render() {
    return (
      <div className="container">
        <div className="header-content">{`404 - Page Not Found`}</div>
        <div>
          <AWSUI.Button
          href="/"
          iconAlign="right"
          iconName="external"
          >
            {`Go to New Run Page`}
          </AWSUI.Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
