import { FormSection, ColumnLayout, FormField, Select, Alert, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { API_STATUS } from '../../../../constants';
import { labConfigSelector } from '../../../../stores/newRun/acm/labConfigSlice';
import { customerIdSelector } from '../../../../stores/newRun/customerIdSlice';
import { dutListSelector } from '../../../../stores/newRun/deviceListSlice';
import DeviceConfigWrapper from './devicePiConfig';
import { get } from 'lodash';
import { handleCustomerIdChange, setupInitalDeviceConfig } from '../../../../stores/newRun/acm/testCreationMiddleware';



export default ({ devAccMap, account }) => {

  const {accountConfig, error: labValidationErr} = useSelector(labConfigSelector);
  const {customerIds, status: cidStatus} = useSelector(customerIdSelector);
  const {status: dutStatus, dutList, error: dutFetchError } = useSelector(dutListSelector);
  const selectedCustomerInfo = get(accountConfig, `${account}.customerIdInfo`, {}) || {};
  const selectedDevicePiConfigs = get(accountConfig, `${account}.devicePiConfig`, []) || [];
  const numberOfDevices = devAccMap.length;
  const dispatch = useDispatch();
  const intl = useIntl();
  const {ERROR, SUCCESS, PENDING} = API_STATUS;
  useEffect(
    () => {
      dispatch(setupInitalDeviceConfig(account));
    }, []
  );

  const handleCidChange = (e) => {
    const cIdInfo = e.detail.selectedOption.meta;
    dispatch(handleCustomerIdChange(cIdInfo, account));

  }

  let cIdOptions = [];
  if(cidStatus === API_STATUS.SUCCESS) {
    cIdOptions = customerIds.map(cId=>({ id: cId.customerId, label: cId.email, meta: cId }));
  }

  let selectCustIdOption = {};
  const {customerId, email} = selectedCustomerInfo;
  if(customerId) {
    selectCustIdOption = {id: customerId, label: email}
  }

  let deviceErrView;
  if(dutStatus === ERROR) {
    deviceErrView = <Alert type="error" className="awsui-util-mt-s">
      {dutFetchError}
    </Alert>
  }

  const deviceLabels = devAccMap.reduce((iter, {deviceType})=> {
    iter.add(deviceType);
    return iter;
  }, new Set());

  const labValidationErrForAccount = get(labValidationErr, `${account}`);

  const customerIdValidationErr = get(labValidationErrForAccount, "customerId");

  let devicesView;
  if(dutStatus === PENDING) {
    devicesView = <div className="awsui-util-mt-l">
      <Spinner /> <FormattedMessage id="LOADING_RESOURCES" />
    </div>
  } else if(dutStatus === SUCCESS) {
    devicesView = <DeviceConfigWrapper
      devAccMap={devAccMap}
      account={account}
      customerId={customerId}
      dutList={dutList}
      devicePiConfig={selectedDevicePiConfigs}
      labValidationErrForAccount={labValidationErrForAccount}
      numberOfDevices={numberOfDevices}
      />
  }


  return (
    <>
      <FormSection header={<><FormattedMessage id="ACCOUNT" /> - {account}</>}>
        <ColumnLayout>
          <Alert>
            <FormattedMessage
              id="THIS_ACCOUNT_REQUIRES_DEVICES"
              values={{
                numberDevices: numberOfDevices === 1 ? `${numberOfDevices} device` : `${numberOfDevices} devices`,
                deviceList: [...deviceLabels].join(', ')
              }}
              />
          </Alert>

          <div className="awsui-util-mt-xl">
            <FormField label={<FormattedMessage id="CUSTOMER_ID" />}  description={<FormattedMessage id="SELECT_CUSTOMER_ID" />}>
              <Select
                options={cIdOptions}
                onChange={handleCidChange}
                selectedOption={selectCustIdOption}
                loading={cidStatus === PENDING}
                selectedLabel="Selected"
                invalid={!!customerIdValidationErr}
                />
                {
                  customerIdValidationErr ? <div className='formErrMsg'>{customerIdValidationErr}</div> : null
                }
            </FormField>
            {deviceErrView}
            {devicesView}
          </div>
        </ColumnLayout>
      </FormSection>
    </>
  )
}
