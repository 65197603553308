import constants from '../constants/auth';
import apiUtils from 'config/apiUtils';

/**
 * Sets app environment
 * @param {Object} environment
 */
export function setEnvironment(environment) {
  return (dispatch) => {
    return dispatch({
      type: constants.SET_ENVIRONMENT,
      payload: environment
    });
  }
}

/**
 * Set user session information
 * @param {Object} session
 */
export function setSession(session) {
  return (dispatch) => {
    return dispatch({
      type: constants.SET_SESSION,
      payload: session
    });
  }
}

/**
 * TODO:
 */
export function getAllowList() {
  return (dispatch, getState) => {
    const { allowList } = getState().auth;
    if (allowList) {
      return Promise.resolve(allowList);
    }

    const url = apiUtils.getAllowList();

    dispatch({
      type: [
        constants.FETCH_ALLOW_LIST_PENDING,
        constants.FETCH_ALLOW_LIST_SUCCESS,
        constants.FETCH_ALLOW_LIST_FAILURE
      ],
      meta: {
        url,
        method: 'get'
      }
    });
  }
}


export default {
  setEnvironment,
  setSession,
  getAllowList
}
