import AppConstants from '_Constants/AppConstants';

/**
 * Build spl settings object
 * ex:
 * {
 *    'dut': 45,
 *    'speech': { '2.7 m 30 deg': 65, '2.7 m 90 deg': 59 },
 *    'noise': { '1.8 m 135 deg': 54 },
 *    'measuringUnit': 'dBA'
 * }
 * @param {Object} scenario with test information
 */
export function getSPLSettings(scenario) {
  const settings = {};
  const { qual: { spl } = {}, testSuite } = scenario;

  if (!spl) return settings;

  const { DEFAULT_SPEECH_LOCATION } = AppConstants.TEST_SUITES[testSuite];

  let dut;
  let speech = {}
  const noise = {};
  Object.keys(spl).forEach(key => {
    if (spl[key].actor === 'noise') {
      noise[spl[key].location] = spl[key].value;
    } else if (spl[key].actor === 'speech') {
      speech[spl[key].location] = spl[key].value;
    } else if (spl[key].actor === 'dut') {
      dut = spl[key].value;
    }
  });

  // default speech speaker configuration
  if (Object.keys(speech).length === 0) {
    speech = {
      [DEFAULT_SPEECH_LOCATION]: 70
    };
  }

  return {
    dut,
    speech,
    noise,
    measuringUnit: 'dBA'
  };
};

export default {
  getSPLSettings
}
