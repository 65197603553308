import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '_Constants/AppConstants';
import AutoLocalSearchTestConstants from '_Constants/AutoLocalSearchTestConstants';
import { getErrorTextForDropdown } from '../Util';
import { sendRawInfoToNewRunWithIdentifier } from '../NewRunDataTransferUtils';
import { getCommonSelect, getInputField } from '../uiUtils';
import { onChangeInput, onInput } from '../uiActionsUtils';

const IDENTIFIER = 'AutoLocalSearch';

class AutoLocalSearch extends React.Component {

  selectDeviceVirtualAssistant = () => {
    const { selectedScenario } = this.props.params;
    return (
      this.getSelectField('deviceVirtualAssistant', AppConstants.DEVICE_VIRTUAL_ASSISTANT_LABEL,
        'Select the Device Virtual Assistant to run',
        AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS,
        selectedScenario.deviceVirtualAssistant ? selectedScenario.deviceVirtualAssistant
          : AppConstants.EMPTY)
    )
  }

  /**
   * Get select options within TestGroupInfo Section
   * @param {*} id
   * @param {*} label
   * @param {*} hintText
   * @param {*} options
   * @param {*} selected
   */
  getSelectField = (id, label, hintText, options, selected) => {
    const { selectedScenarioError } = this.props.params;
    let shouldDisable = false;
    let errorText = getErrorTextForDropdown(id, selectedScenarioError[id], []);
    return getCommonSelect(id, label, hintText, options, selected, shouldDisable, errorText, this.onChangeSelectSimple);
  }

  /**
   * General operation for select box, only update state value and error state in new run page,
   * no other operations
   * @param {*} id
   * @param {*} event
   */
  onChangeSelectSimple = (id, event) => {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    let identifier = IDENTIFIER + ' , onChangeSelectSimple, ' + id;
    let updatedParams = selectedScenario;
    updatedParams[id] = event.detail.selectedOption.id
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParams, selectedScenarioCallBack);

    let updatedParamsError = selectedScenarioError;
    updatedParamsError[id] = false;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedParamsError, scenarioErrorCallBack);
  }

  /**
   * Method to display checkbox to set range of utterances to be played for
   * Full scenario for Auto Local Search test suite
   */
  getUtteranceRangeControl = () => {
    const { selectedScenario, selectedScenarioError } = this.props.params;
    return (
      <AWSUI.FormField
        errorText={selectedScenarioError.transcriptRangeError}>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={3} borders='none'>
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox label='Set Utterance Range'
                  checked={selectedScenario.isUtteranceRangeSet}
                  onChange={event => { this.onChangeUtteranceRangeCheck(event) }} />
                <AWSUI.Tooltip text={AutoLocalSearchTestConstants.UTTRANCE_RANGE_TOOLTIP_MESSAGE} size='small' position='top'
                  className='awsui-util-no-margin tooltip-inner'>
                  <AWSUI.Icon name='status-info'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
            {
              selectedScenario.isUtteranceRangeSet && (
                <div>
                  {this.getInputField('fromTranscriptNum', 'From', 'Provide an utterance number to start with', 60)}
                  {this.getInputField('toTranscriptNum', 'To', 'Provide an utterance number to end with', 60)}
                </div>
              )
            }
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  getInputField = (id, label, hintText, maxWidth = 600) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return getInputField(id, label, hintText, maxWidth,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack,
      this.onChangeInput,
      this.onInput);
  }

  /**
   * Event handler - Gets called when the "Set range of utterances to be played" checkbox is checked/ unchecked
   * for Full scenario for Auto Local Search test suite
   * @param event Event which contains value for checked vs unchecked
   */
  onChangeUtteranceRangeCheck = (event) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    let isChecked = event.detail.checked;
    let updatedSelectedScenario = selectedScenario;
    updatedSelectedScenario.isUtteranceRangeSet = isChecked;
    updatedSelectedScenario.fromTranscriptNum = isChecked ? selectedScenario.fromTranscriptNum : AutoLocalSearchTestConstants.ZERO;
    updatedSelectedScenario.toTranscriptNum = isChecked ? selectedScenario.toTranscriptNum : AutoLocalSearchTestConstants.ZERO;

    let updatedSelecedScenarioError = selectedScenarioError;
    updatedSelecedScenarioError.transcriptRangeError = isChecked ? selectedScenarioError.transcriptRangeError :
      AppConstants.EMPTY;

    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + ',onChangeUtteranceRangeCheck', updatedSelectedScenario, selectedScenarioCallBack);
    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + ',onChangeUtteranceRangeCheck', updatedSelecedScenarioError, scenarioErrorCallBack);
  }

  // when AWS component Input is updated
  // original onChangeInput
  onChangeInput = (id, event) => {
    const source = IDENTIFIER + ',onChangeInput';
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return onChangeInput(id, event, source,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack);
  }

  // operation on InputField
  onInput = (id, event) => {
    const source = IDENTIFIER + ',onChangeInput';
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return onInput(id, event, source,
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack);
  }

  getAutoLocalSearchSelections = () => {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return (
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root='true'>
          {this.selectDeviceVirtualAssistant()}
          {selectedScenario.scenarioType === AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_FULL && (
            this.getUtteranceRangeControl()
          )}
        </div>
      </AWSUI.ColumnLayout>
    )
  }

  render() {
    return (
      <div>
        {this.getAutoLocalSearchSelections()}
        {}
      </div>
    )
  }
}
export default AutoLocalSearch;
