
export const SYNC_RESOURCE_OPTIONS_COLUMNS = [{
  id: 'testSuite',
  header: () => 'Test Suite',
  cell: item => ( item.testSuite ),
  minWidth: '200px',
  allowLineWrap: true
 }, {
  id: 'scenarioType',
  header: () => 'Scenario Type',
  cell: item => ( item.scenarioType ),
  minWidth: '100px',
  allowLineWrap: false
 }, {
  id: 'locales',
  header: () => 'Locales',
  cell: item => ( item.locales ),
  minWidth: '200px',
  allowLineWrap: true
}];

export const SORTABLE_COLUMNS = [
  {id: 'testSuite', field: 'testSuite'},
  {id: 'scenarioType', field: 'scenarioType'}
];
