import React from "react";

function KeyValuePairToken({ keyName, value }) {
  console.log("adding", keyName, value);
  return (
    <div>
      <div className="awsui-util-label">{keyName}</div>
      <div>{value}</div>
    </div>
  );
}

function KeyValuePair({ keyPairs }) {
  console.log("adding", keyPairs);
  return keyPairs.map((item, index) => {
    return Object.keys(item).map((key, idx) => {
      return <KeyValuePairToken keyName={key} value={item[key]} />;
    });
  });
}

function CPane({ keyPairs }) {
  return (
    <div>
      <div className="awsui-util-spacing-v-s">
        <KeyValuePair keyPairs={keyPairs} />
      </div>
    </div>
  );
}

export default CPane;
