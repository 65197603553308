import { isEmpty, get, isNil } from 'lodash';
import { logToConsole } from '../../Util';

/**
 * Returns true if the deviceMapping is not empty
 * @param {*} payload
 * @returns
 */
function isValidDeviceMapping(payload) {
  const dutMapping = get(payload, 'deviceMapping.DUT', null) || get(payload, 'deviceMapping.dut1', null);
  const isValidDeviceMapping = !isNil(dutMapping) && !isEmpty(dutMapping);
  logToConsole('validating dut mapping', isValidDeviceMapping);
  return isValidDeviceMapping;
}

/**
 * Returns true if the actorMapping is non-null and valid
 * @param {*} payload
 * @returns
 */
function isValidActorMapping(payload) {

  const actorMapping = get(payload, 'mapping.actorMapping', null);
  const isValidActorMapping = !isNil(actorMapping) && !isEmpty(actorMapping);
  // add more logic if needed for scenario based checks
  logToConsole('validating actor mapping', isValidActorMapping);
  return isValidActorMapping;

}

/**
 * Validates the payload before submission and enables the "Run Test" button
 * @param {*} payload
 * @returns
 */
export function validateTestRunPayload(jobsList) {
  // TODO: add more validation on other parts of the payload

  if (isNil(jobsList) || isEmpty(jobsList)) {
    return false;
  }
  const invalidJobsList = jobsList.filter(jobPayload => {
    // validate actor and device mapping
    return !isValidActorMapping(jobPayload) || !isValidDeviceMapping(jobPayload);
  })
  return isEmpty(invalidJobsList);
}


