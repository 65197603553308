import AppConstants from '../../_Constants/AppConstants';

export const OVERVIEW_COLUMNS = [{
    id: 'raspiName',
    header: 'RasPi Name',
    cell: item => ( item.name ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'location',
    header: 'Location',
    cell: item => ( item.location ),
    minWidth: '100px',
    allowLineWrap: false
  }, {
    id: 'status',
    header: 'Status',
    cell: item => ( item.getPingStatus(item.labId, item.rasPiIndex) ),
    minWidth: '100px',
    allowLineWrap: false
  }, {
    id: 'downloadStatus',
    header: 'Download Status',
    cell: item => ( item.getDownloadStatus(item.labId, item.rasPiIndex) ),
    minWidth: '550px',
    allowLineWrap: true
  }];

export const DETAIL_COLUMNS = [{
    id: 'raspiName',
    header: () => 'RasPi Name',
    cell: item => ( item.name ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'location',
    header: () => 'Far Field Location',
    cell: item => ( item.location ),
    minWidth: '100px',
    allowLineWrap: true
  }, {
    id: 'musiclocation',
    header: () => 'Music Location',
    cell: item => ( item.musiclocation ),
    minWidth: '100px',
    allowLineWrap: true
  }, {
    id: 'closetalklocation',
    header: () => 'Close Talk Location',
    cell: item => ( item.closetalklocation ),
    minWidth: '100px',
    allowLineWrap: true
}, {
  id: 'mobilelocation',
  header: () => 'Mobile Location',
  cell: item => ( item.mobilelocation ),
  minWidth: '100px',
  allowLineWrap: true
}];

export const SORTABLE_COLUMNS = [
];

export const DISTROS = [
  {
    text: "Debian",
    items: [
      { text: "Raspbian", id: "raspbian" },
      { text: "Ubuntu", id: "ubuntu" },
      { text: "Others", id: "debian" }
    ]
  }
]
