/**
 * This file contains utility methods for UPL Live Feed for Live Run Page
 */

import { UPL_LIVE_SUMMARY_COLUMNS } from './UPLLiveRunSummaryTableConfig';
import { UPL_UTTERANCE_HISTORY_COLUMNS } from './UPLUtteranceHistoryTableConfig';
import { getLoadingMessage, logToConsole } from '../../../Util';
import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import LiveRunStyleConstants from '../../../_Constants/LiveRunStyleConstants';
import './UPLLiveFeedStyle.css';

/**
 * UPL Util method to load UPL Test Ongoing Summary Table from UPLPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadUPLLiveSummaryTableData(payload) {
  let result = [];
  try {
    let index = 1;

    for (let [utterance, utteranceResults] of Object.entries(payload.uplUtteranceResultMap)) {

      let lastCompletedMeasureIndex = utteranceResults.length - 1;
      // get the last utterance result from the list
      let lastUtteranceResult = utteranceResults[lastCompletedMeasureIndex];

      // Rollback to previous index if the summary result is not yet available to display
      while (lastCompletedMeasureIndex > 0 && 
        lastUtteranceResult.finalUPLValue.toLowerCase().includes('processing')) {
          lastUtteranceResult = utteranceResults[--lastCompletedMeasureIndex];
      }

      // load summary details
      let totalAttempts = lastUtteranceResult.totalAttempts;
      let averageUPLValue = getUPLDisplayValueStatus(lastUtteranceResult.averageUPLValue);
      let tp50 = getUPLDisplayValueStatus(lastUtteranceResult.tp50);
      let tp90 = getUPLDisplayValueStatus(lastUtteranceResult.tp90);
      let resultStatus = getResultStatusText(lastUtteranceResult.resultStatus);

      result.push({
        id: index++,
        utterance: utterance,
        totalAttempts: totalAttempts,
        averageUPLValue: averageUPLValue,
        tp50: tp50,
        tp90: tp90,
        result: resultStatus
      });
    }
  } catch (ex) {
    logToConsole(JSON.stringify(ex));
  }
  return result;
}

/**
 * NOT IN USE AT THE MOMENT.
 * UPL Util method to load UPL Test Utterance Result Table from UPLPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadUPLLiveUtteranceTableData(payload) {
  let result = [];
  try {
    // get the last utterance result from the list
    let utteranceResult = payload.uplUtteranceResult;

    // load summary details
    let utterance = utteranceResult.asrText;
    let actualResponse = utteranceResult.actualResponse;
    let actualIntent = utteranceResult.actualIntent;
    let finalUPLValue = utteranceResult.finalUPLValue;
    let isDeviceOnline = utteranceResult.deviceOnlineStatus === 'ONLINE';
    let deviceStatus = getOnlineOfflineStatusText(isDeviceOnline);

    result.push({
      utterance: utterance,
      actualResponse: actualResponse,
      actualIntent: actualIntent,
      finalUPLValue: finalUPLValue,
      deviceStatus: deviceStatus,
    });
  } catch (ex) {
    logToConsole(JSON.stringify(ex));
  }
  return result;
}

/**
 * UPL Util method to load UPL Utterance History Table from UPLPayload
 * @param - payload
 * @return - list of table rows
 */
export function loadUPLUtteranceHistoryTableData(payload) {
  let result = [];
  try {
    let index = 1;
    for (let [utterance, utteranceResults] of Object.entries(payload.uplUtteranceResultMap)) {

      for (let utteranceResult of utteranceResults) {

        // load utterance details
        utterance = utteranceResult.asrText;
        let actualResponse = utteranceResult.actualResponse;
        let domain = utteranceResult.domain;
        let transcribeText = getUPLDisplayValueStatus(utteranceResult.transcribeText);
        let finalUPLValue = getUPLDisplayValueStatus(utteranceResult.finalUPLValue);
        let isDeviceOnline = utteranceResult.deviceOnlineStatus === 'ONLINE';
        let deviceStatus = getOnlineOfflineStatusText(isDeviceOnline);

        result.push({
          id: index++,
          utterance: utterance,
          actualResponse: actualResponse,
          domain: domain,
          transcribeText: transcribeText,
          finalUPLValue: finalUPLValue,
          deviceStatus: deviceStatus,
        });
      }
    }
  } catch (ex) {
    logToConsole(JSON.stringify(ex));
  }
  return result;
}

/**
 * Method to get display friendly text for device online offline status.
 * @param isDeviceOnline device online or not
 * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
 */
export function getOnlineOfflineStatusText(isDeviceOnline) {
  if (isDeviceOnline === undefined) {
    return AppConstants.UNAVAILABLE;
  }

  let styleMapEntry = LiveRunStyleConstants.DEVICE_ONLINE_OFFLINE_STATUS[isDeviceOnline];
  let deviceStatusText = styleMapEntry.displayText;

  return (
    <AWSUI.ColumnLayout columns={4} borders='none'>
      <div data-awsui-column-layout-root='true'>
        <div className={styleMapEntry.deviceStatusClass}>
          <AWSUI.Icon name={styleMapEntry.deviceStatusIcon}
            variant={styleMapEntry.variant} />
          <span className={styleMapEntry.deviceStatusTextStyle}>
            {deviceStatusText}
          </span>
        </div>
        <div>
          {
            (!isDeviceOnline) &&
            (
              <AWSUI.Tooltip text={AppConstants.DEVICE_OFFLINE_MESSAGE} size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                <AWSUI.Icon name='status-info'></AWSUI.Icon>
              </AWSUI.Tooltip>
            )
          }
        </div>
        <div />
        <div />
      </div>
    </AWSUI.ColumnLayout>
  );
}

/**
 * Method to get display friendly text for utterance result status.
 * @param resultStatus utterance result status
 * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
 */
export function getResultStatusText(resultStatus) {
  if (resultStatus === undefined) {
    return AppConstants.UNAVAILABLE;
  }

  let styleMapEntry = LiveRunStyleConstants.UTTERANCE_RESULT_STATUS[resultStatus];
  let deviceStatusText = styleMapEntry.displayText;

  return (
    <AWSUI.ColumnLayout columns={1} borders='none'>
      <div data-awsui-column-layout-root='true'>
        <div className={styleMapEntry.deviceStatusClass}>
          <AWSUI.Icon name={styleMapEntry.deviceStatusIcon}
            variant={styleMapEntry.variant} />
          <span className={styleMapEntry.deviceStatusTextStyle}>
            {deviceStatusText}
          </span>
        </div>
      </div>
    </AWSUI.ColumnLayout>
  );
}

/**
 * Renders animated component for "Processing..." UPL value
 * @returns "Running" text which will flash every second
 */
export function getUPLDisplayValueStatus(uplValue) {

  if (uplValue.toString().toLowerCase().includes('processing')) {
    return (
      <div className='awsui-util-label'>
        <span className='ellipsis-anim-with-color'>
          <span>&bull;</span><span>&bull;</span><span>&bull;</span><span>&nbsp;</span><span>R</span>
          <span>u</span><span>n</span><span>n</span>
          <span>i</span><span>n</span><span>g</span>
        </span>
      </div>
    );
  } else {
    return uplValue;
  }
}

/**
 * Method to get New UPL Live Run Table
 */
export function getUPLLiveRunTable(state) {
  if (state &&
    state.testStats.hasOwnProperty('payload')) {
    let payload = state.testStats.payload;
    // Load the UPL data from payload
    let UPLLiveSummaryData = loadUPLLiveSummaryTableData(payload);
    let UPLUtteranceHistoryData = loadUPLUtteranceHistoryTableData(payload);

    let liveTableContainer = (
      <div>
        {getLoadingMessage('Ongoing UPL Test Summary')}
        <div>
          <AWSUI.Table
            columnDefinitions={UPL_LIVE_SUMMARY_COLUMNS}
            items={UPLLiveSummaryData}
          >
          </AWSUI.Table>
        </div>
        {getLoadingMessage('UPL Utterance History')}
        <div class='scrollstylecontainer'>
          <AWSUI.Table
            columnDefinitions={UPL_UTTERANCE_HISTORY_COLUMNS}
            items={UPLUtteranceHistoryData}
          >
          </AWSUI.Table>
        </div>
      </div>
    )
    return liveTableContainer;
  }

  return getLoadingMessage(state.testStatus == AppConstants.RASPI_RESOURCES_SYNCING ?
    AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE : AppConstants.RETRIEVING_DATA_MESSAGE);
}