import React from 'react';
import WakewordCustomizedOptions from "./WakewordCustomizedOptions";
import TestType from "../ScenarioCommon/TestType";

/**
 * Use for acoustic Near Field and Far Field tests
 */
class WakewordTestTypeSelection extends React.Component {

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return (
      <div>
        <TestType
          allowedTypes={[0, 3]}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
        </TestType>
        <WakewordCustomizedOptions params={{
          selectedScenario: selectedScenario,
          selectedScenarioCallBack: selectedScenarioCallBack,
          selectedScenarioError: selectedScenarioError,
          scenarioErrorCallBack: scenarioErrorCallBack,
        }} />
      </div>
    );
  }
}

export default WakewordTestTypeSelection;
