import { createSlice } from '@reduxjs/toolkit'
import { GOTO_STEP } from "../../../constants";


/**
 * [
 *  {
 *    category: 'abc',
 *    scenarios: [],
 *  }
 * ]
 */
export const uiSlice = createSlice({
  name: 'acmUiWizardSlice',
  initialState: {
    step: GOTO_STEP.TEST_SUITE,
  },
  reducers: {
    updateStep: (state, action) => {
      state.step = action.payload;
    },
    reset: (state) => {
      state.step =  GOTO_STEP.TEST_SUITE;
    }
  },
})

export const { updateStep, reset } = uiSlice.actions;
export const uiStepSelector = (state) => state.createTestUi;


export default uiSlice.reducer
