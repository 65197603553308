
export const getState = (key) => {
    try {
        const data = localStorage.getItem(key);
        if(!data) {
          return null;
        }
        return JSON.parse(data);
    } catch (e) {
      return null;
    }
}

export const saveState = (data, key) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    return null;
  }
}

export const removeState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch(e) {
    return null;

  }
}