
import { reset as clearTestInitSlice } from "../testInitSlice";
import { reset as clearLabConfigSlice, setLab, setAccountConfig, removeAccountConfig, getAccount } from "./labConfigSlice";
import {
  reset as clearScenarioSlice,
  setId,
  setScenarioType,
  setCategoryAndScenario,
  removeCategory,
  addNewCustomization,
  SCENARIO_TYPE
} from "./scenarioSelectionSlice";
import { reset as clearUiSlice } from "./uiSlice";
import { reset as clearTestSuitesSlice } from "../../newRun/scenariosSlice";
import { addNewTestInTestList } from "../testList/testListMiddleware";
import { fetchLabs } from "../labSlice";
import { fetchCustomerIds } from "../customerIdSlice";
import { fetchPis } from "../piSlice";
import { get, xor } from "lodash";
import { fetchDevices } from "../deviceListSlice";
import { TESTID_DEVICE_MAP } from "../../../constants";
import { fetchScenarios } from "../scenariosSlice";

export const setupInitialLabConfig = (labId) => (dispatch, getState) => {
  const {labs} = getState().labList;
  const {customerIds} = getState().customerIds;
  const {labInfo: selectedLabInfo} = getState().acmLabConfig;
  dispatch(fetchLabs()); // needs to be pulled again in-order to evaluate if the lab is locked or not
  if(customerIds.length === 0) {
    dispatch(fetchCustomerIds());
  }
  if(selectedLabInfo.id) {
    dispatch(fetchPis(labId))
  }
}

export const handleLabSelection = (newLabInfo) => (dispatch, getState) => {
  const {labInfo: prevLabInfo} = getState().acmLabConfig;
  if(prevLabInfo.id !== newLabInfo.id) {
      dispatch(setLab(newLabInfo));
      dispatch(fetchPis(newLabInfo.id));
  }
}

export const setupInitialTestConfig = () => (dispatch, getState) => {
  const {id} = getState().testInit;
  if(!id) {
    dispatch(setId());
  }
  dispatch(fetchScenarios());

}

export const setupInitalDeviceConfig = (account) => (dispatch, getState) => {
  const { accountConfig } = getState().acmLabConfig;
  const {customerId} = get(accountConfig, `${account}.customerIdInfo`, {}) || {};
  if(customerId) {
      dispatch(fetchDevices({cid:customerId}));
  }
}

export const handleCustomerIdChange = (cIdInfo, account) => (dispatch, getState) => {
  const { accountConfig } = getState().acmLabConfig;
  const { dutList } = getState().devicesList
  const {customerId: prevCustId} = get(accountConfig, `${account}.customerIdInfo`, {}) || {};
  if(cIdInfo.customerId !== prevCustId) {
    dispatch(setAccountConfig({
      account,
      customerIdInfo: cIdInfo,
      devicePiConfig: [],
    }))
    if(!dutList[cIdInfo.customerId]) {
      dispatch(fetchDevices({cid:cIdInfo.customerId}));
    }
  }
}

export const updateCategoryScenario = ({ category, scenarios, index }) => (dispatch, getState) => {
  const { customizedScenarios, scenarioType } = getState().acmScenarioSlection;
  const { scenarios: scenarioInStore = [] } = customizedScenarios[index];
  if(xor(scenarioInStore, scenarios).length && scenarioType !== SCENARIO_TYPE.WWA) {
    const devicesForThisScenario = scenarioInStore.reduce(
      (iter, scenarioId) => {
        let devices;
        devices = TESTID_DEVICE_MAP[scenarioId].devices
        iter.push(...devices);
        return iter;
      }, []
    );
    const uniqDevices = [...new Set(devicesForThisScenario)];
    uniqDevices.forEach(
      deviceId => {
        const { label } = getAccount(deviceId);
        dispatch(removeAccountConfig(label));
      }
    );
  }

  dispatch(setCategoryAndScenario({
    category,
    scenarios,
    index
  }));

}


export const removeCategoryScenarioMap = ({index}) => (dispatch, getState) => {
  const { customizedScenarios } = getState().acmScenarioSlection;
  const { scenarios: scenarioInStore = [] } = customizedScenarios[index];
  const devicesForThisScenario = scenarioInStore.reduce(
    (iter, scenarioId) => {
      const { devices } = TESTID_DEVICE_MAP[scenarioId];
      iter.push(...devices);
      return iter;
    }, []
  );
  const uniqDevices = [...new Set(devicesForThisScenario)];
  uniqDevices.forEach(
    deviceId => {
      const { label } = getAccount(deviceId);
      dispatch(removeAccountConfig(label));
    }
  );
  dispatch(removeCategory({index}));
}

export const addCategoryScenarioMap = () => (dispatch, getState) => {
  dispatch(addNewCustomization())
}

export const handleDevicePiMapChange = (newDevicePiMap, account) => (dispatch, getState) => {
  const { accountConfig } = getState().acmLabConfig;
  const { devicePiConfig, customerIdInfo } = get(accountConfig, `${account}`, {}) || {};
  const {dsnInfo, pi, firmware, index} = newDevicePiMap;
  const newConfig = [...devicePiConfig];
  newConfig[index] = {dsnInfo, pi, firmware};
  dispatch(setAccountConfig({
    account,
    customerIdInfo: customerIdInfo,
    devicePiConfig: newConfig,
  }))
}


export const handleScenarioTypeSelection = (scenarioType) => (dispatch, getState) => {
  const {scenarioType: scenTypeInStore} = getState().acmScenarioSlection;
  if(scenTypeInStore !== scenarioType) {
    dispatch(clearLabConfigSlice());
  }
  dispatch(setScenarioType(scenarioType));
}


export const submitTest = () => (dispatch, getState) => {
  const scenarioSelectionState = getState().acmScenarioSlection;
  const labConfigState = getState().acmLabConfig;
  const testInitState = getState().testInit;
  const testMapping = getState().testMapping;
  const testOpts = getState().testOpts;

  dispatch(addNewTestInTestList({
    ...scenarioSelectionState,
    ...labConfigState,
    testMapping: testMapping,
    ...testOpts,
    testSuite: testInitState.testSuite
  }));

  dispatch(cancelTestCreation());

}

export const cancelTestCreation = () => (dispatch) => {
  dispatch(clearTestInitSlice());
  dispatch(clearScenarioSlice());
  dispatch(clearLabConfigSlice());
  dispatch(clearUiSlice());
  dispatch(clearTestSuitesSlice());
}
