import React, { Component } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { getLoadingMessage, logToConsole } from '../../../Util';
import AppConstants from '_Constants/AppConstants';
import Containers from './Containers';
import * as SecurityConstants from '_Constants/SecurityTestConstants';
import PortScanner from './PortScanner';
import PacketCaptureAnalysis from './PacketCaptureAnalysis';
import ld from 'lodash';

class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgressTest: null
    };
  }

  /**
   * render loader message
   */
  renderLoadingMessage() {
    const { testStatus } = this.props;
    const loadingMessage = testStatus === AppConstants.RASPI_RESOURCES_SYNCING
      ? AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE
      : AppConstants.RETRIEVING_DATA_MESSAGE;
    return getLoadingMessage(loadingMessage);
  }


  /**
   * render security live feeds based on test type
   */
  renderSecurityLiveFeed = () => {
    const { payload } = this.props.testStats || {};
    switch (ld.get(payload, 'tests[0].type', '')) {
      case SecurityConstants.TEST_TYPES.PORT_SCANNER:
        // return <PortScanner
        //   testStatus={this.props.testStatus}
        //   testStats={this.props.testStats}
        // />;
        return <PacketCaptureAnalysis
          testStatus={this.props.testStatus}
          testStats={this.props.testStats}
          testDetails={this.props.testDetails}
        />;
      case SecurityConstants.TEST_TYPES.PACKET_CAPTURE_ANALYSIS:
      case SecurityConstants.TEST_TYPES.MAN_IN_THE_MIDDLE_DETECTION:
        return <PacketCaptureAnalysis
          testStatus={this.props.testStatus}
          testStats={this.props.testStats}
          testDetails={this.props.testDetails}
        />;
      default:
        return this.renderLoadingMessage();
    }
  }

  render() {
    const { payload } = this.props.testStats || {};

    if (!payload) return this.renderLoadingMessage();

    return (
      <div>

        {
          this.renderSecurityLiveFeed()
        }

      </div>
    );
  }
}

export default Security;
