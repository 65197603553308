import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { Header, Table, SpaceBetween, Grid, Box }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import {
  fetchLabsAndRasPis,
  getRasPiSetupCommand,
  actionRasPi,
  actionRasPiWithBodyAndAckTimeout,
  talkRasPi,
  getWhitelistedScenarios,
  jobSockets,
  saveLabUserPreference,
  generateItemFieldsForCalibration,
  actionUbuntu,
  sendMQTTMessage
} from './controller';
import HotspotController from './HotspotController';
import SyncResourceControl from './SyncResourceControl';
import SyncResourceDetails from './SyncResourceDetails';
import {
  SORTABLE_COLUMNS, OVERVIEW_COLUMNS,
  DETAIL_COLUMNS, DISTROS
} from './TableConfig';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import SyncResourceConstants from '../../_Constants/SyncResourceConstants';
import {AUTOMOTIVE_PLUGIN_INSTALL, QUAL_RF_INSTALL_PLUGIN} from "../../_Constants/AQTHotspotConstants";
import { logToConsole, getLoadingMessage, networkError, isProdEnvironment } from '../../Util';
import { LABCONTROL_DEFINITION } from './LabControlConfig';
import openSocket from 'socket.io-client';
import { aqtStore } from '../../Components/State/Store';
import { setSocketHandlers } from './labPageSocketHandler';
import {
  getOverallProgressPercentage, getOverallCompletedCount,
  getOverviewProgressBarState
} from './SyncResourceUtil';
import { buildUserPreferenceObject, getRasPiName, getRasPiLoc } from '../../Util/index';
import { getQualRfSetupCmd, getSetupCmd, getUbuntuSetupCmd } from "./SetupHelper";
import ld from "lodash";
import {DEFAULT_PLUGINS, STOP_MORGAN_NOISE} from "./constants";
import LabEnvSelector from './LabEnvSelector'

const STOP = 'Stop';
const START = 'Speaker Calibration';
const ICON_STOP = 'status-stopped';
const ICON_START = 'caret-right-filled';

const SYNC_QUERY_TIMEOUT = 20000;
const RESOURCE_SYNC_INTERVAL = 1000; // Frequency to get pushed status for resource sync

const CALIBRATE_SKILL_ITEM_LIST = generateItemFieldsForCalibration("_Administrative_alexa_play_task_two_from_skill.wav", "SKILL");
const CALIBRATE_HAPPY_ITEM_LIST = generateItemFieldsForCalibration("_Administrative_alexa_play_happy_pharrell_williams.wav", "HAPPY");

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: 600
  },
  nav: {
    maxHeight: '80vh',
    overflowY: 'auto'
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight: 'bold'
  },
  container: {
    margin: 'auto',
    width: 500
  },
  message: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    marginTop: 100
  },
  command: {
    height: 250,
    width: 450,
    textAlign: 'center',
    overFlowX: 'auto'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -13,
    marginLeft: -13
  },
  wrapper: {
    position: 'relative'
  }
});

class Labs extends React.Component {

  state = {
    labEnv: 'General',
    labsAndRasPis: {
      loading: false,
      data: {},
      error: {
        isError: false,
        message: null
      }
    },
    rasPiCmd: {
      loading: false,
      raspId: null,
      command: null,
      error: {
        isError: false,
        message: null
      }
    },
    anchorEl: null,
    copyingCmd: false,
    actionAllLabs: false,
    whitelistedScenarioIds: [],
    activeTabIdMap: {},
    expandedLab: AppConstants.EMPTY,
    elapsedSeconds: 0,
    selectedRaspiItems: {}
  };

  piThingNameMap = {};

  componentWillMount() {
    this.getLabsAndRasPis();
    this.getWhitelistedScenarios();
  }

  componentWillUnmount() {
    Object.keys(jobSockets).forEach(function (socketId) {jobSockets[socketId].close()});
    clearInterval(this.interval);
  }

  componentDidMount() {
    if(this.state.elapsedSeconds <= SyncResourceConstants.MAX_WAIT_TIME) {
      this.interval = setInterval(() => this.setState({ elapsedSeconds: this.state.elapsedSeconds + 1 }), 1000);
    }
  }

  updateDownloadProgressFunc = (raspData) => {
    logToConsole ("Updating download progress with following: " );
    logToConsole(raspData);
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    for (var thingName in raspData) {
      let labIdAndPiIndexInfo = this.piThingNameMap[thingName];
      // need to add in the transition progress of the data
      logToConsole("Pi download state is " + raspData[thingName].state)
      switch (raspData[thingName].state) {
        // this state has been verified in labPageSocketHandler.js, when open socket part
        case AppConstants.DOWNLOAD_STATUS.COMPLETED:
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.downloadStatus = raspData[thingName].state;
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.syncStatusData['complete_date'] = raspData[thingName].complete_date;
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.shadowState = raspData[thingName].shadowState;
          break;
        case AppConstants.DOWNLOAD_STATUS.IN_PROGRESS:
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.downloadStatus = raspData[thingName].state;
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.syncStatusData = raspData[thingName].syncData;
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.shadowState = raspData[thingName].shadowState;
          break;
        default:
        // default is in progress, in middle of payload
          logToConsole("State status returned from shadow is " + raspData[thingName].state)
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.syncStatusData = raspData[thingName].syncData;
          currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.shadowState = raspData[thingName].shadowState;
      }
      logToConsole("Updating pi data from shadow");
      logToConsole(currRaspiData[labIdAndPiIndexInfo.labId].rasPis[labIdAndPiIndexInfo.piIndex].rasPiStatus.syncStatusData);
    }

    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
  }

  getLabsAndRasPis = () => {
    let cacheName = AppConstants.cache.LABSPIS;
    this.setState({ labsAndRasPis: {
        ...this.state.labsAndRasPis,
        loading: true
      }
    });
    let labsAndRasPisData = {};
    //TODO: Disabling Session storage with respect to multiple account usage.
    const cachedHits = sessionStorage.removeItem(cacheName);
    if (cachedHits) {
      logToConsole('Retrieved from Cache: ', cacheName);
      labsAndRasPisData = JSON.parse(cachedHits);
      this.setState({
        labsAndRasPis: {
          loading: false,
          data: labsAndRasPisData,
          error: { isError: false, message: null },
      }});
      this.runActionSequenceAllLabs(labsAndRasPisData, AppConstants.rasPiAction.STATE.id);
    } else {
      fetchLabsAndRasPis().then(labsAndRasPisPromises => {
        if (!labsAndRasPisPromises.hasOwnProperty('error')) {
          Promise.all(labsAndRasPisPromises)
            .then(labsAndThings => {
              logToConsole("Fetching labs");
              labsAndThings.forEach(labAndThings => {
                // Assign lab data
                labsAndRasPisData[labAndThings.lab.id] = labAndThings;
                labsAndRasPisData[labAndThings.lab.id].actionAllPis = false;
                labsAndRasPisData[labAndThings.lab.id].isLabPreferenceUnderEdit = false;
                labsAndRasPisData[labAndThings.lab.id].preference = buildUserPreferenceObject(labAndThings.lab, labsAndRasPisData[labAndThings.lab.id].rasPis);
                labsAndRasPisData[labAndThings.lab.id].error = { isError: false, message: null };
                logToConsole("lab id is " + labAndThings.lab.id);
                Object.keys(labsAndRasPisData[labAndThings.lab.id].rasPis).forEach(rasPi => {
                  logToConsole("pi is " + rasPi);
                  logToConsole(labsAndRasPisData[labAndThings.lab.id].rasPis[rasPi]);
                  let labIdAndPiIndexInfo = {};
                  labIdAndPiIndexInfo.labId = labAndThings.lab.id;
                  labIdAndPiIndexInfo.piIndex = rasPi;
                  this.piThingNameMap[labsAndRasPisData[labAndThings.lab.id].rasPis[rasPi].thingName] = labIdAndPiIndexInfo;

                  labsAndRasPisData[labAndThings.lab.id].rasPis[rasPi].rasPiStatus = {
                    [AppConstants.rasPiAction.STATE.action]: false,
                    [AppConstants.rasPiAction.UPDATE.action]: false,
                    [AppConstants.rasPiAction.STOP.action]: false,
                    status: false,
                    calibrate: false,
                    calibrationInProgress: false,
                    downloadStatus: AppConstants.DOWNLOAD_STATUS.INITIAL,
                    downloadInProgress: false,
                    syncStatusData: {},
                    syncTimeoutCnt: 0,   // see how many consecutive sync timeout have been there
                  }
                });
                logToConsole("pi thing map is ");
                logToConsole(this.piThingNameMap); //correct
                //Open Socket for each lab
                logToConsole("Opening Socket");
                let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
                const jobSocket = openSocket(controllerEndpoint, {
                  query: {
                    token: aqtStore.getState().session.idToken.jwtToken,
                    request: "labLiveStatus",
                    labID: labAndThings.lab.id,
                    pushIntervalMilliseconds: RESOURCE_SYNC_INTERVAL
                  }
                });
                jobSockets[labAndThings.lab.id] = jobSocket;

                setSocketHandlers(labAndThings.lab.id, jobSocket, this.updateDownloadProgressFunc);
              });
            })
            .then(() => {
              this.setState({
                labsAndRasPis: {
                  loading: false,
                  data: labsAndRasPisData,
                  error: { isError: false, message: null }
              }});
              //sessionStorage.setItem(cacheName, JSON.stringify(labsAndRasPisData));
              this.runActionSequenceAllLabs(labsAndRasPisData, AppConstants.rasPiAction.STATE.id);
            })
            .catch(error => {
              logToConsole('Error loading labs : ' + error);
              this.setState({
                labsAndRasPis: {
                  loading: false,
                  data: {},
                  error: { isError: true, message: 'Error loading labs and rasPis' }
              }});
              networkError(error, this.getLabsAndRasPis.name);
            });
        } else {
          this.setState({
            labsAndRasPis: {
              loading: false,
              data: {},
              error: { isError: true, message: labsAndRasPisPromises.error }
          }});
          sessionStorage.removeItem(cacheName);
        }
      });
    }
  }

  getRasPiSetup = (target, distro, rasPiId, labId) => {
    this.setState({ rasPiCmd: {
      ...this.state.rasPiCmd,
      raspId: rasPiId,
      loading: true
    }});
    let pluginsToInstall = [...DEFAULT_PLUGINS];
    if (this.isLocationUbuntuMachine(rasPiId, labId)) {
      pluginsToInstall.push(AUTOMOTIVE_PLUGIN_INSTALL.plugins);
    }
    if (this.isLocationQualRfMachine(rasPiId, labId)) {
      pluginsToInstall = [QUAL_RF_INSTALL_PLUGIN.plugins];
    }
    getRasPiSetupCommand(distro, rasPiId, pluginsToInstall).then(rasPiCmd => {
      if (!rasPiCmd.hasOwnProperty('error')) {
        this.setState({
          anchorEl: target,
          rasPiCmd: {
            raspId: rasPiId,
            loading: false,
            command: this.isLocationUbuntuMachine(rasPiId, labId)
              ? getUbuntuSetupCmd(rasPiCmd.data) : this.isLocationQualRfMachine(rasPiId, labId) ? getQualRfSetupCmd(rasPiCmd.data) : getSetupCmd(rasPiCmd.data),
            error: { isError: false, message: null },
            popoutSize: 15
        }});
      } else {
        this.setState({
          anchorEl: target,
          rasPiCmd: {
            raspId: rasPiId,
            loading: false,
            command: null,
            error: { isError: true, message: rasPiCmd.error },
            popoutSize: 15
        }});
      }
    });
  }

  /**
   * Renders a Button component for thing Setup [Raspi]
   * @param {*} cmd the Setup command retrieved from the thing setup API call to be copied to the clipboard
   * @param {*} label the label for the Button
   * @param {*} variant the type of the Button [https://material-ui.com/api/button/]
   */
  getRasCmdButton = (cmd, label, variant) => {
    return (
      <Button color='primary' size='small' variant={ variant }
        disabled={ this.state.copyingCmd }
        onClick={ () => {
          this.setState({ copyingCmd: true });
          copy(cmd);
          this.setState({ copyingCmd: false, anchorEl: null });
        }}
      >
        { label }
      </Button>
    );
  }

  getRasCmdPop = () => {
    return (
      <Popover
        open={ Boolean(this.state.anchorEl) }
        anchorEl={ this.state.anchorEl }
        onClose={ () => {
          this.setState({ anchorEl: null });
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={ this.props.classes.command }>
          <CardContent>
            { !this.state.rasPiCmd.error.isError ? (
              <div>
                <TextField
                  multiline
                  fullWidth
                  disabled
                  rows={ this.state.rasPiCmd.popoutSize }
                  value={ this.state.rasPiCmd.command }
                >
                </TextField>
                <div className={ this.props.classes.wrapper }>
                  { this.getRasCmdButton(this.state.rasPiCmd.command, 'Copy to Clipboard', 'raised') }
                  { this.state.copyingCmd && <CircularProgress size={ 24 } className={ this.props.classes.buttonProgress } /> }
                </div>
              </div>
            ) : (
              <Typography className={ this.props.classes.message } variant='caption' color='error'>
                { this.state.rasPiCmd.error.message }
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    );
  }

  /**
   * Utility to Check if the location name is Ubuntu
   * @param rasPiId - rasPi Index
   * @param labId - lab Index
   * @return boolean status if the location is Automotive Companion or not
   */
  isLocationUbuntuMachine(rasPiId, labId) {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isUbuntuLocation = false;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId]
        && currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location) {
          if (currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location.includes(AppConstants.AUTOMOTIVE_LOCATION)) {
            isUbuntuLocation = true;
          }
      }
    return isUbuntuLocation;
  }

  /**
   * Utility to Check if the location name is Qual and RF Companion
   * @param rasPiId - rasPi Index
   * @param labId - lab Index
   * @return boolean status if the location is Qual and RF Companion or not
   */
  isLocationQualRfMachine(rasPiId, labId) {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isRfLocation = false;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && ((currLabsAndRaspi[labId].preference || {}).actor_mapping_preference[rasPiId] || {}).location) {
      if (currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location.includes(AppConstants.QUAL_RF_LOCATION)) {
        isRfLocation = true;
      }
    }
    return isRfLocation;
  }

  /**
   * Returns True if lab is configured for Automotive Test that uses Ubuntu Machine, False otherwise.
   * If raspi is named ubuntu then it is configured for Automotive Scenarios.
   */
  isUbuntuLab = (labId) => {
  let currLabsAndRaspi = this.state.labsAndRasPis.data;
  let isUbuntuLab = false;

  if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference) {

        var mapPref = currLabsAndRaspi[labId].preference.actor_mapping_preference;
        for (var raspiPref in mapPref) {
          var raspiData = mapPref[raspiPref];
          if (raspiData.location &&
            raspiData.location.includes(AppConstants.AUTOMOTIVE_LOCATION)) {
              isUbuntuLab = true;
            }
          }
        }

    return isUbuntuLab;
  }

  /**
   * Method to retrieve whitelisted scenarios for the account
   * @return List of whitelisted scenarios for the account
   */
  getWhitelistedScenarios = () => {
    let whitelistedScenariosIn = [];
    return Promise.resolve(getWhitelistedScenarios().then(response => {
      if (!response.hasOwnProperty('error')) {
        response.scenarios.forEach(whitelistedScenario => {
        if (AppConstants.WHITELISTED_SCENARIO_TYPES.includes(whitelistedScenario.name)
              && !whitelistedScenariosIn.includes(whitelistedScenario.name)) {
          whitelistedScenariosIn.push(whitelistedScenario.name);
         }
       });
      }
     this.setState({
      whitelistedScenarioIds: whitelistedScenariosIn
     });
    }));
  }

  /**
   * Method which decides what header to display for a location column on Labs page depending on whitelisted
   * scenarios
   */
  getLabsPageColumnsToDisplay = () => {
    let labsPageColumns = DETAIL_COLUMNS;
    let acousticMobileHeaderName = AppConstants.EMPTY;
    // Both Acoustic & Mobile share same set of actors, so based on which scenario is whitelisted we can
    // display actor values in the same column with appropriate header for that column
    if (this.state.whitelistedScenarioIds.length > 0) {

      if (this.state.whitelistedScenarioIds.includes(AppConstants.ACOUSTIC_JAR_NAME)) {
        acousticMobileHeaderName = 'Acoustic Location';
      }

      labsPageColumns[1] = {
        id: 'location',
        header: () => acousticMobileHeaderName,
        cell: item => ( item.location ),
        minWidth: '100px',
        allowLineWrap: true
      }

      let musicFunctionalHeaderName = AppConstants.EMPTY;
      // Both Music & Functional share same set of actors, so based on which scenario is whitelisted we can
      // display actor values in the same column with appropriate header for that column
      if (this.state.whitelistedScenarioIds.includes(MusicConstants.MUSIC_JAR_NAME)
      && this.state.whitelistedScenarioIds.includes(AppConstants.FUNCTIONAL_JAR_NAME)) {
        musicFunctionalHeaderName = 'Music/ Functional Location';
      } else if (this.state.whitelistedScenarioIds.includes(MusicConstants.MUSIC_JAR_NAME)) {
        musicFunctionalHeaderName = 'Music Location';
      } else if (this.state.whitelistedScenarioIds.includes(AppConstants.FUNCTIONAL_JAR_NAME)) {
        musicFunctionalHeaderName = 'Functional Location';
      }
      labsPageColumns[2] = {
        id: 'musiclocation',
        header: () => musicFunctionalHeaderName,
        cell: item => ( item.musiclocation ),
        minWidth: '100px',
        allowLineWrap: true
      }
    }
    return labsPageColumns;
  }

  /* Provides the Action buttons on the Rapsi table header */
  getRaspiActions = (labDetails, labName, size, labId, labsData) => {
    return (
        <Header
            counter={ "(" + size + ")" }
            actions={
              <SpaceBetween direction="horizontal" size="xxl">
                { this.getSetupAndCalibrate(labDetails) }
                { this.getLabControls(labId, labsData) }
              </SpaceBetween>
            }
        >
          { labName }
        </Header>
    );
  }

  /**
   * Gets the content for Overview tab in table format
   */
  getRasPiTableOverview = (labName, labId, data) => {
    let overviewTable = [];
    data.rasPis.forEach((rasPiData, index) => {

      //Don't show non-ubuntu location if the lab has ubuntu machine configured
      if (this.isUbuntuLab(labId) && !this.isLocationUbuntuMachine(rasPiData.id, labId)) {
        return;
      }

      overviewTable.push({
        labId: labId,
        rasPiIndex: index,
        id: rasPiData.id,
        name: getRasPiName(rasPiData, data),
        location: getRasPiLoc(rasPiData, index, AppConstants.defaultLocation, data),
        getDownloadStatus: this.getDownloadStatus,
        getPingStatus: this.getPingStatus,
        detailsButton: this.getDetailsButtonForLocation(labId, index),
        getSetupAndCalibrate: this.getSetupAndCalibrate
      });
    })

    return (
        <Table
            columnDefinitions={ OVERVIEW_COLUMNS }
            selectedItems={ this.state.selectedRaspiItems[labId] || [] }
            onSelectionChange={ ({detail}) =>
                this.setState({
                  selectedRaspiItems: {...this.state.selectedRaspiItems, [labId]: [detail.selectedItems[0]]}
                })
            }
            items={ overviewTable }
            selectionType="single"
            trackBy="id"
            header={
              this.getRaspiActions(this.state.selectedRaspiItems[labId], labName, overviewTable.length, labId, data)
            }
        />
    );
  }

  /**
   * Gets the content for Details tab in table format
   */
  getRasPiTableDetails = (labName, labId, data) => {
    if (!labId || !data) {
      return;
    }

    let detailsTable = [];
    data.rasPis.forEach((rasPiData, index) => {
      detailsTable.push({
        labId: labId,
        rasPiIndex: index,
        id: rasPiData.id,
        name: this.getRasPiNameDetails(rasPiData, labId, data),
        location: this.getRasPiLocDetails(rasPiData, index, AppConstants.defaultLocation, labId, data),
        musiclocation: AppConstants.ACOUSTIC_LOCATION_TO_MUSIC_MAP[getRasPiLoc(rasPiData, index, AppConstants.defaultLocation, data)],
        closetalklocation: AppConstants.ACOUSTIC_LOCATION_TO_CLOSE_TALK_MAP[getRasPiLoc(rasPiData, index, AppConstants.defaultLocation, data)],
        mobilelocation: AppConstants.ACOUSTIC_LOCATION_TO_MOBILE_MAP[getRasPiLoc(rasPiData, index, AppConstants.defaultLocation, data)]
      });
    })

    // Logic to decide which location information to display on the labs page, based on whitelisted test suites
    let labsPageColumns = this.getLabsPageColumnsToDisplay();
    if (this.state.whitelistedScenarioIds.length > 0) {
      for (var location in AppConstants.labsPageLocationToScenarioMap) {
        let scenariosForLocation = AppConstants.labsPageLocationToScenarioMap[location];
        let numScenariosMatch = 0;
        for (var i = 0; scenariosForLocation && i < scenariosForLocation.length; i++) {
          if (this.state.whitelistedScenarioIds.includes(scenariosForLocation[i])) {
            numScenariosMatch += 1;
          }
        }
        if (numScenariosMatch === 0) {
          labsPageColumns = labsPageColumns.filter(item => item.id !== location);
        }
      }
    }

    return (
      <Box margin={{ top:'m' }}>
        {
          this.isLabPreferenceUnderEdit(labId, data) &&
          (<div>
            <AWSUI.Button variant='primary' text='Save' onClick={ () =>
              { this.saveDynamicMappingUserPreference(labId);}}>
            </AWSUI.Button>
            <AWSUI.Button variant='primary' text='Cancel' onClick={ () =>
              { this.cancelEditLabPreference(labId);}}>
            </AWSUI.Button>
          </div>)
        }
        {
          !this.isLabPreferenceUnderEdit(labId, data) &&
          <AWSUI.Button variant='primary' text='Edit' onClick={ () => {
                this.setIsLabPreferenceToEditState(labId, true); }}>
            </AWSUI.Button>
        }
        <AWSUI.FormField
          errorText={this.state.labsAndRasPis.data[labId].error.isError ? this.state.labsAndRasPis.data[labId].error.message : AppConstants.EMPTY}>
          <AWSUI.Table columnDefinitions={ labsPageColumns } items={ detailsTable } >
            <AWSUI.TableSorting sortableColumns={ SORTABLE_COLUMNS }/>
          </AWSUI.Table>
        </AWSUI.FormField>
      </Box>
    );
  }

  labEnvOnChange = (env) => {
    this.setState({labEnv: env})
  }

  /**
   * Gets lab location details to render.
   * @param rasPiData Raspi object retrieved from controller with details of current raspi.
   * @param index Index of current raspi in the list of current lab raspis
   * @param locationMap Static Map of locations. varies depending on scenario(Music/Acoustic/Closetalk)
   * @param labId ID of current lab
   * @param data Current lab data object retrieved from controller, contains added preference mapping built on load.
   * @returns Returns text box if under edit, else returns string value of location
   */
  getRasPiLocDetails = (rasPiData, index, locationMap, labId, data) => {
    if (this.isLabPreferenceUnderEdit(labId, data)) {
      let preferenceOptions = [];
      AppConstants.actorMappingKeys.forEach(actorLocation => {
        preferenceOptions.push({
          'id': actorLocation,
          'label': actorLocation
        })
      })
      return (<AWSUI.Select
            options={ preferenceOptions }
            selectedId={getRasPiLoc(rasPiData, index, locationMap, data)}
            onChange={event => { this.onChangeRaspiLocationMapping(event, rasPiData.id, labId) }}
            enableFiltering={ true }
            filteringLabel='label'
            placeholder= { AppConstants.EMPTY }
          >
      </AWSUI.Select>);
    } else {
      return getRasPiLoc(rasPiData, index, locationMap, data);
    }
  }

  /**
   * Calls validate on current user preference mapping state. Saves into Lab database record if validation is success.
   * @param labId ID of current lab.
   */
  saveDynamicMappingUserPreference = (labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    if (currLabsAndRaspi.hasOwnProperty(labId)) {
        let validation = this.validateRaspiLocationMapping(labId);
        currLabsAndRaspi[labId].error = validation;
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
            data: currLabsAndRaspi
          }
        });
        if (!validation.isError) {
          saveLabUserPreference(labId, JSON.stringify(currLabsAndRaspi[labId].preference)).then(labUpdateResponsePromise => {
            if (labUpdateResponsePromise.hasOwnProperty('error')) {
              currLabsAndRaspi[labId].error = {isError: true, message: "Unable to save user preference."}
              this.setState({
                labsAndRasPis: {
                  ...this.state.labsAndRasPis,
                  data: currLabsAndRaspi
                }
              });
            } else {
              this.setIsLabPreferenceToEditState(labId, false);
              currLabsAndRaspi[labId].preference = JSON.parse(labUpdateResponsePromise.data.preference);
              this.setState({
                labsAndRasPis: {
                  ...this.state.labsAndRasPis,
                  data: currLabsAndRaspi
                }
              });
            }
          });
        }
      }
  }

  /**
   * Validates user preference raspi name and location mapping.
   * @param labId ID of lab whose preference is to be validated.
   * @returns { isError: true if error exists, false otherwise, message: errorMessage }
   */
  validateRaspiLocationMapping = (labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let actorMappingPreference = currLabsAndRaspi[labId].preference["actor_mapping_preference"];
    let raspiNamesAreUnique = true;
    let locationsSelectedAreUnique = true;
    let actorNames = new Set();
    let locationMapped = new Set();
    Object.keys(actorMappingPreference).forEach(raspiId => {
        if (actorNames.has(actorMappingPreference[raspiId].name)) {
          raspiNamesAreUnique = false;
        } else {
          actorNames.add(actorMappingPreference[raspiId].name);
        }
        if (locationMapped.has(actorMappingPreference[raspiId].location)) {
          locationsSelectedAreUnique = false;
        } else {
          locationMapped.add(actorMappingPreference[raspiId].location);
        }
    })
    let errorMessage = AppConstants.EMPTY;
    if (!raspiNamesAreUnique) {
      errorMessage += "Raspi names must be unique. ";
    }
    if (!locationsSelectedAreUnique) {
      errorMessage += "More than one raspi cannot be mapped to the same location.";
    }
    let labEditAllowed = this.checkIfLabEditIsAllowed(labId);
    if (!labEditAllowed.isEditAllowed) {
      errorMessage += labEditAllowed.message;
    }
    return { isError: (!raspiNamesAreUnique || !locationsSelectedAreUnique || !labEditAllowed.isEditAllowed), message: errorMessage };
  }

  /**
   * Handles raspi location mapping change events when edit is performed.
   * @param event Raspi mapping change event
   * @param raspiId ID of raspi whose mapping is changed
   * @param labId ID of lab whose mapping is modified.
   */
  onChangeRaspiLocationMapping = (event, raspiId, labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    logToConsole("location selected");
    logToConsole(event);
    if (currLabsAndRaspi.hasOwnProperty(labId)) {
        currLabsAndRaspi[labId].preference["actor_mapping_preference"][raspiId].location = event.detail.selectedOption.id;

        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
            data: currLabsAndRaspi
          }
        });
      }
  }

  /**
   * Gets triggered when user changes raspi name.
   * @param event Raspi name change event
   * @param labId ID of lab being modified.
   * @param raspiId ID of raspi whose name is changed
   */
  onChangeRaspiName = (event, labId, raspiId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].lab) {
      currLabsAndRaspi[labId].preference["actor_mapping_preference"][raspiId].name = event.detail.value.trim();

      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currLabsAndRaspi
        }
      });
    }
  }

  /**
   * Cancels edit in progress. Reverts state of lab mapping and raspi name to previous saved state.
   * @param labId ID of lab for which preference is modified.
   */
  cancelEditLabPreference = (labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].lab) {
        currLabsAndRaspi[labId].isLabPreferenceUnderEdit = false;
        currLabsAndRaspi[labId].preference = buildUserPreferenceObject(currLabsAndRaspi[labId].lab, currLabsAndRaspi[labId].rasPis);
        currLabsAndRaspi[labId].error = { isError: false, message: null };
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
            data: currLabsAndRaspi
          }
        });
      }
  }

  /**
   * Sets edit state of lab to be true/false. Effects rendering of raspi name and mapping options.
   * @param labId ID of lab for which edit state must be changed.
   * @param editStatus true to set to edit, false to set read-only
   */
  setIsLabPreferenceToEditState = (labId, editStatus) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].lab) {
        currLabsAndRaspi[labId].isLabPreferenceUnderEdit = editStatus;
        let labEditAllowed = this.checkIfLabEditIsAllowed(labId);
        if (!labEditAllowed.isEditAllowed) {
          currLabsAndRaspi[labId].error = { isError: !labEditAllowed.isEditAllowed, message: labEditAllowed.message }
        }
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
            data: currLabsAndRaspi
          }
        });
      }
  }

  /**
   * Returns {isEditAllowed: True/False, message: Error message or null}
   * isEditAllowed is True if edit is allowed, false otherwise.
   */
  checkIfLabEditIsAllowed = (labId) => {
    let result = { isEditAllowed: true, message: null };
    if (this.isCloseTalkLab(labId)) {
      result.isEditAllowed = false;
      result.message = "Changes are not allowed for close talk lab setup. " ;
    }

    return result;
  }

  /**
   * Returns True if lab is configured for close talk, False otherwise.
   * If raspi is named withDAC then it is configured for close talk.
   */
  isCloseTalkLab = (labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isCloseTalkLab = false;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].rasPis) {
          const hatsPiNameSuffix = "WITHDAC";
          currLabsAndRaspi[labId].rasPis.forEach((rasPiData) => {
            if (rasPiData.hasOwnProperty("thingName")
                && rasPiData.thingName.toUpperCase().includes(hatsPiNameSuffix)) {
                  isCloseTalkLab = true;
                }
          })
      }
    return isCloseTalkLab;
  }

  /**
   * Gets raspi name details to render.
   * @param raspiData Raspi object retrieved from controller with details of current raspi.
   * @param labId ID of lab to which raspi belongs.
   * @param data Current lab data object retrieved from controller, contains added preference mapping built on load.
   * @returns Input text box with raspi name if lab is under edit, string name otherwise.
   */
  getRasPiNameDetails = (raspiData, labId, data) => {
    let raspiName = getRasPiName(raspiData, data);
    if (this.isLabPreferenceUnderEdit(labId, data)) {
      return <AWSUI.Input value={ raspiName } onChange={ event => {
        if (event.detail.value.trim() !== AppConstants.EMPTY) {
          this.onChangeRaspiName(event, labId, raspiData.id)
        }
    }}></AWSUI.Input>
    } else {
      return raspiName;
    }
  }

  /**
   * Returns if lab is under edit.
   * @param labId ID of lab to check for edit status.
   * @param data Current lab data object retrieved from controller, contains added preference mapping built on load.
   * @returns true if lab is in edit, false otherwise
   */
  isLabPreferenceUnderEdit = (labId, data) => {
    let isLabPreferenceUnderEdit = false;
    if (data && data.hasOwnProperty("isLabPreferenceUnderEdit")) {
      isLabPreferenceUnderEdit = data.isLabPreferenceUnderEdit;
    }
    return isLabPreferenceUnderEdit;
  }

  /**
   * Gets lab name for this lab ID
   */
  getLabName = (labId, data) => {
    if (labId && data && data.hasOwnProperty(labId)) {
      let lab = data[labId].lab;
      if (lab) {
        return lab.name;
      }
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets lab data for this lab ID
   */
  getLabData = (labId, data) => {
    if (labId && data && data.hasOwnProperty(labId)) {
      return data[labId];
    }
    return {};
  }

  /**
   * Gets all Raspi and labs data for the side navigation
   */
  getRasPiRecords = (data) => {
    let rasPiRecords = [];
    Object.keys(data).forEach(labId => {
          let labName = this.getLabName(labId, data);
          rasPiRecords.push({
            type: 'link',
            href: labId,
            text: labName
          });
        });
    return rasPiRecords;
  }

  isAQTSecurityWhitelistedCustomer = () => {
    const whiteListedScenarios = this.state.whitelistedScenarioIds;
    if (whiteListedScenarios && whiteListedScenarios.includes(AppConstants.AVS_SECURITY_JAR_NAME))
      return true;
    return false;
  }

  isACMAllowListed = () => {
    const whiteListedScenarios = this.state.whitelistedScenarioIds;
    if (whiteListedScenarios && whiteListedScenarios.includes(AppConstants.ACM))
      return true;
    return false;
  }

  /**
   * Gets tabs to display for expanded lab
   */
  getTabsForLab = (labId) => {
    let tabs = [];
    if (labId) {
      tabs = [{
        'label': 'Overview',
        'id': labId + '_overview_tab',
      }, {
        'label': 'Details',
        'id': labId + '_details_tab',
      }, {
        'label': 'Update Resources',
        'id': labId + '_sync_resources_tab',
      }, {
        'label': 'Sync Progress',
        'id': labId + '_sync_status_tab',
      }];
    }

    if (this.isAQTSecurityWhitelistedCustomer()) {
      tabs.push({
        'label': 'Hotspot',
        'id': labId + '_hotspot_status_tab',
      })
    }
    return tabs;
  }

  /**
   * Gets active tab for current expanded lab
   */
  getActiveTabForLab = (labId) => {
    let activeTabId = AppConstants.EMPTY;
    if (labId) {
      // Look up for current active tab for this lab in the map
      if (this.state.activeTabIdMap.hasOwnProperty(labId)) {
        activeTabId = this.state.activeTabIdMap[labId];
      } else {
        // Set overview tab by default
        activeTabId = labId + '_overview_tab';
      }
    }
    return activeTabId;
  }

  /**
   * Gets section which represents a lab
   */
  getSectionForLab = (labId, labsData) =>  {
    let labName = this.getLabName(labId, labsData);
    let labData = this.getLabData(labId, labsData)
    let tabs = this.getTabsForLab(labId);

    // If no tabs information was found, don't render anything
    if (!tabs || tabs.length == 0) {
      return (
        <div></div>
      )
    }
    let activeTabId = this.getActiveTabForLab(labId);
    return (
      <div>
        {
          labId && labData && Object.keys(labData).length > 0 ? (
            <div>
              <AWSUI.Tabs
                tabs={ tabs }
                variant="container"
                activeTabId={ activeTabId }
                onChange={ event => {
                this.setState({
                  activeTabIdMap: {
                    ...this.state.activeTabIdMap,
                    [labId]: event.detail.activeTabId
                    }
                  })
                }}
              />
              {
                activeTabId.includes('overview_tab') && (
                this.getRasPiTableOverview(labName, labId, labData)
                )
              }
              {
                activeTabId.includes('details_tab') && (
                this.getRasPiTableDetails(labName, labId, labData)
                )
              }
              {
                activeTabId.includes('sync_resources_tab') && (
                  <Box margin={{ top:'m' }}>
                    <SyncResourceControl params={{
                      labId: labId,
                      labData: labData,
                      whitelistedScenarioIds: this.state.whitelistedScenarioIds
                    }}
                    onTabChange={this.handleTabChangeSyncResources }
                    syncClicked={ this.handleSyncClicked }/>
                  </Box>
                )
              }
              {
                activeTabId.includes('sync_status_tab') && (
                  <Box margin={{ top:'m' }}>
                    <SyncResourceDetails params={{
                      labId: labId,
                      labData: labData,
                      socketData: this.state.labsAndRasPis.data,
                      whitelistedScenarioIds: this.state.whitelistedScenarioIds,
                      elapsedSeconds: this.state.elapsedSeconds
                    }}
                    onTabChange={ this.handleTabChangeSyncDetails }
                    syncClicked={ this.handleSyncClicked }/>
                  </Box>
                )
              }
              {
                activeTabId.includes('hotspot_status_tab') && (
                  <Box margin={{ top:'m' }}>
                    <HotspotController params={{
                      labId: labId,
                      labData: labData,
                      socketData: this.state.labsAndRasPis.data,
                      whitelistedScenarioIds: this.state.whitelistedScenarioIds,
                      elapsedSeconds: this.state.elapsedSeconds
                    }}
                    />
                  </Box>
                )
              }
            </div>
          ) : (
            <div align='center'>
              {
                getLoadingMessage(SyncResourceConstants.ERROR_NO_DATA)
              }
            </div>
          )
        }
      </div>
    );
  }

    /**
   * Invoked when particular location within the lab is expanded. All other locatins are collapsed automatically
   * in such case
   */
  onLabExpanded = (labName, event) => {
    if(labName) {
      let isExpanded = event.detail.expanded ? true : false;
      this.setState({
        ...this.state,
        expandedLab : isExpanded ? labName : AppConstants.EMPTY
      })
    }
  }

  /**
   * Returns whether location is expanded or not
   */
  isLabExpanded = (labName) => {
    if(labName) {
      return this.state.expandedLab === labName;
    }
    return false;
  }

  /**
   * Handles tab change event for sync resources tab
   */
  handleTabChangeSyncResources = () => {}

  /**
   * Handles tab change event for sync details tab
   */
  handleTabChangeSyncDetails = () => {}

  /**
   * Handles the event of clicking "Sync" from Sync status tab
   */
  handleSyncClicked = (labId, rasPiIndexes, downloadResourceJson, id) => {
    // Set Overview tab as default once Sync is kicked off
    this.setState({
      activeTabIdMap: {
        ...this.state.activeTabIdMap,
        [labId]: labId + '_overview_tab'
      }
    })

    // Invoke sync resource for each location one by one
    rasPiIndexes.map(rasPiIndex => {
      logToConsole('LabTestLog - Invoking resource sync for location ' + (rasPiIndex + 1) + ' for Lab ' + labId);
      logToConsole('LabTestLog - Resources to be syncd = ' + JSON.stringify(downloadResourceJson));
      this.syncResourceForLocation(labId, rasPiIndex, downloadResourceJson, id);
    });
  }

  /**
   * Perform resource sync for a particular location based on options received from Sync Details
   * or Sync status tab after hitting Sync button
   */
  syncResourceForLocation = (labId, rasPiIndex, downloadResourceJson, id) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;

    if (rasPiStatus) {
      this.runSyncRasPiWithPushMechanism(labId, currRaspiData, rasPiIndex, downloadResourceJson, id);
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  getRasPiStateButton = (labId, rasPiIndex, id) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    if (rasPiStatus) {
      return (
        <AWSUI.Button text={ AppConstants.rasPiAction[id].name } variant='link' icon={ AppConstants.rasPiAction[id].icon }
          disabled={ rasPiStatus[AppConstants.rasPiAction[id].action] }
          loading={ rasPiStatus[AppConstants.rasPiAction[id].action] }
          onClick={ () => {
            this.runActionRasPi(labId, currRaspiData, rasPiIndex, id);
          }}
        />
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Gets the setup and calibration buttons for rasPi
   */
  getSetupAndCalibrate = (labDetails) => {
    return (
      <span align='center'>
        <span className='awsui-util-ml-xs'>
        {
            labDetails && this.isLocationUbuntuMachine(labDetails[0].id, labDetails[0].labId) && this.getUbuntuLabCalibrationButton()
        }
        </span>
        <span className='awsui-util-ml-xs'>
        {
          labDetails ?
              this.getSetupCell(labDetails[0].labId, labDetails[0].id) :
              <AWSUI.Button variant='primary' text='Setup' iconAlign='right' icon='caret-down-filled' disabled={ true } />
        }
        </span>
        <span className='awsui-util-ml-xs'>
        {
          labDetails ?
              this.getCalibrationButton(labDetails[0].labId, labDetails[0].rasPiIndex, AppConstants.rasPiAction.CALIBRATE.id) :
              <AWSUI.Button  text='Calibrate' iconAlign='right' icon='caret-down-filled' disabled={ true } />
        }
        </span>
      </span>
    )
  }

  getSetupCell = (labId, rasPiId) => {
    return (
      <span>
        <span className={ this.props.classes.wrapper }>
          <AWSUI.ButtonDropdown
              variant="primary"
              disabled={ this.state.rasPiCmd.raspId === rasPiId ? this.state.rasPiCmd.loading : false }
              items={ DISTROS }
              onItemClick={ (event) => {
                this.getRasPiSetup(event.currentTarget, event.detail.id, rasPiId, labId);
              }}
          >
            Setup
          </AWSUI.ButtonDropdown>
        </span>
        { this.getRasCmdPop() }
      </span>
    );
  }

  getCalibrationButton = (labId, rasPiIndex, id) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    let rasPiId = currRaspiData[labId].rasPis[rasPiIndex].id;
    let calibrateStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.calibrate;
    let calibrateText = calibrateStatus ? STOP : START;

    const speakerCalibration = {
        "id": AppConstants.PINK_NOISE_FILE,
        "text": calibrateText
      };

    const skill = {
        "id": "DUT_SKILL",
        "text": "DUT Calibration - Skill",
        "disabled": calibrateStatus,
        "items": CALIBRATE_SKILL_ITEM_LIST
      };

    const happySong = {
      "id": "DUT_HAPPY",
      "text": "DUT Calibration - Happy Song",
      "disabled": calibrateStatus,
      "items": CALIBRATE_HAPPY_ITEM_LIST
    };

    let items = this.isUbuntuLab(labId) ? [skill, happySong] : [speakerCalibration, skill, happySong];

    if (rasPiStatus) {
      return (
        <AWSUI.ButtonDropdown items={items} expandableGroups={true}
          disabled={ currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.calibrationInProgress }
          onItemClick={ (event) => {
            if (!calibrateStatus) {
              this.runTalkRasPi(labId, currRaspiData, rasPiIndex, id, rasPiId, calibrateStatus, event.detail.id);
            } else {
              this.runStopRasPi(labId, currRaspiData, rasPiIndex, AppConstants.rasPiAction.STOP.id, rasPiId);
            }
          }}
        >
          Calibrate
        </AWSUI.ButtonDropdown>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Private utility to show Lab Calibration tool button on the Labs page for Ubuntu Machine.
   */
  getUbuntuLabCalibrationButton = () => {
    return (
      <span>
        <span className={ this.props.classes.wrapper }>
        <AWSUI.Button text='Lab Calibration Tool'
          onClick={ (event) => {
            this.setState({
              anchorEl: event.currentTarget,
              rasPiCmd: {
                loading: false,
                command: AppConstants.MORGAN_UBUNTU_LAB_CALIBRATION_TOOL_CMD,
                error: { isError: false, message: null },
                popoutSize: 3
            }});
          }}
        />
        </span>
        { this.getRasCmdPop() }
      </span>
    );
  }

  /**
  * Delay for a number of milliseconds
  */
  sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
  }

  /**
   * Gets controls common across all locations such as Ping all pis, stop audio, update software for all
   * and update resources for all
   */
  getLabControls = (labId, data) => {
    return (
      <AWSUI.ButtonDropdown items={ LABCONTROL_DEFINITION } text='Lab Controls'
        loading={ this.state.actionAllLabs || this.state.labsAndRasPis.data[labId].actionAllPis }
          onItemClick={ (event) => {
            this.runActionSequence(data, event.detail.id);
          }}
        >
      </AWSUI.ButtonDropdown>
    );
  }

  getPingStatus = (labId, rasPiIndex) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    if (rasPiStatus) {
      return (
        <div>
          { rasPiStatus.status ? (
            <div className={ rasPiStatus.status === AppConstants.ONLINE ? 'awsui-util-status-positive' : 'awsui-util-status-negative' }>
              <AWSUI.Icon name={ rasPiStatus.status === AppConstants.ONLINE ? 'status-positive' : 'status-negative' } />
              <span className='dark-grey-color-text awsui-util-ml-xs'>
                { rasPiStatus.status }
              </span>
            </div>
          ) : (
            <span>
              <AWSUI.Spinner />
            </span>
          )}
        </div>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Gets the download status for each location
   */
  getDownloadStatus = (labId, rasPiIndex) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    // Retrieve the shadow state for current location
    let shadowState = rasPiStatus.hasOwnProperty('shadowState') ? rasPiStatus.shadowState : {};
    //logToConsole('LabTestLog - Shadow data for progressbar for location ' + rasPiIndex + ' = ' + JSON.stringify(shadowState));
    return (
      <AWSUI.ProgressBar
        description={
          <div align='left' className='nowrap-style'>
            <div className='awsui-util-ml-s'>
            {
              getOverviewProgressBarState(shadowState, this.state.elapsedSeconds)
            }
            </div>
            {
              <span className='awsui-util-ml-m'>
              {
                 getOverallCompletedCount(shadowState, this.state.elapsedSeconds)
              }
              {
                this.getDetailsButtonForLocation(labId, rasPiIndex, shadowState)
              }
              </span>
            }
          </div>
        }
        value={ getOverallProgressPercentage(shadowState) }
      />
    )
  }

  /**
   * Gets details button for particular location on overview tab
   */
  getDetailsButtonForLocation = (labId, rasPiIndex, shadowState) => {
    let isDisabled = shadowState && Object.keys(shadowState).length > 0 ? false : true;
    return (
      <AWSUI.Button className='awsui-util-ml-s'
        variant={ 'link' }
        icon={ 'zoom-in' }
        text={ 'Details'}
        disabled={ isDisabled }
        onClick={ event => {
          this.setState({
            activeTabIdMap: {
              ...this.state.activeTabIdMap,
              [labId]: labId + '_sync_status_tab'
            }
          })
       }}
      />
    )
  }

  runActionRasPi = (labId, currRaspiData, rasPiIndex, actionId) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionRasPi(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId).then(response => {
      let status = AppConstants.OFFLINE;
      if (response.hasOwnProperty("success")
          && response.success.hasOwnProperty("payload")
          && response.success.payload.hasOwnProperty("thingStatus")
          && response.success.payload.thingStatus.toUpperCase().includes(AppConstants.ONLINE.trim().toUpperCase())) {
            status = AppConstants.ONLINE;
      } else if (response.hasOwnProperty("error")) {
        status = AppConstants.STATUS_ERROR;
      }
      currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
        ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
        status: status,
        [AppConstants.rasPiAction[actionId].action]: false
      };
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
         data: currRaspiData
        }
      });
      return;
    });
  }

  /**
   * Automotive Lab uses only one ubuntu for running tests
   * Hence we use v2 update api to perform software update
   * by passing specific mdx plugins
   * @param labId
   * @param ubuntuId
   * @param actionId
   * @param currRaspiData
   * @returns {Promise<T>}
   */
  performAutomotiveUbuntuActions = (labId, ubuntuThingIndex, actionId, currRaspiData) => {
    const ubuntuThingId = currRaspiData[labId].rasPis[ubuntuThingIndex].id;
    currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionUbuntu(labId, ubuntuThingId, actionId).then(response => {
      let status = AppConstants.OFFLINE;
      if (response.hasOwnProperty("success")
        && response.success.hasOwnProperty("payload")
        && response.success.payload.hasOwnProperty("thingStatus")
        && response.success.payload.thingStatus.toUpperCase().includes(AppConstants.ONLINE.trim().toUpperCase())) {
        status = AppConstants.ONLINE;
      } else if (response.hasOwnProperty("error")) {
        status = AppConstants.STATUS_ERROR;
      }
      currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus = {
        ...currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus,
        status: status,
        [AppConstants.rasPiAction[actionId].action]: false
      };
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currRaspiData
        }
      });
      return;
    });
  }

  runActionRasPiWithReturn = (labId, currRaspiData, rasPiIndex, actionId, body) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionRasPiWithBodyAndAckTimeout(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId, body, SYNC_QUERY_TIMEOUT).then(response => {
      if (!response.hasOwnProperty('error')) {
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          status: response.success ? AppConstants.ONLINE : AppConstants.OFFLINE,
          [AppConstants.rasPiAction[actionId].action]: false
        };
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           data: currRaspiData
          }
        });
      } else {
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          [AppConstants.rasPiAction[actionId].action]: false
        };
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           status: false,
           data: currRaspiData
          }
        });
      }
      if (response.hasOwnProperty('success')) {
        return response.success;
      }
      if (response.hasOwnProperty('error')) {
        logToConsole("Error content: " +  response.error);
        return response;
      }
      return;
    });
  }

  runStopRasPi = (labId, currRaspiData, rasPiIndex, actionId, rasPiId) => {
    return actionRasPi(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId).then(response => {
      if (!response.hasOwnProperty('error')) {
        logToConsole('SUCCESS runStopRasPi: ', rasPiId);
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          calibrate: false
        };

        for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
          currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
            ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
           calibrationInProgress : false,
          };
        }

        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           data: currRaspiData
          }
        });
      } else {
        logToConsole('ERROR runStopRasPi: ', rasPiId);
      }
      return;
    });
  }

  runTalkRasPi = (labId, currRaspiData, rasPiIndex, actionId, rasPid, calibrateStatus, talkFileName) => {
    const { PINK_NOISE_FILE_TIMEOUT } = AppConstants;
    let newCalibrateStatus = calibrateStatus ? false : true;
    let rasPiName = currRaspiData[labId].rasPis[rasPiIndex].name;
    let controlName = AppConstants.RAS_PI_CONTROL_NAME;
    const hatsPiNameSuffix = "WITHDAC";
    if (rasPiName.toUpperCase().includes(hatsPiNameSuffix)) {
       controlName = AppConstants.HIFI_PI_CONTROL_NAME;
    }
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      calibrate: newCalibrateStatus,
    };

    for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
      if (rasPiIndexIn !== rasPiIndex) {
        currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
          calibrationInProgress : true,
        };
      }
    }

    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });

    return talkRasPi(labId, rasPid, talkFileName, PINK_NOISE_FILE_TIMEOUT, controlName).then(response => {
      if (!response.hasOwnProperty('error')) {
        logToConsole('SUCCESS runTalkRasPi: ', rasPid);
        this.resetCalibrationState(labId, currRaspiData, rasPiIndex);
        return;
      } else {
        logToConsole('ERROR runTalkRasPi: ', rasPid);
        this.resetCalibrationState(labId, currRaspiData, rasPiIndex);
        return;
      }
    });
  }

  runSyncRasPiWithPushMechanism = (labId, currRaspiData, rasPiIndex, downloadResourceJson, actionId) => {
    let body = downloadResourceJson;
    let rasPiName = currRaspiData[labId].rasPis[rasPiIndex].name;
    logToConsole("Current raspi for sync: " + rasPiName);

    let downloadStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.downloadStatus;
    if (downloadStatus === AppConstants.DOWNLOAD_STATUS.INITIAL) {
      // display initial state immediately after user click sync button
      // haven't started, then Starting
      currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.downloadStatus = AppConstants.DOWNLOAD_STATUS.START;
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currRaspiData
        }
      });
    }
    this.runActionRasPiWithReturn(labId, currRaspiData, rasPiIndex, actionId, body);
  }

  resetCalibrationState = (labId, currRaspiData, rasPiIndex) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      calibrate: false,
    };

    for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
      currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
        ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
        calibrationInProgress : false,
      };
    }

    this.setState({
    labsAndRasPis: {
      ...this.state.labsAndRasPis,
      data: currRaspiData
    }
   });
  }

  runActionSequenceAllLabs = (labsAndRasPisData, actionId) => {
    let allActionPromises = [];
    this.setState({ actionAllLabs: true });
    Object.keys(labsAndRasPisData).forEach(labId => {
      labsAndRasPisData[labId].rasPis.forEach((rasPiData, index) => {
        allActionPromises.push(this.runActionRasPi(labId, labsAndRasPisData, index, actionId));
      });
    });
    Promise.all(allActionPromises).then(() => {
      this.setState({ actionAllLabs: false });
    });
  }

  /**
   * Find the Automotive Companion thing index for the current lab's things
   * using actor mapping preference
   * @param labThings
   * @returns {string|null}
   */
  findAutomotiveCompanionThingIndex = (labThings) => {
    const actor_mapping_preference = ld.get(labThings, 'preference.actor_mapping_preference');
    const rasPis = ld.get(labThings, 'rasPis');

    let rasPiKey;
    for (var key in actor_mapping_preference){
      const location = actor_mapping_preference[key].location;
      if (location==='Automotive Companion') {
        rasPiKey = key;
        break;
      }
    }

    for(var rasPi in rasPis) {
      const rasPiId = rasPis[rasPi].id;
      if (rasPiKey === rasPiId) {
        return rasPi;
      }
    }
    return null;
  }

  runActionSequence = (labAndRasPis, actionId) => {
    let allActionPromises = [];
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    currLabsAndRaspi[labAndRasPis.lab.id].actionAllPis = true;
    const isUbuntuLab = this.isUbuntuLab(labAndRasPis.lab.id);
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currLabsAndRaspi
      }
    });
    if (isUbuntuLab === true) {
      const ubuntuThingIndex = this.findAutomotiveCompanionThingIndex(currLabsAndRaspi[labAndRasPis.lab.id]);
      if (actionId === AppConstants.rasPiAction.UPDATE.id) {
        allActionPromises.push(this.performAutomotiveUbuntuActions(labAndRasPis.lab.id, ubuntuThingIndex, actionId, currLabsAndRaspi));
      } else if (actionId === AppConstants.rasPiAction.STOP.id) {
        const companyId = ld.get(currLabsAndRaspi[labAndRasPis.lab.id], 'lab.companyId');
        const rasPiName = ld.get(currLabsAndRaspi[labAndRasPis.lab.id].rasPis[ubuntuThingIndex], 'thingName').replace(companyId + '-', '');
        allActionPromises.push(sendMQTTMessage(companyId, rasPiName, STOP_MORGAN_NOISE));
      }
    } else {
      Object.keys(labAndRasPis.rasPis).forEach((rasPiData, index) => {
        logToConsole("actionId " + actionId);
        if (actionId === AppConstants.rasPiAction.SYNC.id) {
          logToConsole("Entering into Sync");
          allActionPromises.push(this.runSyncRasPiWithPushMechanism(labAndRasPis.lab.id, currLabsAndRaspi, index, actionId));
        } else if (actionId === AppConstants.rasPiAction.UPDATE.id && isUbuntuLab === true) {
          allActionPromises.push();
        } else {
          logToConsole("Other options");
          allActionPromises.push(this.runActionRasPi(labAndRasPis.lab.id, currLabsAndRaspi, index, actionId));
        }

      });
    }
    Promise.all(allActionPromises).then(() => {
      let currLabsAndRaspi = this.state.labsAndRasPis.data;
      currLabsAndRaspi[labAndRasPis.lab.id].actionAllPis = false;
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currLabsAndRaspi
        }
      });
    });
  }

  render() {
    // Don't auto refresh component for fetching data once MAX_WAIT_TIME is elapsed
    if(this.state.elapsedSeconds > SyncResourceConstants.MAX_WAIT_TIME) {
      clearInterval(this.interval);
    }
    const { classes } = this.props;
    let rasPiRecords = this.getRasPiRecords(this.state.labsAndRasPis.data);
    return (
      <div className={ classes.root }>
        { this.state.labsAndRasPis.error.isError || this.state.labsAndRasPis.loading ? (
          (() => {
            if (this.state.labsAndRasPis.error.isError) {
              return (
                <AWSUI.Alert
                  header='Cannot retrieve labs at this time'
                  content={ this.state.labsAndRasPis.error.message }
                  type='error'
                ></AWSUI.Alert>
              )
            } else {
              return ( <div align='center'><CircularProgress size={ 50 } /></div> )
            }
          })()
        ) : (
          <Grid disableGutters gridDefinition={[{colspan: 2}, {colspan: 10}]}>
              <div className={classes.nav}>
                  <AWSUI.SideNavigation
                      header={{ href: '', text: 'Labs' }}
                      activeHref={this.state.expandedLab === AppConstants.EMPTY ? rasPiRecords[0].href : this.state.expandedLab}
                      onFollow={event => {
                          event.preventDefault();
                          this.setState({
                              expandedLab: event.detail.href
                          })
                      }
                      }
                      items={rasPiRecords}
                  />
              </div>
              <div>
                  { this.state.expandedLab === AppConstants.EMPTY ?
                      this.getSectionForLab(rasPiRecords[0].href, this.state.labsAndRasPis.data) :
                      this.getSectionForLab(this.state.expandedLab, this.state.labsAndRasPis.data)
                  }
              </div>
          </Grid>
        )}
      </div>
    );
  }
}

Labs.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object
};

export default withStyles(styles, {params:{}})(Labs);
