import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { connect } from 'react-redux'

import { logToConsole } from '../../../Util';
import { getLabThings } from 'redux/actions/labs';
import './index.css';
import { getAutoSyncResourcesField, fallBackToUtterancePlay } from '../uiUtils';
import { setCheckedStateInTestParams } from '../uiActionsUtils';


class PiMapper extends Component {
  static propTypes = {
    config: PropTypes.object,
    labId: PropTypes.string,
    labsInAccount: PropTypes.arrayOf(PropTypes.object),
    selectedScenario: PropTypes.object.isRequired,
    selectedScenarioError: PropTypes.object.isRequired,
    selectedScenarioCallBack: PropTypes.func.isRequired,
    scenarioErrorCallBack: PropTypes.func.isRequired,
  }

  state = {
    selectedOptions: {},
    loadFallBackToUtterancePlay: false,
    utteranceInjectionSelectable: false,
  }

  UTTERANCE_INJECTION_SUPPORTED_MARKETPLACE = ['en_US', 'en_CA', 'en_AU']

  componentDidMount() {
    // Get things for the given lab
    this.props.getLabThings(this.props.labId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.labId !== prevProps.labId) {
      this.props.getLabThings(this.props.labId);
    }
  }

  onChangeHandler = (actorIndex) => {
    return (event) => {
      if (!event) return;

      this.setState({
        selectedOptions: {
          ...this.state.selectedOptions,
          [actorIndex]: event.detail.selectedOption
        }
      })

      const { selectedScenario } = this.props;

      this.props.selectedScenarioCallBack({
        ...selectedScenario,
        customTestOptions: {
          ...selectedScenario.customTestOptions,
          actorLabMapping: {
            ...selectedScenario.customTestOptions.actorLabMapping,
            [actorIndex]: event.detail.selectedOption.value
          }
        }
      });
    }
  }

  /**
   * If dynamic mapping is used to set user preferences, then
   * use the mapping to get list of drop down options based on
   * {raspi preferred name => IOT Thing name}
   *
   * @param {object} things Array of lab thing objects
   * @param {object} currentLab Lab object with lab preference info
   * @returns Array of objects where each entry is preferred raspi name mapped to its iot thing name.
   */
  getDropdownOptionsFromLabPreference = (things, currentLab) => {
    let options = [];
    if (currentLab && currentLab.preference) {
      try {
        // Parse lab preferences to get preferred actor mapping
        let labPreference = JSON.parse(currentLab.preference);
        let actor_mapping_preference = labPreference.actor_mapping_preference;
        // Create mapping of thingId -> thing details for each lab thing
        var thingsById = things.reduce(function (map, thingObj) {
          map[thingObj.id] = thingObj;
          return map;
        }, {});
        // For each preferred naming get corresponding IOT thing name mapping
        Object.keys(actor_mapping_preference).forEach(thingId => {
          let thingPreferredName = actor_mapping_preference[thingId].name;
          let thingInfo = thingsById[thingId];
          options.push({
            label: thingPreferredName,
            value: thingInfo.thingName,
            id: `option-${thingPreferredName}`
          });
        });
        // Sort by label
        options.sort((thingA, thingB) => (thingA.label > thingB.label) ? 1 : -1);
      } catch (error) {
        logToConsole("Error parsing lab preference json string");
      }

    }

    return options;
  }

  /**
   * Render dropdown for selecting RasPi
   * @param {number} actorIndex
   */
  renderThingsDropdown = (actorIndex) => {
    const { labId, labs, newRun: { scenarioErrors }, currentLab } = this.props;
    const errors = scenarioErrors.customTestOptions;

    const things = labs.labToThingsMapping[labId];
    let options = this.getDropdownOptionsFromLabPreference(things, currentLab);
    if (options.length == 0) {
      options = things.map(thing => ({
        label: thing.name.substring(thing.name.lastIndexOf('-') + 1),
        value: thing.thingName,
        id: `option-${thing.id}`
      }));
    }

    const errorText = errors && errors[actorIndex] ? `Please select a pi` : '';
    return (
      <AWSUI.FormField
        errorText={errorText}
      >
        <div style={{ maxWidth: 600 }}>
          <AWSUI.Select
            options={options}
            className={'scroll-height'}
            selectedOption={this.state.selectedOptions[actorIndex]}
            onChange={this.onChangeHandler(actorIndex)}
            enableFiltering={true}
            filteringLabel='label'
            selectedAriaLabel='Selected'
            placeholder={'Choose Pi'}
          ></AWSUI.Select>
        </div>
      </AWSUI.FormField>
    );
  }

  /**
   * Renders header for Pi Mapping table
   * @param {array} headers
   */
  getHeader = (headers = []) => {
    return (
      <AWSUI.ColumnLayout columns={2} borders="vertical">
        <div
          className="awsui-util-mb-xs awsui-table-header-content"
          data-awsui-column-layout-root="true"
        >
          {headers.map(header => (<span>{header}</span>))}
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  /**
  * Method to display checkbox to enable/ disable proxy settings
  * @param {*} checkedState - state parameter to indicate whether this box is checked or not
  * @param {*} params - params where this state parameter is located, in this case, selectedScenario
  * @param {*} paramsUpdateCallBack - callback function to be able to update params, eg, selectedScenarioCallBack
  * @param {*} disabled
  */
  getProxySettings = (checkedState, params, paramsUpdateCallBack, disabled = false,
    checkboxLabel = 'Enable Proxy Settings') => {
    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={2} borders='none'>
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox label={checkboxLabel}
                  disabled={disabled}
                  checked={checkedState}
                  onChange={event => {
                    setCheckedStateInTestParams(event, "isProxyEnabled",
                      params, paramsUpdateCallBack)
                    this.setState({
                      loadFallBackToUtterancePlay: !checkedState,
                    });
                  }} />
                <AWSUI.Tooltip text='Check this box to enable proxy settings. Refer to AQT documentation for
                      further details' size='small' position='top'
                  className='awsui-util-no-margin tooltip-inner'>
                  <AWSUI.Icon name='status-info'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  getCheckboxOptionsForCustom = (selectedScenario, selectedScenarioCallBack) => {
    let utteranceInjectionSelectable;
    if (this.UTTERANCE_INJECTION_SUPPORTED_MARKETPLACE.includes(selectedScenario.marketPlace)) {
      utteranceInjectionSelectable = true;
    } else {
      utteranceInjectionSelectable = false;
    }
    if (this.state.utteranceInjectionSelectable !== utteranceInjectionSelectable) {
      this.setState({
        utteranceInjectionSelectable: utteranceInjectionSelectable,
      });
    }
    return (
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root='true'>
          {getAutoSyncResourcesField(selectedScenario.isAutoSyncEnabled,
            selectedScenario, selectedScenarioCallBack)}
          {this.state.utteranceInjectionSelectable &&
            this.getProxySettings(selectedScenario.isProxyEnabled,
              selectedScenario, selectedScenarioCallBack, false, "Enable Utterance Injection")
          }
          {this.state.loadFallBackToUtterancePlay &&
            fallBackToUtterancePlay(selectedScenario.fallBackToUtterancePlay, selectedScenario, selectedScenarioCallBack)
          }
        </div>
      </AWSUI.ColumnLayout>
    )
  }

  render() {
    const { labId, labs, selectedScenario: { customTestOptions = {} } = {},
      selectedScenario, selectedScenarioCallBack } = this.props;

    if (!selectedScenario.hasOwnProperty('fallBackToUtterancePlay')) {
      selectedScenario.fallBackToUtterancePlay = false;
    }

    if (!labId || labs.loading || !labs.labToThingsMapping[labId] || !customTestOptions.config) {
      return (<div></div>);
    }

    const config = customTestOptions.config;
    const { noiseLocationPi, recordingLocationPi, actorCount } = config;
    const actorMapping = {};
    if (actorCount > 0) {
      for (let location = 1; location <= actorCount; location++) {
        if (!noiseLocationPi || (location != noiseLocationPi)) {
          if (actorMapping[location]) actorMapping[location].push('Speech')
          else actorMapping[location] = ['Speech'];
        }
      }
    }

    if (noiseLocationPi) {
      if (actorMapping[noiseLocationPi]) actorMapping[noiseLocationPi].push('Noise')
      else actorMapping[noiseLocationPi] = ['Noise'];
    }

    if (recordingLocationPi) {
      if (actorMapping[recordingLocationPi]) actorMapping[recordingLocationPi].push('Recording')
      else actorMapping[recordingLocationPi] = ['Recording'];
    }

    return (
      <div>
        {this.getCheckboxOptionsForCustom(selectedScenario, selectedScenarioCallBack)}
        <div className="awsui-util-container awsui-util-m-l">
          <div className="awsui-util-container-header">
            {this.getHeader(['Actor Type', 'RasPi'])}
          </div>
          <div>
            <AWSUI.ColumnLayout columns={2} borders="horizontal" >
              <div data-awsui-column-layout-root="true" className='mapping-grid'>
                {
                  Object.keys(actorMapping).map(actor => {
                    return (
                      <React.Fragment>
                        <div>{`Pi${actor} (${actorMapping[actor].join(', ')})`}</div>
                        <div>{this.renderThingsDropdown(actor, actorMapping[actor])}</div>
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ newRun, labs }) => ({ newRun, labs }),
  { getLabThings }
)(PiMapper);
