import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../_Constants/AppConstants';
import {
  getTestTypeToDisplay,
  getDisplayNameForId,
  renderLabelElement,
  getDisplayFieldForCustomFar,
  getCustomOptionsDisplayMap,
  getDisplayFieldForCheckBox,
  getTrainers,
  getAmazonIdToDisplay,
  getFunctionalTestCases,
  getFunctionalScenarios,
  isA4PCDevice,
  logToConsole,
  getFunctionalTestCasesCutomOptions
} from '../../Util';
import { isValidDeviceConfig } from './Util';
import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';
import { FAR_CUSTOM_DUT_TABLE_COLUMNS } from './FARCustomDUTTableConfig';
import { RASPI_MAPPING_TABLE_COLUMNS } from './RaspiMappingTableConfig';
import HotspotOptions from './HotspotOptions.js'
import ld from "lodash";

class TestOptionsDisplay extends React.Component {

  /**
   * Gets custom options in display friendly format
   * @param options Custom options
   * @param testSuite Test Suite
   * @return Returns custom options list in display friendly format
   */
  customOptPretty(options, testSuite) {
    let customOptionsDisplay = getCustomOptionsDisplayMap(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(customOptionsDisplay).map(option => {
           let optionDisplay = option;
           // If it's a Close Talk Scenario, display 0.9 m 90 deg as HATS
           if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID && option === AppConstants.LOCATION_THREE_NINETY) {
             optionDisplay = AppConstants.CT_HATS_LOCATION;
           }
           return (<div key={ option } > { optionDisplay + ' - ' + customOptionsDisplay[option] } </div>)
        })}
      </div>
    );
  }

  /**
   * Gets the list of trainers in display friendly format
   * @param options Custom options
   * @return Returns list of trainers in display friendly format
   */
  getTrainersPretty(options) {
    let trainersDisplay = getTrainers(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div> { trainersDisplay } </div>
      </div>
    );
  }

  /**
   * Gets the list of Functional Test cases for Functional Custom scenario in display friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getFunctionalTestCasesPretty(testParams) {
    let functionalTestCasesDisplay = getFunctionalTestCases(testParams);
    return (
      <div className='awsui-util-spacing-v-s'>
        { Object.keys(functionalTestCasesDisplay).map(option => {
           return (<div key={ option } > { option + ' ==> ' + functionalTestCasesDisplay[option] } </div>)
        })}
      </div>
    );
  }
  /**
   * Gets the list of Functional Test cases for custom options to display in friendly format
   * @param options Custom options
   * @return Returns list of Functional test cases in display friendly format
   */
  getFunctionalTestCasesCustomOptionsPretty(options) {
    let functionalTestCasesDisplay = getFunctionalTestCasesCutomOptions(options);
    return (
      <div className='awsui-util-spacing-v-s'>
        <div>
          { functionalTestCasesDisplay[0] }
          { functionalTestCasesDisplay.slice(1).map(option => {return ', ' + option}) }
        </div>
      </div>
    );
  }

  /**
   * Render SPL settings in display friendly format
   * @param options SPL Setting
   * @param testSuite Test Suite
   * @return Returns SPL settings
   */
  renderSPLSettings(testSuite, scenarioType, spl) {
    if (testSuite !== AppConstants.QUAL_SCENARIO_ID || scenarioType === 'QUAL_WWDD' || !spl) return;

    const measuringUnit = spl.measuringUnit;

    const settings = Object.keys(spl)
      .filter(k => spl[k] && k !== 'measuringUnit')
      .map(key =>
        (
          <div key={key}>
            {
              key === 'dut'
                ? (<div>{`${key.toUpperCase()}: ${spl[key]} ${measuringUnit}`}</div>)
                : (
                  Object.keys(spl[key])
                  .map(loc => (<div>{`${key.toUpperCase()} (${loc}): ${spl[key][loc]} ${measuringUnit}`}</div>))
                )
            }
          </div>
        )
      );

    if (!settings) return;

    return (
      <div>
        <div className='awsui-util-label'><b>SPL Settings</b></div>
        <div className={`awsui-util-label`}>
          <div className='awsui-util-spacing-v-s'>{settings}</div>
        </div>
      </div>
    );
  }

  /**
   * Method used to display Scenario Type (for Acoustic) or MSP/ MSP (for Music) on Test options page
   * @param testSuite To decide whether to display scenario Type or MSP/ MSK
   * @param scenarioType Scenario Type for Acoustic/ MSP or MSK for Music
   * @param testType Test type for Acoustic
   * @return Name of Scenario Type for Acoustic or MSP/ MSK for music
  */
  getTestTypeToDisplay = (testSuite, scenarioType, testCategory, testType) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_TYPE, scenarioType);
    }
    if ([AppConstants.SECURITY_SCENARIO_ID, AppConstants.UPL_SCENARIO_ID, AppConstants.STABILITY_SCENARIO_ID].includes(testSuite)) {
      return getTestTypeToDisplay(testSuite, scenarioType, testCategory, AppConstants.EMPTY);
    } else if (testSuite !== AppConstants.FUNCTIONAL_SCENARIO_ID) {
      return getTestTypeToDisplay(testSuite, scenarioType, testCategory, testType);
    } else {
      return getDisplayNameForId(getFunctionalScenarios(), scenarioType);
    }
  }

  /**
   * Method used to display 'Scenario Type' label based on Test Suite
   * @param testSuite Test Suite
   * @return Scenario Type label based on type of test suite
   */
  getTestTypeLabelToDisplay = (testSuite) => {
    if (AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY.hasOwnProperty(testSuite)) {
      return AppConstants.TEST_OPTIONS_SCENARIO_TYPE_DISPLAY[testSuite];
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets Device Virtual Assistant label to display
   */
  getDeviceVirtualAssistanLabelToDisplay = (testSuite) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return AppConstants.DEVICE_VIRTUAL_ASSISTANT_LABEL;
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets range of utterances to be played for Auto Local Search Full test scenario
   */
  getUtteranceRangeToDisplay = (fromTranscripts, toTranscripts) => {
    if (fromTranscripts && toTranscripts) {
      return fromTranscripts + ' to ' + toTranscripts;
    }
  }

  /**
   * Gets value for selected Device Virtual Assistant from New Run page
   */
  getDeviceVirtualAssistant = (testSuite, deviceVirtualAssistant) => {
    if (testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
      return getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS, deviceVirtualAssistant);
    }
    return AppConstants.EMPTY;
  }

  /**
   * Method to display test options for Security scenario
   */
  renderTestOptionsForSecurity = (params) => {
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
            <div className='awsui-util-action-stripe-group'>
              <AWSUI.Button text='Back' variant='primary' disabled={ params.disabled } onClick={ () => { params.back() } } />
            </div>
          </div>
        </div>
        <div>
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div></div>
              <div align='center'>
                { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      params.testParams.testSuite)) }
              </div>
              <div></div>
            </div>
          </AWSUI.ColumnLayout>
        </div>
        <div align='center'>
          {
            this.getRaspiLocationMappingTable(params)
          }
        </div>
      </div>
    );
  }

  /**
   * Generates table containing DUT Information for FAR Custom test
   * Table contains Customer ID, DSN, Amazon ID, Device Name & Build Information
   */
  getFARCustomDUTTable = (params) => {
    let deviceMapping = params.testParams.deviceMapping;
    logToConsole('TestLog - DUT mapping inside Test options page = ' + JSON.stringify(deviceMapping));
    let dutTableDataIn = [];
    Object.keys(deviceMapping).forEach(function(dutKey) {
      let dut = deviceMapping[dutKey];
      dutTableDataIn.push({
        customerId: dut.hasOwnProperty('customerId') ? dut['customerId'] : AppConstants.EMPTY,
        deviceName: dut.hasOwnProperty('deviceName') ? dut['deviceName'] : AppConstants.EMPTY,
        dsn: dut.hasOwnProperty('dsn') ? dut['dsn'] : AppConstants.EMPTY,
        amazonId: dut.hasOwnProperty('amazonId') ? dut['amazonId'] : AppConstants.EMPTY,
        buildInfo: dut.hasOwnProperty('buildInfo') ? dut['buildInfo'] : AppConstants.EMPTY,
      });
    });
    return (
      <div align='center'>
        <AWSUI.FormField
          label={ <h4>DUT Information</h4> }
          className='awsui-util-m-l'
      >
          <AWSUI.Table
            columnDefinitions={ FAR_CUSTOM_DUT_TABLE_COLUMNS }
            items={ dutTableDataIn }
          >
          </AWSUI.Table>
        </AWSUI.FormField>
      </div>
    );
  }

  /**
   * Generates table containing current raspi mapping before job submission.
   * Contains two columns (Location, Name of raspi). Example value: ("0.9 m 90 deg", "AQT_raspi_x")
   */
  getRaspiLocationMappingTable = (params) => {
    let userPreferenceMapping = params.otherParams.userPreferenceMapping;
    if (userPreferenceMapping && Object.keys(userPreferenceMapping).length > 0) {
      let raspiMappingItems = [];
      Object.keys(userPreferenceMapping).forEach(function(locationKey) {
        raspiMappingItems.push({
          location: locationKey,
          name: userPreferenceMapping[locationKey].name
        });
      });
      return (
        <div align='center'>
          <AWSUI.FormField
            label={ <h4>RASPI location mapping for current test</h4> }
            hintText = "(Location mapping can be changed from Lab details section)"
            className='awsui-util-m-l'
          >
            <AWSUI.Table
              columnDefinitions={ RASPI_MAPPING_TABLE_COLUMNS }
              items={ raspiMappingItems }
            >
            </AWSUI.Table>
          </AWSUI.FormField>
        </div>
      );
    }
  }

  /**
   * Renders Hotspot Options pane to select the connected device
   */
  showHotspotConfigs = () => {
    const { testParams } = this.props.params;
    const shouldShowHotspotConfigPane = AppConstants.TEST_SUITE_LIST_ENABLED_FOR_HOTSPOT.includes(testParams.testSuite);
    return (
      <div>
        {shouldShowHotspotConfigPane && (
          <HotspotOptions
            labId={testParams.labId}
            onDeviceOptionSelect={this.props.params.onConnectedDeviceChange}
          />)}
      </div>
    );
  }

  /**
   * render helpful text if DUT is an A4PC device.
   * @param {Object} params
   */
  renderDeviceConfigInfo = (params = {}) => {
    const {selectedTestGroupInfo} = params;
    const { deviceConfig, deviceTypeId, dsn } = selectedTestGroupInfo;
    if (!isA4PCDevice(deviceTypeId)) return (<div></div>);

    let label;
    let errorClass = 'awsui-util-status-negative'

    // Show the message here too, to the let the user know that he is submitting the wrong file
    if (!isValidDeviceConfig(deviceConfig, dsn)) {
      label = AppConstants.INVALID_DEVICE_CONFIG;
    } else if (deviceConfig === AppConstants.EMPTY) {
      label = AppConstants.MISSING_DEVICE_CONFIG;
    } else {
      label = 'Provided'
      errorClass = ''
    }

    return (
      <div>
        <div>
          <div className='awsui-util-label'><b>A4PC Device Config</b></div>
          <div className={`awsui-util-label ${errorClass}`}>{ label }</div>
        </div>
        {
          this.getA4PCWakewordOption(params)
        }
      </div>
    );
  }

  /**
   * Renders default Test Options for most of the scenarios except FAR Custom, Security and Music.
   */
  renderDefault = (params) => {
    const { testParams, selectedScenario } = params;
    const { testOptions } = selectedScenario;
    const { testSuite, testType, scenarioType , testCategory} = testParams;
    let dsn = testParams.dsn;
    let deviceName = testParams.deviceName;
    let amazonId = getAmazonIdToDisplay(testParams.deviceTypeId, testParams.scenarioType);
    let firmware = testParams.buildInfo;
    if(testParams.scenarioType === AppConstants.FAR_CUSTOM) {
      dsn = AppConstants.CUSTOM;
      deviceName = AppConstants.CUSTOM;
    }
    else if (selectedScenario.testSuite === AppConstants.MUSIC_SCENARIO_ID &&
      testParams.testOptions.customOptions.is3PDAEnabled) {
      let dut = testParams.deviceMapping['DUT_1'];
      dsn = dut.dsn;
      deviceName = dut.deviceName;
      amazonId = dut.amazonId;
      firmware = selectedScenario.buildInfo;
    }
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
          <div className='awsui-util-action-stripe-group'>
            <AWSUI.Button text='Back' variant='primary' disabled={ params.disabled } onClick={ () => { params.back() } } />
          </div>
        </div>
        </div>
        <div>
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Customer ID ', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, params.testParams.customerId) }
                </div>
                <div>
                  { renderLabelElement('Device Serial Number', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, dsn) }
                </div>
                <div>
                  { renderLabelElement('Device Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, deviceName) }
                </div>
                <div>
                  { renderLabelElement('Amazon ID', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, amazonId) }
                </div>
                { this.renderDeviceConfigInfo(params) }
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement(AppConstants.FIRMWARE, AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                     getDisplayFieldForCustomFar(firmware, params.testParams.scenarioType)) }
                </div>
                {
                  params.testParams.testSuite !== AppConstants.SECURITY_SCENARIO_ID &&
                  (
                    <div>
                      {renderLabelElement('Locale', AppConstants.EMPTY)}
                      {renderLabelElement(AppConstants.EMPTY,
                        getDisplayNameForId(AppConstants.MARKETPLACE, params.testParams.marketPlace))}
                    </div>
                  )
                }
                {
                  ( params.testParams.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM ||
                    params.testParams.scenarioType === FunctionalTestCases.FUNC_NAV_ALL ) &&
                  (
                    <div>
                      {renderLabelElement('SpoofedLocation', AppConstants.EMPTY)}
                      {renderLabelElement(AppConstants.EMPTY,
                        getDisplayNameForId(AppConstants.AVAILABLE_LOCATIONS_EN_US, params.testParams.spoofedLocation))}
                    </div>
                  )
                }
                <div>
                   { renderLabelElement('Email Notification', AppConstants.EMPTY) }
                   { renderLabelElement(AppConstants.EMPTY,
                       getDisplayFieldForCheckBox(params.testParams.sendCompletionEmail)) }
                </div>
                {
                  (params.testParams.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID ||
                    params.testParams.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID)
                    && (
                      <div>
                        { renderLabelElement('Auto-sync resources', AppConstants.EMPTY) }
                        { renderLabelElement(AppConstants.EMPTY,
                            getDisplayFieldForCheckBox(params.testParams.isAutoSyncEnabled)) }
                      </div>
                  )
                }
                <div>
                { renderLabelElement('Lab Name', AppConstants.EMPTY) }
                  <div> {
                    params.labs.map(lab => {
                      return lab.id === params.testParams.labId ? lab.label : ''
                    })
                   }
                   </div>
                </div>
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Test Group Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, params.testParams.testName) }
                </div>
                <div>
                  { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      params.testParams.testSuite)) }
                </div>
                <div>
                  { renderLabelElement(this.getTestTypeLabelToDisplay(testSuite), AppConstants.EMPTY) }
                  {
                    testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID
                      ? (testOptions.config && renderLabelElement(AppConstants.EMPTY,testOptions.config.name) || '')
                    : renderLabelElement(AppConstants.EMPTY, this.getTestTypeToDisplay(testSuite, scenarioType, testCategory, testType))
                  }
                </div>
                {this.renderSPLSettings(selectedScenario.testSuite, selectedScenario.scenarioType, testOptions.spl)}
                <div>
                  { renderLabelElement(this.getDeviceVirtualAssistanLabelToDisplay(params.testParams.testSuite), AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getDeviceVirtualAssistant(params.testParams.testSuite,
                      params.testParams.deviceVirtualAssistant)) }
                </div>
                {
                  params.testParams.isUtteranceRangeSet &&
                  params.testParams.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID &&
                  params.testParams.scenarioType === AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_FULL && (
                  <div>
                    { renderLabelElement('Utterances Range', AppConstants.EMPTY) }
                    { renderLabelElement(AppConstants.EMPTY,
                      this.getUtteranceRangeToDisplay(params.testParams.fromTranscriptNum, params.testParams.toTranscriptNum)) }
                  </div>
                  )
                }
                { ((params.testParams.testType === AppConstants.CUSTOMIZED &&
                    testParams.testSuite !== AppConstants.QUAL_SCENARIO_ID &&
                    testParams.scenarioType !== AppConstants.MOBILE_FUNCTIONAL &&
                    testParams.scenarioType !== AppConstants.WAKEWORD) ||
                  testParams.testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID)
                  && (
                  <div>
                     { renderLabelElement('Custom Options', AppConstants.EMPTY) }
                    <div>
                      { renderLabelElement(AppConstants.EMPTY,
                      this.customOptPretty(params.testParams.testOptions.customOptions, params.testParams.testSuite)) } </div>
                  </div>
                )}
                { params.testParams.testType === AppConstants.CUSTOMIZED &&
                  params.testParams.scenarioType === AppConstants.TRAINED_MOBILE && (
                  <div>
                     { renderLabelElement('Trainers', AppConstants.EMPTY) }
                    <div>
                      { renderLabelElement(AppConstants.EMPTY, this.getTrainersPretty(params.testParams.testOptions.customOptions)) }
                    </div>
                  </div>
                )}
                { params.testParams.testType === AppConstants.CUSTOMIZED &&
                  testParams.testSuite !== AppConstants.QUAL_SCENARIO_ID &&
                  params.testParams.customizedTestType !== AppConstants.EMPTY &&
                  params.testParams.scenarioType !== AppConstants.VOICE_ROBUSTNESS &&
                  params.testParams.scenarioType !== AppConstants.MOBILE_FUNCTIONAL &&
                  params.testParams.scenarioType !== AppConstants.WAKEWORD && (
                  <div>
                     { renderLabelElement('Custom Sub-Test Type', AppConstants.EMPTY) }
                    <div>
                      { renderLabelElement(AppConstants.EMPTY,
                        getDisplayNameForId(AppConstants.CUSTOMIZED_SCENARIOS, params.testParams.customizedTestType)) }
                    </div>
                  </div>
                )}
                { (params.testParams.scenarioType === FunctionalTestCases.FUNC_CUSTOM
                  || params.testParams.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM
                  || params.testParams.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM
                  || (params.testParams.scenarioType === AppConstants.MOBILE_FUNCTIONAL &&
                    params.testParams.testType === AppConstants.CUSTOMIZED)
                  || (params.testParams.scenarioType === AppConstants.WAKEWORD &&
                      params.testParams.testType === AppConstants.CUSTOMIZED)) && (
                  <div>
                     { renderLabelElement('Functional Test Cases', AppConstants.EMPTY) }
                    <div> { ( params.testParams.scenarioType === FunctionalTestCases.FUNC_CUSTOM ||
                              params.testParams.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM) ?
                      renderLabelElement(AppConstants.EMPTY, this.getFunctionalTestCasesPretty(params.testParams)) :
                      renderLabelElement(AppConstants.EMPTY, this.getFunctionalTestCasesCustomOptionsPretty(params.testParams.testOptions.customOptions)) }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AWSUI.ColumnLayout>
        </div>
        <div>
          {
            AppConstants.TEST_SUITE_LIST_ENABLED_FOR_HOTSPOT.includes(params.testParams.testSuite) ?
              this.showHotspotConfigs() :
              this.getRaspiLocationMappingTable(params)
          }
        </div>
      </div>
    );
  }

  /**
   * Renders Test Options for FAR Custom Scenario
   */
  renderFARCustom = (params) => {
    return (
      <div className='awsui-util-container awsui-no-margin'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>Test Options</h2>
            </div>
          <div className='awsui-util-action-stripe-group'>
            <AWSUI.Button text='Back' variant='primary' disabled={ params.disabled } onClick={ () => { params.back() } } />
          </div>
        </div>
        </div>
        <div>
          <div align='center'>
            {
              this.getFARCustomDUTTable(params)
            }
          </div>
          <AWSUI.FormField
            label={ <h4>Other Fields</h4> }
            className='awsui-util-m-l'
           >
          <AWSUI.ColumnLayout columns={ 3 } variant='text-grid'>
            <div data-awsui-column-layout-root='true'>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement(this.getTestTypeLabelToDisplay(params.testParams.testSuite), AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, this.getTestTypeToDisplay(params.testParams.testSuite,
                      params.testParams.scenarioType, params.testParams.testType)) }
                </div>
                <div>
                  { renderLabelElement('Test Suite', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS,
                      params.testParams.testSuite)) }
                </div>
                { this.renderDeviceConfigInfo(params) }
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Test Name', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY, params.testParams.testName) }
                </div>
                <div>
                  { renderLabelElement('Locale', AppConstants.EMPTY) }
                  { renderLabelElement(AppConstants.EMPTY,
                      getDisplayNameForId(AppConstants.MARKETPLACE, params.testParams.marketPlace)) }
                </div>
              </div>
              <div className='awsui-util-spacing-v-s'>
                <div>
                  { renderLabelElement('Lab Name', AppConstants.EMPTY) }
                    <div> {
                      params.labs.map(lab => {
                        return lab.id === params.testParams.labId ?
                          renderLabelElement(AppConstants.EMPTY, lab.label) : ''
                    })
                  }
                  </div>
                </div>
                <div>
                   { renderLabelElement('Email Notification', AppConstants.EMPTY) }
                   { renderLabelElement(AppConstants.EMPTY,
                       getDisplayFieldForCheckBox(params.testParams.sendCompletionEmail)) }
                </div>
              </div>
            </div>
          </AWSUI.ColumnLayout>
          </AWSUI.FormField>
          <div align='center'>
            {
              this.getRaspiLocationMappingTable(params)
            }
          </div>
        </div>
      </div>
    );
  }

  showRetryEnabled = (params) => {
    const retryEnabled = ld.get(params, 'testParams.testOptions.customOptions.retryEnabled');
    if (!ld.isNull(retryEnabled) && retryEnabled == true) {
     return (<div> Utterance retry enabled: <b>True</b> </div>);
    }
  }

  getA4PCWakewordOption = (params) => {
    const {selectedScenario, selectedTestGroupInfo} = params;
    const aqtA4PCCompanionAppEnabled = ld.get(selectedTestGroupInfo, 'aqtA4PCCompanionAppEnabled', false)
    if (!aqtA4PCCompanionAppEnabled) return (<div></div>);
    return (
      <div>
        <div className='awsui-util-label'><b>A4PC Wakeword Mode</b></div>
        <div className={`awsui-util-label`}>
          {
            AppConstants.A4PC_WAKEWORD_OPTIONS.find(element => element.value === selectedScenario.a4pcWakewordModel).label
          }
        </div>
      </div>
    );
  }

  render() {
    const { params } = this.props;

    // For FAR Custom, render Table of DUT Information & other fields.
    if (params.testParams.scenarioType === AppConstants.FAR_CUSTOM) {
      return this.renderFARCustom(params);
    }
    return this.renderDefault(params);
  }
}

TestOptionsDisplay.propTypes = {
  params: PropTypes.object.isRequired
};

export default TestOptionsDisplay;
