import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { getSelectedItemToView, removeSelectedItemsFromTestQueue } from './Utils';
import { sendRawInfoToNewRunWithIdentifier, sendRawInfoToNewRunWithStateId } from '../NewRunDataTransferUtils';

const IDENTIFIER = 'JobListTableHeader';
export class JobListHeader extends React.Component {

  /**
   * can only view one item
   * Prior to this function, it's fixed that testParamsArray length > 0
   */
  onClickViewDetails = () => {
    const { testParamsArray, selectedItems,
      pageDisplayParams, updatePageDisplayParamsCallBack,
      updateNewRunStateParameterCallBack} = this.props;
    let identifier = IDENTIFIER + ' onClickViewDetails';
    let testParams = getSelectedItemToView(testParamsArray, selectedItems);
    sendRawInfoToNewRunWithStateId(identifier, testParams, 'detailsTestParams', updateNewRunStateParameterCallBack);

    // update parameter to display
    let updatedPageDisplayParams = pageDisplayParams;
    updatedPageDisplayParams.viewJobListTableDetails = true;
    sendRawInfoToNewRunWithIdentifier(identifier, updatedPageDisplayParams, updatePageDisplayParamsCallBack);
  }

  OnClickDeleteItems = () => {
    const { testParamsArray, selectedItems,
      updateTestParamsArrayCallBack} = this.props;
    let identifier = IDENTIFIER + ' onClickViewDetails';
    let updatedTestParamsArray = removeSelectedItemsFromTestQueue(testParamsArray, selectedItems);
    sendRawInfoToNewRunWithIdentifier(identifier, updatedTestParamsArray, updateTestParamsArrayCallBack);
  }

  render() {
    const isOnlyOneSelected = this.props.selectedItems.length === 1;
    return (
      <TableHeader
        {...this.props}
        title={this.props.headerText}
        actionButtons={
          <div>
            <AWSUI.Button
              disabled={!isOnlyOneSelected}
              onClick={this.onClickViewDetails}>
              View details
            </AWSUI.Button>
            <AWSUI.Button
              disabled={this.props.selectedItems.length === 0}
              onClick={this.OnClickDeleteItems}>
                Delete
            </AWSUI.Button>
          </div>
        }
      />
    );
  }
};

export const TableHeader = ({ ...props }) => {
  let counter = props.counter;
  if (!counter) {
    counter = props.totalItems
      ? props.serverSide
        ? getServerHeaderCounterText(props.totalItems, props.selectedItems)
        : getHeaderCounterText(props.totalItems, props.selectedItems)
      : null;
  }
  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2 className={props.updateTools ? 'awsui-util-d-ib' : null}>
            {`${props.title} `}
            {counter ? (
              <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                {`${counter}`}
              </span>
            ) : null}
          </h2>
        </div>
        {props.actionButtons && <div className="awsui-util-action-stripe-group">{props.actionButtons}</div>}
      </div>
      {props.description && <div className="awsui-util-container-header-description">{props.description}</div>}
    </div>
  );
};

export const getHeaderCounterText = (items, selectedItems) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`;
};

export const getServerHeaderCounterText = (totalCount, selectedItems) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${totalCount}+)` : `(${totalCount}+)`;
};
