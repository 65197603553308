import { FormSection, ColumnLayout, FormField, Toggle, Select, Input } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { WWA_LOCALE_LIST } from '../../../../constants';
import {
  setEnableEmailNotification,
  setId,
  setLocale,
  setName,
  testSuiteConfigSelector
} from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import ScenarioSelection from './scenarioSelection';

export default () => {

  return <div>
    <StandardSelection />
    <ScenarioSelection />
  </div>
}

const StandardSelection = () => {
  const {error, enableEmailNotification, locale, name, id} = useSelector(testSuiteConfigSelector);
  const dispatch = useDispatch();

  useEffect(
    ()=> {
      if(!id) {
        dispatch(setId());
      }
    }, []
  )

  const handleChange = (e) => {
    dispatch(setEnableEmailNotification(e.detail.checked))
  }

  const handleNameChange = (ip) => {
    dispatch(setName(ip.detail.value));
  }

  const handleLocaleChange = (e) => {
    dispatch(setLocale(e.detail.selectedId));
  }

  const hasError = error && typeof(error) === "object";

  return (
    <FormSection header={<FormattedMessage id="GENERAL_INFO" />}>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <FormField label={<FormattedMessage id="TEST_NAME" />} description={<FormattedMessage id="PROVIDE_NAME_FOR_YOUR_TEST" />}>
            <Input
              onChange={handleNameChange}
              value={name}
              autofocus
              invalid={!!error}  />
            {
              hasError && error.name ? <div className='formErrMsg'>{error.name}</div> : null
            }
          </FormField>
          <FormField label={<FormattedMessage id="LOCALE" />} description={<FormattedMessage id="PROVIDE_LOCALE_TO_BE_TESTED" />}>

            <Select
              options={WWA_LOCALE_LIST}
              selectedOption={WWA_LOCALE_LIST.find(lo => lo.id == locale.id)}
              onChange={handleLocaleChange}
              selectedLabel="Selected"
              invalid={hasError && !!error["locale"]}
            ></Select>
            {
              hasError && error["locale"] ? <div className='formErrMsg'>{error["locale"]}</div> : null
            }

          </FormField>
          <Toggle checked={enableEmailNotification} onChange={handleChange}>
            <FormattedMessage id="ENABLE_EMAIL_NOTIFICATION" />
          </Toggle>
        </div>
      </ColumnLayout>
    </FormSection>
  )
}
