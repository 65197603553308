const UPLTestData = {
  TEST_DATA: {
    "payload": {
      "uplUtteranceResult": {
        "transcribeText": "Alexa. What is the time? Now it\u0027s 5:15 p.m. Have a good evening.",
        "domain": "Time",
        "uplValueFromTranscribe": "1630.00",
        "finalUPLValue": "1630.00",
        "validationNote": "UPL Calculated from AWS Transcribe Service.",
        "totalAttempts": 3,
        "averageUPLValue": "1630.00",
        "tp50": "1630.00",
        "tp90": "1630.00",
        "preSignedS3GetURL": "https://s3-us-west-2.amazonaws.com/aqt-audio-recordings-alpha-us-west-2/UPL/47148017-f243-4239-beb1-6f8332266c5d/what_is_the_time_now_10_2.wav?X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Credential\u003dAKIARUSZNNJ3XWTPMZKR/20200924/us-west-2/s3/aws4_request\u0026X-Amz-Date\u003d20200924T001548Z\u0026X-Amz-Expires\u003d604800\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Signature\u003d6c56f6fbac94232e396be1e3c33dce361cc1276d9305f33da2889b3b291d1510",
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "It\u0027s 5:15 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "transcribeText": "Alexa. What is the time? Now it\u0027s 5:15 p.m. Have a good evening.",
            "domain": "Time",
            "uplValueFromTranscribe": "1630.00",
            "finalUPLValue": "1630.00",
            "validationNote": "UPL Calculated from AWS Transcribe Service.",
            "totalAttempts": 3,
            "averageUPLValue": "1630.00",
            "tp50": "1630.00",
            "tp90": "1630.00",
            "preSignedS3GetURL": "https://s3-us-west-2.amazonaws.com/aqt-audio-recordings-alpha-us-west-2/UPL/47148017-f243-4239-beb1-6f8332266c5d/what_is_the_time_now_10_2.wav?X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Credential\u003dAKIARUSZNNJ3XWTPMZKR/20200924/us-west-2/s3/aws4_request\u0026X-Amz-Date\u003d20200924T001548Z\u0026X-Amz-Expires\u003d604800\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Signature\u003d6c56f6fbac94232e396be1e3c33dce361cc1276d9305f33da2889b3b291d1510",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "It\u0027s 5:15 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          },
          {
            "transcribeText": "Processing...",
            "domain": "Time",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 2,
            "averageUPLValue": "Processing...",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://s3-us-west-2.amazonaws.com/aqt-audio-recordings-alpha-us-west-2/UPL/47148017-f243-4239-beb1-6f8332266c5d/what_is_the_time_now_9_2.wav?X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Credential\u003dAKIARUSZNNJ3XWTPMZKR/20200924/us-west-2/s3/aws4_request\u0026X-Amz-Date\u003d20200924T001643Z\u0026X-Amz-Expires\u003d604800\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Signature\u003d17b2df5861aa85397f700d6544d11798bd00bf0bde5e08ebc86011688088b704",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "It\u0027s 5:15 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          },
          {
            "transcribeText": "Processing...",
            "domain": "Time",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 3,
            "averageUPLValue": "Processing...",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://s3-us-west-2.amazonaws.com/aqt-audio-recordings-alpha-us-west-2/UPL/47148017-f243-4239-beb1-6f8332266c5d/what_is_the_time_now_8_2.wav?X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Credential\u003dAKIARUSZNNJ3XWTPMZKR/20200924/us-west-2/s3/aws4_request\u0026X-Amz-Date\u003d20200924T001711Z\u0026X-Amz-Expires\u003d604800\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Signature\u003d89dbd43670989a308e3184c3f13954770d3c72776d7ab7ae461a9f6971ce2cb9",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "It\u0027s 5:16 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "47148017-f243-4239-beb1-6f8332266c5d",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 9,
          "day": 23
        },
        "time": {
          "hour": 17,
          "minute": 18,
          "second": 17,
          "nano": 67000000
        }
      },
      "testProgressPercent": 1.43
    },
    "customPayload": {
      "uplUtteranceResult": {
        "uplValueFromPoPro": 2222.22,
        "uplValueFromTranscribe": 2111.11,
        "finalUPLValue": 2222.22,
        "finaUPLValueReason": "Audio PoPro value is more precise than Transcribe UPL",
        "totalAttempts": 4,
        "averageUPLValue": 2222.22,
        "tp50": 2222.22,
        "tp90": 2222.22,
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "It\u0027s 3:53 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "uplValueFromPoPro": 2222.22,
            "uplValueFromTranscribe": 2111.11,
            "finalUPLValue": 2222.22,
            "finaUPLValueReason": "Audio PoPro value is more precise than Transcribe UPL",
            "totalAttempts": 4,
            "averageUPLValue": 2222.22,
            "tp50": 2222.22,
            "tp90": 2222.22,
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "It\u0027s 3:53 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "1fb72a2b-14af-4afb-8941-491386449417",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 8,
          "day": 28
        },
        "time": {
          "hour": 15,
          "minute": 53,
          "second": 19,
          "nano": 772000000
        }
      },
      "testProgressPercent": 0.01
    }
  },

  INITIAL_TEST_DATA: {
    "payload": {
      "uplUtteranceResult": {
        "uplValueFromPoPro": "Processing...",
        "uplValueFromTranscribe": "Processing...",
        "finalUPLValue": "Processing...",
        "totalAttempts": 1,
        "averageUPLValue": "0.0",
        "tp50": "Processing...",
        "tp90": "Processing...",
        "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "The time is 12:49 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "uplValueFromPoPro": "Processing...",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 1,
            "averageUPLValue": "0.0",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "The time is 12:49 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "dbc0b72d-049d-4b74-b2b2-987068fe8528",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 9,
          "day": 10
        },
        "time": {
          "hour": 12,
          "minute": 50,
          "second": 23,
          "nano": 757000000
        }
      },
      "testProgressPercent": 0.01
    },
    "customPayload": {
      "uplUtteranceResult": {
        "uplValueFromPoPro": "Processing...",
        "uplValueFromTranscribe": "Processing...",
        "finalUPLValue": "Processing...",
        "totalAttempts": 1,
        "averageUPLValue": "0.0",
        "tp50": "Processing...",
        "tp90": "Processing...",
        "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "The time is 12:49 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "uplValueFromPoPro": "Processing...",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 1,
            "averageUPLValue": "0.0",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "The time is 12:49 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "dbc0b72d-049d-4b74-b2b2-987068fe8528",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 9,
          "day": 10
        },
        "time": {
          "hour": 12,
          "minute": 50,
          "second": 23,
          "nano": 757000000
        }
      },
      "testProgressPercent": 0.01
    },
    "customPayload": {
      "uplUtteranceResult": {
        "uplValueFromPoPro": "Processing...",
        "uplValueFromTranscribe": "Processing...",
        "finalUPLValue": "Processing...",
        "totalAttempts": 1,
        "averageUPLValue": "0.0",
        "tp50": "Processing...",
        "tp90": "Processing...",
        "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "The time is 12:49 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "uplValueFromPoPro": "Processing...",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 1,
            "averageUPLValue": "0.0",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "The time is 12:49 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "dbc0b72d-049d-4b74-b2b2-987068fe8528",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 9,
          "day": 10
        },
        "time": {
          "hour": 12,
          "minute": 50,
          "second": 23,
          "nano": 757000000
        }
      },
      "testProgressPercent": 0.01
    },
    "customPayload": {
      "uplUtteranceResult": {
        "uplValueFromPoPro": "Processing...",
        "uplValueFromTranscribe": "Processing...",
        "finalUPLValue": "Processing...",
        "totalAttempts": 1,
        "averageUPLValue": "0.0",
        "tp50": "Processing...",
        "tp90": "Processing...",
        "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
        "id": 1,
        "asrText": "What is the time now",
        "validationType": "INTENT_TYPE_VALIDATION",
        "aqtEngineMeasureType": "UPL",
        "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
        "expectedIntent": "WhatTimeIntent",
        "actualResponse": "The time is 12:49 PM.",
        "actualIntent": "WhatTimeIntent",
        "resultStatus": "SUCCESS",
        "deviceOnlineStatus": "ONLINE",
        "retryIndex": 2
      },
      "uplUtteranceResultMap": {
        "What is the time now": [
          {
            "uplValueFromPoPro": "Processing...",
            "uplValueFromTranscribe": "Processing...",
            "finalUPLValue": "Processing...",
            "totalAttempts": 1,
            "averageUPLValue": "0.0",
            "tp50": "Processing...",
            "tp90": "Processing...",
            "preSignedS3GetURL": "https://aqt-audio-recordings-alpha-us-west-2.s3.us-west-2.amazonaws.com/UPL/dbc0b72d-049d-4b74-b2b2-987068fe8528/what_is_the_time_now_10_2.wav?X-Amz-Security-Token\u003dIQoJb3JpZ2luX2VjEFwaCXVzLXdlc3QtMiJIMEYCIQC7bodq1tZjYmWxHlC6DC%2FZTaUqk6xvh%2FtTH3slKamXXQIhAME8jZGKa2GnhbAem75RljwwPNN4QKjm78EfRaP0t753KtoDCGUQAhoMMTEyOTMwNDE3MjcxIgw9UUZs8oYHWE%2BSgCAqtwOpiF%2FL7CykQxpYTARdUhYyGm5cNXtaxS0LKlQ2qER3gWFJx7%2BMHSs9x0xS6dKD%2BzwN3XHyX3Njy1GL1CWVlLbqtan0ZZe1ypcwJPwDg3y3IJp9XLE%2FMM0FPoANjnXcArh7juaoKVeFpwS2My1v4XfYFvtHA4kAKF2YpKmRAA3L8sHCktCeecxgh9y6ybqEeDwOZrPg%2F6PKOHYus3S86bv3WrVtijMOJZ74v4%2F%2FRo6MZahKUObD42mJxm7LLu2OCB7C2OD0JnP1pj43fY1O93I4jISBEpapEhmB%2FPFq4mlpf3FCTtGe95dOtarxv%2FAkYDjmsjQZiifHVJB0HcoQW7ftgrt1GBXlCkZss3AjCk%2FHeeEqC%2FCsK2Sp1%2FDPqZKuDCyZPVIrOwp%2FpDGJ%2BdukthYCi6L1zrNHmAoSAzish8%2F%2F%2FklF67SwbXvZEGwmNBQxkx%2BDrcb8aKU400Pg1EUYxci0Rbt6pbvNbXkm5%2BxYyZPfcSxb7Zi4NMsqh8yjO0PaGOKj1iNcc68dd1CFiLn%2F5PAdlUuVTMkF7pCFlGHD5MQHfhBZcaux0echiS%2BLjM7FNN7eNajUzLMqMMqG6voFOpwBLqCdlNtUfDl98%2BLRnQJTuBtAcvefQ6StWpO8zRV65ZB4A%2Byn7XVK%2BMeBt1IkHHqpivz%2BhUSfDiRsy9csBqfdC8113Y0jpZLVDOKvVh3KXYJ%2Ba21cx5o0NDj%2BFhtjtPMmrtUOew0P42KCQPyiUVpdQyO6VMNE2PbDBT%2FRdO1bFc7Idphn2vY306HxOOO56738BCuLHepHilXWNcsj\u0026X-Amz-Algorithm\u003dAWS4-HMAC-SHA256\u0026X-Amz-Date\u003d20200910T194951Z\u0026X-Amz-SignedHeaders\u003dhost\u0026X-Amz-Expires\u003d604800\u0026X-Amz-Credential\u003dASIARUSZNNJ3ZNAKDIEZ%2F20200910%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Signature\u003dae93b9eb904c091990a025753e284130083c365f5cc5fc01a19a2f489402c03f",
            "id": 1,
            "asrText": "What is the time now",
            "validationType": "INTENT_TYPE_VALIDATION",
            "aqtEngineMeasureType": "UPL",
            "expectedResponsePattern": "(?\u003d.*(am|pm).*)(?\u003d.*(It\u0027s|time|it).*)",
            "expectedIntent": "WhatTimeIntent",
            "actualResponse": "The time is 12:49 PM.",
            "actualIntent": "WhatTimeIntent",
            "resultStatus": "SUCCESS",
            "deviceOnlineStatus": "ONLINE",
            "retryIndex": 2
          }
        ]
      },
      "simulationEnvironment": {
        "companyId": "d4e8133d-ac82-47c2-942f-343e98e2f605",
        "actors": [
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
            "actorName": "1.8 m 135 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_3",
            "actorName": "2.7 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_5",
            "actorName": "0.9 m 30 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_2",
            "actorName": "2.7 m 90 deg"
          },
          {
            "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_4",
            "actorName": "0.9 m 90 deg"
          }
        ],
        "noiseActor": {
          "actorId": "d4e8133d-ac82-47c2-942f-343e98e2f605-Vinay_Alpha_1_Loc_1",
          "actorName": "1.8 m 135 deg"
        },
        "devices": [
          {
            "customerId": "A3CC0H36R5EQF3",
            "deviceSerialNumber": "G090VC0991141J5A",
            "buildInfo": "BuildInfo1",
            "deviceType": "ECHO_DOT_V3",
            "deviceName": "ECHO_DOT_V3",
            "amazonId": "AmazonId1",
            "amazonMarketPlaceId": "ATVPDKIKX0DER",
            "aqtRealm": "USAmazon"
          }
        ]
      },
      "simulationOptions": {
        "batchJobId": "dbc0b72d-049d-4b74-b2b2-987068fe8528",
        "testName": "Demo_Test",
        "releaseVersionNumber": "UNKNOWN",
        "marketPlace": "en_US",
        "testSuite": "UPL",
        "testType": "UPL_STANDARD",
        "testOption": "STANDARD",
        "customOptions": "{\"functionalTestCases\":[\"Shopping\"],\"multiModalCustomCommand\":\"actions, weather\",\"isMultimodalDevice\":false}"
      },
      "measureTime": {
        "date": {
          "year": 2020,
          "month": 9,
          "day": 10
        },
        "time": {
          "hour": 12,
          "minute": 50,
          "second": 23,
          "nano": 757000000
        }
      },
      "testProgressPercent": 0.01
    }
  }
}

export default UPLTestData;