
import AppConstants from '_Constants/AppConstants';
import MobileConstants from '../../../_Constants/MobileConstants';

/**
 * Gets trained users list in the format needed for multi-select dropdown
 * @returns Multi-select dropdown list with all trained users
 */
export function getTrainedUsers(selectedScenario) {
  if(AppConstants.OVERHAULED_MOBILE_LOCALES.includes(selectedScenario.marketPlace)) {
    return AppConstants.GENERIC_TRAINED_MOBILE_USERS.map(user =>
      ({ label: user, id: user, disabled: false }));
  }
  return AppConstants.TRAINED_MOBILE_USERS.map(user =>
    ({ label: user, id: user, disabled: false }));
}

export function getNoisesWithLevels() {
  let noisesWithLevels = []
  let noises = AppConstants.NOISE_TYPES_MOBILE_VOICE_ROBUSTNESS;
  let noiseLevels = AppConstants.NOISE_LEVELS_MOBILE_VOICE_ROBUSTNESS;

  for (let i = 0; i < noises.length; i++) {
    for (let j = 0; j < noiseLevels.length; j++){
      if ( noises[i] !== AppConstants.NOISE_TYPE_SILENCE ) {
        noisesWithLevels.push(noises[i] + " (" + noiseLevels[j] + ")");
      } else {
        noisesWithLevels.push(noises[i]);
        break;
      }
    }
  }
  return noisesWithLevels.map(user =>
    ({ label: user, id: user, disabled: false }));

}

export function getAlexaHandsFreeVerificationTestCases() {
  return MobileConstants.ALEXA_HF_VERIFICATION_STANDARD_TEST_CASES.map(test =>
    ({ label: test, id: test, disabled: false }));
}

export function getAppVerificationTestCases() {
  return MobileConstants.APP_VERIFICATION_STANDARD_TEST_CASES.map(test =>
    ({ label: test, id: test, disabled: false }));
}

export function getDeviceLockHandsFreeVerificationTestCases() {
  return MobileConstants.DEVICE_LOCK_HF_VERIFICATION_TEST_CASES.map(test =>
    ({ label: test, id: test, disabled: false }));
}
