import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../services/client';
import { API_STATUS, URL_MAPS } from '../../constants';
import { getUrl } from '../../services/utils';

export const fetchCustomerIds = createAsyncThunk('api/customerids', async () => {
  const response = await client.get(getUrl(URL_MAPS.CUSTOMER_ID))
  return response.data;
})

export const customerIdSlice = createSlice({
  name: 'customerIds',
  initialState: {
    status: API_STATUS.INIT,
    customerIds: [],
    error: null
  },
  extraReducers:(builder) => {
    builder
      .addCase(fetchCustomerIds.fulfilled, (state, action) => {
        state['status'] = API_STATUS.SUCCESS;
        if(Array.isArray(action.payload)) {
          const customerIdMap = action.payload.map(
              cidObj => {
                let customerId,email;
                [[customerId, email]] = Object.entries(cidObj);
                return {customerId, email}
              }
          )
          state['customerIds'] = customerIdMap;
        } else {
          state['customerIds'] = [];
        }
      })
      .addCase(fetchCustomerIds.pending, (state) => {
        state['status'] = API_STATUS.PENDING;
      })
      .addCase(fetchCustomerIds.rejected, (state) => {
        state['status'] = API_STATUS.ERROR;
        state['error'] = "Failed to fetch customer ids";
      })
  }
})

export const customerIdSelector = (state) => state.customerIds;

export default customerIdSlice.reducer;
