import constants from '../constants/customTest';

const initialState = {
  loading: false,
  scenarios: [],
  error: null
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload, meta } = action;

  switch (type) {
    case constants.FETCH_CUSTOM_SCENARIOS_PENDING:
      return {
        ...state,
        loading: true,
        error: null
      }
    case constants.FETCH_CUSTOM_SCENARIOS_SUCCESS:
      const skip = (meta.params && meta.params.skip) || 0;
      let resp = payload;
      if(typeof(payload) === "string") {
        try {
          resp = JSON.parse(payload)
        } catch (e) {
          resp = null;
        }
      }
      let newScenarios = resp || [];
      if (skip > 0) newScenarios = [...state.scenarios, ...resp];
      if (newScenarios.length === 0) {
        newScenarios = [{
          id: 'CREATE_NEW_SCENARIO',
          name: 'Create New Scenario',
          description: 'Create new user defined scenario'
        }];
      }
      return {
        ...state,
        loading: false,
        scenarios: newScenarios
      }
    case constants.FETCH_CUSTOM_SCENARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state;
  }
};

export default reducer;
