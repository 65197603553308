const LiveRunStyle = {
  
  // Mapping to display device online status on Live run page
  DEVICE_ONLINE_OFFLINE_STATUS: {
    true: {
      deviceStatusClass: 'awsui-util-status-positive nowrap-style',
      deviceStatusIcon: 'status-positive',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-passed-test-status',
      displayText: 'Online'
    },
    false: {
      deviceStatusClass: 'awsui-util-status-negative nowrap-style',
      deviceStatusIcon: 'status-negative',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-failed-test-status',
      displayText: 'Offline'
    },
    'Unavailable': {
      deviceStatusClass: 'awsui-util-status-negative nowrap-style',
      deviceStatusIcon: 'status-warning',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-error-test-status',
      displayText: 'Unavailable'
    }
  },

  // Mapping to display utterance result status on Live run page
  UTTERANCE_RESULT_STATUS: {
    'SUCCESS': {
      deviceStatusClass: 'awsui-util-status-positive nowrap-style',
      deviceStatusIcon: 'status-positive',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-passed-test-status',
      displayText: 'Success'
    },
    'FAILURE': {
      deviceStatusClass: 'awsui-util-status-negative nowrap-style',
      deviceStatusIcon: 'status-negative',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-failed-test-status',
      displayText: 'Failure'
    },
    'ERROR': {
      deviceStatusClass: 'awsui-util-status-negative nowrap-style',
      deviceStatusIcon: 'status-warning',
      variant: 'normal',
      deviceStatusTextStyle: 'awsui-util-ml-xs functional-error-test-status',
      displayText: 'Error'
    }
  },

  // Mapping to display false wake counts on Live run page
  PASS_RATE: {
    true: {
      wakeClass: 'awsui-util-status-positive nowrap-style',
      wakeTextStyle: 'awsui-util-ml-xs functional-passed-test-status',
    },
    false: {
      wakeClass: 'awsui-util-status-negative nowrap-style',
      wakeTextStyle: 'awsui-util-ml-xs functional-failed-test-status',
    }
  }
}

export default LiveRunStyle;