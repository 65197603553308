import { logToConsole } from '../../Util';

const logger = ({ getState }) => {
  return next => action => {
    const { type, payload } = action;
    const logState = false;

    if (logState) {
      logToConsole('previous state', getState());
      logToConsole('dispatching action', type, payload);
    }

    const result = next(action);

    if (logState) {
      logToConsole('current state', getState());
    }
    return result;
  };
};

export default logger
