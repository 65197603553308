/**
 * Validates actor mapping for custom test
 * @param {object} options
 */
export function validateActorLabMapping(options = {}) {
  const { config, actorLabMapping } = options;
  if (!config || !actorLabMapping) {
    return {
      isValid: false
    }
  }
  const errors = {};
  let isValid = true;
  const { noiseLocationPi, speechLocationPi, recordingLocationPi } = config;
  if (noiseLocationPi && !actorLabMapping[noiseLocationPi]) {
    isValid = false;
    errors[noiseLocationPi] = true;
  }

  if (speechLocationPi && !actorLabMapping[speechLocationPi]) {
    isValid = false;
    errors[speechLocationPi] = true;
  }

  if (recordingLocationPi && !actorLabMapping[recordingLocationPi]) {
    isValid = false;
    errors[recordingLocationPi] = true;
  }

  return {
    isValid,
    errors
  }
}

export default {
  validateActorLabMapping
}
