// These value of states are stored for retrieval
const NewRunStateSaveConstants = {
    TEST_PARAMS: 'testParams', // save this state when going to AddTestConfirmation page,
    // to cover the situation where people go to another page from AddTestConfirmation page and then come back
    // TestOptionsDisplay now using testParams to display contents in AddTestConfirmation Page.
    TEST_PARAMS_ARRAY: 'testParamsArray', // save job list table
    SELECTED_TEST_GROUP_INFO: 'selectedTestGroupInfo',
    SELECTED_SCENARIO: 'selectedScenario',
    OTHER_PARAMS: 'otherParams',
    PAGE_DISPLAY_PARAMS: 'pageDisplayParams'
}

export default {
  ...NewRunStateSaveConstants,
};
