import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import Results from '../Results';
import LiveRun from '../LiveRun';
import ReleaseNotes from '../ReleaseNotes';
import Labs from '../Labs';
import CustomTests from '../CustomTest';
import CreateTest from '../../newSystem/index';

export default [
  {
    icon: 'settings',
    label: 'Labs',
    path: '/labs',
    id: 'labs',
    component: <Labs />
  },
  {
    icon: 'refresh',
    label: 'New Run',
    path: '/',
    id: 'newRun',
    component: (<div align='center'><AWSUI.Spinner size='large'/></div>)
  },
  {
    icon: 'share',
    label: 'Live Runs',
    path: '/liveRuns',
    id: 'liveRuns',
    component: <LiveRun />
  },
  {
    icon: 'folder',
    label: 'User Defined Test',
    path: '/customTests',
    id: 'customTests',
    component: <CustomTests />
  },
  {
    icon: 'download',
    label: 'Results',
    path: '/results',
    id: 'results',
    component: <Results />
  },
  {
    icon: 'notification',
    label: 'Release Notes',
    path: '/releases',
    id: 'releaseNotes',
    component: <ReleaseNotes/>
  }
];
