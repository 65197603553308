const AutoLocalSearchTestConstants = { 
    REF_MIC_STATUS_UNAVAILABLE: 'Unavailable',
    REF_MIC_STATUS_ONLINE: true,
    REF_MIC_STATUS_OFFLINE: false,
    MIN_UTTERANCE_NUM: '1',
    MAX_UTTERANCE_NUM: '60000',
    MIN_UTTERANCE_NUM_INT: 1,
    MAX_UTTERANCE_NUM_INT: 60000,
    ZERO: '0',
    UTTERANCE_RANGE_ERROR: 'Invalid/ out of range value for utterances. Please provide non-zero value.',
    UTTERANCE_INPUT_ERROR: 'Invalid/ out of range input.',
    UTTRANCE_RANGE_TOOLTIP_MESSAGE: 'Check this box to set custom range for utterances to be played.',
    STATUS_PENDING: ['IN_PROGRESS', 'QUEUED'],
    ALEXA: 'ALEXA',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    TRANSCRIBE_RESPONSE_MESSAGE: 'Responses from reference mic recordings may be delayed by a few minutes',
    RESPONSE_PENDING_MESSAGE: 'All utterances have been played. Waiting for the response for some of the utterances',

    // Map containing styles & Icons to display utterance status
    UTTERANCE_STATUS_MAP: {
      'SUCCESS': {
        testStatusClass: 'awsui-util-status-positive wrap-text-style', 
        testStatusIcon: 'status-positive', 
        variant: 'success',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-passed-test-status',
        testStatusValue: 'Succeeded'},
      'FAILURE': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style', 
        testStatusIcon: 'status-negative', 
        variant: 'error',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-failed-test-status',
        testStatusValue: 'Failed'},
       'ERROR': {
         testStatusClass: 'awsui-util-status-negative wrap-text-style', 
         testStatusIcon: 'status-warning', 
         variant: 'warning',
         testStatusTextStyle: 'awsui-util-ml-xs autolocal-failed-test-status',
         testStatusValue: 'Error'},
      'IN_PROGRESS': {
        testStatusClass: 'awsui-util-status-info wrap-text-style', 
        testStatusIcon: 'status-in-progress', 
        variant: 'link',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-running-test-status',
        testStatusValue: 'In Progress'},
      'QUEUED': {
        testStatusClass: 'awsui-util-status-info wrap-text-style', 
        testStatusIcon: 'status-pending', 
        variant: 'link',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-pending-test-status',
        testStatusValue: 'Queued'},
      'TIME_OUT': {
        testStatusClass: 'awsui-util-status-negative wrap-text-style', 
        testStatusIcon: 'status-stopped', 
        variant: 'subtle',
        testStatusTextStyle: 'awsui-util-ml-xs autolocal-timeout-test-status',
        testStatusValue: 'Timed Out'}
    },

    // Mapping to display reference mic status on Live run page
    REF_MIC_STATUS: {
      true: {
        displayText: 'Online',
        displayStyle: 'ref-mic-online-style'
      },
      false: {
        displayText: 'Offline',
        displayStyle: 'ref-mic-offline-style'
      },
      'Unavailable': {
        displayText: 'Retrieving ...',
        displayStyle: 'ref-mic-unavailable-style'
      }
    }
  }
       
  export default AutoLocalSearchTestConstants;
