const ApiConstants = {
  THINGS: 'things',
  VIEW_GROUP_LIST_URL: '/api/labs',
  POST_JOB_SUBMIT_URL: '/api/jobs',
  POST_JOB_SUBMIT_URL_WITH_AUTO_SYNC: '/api/v2/jobs',
  VIEW_TESTSUITE_LIST_URL: '/api/testsuites',
  VIEW_JOB_DEFINITION_VERSION_SET_LIST_URL: '/api/jobdefinitionversionsets',
  VIEW_INSTANCEID: '/api/jobs',
  POST_DUT: '/api/deviceundertests',
  POST_JOB_DEF_SIMPLIFIED: '/api/simplifiedjobdefinitions',
  VIEW_THING_SETUP: '/api/things/thingId/setup/distro',
  GET_CID_WHITELIST: '/api/customeridwhitelist',
  GET_WHITELISTED_SCENARIOS: '/api/scenarios',
  GET_RELEASE_NOTES: '/api/releasenotes',
  GET_EXPERIMENT: '/api/experiments',
  EXPERIMENT_V2: '/api/v2/experiments',
  EXPERIMENT_V1: '/api/experiments',
  SEND_MQTT_MESSAGE: '/api/mqtt/message',
  GET_CUSTOM_SCENARIOS: '/api/customscenarios',
  GET_BANNER_MESSAGE: '/api/bannerMessages'
}

export default ApiConstants;
