/**
 * This file contains helper methods needed in New Run page
 */
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import MobileConstants from '../../_Constants/MobileConstants';
import { logToConsole, getDisplayNameForId } from '../../Util';
import AutoLocalSearchTestConstants from '../../_Constants/AutoLocalSearchTestConstants';
import { ENABLE_DSNONLINESTATUS } from './FeatureSwitch';
// TODO: Dont use this and use redux store instead
import { stateStore } from '../../Components/State/Store';
import { saveState } from '../../Components/State/Actions';
import { sendRawInfoToNewRun } from './NewRunDataTransferUtils';
import _, { get, isEmpty, isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStage } from 'newSystem/services/utils';
import { STAGE_TYPES } from 'newSystem/constants';

const OPTIONAL_FIELDS = ['deviceTypeId', 'deviceName', 'farScenarioId', 'deviceConfig'];

/**
 * Gets Error text for input field
 * @param id ID of the input field
 * @param error error state for input field
 * @param value Value inside input field
 * @returns Error text for input field
 */
export function getErrorText(id, error, value) {
  if (error) {
    if (id === AppConstants.iHeartEmailFieldId) {
      return (value === AppConstants.EMPTY) ? AppConstants.INPUTERR_IHRT_ACC_ERR : AppConstants.INPUTERR_EMAIL;
    }
    if (value === AppConstants.EMPTY) {
      return AppConstants.INPUTERR_BLANK;
    } else if (id === 'fromTranscriptNum' || id === 'toTranscriptNum') {
      return AutoLocalSearchTestConstants.UTTERANCE_INPUT_ERROR;
    } else if (!value.match(/[\w]/)) {
      return AppConstants.INPUTERR_ALPHANUMERIC;
    }
    if (id === 'buildInfo') {
      return AppConstants.INPUTERR_INVALID_INPUT_BUILD_INFO;
    } else if (id === 'deviceConfig') {
      return AppConstants.INVALID_DEVICE_CONFIG;
    }
    if (id === 'testName' && value.length > 30) {
      return AppConstants.INPUTERR_TEXT_LENGTH;
    }

    return AppConstants.INPUTERR_INVALID_INPUT;
  }
  return AppConstants.EMPTY;
}

/**
 * Gets error text for utterance range component based on values provided as
 * input in From & To text boxes
 * @param from Minimum value - from range for utterances
 * @param to Maximum value - to range for utterances
 * @returns Error text for utterance range input fields
 */
export function getErrorTextUtteranceRange(from, to) {
  let isUtteranceRangeValid = validateUtteranceRange(from, to);
  let errorText = isUtteranceRangeValid ? AppConstants.EMPTY : AutoLocalSearchTestConstants.UTTERANCE_RANGE_ERROR;
  return errorText;
}

/**
* Gets error text for utterance input component based on value provided as
* input in either From or To text boxes
* @param input Input value for utterance range input field
* @returns Error text for utterance range input field
*/
export function getErrorTextUtteranceInput(input) {
  let isUtteranceInputValid = validateUtteranceInput(input);
  let errorText = isUtteranceInputValid ? AppConstants.EMPTY : AutoLocalSearchTestConstants.UTTERANCE_RANGE_ERROR;
  return errorText;
}

/**
 * Validates utterance range for Auto local search Full scenario
 * @param from Minimum value - from range for utterances
 * @param to Maximum value - to range for utterances
 * @returns Whether range of utterances is valid or not
 */
export function validateUtteranceRange(from, to) {
  let isRangeValid = false;
  try {
    if (from && to) {
      let fromValueInt = parseInt(from);
      let toValueInt = parseInt(to);
      isRangeValid = fromValueInt <= toValueInt
        && fromValueInt >= AutoLocalSearchTestConstants.MIN_UTTERANCE_NUM_INT
        && toValueInt <= AutoLocalSearchTestConstants.MAX_UTTERANCE_NUM_INT;
    }
  } catch (error) {
    logToConsole('Error while validating range of utterances: ', JSON.stringify(error));
  }
  return isRangeValid;
}

/**
 * Validates input value for utterance range input
 * @param input Value provided in input field for From or To
 * @param to Maximum value - to range for utterances
 * @returns Whether input for utterance field is within the range or not
 */
export function validateUtteranceInput(input) {
  let isInputValid = false;
  try {
    if (input) {
      let inputValueInt = parseInt(input);
      isInputValid = inputValueInt
        && inputValueInt >= AutoLocalSearchTestConstants.MIN_UTTERANCE_NUM_INT
        && inputValueInt <= AutoLocalSearchTestConstants.MAX_UTTERANCE_NUM_INT;
    }
  } catch (error) {
    logToConsole('Error while validating input value for utterance field: ', JSON.stringify(error));
  }
  return isInputValid;
}

/**
 * Gets Error text for dropdown field
 * @param id ID of the dropdown field
 * @param error error state for input field
 * @param dutList List of DUTs
 * @returns Error text for dropdown field
 */
export function getErrorTextForDropdown(id, error, dutList) {
  if (id === 'dutType') {
    logToConsole("Warn: dutType Empty.");
    return AppConstants.EMPTY;
  }
  if (error) {
    if (id === 'dsn' && dutList && dutList.length === 0) {
      logToConsole("Error: dut list is empty");
      return AppConstants.MISSING_DUTS_ERROR;
    }
    return AppConstants.DEFAULT_DROPDOWN_ERROR;
  }
  return AppConstants.EMPTY;
}

/**
 * Decides whether dropdown field should be disabled or not
 * @param id ID of the dropdown field
 * @param error error state for dropdown
 * @param dutList List of DUTs
 * @param dutListLoading Whether DUT list is in loading state
 * @returns true only when dropdown is DSN & we cannot find any DUTs for selected CID
 * from CID dropdown
 */
export function shouldDisableDropdown(id, error, dutList, dutListLoading) {
  if (id === 'customerId' && dutListLoading === true) {
    return true;
  }
  if (error) {
    if (id === 'dsn' && getErrorTextForDropdown(id, error, dutList) === AppConstants.MISSING_DUTS_ERROR) {
      return true;
    }
  }
  return false;
}

/**
 * Checks if DSN exists in the DUT list
 * @param dutList List of DUTs
 * @param dsn DSN to be searched
 * @returns true if DSN exists in the dutList
 */
export function isDutInList(dutList, dsn) {
  let dutFound = false;
  if (dutList && dsn) {
    for (var i = 0; i < dutList.length; i++) {
      let currentDsn = dutList[i].id;
      if (dsn === currentDsn) {
        dutFound = true;
        break;
      }
    }
  }
  return dutFound;
}

/**
 * Decides whether input field is optional or not
 * @param key ID of the input field
 * @param testSuite Test Suite
 * @returns Whether input field is optional or not
 */
export function isInputOptional(key, testSuite, scenarioType) {
  // If test suite is NOT Auto Local Search, deviceVirtualAssistant is not
  // applicable in such cases. Skip validation (return true) in such cases
  if (testSuite !== AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
    && key === 'deviceVirtualAssistant') {
    return true;
  }
/*MOBILE FUNCTIONAL LOGIC- TODO
  // If testCategory exists (i.e. this is Mobile Functional Scenario)
  if (_.has(fields, 'testCategory')) {
    // If testCategory IS Device Lock Hands-Free Verification, testType is not applicable
    // If testCategory IS NOT Device Lock Hands-Free Verification, lockType is not applicable
    return fields['testCategory'] === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION ?
      key === 'testType' : key === 'lockType';
  }*/
    if (key !== 'testType') {
    return OPTIONAL_FIELDS.includes(key);
  }
  // For Music scenarios, testType is optional
  return testSuite === MusicConstants.MUSIC_SCENARIO_ID && key === 'testType';
}

/**
 * Gets amazonId to display on New Run page
 * @param amazonId Amazon ID
 * @returns Amazon ID to display on new run page
 */
export function getAmazonIdToDisplay(amazonId) {
  if (amazonId && amazonId !== AppConstants.EMPTY) {
    return amazonId;
  }
  return AppConstants.UNAVAILABLE;
}

/**
 * Decodes Unicode string & returns the decoded value
 * e.g. Input = US\\0027 Test Device
 *      Output = US's Test Device
 * @param inputString Input to be decoded - may be containing unicode characters
 * @returns Decoded string
 */
export function decodeUnicodeString(inputString) {
  return inputString.replace(/\\\\u(\w\w\w\w)/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
}

/**
 * Gets noise location based on test suite
 * @param testSuite Test suite
 * @returns Noise location
 */
export function getNoiseLocation(testSuite) {
  let noiseLocation = AppConstants.NOISE_LOCATION;
  if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) {
    noiseLocation = AppConstants.CT_NOISE_LOCATION;
  } else if (testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID) {
    noiseLocation = AppConstants.AUTOMOTIVE_LOCATION;
  }
  return noiseLocation;
}

/**
 * Transfer dutList from below format
 * Format A, 0, 1, 2 stands for DeviceOnlineList, StatusUnknownList, and DeviceOfflineList
 *    0: {label: "Online", options: Array(1)}
 *    1:{
 *     label: "Status Unknown"
 *     options: Array(2)
 *       0: {id: "1223", label: "Unknown", type: "", name: "", deviceUnderTestId: "ba41c45e-320e-4595-8c97-3a957680f70f", …}
 *       1: {id: " G090U610905607D5", label: "test-local-aqt", type: "ECHO_DOT_V3", name: "test-local-aqt", deviceUnderTestId: "c1dbd110-791b-11ea-bc55-0242ac130003", …}
 *       length: 2
 *    }
 *    2: {label: "Offline", options: Array(1)}
 *
 * To Format B
 *   0: {id: "1223", label: "Unknown", type: "", name: "", deviceUnderTestId: "ba41c45e-320e-4595-8c97-3a957680f70f", …}
 *   1: {id: "G090U610905607D6", label: "Unknown", type: "ECHO_DOT_V3", name: "", deviceUnderTestId: "b3156237-eaab-43ec-93e4-9eff537a1949", …}
 *   2: {id: "G090U610905607D5", label: "test-local-aqt", type: "ECHO_DOT_V3", name: "test-local-aqt", deviceUnderTestId: "c1dbd110-791b-11ea-bc55-0242ac130003", …}
 *   3: {id: "G090U610905607D4", label: "test-local-aqt-dummy", type: "ECHO_DOT_V3", name: "test-local-aqt-dummy", deviceUnderTestId: "1614ece4-791c-11ea-bc55-0242ac130003", …}
 *
 * @param dutList: dutList of Format A
 * @return dutListReturn of Format B
 */
export function convertDUTListFormat(dutList) {
  let dutListResponse = [];
  dutList.forEach(dutObject => {
    let dutSubList = dutObject.options;
    dutSubList.forEach(dut => {
      dutListResponse.push(dut);
    })
  })
  return dutListResponse;
}

/**
 * Returns Amazon ID for selected DSN in the dropdown
 * @param dutList List of DUTs for a particular customer ID
 * @param dsn DSN selected in the dropdown
 * @returns Amazon ID for selected DSN
 */
export function getAmazonIdForDut(dutList, dsn) {
  let amazonId = AppConstants.EMPTY;
  if (ENABLE_DSNONLINESTATUS) {
    // top dutlist is actually categories
    for (var i = 0; i < dutList.length; i++) {
      let duts = dutList[i].options;
      if (duts && dsn) {
        for (let i = 0; i < duts.length; i++) {
          let dut = duts[i];
          if (dut['id'] === dsn) {
            return dut['type'];
          }
        }
      }
    }
  } else {
    if (dutList && dsn) {
      for (let i = 0; i < dutList.length; i++) {
        let dut = dutList[i];
        if (dut['id'] === dsn) {
          return dut['type'];
        }
      }
    }
  }
  return amazonId;
}

/**
 * Returns Device Name for selected DSN in the dropdown
 * @param dutList List of DUTs for a particular customer ID
 * @param dsn DSN selected in the dropdown
 * @returns Device Name for selected DSN
 */
export function getDeviceNameForDut(dutList, dsn) {
  let deviceName = AppConstants.EMPTY;
  if (ENABLE_DSNONLINESTATUS) {
    // top dutlist is actually categories
    for (var i = 0; i < dutList.length; i++) {
      let duts = dutList[i].options;
      if (duts && dsn) {
        for (let i = 0; i < duts.length; i++) {
          let dut = duts[i];
          if (dut['id'] === dsn) {
            return dut['name'];
          }
        }
      }
    }
  } else {
    if (dutList && dsn) {
      for (let i = 0; i < dutList.length; i++) {
        let dut = dutList[i];
        if (dut['id'] === dsn) {
          return dut['name'];
        }
      }
    }
  }
  return deviceName;
}

/**
 * Method to populate dropdown options for Locale for Music scenario depending upon Test Suite/Scenario options
 */
export function getMusicLocaleOptions(musicScenarioSelected) {
  if (MusicConstants.MUSIC_TEST_TYPE_MARKETPLACE_MAP.hasOwnProperty(musicScenarioSelected)) {
    return MusicConstants.MUSIC_TEST_TYPE_MARKETPLACE_MAP[musicScenarioSelected];
  }
  // Default Option
  return AppConstants.TEST_SUITES.MUSIC.MARKETPLACE
}

/**
 * Method to populate dropdown options for Locale for Mobile scenario depending upon Test Suite/Scenario options
 */
export function getMobileLocaleOptions(companyId, mobileScenarioSelected) {
  if (MobileConstants.MOBILE_SCENARIO_MARKETPLACE_MAP.hasOwnProperty(mobileScenarioSelected)) {
    return MobileConstants.MOBILE_SCENARIO_MARKETPLACE_MAP[mobileScenarioSelected];
  }
  if(isMobileOverhaulEnabledForPartner(companyId)) {
    return AppConstants.TEST_SUITES.MOBILE.OVERHAULED_MARKETPLACE
  }
  // Default Option
  return AppConstants.TEST_SUITES.MOBILE.MARKETPLACE
}

/**
 * Method to populate the locales required for different test suites
 */
export function getFilteredLocaleOptions(companyId, selectedScenario) {
  const testSuiteInfo = AppConstants.TEST_SUITES[selectedScenario.testSuite];
  let localeOptions = testSuiteInfo.MARKETPLACE;
  if (selectedScenario.testSuite === AppConstants.TEST_SUITES.MUSIC.ID) {
    return getMusicLocaleOptions(selectedScenario.scenarioType)
  }
  if (selectedScenario.testSuite === AppConstants.TEST_SUITES.MOBILE.ID) {
    return getMobileLocaleOptions(companyId, selectedScenario.scenarioType);
  }
  if (selectedScenario.testSuite === AppConstants.TEST_SUITES.ACOUSTIC.ID && AppConstants.FAR_PLUS === selectedScenario.scenarioType) {
    const marketplace = getStage() === STAGE_TYPES.PROD ? AppConstants.MARKETPLACE_FAR_PLUS_PROD: AppConstants.MARKETPLACE_FAR_PLUS;
    return marketplace;
  }
  return localeOptions;
}

/**
 * Method to populate the locations based on selected locale
 */
export function getFilteredLocationOptions(selectedScenario, currentSelectedLocale) {

  let currentSelectedLocationInfo =  AppConstants.AVAILABLE_SPOOFED_LOCATIONS[currentSelectedLocale];
  let locationOptions = currentSelectedLocationInfo.LOCALE_BASED_LOCATIONS;
  return locationOptions;
}

/**
 * Returns beta enabled locales
 * @param {*} companyId
 * @param {*} selectedScenario
 * @returns
 */
function getBetaEnabledLocales(companyId, selectedScenario) {
  let locales = [];

  if(!isBetaEnabledPartner(companyId)) {
    return locales;
  }

  for(const betaLocaleFeature of AppConstants.BETA.BETA_LOCALES) {
    // check if the locale (feature) is beta enabled for use and also if partner is allowed to use
    if (isFeatureBetaEnabledForPartner(betaLocaleFeature, companyId) && isFeatureBetaEnabled(betaLocaleFeature)) {
      if (isTestsuiteEnabledForBetaLocale(selectedScenario, betaLocaleFeature)) {
        // add the locales to the dropdown filter
        locales.push(...betaLocaleFeature.MARKETPLACE);
      }
    }
  }

  return locales;
}

/**
 * Given the test suite, check if the locale is beta enabled
 * since locale is tightly coupled with scenarios at this point
 * @param {*} companyId
 * @param {*} selectedScenario
 * @returns
 */
export function isTestsuiteEnabledForBetaLocale(selectedScenario, feature) {
  const betaScenarios = _.get(feature.ALLOW_LISTED_SCENARIOS[selectedScenario.testSuite], 'SCENARIO_TYPE');
  if (!_.isEmpty(betaScenarios)) {
    const isScenarioAllowedForLocale = betaScenarios.filter(scenario => scenario.id === selectedScenario.scenarioType).length >= 1;
    logToConsole(selectedScenario.scenarioType, 'is allow listed for the feature: ',feature.NAME);
    return isScenarioAllowedForLocale;
  }
  return false;
}

/**
 * checks is the feature is available for beta use
 * @param {*} feature
 */
export function isFeatureBetaEnabled(feature) {
  if (AppConstants.BETA.BETA_FEATURES_ENABLED.includes(feature)) {
    logToConsole(feature.NAME,' is beta enabled to use');
    return true;
  }
  return false;
}

/**
 * checks if the feature is available for the specific partner/companyId
 * @param {*} feature
 * @param {*} companyId
 * @returns
 */
export function isFeatureBetaEnabledForPartner(feature, companyId) {
  if (isBetaEnabledPartner(companyId)) {
    const isFeatureAllowListedToPartner = AppConstants.BETA.BETA_PARTNERS[companyId].includes(feature);
    logToConsole(feature.NAME, ' is allow listed to partner');
    return isFeatureAllowListedToPartner;
  }
  return false;
}

/**
 * check if the partner is enabled to use overhauled mobile locales
 * @param locale
 * @param companyId
 * @returns {*}
 */
export function isMobileOverhaulEnabledForPartner(companyId) {
  return AppConstants.MOBILE_OVERHAUL_PARTNERS.includes(companyId)
}

/**
 * check if the currenly logged in user is beta allow listed
 * @param {*} companyId
 * @returns
 */
export function isBetaEnabledPartner(companyId) {
  return !_.isEmpty(_.get(AppConstants.BETA.BETA_PARTNERS, companyId));
}

/**
 * Method to return music scenario types for the given locale
 * returns list of MSP supported for a locale
 */
export function getMusicTestTypesBasedOnLocale(locale) {
  let filteredOptions = [];
  MusicConstants.MSP_TYPE.forEach((msp) => {
    if (!MusicConstants.MUSIC_TEST_TYPE_MARKETPLACE_MAP.hasOwnProperty(msp.id) ||
      MusicConstants.MUSIC_TEST_TYPE_MARKETPLACE_MAP[msp.id].some((mspLocale) => mspLocale.id === locale)) {
      filteredOptions.push(msp)
    }
  });
  return filteredOptions;
}

/**
 * Gets error text to display for selection of Music scenario + test type + locales
 */
export function getMusicScenariosErrorText(isErrorMusicScenarios, isErrorEmptyMusicScenarios) {
  if (isErrorMusicScenarios) {
    return AppConstants.ERROR_MUSIC_SCENARIO_OPTIONS;
  }
  if (isErrorEmptyMusicScenarios) {
    return AppConstants.ERROR_EMPTY_MUSIC_SCENARIOS;
  }
  return AppConstants.EMPTY;
}

/**
 * Gets the test type which is currently selected for particular Music scenario
 */
export function getMusicTestTypeSelected(musicScenariosSelected, musicScenarioId) {
  if (musicScenarioId && musicScenariosSelected) {
    if (musicScenariosSelected.hasOwnProperty(musicScenarioId)) {
      let musicScenarioValue = musicScenariosSelected[musicScenarioId];
      if (musicScenarioValue.hasOwnProperty('testType')) {
        return musicScenarioValue.testType;
      }
    }
  }
  return AppConstants.EMPTY;
}

/**
* handler to disable run button on Test confirmation page
* based on specific conditions
*/
export function shouldDisableRunButton(params) {
  const { selectedScenario , otherParams } = params;
  const testSuite = get(selectedScenario, 'testSuite', null);

  switch (testSuite) {
    case AppConstants.SECURITY_SCENARIO_ID:
      return get(selectedScenario, 'testOptions.hotspotOptions.connectedDevice', []).length <= 0;
    default:
      return isEmpty(otherParams.mapping) || isNil(otherParams.mapping);
  }
}

/**
 * Generic method to get updated mapping
 * based on the test suite
 *
 * @param {*} selectedScenario
 * @param {*} otherParams
 */
export function getUpdatedMapping(selectedScenario, otherParams) {
  let newMapping = {};
  const testSuite = get(selectedScenario, 'testSuite', null);
  const mapping = get(otherParams, 'mapping', {});
  switch (testSuite) {
    case AppConstants.SECURITY_SCENARIO_ID:
      newMapping['actorMapping'] = getUpdatedActorMapping(selectedScenario, otherParams);
      newMapping['noiseMapping'] = getUpdateNoiseActorMapping(selectedScenario, otherParams);
      newMapping['companyId'] = get(selectedScenario, 'testOptions.hotspotOptions.rasPi.companyId', '');
      return newMapping;
    default:
      return mapping;
  }
}

/**
 * Generic method to get updated noise actor mapping
 * based on the test suite
 *
 * @param {*} selectedScenario
 * @param {*} otherParams
 */
export function getUpdateNoiseActorMapping(selectedScenario, otherParams) {
  const testSuite = get(selectedScenario, 'testSuite', null);
  switch (testSuite) {
    case AppConstants.SECURITY_SCENARIO_ID:
      return {};
    default:
      return get(otherParams, 'mapping.noiseMapping', {});
  }
}

/**
 * Generic method to get the updated actor mapping
 * based on the test suite
 *
 * @param {*} selectedScenario
 * @param {*} otherParams
 */
export function getUpdatedActorMapping(selectedScenario, otherParams) {
  let newActorMapping = {};
  const testSuite = get(selectedScenario, 'testSuite', null);
  switch (testSuite) {
    case AppConstants.SECURITY_SCENARIO_ID:
      const actorMapping = get(otherParams, 'mapping.actorMapping', {});
      const hotspotOptions = get(selectedScenario, 'testOptions.hotspotOptions', {});
      const rasPiName = get(hotspotOptions, 'rasPi.companyId', '') + '-' + get(hotspotOptions, 'rasPi.label', '');

      // filter pi locations that are not required for AVS Security
      Object.entries(actorMapping).forEach(([key, value]) => {
        if (value === rasPiName) {
          newActorMapping[key] = value;
        }
      });
      return newActorMapping;
    default:
      return get(otherParams, 'mapping.actorMapping', {});
  }
}
/* TODO: add format for scenarioArray
 * @param {*} scenarioName eg
 * @param {*} scenarioArray eg
 */
export function getIdForScenario(scenarioName, scenarioArray) {
  for (let i = 0; i < scenarioArray.length; i++) {
    if (scenarioArray[i].id === scenarioName) {
      return scenarioArray[i].scenarioId;
    }
  }
  return AppConstants.EMPTY;
}

/**
 * save state so other pages can retrieve it, useful for cache
 * @param {*} paramId
 * @param {*} paramState
 */
export function saveExecutionState(paramId, paramState) {
  stateStore.dispatch(saveState(paramId, paramState));
}

/**
 * TODO: put more details comments
 * Add selected parameters to the parameter to be submitted for test run
 * @param {*} selectedTestGroupInfo
 * @param {*} selectedScenario
 * @param {*} testParams
 * @param {*} updateTestParamsCallBack
 */
export function constructTestParamsFromSelectedParameters(selectedTestGroupInfo, selectedScenario,
  testParams, updateTestParamsCallBack, otherParams) {
  let updatedTestParams = testParams;
  // deviceMapping for FAR custom, verify it shortly
  updatedTestParams.deviceMapping = otherParams.deviceMapping;
  updatedTestParams.deviceName = selectedTestGroupInfo.deviceName;
  updatedTestParams.testSuite = selectedScenario.testSuite;
  updatedTestParams.testType = selectedScenario.testType;
  updatedTestParams.testCategory = selectedScenario.testCategory;
  updatedTestParams.lockType = selectedScenario.lockType;
  updatedTestParams.userPreferenceMapping = otherParams.userPreferenceMapping;
  updatedTestParams.deviceTypeId = selectedTestGroupInfo.deviceTypeId;
  updatedTestParams.customerId = selectedTestGroupInfo.customerId;
  updatedTestParams.dsn = selectedTestGroupInfo.dsn;
  updatedTestParams.scenarioType = selectedScenario.scenarioType;
  updatedTestParams.buildInfo = selectedTestGroupInfo.buildInfo;
  updatedTestParams.marketPlace = selectedScenario.marketPlace;
  updatedTestParams.spoofedLocation = selectedScenario.spoofedLocation
  updatedTestParams.sendCompletionEmail = selectedTestGroupInfo.sendCompletionEmail;
  updatedTestParams.isAutoSyncEnabled = selectedScenario.isAutoSyncEnabled;
  updatedTestParams.labId = selectedTestGroupInfo.labId;
  updatedTestParams.isUtteranceRangeSet = selectedScenario.isUtteranceRangeSet;
  updatedTestParams.testOptions = selectedScenario.testOptions;
  updatedTestParams.customizedTestType = selectedScenario.customizedTestType;
  //updatedTestParams.mapping = otherParams.mapping; // doesn't matter should
  updatedTestParams.isDsnInputField = selectedTestGroupInfo.isDsnInputField;
  updatedTestParams.deviceUnderTestId = selectedTestGroupInfo.deviceUnderTestId;
  updatedTestParams.scenarioId = selectedScenario.scenarioId;
  updatedTestParams.testName = selectedTestGroupInfo.testName;
  updatedTestParams.functionalTestCases = selectedScenario.functionalTestCases;
  // calculate test name for each test in test group
  updatedTestParams.calculatedTestName = calulateIndividualTestNameForTestGroup(selectedTestGroupInfo.testName);
  updatedTestParams.uuid = uuidv4();
  updatedTestParams.deviceVirtualAssistant = selectedScenario.deviceVirtualAssistant;
  updatedTestParams.fromTranscriptNum = selectedScenario.fromTranscriptNum;
  updatedTestParams.toTranscriptNum = selectedScenario.toTranscriptNum;
  updatedTestParams.labDependenciesUri = selectedScenario.labDependenciesUri;
  // Add A4PC device config s3 url
  updatedTestParams.deviceConfig = selectedTestGroupInfo.deviceConfig;
  sendRawInfoToNewRun(updatedTestParams, updateTestParamsCallBack);
}

/**
 * Generate test name from testGroupName
 * attach 5 random number digits to testGroupName
 * @param {*} testGroupName  eg, testAcousticSequencing
 * @return testName  eg, testAcousticSequencing_12345
 *
 */
export function calulateIndividualTestNameForTestGroup(testGroupName) {
  let digit = Math.floor(10000 + Math.random() * 9000);
  let testName = testGroupName + '_' + digit;
  return testName;
}

/**
 *
 * @param {*} scenarioType eg ACOUSTIC
 * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/c01d430b0c66fca4fbac780549f0518f3a74920d/--/src/_Constants/AppConstants.js#L223
 * @param {*} testSuite eg AC_NEAR_FIELD
 */
export function getScenarioTypeDisplay(scenarioType, testSuite) {
  let scenarioNameDisplay;
  if (AppConstants.ACOUSTIC_SCENARIO_ID === testSuite) {
    scenarioNameDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE, scenarioType);
  } else if (MusicConstants.MUSIC_SCENARIO_ID === testSuite) {
    scenarioNameDisplay = getDisplayNameForId(MusicConstants.MSP_TYPE, scenarioType);
  } else {
    scenarioNameDisplay = scenarioType;
  }
  scenarioNameDisplay = scenarioNameDisplay.replace(/\s/g, '');
  return scenarioNameDisplay;
}

/**
 * Checks if the A4PC device config path is valid or not
 * @param {string} s3FilePath A4PC device config s3 uri
 * A4PC device config filename follows this format <DSN>_<GUID>.<ext>
 * @return {boolean} Returns true if the dsn matches or false otherwise
 */
export function isValidDeviceConfig(s3FilePath, dsn) {
  // return true if there is no s3FilePath
  if (!s3FilePath) return true;

  const chunks = s3FilePath.split('/');
  const filename = chunks.pop();

  // Return false if the URI doesn't have matching DSN
  return filename.includes(dsn);
}

/**
 * Check whether newRun page data is loaded successful
 * @param {*} loadingFields
 * @param {*} loadErr
 * @param {*} labIdList
 * @param {*} customerIdList
 * @return false, data loaded successful, display newRun test info form.
 *         true, data loading failure, display alert message.
 */
export function isFieldDataFailure(loadingFields, loadErr, labIdList, customerIdList) {
  if (loadingFields || loadErr ||
    (labIdList.length <= 0) ||
    (customerIdList.length <= 0)) {
    return true;
  } else {
    return false;
  }
}

/**
 * Gets the multi-select test cases from list of test cases constants
 * @param {*} testCasesConstants List<string> test cases
 * @returns Multi- select dropdown list of functional test cases
 */
export function getMultiSelectFunctionalTestCases(testCasesConstants) {
  return testCasesConstants.map(test =>
    ({label: test, id: test, disabled: false}));
}
