import React from "react";
import AWSUI from '@amzn/awsui-components-react';

export const SYNC_PROGRESS_COLUMNS = [
    {
        id: 'raspiName',
        header: () => 'RasPi Name',
        cell: item => ( item.raspiName ),
        minWidth: '100px',
        allowLineWrap: false
    },
    {
        id: 'status',
        header: () => 'Status',
        cell: item => ( item.status ),
        minWidth: '100px',
        allowLineWrap: false
    },
    {
        id: 'resourceSyncStatus',
        header: () => (<div><span className="alignleft">Resource Sync Status</span> <span className="alignright awsui-util-status-info nowrap-style">
                          <AWSUI.Icon name="status-info" variant="normal"/>
                          <span className = "awsui-util-ml-xs sync-running-test-status"> View details in labs page
                              </span>
                      </span></div>),
        cell: item => item.resourceSyncStatus,
        minWidth: '200px',
        allowLineWrap: false
    }
];

export const AutoSyncProgressBarConstants = {
    SYNC_STATES: {
        'queued': {
            label: 'Queued',
            className: 'grey-color-text'
        },
        'failed': {
            label: 'Failed',
            className: 'red-color-text'
        },
        'complete': {
            label: 'Completed',
            className: 'green-color-text'
        },
        'inprogress': {
            label: 'In Progress',
            className: 'blue-color-text'
        }
    }
}