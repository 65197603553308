import { Table } from "@amzn/awsui-components-react";
import React from "react";

function StatementsTable({ items = [], columnDefinitions = [] }) {
  if (items.length > 0)
    return (
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={items}
        header={<h2>Statements</h2>}
        stickyHeader={true}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No statements</b>
            </div>
          </div>
        }
      ></Table>
    );
  return null;
}

export default StatementsTable;
