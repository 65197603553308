import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

export const validate = (state) => {
  let error = {};
  if (!state.testSuite) {
    error["testSuite"] = "TestSuite cannot be empty";
  }

  return Object.keys(error).length ? error : null;
};

const getInitialState = () => ({
  actorMapping: {},
  deviceMapping: {},
  mapping: [],
});

export const testMappingSlice = createSlice({
  name: "testMapping",
  initialState: getInitialState(),
  reducers: {
    setMapping: (state, action) => {
      state.mapping = action.payload.mapping;
      state.deviceMapping = action.payload.deviceMapping;
      state.actorMapping = action.payload.actorMapping;
    },
    addMapping: (state, action) => {
      const { name } = action.payload;
      const index = state.mapping.findIndex((m) => m.name === name);
      if (index > -1) {
        state.mapping[index] = {
          ...state.mapping[index],
          ...action.payload,
        };
      } else {
        state.mapping.push({ ...action.payload });
      }
      if (action.payload.configType) {
        // eslint-disable-next-line default-case
        switch (action.payload.configType) {
          case "actor":
            state.actorMapping[name] =
              action.payload.piMeta.thingName;
            break;
          case "dut":
            state.deviceMapping[name] = {
              dsn: action.payload.dsn,
              customerId: action.payload.customerId,
              namespace: get(action.payload, "dsnMeta.namespace", ""),
              deviceType: get(action.payload, "dsnMeta.typeId", ""),
              amazonId: get(action.payload, "dsnMeta.typeId", ""),
              buildInfo: get(action.payload, "dsnMeta.buildInfo", ""),
              cidEmail: get(action.payload, "cidEmail", ""),
            };
            break;
        }
      }
    },
    removeMapping: (state, action) => {
      const { name } = action.payload;
      const filtered = state.mapping.filter((m) => m.name !== name);
      state.mapping = filtered;
    },
    resetMapping: () => getInitialState(),
  },
});

export const { setMapping, addMapping, removeMapping, resetMapping } =
  testMappingSlice.actions;

export const testMappingSelector = (state) => state.testMapping;

export default testMappingSlice.reducer;
