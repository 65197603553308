/**
 * Component to render Mobile Functional test cases
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MOBILE_FUNCTIONAL_TEST_METRICS_COLUMNS, MOBILE_FUNCTIONAL_EXPANDABLE_COLUMNS }
  from './MobileFunctionalTableConfig';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../../_Constants/AppConstants';
import FunctionalTestCases from '../../../../_Constants/FunctionalTestCasesConstants';
import MobileConstants from '../../../../_Constants/MobileConstants';
import '../../Functional/FunctionalLiveFeedStyles.css';
import MobileFunctionalTestMetricsFeeds from "./MobileFunctionalTestMetricsFeeds";
import {
  getOverallTestStatusList, getCompletedTestsNum, getCompletedTestsStats,
  getOverallEta, getTestStatusForValidationType, getFailureLogsForValidationType,
  getOverallValidationStatus, getOverallFailureLogs, getInProgressTestNameDisplay,
  getInProgressStatus, getTestStatusValue, getExpandableSectionContent,
  getFunctionalTestMetrics, getValueForUtteranceFeed, getUtteranceStatus
}
  from '../../Functional/FunctionalLiveFeedUtil';
import {getLoadingMessage} from '../../../../Util';

/**
 * Component to render mobile functional test cases
 * Displays status for all mobile functional tests in a tabular format
 */
class MobileFunctionalTestMetrics extends React.Component {

  state = {
    isSectionExpanded: false
  };

  componentWillMount() {
    this.loadFunctionalTestData();
  }

  /** Gets test status to display for individual test case
   * @param jobStatus Status for test case -> Queued, Running, Failed, Passed or Error
   * @param failureLogs Error logs to display in tooltip in case of test failure/ error
   * @return Component with test status + error tooltip if any
   */
  getTestStatus = (jobStatus, failureLogs) => {
    let testStatusValue = getTestStatusValue(jobStatus);
    let failureLogsString = this.getFailureLogsMessage(failureLogs);
    // Renders test status & if test status is FAILED or ERROR, renders additional tooltip
    // containing error message
    return (
      <AWSUI.ColumnLayout columns={ 4 } borders='none'>
        <div data-awsui-column-layout-root='true'>
          {
            (jobStatus !== FunctionalTestCases.IN_PROGRESS && jobStatus !== FunctionalTestCases.EVALUATING_RESULT) ? (
              <div className={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusClass }>
                <AWSUI.Icon name={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusIcon }
                            variant={ FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].variant }/>
                <span className = { FunctionalTestCases.TEST_STATUS_MAP[testStatusValue].testStatusTextStyle }>
                  { testStatusValue }
                </span>
              </div>
            ) : (
              <div>
                { getInProgressStatus() }
              </div>
            )
          }
          <div></div>
          <div>
            {
              ((jobStatus === FunctionalTestCases.FAILED || jobStatus === FunctionalTestCases.ERROR)
                && failureLogsString && failureLogsString.length > 0) &&
              (
                <AWSUI.Tooltip text={ failureLogsString } size='small' position='top' className='awsui-util-ml-xxl tooltip-inner'>
                  <AWSUI.Icon name='status-info'></AWSUI.Icon>
                </AWSUI.Tooltip>
              )
            }
          </div>
          <div></div>
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  /**
   * Gets error message to display in tooltip for a particular test case
   * @param failureLogs List of failure logs
   * @returns String representing error message
   */
  getFailureLogsMessage = (failureLogs) => {
    let failureLogsString = AppConstants.EMPTY;
    if (failureLogs && failureLogs.length > 0) {
      // Iterate over list of failure logs
      for (let i = 0; i < failureLogs.length; i++) {
        failureLogsString += failureLogs[i];
        failureLogsString += '\n';
      }
    }
    return failureLogsString;
  }

  /**
   * Gets the expandable section for all test cases
   * @param functionalTestsStatus status e.g. IN_PROGRESS
   * @param functionalTestsData Data for list of tests
   * @returns Expandable section
   */
  getExpandableSection = (functionalTestsStatus, functionalTestsData) => {
    return (
      <AWSUI.ExpandableSection
        id = { AppConstants.TEST_CASES }
        header={
          getExpandableSectionContent(AppConstants.TEST_CASES, functionalTestsStatus, this.state.isSectionExpanded)
        }
        expanded={ this.state.isSectionExpanded }
        onChange={ event => { this.onSectionExpanded(AppConstants.TEST_CASES, event) } }
      >
        <AWSUI.Table
          columnDefinitions={ MOBILE_FUNCTIONAL_TEST_METRICS_COLUMNS }
          items={ functionalTestsData }
          variant='borderless'
        >
        </AWSUI.Table>
      </AWSUI.ExpandableSection>
    )
  }

  /**
   * Event handler for expanding/ collapsing Functional test case sections
   */
  onSectionExpanded() {
    this.setState(prevState => ({
      isSectionExpanded: !prevState.isSectionExpanded
    }));
    this.loadFunctionalTestData();
  }

  /**
   * Gets reason to display if overall result is PASSED or FAILED when Cloud or Ref Mic validation results
   * conflict
   * @param overallValidationStatus Overall validation status
   * @param cloudValidationStatus Alexa Cloud validation status
   * @param refMicValidationStatus Ref Mic validation status
   * @returns Reason for overallValidationStatus being PASSED or FAILED
   */
  getOverallValidationReason = (overallValidationStatus, cloudValidationStatus, refMicValidationStatus) => {
    let overallValidationReason = AppConstants.EMPTY;
    if (overallValidationStatus && cloudValidationStatus && refMicValidationStatus) {
      let overallReasonKey = 'OVERALL_' + overallValidationStatus + '_CLOUD_' + cloudValidationStatus
        + '_REF_MIC_' + refMicValidationStatus;
      // Retrieve Overall validation reason from Map
      if (_.has(FunctionalTestCases.OVERALL_VALIDATION_REASON_MAP, overallReasonKey)) {
        return FunctionalTestCases.OVERALL_VALIDATION_REASON_MAP[overallReasonKey];
      }
    }
    return overallValidationReason;
  }

  /**
   * Gets reason to display when results of Cloud Validation & Ref Mic validation are conflicting
   * @param overallValidationReason Reason to display
   * @returns Text in display friendly format
   */
  getOverallValidationReasonDisplay = (overallValidationReason) => {
    return (
      <span className='overall-validation-reason-style'>{ overallValidationReason }</span>
    )
  }

  /**
   * Gets Utterance details button which can be clicked to view all utterances for a test case
   * @param functionalTestCaseName Name of the functional test case
   * @returns Button for a test case
   */
  getUtteranceDetailsButton = (functionalTestCaseName) => {
    return (
      <div className='awsui-util-ml-l'>
        <AWSUI.Button
          variant='normal'
          icon='zoom-in'
          text='Utterances'
          onClick={ () => this.handleTabChange(functionalTestCaseName) }/>
      </div>
    )
  }

  /**
   * Handles onClick event when Utterance button is clcked which results in
   * navigation to Utterance details tab
   * @param functionalTestCaseName Name of the functional test case
   */
  handleTabChange = (functionalTestCaseName) => {
    this.props.onTabChange('utteranceDetailsTab', functionalTestCaseName);
  }

  /**
   * Loads the functional test cases table with data
   * @returns Data for functional scenario in tabular format
   */
  loadFunctionalTestData = () => {
    let testCaseCategoryStatusMap = this.props.params.testCaseCategoryStatusMap;

    // Read functional test cases JSON from params
    let functionalTestsFromJson = this.props.params.functionalTestCases;
    let functionalTestsDataIn = [];
    let testCategoryStatus = FunctionalTestCases.QUEUED;
    if (functionalTestsFromJson && functionalTestsFromJson.length > 0) {
      // Iterate over list of functional test cases
      for (let i = 0; i < functionalTestsFromJson.length; i++) {
        let currentTestCase = functionalTestsFromJson[i];
        if (currentTestCase) {
          // Retrieve status data for current test case
          let functionalTestCaseName = currentTestCase.testCaseName;

          // Retrieve following test statuses:
          // 1. Device Validation
          // 2. Alexa Cloud Validation
          // 3. Ref Mic Validation
          // 4. Overall Status
          let testStatusData = currentTestCase.testCaseData;
          let deviceValidationStatus = getTestStatusForValidationType(testStatusData, MobileConstants.CUSTOM_MOBILE_VALIDATION);
          let deviceValidationFailureLogs = getFailureLogsForValidationType(testStatusData, MobileConstants.CUSTOM_MOBILE_VALIDATION);
          let cloudValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let cloudValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          // Retrieve REF_MIC_VALIDATION status first
          let refMicValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.REF_MIC_VALIDATION);
          let refMicValidationFailureLogs = [];
          // If REF_MIC_VALIDATION is not NOT_APPLICALBLE, retrieve failure logs for the same, otherwise check for
          // REF_MIC_SPEECH_AUDIO_VALIDATION status & failure logs
          if (refMicValidationStatus !== FunctionalTestCases.STATUS_NOT_APPLICABLE) {
            refMicValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.REF_MIC_VALIDATION);
          } else {
            refMicValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.REF_MIC_SPEECH_AUDIO_VALIDATION);
            refMicValidationFailureLogs = getFailureLogsForValidationType(testStatusData, FunctionalTestCases.REF_MIC_SPEECH_AUDIO_VALIDATION);
          }
          let overallValidationStatus = getOverallValidationStatus(currentTestCase);
          let overallFailureLogs = getOverallFailureLogs(currentTestCase);
          let overallValidationReason = this.getOverallValidationReason(overallValidationStatus, cloudValidationStatus,
            refMicValidationStatus);

          functionalTestsDataIn.push({
            functionalTestCaseName: functionalTestCaseName,
            deviceValidationStatus: this.getTestStatus(deviceValidationStatus, deviceValidationFailureLogs),
            cloudValidationStatus: this.getTestStatus(cloudValidationStatus, cloudValidationFailureLogs),
            refMicValidationStatus: this.getTestStatus(refMicValidationStatus, refMicValidationFailureLogs),
            overallValidationStatus: this.getTestStatus(overallValidationStatus, overallFailureLogs),
            comments: this.getOverallValidationReasonDisplay(overallValidationReason),
            utteranceDetails: this.getUtteranceDetailsButton(functionalTestCaseName)
          });

          // Retrieve status for current test case
          if (testCaseCategoryStatusMap.hasOwnProperty(currentTestCase)) {
            testCategoryStatus = testCaseCategoryStatusMap[currentTestCase];
          }
        }
      }
    }
    return this.getExpandableSection(testCategoryStatus, functionalTestsDataIn);
  }

  render() {
    // Refresh functional stats table upon every render
    let testCases = this.loadFunctionalTestData();
    return (
      testCases ? (
        <div>
          <MobileFunctionalTestMetricsFeeds
            params={this.props.params}
          />
          <div>
            <AWSUI.Table
              columnDefinitions={ MOBILE_FUNCTIONAL_EXPANDABLE_COLUMNS }
              items={[{
                testCases: testCases
              }]}
            >
            </AWSUI.Table>
          </div>
        </div>
      ) : (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
          ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
          getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
      )
    );
  }
}

MobileFunctionalTestMetrics.propTypes = {
  onTabChange: PropTypes.func.isRequired
};

export default MobileFunctionalTestMetrics;
