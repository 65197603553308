import { createStore, applyMiddleware, compose } from 'redux';
import middleware from './middleware';
import rootReducer from './reducers';

// this can be saved state from the browser cache
const initialState = {};

// if dev tools extension isn't installed, add a dummy function
const devTools = (
  // Redux devtools extension for easy debugging
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
  ) || (p => p);

const store = () => createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    devTools
  )
);

export default store;
