import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TestGroupInfo from './TestGroupInfo';
import ScenarioSelection from './ScenarioSelection';
import TestRunButtons from './TestRun';
import AddTestConfirmationPage from './AddTestConfirmationPage';
import JobList from './JobQueue';
import { setValidationResult, resetValidationResult } from 'redux/actions/newRun';
import { getWhitelistedCustomerIds } from './controller';
import {
  getDisplayNameForId, getDisplayTagForId, checkScenarioExistsInList,
  logToConsole
} from '../../Util';
import { isFieldDataFailure } from './Util';
import { getFieldEmptyOrErr, getConfirmation } from './uiUtils';
import { isEmpty } from 'lodash';
import AppConstants from '../../_Constants/AppConstants';
import NewRunStateSaveConstants from './NewRunStateSaveConstants';
import { fetchLabs, getWhitelistedScenarios } from '../RasPiTable/controller';
import { stateStore } from '../../Components/State/Store';
import AutoLocalSearchTestConstants from '../../_Constants/AutoLocalSearchTestConstants';
import Details from './JobQueue/Details';
import FunctionalTestCases from "../../_Constants/FunctionalTestCasesConstants";
class NewRun extends React.Component {
  // Using this till this page mess is fixed / refactored
  _mounted = false;
  state = {
    whitelistedCustomerIds: [],
    whitelistedScenarioIds: [],
    labsInAccount: [],
    // Use for FAR Custom, has to select one dsn in testGroupInfo session before additional dsns can be selected on FAR Custom
    primaryDSNSelected: false,
    // For FAR Custom Use, select multiple duts parameters that
    // reflect customer selection in Test Group Info Section
    dutsInUse: new Array(AppConstants.MAX_OPTIONAL_DUTS + 1),
    selectedTestGroupInfo: {
      customerId: AppConstants.EMPTY,
      dsn: AppConstants.EMPTY,
      deviceUnderTestId: AppConstants.EMPTY, // different from dsn
      namespace: AppConstants.EMPTY, // DME namespace, use for coldplay utterance query
      deviceName: AppConstants.UNKNOWN,
      buildInfo: AppConstants.EMPTY, // Firmware Info
      testName: AppConstants.EMPTY, // Now it's test Group Name
      labId: AppConstants.EMPTY,
      deviceTypeId: AppConstants.EMPTY, // TODO: sending deviceType to controller, maybe rename this to deviceType
      dutList: [], // This is necessary to be put into NewRun state, local state would not be good enough.
      isDsnInputField: false, // TODO: implement this logic, see whether need to input dsn manually
      deviceConfig: AppConstants.EMPTY, // TODO: Add this variable to customOptions as this is specific to A4PC
      sendCompletionEmail: false, // check send email section
    },

    // Error control parameters from Test Group Info Section
    selectedTestGroupInfoError: {
      customerId: false,  // need to check when cid load error
      dsn: false,
      testName: false,
      deviceTypeId: false,
      buildInfo: false,
      deviceConfig: false,
      lab: false,
      dutMappingError: false, // what is this use for?
    },

    // parameters that reflect customer selection in Scenario Select Section
    selectedScenario: {
      testSuite: AppConstants.EMPTY,
      scenarioType: AppConstants.EMPTY,
      testType: AppConstants.STANDARD,
      //testCategory: AppConstants.EMPTY,
      //lockType: AppConstants.EMPTY,
      marketPlace: AppConstants.EMPTY,
      scenarioId: AppConstants.EMPTY, // scenario ID from controller side, usually 1-1 mapping with test suite, but FAR Custom has unique scenarioID
      musiciHeartEmailAddr: AppConstants.EMPTY, // For Music Iheart radio configuration, only useful in customOptions
      farFieldCustomOptionsSelected: false,
      nearFieldCustomOptionsSelected: false,
      trainedMobileCustomOptionsSelected: false, // used for trained mobile customized option selection
      mobileVoiceRobustnessCustomOptionsSelected: false,
      mobileFunctionalCustomOptionsSelected: false,
      acousticWakewordCustomOptionsSelected: false,
      closeTalkCustomOptionsSelected: false,
      qualCustomOptionsSelected: false,
      automotiveCustomOptionsSelected: false,
      testTypeArr: [false, false, false, false], // being used in onSmokeClickValidate
      testCategoryArr: [false, false, false],
      lockTypeArr: [false, false],
      additionalDutList: new Array(AppConstants.MAX_OPTIONAL_DUTS), //Used for FAR Custom, to store loaded dut list for additional dut selections.
      // TODO: move it out to another place
      // looks like this is the place where parameters eventually sent to ABESController
      // key parameters will be sent to controller
      testOptions: {
        Smoke: "false",
        customOptions: {
          spoofedLocation: AppConstants.NOT_APPLICABLE,
          amazonId: AppConstants.EMPTY,  //same as deviceTypeId for now
          customizedTestType: AppConstants.EMPTY, // CustomOptions, Customized standard, Quick or Demo
          delayBetweenUtterances: AppConstants.DELAY_BETWEEN_UTTERANCES_NON_CUSTOM,
          deviceName: AppConstants.UNKNOWN,
          deviceVirtualAssistant: AppConstants.EMPTY, // For Auto Local Search
          disableAdminCheck: false, //
          mobileVoiceTrainingIsManual: false, // Used in mobile voice training
          isDevice1PSV: false, // Used in mobile voice training
          disablePlaybackSkill: false, // Used in Acoustic and mobile voice training
          isProxyEnabled: false,  // proxy switch
          is3PDAEnabled: false, // For using 3PDA/Mic-less Device
          useCustomNoises: false, // Morgan
          musiciHeartEmailAddr: AppConstants.EMPTY, // For Music Iheart radio configuration,
          //value taken from selectedScenario.musiciHeartEmailAddr during validation
          functionalTestCases: [], // used for functional test suite, looks like for functional test suite
          // only this field is sent to controller,
          // https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/e23ed5ba2c9d4c931b9dfeac81d29bb66b094657/--/src/Container/NewRun/index.js#L2778
        },
        testSuite: AppConstants.EMPTY, // Not being updatd for now.
        scenarioType: AppConstants.EMPTY, // needed by music sequencing
        amazonId: AppConstants.EMPTY,
        deviceName: AppConstants.UNKNOWN,
        resourcesRequired: AppConstants.EMPTY, // resources required for auto sync resource, for auto resource sync, TODO check where it's being set
      },

      delayBetweenUtterances: AppConstants.DELAY_BETWEEN_UTTERANCES_NON_CUSTOM,
      isDelayBetweenUtterancesChecked: false,
      expandedSections: [], // is it for functional only ? function is using it.
      expandedSectionsFARCustom: [], // FAR Custom usage
      customizedTestType: AppConstants.EMPTY, // CustomOptions, Customized standard, Quick or Demo
      trainedUsers: [], // For Mobile Training scenario
      noiseSelection: [],
      deviceVirtualAssistant: AppConstants.EMPTY, // For Auto Local Search
      fromTranscriptNum: AutoLocalSearchTestConstants.ZERO,    // For AutoLocalSearch
      toTranscriptNum: AutoLocalSearchTestConstants.ZERO, // For AutoLocalSearch

      isUtteranceRangeSet: false,  // where? looks like AutoLocalSearch

      isProxyEnabled: false, // proxy backend service switch
      is3PDAEnabled: false, // For using 3PDA/Mic-less Device
      isAutoSyncEnabled: true, // Now only is option for Acoustic tests, boolean
      mobileVoiceTrainingIsManual: false, // Used in mobile voice training
      isDevice1PSV: false,
      disablePlaybackSkill: false, // Used in Acoustic and mobile voice training
      disableAdminCheck: false, // what is this used for
      functionalTestCases: [], // being used for functional or mobile functional test cases
      isMultimodalDevice: false, // adapt to see whether DUT is multimodal device, currently only used in functional test case.
      /**
       * Qual configuration settings will be stored here and validations
       * are performed on this before submitting the test
       */
      qual: {
        spl: {}
      },
      /**
       * Custom Test scenario configuration and actor mapping will be saved here
       */
      customTestOptions: {
        actorLabMapping: {}
      },
      /**
       * Specific to A4PC device to log the wake word model type
       */
      a4pcWakewordModel: "SWW"
    },

    selectedScenarioError: {
      testSuite: false, // monitor whether Test Suite selection box has any error
      testOptions: false, // to be used in onSmokeClickValidate
      testTypeArr: false,
      testCategoryArr: false,
      lockTypeArr: false,
      dutMappingError: false,
      marketPlace: false,
      spoofedLocation:AppConstants.NOT_APPLICABLE,
      deviceMappingError: AppConstants.EMPTY, // looks like only be used in FARCustom, need to confirm
      trainedUsers: false,
      noiseSelection: false,
      functionalTestCases: false,
      fromTranscriptNum: false,
      toTranscriptNum: false,
      transcriptRangeError: AppConstants.EMPTY, // for what?
    },

    // parameters not selected by customers but is generated from API calls
    otherParams: {
      companyId: AppConstants.EMPTY,
      deviceMapping: {}, //TODO: move this into mapping
      mapping: {}, // Found in generateMapping, contains actorMapping, noiseMapping
      userPreferenceMapping: {}, // Not sure what to use, found in generateMapping
    },

    // parameters not selected by customers but is generated from API calls
    otherParamsError: {
      // mainly record for deviceMapping error,
      //eg  DUT_1
    },

    // Add all parameters related to page transition
    pageDisplayParams: {
      runTest: false, // TODO: check for usage
      runTestGroup: false, // Determine whether Test Group Run Button is enabled or disabled
      testOptionsDisplay: false, // View Test Detailed information after selection is finished for AddTestPreview
      disableBack: false, // used for BackButton in AddTestConfirmationPage
      viewJobListTableDetails: false, // used for view button for jobListTable
    },

    // Parameters that is being used in test confirmation page, and also will be passed
    // to test submission.
    testParams: {
      calculatedTestName: AppConstants.EMPTY, // test name for test groups
      uuid: AppConstants.EMPTY, // To tell among different testParams when put into testParamsArray
      scenarioId: AppConstants.EMPTY, // what is for? looks like it's 1-1 mapping with testSuite
      isDsnInputField: false, // TODO: implement this logic, see whether dsn is input manually, may not need it in testParams
      deviceMapping: {},
      userPreferenceMapping: {}, // Not sure what to use, found in generateMapping
      testSuite: AppConstants.EMPTY,
      scenarioType: AppConstants.EMPTY,
      testCategory: AppConstants.EMPTY,
      testType: AppConstants.STANDARD,
      //lockType: AppConstants.EMPTY,
      customerId: AppConstants.EMPTY,
      dsn: AppConstants.EMPTY,
      deviceConfig: AppConstants.EMPTY, // use for A4PC device
      deviceName: AppConstants.UNKNOWN,
      buildInfo: AppConstants.EMPTY, // Firmware Info
      testName: AppConstants.EMPTY, // Now it's test Group Name
      marketPlace: AppConstants.EMPTY, // Locale
      spoofedLocation: AppConstants.NOT_APPLICABLE,
      labId: AppConstants.EMPTY,
      deviceTypeId: AppConstants.EMPTY,
      deviceVirtualAssistant: AppConstants.EMPTY, // For Auto Local Search
      fromTranscriptNum: AutoLocalSearchTestConstants.ZERO,    // For AutoLocalSearch
      toTranscriptNum: AutoLocalSearchTestConstants.ZERO, // For AutoLocalSearch
      sendCompletionEmail: false, // check send email section
      isAutoSyncEnabled: true, // Now only used for acoustic, to see whether auto resource sync is enabled
      //fallBackToUtterancePlay: false, // Use for UDT. Flag to check if utterance injection switch endpoint fails, whether to fall back pi utterance play.
      isUtteranceRangeSet: false,  // where? looks like AutoLocalSearch
      testOptions: {}, // copied from selectedScenario
      customizedTestType: AppConstants.EMPTY, // CustomOptions, Customized standard, Quick or Demo
      mapping: {}, // contains actor, noise mapping, company ID
      deviceUnderTestId: AppConstants.EMPTY, // different from dsn, used in test group run submission
    }, // testParams after verification, be able to submit to test run

    detailsTestParams: {}, // copy of testParams from testParamsArray, depends on jobListTable customer selection

    // array of testParams, final parameters submitted to controller is calculated
    // out from this array
    testParamsArray: [],
    loadErr: false,
    loadingFields: false, // what's the use?
    cidLoaded: false,  // indicate whether cid is loaded
    // used for pop up window if it failed when Run Test Group button is clicked
    confirm: {
      pop: false,
      title: 'Test Submission Failed',
      message: AppConstants.EMPTY,
      callback: () => { }
    },
  };

  // TODO: load state use stateStore
  // after customer select something, need to store it, clear it after people click runTest
  componentDidMount() {
    this._mounted = true;
    this.setFields();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <div>
        <div>
          {this.state.pageDisplayParams.viewJobListTableDetails && (
            <Details
              params={{
                onConnectedDeviceChange: this.onConnectedDeviceChange,  // hotspot connected device onclick call back
                testParams: this.state.detailsTestParams,
                labs: this.state.labsInAccount, // use labs label when displaying labs
                back: this.onDetailsPageBackButtonClicked,
              }}
            />
          )
          }
          {this.state.pageDisplayParams.testOptionsDisplay && (
            <AddTestConfirmationPage params={{
              selectedTestGroupInfo: this.state.selectedTestGroupInfo,
              selectedTestGroupInfoCallBack: this.retrieveSelectedTestGroupInfo,
              selectedScenario: this.state.selectedScenario,
              selectedScenarioCallBack: this.retrieveSelectedScenario,
              selectedScenarioError: this.state.selectedScenarioError, // may not need
              scenarioErrorCallBack: this.retreieveScenarioSelectionError,
              labsInAccount: this.state.labsInAccount,
              otherParams: this.state.otherParams,
              updateOtherParamsCallBack: this.updateOtherParams,
              pageDisplayParams: this.state.pageDisplayParams,
              updatePageDisplayParamsCallBack: this.updatePageDisplayParams,
              testParams: this.state.testParams,
              updateTestParamsCallBack: this.updateTestParams,
              testParamsArray: this.state.testParamsArray,
              updateTestParamsArrayCallBack: this.updateTestParamsArray,
            }} />
          )
          }
          {
            !this.state.pageDisplayParams.testOptionsDisplay &&
            !this.state.pageDisplayParams.viewJobListTableDetails && (
              isFieldDataFailure(this.state.loadingFields, this.state.loadErr,
                this.state.labsInAccount, this.state.whitelistedCustomerIds) ? (
                  getFieldEmptyOrErr(this.state.loadingFields, this.state.loadErr,
                    this.state.labsInAccount, this.state.whitelistedCustomerIds)
                ) : (
                  <div>
                    <TestGroupInfo params={{
                      whitelistedCustomerIds: this.state.whitelistedCustomerIds,
                      selectedTestGroupInfo: this.state.selectedTestGroupInfo,
                      selectedTestGroupInfoCallBack: this.retrieveSelectedTestGroupInfo,
                      selectedTestGroupInfoError: this.state.selectedTestGroupInfoError,
                      selectedTestGroupInfoErrorCallBack: this.retrieveSelectedTestGroupInfoError,
                      otherParams: this.state.otherParams,
                      labsInAccount: this.state.labsInAccount,
                      primaryDSNSelected: this.state.primaryDSNSelected, // TODO: not necessary pass into TestGroupInfo, remove it
                      updateNewRunStateParameterCallBack: this.updateNewRunStateParameter,
                      lockFields: !isEmpty(this.state.testParamsArray) // To disable the fields if atleast 1 job is sequenced
                    }} />

                    <ScenarioSelection
                      params={{
                        whitelistedScenarioIds: this.state.whitelistedScenarioIds,
                        selectedScenario: this.state.selectedScenario,
                        selectedScenarioError: this.state.selectedScenarioError,
                        selectedScenarioCallBack: this.retrieveSelectedScenario,
                        scenarioErrorCallBack: this.retreieveScenarioSelectionError,
                        otherParams: this.state.otherParams, // TODO: only pass deviceMapping there
                        updateOtherParamsCallBack: this.updateOtherParams,
                        whitelistedCustomerIds: this.state.whitelistedCustomerIds,
                        dutsInUse: this.state.dutsInUse,
                        updateNewRunStateParameterCallBack: this.updateNewRunStateParameter,
                        labId: this.state.selectedTestGroupInfo.labId,
                        labsInAccount: this.state.labsInAccount,
                        //use for FAR Custom only for now, indicate whether addtional duts can be selected.
                        primaryDSNSelected: this.state.primaryDSNSelected,
                        lockFields: !isEmpty(this.state.testParamsArray), // To disable the fields if atleast 1 job is sequenced
                        selectedTestGroupInfo: this.state.selectedTestGroupInfo // If the scenario selection needs TestGroupInfo to make decisions
                      }}
                      resetValidationResult={this.props.resetValidationResult}
                    />

                    { !isEmpty(this.state.testParamsArray) &&
                      <JobList params={{
                        testParamsArray: this.state.testParamsArray,
                        updateTestParamsArrayCallBack: this.updateTestParamsArray,
                        labsInAccount: this.state.labsInAccount,
                        pageDisplayParams: this.state.pageDisplayParams,
                        updatePageDisplayParamsCallBack: this.updatePageDisplayParams,
                        detailsTestParams: this.state.detailsTestParams,
                        updateNewRunStateParameterCallBack: this.updateNewRunStateParameter,
                      }} />
                    }
                    <TestRunButtons params={{
                      selectedTestGroupInfo: this.state.selectedTestGroupInfo,
                      selectedTestGroupInfoCallBack: this.retrieveSelectedTestGroupInfo,
                      selectedTestGroupInfoError: this.state.selectedTestGroupInfoError,
                      selectedTestGroupInfoErrorCallBack: this.retrieveSelectedTestGroupInfoError,
                      selectedScenario: this.state.selectedScenario,
                      selectedScenarioCallBack: this.retrieveSelectedScenario,
                      selectedScenarioError: this.state.selectedScenarioError,
                      scenarioErrorCallBack: this.retreieveScenarioSelectionError,
                      labsInAccount: this.state.labsInAccount,
                      otherParams: this.state.otherParams,
                      otherParamsError: this.state.otherParamsError,
                      updateOtherParamsCallBack: this.updateOtherParams,
                      pageDisplayParams: this.state.pageDisplayParams,
                      updatePageDisplayParamsCallBack: this.updatePageDisplayParams,
                      testParams: this.state.testParams,
                      updateTestParamsCallBack: this.updateTestParams,
                      testParamsArray: this.state.testParamsArray,
                      updateTestParamsArrayCallBack: this.updateTestParamsArray,
                      confirm: this.state.confirm,
                      updateNewRunStateParameterCallBack: this.updateNewRunStateParameter,
                    }}
                      releaseNotesVersionCallBack={this.props.releaseNotesVersionCallBack}
                    />
                  </div>
                )
            )
          }
          <div>
            {this.state.confirm.pop && (
              getConfirmation(this.state.confirm, this._mounted, this.updateNewRunStateParameter)
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Action when Back Button is clicked
   * This BackButton is located on Details page
   */
  onDetailsPageBackButtonClicked = () => {
    let updatedPageDisplayParams = this.state.pageDisplayParams;
    updatedPageDisplayParams.viewJobListTableDetails = false;
    this.updatePageDisplayParams(updatedPageDisplayParams, 'onDetailsPageBackButtonClicked');
  }

  /**
  * Method to retrieve whitelisted customer IDs from controller
  * Return values through state parameters
  * Can it be moved to lower level?
  */
  retrieveCIDs = () => {
    let customerIdsIn = [];
    return Promise.resolve(getWhitelistedCustomerIds().then(cidResponse => {
      // eslint-disable-next-line no-prototype-builtins
      if (!cidResponse.hasOwnProperty('error')) {
        cidResponse.cid.forEach(customerID => {
          let cidValue = Object.keys(customerID)[0];
          customerIdsIn.push({
            id: cidValue,
            label: customerID[cidValue],
            description: cidValue
          });
        });
        this.setState({
          cidLoaded: true,
        });
        return { whitelistedCustomerIds: customerIdsIn };
      } else {
        return { loadErr: cidResponse.error };
      }
    }));
  }

  //TODO: call back to return TestGroupInfo data to NewRun Page
  // TODO: refactor these callback functions to be driven by id
  retrieveSelectedTestGroupInfo = (testGroupInfo, identifier) => {
    logToConsole("Identifier for retrieveSelectedTestGroupInfo: " + identifier);
    this.setState({
      selectedTestGroupInfo: testGroupInfo
    });
    logToConsole("testGroupInfo is " + JSON.stringify(testGroupInfo));
    // update information regarding to whether primary dsn is selected
    if (!isEmpty(testGroupInfo) && !isEmpty(testGroupInfo.dsn)) {
      let dutInUse = this.state.dutsInUse;
      dutInUse[0] = testGroupInfo.dsn;
      this.setState({
        primaryDSNSelected: true,
        dutInUse: dutInUse,
      })
    } else {
      this.setState({
        primaryDSNSelected: false
      })
    }
  }
  /**
   * @param {*} testGroupInfoError
   * @param {*} identifier from where the state is updated
   */
  retrieveSelectedTestGroupInfoError = (testGroupInfoError, identifier) => {
    logToConsole("Identifier for retreieveScenarioSelectionError: " + identifier);
    this.setState({
      selectedTestGroupInfoError: testGroupInfoError
    });
    logToConsole("testGroupInfoError is " + JSON.stringify(testGroupInfoError));
  }

  retrieveSelectedScenario = (selectedScenario, identifier) => {
    logToConsole("Identifier for retrieveSelectedScenario: " + identifier);
    this.setState({
      selectedScenario: selectedScenario
    });
    logToConsole("scenario is " + JSON.stringify(selectedScenario));
  }

  retreieveScenarioSelectionError = (selectedScenarioError, identifier) => {
    logToConsole("Identifier for retreieveScenarioSelectionError: " + identifier);
    this.setState({
      selectedScenarioError: selectedScenarioError
    });
    this.props.setValidationResult(selectedScenarioError);
    logToConsole("scenario Selection error is " + JSON.stringify(selectedScenarioError));
  }

  // update  otherParams in state
  updateOtherParams = (otherParams, identifier) => {
    logToConsole("Identifier for updateOtherParams: " + identifier);
    this.setState({
      otherParams: otherParams
    });
    logToConsole("updated otherParams is " + JSON.stringify(otherParams));
    logToConsole("current testParams is " + JSON.stringify(this.state.testParams));

    // update test params for mapping accordingly.
    let updatedTestParams = this.state.testParams;
    updatedTestParams.mapping = otherParams.mapping;
    updatedTestParams.userPreferenceMapping = otherParams.userPreferenceMapping;
    updatedTestParams.deviceMapping = otherParams.deviceMapping; // TODO: verify whether this is needed.
    this.updateTestParams(updatedTestParams, 'updateOtherParams');
    logToConsole("After update, testParams is " + JSON.stringify(this.state.testParams));
  }

  // TODO: modify
  // update  pageDisplayParams in state
  // identifier is to show where the state update is coming from
  updatePageDisplayParams = (pageDisplayParams, identifier) => {
    logToConsole("Identifier for updatePageDisplayParams: " + identifier);
    const toUpdate = pageDisplayParams;
    this.setState({
      pageDisplayParams: toUpdate
    });
    logToConsole("State pageDisplayParams is " + JSON.stringify(this.state.pageDisplayParams));
    logToConsole("updated pageDisplayParams is " + JSON.stringify(pageDisplayParams));
  }

  // update testParams
  updateTestParams = (testParams, identifier) => {
    logToConsole("Identifier for updateTestParams: " + identifier);
    this.setState({
      testParams: testParams
    });
    logToConsole("updated testParams is " + JSON.stringify(testParams));
  }

  // update testParamsArray
  updateTestParamsArray = (testParamsArray, identifier) => {
    logToConsole("Identifier for updateTestParamsArray: " + identifier);
    this.setState({
      testParamsArray: testParamsArray
    });
    logToConsole("updated testParamsArray is " + JSON.stringify(testParamsArray));
    // enable run test group button when this array length larger than 1.
    if (testParamsArray.length > 0) {
      let updatedPageDisplayParams = this.state.pageDisplayParams;
      updatedPageDisplayParams.runTestGroup = true;
      this.updatePageDisplayParams(updatedPageDisplayParams, 'updateTestParamsArray');
    } else {
    // disable run test group button when job list table is cleared.
      let updatedPageDispisplayParams = this.state.pageDisplayParams;
      updatedPageDispisplayParams.runTestGroup = false;
      this.updatePageDisplayParams(updatedPageDispisplayParams, 'updateTestParamsArray');
    }
  }

  /**
   * Update particular state parameter on NewRun Page, by id
   * @param {*} paramValue: values to be updated to.
   * @param {*} id: stateId to update, eg, dutsInUse
   * @param {*} identifier: indication regarding to where the update parameter is from
   */
  updateNewRunStateParameter = (paramValue, id, identifier) => {
    logToConsole("Identifier for updateNewRunStateParameter: " + identifier);
    logToConsole("Id: " + id);
    logToConsole("Before update: " + JSON.stringify(this.state[id]));
    this.setState({
      [id]: paramValue
    });
    logToConsole("After update: " + JSON.stringify(this.state[id]));
  }

  //TODO: change name, or move it to other level ?
  setFields = () => {
    this.setState({
      loadingFields: true,
      loadErr: false
    });
    let errors = AppConstants.EMPTY;
    Promise.all([this.retrieveLabs(), this.retrieveCIDs(), this.retrieveScenarios()]).then(fields => {
      fields.forEach(field => {
        // eslint-disable-next-line no-prototype-builtins
        if (field.hasOwnProperty('loadErr')) {
          //TODO: Append errors here when we move on to specific error messages
          errors = field.loadErr;
        } else {
          if (field[Object.keys(field)[0]].length > 0) {
            this.setState({
              ...this.state,
              [Object.keys(field)[0]]: field[Object.keys(field)[0]]
            });
            logToConsole("State is set up here", JSON.stringify([Object.keys(field)[0]]));
          }
        }
      });
      if (errors === AppConstants.EMPTY) {
        //Retrieveing the state if set already
        this.loadExistingState();
      } else {
        this.setState({
          loadingFields: false,
          loadErr: errors
        });
      }
    });
  }

  loadExistingState = () => {
    let existingSelectedTestGroupInfo = {};
    let existingSelectedScenario = {};
    let existingOtherParams = {};
    let existingPageDisplayParams = {};
    let existingTestParams = {}; // now reload it from state for testOptionDisplay
    let existingTestParamsArray = {}; // now reload it from state for jobListTable, when customer navigate to other page
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.SELECTED_TEST_GROUP_INFO)) {
      existingSelectedTestGroupInfo = stateStore.getState()[NewRunStateSaveConstants.SELECTED_TEST_GROUP_INFO];
    }
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.SELECTED_SCENARIO)) {
      existingSelectedScenario = stateStore.getState()[NewRunStateSaveConstants.SELECTED_SCENARIO];
    }
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.OTHER_PARAMS)) {
      existingOtherParams = stateStore.getState()[NewRunStateSaveConstants.OTHER_PARAMS];
    }
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.PAGE_DISPLAY_PARAMS)) {
      existingPageDisplayParams = stateStore.getState()[NewRunStateSaveConstants.PAGE_DISPLAY_PARAMS];
    }
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.TEST_PARAMS)) {
      existingTestParams = stateStore.getState()[NewRunStateSaveConstants.TEST_PARAMS];
    }
    // eslint-disable-next-line no-prototype-builtins
    if (stateStore.getState().hasOwnProperty(NewRunStateSaveConstants.TEST_PARAMS_ARRAY)) {
      existingTestParamsArray = stateStore.getState()[NewRunStateSaveConstants.TEST_PARAMS_ARRAY];
    }
    if (Object.keys(existingSelectedTestGroupInfo).length > 0) {
      this.setState({
        selectedTestGroupInfo: existingSelectedTestGroupInfo,
        selectedScenario: existingSelectedScenario,
        otherParams: existingOtherParams,
        pageDisplayParams: existingPageDisplayParams,
        testParams: existingTestParams,
        testParamsArray: existingTestParamsArray,
        loadingFields: false
      });
    } else {
      this.setState({
        loadingFields: false
      });
    }
  }

  /**
   * Method to retrieve whitelisted scenarios from controller
   */
  retrieveScenarios = () => {
    let whitelistedScenariosIn = [];
    return Promise.resolve(getWhitelistedScenarios().then(response => {
      // eslint-disable-next-line no-prototype-builtins
      if (!response.hasOwnProperty('error')) {
        response.scenarios.forEach(whitelistedScenario => {
          if (AppConstants.WHITELISTED_SCENARIO_TYPES.includes(whitelistedScenario.name)
            && !checkScenarioExistsInList(whitelistedScenariosIn, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name])) {
            whitelistedScenariosIn.push({
              scenarioId: whitelistedScenario.id,
              id: AppConstants.TEST_SUITE_MAP[whitelistedScenario.name],
              label: getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name]),
              tags: [getDisplayTagForId(AppConstants.WHITELISTED_SCENARIOS, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name])]
            });
          }
        });

        // FAR is now a special scenario in acoustic that runs AQTEngine and has to be dealt specially. Hence preseving its scenarioId
        // so that we can override AVSCertSimulation jar with AVSCertFAR which is mirror.
        /* TODO: Until Controller supports multi scenario ids for Test Sequencing, FAR will use AVSCertSimulation
        response.scenarios.forEach(whitelistedScenario => {
          if (whitelistedScenario.name === AppConstants.FAR_JAR_NAME) {
            logToConsole('Successfully retrieved FAR Scenario Id!');
            this.setState({
              testParams: {
                ...this.state.testParams,
                farScenarioId: whitelistedScenario.id
              }
            });
          }
        });
        */

        if (whitelistedScenariosIn.length === 1) {
          // default value when there's only one scenario
          this.setState({
            selectedScenario: {
              ...this.state.selectedScenario, testSuite: whitelistedScenariosIn[0].id,
              scenarioId: whitelistedScenariosIn[0].scenarioId
            }
          });
        }
        return { whitelistedScenarioIds: whitelistedScenariosIn };
      } else {
        return { loadErr: response.error };
      }
    }));
  }

  //TODO: deal with the case lab is not loaded properly
  /**
   * Method to retrieve labs
   * setLabs
   */
  retrieveLabs = () => {
    let labsIn = [];
    let companyId = AppConstants.EMPTY;
    //TODO: Disabling Session storage with respect to multiple account usage.
    //const labsPiscachedHits = sessionStorage.getItem(AppConstants.cache.LABSPIS);
    const labsPiscachedHits = sessionStorage.removeItem(AppConstants.cache.LABSPIS);
    if (labsPiscachedHits) {
      logToConsole('Retrieved from Cache: ', AppConstants.cache.LABSPIS);
      let labsAndThings = JSON.parse(labsPiscachedHits);
      Object.keys(labsAndThings).forEach(labId => {
        labsIn.push({
          id: labId,
          label: labsAndThings[labId].lab.name,
          lock: labsAndThings[labId].lab.lock
        });
        // Fetch company ID to add it to mapping object
        // Only one company Id to all labIds
        if (companyId === AppConstants.EMPTY) {
          companyId = labsAndThings[labId].lab.companyId;
        }
      });
      this.setState({
        otherParams: {
          ...this.state.otherParams,
          companyId: companyId
        }
      });
      return Promise.resolve({ labId: labsIn });
    } else {
      return Promise.resolve(fetchLabs().then(labs => {
        // eslint-disable-next-line no-prototype-builtins
        if (!labs.hasOwnProperty('error')) {
          labs.forEach(lab => {
            labsIn.push({
              id: lab.id,
              label: lab.name,
              preference: lab.preference,
              lock: lab.lock
            });
            // Fetch company ID to add it to mapping object
            if (companyId === AppConstants.EMPTY) {
              companyId = lab.companyId;
            }
          });
          this.setState({
            otherParams: {
              ...this.state.otherParams,
              companyId: companyId
            }
          });
          return { labsInAccount: labsIn };
        } else {
          return { loadErr: labs.error };
        }
      }));
    }
  }
}

// TODO: update this
NewRun.propTypes = {
  releaseNotesVersionCallBack: PropTypes.func
};

export default connect(s => s, {
  setValidationResult,
  resetValidationResult
})(NewRun);
