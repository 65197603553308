import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';

export const COLUMN_DEFINITIONS = [{
  id: 'testName',
  header: () => 'Test Name',
  cell: item => item.testName,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'testSuiteName',
  header: () => AppConstants.TEST_SUITE_LABEL,
  cell: item => item.testSuiteName,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioType',
  header: () => AppConstants.ACOUSTIC_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeFunc',
  header: () => AppConstants.FUNCTIONAL_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeStability',
  header: () => AppConstants.STABILITY_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeUPL',
  header: () => AppConstants.UPL_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeQual',
  header: () => AppConstants.QUAL_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeCustomTest',
  header: () => AppConstants.CUSTOM_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeAutoLocalSearch',
  header: () => AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeAutomotive',
  header: () => AppConstants.AUTOMOTIVE_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'deviceVirtualAssistant',
  header: () => 'Device Virtual Assistant',
  cell: item => item.deviceVirtualAssistant,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeCloseTalk',
  header: () => AppConstants.CLOSE_TALK_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioTypeMobile',
  header: () => AppConstants.MOBILE_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'msp',
  header: () => MusicConstants.MUSIC_SCENARIO_LABEL,
  cell: item => item.scenarioType,
  minWidth: '200px',
  allowLineWrap: true
}, {
  id: 'testType',
  header: () => 'Test Type',
  cell: item => item.testType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'devicetype',
  header: () => 'Amazon ID',
  cell: item => item.deviceType,
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'date',
  header: () => 'Date',
  cell: item => item.date.toString(),
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'status',
  header: () => 'Test Status',
  cell: item => ( item.testStatus(item.state, item.reportStatus, item.summaryReportDownloadURL) ),
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'results',
  header: () => 'Results',
  cell: item => ( item.urlButton(item.id, item.state, item.summaryReportDownloadURL) ),
  minWidth: '100px',
  allowLineWrap: true
}];

export const SORTABLE_COLUMNS = [
  {id: 'testName', field: 'testName'},
  {id: 'testSuiteName', field: 'testSuiteName'},
  {id: 'scenarioType', field: 'scenarioType'},
  {id: 'scenarioTypeQual', field: 'scenarioTypeQual'},
  {id: 'testType', field: 'testType'},
  {id: 'msp', field: 'msp'},
  {id: 'devicetype', field: 'devicetype'},
  {id: 'date', field: 'date'},
  {id: 'status', field: 'status'},
];
