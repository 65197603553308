import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

export const validate = (state) => {
  let error = {};
  if(!state.testSuite) {
    error['testSuite'] = 'TestSuite cannot be empty';
  }

  return Object.keys(error).length ? error : null;
}

const getInitialState = () => ({
  testOptions: {}
});

export const testOptsSlice = createSlice({
  name: 'testMapping',
  initialState: getInitialState(),
  reducers: {
    setTestOpt: (state, action) => {
      for (var key in action.payload) {
        state.testOptions[key] = action.payload[key];
    }
    },
    resetTestOpts: () => getInitialState(),
  }
});

export const { setTestOpt, resetTestOpts } = testOptsSlice.actions

export const testOptsSelector = (state) => state.testOptions

export default testOptsSlice.reducer
