import React from 'react'
import './assets/app.css';
import './pages/newRun/newRun.css';

export default ({ children }) => {

  return (
    <div className='newSystemApp'>
      {children}
    </div>
  );
}