import {logToConsole} from "../../Util";
import {getBannerMessages} from "./controller";

/**
 * Method to get banner messages from the controller.
 */
export function fetchBannerMessages(){
  return Promise.resolve(getBannerMessages().then(response => {
    if (!response.hasOwnProperty('error')) {
      logToConsole('Banner Messages from controller = ' + JSON.stringify(response));
      return response.bannerMessages;
    }
      logToConsole('Error retrieving banner messages from controller');
      return null;
  }));
}

const statusCodeMap = {
  "100": "info",
  "200": "success",
}

function getMessageType (code){
  return statusCodeMap[code] || "error";
}

/**
 * Check if banner messages are latest.
 */
function shouldDisplayBanner(startDate, endDate) {
  // Compute message times in millis
  const messageStartDateTime = new Date(startDate);
  const messageEndDateTime = new Date(endDate);
  const currentDateTime = new Date()

  // Check if banner is not outdated
  return currentDateTime >= messageStartDateTime && (endDate == null || currentDateTime <= messageEndDateTime);
}

/**
 * Filters out outdated banner messages.
 */
export function getLatestBannerMessages (bannerMessages){
  return bannerMessages
    .filter(({ startDate, endDate }) => startDate && shouldDisplayBanner(startDate, endDate))
    .map(message =>({
      type: getMessageType(message.code),
      dismissible: true,
      content: message.message
    }))
}
