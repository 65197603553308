import React, { Component } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { getLoadingMessage, logToConsole } from '../../../Util';
import AppConstants from '_Constants/AppConstants';
import Containers from './Containers';

class PortScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgressTest: null
    };
  }

  /**
   * render loader message
   */
  renderLoadingMessage() {
    const { testStatus } = this.props;
    const loadingMessage = testStatus === AppConstants.RASPI_RESOURCES_SYNCING
      ? AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE
      : AppConstants.RETRIEVING_DATA_MESSAGE;
    return getLoadingMessage(loadingMessage);
  }

  /**
   * render download report button
   * @param {*} s3DownloadURL
   */
  renderDownloadButton(s3DownloadURL) {
    if (!s3DownloadURL) return;
    return (
      <AWSUI.Button
        variant='primary'
        icon='download'
        target='_blank'
        href={s3DownloadURL}
      >
        {`Report`}
      </AWSUI.Button>
    );
  }

  /**
   * render test information header panel
   * @param {*} label
   * @param {*} status
   * @param {*} s3DownloadURL
   */
  getHeader = (label, status, s3DownloadURL) => {
    const classMap = {
      PENDING: 'awsui-util-status-negative',
      FAILED: 'awsui-util-status-negative',
      RUNNING: 'awsui-util-status-positive',
      INPROGRESS: 'awsui-util-status-positive',
      COMPLETED: 'awsui-util-status-positive',
    };
    const defaultClass = 'awsui-util-status-positive';

    const color = classMap[status] || defaultClass;

    return (
      <AWSUI.ColumnLayout columns={4} borders="none">
        <div className="awsui-util-mb-xs" data-awsui-column-layout-root="true">
          <h3 className="test-category-text-style">{label}</h3>
          <div />
          <div />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>
              <span>{`Status:`}</span>
              <span className={color}>{` ${status}`}</span>
            </div>
            <div>{this.renderDownloadButton(s3DownloadURL)}</div>
          </div>
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  handleChange = (type) => {
    return (event) => {
      this.setState({
        [type]: event.detail.expanded
      })
    };
  }

  /**
   * render test details
   */
  renderTestDetails = (test) => {
    const { type } = test;
    const testdetailsContainerMap = {
      PORT_DETECTION: Containers.PortDetection,
      HTTP_ENUMERATION: Containers.HTTPEnumeration,
      SSL_VULNERABILITY_SCAN: Containers.SSLTest,
      SSH_BRUTEFORCE: Containers.BruteforceTest,
      OS_DETECTION: Containers.OSDetection,
      TELNET_BRUTEFORCE: Containers.BruteforceTest
    };

    // If the test type isn't supported, log it
    if (!testdetailsContainerMap[type]) {
      logToConsole('Error', `Test type ${type} isn't supported`);
      return;
    }

    const TestDetailsContainer = testdetailsContainerMap[type];

    return (<TestDetailsContainer {...test} />);
  }

  /**
   * render test sections
   * @param {*} payload
   */
  renderTestSections(payload) {
    if (!payload || !payload.tests) return;

    const tests = payload.tests || [];
    return tests.map(test => {
      const { label, state = {}, types, s3DownloadURL } = test;
      const sections = Object.keys(types).map(type => {
        const testDetails = types[type];
        const status = testDetails.state.status;
        const isTestRunning = status && status === 'RUNNING';
        return (
          <AWSUI.ExpandableSection
            key={type}
            header={
              this.getHeader(
                testDetails.label || type,
                status,
                testDetails.s3DownloadURL
              )
            }
            variant="container"
            onChange={this.handleChange(type)}
            expanded={!!isTestRunning || !!this.state[type]}
          >
            {this.renderTestDetails(testDetails)}
          </AWSUI.ExpandableSection>
        )
      });
      return (
        <div className="awsui-util-container" key={label}>
          <div className="awsui-util-container-header">
            <h2>{this.getHeader(label, state.status, s3DownloadURL)}</h2>
          </div>
          <div>{sections}</div>
        </div>
      );
    });
  }

  render() {
    const { payload } = this.props.testStats || {};

    if (!payload) return this.renderLoadingMessage();

    return (
      <div>
        <div className='awsui-util-mt-xl awsui-util-mb-xl awsui-util-ml-xl awsui-util-mr-xl'>
          <div>
            {this.renderTestSections(payload)}
          </div>
        </div>
      </div>
    );
  }
}

export default PortScanner;
