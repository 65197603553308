export const MOBILE_FUNCTIONAL_EXPANDABLE_COLUMNS = [{
  id: 'testCases',
  cell: item => item.testCases,
  minWidth: '100px',
  allowLineWrap: false
}];

export const MOBILE_FUNCTIONAL_TEST_METRICS_COLUMNS = [{
  id: 'functionalTestCaseName',
  header: () => 'Test Case Name',
  cell: item => item.functionalTestCaseName,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'deviceValidationStatus',
  header: () => 'Device Validation',
  cell: item => item.deviceValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'cloudValidationStatus',
  header: () => 'Alexa Cloud Validation',
  cell: item => item.cloudValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'refMicValidationStatus',
  header: () => 'Audio Recording Validation',
  cell: item => item.refMicValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'overallValidationStatus',
  header: () => 'Overall Result',
  cell: item => item.overallValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'comments',
  header: () => 'Comments',
  cell: item => item.comments,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'utteranceDetails',
  header: () => '',
  cell: item => item.utteranceDetails,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];

export const MOBILE_FUNCTIONAL_UTTERANCES_COLUMNS = [{
  id: 'questionPlayed',
  header: () => 'Device Command / Question Played',
  cell: item => item.questionPlayed,
  minWidth: '300px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'dutHeard',
  header: () => 'DUT Heard',
  cell: item => item.dutHeard,
  minWidth: '300px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'dutResponded',
  header: () => 'Response',
  cell: item => item.dutResponded,
  minWidth: '300px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'validationType',
  header: () => 'Validation Type',
  cell: item => item.validationType,
  minWidth: '200px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'comments',
  header: () => 'Comments',
  cell: item => item.comments,
  minWidth: '200px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'backToTestMetrics',
  header: () => '',
  cell: item => item.backToTestMetrics,
  minWidth: '200px',
  allowLineWrap: true,
  resizableColumns: true
}];
