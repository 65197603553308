import React from 'react';
import TestTypeScenarioOptions from '../../ScenarioCommon/TestTypeScenarioOptions';
import AppConstants from "../../../../_Constants/AppConstants";
import MobileFunctionalTestCasesSelection from "./MobileFunctionalTestCasesSelection";
import TestCategory from "./TestCategory";
import LockType from "./LockType";

class MobileFunctional extends React.Component {

  render() {
    const styles = {
      color: '#eb5f07'
    }

    const {
      selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack
    } = this.props.params;
    return (
      <div>
        <div className='awsui-util-mt-m'>
          <TestCategory
            allowedTypes={[0, 1, 2]}
            selectedScenario={selectedScenario}
            selectedScenarioError={selectedScenarioError}
            selectedScenarioCallBack={selectedScenarioCallBack}
            scenarioErrorCallBack={scenarioErrorCallBack}
          >
          </TestCategory>
        </div>
        {/* Render prerequisite message based on test category */}
        <div className='awsui-util-mr-s' style={styles}>
          {(selectedScenario.testCategory === AppConstants.ALEXA_HANDS_FREE_VERIFICATION) &&
            <p>{AppConstants.ALEXA_HF_VERIFICATION_PREREQ}</p>}
          {(selectedScenario.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) &&
            <p>{AppConstants.DEVICE_LOCK_HF_VERIFICATION_PREREQ}</p>}
          {(selectedScenario.testCategory === AppConstants.APP_VERIFICATION) &&
            <p>{AppConstants.APP_VERIFICATION_PREREQ}</p>}
        </div>
        {/* Render scenario options based on test category */}
        <div className='awsui-util-mt-m'>
          {(selectedScenario.testCategory === AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION) &&
            <LockType
              allowedTypes={[0, 1]}
              selectedScenario={selectedScenario}
              selectedScenarioError={selectedScenarioError}
              selectedScenarioCallBack={selectedScenarioCallBack}
              scenarioErrorCallBack={scenarioErrorCallBack}
            />}
        </div>
        {(selectedScenario.testCategory === AppConstants.ALEXA_HANDS_FREE_VERIFICATION ||
            selectedScenario.testCategory === AppConstants.APP_VERIFICATION) &&
          <TestTypeScenarioOptions
            allowedTypes={[0, 3]}
            showDelayConfig={AppConstants.CUSTOMIZED === selectedScenario.testType}
            showAdminValidation={AppConstants.CUSTOMIZED === selectedScenario.testType}
            selectedScenario={selectedScenario}
            selectedScenarioError={selectedScenarioError}
            selectedScenarioCallBack={selectedScenarioCallBack}
            scenarioErrorCallBack={scenarioErrorCallBack}
          >
          </TestTypeScenarioOptions>}
        <MobileFunctionalTestCasesSelection params={{
          selectedScenario: selectedScenario,
          selectedScenarioCallBack: selectedScenarioCallBack,
          selectedScenarioError: selectedScenarioError,
          scenarioErrorCallBack: scenarioErrorCallBack,
        }} />
      </div>
    );
  }
}

export default MobileFunctional;
