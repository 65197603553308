export const FAR_CUSTOM_DUT_TABLE_COLUMNS = [{
    id: 'customerId',
    header: () => 'Customer ID',
    cell: item => item.customerId,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'dsn',
    header: () => 'DSN',
    cell: item => item.dsn,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'deviceName',
    header: () => 'Device Name',
    cell: item => item.deviceName,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'amazonId',
    header: () => 'Amazon ID',
    cell: item => item.amazonId,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }, {
    id: 'buildInfo',
    header: () => 'Firmware',
    cell: item => item.buildInfo,
    minWidth: '100px',
    allowLineWrap: true,
    resizableColumns: true
  }];