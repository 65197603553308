export const FUNCTIONAL_EXPANDABLE_COLUMNS = [{
  id: 'functionalCategory',
  cell: item => item.functionalCategory,
  minWidth: '100px',
  allowLineWrap: false
}];
 
export const FUNCTIONAL_TEST_METRICS_COLUMNS = [{
  id: 'functionalTestCaseName',
  header: () => 'Test Case Name',
  cell: item => item.functionalTestCaseName,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'cloudValidationStatus',
  header: () => 'Alexa Cloud Validation',
  cell: item => item.cloudValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'refMicValidationStatus',
  header: () => 'Audio Recording Validation',
  cell: item => item.refMicValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'overallValidationStatus',
  header: () => 'Overall Result',
  cell: item => item.overallValidationStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'comments',
  header: () => 'Comments',
  cell: item => item.comments,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'utteranceDetails',
  header: () => '',
  cell: item => item.utteranceDetails,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];