
import { getWhitelistedCustomerIds } from './controller';
import { fetchLabs } from '../RasPiTable/controller';
import { DeviceOnlineStatusDisplay, DeviceOnlineStatus } from '../../_Constants/DeviceConstants'
import { isDutInList } from './Util';
import { getDUTNameDisplay } from './MappingGeneratorUtil';
import { logToConsole } from '../../Util';
import AppConstants from '../../_Constants/AppConstants';

/**
 * TODO: need to rename this function
 * Need to remove out maybe to data retrieve helper
 *
 * Method to retrieve labs
 */
export function setLabs() {
  let labsIn = [];
  let companyId = AppConstants.EMPTY;
  //TODO: Disabling Session storage with respect to multiple account usage.
  //const labsPiscachedHits = sessionStorage.getItem(AppConstants.cache.LABSPIS);
  const labsPiscachedHits = sessionStorage.removeItem(AppConstants.cache.LABSPIS);
  if (labsPiscachedHits) {
    logToConsole('Retrieved from Cache: ', AppConstants.cache.LABSPIS);
    let labsAndThings = JSON.parse(labsPiscachedHits);
    Object.keys(labsAndThings).forEach(labId => {
      labsIn.push({
        id: labId,
        label: labsAndThings[labId].lab.name
      });
      // Fetch company ID to add it to mapping object
      if (companyId === AppConstants.EMPTY) {
        companyId = labsAndThings[labId].lab.companyId;
      }
    });
    this.setState({
      testParams: { ...this.state.testParams, companyId: companyId }
    });
    return Promise.resolve({ labId: labsIn });
  } else {
    return Promise.resolve(fetchLabs().then(labs => {
      if (!labs.hasOwnProperty('error')) {
        labs.forEach(lab => {
          labsIn.push({
            id: lab.id,
            label: lab.name
          });
          // Fetch company ID to add it to mapping object
          if (companyId === AppConstants.EMPTY) {
            companyId = lab.companyId;
          }
        });
        this.setState({
          testParams: { ...this.state.testParams, companyId: companyId }
        });
        return { labId: labsIn };
      } else {
        return { loadErr: labs.error };
      }
    }));
  }
}

/**
 * TODO: need to rename this function
 * Need to remove out maybe to data retrieve helper
 * Method to retrieve whitelisted customer IDs from controller
 */
export function setCustomerIds() {
  let customerIdsIn = [];
  return Promise.resolve(getWhitelistedCustomerIds().then(cidResponse => {
    if (!cidResponse.hasOwnProperty('error')) {
      cidResponse.cid.forEach(customerID => {
        let cidValue = Object.keys(customerID)[0];
        customerIdsIn.push({
          id: cidValue,
          label: customerID[cidValue],
          description: cidValue
        });
      });
      return { customerId: customerIdsIn };
    } else {
      return { loadErr: cidResponse.error };
    }
  }));
}

/**
 * Generates list of DUTs with Online / Offline Status based on DUT response received from controller
 * @param dutResponse DUT response from controller
 * @returns List of DUTs to be displayed in dropdown
 */
export function generateDUTListWithOnlineOfflineStatus(dutResponse) {
  let dutListTemp = [];
  let dutListPassed = [];
  let dutonlineList = {};
  dutonlineList.label = DeviceOnlineStatusDisplay.ONLINE;
  dutonlineList.options = [];
  let dutofflineList = {};
  dutofflineList.label = DeviceOnlineStatusDisplay.OFFLINE;
  dutofflineList.options = [];
  let dutunknownList = {};
  dutunknownList.label = DeviceOnlineStatusDisplay.UNKNOWN;
  dutunknownList.options = [];

  if (!dutResponse.hasOwnProperty('error')) {
    dutResponse.duts.forEach(dut => {
      if (!isDutInList(dutListTemp, dut.dsn)) {
        if (dut.onlineStatus === DeviceOnlineStatus.ONLINE) {
          pushOptionToArray(dutonlineList.options, dut)
        } else if (dut.onlineStatus === DeviceOnlineStatus.OFFLINE) {
          pushOptionToArray(dutofflineList.options, dut)
        } else if (dut.onlineStatus === DeviceOnlineStatus.UNKNOWN) {
          pushOptionToArray(dutunknownList.options, dut)
        } else {
          pushOptionToArray(dutunknownList.options, dut)
        }
      }
    });
    if (dutonlineList && dutonlineList.options.length) {
      dutListTemp.push(dutonlineList);
    }
    if (dutunknownList && dutunknownList.options.length) {
      dutListTemp.push(dutunknownList);
    }
    if (dutofflineList.options && dutofflineList.options.length) {
      dutListTemp.push(dutofflineList);
    }
    /**
     * dutListPassed is of Format A specified in
     * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/
     * blobs/bcbeeef7e4ed4c3abde1574b2c3beb342df5b9d8/--/src/Container/NewRun/Util.js#L222
     * ENABLE_DSNONLINESTATUS removed by default true
     */
    dutListPassed = dutListTemp;
  } else {
    logToConsole('Error while fetching DUTs: ' + JSON.stringify(dutResponse.error));
  }

  return dutListPassed;
}

/*
   * Push dut information into options for display in new run page
   */
function pushOptionToArray(arrayToPush, dut) {
  arrayToPush.push({
    id: dut.dsn,
    label: getDUTNameDisplay(dut.name),
    type: dut.typeId,
    name: dut.name,
    deviceUnderTestId: dut.id,
    namespace: dut.namespace,
    tags: [dut.dsn]
  });
}
