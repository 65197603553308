/* eslint-disable no-prototype-builtins */
import {
   getDeviceNameForDut
} from './Util';
import { fetchLabs } from '../RasPiTable/controller';
import { fetchRasPis } from '../RasPiTable/controller';
import AppConstants from '_Constants/AppConstants';
import MusicConstants from '_Constants/MusicConstants';
import Utilities from './Utilities';
import { buildUserPreferenceObject } from '../../Util/index';
import { get_locations_for_test_options } from '../../Util/AQTTestOptionsParser';
import CustomOptionsConstants from '../../_Constants/CustomOptionsConstants';
import { sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';

/**
 * TODO: update comments
 * TODO: need to check error handling
 * Generates mapping object containing companyId, deviceMapping,
 * actorMapping and noisMapping before submitting the test for run
 * @param {*} selectedTestGroupInfo
 * @param {*} selectedScenario
 * @param {*} otherParams     see state params in NewRun index page
 * @param {*} updateOtherParamsCallBack
 */
export async function generateMapping(selectedTestGroupInfo, selectedScenario, otherParams, updateOtherParamsCallBack) {
  const identifier = 'TestRunHelpers, generateMapping';
  let rasPiList = [];
  let actorMapping = {};
  let noiseMapping = {};
  let userPreferenceMapping = {};
  let deviceMapping = otherParams.deviceMapping;
  let primaryDutMap = {
    'dsn': selectedTestGroupInfo.dsn,
    'namespace': selectedTestGroupInfo.namespace,
    'deviceType': selectedTestGroupInfo.deviceTypeId,
    'deviceName': getDeviceNameForDut(selectedTestGroupInfo.dutList, selectedTestGroupInfo.dsn),
    'customerId': selectedTestGroupInfo.customerId,
    'buildInfo': selectedTestGroupInfo.buildInfo,
    'amazonId': selectedTestGroupInfo.deviceTypeId,
    'deviceConfig': selectedTestGroupInfo.deviceConfig
  };
  deviceMapping['DUT'] = primaryDutMap;
  // TODO: reduce fetchLabs
  // may not need to fetch the labs again, since setLabs already fetched the labs
  const labs = await fetchLabs();
  let currentLab;
  // eslint-disable-next-line no-prototype-builtins
  if (!labs.hasOwnProperty('error')) {
    for (let index = 0; index < labs.length; index++) {
      if (labs[index].id === selectedTestGroupInfo.labId) {
        currentLab = labs[index];
        break;
      }
    }
  }
  const rasPis = await fetchRasPis(selectedTestGroupInfo.labId);
  if (!rasPis.hasOwnProperty('error')) {
    rasPis.forEach(rasPi => {
      rasPiList.push(rasPi.thingName);
    });
    rasPiList = rasPiList.sort();
    let actorMappingKeys = AppConstants.actorMappingKeys;
    let spl = {};
    for (let index = 0; index < rasPiList.length; index++) {
      // For custom scenarios, actor mapping is not used
      if (selectedScenario.testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID) continue;

      // For Functional test suite, we only want 0.9 m 90 deg location in the actor mapping
      if (selectedScenario.testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID
        && AppConstants.functionalSkipLocations.includes(index)) {
        continue;
      }
      // For Mobile test suite, we want to exclude the Far-Field locations from the actor mapping
      if ((selectedScenario.testSuite === AppConstants.MOBILE_SUITE_ID
          && AppConstants.mobileSkipLocations.includes(index)) ||
        (selectedScenario.scenarioType === AppConstants.MOBILE_FUNCTIONAL
          && AppConstants.mobileFunctionalSkipLocations.includes(index))) {
        continue;
      }
      // For Music test suite, we only want 0.9 m 90 deg location in the actor mapping
      if (selectedScenario.testSuite === MusicConstants.MUSIC_SCENARIO_ID
        && AppConstants.musicSkipLocations.includes(index)) {
        continue;
      }
      // For AUTO_LOCAL_SEARCH test suite, we only want 0.9 m 90 deg location in the actor mapping
      if (selectedScenario.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID
        && AppConstants.autoLocalSearchSkipLocations.includes(index)) {
        continue;
      }
      // For Stability test suite, we only want 0.9 m 90 deg location in the actor mapping
      if (selectedScenario.testSuite === AppConstants.STABILITY_SCENARIO_ID
        && AppConstants.stabilitySkipLocations.includes(index)) {
        continue;
      }
      // For UPL test suite, we only want 0.9 m 90 deg location in the actor mapping
      if (selectedScenario.testSuite === AppConstants.UPL_SCENARIO_ID
        && AppConstants.uplSkipLocations.includes(index)) {
        continue;
      }
      // For Qual test suite, we use three locations
      if (selectedScenario.testSuite === AppConstants.QUAL_SCENARIO_ID) {
        if (AppConstants.qualSkipLocations.includes(index)) continue;

        spl = Utilities.getSPLSettings(selectedScenario);

        /**
         * Update speech and noise actors in mapping for Qual, since they vary
         * based on the scenario and not all of them will be used all the time.
         * Custom tests can have 2.7m 30 deg & 90 deg locations for speech and
         * standard will only have 2.7m 90 deg
         */
        const requiredActors = [...Object.keys(spl.speech), ...Object.keys(spl.noise)];
        if (!requiredActors.includes(actorMappingKeys[index])) continue;
      }
      // For Automotive Test, We send only 'Automotive Companion' as both actor and noise
      if (selectedScenario.testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID) {
        if (currentLab && currentLab.preference) {
            var pref = JSON.parse(currentLab.preference)
            if (pref
                && pref.actor_mapping_preference
                && rasPis[index].hasOwnProperty("id")
                && pref.actor_mapping_preference[rasPis[index].id]
                && pref.actor_mapping_preference[rasPis[index].id].location) {
                  if (pref.actor_mapping_preference[rasPis[index].id].location.includes(AppConstants.AUTOMOTIVE_LOCATION)) {
                    actorMapping[AppConstants.SCENARIO_UBUNTU_LOCATION.toLowerCase()] = rasPis[index].thingName;
                    noiseMapping[AppConstants.SCENARIO_UBUNTU_LOCATION.toLowerCase()] = rasPis[index].thingName;
                  }
            }
        }
        continue;
      }

      actorMapping[actorMappingKeys[index]] = rasPiList[index];
    }

    if (selectedScenario.scenarioType === AppConstants.WAKEWORD) {
      // for wakeword test the raspi, lab mapping is fixed
      var key = AppConstants.actorMappingKeys[3]
      actorMapping = { [key] :rasPiList[3]}
    }

    userPreferenceMapping = buildUserPreferenceObject(currentLab, rasPis);
    let noiseLocation = AppConstants.NOISE_LOCATION;
    // Noise mapping needs to be empty for Functional, Music, AUTO_LOCAL_Search, Stability, UPL, Automotive and Acav scenario
    if (
      selectedScenario.testSuite !== AppConstants.FUNCTIONAL_SCENARIO_ID &&
      selectedScenario.testSuite !== MusicConstants.MUSIC_SCENARIO_ID &&
      selectedScenario.testSuite !== AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID &&
      selectedScenario.testSuite !== AppConstants.STABILITY_SCENARIO_ID &&
      selectedScenario.testSuite !== AppConstants.UPL_SCENARIO_ID &&
      selectedScenario.testSuite !== AppConstants.TEST_SUITES.CUSTOM_TEST.ID &&
      selectedScenario.testSuite !== AppConstants.AUTOMOTIVE_SCENARIO_ID &&
      selectedScenario.testSuite !== AppConstants.ACAV_SCENARIO_ID &&
      selectedScenario.scenarioType !== AppConstants.WAKEWORD
    ) {

      // Not all QUAL scenarios need noise actors
      if (selectedScenario.testSuite === AppConstants.QUAL_SCENARIO_ID) {
        if (Object.keys(spl.noise || {}).length > 0) {
          noiseMapping[noiseLocation] = rasPiList[0];
        }
      } else {
        noiseMapping[noiseLocation] = rasPiList[0];
      }
    }
    updateActorMappingThingsBasedOnUserPreference(actorMapping, noiseMapping, userPreferenceMapping, rasPis);
    if (selectedScenario.scenarioType !== AppConstants.WAKEWORD) { // for wakeword we use single defined pi location
      if (selectedScenario.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID ||
        selectedScenario.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) {
        let actorsRequiredForCurrentTest = new Set();
        if (selectedScenario.testType === AppConstants.CUSTOMIZED) {
          delete noiseMapping[noiseLocation];
          let schemeDefList = selectedScenario.testOptions.customOptions[CustomOptionsConstants.SCHEME_DEF_KEY]
          if (schemeDefList && schemeDefList.length > 0) {
            for (let j = 0; j < schemeDefList.length; j++) {
              let currentActorMap = schemeDefList[j];
              let currentActor = Object.keys(currentActorMap)[0];
              actorsRequiredForCurrentTest.add(currentActor);
              let currentNoiseList = schemeDefList[j][currentActor];
              for (let noiseIndex = 0; noiseIndex < currentNoiseList.length; noiseIndex++) {
                if (Object.keys(currentNoiseList[noiseIndex])[0] !== AppConstants.NOISE_TYPE_DEVICE_PLAYBACK
                  && Object.keys(currentNoiseList[noiseIndex])[0] !== AppConstants.NOISE_TYPE_SILENCE) {
                  actorsRequiredForCurrentTest.add(AppConstants.NOISE_LOCATION);
                  noiseMapping[noiseLocation] = actorMapping[noiseLocation];
                }
              }
            }
          }
        } else {
          actorsRequiredForCurrentTest = get_locations_for_test_options(selectedScenario.testSuite,
            selectedScenario.scenarioType, selectedScenario.testType, undefined, selectedScenario.marketPlace);
        }
        if (actorsRequiredForCurrentTest.size > 0) {
          Object.keys(actorMapping).forEach(actor => {
            if (!actorsRequiredForCurrentTest.has(actor)) {
              delete actorMapping[actor];
            }
          })
        }
      } else if (selectedScenario.testSuite === AppConstants.TEST_SUITES.CUSTOM_TEST.ID
        && selectedScenario.customTestOptions && selectedScenario.customTestOptions.actorLabMapping) {
        const {noiseLocationPi} = selectedScenario.customTestOptions.config;
        Object.keys(selectedScenario.customTestOptions.actorLabMapping).map(location => {
          if (noiseLocationPi && location == noiseLocationPi) {
            noiseMapping[location] = selectedScenario.customTestOptions.actorLabMapping[location];
          } else {
            actorMapping[location] = selectedScenario.customTestOptions.actorLabMapping[location];
          }
        })
      }
    }
  }

  // Update the device,actor and noise mapping for FAR+ to match the input with OAK
  if (selectedScenario.scenarioType === AppConstants.FAR_PLUS) {
    deviceMapping["dut1"] = primaryDutMap;
    delete deviceMapping['DUT'];
    Object.keys(actorMapping).forEach(actor => {
      actorMapping["pi1"] = actorMapping[actor];
      delete actorMapping[actor];
    })
    Object.keys(noiseMapping).forEach(actor => {
      noiseMapping["pi1"] = noiseMapping[actor];
      delete noiseMapping[actor];
    })
  } else {
    /**
     * If user selected FAR+(OAK type) but later decided to go back from the
     * preview page and selected any other test, we would need to remove the
     * dut1 mapping added for FAR+.
     */
    if (deviceMapping.hasOwnProperty('dut1')) {
      delete deviceMapping['dut1'];
    }
  }
  
  let mapping = {
    'companyId': otherParams.companyId,
    //'deviceMapping': this.state.testParams.deviceMapping, //TODO: deal with it later, something to do with FAR custom
    'actorMapping': actorMapping,
    'noiseMapping': noiseMapping
  };

  let updatedOtherParams = otherParams;
  updatedOtherParams.deviceMapping = deviceMapping;
  updatedOtherParams.mapping = mapping;
  updatedOtherParams.userPreferenceMapping = getUserPreferenceMappingForReport(userPreferenceMapping, actorMapping, selectedScenario);
  sendRawInfoToNewRunWithIdentifier(identifier, updatedOtherParams, updateOtherParamsCallBack);
}

/**
   * Input default location => thingName.
   * Updates actor mapping{@param actorMapping} location to iotThing based on user preference mapping.
   * @param actorMapping Map of required locations for test with their Iot thing name
   * @param noiseMapping Map of required noise location for test
   * @param userPreference User defined mapping of location -> raspi name.
   * @param rasPisOfLab All raspis present in the lab used for test.
   */
function updateActorMappingThingsBasedOnUserPreference(actorMapping, noiseMapping, userPreference, rasPisOfLab) {
  let mapRaspiIdToThing = {};
  rasPisOfLab.forEach(raspi => {
    mapRaspiIdToThing[raspi.id] = raspi.thingName;
  })

  Object.keys(userPreference.actor_mapping_preference).forEach(raspiId => {
    let mappedLocation = userPreference.actor_mapping_preference[raspiId].location;
    if (actorMapping.hasOwnProperty(mappedLocation)) {
      actorMapping[mappedLocation] = mapRaspiIdToThing[raspiId];
    }
    if (noiseMapping.hasOwnProperty(mappedLocation)) {
      noiseMapping[mappedLocation] = mapRaspiIdToThing[raspiId];
    }
  })
}

/**
  * From user preference raspi mapping builds a map to be consumed by report lambda.
  * @param userPreference user preference mapping of raspi name to location.
  * @param actorMapping required actors for current test.
  */
function getUserPreferenceMappingForReport(userPreference, actorMapping, selectedScenario) {
  let userPreferenceMapForReport = {};
  if (userPreference && userPreference.hasOwnProperty("actor_mapping_preference")) {
    Object.keys(userPreference.actor_mapping_preference).forEach(raspiId => {
      let location = userPreference.actor_mapping_preference[raspiId].location;
      if (actorMapping.hasOwnProperty(location)) {
        let mappedLocation = get_transformed_location(location, selectedScenario);
        userPreferenceMapForReport[mappedLocation] = {
          "name": userPreference.actor_mapping_preference[raspiId].name,
          "id": raspiId
        }
      }
    })
  }
  return userPreferenceMapForReport;
}

/**
 * This function is to update the userPreference Mapping from standard locations [* 30 deg, * 90 deg, 1.m 135 deg]
 *  to testSuite/Scenario based custom locations
 *  Eg: Acoustic 0.9m 90 deg to CloseTalk HATS and Noise 1.m 135 deg to Close Talk 2.0m 90 deg
 * @type {function(*, *): undefined}
 * @param location
 * @param selectedScenario
 * @return {undefined}
 */
function get_transformed_location(location, selectedScenario) {
  if (selectedScenario.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) {
    if (AppConstants.ACOUSTIC_LOCATION_TO_CLOSE_TALK_MAP.hasOwnProperty(location)) {
      return AppConstants.ACOUSTIC_LOCATION_TO_CLOSE_TALK_MAP[location];
    }
  }
  return location;
}
