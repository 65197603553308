import React from 'react';
import axios from 'axios';
import Main from './Container/Main';
import Login from './Container/Login';
import ForgotPassword from './Container/Login/ForgotPassword';
import ResetPassword from './Container/Login/ResetPassword';
import ChangeInitialPassword from './Container/Login/ChangeInitialPassword';
import Amplify, { Auth } from 'aws-amplify';
import { aqtStore } from './Components/State/Store';
import { setSession, setEnvironment } from './Components/State/Actions';
import AppConstants from './_Constants/AppConstants';
import { connect } from 'react-redux'
import { isEmpty } from 'lodash';

import * as authActions from './redux/actions/auth';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

class App extends React.Component {

  state = {
    isSignedIn: false,
    sessionObj: {},
    isForgotPassword: false,
    isResetPassword: false,
    isChangeInitialPassword: false,
    username: AppConstants.EMPTY,
    password: AppConstants.EMPTY,
    user: {}
  };

  componentWillMount() {
    this.unsubscribe = aqtStore.subscribe(this.aqtStateHandler);
    return axios.get("/config.json").then(config => {
        if (config.data.loadLocal) {
          console.log('Loading local config');
          config.data = {
            aws_cognito_identity_pool_id: "us-west-2:c98e9171-4b3e-4385-92cc-3899a8886511",
            aws_cognito_region: "us-west-2",
            aws_sign_in_enabled: "enable",
            aws_user_pools: "enable",
            aws_user_pools_id: "us-west-2_nlUkid4Hr",
            aws_user_pools_mfa_type: "OFF",
            aws_user_pools_web_client_id: "4cg2vllep63547j84q57eaqkk7",
            controllerEndpoint: "https://api.us-west-2.alpha.amazon-aqt.com"
          }
        }
        // TODO: Remove this and use single store
        aqtStore.dispatch(setEnvironment(config.data));
        this.props.setEnvironment(config.data);
        Amplify.configure(config.data);
        return Auth.currentSession()
            .then( session => {
              // TODO: Remove this and use single store
              aqtStore.dispatch(setSession(session));
              this.props.setSession(session);
              this.setState({ isSignedIn: true, sessionObj: session });
            })
            .catch( err => {
              this.setState({ isSignedIn: false, sessionObj: {} });
            });
        }
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  aqtStateHandler = () => {
    // TODO: Temporary sign out till this file mess is refactored
    if (isEmpty(aqtStore.getState().session)) {
      this.handleSignOut();
    }
  }

  handleSignIn = (session) => {
    let sessionJsonString = JSON.stringify(session);
    if (sessionJsonString.includes(NEW_PASSWORD_REQUIRED)) {
      this.setState({ isSignedIn: false, isChangeInitialPassword: true, user : session });
    } else {
      window.location.reload(true);
    }
    aqtStore.dispatch(setSession(session));
    this.props.setSession(session);
    this.setState({ isSignedIn: true, sessionObj: session });
  }

  handleSignOut = () => {
    // TODO: Handle this in re-factor
    this.setState({ isSignedIn: false, sessionObj: {} });
  }

  handleForgotPassword = () => {
    this.setState({ isForgotPassword: true });
  }

  handleBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false });
  }

  handleResetPasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleResetPasswordSubmit = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleSendCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleResetPasswordHaseCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleChangePasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false, isChangeInitialPassword: false });
  }

  handleChangePasswordSubmit = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false, isChangeInitialPassword: false, sessionObj: {} });
  }

  /**
   * Render pages related to authentication
   */
  renderAuth = () => {
    const { isChangeInitialPassword, isResetPassword, isForgotPassword } = this.state;
    if (isForgotPassword && !isResetPassword && !isChangeInitialPassword) {
      return (
        <ForgotPassword
          backtologin = { this.handleBackToLogin }
          sendcode = { this.handleSendCode }
          resetpassword={ this.handleResetPasswordHaseCode }
        />
      );
    } else if (isResetPassword && !isChangeInitialPassword) {
      return (
        <ResetPassword
          params={{ username: this.state.username }}
          backtologin = { this.handleResetPasswordBackToLogin }
          submit = {this.handleResetPasswordSubmit}
        />
      );
    } else if (isChangeInitialPassword) {
      return (
        <ChangeInitialPassword
          params={{ user: this.state.user }}
          backtologin = { this.handleChangePasswordBackToLogin }
          submit = { this.handleChangePasswordBackToLogin }
        />
      )
    }

    return (
      <Login
        authenticate = {this.handleSignIn}
        forgotpassword = {this.handleForgotPassword}
      />
    );
  }

  render() {
    const { isSignedIn, isForgotPassword, isResetPassword, isChangeInitialPassword } = this.state;
    if (!isSignedIn || isForgotPassword || isResetPassword || isChangeInitialPassword) {
      return this.renderAuth();
    }

    return <Main />;
  }
}

export default connect(s => s, {
  setEnvironment: authActions.setEnvironment,
  setSession: authActions.setSession,
})(App);
