/**
 * Component to display test metrics
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import { getTimeForDisplay, getTime, isTestCompleted, renderLabelElement, getTextOrLoading,
  isLatencyScenario, getUtteranceCountPerIterationWWDD, getLoadingMessage, logToConsole } from '../../Util';
import ld from 'lodash'
import {AutomotiveScenarioTypes} from "../../_Constants/AutomotiveConstants";
/**
 * Component which displays following test metrics:
 * 1. Utterance Count (Played / Total in Test)
 * 2. FRR (Missed/ Spoken Wake Words)
 * 3. RAR (Correct Responses / Requests)
 * 4. Location & Noise Played
 * 5. Start Time
 * 6. Elapsed Time
 * 7. ETA (Estimated Time Remaining)
 */
class TestMetrics extends React.Component {

  componentDidMount() {
  }

  componentWillUnmount() {
    if (AppConstants.FAR_TEST_TYPES.includes(this.props.params.testType)) {
      clearInterval(this.interval);
    }
  }

  /**
   * Gets current location, noise played for specific scenario type
   * @param scenarioType Type of scenario
   * @return Current location, noise played
   */
  getLocationNoiseForDisplay = (scenarioType) => {
    if (AutomotiveScenarioTypes.includes(scenarioType)) {
      const {noiseFeeds} = this.props.params;
      return ld.get(noiseFeeds, 'payload.currentScenario', ' - ');
    } else if (AppConstants.SCENARIO_WITH_TEST_TYPES.includes(scenarioType)
          || scenarioType === AppConstants.CUSTOM_STANDARD) {
      return this.props.params.testStats.locationNoise;
    }
    return AppConstants.NOT_APPLICABLE;
  }

  /**
   * Gets elapsed time element to display based on test case is FAR or non-FAR
   * @return Elapsed time element
   */
  getElapsedTimeDisplay = () => {
    if (AppConstants.FAR_TEST_TYPES.includes(this.props.params.testType)) {
      let elapsedTimeId = 'elapsedTime_' + this.props.params.labJobIdFar;
      return (
          <div className='awsui-util-label' id={ elapsedTimeId }></div>
        );
    } else {
      return (
          <div className='awsui-util-label'>{ this.props.params.testStats.elapsedTime }</div>
        );
    }
  }

  /**
   * Gets ETA element to display based on test case is FAR or non-FAR
   * @return ETA element
   */
  getEtaDisplay = () => {
    if (AppConstants.FAR_TEST_TYPES.includes(this.props.params.testType)) {
      let etaId = 'eta_' + this.props.params.labJobIdFar;
      return (
          <div className='awsui-util-label' id={ etaId }></div>
        );
    } else {
      return (
          <div className='awsui-util-label'>{ this.props.params.testStats.timeRemaining }</div>
        );
    }
  }

  /**
   * Sets elapsed time value for FAR test
   * @param elapsedTimeMillis Elapsed time in milliseconds
   */
  setElapsedTimeFar = (elapsedTimeMillis) => {
    let elapsedTimeId = 'elapsedTime_' + this.props.params.labJobIdFar;
    let elapsedTime = getTime(elapsedTimeMillis);
    let elapsedTimeElement = document.getElementById(elapsedTimeId);
    if (elapsedTimeElement) {
      elapsedTimeElement.innerHTML = elapsedTime;
    }
  }

  /**
   * Sets ETA value for FAR test
   * @param elapsedTimeMillis Elapsed time in milliseconds
   * @param testStatus Status of the test
   */
  setEtaFar = (elapsedTimeMillis, testStatus) => {
    let etaId = 'eta_' + this.props.params.labJobIdFar;
    let timeRemaining = AppConstants.EMPTY;
    if (isTestCompleted(testStatus)) {
      timeRemaining = AppConstants.FINISHED;
    } else {
      timeRemaining = AppConstants.ONE_DAY_DURATION - elapsedTimeMillis > 0 ?
        getTime(AppConstants.ONE_DAY_DURATION - elapsedTimeMillis) : AppConstants.FINISHED;
    }
    let etaElement = document.getElementById(etaId);
    if (etaElement) {
      etaElement.innerHTML = timeRemaining;
    }
  }

  /**
   * Renders test metrics feeds for Acoustic
   */
  renderAcousticTestMetricsFeeds = () => {
    logToConsole('TestDebug - Test stats in TestMetrics = ' + JSON.stringify(this.props.params.testStats));
    const isAutomotiveScenario = AutomotiveScenarioTypes.includes(this.props.params.scenarioType);
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let scenarioType = this.props.params.scenarioType;
    let startTime, frr, rar, count, elapsed, eta, location, iar, currentTrainedVoice, latencyValue,
      utteranceCountForIteration;
    let etaDescription = 'ETA ';
    let etaDescriptionAdditional = '(Estimated Time Remaining)';
    if (scenarioType === AppConstants.LATENCY) {
      etaDescription = 'Max. ETA ';
      etaDescriptionAdditional = '(Maximum Estimated Time Remaining)';
    }
    if (isDataAvailable) {
      // TODO: Do we need isFARTest? since we don't show metrics tab for those scenarios
      const isFARTest = AppConstants.FAR_TEST_TYPES.includes(this.props.params.testType);
      startTime = getTimeForDisplay(this.props.params.testStats.startTime);
      frr = isFARTest ? AppConstants.NOT_APPLICABLE : this.props.params.testStats.frr;
      rar = isFARTest ? AppConstants.NOT_APPLICABLE : this.props.params.testStats.rar;
      iar = this.props.params.testStats.iar;
      count = isFARTest ? AppConstants.NOT_APPLICABLE : this.props.params.testStats.count;
      elapsed = this.getElapsedTimeDisplay();
      eta = this.getEtaDisplay();
      location = this.getLocationNoiseForDisplay(scenarioType);
      currentTrainedVoice = this.props.params.testStats.currentTrainedVoice;
      latencyValue = this.props.params.testStats.latencyValue;
      if (latencyValue) {
        latencyValue += ' secs';
      }
      let currentUtteranceCount = this.props.params.testStats.currentUtteranceCount;
      if (currentUtteranceCount) {
        utteranceCountForIteration = getUtteranceCountPerIterationWWDD(currentUtteranceCount);
      }
    }

    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
           { renderLabelElement('Utterance Count ','(Played / Total in Test)') }
           { getTextOrLoading(count) }
           { renderLabelElement('Start Time', AppConstants.EMPTY) }
           { getTextOrLoading(startTime) }
           { renderLabelElement('FRR ', '(Missed / Spoken Wake Words)') }
           { getTextOrLoading(frr) }
           { renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
           { getTextOrLoading(elapsed) }
           { renderLabelElement('RAR ', '(Correct Responses / Requests)') }
           { getTextOrLoading(rar) }
           { renderLabelElement(etaDescription, etaDescriptionAdditional) }
           { getTextOrLoading(eta) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (renderLabelElement('IAR ', '(Heard / Spoken Wake Words for IAR)')) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (getTextOrLoading(iar)) }

           { !isAutomotiveScenario && renderLabelElement('Location & Noise Played', AppConstants.EMPTY) }
           { isAutomotiveScenario && renderLabelElement('Noise Played', AppConstants.EMPTY) }
           { getTextOrLoading(location) }

           { scenarioType === AppConstants.TRAINED_MOBILE && (renderLabelElement('Current Trainer ', AppConstants.EMPTY)) }
           { scenarioType === AppConstants.TRAINED_MOBILE && (getTextOrLoading(currentTrainedVoice)) }
           { isLatencyScenario(scenarioType) && (renderLabelElement('Current Latency Value', AppConstants.EMPTY)) }
           { isLatencyScenario(scenarioType) && (getTextOrLoading(latencyValue)) }
           { scenarioType === AppConstants.LATENCY && renderLabelElement('Utterance Count ', '(Current Round)') }
           { scenarioType === AppConstants.LATENCY && getTextOrLoading(utteranceCountForIteration) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Renders test metrics feeds for Music
   */
  renderMusicTestMetricsFeeds = () => {
    const isDataAvailable = Object.keys(this.props.params.testStats).length > 0;
    let startTime, count, elapsed, eta;
    if (isDataAvailable) {
      startTime = getTimeForDisplay(this.props.params.testStats.startTime);
      count = this.props.params.testStats.count;
      elapsed = this.getElapsedTimeDisplay();
      eta = this.getEtaDisplay();
    }

    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
           { renderLabelElement('Utterance Count ','(Played / Total in Test)') }
           { getTextOrLoading(count) }
           { renderLabelElement('Start Time', AppConstants.EMPTY) }
           { getTextOrLoading(startTime) }
           { renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
           { getTextOrLoading(elapsed) }
           { renderLabelElement('ETA ', '(Estimated Time Remaining)') }
           { getTextOrLoading(eta) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  /**
   * Checks whether test status is in auto resource sync stage
   * @returns true if auto resource sync is in-progress
   */
  isAutoSyncInProgress = () =>  {
    return this.props.params.testStatus
        && AppConstants.RASPI_RESOURCES_SYNCING == this.props.params.testStatus.toLowerCase();
  }

  /**
   * Checks whether test is in the Queued state
   * @returns true if test is in the Queued state
   */
  isTestQueued = () => {
    return this.props.params.testStatus
        && AppConstants.QUEUED_STATES.includes(this.props.params.testStatus.toLowerCase());
  }

  /**
   * Checks whether test case has completed or canceled
   * @returns true if test case has completed or canceled
   */
  isTestCaseCompleted = () =>  {
    return this.props.params.testStatus
      && AppConstants.COMPLETED_STATES.includes(this.props.params.testStatus.toLowerCase());
  }

  /**
   * Returns message to be displayed when test has completed or canceled
   * @returns Message to display when test case has completed or canceled
   */
  getTestCaseCompletedMessage = () => {
    let testStatus = this.props.params.testStatus;
    let adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE;
    if (testStatus) {
      let testStatusLower = this.props.params.testStatus.toLowerCase();
      if (testStatusLower === AppConstants.CANCELED) {
        adminUtteranceMessage = AppConstants.TEST_CANCELED_MESSAGE;
      } else {
        adminUtteranceMessage = AppConstants.TEST_COMPLETED_MESSAGE;
      }
    }
    return adminUtteranceMessage;
  }

  /**
   * if test loader is handled by the live run component (testsuite)
   * @returns {boolean}
   */
  isTestLoadingHandledByTestSuite = () => {
    const isAutomotiveScenario = AutomotiveScenarioTypes.includes(this.props.params.scenarioType);
    if (isAutomotiveScenario) {
      return true;
    }
    return false;
  }

  // Renders test metrics
  render() {
    const isAutomotiveScenario = AutomotiveScenarioTypes.includes(this.props.params.scenarioType);
    if(this.isTestQueued() && !this.isTestLoadingHandledByTestSuite()) {
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
            ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            getLoadingMessage(AppConstants.JOB_QUEUED_MESSAGE)
      )
    } else if (this.isAutoSyncInProgress() && !this.isTestLoadingHandledByTestSuite()) {
      return (
          this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
              ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
              getLoadingMessage(AppConstants.AUTO_SYNC_IN_PROGRESS_MESSAGE)
      )
    } else if (isAutomotiveScenario) {
      // automotive scenario uses Acoustic's test metrics
      // but implements its own component for utterance live feed.
      // automotive scenario will render admin utt hence the below code is skipped.
      return this.renderAcousticTestMetricsFeeds();
    } else if (Object.keys(this.props.params.testStats).length !== 0
          && this.props.params.testStats.num) {
      if (!this.props.params.testSuite
           || this.props.params.testSuite === AppConstants.ACOUSTIC_SCENARIO_ID
           || this.props.params.testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID
           || isAutomotiveScenario) {
           // Acoustic case
           return this.renderAcousticTestMetricsFeeds();
        } else if (this.props.params.testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
           // Music case
           return this.renderMusicTestMetricsFeeds();
        }
        // Default: Return Acoustic Test Metrics by default
        return this.renderAcousticTestMetricsFeeds();
    } else if(this.props.params.testStats.measureTypeCustomPayload === AppConstants.ADMIN_MEASURE) {
      // TODO: Refactor the below code; below code renders notification while the admin utt is being executed
      let testStatsFromPayload = this.props.params.testStats;
      let adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE;
      // If test has completed, display a message & ask user to navigate to results page
      if (this.isTestCaseCompleted()) {
        adminUtteranceMessage = this.getTestCaseCompletedMessage();
      }
      else if (testStatsFromPayload
            && Object.keys(testStatsFromPayload).length > 0) {
        let intentTypeFromPayload = testStatsFromPayload.intentType;
        if (intentTypeFromPayload
              && AppConstants.ADMIN_UTTERANCE_MESSAGE_MAP.hasOwnProperty(intentTypeFromPayload)) {
          adminUtteranceMessage = AppConstants.ADMIN_UTTERANCE_MESSAGE_MAP[intentTypeFromPayload];
        }
      }
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
         ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
         getLoadingMessage(adminUtteranceMessage)
      )
    } else {
      return (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
         ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
          getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
      )
    }
  }
}

TestMetrics.propTypes = {
  params: PropTypes.object.isRequired
};

export default TestMetrics;
