// Lab constants
export const FETCH_THINGS_FOR_LAB_PENDING = 'action/FETCH_THINGS_FOR_LAB_PENDING';
export const FETCH_THINGS_FOR_LAB_SUCCESS = 'action/FETCH_THINGS_FOR_LAB_SUCCESS';
export const FETCH_THINGS_FOR_LAB_FAILURE = 'action/FETCH_THINGS_FOR_LAB_FAILURE';

export const EXECUTE_ACTION_ON_THING_PENDING = 'action/EXECUTE_ACTION_ON_THING_PENDING';
export const EXECUTE_ACTION_ON_THING_SUCCESS = 'action/EXECUTE_ACTION_ON_THING_SUCCESS';
export const EXECUTE_ACTION_ON_THING_FAILURE = 'action/EXECUTE_ACTION_ON_THING_FAILURE';

export default {
  FETCH_THINGS_FOR_LAB_PENDING,
  FETCH_THINGS_FOR_LAB_SUCCESS,
  FETCH_THINGS_FOR_LAB_FAILURE,
  EXECUTE_ACTION_ON_THING_PENDING,
  EXECUTE_ACTION_ON_THING_SUCCESS,
  EXECUTE_ACTION_ON_THING_FAILURE
};