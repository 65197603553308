import React from 'react';
import AWSUI from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';
import AppConstants from '_Constants/AppConstants';
import { getDisablePlaybackSkill } from '../uiUtils';
import { setCheckedStateInTestParams } from "../uiActionsUtils";
import CustomizeOptionsSubPage from '../CustomizeOptionsSubPage';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';
class MobileVoiceTraining extends React.Component {

  /**
   * Checkbox to enable running Mobile voice training manually
   * @param {*} selectedScenario
   * @param {*} selectedScenarioCallBack
   */
  getManualMobileVoiceTrainingCheckbox = (selectedScenario, selectedScenarioCallBack) => {
    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.Checkbox label='Run Mobile Voice Training manually'
              checked={selectedScenario.mobileVoiceTrainingIsManual}
              onChange={event => {
                setCheckedStateInTestParams(event, "mobileVoiceTrainingIsManual",
                  selectedScenario, selectedScenarioCallBack)
              }} />
            <AWSUI.Checkbox label='Device uses Amazon Speaker Verification'
                            checked={selectedScenario.isDevice1PSV}
                            disabled={!selectedScenario.mobileVoiceTrainingIsManual}
                            onChange={event => {
                              setCheckedStateInTestParams(event, "isDevice1PSV",
                                selectedScenario, selectedScenarioCallBack)
                            }} />
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  /**
   *
   * @param {*} selectedScenario
   * @param {*} selectedScenarioCallBack
   */
  getCheckboxOptionsForMobile = (selectedScenario, selectedScenarioCallBack) => {
    return (
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root='true'>
          {this.getManualMobileVoiceTrainingCheckbox(selectedScenario, selectedScenarioCallBack)}
          {getDisablePlaybackSkill(selectedScenario, selectedScenarioCallBack)}
        </div>
      </AWSUI.ColumnLayout>
    )
  }

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack } = this.props.params;
    return (
      <div>
        {this.getCheckboxOptionsForMobile(selectedScenario, selectedScenarioCallBack)}
        <TestTypeScenarioOptions
          allowedTypes={[0, 1, 2, 3]}
          showDelayConfig={AppConstants.CUSTOMIZED === selectedScenario.testType}
          showAdminValidation={AppConstants.CUSTOMIZED === selectedScenario.testType}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
          {/* These components are specific to Mobile test */}
          {!isEmpty(selectedScenario) &&
            (<CustomizeOptionsSubPage params={{
              selectedScenario: selectedScenario,
              selectedScenarioCallBack: selectedScenarioCallBack,
              piLocations: AppConstants.trainedMobileLocation,
              selectedScenarioError: selectedScenarioError,
              scenarioErrorCallBack: scenarioErrorCallBack
            }} />)
          }
        </TestTypeScenarioOptions>
      </div>
    )
  }
}

export default MobileVoiceTraining;
