/**
 * Renders test metrics feeds for Mobile Functional
 */

import React from 'react';
import AppConstants from "../../../../_Constants/AppConstants";
import FunctionalTestCases from "../../../../_Constants/FunctionalTestCasesConstants";
import {
  getCompletedTestsNum,
  getCompletedTestsStats, getInProgressTestNameDisplay, getOverallEta,
  getOverallTestStatusList, getUtteranceStatus, getValueForUtteranceFeed
} from "../../Functional/FunctionalLiveFeedUtil";
import AWSUI from "@amzn/awsui-components-react";
import {getTextOrLoading, renderLabelElement} from "../../../../Util";

class MobileFunctionalTestMetricsFeeds extends React.Component {
  /**
   * Gets Test Metrics to render for Mobile Functional test case
   * @param completedTestsStats Statistics of Completed tests
   * @param currentFunctionalTest Name of Current Functional test case
   * @param startTime Start time of the test case
   * @param questionPlayed Device Command / Question Played (Current Utterance)
   * @param elapsedTime Time elapsed since test case was kicked off
   * @param dutHeard Asr text heard by DUT
   * @param eta Estimated time remaining
   * @param dutResponded Response
   * @param scoreFrr FRR score -> 0 or 1
   * @param scoreRar RAR score -> 0 or 1
   * @param utteranceValidationType Type of validation used for utterance - INTENT vs REGEX
   * @param utteranceStatus Status of current utterance from payload
   * @returns Test metrics in tabular format containing all above data
   */
  getMobileFunctionalTestMetrics(completedTestsStats, currentFunctionalTest, startTime, questionPlayed,
                                 elapsedTime, dutHeard, eta, dutResponded, scoreFrr, scoreRar, utteranceValidationType, utteranceStatus) {
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Test Completion Count ','(Completed / Total number of Tests)') }
            { getTextOrLoading(completedTestsStats) }
            { renderLabelElement('Current Test Case ', AppConstants.EMPTY) }
            { getTextOrLoading(currentFunctionalTest) }
            { renderLabelElement('Start Time', AppConstants.EMPTY) }
            { getTextOrLoading(startTime) }
            { renderLabelElement('Device Command / Question Played ', '(Current Utterance)') }
            { getUtteranceStatus(questionPlayed, utteranceStatus, 0, FunctionalTestCases.QUESTION_PLAYED_OVERALL,
              utteranceValidationType) }
            { renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
            { getTextOrLoading(elapsedTime) }
            { renderLabelElement('DUT Heard ', '(Current Utterance)') }
            { getUtteranceStatus(dutHeard, utteranceStatus, scoreFrr, FunctionalTestCases.DUT_HEARD,
              utteranceValidationType) }
            { renderLabelElement('Overall ETA ', '(Total Estimated Time Remaining)') }
            { getTextOrLoading(eta) }
            { renderLabelElement('Response ', '(Current Utterance)') }
            { getUtteranceStatus(dutResponded, utteranceStatus, scoreRar, FunctionalTestCases.DUT_RESPONDED,
              utteranceValidationType) }
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  render() {
    const isDataAvailable = Object.keys(this.props.params).length > 0;
    let completedTestsStats, startTime, elapsedTime, eta;
    let currentFunctionalTest = AppConstants.UNAVAILABLE;
    let questionPlayed = AppConstants.RETRIEVING;
    let dutHeard = AppConstants.RETRIEVING;
    let dutResponded = AppConstants.RETRIEVING;
    let scoreFrr;
    let scoreRar;
    let labJobId = AppConstants.EMPTY;
    let utteranceValidationType = AppConstants.EMPTY;
    let utteranceStatus = FunctionalTestCases.FAILED;

    if (isDataAvailable) {
      startTime = this.props.params.startTime;
      elapsedTime = this.props.params.elapsedTime;
      labJobId = this.props.params.labJobId;

      let functionalStatusList = getOverallTestStatusList(this.props.params.functionalTestCases);
      let completedTestsNum = getCompletedTestsNum(functionalStatusList);
      completedTestsStats = getCompletedTestsStats(completedTestsNum, functionalStatusList);
      eta = getOverallEta(this.props.params.avgTestTime, completedTestsNum, this.props.params.functionalTestCases);
      currentFunctionalTest = getInProgressTestNameDisplay(this.props.params.currentFunctionalTest);
      utteranceValidationType = this.props.params.utteranceValidationType;
      utteranceStatus = this.props.params.utteranceStatus;

      if (this.props.params.testStats && Object.keys(this.props.params.testStats).length > 0) {
        let currentUtteranceData = getValueForUtteranceFeed(labJobId + FunctionalTestCases.UTTERANCE_DATA_KEY,
          this.props.params.questionPlayed,
          this.props.params.dutHeard,
          this.props.params.dutResponded,
          this.props.params.scoreFrr,
          this.props.params.scoreRar,
          this.props.params.utteranceStatus,
          this.props.params.utteranceAttribute
        );

        questionPlayed = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.QUESTION_PLAYED] :
          AppConstants.RETRIEVING;
        dutHeard = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_HEARD] :
          AppConstants.RETRIEVING;
        dutResponded = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.DUT_RESPONDED] :
          AppConstants.RETRIEVING;
        scoreFrr = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.FRR_KEY] :
          AppConstants.RETRIEVING;
        scoreRar = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.RAR_KEY] :
          AppConstants.RETRIEVING;
        utteranceStatus = Object.keys(currentUtteranceData).length > 0 ?
          currentUtteranceData[FunctionalTestCases.STATUS_KEY] :
          AppConstants.RETRIEVING;
      }
    }

    return (
      this.getMobileFunctionalTestMetrics(completedTestsStats, currentFunctionalTest, startTime, questionPlayed, elapsedTime,
        dutHeard, eta, dutResponded, scoreFrr, scoreRar, utteranceValidationType, utteranceStatus)
    );
  }
}

export default MobileFunctionalTestMetricsFeeds;
