import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../services/client';
import { API_STATUS, URL_MAPS } from '../../constants';
import { getUrl } from '../../services/utils';

export const fetchLabs = createAsyncThunk('labs/fetchLabs', async () => {
  const response = await client.get(getUrl(URL_MAPS.LAB))
  return response.data;
})

export const labSlice = createSlice({
  name: 'labsList',
  initialState: {
    status: API_STATUS.INIT,
    labs: [],
    error: null
  },
  extraReducers:(builder) => {
    builder
      .addCase(fetchLabs.fulfilled, (state, action) => {
        state['status'] = API_STATUS.SUCCESS;
        state['labs'] = Array.isArray(action.payload) ? action.payload : [];
        state['error'] = null;
      })
      .addCase(fetchLabs.pending, (state) => {
        state['status'] = API_STATUS.PENDING;
        state['error'] = null;
      })
      .addCase(fetchLabs.rejected, (state, action) => {
        state['status'] = API_STATUS.ERROR;
        state['error'] = "Failed to fetch lab information";
      })
  }
})

export const labsSelector = (state) => state.labList;

export default labSlice.reducer;
