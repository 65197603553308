import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';

import AppConstants from '_Constants/AppConstants';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';
import TestCalibration from './TestCalibration';
import Helpers from './Helpers';

/**
 * This component renders Qual Test specific custom options
 * User can configure SPL for each speaker including DUT
 * SPL - Sound Pressure Level
 */
class CustomizedOptions extends Component {
  static propTypes = {
    selectedScenario: PropTypes.object.isRequired,
    selectedScenarioError: PropTypes.object.isRequired,
    selectedScenarioCallBack: PropTypes.func.isRequired,
    scenarioErrorCallBack: PropTypes.func.isRequired,
    labsInAccount: PropTypes.arrayOf(PropTypes.object),
  };

  /**
   * Update SPL settings for actors and DUT
   * @param {*} spl
   */
  handleSPLSelection = (spl = {}) => {
    const { qual } = this.props.selectedScenario;
    this.props.selectedScenarioCallBack({
      ...this.props.selectedScenario,
      qual: {
        ...qual,
        spl
      }
    })
  }

  /**
   * Handler to update state based on the selection
   */
  handleQualCustomizedSelection = (location) => {
    return (event) => {
      if (!event) return;
      const { qual } = this.props.selectedScenario;
      const { detail } = event;
      let selectedOptions = {};
      // when location is unchecked, remove it from the selected actors
      if (!detail.checked) {
        Object.keys(qual.spl).forEach(key => {
          if (qual.spl[key].location !== location) selectedOptions[key] = qual.spl[key];
        });
      } else selectedOptions = qual.spl

      this.props.selectedScenarioCallBack({
        ...this.props.selectedScenario,
        qual: {
          ...qual,
          spl: selectedOptions,
          locations: {
            ...qual.locations,
            [location]: detail.checked
          }
        }
      });
    }
  }

  /**
   * Render options when Custom test type is selected
   * and these are specific to Qual test
   */
  renderQualCustomOptions = () => {
    const { qual = {}, testSuite, qualCustomOptionsSelected } = this.props.selectedScenario;
    if (!qualCustomOptionsSelected) return;

    const QUAL = AppConstants.TEST_SUITES[testSuite];
    const { SPEECH_LOCATIONS } = QUAL;
    return (
      <AWSUI.ColumnLayout columns={2}>
        <div data-awsui-column-layout-root='true' className='awsui-util-pb-s'>
          {
            SPEECH_LOCATIONS.map(loc => (
              <AWSUI.Checkbox
                onChange={this.handleQualCustomizedSelection(loc)}
                checked={!!(qual.locations && qual.locations[loc])}
              >
                {loc}
              </AWSUI.Checkbox>
            ))
          }
        </div>
      </AWSUI.ColumnLayout>
    );
  }

  /**
   * Renders Calibration section with SPL settings
   */
  renderTestCalibration = () => {
    const { testSuite, scenarioType, testType, qual } = this.props.selectedScenario;
    const { labId } = this.props;
    if (testSuite !== AppConstants.QUAL_SCENARIO_ID || !labId) return;

    const { labsInAccount } = this.props;

    const lab = labsInAccount.find(lab => lab.id === labId);

    return (
      <TestCalibration
        testSuite={testSuite}
        scenarioType={scenarioType}
        testType={testType}
        actors={Helpers.getAllowedActors(this.props.selectedScenario)}
        labId={labId}
        lab={lab}
        spl={qual.spl}

        handleSPLSelection={this.handleSPLSelection}
      />
    );
  }

  render() {
    return (
      <TestTypeScenarioOptions
        allowedTypes={[0, 2, 3]}
        selectedScenario={this.props.selectedScenario}
        selectedScenarioError={this.props.selectedScenarioError}
        selectedScenarioCallBack={this.props.selectedScenarioCallBack}
        scenarioErrorCallBack={this.props.scenarioErrorCallBack}
      >
        {/* These components are specific to QUAL test */}
        {this.renderQualCustomOptions()}
        {this.renderTestCalibration()}
      </TestTypeScenarioOptions>
    );
  }
}

export default CustomizedOptions;
