import axios from 'axios';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import { aqtStore } from '../../Components/State/Store';
import { withHeader } from '../../Auth';
import { networkError, getSession, logToConsole } from '../../Util';

const RECORDS_LIMIT = 'limit=200';

export function fetchJobs(query) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let requestUrl = controllerEndpoint + ApiConstants.POST_JOB_SUBMIT_URL + '?' + RECORDS_LIMIT;
  if (query !== AppConstants.EMPTY) {
    requestUrl += '&q=';
  }
  return axios.get(requestUrl + query, withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(jobs => {
      if (jobs.hasOwnProperty('data')) {
        logToConsole('Jobs: ', JSON.stringify(jobs.data));
        return jobs.data;
      } else {
        return {
          error: AppConstants.NOJOBS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchJobs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function generateJobQuery(fromDate, toDate) {
  let query = {'createdAt' : {'gte' : fromDate, "lte" : toDate}};
  return JSON.stringify(query);
}

export function fetchDownloadUrl(jobId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let downloadUrl = ApiConstants.POST_JOB_SUBMIT_URL + '/' + jobId + '/summaryReportDownloadURL'
  return axios.get(controllerEndpoint +
      downloadUrl,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(reportUrl => {
      if (reportUrl.hasOwnProperty('data')) {
        logToConsole('URL: ', JSON.stringify(reportUrl.data));
        return reportUrl.data;
      } else {
        return {
          error: AppConstants.NOURL
        }
      }
    })
    .catch(error => {
      networkError(error, fetchDownloadUrl.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}
