import constants from '../constants/labs';
import apiUtils from 'config/apiUtils';

export function executeActionOnThing(labId, raspiId, action, actionTimeout, params = {}) {
  return (dispatch) => {
    const url = apiUtils.executeActionOnThing(labId, raspiId, action);

    let data = {};
    if (action !== 'stop') {
      data = {
        // set defaults
        volume: 100,
        ...params,
      }
    }

    return dispatch({
      type: [
        constants.EXECUTE_ACTION_ON_THING_PENDING,
        constants.EXECUTE_ACTION_ON_THING_SUCCESS,
        constants.EXECUTE_ACTION_ON_THING_FAILURE
      ],
      meta: {
        url,
        method: 'post',
        data,
        params: {
          actionTimeout
        }
      }
    });
  }
}

/**
 * Get things associated to the given lab
 * @param {string} labId
 */
export function getLabThings(labId) {
  return (dispatch, getState) => {
    if (!labId) {
      return Promise.reject({
        error: 'Missing labId'
      })
    }

    const { labs: { labToThingsMapping = {} } = {} } = getState();
    // if already fetched and available in store, return it!
    if (labToThingsMapping[labId]) {
      return Promise.resolve(labToThingsMapping[labId]);
    }

    const url = apiUtils.getLabThings(labId);

    dispatch({
      type: [
        constants.FETCH_THINGS_FOR_LAB_PENDING,
        constants.FETCH_THINGS_FOR_LAB_SUCCESS,
        constants.FETCH_THINGS_FOR_LAB_FAILURE
      ],
      meta: {
        url,
        method: 'get'
      }
    });
  }
}
