/**
 * Utility file which contains helper methods for other components in the app
 */
import AppConstants from '../_Constants/AppConstants';
import MusicConstants from '../_Constants/MusicConstants';
import { aqtStore } from '../Components/State/Store';
import { Auth } from 'aws-amplify';
import { transform, isEqual, isObject } from 'lodash';
import React from 'react';
import CustomOptionsConstants from '../_Constants/CustomOptionsConstants';
import FunctionalTestCases from '../_Constants/FunctionalTestCasesConstants';
import AWSUI from '@amzn/awsui-components-react';

// List of scenario types for which test type is same i.e. not applicable
const SCENARIOS_WITH_NO_TEST_TYPES = ['AC_WWDD_QUICK_TEST', 'AC_WWDD_FULL_TEST', 'AC_FAR', 'AC_FAR_CUSTOM',
    'CUSTOM_STANDARD', 'LATENCY', 'LATENCY_FULL', 'FAR', 'FAR_CUSTOM', 'AC_FAR_PLUS'];

/**
 * Helper method to get a session when user signs in
 */
export function getSession() {
  if (!aqtStore.getState().session || !aqtStore.getState().environment) {
    return AppConstants.SESSIONERR;
  } else {
    return aqtStore.getState().session;
  }
}

/**
 * Helper method to handle network error scenarios
 * @param error Error in the response used to log error message
 * @param func Parent function which lead to network error
 */
export function networkError(error, func) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    logToConsole('Error: ' + func + ' - Server response: ',
      JSON.stringify(error.response));
    let statusCode = error.response.status;
    if (statusCode && statusCode === AppConstants.SESSION_EXPIRED_STATUS_CODE) {
      logToConsole('Session Expired');
      handleSignOut();
    }
  } else if (error.request) {
    // The request was made but no response was received
    logToConsole('Error: ' + func + ' - No response from server. Request: ',
      JSON.stringify(error.request));
  } else {
    // Something happened in setting up the request that triggered an Error
    logToConsole('Error: ' + func + ' - Trace: ',
      error.message);
  }
  //logToConsole(error.config);
}

/**
 * Gets Utterance count (currently played/ total count) and percentage
 * @param numer Current utterance number
 * @param denom Total number of utterances
 * @return String displaying (current utterance/ total and % of current/ total)
 */
export function countAndPercentage(numer, denom) {
  try {
    // Make sure the numerator is not null and the denominator is neither null nor 0
    if (numer != null && denom) {
      let percentage = parseInt(numer, 10) * 100 / parseInt(denom, 10);
      return numer + ' / ' + denom + ' (' + percentage.toFixed(1) + '%)';
    }
  } catch (error) {
    logToConsole('Error: ' + countAndPercentage.name + ' - Trace: ',
      JSON.stringify(error))
  }
  return '0';
}

/**
 * Gets Percentage value for numerator/ denominator
 * @param numer Numerator
 * @param denom Denominator
 * @return Percentage (numer/ denom) in %
 */
export function getPercentage(numer, denom) {
  try {
    if (numer && denom) {
      let percentage = parseInt(numer, 10) * 100 / parseInt(denom, 10);
      return percentage;
    }
  } catch (error) {
    logToConsole('Error: ' + getPercentage.name + ' - Trace: ',
      JSON.stringify(error))
  }
  return 0;
}

/**
 * Gets time string based on input milliseconds
 * @param milliseconds Time in milliseconds
 * @return Time string in format hh mm
 */
export function getTime(milliseconds) {
  if (milliseconds < 0) {
    return AppConstants.EMPTY;
  }
  try {
    let seconds = milliseconds/1000.0;
    logToConsole("seconds " + seconds);
    let hours = Math.floor(seconds / 3600);
    (hours >= 1) ? seconds = seconds - (hours * 3600) : hours = '0';
    let minutes = Math.ceil(seconds / 60);
    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }
    if (hours > 0) {
      return hours + ' h, ' + minutes + ' min';
    } else if (minutes >= 0) {
      return minutes + ' min';
    }

  } catch (error) {
    logToConsole('Error: ' + getTime.name + ' - Trace: ',
      JSON.stringify(error))
  }
  return 'Calculating';
}

/**
 * Gets time string based on input milliseconds
 * @param download_speed in KB
 * @param completedSize in bytes
 * @param total_size in bytes
 * @return time in miliseconds
 */
export function calculateRemainingTime(download_speed, completedSize, total_size) {
  logToConsole("Input is " + download_speed + " " + completedSize + " " + total_size);
  logToConsole("result is " + (total_size - completedSize)/download_speed/1.024);
  return (total_size - completedSize)/download_speed/1.024;
}

/**
 * Gets short time string for display purposes
 * @param time Time string format Day Month Date GMT - offset (Timezone)
 * @return Shorter version of time string
 */
export function getTimeForDisplay(time) {
  if (time) {
    time = time.substring(4, time.length);
    var timeTokens = time.split("(");
    let timeWithoutZone = timeTokens[0];
    var timeZoneTokens = timeTokens[1].split(" ");
    let timeZone = " (";
    for (var i = 0; i < timeZoneTokens.length; i++) {
      timeZone += timeZoneTokens[i].charAt(0);
    }
    timeZone += ")";
    return timeWithoutZone + timeZone;
  }
}

/**
 * Gets list of scenario types based on test suite
 * @param testSuite Test Suite
 * @returns List of scenario types based on test suite
 */
function getScenariosForTestSuite(testSuite) {
  const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
  return (testSuiteInfo && testSuiteInfo.SCENARIO_TYPE) || [];
}

/**
 * Gets Scenario type + Test type for display based on input scenario type, test type
 * @param testSuite Test suite
 * @param scenarioType Scenario type
 * @param testType Test Type
 * @return Combination of customer facing scenario type, test type
 */
export function getTestTypeToDisplay(testSuite, scenarioType, testCategory, testType) {
  let testTypeDisplay = scenarioType;
  if (testSuite !== AppConstants.ACOUSTIC_SCENARIO_ID) {
    let lookupArray = getScenariosForTestSuite(testSuite);
    testTypeDisplay = getDisplayNameForId(lookupArray, scenarioType);
    // Following logic is used to backfill data in case scenarioType is from older enum
    if (!testTypeDisplay || testTypeDisplay === AppConstants.EMPTY) {
      testTypeDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE_DEPRECATED, scenarioType);
    }
    // Close Talk test type
    if (!testTypeDisplay || testTypeDisplay === AppConstants.EMPTY) {
      testTypeDisplay = getDisplayNameForId(AppConstants.CLOSE_TALK_SCENARIO_TYPE, scenarioType);
    }
    if (scenarioType === AppConstants.MOBILE_FUNCTIONAL) {
        testTypeDisplay = getDisplayNameForId(getMobileScenarios(), scenarioType)
    }
    if (testCategory && testCategory.length > 0) {
        testCategory = testCategory.toLowerCase().split('_');
        for (var i = 0; i < testCategory.length; i++) {
          testCategory[i] = testCategory[i].charAt(0).toUpperCase() + testCategory[i].substring(1);
        }
        testTypeDisplay += ', ' + testCategory.join(' ')
    }
    if (testType && testType.length > 0) {
      testTypeDisplay += ', ' + testType.charAt(0) + testType.slice(1).toLowerCase();
    }
  } else {
    testTypeDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE, scenarioType);
    // Following logic is used to backfill data in case scenarioType is from older enum
    if (!testTypeDisplay || testTypeDisplay === AppConstants.EMPTY) {
      testTypeDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE_DEPRECATED, scenarioType);
    }
  }
  return testTypeDisplay;
}

/**
 * Gets Scenario type for display based on input scenario type
 * @param scenarioType Scenario type
 * @param testSuite Test Suite
 * @param job Current job config information if applicable
 * @return Customer facing scenario type
 */
export function getScenarioTypeToDisplay(scenarioType, testSuite, config) {
  const testSuites = [
    AppConstants.FUNCTIONAL_SCENARIO_ID,
    AppConstants.MOBILE_SUITE_ID,
    AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID,
    AppConstants.STABILITY_SCENARIO_ID,
    AppConstants.UPL_SCENARIO_ID,
    AppConstants.QUAL_SCENARIO_ID,
    AppConstants.SECURITY_SCENARIO_ID,
    AppConstants.AUTOMOTIVE_SCENARIO_ID
   ];

  let scenarioTypeDisplay = AppConstants.EMPTY;
  if (testSuite === AppConstants.ACOUSTIC_SCENARIO_ID) {
    scenarioTypeDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE, scenarioType);
    // Following logic is used to backfill data in case scenarioType is from older enum
    if (!scenarioTypeDisplay || scenarioTypeDisplay === AppConstants.EMPTY) {
      scenarioTypeDisplay = getDisplayNameForId(AppConstants.SCENARIO_TYPE_DEPRECATED, scenarioType);
    }
  } else if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID) {
    scenarioTypeDisplay = getDisplayNameForId(AppConstants.CLOSE_TALK_SCENARIO_TYPE, scenarioType);
  } else if (testSuite === MusicConstants.MUSIC_SCENARIO_ID) {
    // Handling a special case where scenarioType is "Deezer"
    if (scenarioType === AppConstants.DEEZER_DEPRECATED) {
      scenarioType = MusicConstants.MUSIC_MSK;
    }
    scenarioTypeDisplay = getDisplayNameForId(MusicConstants.MSP_TYPE, scenarioType);
     // Following logic is used to backfill data in case scenarioType is from older enum
    if (!scenarioTypeDisplay || scenarioTypeDisplay === AppConstants.EMPTY) {
      scenarioTypeDisplay = getDisplayNameForId(MusicConstants.MSP_TYPE_DEPRECATED, scenarioType);
    }
  } else if (testSuites.includes(testSuite)) {
    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
    scenarioTypeDisplay = getDisplayNameForId(testSuiteInfo.SCENARIO_TYPE, scenarioType);
  }

  if (testSuite === AppConstants.CUSTOM_SCENARIO_ID && config && config.name) {
    scenarioTypeDisplay = config.name;
  }

  return scenarioTypeDisplay;
}

/**
 * Gets Test type for display based on input test type
 * @param testType Test type
 * @param scenarioType Scenario type
 * @param testSuite Test Suite
 * @return Customer facing Test type
 */
export function getTestTypeDisplay(testType, scenarioType, testSuite) {
  if (testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
    return AppConstants.NOT_APPLICABLE;
  }
  if (testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID
    && scenarioType !== AppConstants.CT_WAKE_AND_RESPONSE) {
    return AppConstants.NOT_APPLICABLE;
  }
  if (SCENARIOS_WITH_NO_TEST_TYPES.includes(testType) || testType === AppConstants.EMPTY) {
    return AppConstants.NOT_APPLICABLE;
  }
  if (testType) {
    return testType.charAt(0) + testType.slice(1).toLowerCase();
  }
  return AppConstants.UNAVAILABLE;
}

/**
 * Gets list of scenarios/ MSPs/ MSKs to display for a test suite
 * @param testSuite Name of test suite
 * @return list of scenario types/ MSPs/ MSKs based on input provided
 */
export function getScenarioListForTestSuite(testSuite) {
  let scenarioList = AppConstants.SCENARIO_TYPE;
  switch(testSuite) {
    case AppConstants.ACOUSTIC_SCENARIO_ID:
      break;
    case AppConstants.CLOSE_TALK_SCENARIO_ID:
      scenarioList = AppConstants.CLOSE_TALK_SCENARIO_TYPE;
      break;
    case MusicConstants.MUSIC_SCENARIO_ID:
      scenarioList = MusicConstants.MSP_TYPE;
      break;
    case AppConstants.AUTOMOTIVE_SCENARIO_ID:
      scenarioList = AppConstants.AUTOMOTIVE_SCENARIO_TYPE;
    default:
      break;
  }
  return scenarioList;
}

/**
  * Gets value to display for a particular field based on scneario is FAR Custom or not
  * @param fieldValue Value to be displayed
  * @param scenarioType Type of Scenario
  * @return Display value for Test options field -> Will return 'Custom' if scenario is FAR Custom
  */
export function getDisplayFieldForCustomFar(fieldValue, scenarioType) {
  if (scenarioType === AppConstants.FAR_CUSTOM) {
    return AppConstants.CUSTOM;
  }
  return fieldValue && fieldValue !== AppConstants.EMPTY ? fieldValue : AppConstants.NOT_APPLICABLE;
}

/**
 * Gets value to display for Email notifications or Auto Sync field based on the state.
 * @param email notification checkbox state
 * @return Display value for Test options field. Returns enabled if checkbox is on, else disabled.
 */
export function getDisplayFieldForCheckBox(checkBoxState) {
  return checkBoxState ? 'Enabled' : 'Disabled';
}

/**
 * Gets Label for a specific ID
 * @param lookupArray Array to look up Ids
 * @param lookUpValue Id to search for
 * @return Label value based on search Id
 */
export function getDisplayNameForId(lookUpArray, lookUpValue) {
  let displayValue =  AppConstants.EMPTY;
  for (var i = 0; i < lookUpArray.length; i++) {
    if (lookUpArray[i].id === lookUpValue) {
      displayValue = lookUpArray[i].label;
      break;
    }
  }
  return displayValue;
}

/**
 * Utility method to check if current scenario exists in list of whitelisted scenarios
 * @param whitelistedScenarioList List of whitelisted scenarios
 * @param scenarioName Scenario name to search for
 * @return Returns true if scenarioName exists in whitelisted list, otherwise false
 */
export function checkScenarioExistsInList(whitelistedScenarioList, scenarioName) {
  if (whitelistedScenarioList && scenarioName) {
    for (var i = 0; i < whitelistedScenarioList.length; i++) {
      var currentScenarioName = whitelistedScenarioList[i].id;
      if (currentScenarioName === scenarioName) {
        return true;
      }
    }
   return false;
  }
  return false;
}

/**
 * Gets Tag to display in a dropdown for a specific ID
 * @param lookupArray Array to look up Ids
 * @param lookUpValue Id to search for
 * @return Tag to be displayed for an ID, if not available returns empty
 */
export function getDisplayTagForId(lookUpArray, lookUpValue) {
  for (var i = 0; i < lookUpArray.length; i++) {
    if (lookUpArray[i].id === lookUpValue && lookUpArray[i].tags) {
      return lookUpArray[i].tags;
    }
  }
  return AppConstants.EMPTY;
}

/**
 * Checks whether test is completed or not
 * @return Returns true if test is completed
 */
export function isTestCompleted(testStatus) {
  if (!testStatus) {
    return false;
  }
  return AppConstants.COMPLETED_STATES.includes(testStatus.toLowerCase());
}

/**
 * Renders element using div tags
 *
 * @param elementInBoldText Element to be rendered in bold text
 * @param element Element to be rendered in regular text
 *
 * @return Returns div element containing elements passed as parameters
 */
export function renderLabelElement(elementInBoldText, element) {
  return (
    <div className='awsui-util-label'>
      { elementInBoldText !== AppConstants.EMPTY && ( <b>{ elementInBoldText }</b> ) }
      { element }
    </div>
  );
}

/**
 * Gets dotted animation for loading element
 * @return Dotten animation element
 */
export function getLoadingElement() {
  return (
    <div className='awsui-util-label'>
      <span className='ellipsis-anim'>
        <span>&bull;</span><span>&bull;</span><span>&bull;</span><span>&bull;</span>
      </span>
    </div>
  );
}

/**
 * Returns text or loading component
 *
 * @param value Text to be displayed
 *
 * @return Returns component containing text or loading
 */
export function getTextOrLoading(value) {
  if (value) {
    return renderLabelElement(AppConstants.EMPTY, value);
  } else {
    return getLoadingElement();
  }
}

/**
 * Gets download speed for resources
 * @param kilobytes
 * @return Download speed per second
 */
export function getDownloadSpeed(kilobytes) {
  // Up to GB
  if (kilobytes < 0) {
    return '';
  }
  try {
    let GB = Math.floor(kilobytes/(1024 * 1024));
    let MB = Math.floor((kilobytes - GB * 1024 * 1024)/1024);
    let ret = '';
    if (GB > 0) {
      ret = (kilobytes/(1024 * 1024)).toFixed(2) + 'GB';
    } else if (MB > 0) {
      ret = (kilobytes/1024).toFixed(2) + 'MB';
    } else {
      ret = kilobytes.toFixed(2) + 'KB';
    }
    return ret + '/S';
  } catch (error) {
    logToConsole('Error: ' + getDownloadSpeed.name + ' - Trace: ',
      JSON.stringify(error))
  }
  return 'Calculating';
}

/**
 * Helper method to handle sign out functionlity
 */
function handleSignOut() {
  Auth.signOut()
    .then(() => {
     logToConsole('Signed out successfully');
     var url = window.location.href;
     if (!url.includes(AppConstants.SESSION_EXPIRED_PARAM)) {
      if (url.indexOf('?') > -1) {
        url += '&' + AppConstants.SESSION_EXPIRED_PARAM
      } else {
        url += '?' + AppConstants.SESSION_EXPIRED_PARAM
      }
     }
     let encodedUrl = encodeURI(url);
     window.location.href = encodedUrl;
    })
    .catch(error => {
      var message;
     if (typeof error === 'string') {
        message = error;
      } else {
        message = error.message;
      }
    logToConsole('Error signing out user ' + message);
   });
 };

/**
 * Konva rotate elements with center as origin
 *
 * @param konvaele the konva element dom reference
 * @param width the width of the element to calculate X offset
 * @param height the height of the element to calculate Y offset
 * @param angle the angle of rotation
 */
export function rotateImageCenter(konvaele, width, height, angle) {
  // Set offset to define new center of element
  konvaele.offsetX(width / 2);
  konvaele.offsetY(height / 2);

  // Reposition the element to its initial coordinates
  konvaele.x(konvaele.x() + width / 2);
  konvaele.y(konvaele.y() + height / 2);

  // Rotate the element
  konvaele.rotation(angle);
}

/**
 * Deep diff between two object, using lodash
 *
 * @param object Object compared
 * @param base Object to compare with base
 *
 * @return return a new object representing the difference
 */
export function objDifference(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
			}
		});
	}
	return changes(object, base);
}

/**
 * Gets custom options map to display on Testdetails & TestOptions page
 * @param options Custom options
 * @returns Custom options map for display purposes
 */
export function getCustomOptionsDisplayMap(options) {
  let customOptions = Object.assign({}, options);
  let schemeDefList = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
  let customOptionsDisplay = {};
  for (var i = 0; schemeDefList &&  i < schemeDefList.length; i++) {
    let customOptionsValues = [];
    let currentActor = Object.keys(schemeDefList[i])[0];
    let currentNoiseList = schemeDefList[i][currentActor];
    for (var j = 0; j < currentNoiseList.length; j++) {
      customOptionsValues.push(Object.keys(currentNoiseList[j])[0]);
    }
    let sortedCustomOptionsValues = customOptionsValues.sort().reverse();
    let displayVal = sortedCustomOptionsValues.join(', ');
    customOptionsDisplay[currentActor] = displayVal;
  }
  return customOptionsDisplay;
}

/**
 * Gets mobile noise selection from custom options to cleanly display in live-run
 * @param options custom options object
 * @returns UI display map of custom mobile noise selection
 */
export function getMobileNoiseDisplayMap(options) {
  let customOptions = Object.assign({}, options);
  let schemeDef = customOptions[CustomOptionsConstants.SCHEME_DEF_KEY];
  let noiseDisplay = {};
  if (schemeDef.length == 1 && Object.keys(schemeDef[0]).length == 1) {
    let noiseList = schemeDef[0][Object.keys(schemeDef[0])[0]];
    for (var i = 0; i < noiseList.length; i++) {
      let thisNoise = Object.keys(noiseList[i])[0];
      let volLevels = [];
      if (noiseList[i][thisNoise].length >= 1) {
        volLevels = "(" + noiseList[i][thisNoise].join(', ') + ")";
      }
      noiseDisplay[thisNoise] = volLevels;
    }
  }
  return noiseDisplay;
}

/**
 * Gets the list of trainers to display on Test Details & Live run page for
 * Mobile Voice training customized scenario
 * @param options Custom Options
 * @returns List of trainers separated by comma
 */
export function getTrainers(options) {
  let customOptions = Object.assign({}, options);
  let trainers = customOptions[CustomOptionsConstants.MOBILE_TRAINERS_KEY];
  let trainersDisplay = AppConstants.EMPTY;
  if (trainers && trainers.length > 0) {
    trainersDisplay = trainers.sort().join(', ');
  }
  return trainersDisplay;
}

/**
 * Gets the list of Functional test cases to display on Test Details & Live run page for
 * Functional Custom scenario
 * @param options Custom Options
 * @returns List of Functional test cases in display friendly format
*/
export function getFunctionalTestCases(testParams) {
  let customOptions = Object.is(testParams.customOptions, undefined)
    ? Object.assign({}, testParams.testOptions.customOptions)
    : Object.assign({}, testParams.customOptions);
  let functionalTestCasesFromCustomOptions = customOptions['functionalTestCases'];
  let functionalTestCasesDisplay = {};
  let functionalTestMapping = testParams.scenarioType === "FUNC_NAV_CUSTOM"
    ? FunctionalTestCases.FUNCTIONAL_NAVIGATION_TEST_CASES_MAPPING
    : FunctionalTestCases.FUNCTIONAL_TEST_CASES_MAPPING

  for (let functionalTestCaseCategory in functionalTestMapping) {
    let functionalCategoryToDisplay = AppConstants.EMPTY;
    let testCasesForCategory = functionalTestMapping[functionalTestCaseCategory];
    for (let j = 0; j < testCasesForCategory.length; j++ ) {
      if (functionalTestCasesFromCustomOptions.includes(testCasesForCategory[j]['id'])) {
        functionalCategoryToDisplay += testCasesForCategory[j]['label'];
        functionalCategoryToDisplay += ', ';
      }
    }
    if (functionalCategoryToDisplay !== AppConstants.EMPTY) {
      functionalCategoryToDisplay = functionalCategoryToDisplay.substring(0, functionalCategoryToDisplay.length - 2);
      functionalTestCasesDisplay[functionalTestCaseCategory] = functionalCategoryToDisplay;
    }
  }
  return functionalTestCasesDisplay;
}

/**
 * Gets the list of Functional test cases to display on Test Details & Live run page for
 * Generic Functional Custom scenario
 * @param options Custom Options
 * @returns List of Functional test cases in display friendly format
 */
export function getFunctionalTestCasesCutomOptions(options) {
  let customOptions = Object.assign({}, options);
  return customOptions['functionalTestCases'];
}

/**
 * Checks whether it's Mobile or Trained Mobile scenario
 * @param scenarioType Type of Scenario
 * @return True if it's mobile  or trained mobile scenario
 */
export function isMobileScenario(scenarioType) {
    return scenarioType === AppConstants.TRAINED_MOBILE;
}

export function isVoiceRobustnessScenario(scenarioType) {
  return scenarioType === AppConstants.VOICE_ROBUSTNESS;
}

/**
 * Checks whether it's WWDD Quick or WWDD Full scenario
 * @param scenarioType Type of Scenario
 * @return True if it's either of WWDD scenarios
 */
export function isLatencyScenario(scenarioType) {
  return scenarioType === AppConstants.LATENCY || scenarioType === AppConstants.LATENCY_FULL;
}

/**
 * Checks if the DUT is an A4PC device or not
 * @param {String} deviceTypeId
 * @return {boolean} true if selected DUT is A4PC
 */
export function isA4PCDevice(deviceTypeId) {
  return deviceTypeId === AppConstants.A4PC_DEVICE_TYPE_ID;
}

/**
 * Validates input value
 * @param key name of the input field
 * @param value Value to be validated
 * @return boolean, true if input is invalid
 *         false if input is valid
 */
export function validateInput(key, value) {
  // skip validation for following fields
  // If input is custom command for multimodal.
  // Device Type & Customized test type dropdowns always have default value selected in it,
  // they should never throw error
  const skipList = [
    'multiModalCustomCommand', 'deviceUnderTestId', 'customizedTestType',
    'deviceConfig', 'labDependenciesUri', 'namespace'
  ];
  if (skipList.includes(key)) return false;

  let valueToValidate = value;
  // Ignore Dots (.) if input field is buildInfo
  if (key === 'buildInfo') {
    valueToValidate = valueToValidate.replace(/\./g, AppConstants.EMPTY);
  }

  if (key === AppConstants.iHeartEmailFieldId || key === 'customerAccountEmail') {
    return !valueToValidate.match(/.+@.+\..+$/)
  }

  if (key === 'testName' && value.length > 30) {
    valueToValidate = true;
  }

  return (!valueToValidate || typeof valueToValidate !== 'string' || !valueToValidate.match(/^[\w-]+$/) || !valueToValidate.match(/[\w]/));
}


/**
 * Validates input value for optional fields
 * @param key name of the input field
 * @param value Value to be validated
 * @return True or false based on input is valid or not
*/
export function validateInputOptional(key, value) {
  // Device name and farScenarioId doesn't need strict validation, can skip it
  if (key === 'deviceName' || key === 'farScenarioId' || key === 'testCategory' || key === 'lockType') {
    return false;
  }
  if (!value || value === AppConstants.EMPTY) {
    return false;
  }
  return validateInput(key, value);
}

/**
  *
  * @param {string} s3FilePath A4PC device config s3 uri
  * A4PC device config filename follows this format <DSN>_<GUID>.<ext>
  * @return {boolean} Returns true if the dsn matches or false otherwise
  */
export function isValidDeviceConfig(s3FilePath, dsn) {
  // return true if there is no s3FilePath
  if (!s3FilePath) return true;

  const chunks = s3FilePath.split('/');
  const filename = chunks.pop();

  // Return false if the URI doesn't have matching DSN
  return filename.includes(dsn);
}

/**
 * Some values do not needed to be validated if not certain testSuite and scenarioType
 * eg, for non Music, iHeart, no need to validate iHeartEmailField
 * @param {*} key
 * @param {*} testSuite
 * @param {*} scenarioType
 * @return true: will skip validation
 *         false: will go through validation
 */
export function skipValidateBasedOnTestInformation(key, testSuite, scenarioType) {
  let skip = false; // by default not skip, go through validation progress
  // should skip testCategory and lockType if not Mobile Functional scenario
  if (scenarioType !== AppConstants.MOBILE_FUNCTIONAL
    && (key === 'testCategory' || key === 'lockType')) {
    skip = true;
  }
  if (key === 'spoofedLocation') {
    if (testSuite === AppConstants.FUNCTIONAL
      && (scenarioType === FunctionalTestCases.FUNC_NAV_ALL
        || scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM )) {
      skip = false;
    } else {
      skip = true;
    }
  }
  if (key === 'musiciHeartEmailAddr') {
    if (testSuite === MusicConstants.MUSIC_SCENARIO_ID
      && (scenarioType === MusicConstants.MUSIC_IHRT_CUSTOM
      || scenarioType === MusicConstants.MUSIC_IHRT_LIVE )) {
        // should not skip if it's Music iHeartRadio for iheart email address
        skip = false;
      } else {
        skip = true;
      }
  }
  return skip;
}

/**
 * Gets utterance count for current iteration for WWDD test
 * Example: 6 / 10
 * @param currentUtteranceCount Current utterance number out of 260
 * @returns Current utterance count out of 10 e.g. 6 / 10
 */
export function getUtteranceCountPerIterationWWDD(currentUtteranceCount) {
  // Do current utterance number % 10. If it's 0, that means it's 10th utterance
  // For current latency value
  let utteranceCountForIteration = parseInt(currentUtteranceCount, 10) % 10;
  if (utteranceCountForIteration === 0) {
    utteranceCountForIteration = 10;
  }
  return utteranceCountForIteration + ' / 10';
}

/**
 * Gets amazon ID to display
 * @param amazonId Amazon ID from JSON
 * @param scenarioType Scenario type
 * @returns Amazon ID to display on Test details & Live run page
 */
export function getAmazonIdToDisplay(amazonId, scenarioType) {
  // Return 'Custom' for FAR Custom type test
  if (scenarioType === AppConstants.FAR_CUSTOM) {
    return AppConstants.CUSTOM;
  }
  // For a 3P device, if amazon ID retrieved from back end is non-empty,
  // return it as it is
  if (amazonId && amazonId !== AppConstants.EMPTY
        && !AppConstants.DUT_TYPE_MAP.hasOwnProperty(amazonId)) {
    return amazonId;
  }
  // For 3P device, if amazon ID is empty, display it as 'Unavailable'
  if (amazonId === AppConstants.EMPTY) {
    return AppConstants.UNAVAILABLE;
  }
  // Return 'N/A' by default - mainly 1P devices
  return AppConstants.NOT_APPLICABLE;
}

/**
 * Gets header for Utterance container based  on test suite
 * @param testSuite Test Suite
 * @returns Header for utterance container box
 */
export function getUtteranceContainerHeader(testSuite) {
  let utteranceContinerHeader = 'Measured Utterance';
  if (testSuite && testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID) {
    // In Functional, utterance container is only applicable to Cloud validation
    utteranceContinerHeader = 'Measured Utterance (Alexa Cloud Validation)';
  }
  return utteranceContinerHeader;
}

/**
 * Method to get parsed value for JSON string
 * @param inputJSON JSON to be parsed
 * @returns Parsed JSON if it includes back slash, otherwise as it is
 */
export function getParsedJSON(inputJSON) {
  // Parse the JSON only if it's not an Object
  if (inputJSON && typeof inputJSON !== 'object') {
      return JSON.parse(inputJSON);
  }
  return inputJSON;
}

/**
 * Method to get state of RasPi for Live Run page in animated format
 * @param rasPiState State of RasPi
 * @returns Flashing text representing state of Raspi
 */
export function getAnimatedRasPiState(rasPiState) {
  if (rasPiState && rasPiState.length > 0) {
    return (
      <div className='awsui-util-label'>
        <span className='ellipsis-anim-raspi-state'>
            <span> { rasPiState } </span>
        </span>
      </div>
    )
  }
  return (
    <div></div>
  )
}

/**
 * Checks whether current state belongs to updating RasPi - In Progress
 * @param rasPiState Current state of job
 * @returns true if state belongs to updating RasPi - In progress
 */
export function isRasPiUpdateInProgress(rasPiState) {
  return rasPiState && AppConstants.RASPI_UPDATING_STATES.includes(rasPiState);
}

/**
 * Checks whether current state belongs to updating resource in RasPi - In Progress
 * @param rasPiState Current state of job
 * @returns true if state belongs to updating RasPi - In progress
 */
export function isRasPiResourceSyncUpdateInProgress(rasPiState) {
  return 'lab_resources_syncing' === rasPiState;
}

/**
 * Checks whether current state belongs to updating RasPi - Completion
 * @param rasPiState Current state of job
 * @returns true if state belongs to updating RasPi - Completion
 */
export function isRasPiUpdateCompleted(rasPiState) {
  return rasPiState && AppConstants.RASPI_UPDATED_STATES.includes(rasPiState);
}

/**
 * Method to display loading message in animated way
 * @param message Text message
 * @returns Flashing text representing message
 */
export function getLoadingMessage(message) {
  if (message && message.length > 0) {
    return (
      <div>
        <div align='center' className='awsui-util-mt-xxl'>
          <h3>
            <span className='fading-anim-loading-message'>
              { message }
            </span>
          </h3>
        </div>
        <div className='loading-data-style'>
        </div>
      </div>
    )
  }
  return (
    <div></div>
  )
}

/**
 * Helper method to log to console.
 */
export function logToConsole() {
  // Log to console only if this is not Production environment
  if(aqtStore.getState().environment
      && aqtStore.getState().environment.controllerEndpoint !== AppConstants.PROD_ENDPOINT) {
        const args = Array.prototype.slice.call(arguments);
        console.log.apply(console, args);
  }
}

/**
 * Temporary method to return list of Acoustic scenarios - will include "Functional"
 * only for BETA, ALPHA and LOCAL testing
 */
export function getAcousticFunctionalScenarios(isFarPlusAllowListed) {
  if (isAlphaEnvironment() || isLocalEnvironment()) {
    return AppConstants.ACOUSTIC_SCENARIO_TYPE_ALPHA;
  } else if (isBetaEnvironment()) {
    return AppConstants.ACOUSTIC_SCENARIO_TYPE_BETA;
  } else {
    // Display the FAR+ test if scenario is allowlisted in prod
    if (isFarPlusAllowListed) {
      return AppConstants.ACOUSTIC_SCENARIO_TYPE_PROD_ALLOWLISTED;
    }
    return AppConstants.SCENARIO_TYPE;
  }
}

/**
 * Temporary method to return list of Functional scenarios - will return "MultiModal"
 * only for ALPHA and LOCAL testing
 */
export function getFunctionalScenarios() {
  if(isAlphaEnvironment() || isLocalEnvironment() || isBetaEnvironment()) {
    return AppConstants.FUNCTIONAL_SCENARIO_TYPE_ALPHA;
  }
  return AppConstants.FUNCTIONAL_SCENARIO_TYPE;
}

/**
 * Temporary method to return list of Mobile scenarios - will include "Functional"
 * only for ALPHA and LOCAL testing
 */
export function getMobileScenarios() {
  return AppConstants.MOBILE_SCENARIO_TYPE;
}

/**
 * Method to check if current environment is LOCAL
 */
export function isLocalEnvironment() {
  return aqtStore.getState().environment
    && aqtStore.getState().environment.controllerEndpoint === AppConstants.LOCAL_ENDPOINT;
}

/**
 * Method to check if current environment is ALPHA
 */
export function isAlphaEnvironment() {
  return aqtStore.getState().environment
    && (aqtStore.getState().environment.controllerEndpoint === AppConstants.ALPHA_ENDPOINT_EAST
      || aqtStore.getState().environment.controllerEndpoint === AppConstants.ALPHA_ENDPOINT_WEST);
}

/**
 * Method to check if current environment is BETA
 */
export function isBetaEnvironment() {
  return aqtStore.getState().environment
    && aqtStore.getState().environment.controllerEndpoint === AppConstants.BETA_ENDPOINT;
}

/**
 * Method to check if current environment is PROD
 */
export function isProdEnvironment() {
  return aqtStore.getState().environment
    && aqtStore.getState().environment.controllerEndpoint === AppConstants.PROD_ENDPOINT;
}

/**
 * Gets test name to display on results page
 */
export function getTestName(options) {
  if (options && options.testName) {
    return options.testName;
  }
  return AppConstants.UNAVAILABLE;
}

/**
 * Method to determine whether number is plural or not
 */
export function pluralization(num) {
  return num === 1 ? AppConstants.EMPTY : 's';
}

/**
 * Gets Test header for Auto Local Search scenario
 */
export function getTestTypeHeaderAutoLocalSearch(scenarioType) {
  let testHeader = AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_LABEL;
  if (scenarioType) {
    let scenarioTypeDisplay = getDisplayNameForId(AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_TYPE,
      scenarioType);
    return testHeader + ' - ' + scenarioTypeDisplay;
  }
  return testHeader;
}

/**
 * Gets the informational text to display in pretty format
 * @param label Text message to be displayed
 * @param status Type of message -> Info in this case
 * @returns UI element containing informational message
*/
export function getMessagePretty(label, status='info') {
  const textStatus = 'status-' + status;
  const labelStyle = 'awsui-util-ml-l awsui-util-p-xs awsui-util-' + textStatus;
  return (
    <div align='center' className='awsui-util-mt-xxl'>
      <h3 className={ labelStyle }>
        <AWSUI.Icon className='awsui-util-mr-s' name={ textStatus } />
        { label }
      </h3>
    </div>
  );
}

/**
 * Builds user preference mapping from raspis if previously defined mapping does not exist.
 * @param lab MDX Lab object
 * @param rasPisOfLab Raspi objects of current lab
 * @returns User preference mapping JSON object.
 */
export function buildUserPreferenceObject(lab, rasPisOfLab) {
  if (lab && lab.hasOwnProperty("preference")
      && lab.preference) {
      try {
        return JSON.parse(lab.preference);
      } catch (e) {
          logToConsole("Error parsing lab preference json string, building from raspi name");
      }
  }
  let preference = {};
  let actor_mapping_preference = {};
  for (let index = 0;index < rasPisOfLab.length; index++) {
    actor_mapping_preference[rasPisOfLab[index].id] = {
      "name": rasPisOfLab[index].name.substring(rasPisOfLab[index].name.lastIndexOf('-') + 1),
      "location": AppConstants.actorMappingKeys[index]
    }
  }
  preference.actor_mapping_preference = actor_mapping_preference;
  return preference;
}

/**
 * Gets location name for RasPi
 * @param raspiData Full Raspi object
 * @param data Lab data object to which raspi belongs
 * @returns String value of raspi name. Name is pulled from preference if it exists.
 */
export function getRasPiName(raspiData, data) {
  let raspiName = raspiData.name.substring(raspiData.name.lastIndexOf('-') + 1);
  if (data && data.hasOwnProperty("preference")
      && data.preference) {
    let preference = getJsonFromString(data.preference);
    if (preference.hasOwnProperty("actor_mapping_preference")
        && preference.actor_mapping_preference.hasOwnProperty(raspiData.id)) {
      raspiName = preference.actor_mapping_preference[raspiData.id].name;
    }
  }
  return raspiName;
}

/**
 * Gets raspi location name. If preference mapping exists it is used else index on location map is used.
 * @param raspiData Raspi object retrieved from controller with details of current raspi.
 * @param index Index of current raspi in the list of current lab raspis
 * @param locationMap Static Map of locations. varies depending on scenario(Music/Acoustic/Closetalk)
 * @param data Current lab data object retrieved from controller, contains added preference mapping built on load.
 * @returns String value of raspi location.
 */
export function getRasPiLoc(raspiData, index, locationMap, data) {
  let raspiLocationName = AppConstants.LOCERR;
  if (locationMap[index]) {
    raspiLocationName = locationMap[index].display;
  }
  if (data && data.hasOwnProperty("preference")
      && data.preference) {
    let preference = getJsonFromString(data.preference);
    if (preference.hasOwnProperty("actor_mapping_preference")
        && preference["actor_mapping_preference"].hasOwnProperty(raspiData.id)) {
          raspiLocationName = preference["actor_mapping_preference"][raspiData.id].location;
    }
  }
  return raspiLocationName;
}

/**
 * Parse input string to JSON object. Return input if error in parsing.
 * @param jsonStrValue JSON string
 * @return JSON object of string. Returns input if it is already a JSON object or cannot be parsed.
 */
export function getJsonFromString(jsonStrValue) {
  try {
    return JSON.parse(jsonStrValue);
  } catch (e) {}
  return jsonStrValue;
}
