import * as React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConstants from '../../_Constants/AppConstants';
import CustomTestConstants from '../../_Constants/CustomTestConstants';
import { Wizard, FormField, Checkbox, ColumnLayout, FormSection, Select, Button, Input, Icon, Table,
  TableSorting, TableFiltering, Alert, Modal, Badge, ExpandableSection, Textarea, Popover } from '@amzn/awsui-components-react';
import { logToConsole, networkError } from '../../Util';
import { fetchCustomScenarios, createCustomScenario, updateCustomScenario, deleteCustomScenario } from './controller';
import IconButton from '@material-ui/core/IconButton';
import RateReviewIcon from '@material-ui/icons/RateReview';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadFile from "../../Util/FileUpload";
import Papa from 'papaparse';
import { validateTranscriptsCsv } from "./CustomTestUtils"
import * as FileSaver from "file-saver";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { validateCustomTestData, getCustomTestRecords, getPopOverContent } from './Helpers';
import { getCommonSelect } from '../NewRun/uiUtils';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import Forbidden from 'Container/ErrorPages/Forbidden';
import { getAllowList } from 'redux/actions/auth';


// TODO: OAK ENABLEMENT | Add more sample feature files and drive via s3 urls
const CUCUMBER_TEMPLATE_FILENAME = "sample-feature-template.feature";
const CUCUMBER_TEMPLATE_BLOB = new Blob(
  [
    "Feature: Custom Test Feature File\n\n",
    "@UDT\n",
    "Scenario: Custom Scenario\n",
    "  * PLAY \"What is the time now in Seattle Washington\" WITH FILENAME \"WhatIsTheTimeNow.wav\" ON \"Pi1\" AS \"P1\"\n",
    "  * WAIT FOR 10 SECONDS\n",
    "  * EXPECT ASR \"What.*time.*Seattle Washington.*\" AND RESPONSE \"(?=.*(time|[0-9]{1,2}:[0-9]{1,2}).*)(?=.*seattle.*washington.*)\" ON \"Dut1\" REF \"P1\" IGNORE FAILURE\n",
    "  * PLAY \"Play Katy Perry on Deezer\" WITH FILENAME \"PlayKatyPerryOnDeezer.wav\" ON \"Pi1\" AS \"P1\"\n",
    "  * WAIT FOR 10 SECONDS\n",
    "  * EXPECT ASR \".*Katy Perry on Deezer\" AND RESPONSE \".*Katy Perry.*Deezer.*\" ON \"Dut1\" REF \"P1\" IGNORE FAILURE\n",
  ],
  { type: "text/csv;charset=utf-8" },
);


const TEMPLATE_FILENAME = "sample-userDefinedTest-template.csv";
const TEMPLATE_BLOB = new Blob(
  [
    "asrText,FileName,asrExpected,ttsResponseExpected,location,volumeLevel,delayBetweenUtterances\n",
    "\"What is the time now\",WhatIsTheTimeNow.wav,\"What,time\",\"Seattle,Washington\",1,70,10\n",
    "\"Play Katy Perry on Deezer\",PlayKatyPerryOnDeezer.wav\n"
  ],
  { type: "text/csv;charset=utf-8" },
);

const templatesButton = (
    <FormField
      id="csvTemplateDownload"
      label= "Template file"
      description="Download this sample CSV file & Feature file containing the template"
    >
      <Button
        id="templateDownloadButton"
        icon="download"
        formAction="none"
        onClick={() => {
          FileSaver.saveAs(TEMPLATE_BLOB, TEMPLATE_FILENAME);
          FileSaver.saveAs(CUCUMBER_TEMPLATE_BLOB, CUCUMBER_TEMPLATE_FILENAME);
        }}
      >
        Download CSV & Feature file Templates
      </Button>
    </FormField>
  );

class CustomTests extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      viewOrEditCustomTestInProgress: false,
      customTestWizard: {
        id: '',
        name: '',
        actorCount: CustomTestConstants.CUSTOM_TEST_RASPI_CONFIGS[0].id,
        dutCount: CustomTestConstants.CUSTOM_TEST_DUT_OPTIONS[0].id,
        dutType: CustomTestConstants.CUSTOM_TEST_DUT_TYPES[0].id,
        autoSynthesizeAudio: false,
        delayBetweenUtterances: "10",
        noiseFile: "",
        speechLocationPi: CustomTestConstants.CUSTOM_TEST_RASPI_NAMES[0].id,
        recordingLocationPi: AppConstants.EMPTY,
        noiseLocationPi: AppConstants.EMPTY,
        marketPlace: AppConstants.EMPTY,
        wwDelay: "700",
        wakeWord: "Alexa"
      },
      customTestWizardAlert: {
        header: '',
        content: '',
        type: ''
      },
      customScenarios: {},
      confirm: {},
      testDefinitionFile: undefined,
      audioFile: undefined,
      testDefinitionGetUrl: undefined,
      audioFilesGetUrl: undefined,
      transcriptValidationResult: undefined,
      validationResults: {}
    };
    this.handleCSVFileUpload = this.handleCSVFileUpload.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleAudioFileUpload = this.handleAudioFileUpload.bind(this);

    this.handleReset = this.handleReset.bind(this);
  }
  hiddenFileInput = React.createRef();

  _mounted = false;

  componentDidMount() {
    this._mounted = true;
    this.props.getAllowList();
    // TO-DO - Call this method only if Custom test is allowlisted
    this.initCustomTestWizardDefaults();
    this.getAllCustomScenarios();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleCSVFileUpload(chosenFile) {
    this.setState({testDefinitionFile: chosenFile});
    Papa.parse(chosenFile, {
      complete: this.handleParseComplete,
      error: this.handleParseError,
      skipEmptyLines: true,
      header: false,
      quoteChar: '"',
    });
  }

  handleAudioFileUpload(chosenFile) {
    this.setState({audioFile: chosenFile});
  }

  handleParseComplete(parseResult, file) {
    try {
      const validationResults = validateTranscriptsCsv(parseResult, file);
      this.setState({transcriptValidationResult: validationResults});
    } catch (e) {
      this.handleParseError(
        {
          message: e.message,
          code: e.errorCode,
          type: e.errorCode,
          row: e.line,
        },
        file,
      );
    }
  }

  handleParseError = (parseError, file) => {
    logToConsole("Error parsing the file => " + JSON.stringify(parseError))
    this.setState({ validationResult: {isSuccess: false}, errorMessage: parseError.message });
  }

  getCSVFileValidationStatus = () => {
    const { testDefinitionFile, transcriptValidationResult } = this.state;
    return (
      (testDefinitionFile && transcriptValidationResult) &&
      (! transcriptValidationResult.isSuccess && (
        <div>
          <div>
            <Badge color="red">
            &nbsp;
            {this.state.testDefinitionFile.name + " has errors"}
            </Badge>
          </div>
          <ExpandableSection
            header="Validation Errors"
          >
            <Textarea readOnly={true}
                      value={ `${transcriptValidationResult.errorMessage.map(
                        ({ line, errorType }) => `Line No: ${line} - ${errorType}`).join("\n")}\n`}
              rows={Math.min(transcriptValidationResult.data.length + 1, 50)}
            />
          </ExpandableSection>
        </div>
      ))
    );
  }

  handleReset = () => {
    this.setState({ testDefinitionFile: undefined, transcriptValidationResult: undefined});
  };

  encodeWakeWord = (wakeWord) => {
    // Encode the wake word to byte array so that unicode chracters are also sent correctly in the request
    // sent to back end. For instance, wake word "Alexa" would be encoded to byte array [65,108,101,120,97]
    let bytes = [];
    // Return empty byte array if wakeword is null or empty
    if (!wakeWord || wakeWord.length == 0) {
      return bytes;
    }
    try {
      const toBytes = (string) => Array.from(Buffer.from(string, 'utf8'));
      // Remove leading and trailing whitespaces in the wakeword, if any.
      const wwTrimmed = wakeWord.trim();
      bytes = toBytes(wwTrimmed);
      // Convert unsigned byte array to signed bytes in the range -128 to 127. For example, unsigned byte encoded value for 
      // unicode character 'é' is 195, however it needs to be converted to signed value 195 - 256 which is -61
      bytes = bytes.map(n => n > 127 ? n - 256 : n);
    } catch (error) {
      logToConsole("TestLog: Error converting wake word to byte array");
    }
    return bytes;
  }

  decodeWakeWord = (wakeWord) => {
    // This method is needed to render correct wake-word in UI text field especially while "editing" already executed custom
    // test scenario. This is to render "alexa" correctly for example instead of rendering byte array [65,108,101,120,97]
    let decodedWakeWord = '';
    // Return empty wake word if encoded wake word is null or empty
    if (!wakeWord || wakeWord.length == 0) {
      return decodedWakeWord;
    }
    // Only if wakeword contains angeled braces ([ and ]) that means it needs to be decoded back to correct value. So, in case
    // UI already receives decoded wake word, skip rest of the logic
    if (!wakeWord.includes('[') && !wakeWord.includes(']')) {
      return wakeWord;
    }
    // Decode the wake word and return decoded value
    try {
      const wakeWordBytes = wakeWord.slice(1).slice(0,-1).split(',')
      const decoder = new TextDecoder('utf-8');
      decodedWakeWord = decoder.decode(new Uint8Array((wakeWordBytes)));
    } catch (error) {
      logToConsole("TestLog: Error decoding wake word, will return empty by default");
    }
    return decodedWakeWord;
  }

  initCustomTestWizardDefaults = (viewOrEditCustomTest) => {
    let customTestConfig = undefined;
    try {
      if (viewOrEditCustomTest) {
        customTestConfig = JSON.parse(viewOrEditCustomTest.config);
      }
    } catch (error) {
      logToConsole("Error parsing json");
    }

    this.setState({customTestWizard: {
      id: viewOrEditCustomTest ? viewOrEditCustomTest.id : '',
      name: viewOrEditCustomTest ? viewOrEditCustomTest.name : '',
      actorCount: customTestConfig ? customTestConfig.actorCount : CustomTestConstants.CUSTOM_TEST_RASPI_CONFIGS[0].id,
      dutCount: customTestConfig ? customTestConfig.dutCount : CustomTestConstants.CUSTOM_TEST_DUT_OPTIONS[0].id,
      dutType: customTestConfig ? customTestConfig.dutType : CustomTestConstants.CUSTOM_TEST_DUT_TYPES[0].id,
      autoSynthesizeAudio: customTestConfig ? customTestConfig.autoSynthesizeAudio : false,
      delayBetweenUtterances: customTestConfig ? customTestConfig.delayBetweenUtterances : "10",
      noiseFile: customTestConfig ? customTestConfig.noiseFile : "",
      speechLocationPi: customTestConfig ? customTestConfig.speechLocationPi : CustomTestConstants.CUSTOM_TEST_RASPI_NAMES[0].id,
      recordingLocationPi: customTestConfig ? customTestConfig.recordingLocationPi : AppConstants.EMPTY,
      noiseLocationPi : customTestConfig ? customTestConfig.noiseLocationPi : AppConstants.EMPTY,
      marketPlace: customTestConfig ? customTestConfig.marketPlace : AppConstants.EMPTY,
      wwDelay: customTestConfig ? customTestConfig.wwDelay.toString() : "700",
      wakeWord: customTestConfig ? this.decodeWakeWord(customTestConfig.wakeWord) : "Alexa",
    },
    testDefinitionGetUrl: viewOrEditCustomTest ? viewOrEditCustomTest.testDefinitionGetUrl : undefined,
    audioFilesGetUrl: viewOrEditCustomTest ? viewOrEditCustomTest.audioFilesGetUrl : undefined,
    customTestWizardAlert: {
      header: '',
      content: '',
      type: ''
    }
  });
  }

  /**
   * Gets all custom tests via controller API
   */
  getAllCustomScenarios = () => {
    this.setState({ customScenarios: {
        ...this.state.customScenarios,
        loading: true
      }
    });
    fetchCustomScenarios().then(customScenarioPromises => {
      if (!customScenarioPromises.hasOwnProperty('error')) {
        Promise.all(customScenarioPromises)
          .then(customScenarioRecords => {
            logToConsole("Fetched custom scenarios");
            logToConsole(customScenarioRecords);
            let customScenarios = {};
            customScenarioRecords.forEach(customTest => {
              customScenarios[customTest.id] = customTest;
            })
            this.setState({
              customScenarios: {
                loading: false,
                data: customScenarios,
                error: { isError: false, message: null }
            }});
          })
          .catch(error => {
            logToConsole('Error loading custom scenarios : ' + error);
            this.setState({
              customScenarios: {
                loading: false,
                data: {},
                error: { isError: true, message: 'Error loading custom tests' }
            }});
            networkError(error, this.getAllCustomScenarios.name);
          });
      } else {
        this.setState({
          customScenarios: {
            loading: false,
            data: {},
            error: { isError: true, message: customScenarioPromises.error }
        }});
      }
    });

  }

  /**
   * Initializes wizard with custom test under edit.
   * @param {object} customTestTarget
   * @param {object} customTestData
   */
  editCustomTestInWizard = (customTestTarget, customTestData) => {
    logToConsole("Editing custom test..");
    logToConsole(customTestData);
    this.setState({
      viewOrEditCustomTestInProgress: true,
    })
    this.initCustomTestWizardDefaults(customTestData);
  }

  /**
   * Returns edit button for custom test
   * @param {object} customTestData
   */
  viewOrEditButton = (customTestData) => {
    return (
      <IconButton color='default' variant='fab' aria-label='download'

        onClick={ (event) => {
          this.editCustomTestInWizard(event.currentTarget, customTestData);
        }}
      >
        <RateReviewIcon />
      </IconButton>
    );
  }

  /**
   * Returns logs download button for custom test
   * @param {object} customTestData
   */
  logsDownloadButton = (customTestData) => {
    return (
      <IconButton color='default' variant='fab' aria-label='download'
        disabled={customTestData.validationResultGetUrl == undefined}
        onClick={ (event) => {
                if (customTestData.validationResultGetUrl) {
                  window.open(customTestData.validationResultGetUrl);
                }
              }}
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  }

  /**
   * Returns validation result json
   * @param {object} customTestData Custom test data
   */
  getSummaryDetails = (customTestData) => {
    if (customTestData && customTestData.validationResultGetUrl) {
      axios.get(customTestData.validationResultGetUrl)
      .then(response => {
        console.log("validation result", response.data);
        if (response.data) {
          let validationResultJson = response.data;
          this.setState({
            validationResults : {
              ...this.state.validationResults,
              [customTestData.id]: {
                isLoading: false,
                validationDetails: validationResultJson
              }
            }
          });
        }
      }, error => {
        console.log("ERROR getting validation result from get url", error);
        this.setState({
          validationResults : {
            ...this.state.validationResults,
            [customTestData.id]: {
              isLoading: true,
              validationDetails: {}
            }
          }
        });
      });
    }
  }

  /**
   * Returns summary button for custom test
   * @param {object} customTestData
   */
  summaryButton = (customTestData) => {
    if (!this.state.validationResults.hasOwnProperty(customTestData.id)) {
      this.setState({
        validationResults : {
          ...this.state.validationResults,
          [customTestData.id]: {
            isLoading: true,
            validationDetails: {}
          }
        }
      });
    }

    return (
      <Popover
      dismissButton={false}
        position="top"
        size="large"
        triggerType="custom"
        content={ getPopOverContent(customTestData, this.state.validationResults) }
      >
        <IconButton color='default' variant='fab' aria-label='download'
         onClick={ () => {
          this.getSummaryDetails(customTestData);
        }}
      >
        <VisibilityIcon />
      </IconButton>
      </Popover>
    );
  }

  /**
   * Performs delete custom test with pop up to confirm deletion.
   * @param {object} customTestTarget
   * @param {object} customTestData
   */
  deleteCustomTest = (customTestTarget, customTestData) => {
    logToConsole("Deleting custom test..");
    logToConsole(customTestData);
    this.setState({
      confirm: {
       pop: true,
       title: 'Confirm Remove',
       message: 'Do you want to delete the test ?',
       callback: (action) => {
         if (action) {
          deleteCustomScenario(customTestData.id).then(customScenarioDeleteResponse => {
            if (!customScenarioDeleteResponse.hasOwnProperty('error')) {
              this.initCustomTestWizardDefaults();
              this.getAllCustomScenarios();
              logToConsole("Deleted custom scenario");
              logToConsole(customScenarioDeleteResponse);
            } else {
              logToConsole('Error deleting custom scenarios : ' + customScenarioDeleteResponse);
              this.setState({
                confirm: {
                  pop: true,
                  title: 'Remove Status',
                  message: 'Removing test failed. ',
                  callback: () => {
                    this.initCustomTestWizardDefaults();
                    this.getAllCustomScenarios();
                  }
                }
              });
            }
          });
         }
       }
     }
   });
  }

  /**
   * Returns a delete custom test button
   * @param {object} customTestData
   */
  deleteCustomTestButton = (customTestData) => {
    return (
      <IconButton color='default' variant='fab' aria-label='download'

        onClick={ (event) => {
          this.deleteCustomTest(event.currentTarget, customTestData);
        }}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  /**
   * Handles custom test wizard cancel
   */
  handleCustomTestCancel = () => {
    this.setState({
      viewOrEditCustomTestInProgress: false,
      testDefinitionFile: undefined,
      audioFile: undefined
    })
    this.initCustomTestWizardDefaults();
    this.getAllCustomScenarios();
  }

  /**
   * Gets confirmation pop up dialog
   */
  getConfirmation() {
    return (
      <Modal
        content={ this.state.confirm.message }
        visible={ this.state.confirm.pop }
        header={ this.state.confirm.title }
        expandToFit={ true }
        footer={ <span className='awsui-util-f-r'>
            <Button variant='link' text='Cancel'
              onClick={ () => { this.state.confirm.callback(false); this.setState({ confirm: {} }); } }>
            </Button>
            <Button variant='primary' text='Ok'
              onClick={ () => { this.state.confirm.callback(true); this.setState({ confirm: {} }); } }>
            </Button>
          </span> }
      ></Modal>
    )
  }

  /**
   * Handles custom test wizard data submission/save
   */
  handleCustomTestSubmit = () => {

    // Encode the wake word to bytes array (string representation) before submitting the request to back end
    const wwBytes = JSON.stringify(this.encodeWakeWord(this.state.customTestWizard.wakeWord));
    // Update the state to reflect the encoded wake word
    this.setState({ customTestWizard: {...this.state.customTestWizard, wakeWord: wwBytes }});

    let postBody = {
      name: this.state.customTestWizard.name,
      config: JSON.stringify(this.state.customTestWizard)
    }

    let customTestValidationResult = validateCustomTestData(this.state);
    this.setState({
      customTestWizardAlert: customTestValidationResult.customTestWizardAlert
      })

    if (customTestValidationResult && customTestValidationResult.isValid) {
      if (this.state.customTestWizard.id.length > 0) {
        updateCustomScenario(postBody, this.state.customTestWizard.id, this.state.testDefinitionFile, this.state.audioFile).then(customScenarioUpdateResponse => {
          if (!customScenarioUpdateResponse.hasOwnProperty('error')) {
            logToConsole("Created custom scenario");
            logToConsole(customScenarioUpdateResponse);
            this.setState({
              customTestWizardAlert: {
                header: 'User Defined Test Update',
                content: 'Successfully updated!',
                type: 'success'
                }
              })
          } else {
            logToConsole('Error updating custom scenarios : ' + customScenarioUpdateResponse);
            this.setState({
              customTestWizardAlert: {
                header: 'User Defined Test Update',
                content: 'Unable to update this test at the moment.',
                type: 'error'
                }
              })
          }
        })
        .then(this.handleCustomTestCancel());
      } else {
        logToConsole( this.state.testDefinitionFile);
        createCustomScenario(postBody, this.state.testDefinitionFile, this.state.audioFile).then(customScenarioCreateResponse => {
          if (!customScenarioCreateResponse.hasOwnProperty('error')) {
            logToConsole("Updated custom scenario");
            logToConsole(customScenarioCreateResponse);
            this.setState({
              customTestWizardAlert: {
                header: 'User Defined Test Creation',
                content: 'Successfully created!.',
                type: 'success'
                }
              })
          } else {
            logToConsole('Error creating custom scenarios : ' + customScenarioCreateResponse);
            this.setState({
              customTestWizardAlert: {
                header: 'User Defined Test Creation',
                content: 'Unable to create user defined test!',
                type: 'error'
                }
              })
          }
        })
        .then(this.handleCustomTestCancel());
      }
    }
  }

  onChangeSelectLocale = (id, event) => {
    this.setState({ customTestWizard: {...this.state.customTestWizard, marketPlace: event.detail.selectedOption.id }})
  }

  getLocaleSelect = (selected, shouldDisable = false, selectProps = {}) => {
    let options = AppConstants.MARKETPLACE;
    if (this.state.customTestWizard.autoSynthesizeAudio) {
      options = AppConstants.MARKETPLACE_CUSTOM_POLLY;
    }
    let errorText = this.state.customTestWizard.marketPlace ? AppConstants.EMPTY : 'Please select locale';
    return getCommonSelect(AppConstants.LOCALE_ID, AppConstants.LOCALE_LABEL,
      'Locale to be tested', options, selected, shouldDisable, errorText, this.onChangeSelectLocale, null, selectProps);
  }

  /**
   * If file object is present use file name from it.
   * Otherwise extract file name from s3 signed URL.
   * @param {object} uploadedFile File upload object.
   * @param {string} downloadUrl AWS S3 Signed URL
   */
  getUploadFileName = (uploadedFile, downloadUrl) => {
    let fileName;

    if (uploadedFile && uploadedFile.name) {
      fileName = uploadedFile.name;
    } else {
      try {
        if (downloadUrl 
          && (downloadUrl.includes(".csv") || downloadUrl.includes(".zip"))) {
            let extension = downloadUrl.includes(".csv") ? ".csv" : ".zip";
            let pathTillExtn = downloadUrl.substring(0, downloadUrl.lastIndexOf(extension));
            let fileWithTimeStamp = pathTillExtn
              .substring(pathTillExtn.lastIndexOf("/") + 1, pathTillExtn.length);
            let file = fileWithTimeStamp.substring(fileWithTimeStamp.indexOf("_") + 1, fileWithTimeStamp.length);
            fileName = file + extension;
        }
      } catch(ex) {
        logToConsole(`Error extracting filename from url ${downloadUrl}`, ex);
        fileName = "Contents";
      }
    }

    return fileName;
  }

  /**
   * Returns custom test wizard -- STEP - 1;
   */
  getCustomTestConfigurationStep = () => {
    const configuration = (
      <FormSection
        header="Test Configuration"
        content={
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField required={true} label="Scenario Name" description="Provide a name for the Custom scenario" hintText="Test will be available in the scenario drop down in the New Run page during Test execution" errorText={this.state.customTestWizard.name === AppConstants.EMPTY ? 'Test name is required' : ''}>
                <Input value={this.state.customTestWizard.name} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, name: event.detail.value }})}></Input>
              </FormField>
            </div>
            <div data-awsui-column-layout-root="true">
              <FormField label="DUT Type" description="Select the type of DUT required for the test">
                <Select options={CustomTestConstants.CUSTOM_TEST_DUT_TYPES} selectedId={this.state.customTestWizard.dutType} selectedLabel="Selected" onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, dutType: event.detail.selectedOption.id }})}></Select>
              </FormField>
            </div>
          </ColumnLayout>
        } >
      </FormSection>
    );
    return configuration;
  }

/**
 * Returns custom test wizard -- STEP - 2;
 */
  getCustomTestImportDataStep = () => {

    let raspiItems = [];
    for ( let i = 0; i < this.state.customTestWizard.actorCount; i++) {
      raspiItems.push(CustomTestConstants.CUSTOM_TEST_RASPI_NAMES[i]);
    }

    const importdata = (
      <FormSection
        header="Import Data"
        content={
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <div>
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <div className="awsui-util-label">Scenario Name</div>
                      <div className="awsui-util-status-positive">
                        <Icon name="status-positive" /> {this.state.customTestWizard.name}
                      </div>
                    </div>
                  </div>
                </div>
              </FormField>

              <UploadFile id="transcriptFileUpload" name="Transcript File upload" label="File Selection"
                description="Import the CSV file or Feature file containing the test definition sequence" fileType=".csv, .feature"
                          onFileUpload={this.handleCSVFileUpload} params={{
                  downloadURL: this.state.testDefinitionGetUrl,
                  fileName: this.getUploadFileName(this.state.testDefinitionFile, this.state.testDefinitionGetUrl)
                }}
              />
              {this.getCSVFileValidationStatus()}
              {templatesButton}
            </div>
          </ColumnLayout>
        } >
      </FormSection>
    );
    return importdata;
  }

  /**
   * Returns custom test wizard -- STEP - 3;
   */
  getCustomSpeechDefinitionStep = () => {

    let raspisAvailable = [];
    for ( let i = 0; i < this.state.customTestWizard.actorCount; i++) {
      raspisAvailable.push(CustomTestConstants.CUSTOM_TEST_RASPI_NAMES[i]);
    }

    const speechDefinition = (
      <FormSection
        header="Speech Options"
        content={
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label="Synthesize Utterances" description="Select this option if the Audio files need to be generated automatically or if custom wake word audio file needs to be uploaded" hintText="Utterance audio files need not be uploaded in the Audio Upload step if this option is selected">
                <Checkbox checked={this.state.customTestWizard.autoSynthesizeAudio} onChange={event => this.setState({audioFilesGetUrl:'', customTestWizard: {...this.state.customTestWizard, autoSynthesizeAudio: event.detail.checked, marketPlace: AppConstants.EMPTY}})} > Auto Synthesize Audio </Checkbox>
              </FormField>
              { this.getLocaleSelect(this.state.customTestWizard.marketPlace) }
              <FormField label="Wake word" description="Wake word to use for auto synthesized audio" hintText="Required for auto synthesized test utterances">
                <Input value={this.state.customTestWizard.wakeWord} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, wakeWord: event.detail.value }})} disabled={ !this.state.customTestWizard.autoSynthesizeAudio }></Input>
              </FormField>
              <FormField label="Wake word delay" description="Delay between wake word and test utterance in milli seconds" hintText="Example: Alexa <wake word delay> what's the time in London?">
                <Input value={this.state.customTestWizard.wwDelay} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, wwDelay: event.detail.value }})} disabled={ !this.state.customTestWizard.autoSynthesizeAudio }></Input>
              </FormField>
              <UploadFile id="audioFileUpload" name="Transcript File upload" label="Audio Archive"
                          description="Select the compressed .zip file containing the required audio files or custom wake word file in .wav format. File names needs to be in English and wake word audio file name needs to be in the format locale_ww_*.wav (for example en_US_ww_customaudio.wav)"
                          fileType="application/zip"
                          onFileUpload= {this.handleAudioFileUpload}
                          hintText="Upload can be skipped if auto utterances generation was selected or utterance file names column is not provided in the imported definition"
                          params={{downloadURL: this.state.audioFilesGetUrl,
                            fileName: this.getUploadFileName(this.state.audioFile, this.state.audioFilesGetUrl)}}
              />
            </div>
          </ColumnLayout>
        } >
      </FormSection>
    );
    return speechDefinition;
  }

  /**
   * Returns custom test wizard -- STEP - 4;
   */
  getCustomTestLocationSetup = () => {

    let raspisAvailable = [];
    for ( let i = 0; i < this.state.customTestWizard.actorCount; i++) {
      raspisAvailable.push(CustomTestConstants.CUSTOM_TEST_RASPI_NAMES[i]);
    }

    const locationsDefinition = (
      <FormSection
        header="Speaker Locations"
        content={
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label="Speech Speaker" description="Select this option to indicate speech speaker" hintText="Speech speaker will play all the utterances in sequence">
                <Select options={raspisAvailable} selectedId={this.state.customTestWizard.speechLocationPi} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, speechLocationPi: event.detail.selectedOption.id }})} selectedLabel="Selected"></Select>
              </FormField>
              <FormField label="Recording Pi" description="Select this option to indicate raspi with reference mic to record test" hintText="Record Pi will have the reference Mic connected that will be used to record the interaction"
                secondaryControl={<Button icon="refresh" onClick={ () => { this.setState({ customTestWizard: {...this.state.customTestWizard, recordingLocationPi: AppConstants.EMPTY }})}}/>}>
                <Select options={raspisAvailable} selectedId={this.state.customTestWizard.recordingLocationPi} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, recordingLocationPi: event.detail.selectedOption.id }})} placeholder={AppConstants.EMPTY}></Select>
              </FormField>
              <FormField label="Noise Speaker" description="Select this option to indicate noise speaker" hintText="Noise speaker will play the below noise file for all test utterances"
                secondaryControl={<Button icon="refresh" onClick={ () => { this.setState({ customTestWizard: {...this.state.customTestWizard, noiseLocationPi: AppConstants.EMPTY }})}} />}>
                <Select options={raspisAvailable} selectedId={this.state.customTestWizard.noiseLocationPi}
                  onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, noiseLocationPi: event.detail.selectedOption.id }})}
                  disabled={ this.state.customTestWizard.actorCount < 2} placeholder={AppConstants.EMPTY}>
                </Select>
              </FormField>
              <FormField label="Ambient Noise File" description="Noise configuration for the test environment" hintText="Requires minimum 2 pi config as Noise speaker cannot be used as Speech speaker">
                <Input value={this.state.customTestWizard.noiseFile} onChange={event => this.setState({ customTestWizard: {...this.state.customTestWizard, noiseFile: event.detail.value }})} disabled={ this.state.customTestWizard.actorCount < 2}></Input>
              </FormField>
            </div>
          </ColumnLayout>
        } >
      </FormSection>
    );
    return locationsDefinition;
  }

  /**
   * Returns array containing steps of custom test wizard.
   */
  getCustomTestWizardSteps = () => {
    const steps = [
      {
        title: 'Test Setup',
        content: this.getCustomTestConfigurationStep()
      },
      {
        title: 'Import Data',
        content: this.getCustomTestImportDataStep()
      },
      {
        title: 'Audio Defintion',
        content: this.getCustomSpeechDefinitionStep()
      },
    ];
    return steps;
  }

  render() {
    const { allowList, loadingAllowList } = this.props.auth;

    if (loadingAllowList) {
      return (
        <div align='center'>
          <CircularProgress size={50} />
        </div>
      );
    }

    if (allowList) {
      const containsCustomScenarioId = (element) => element.name === AppConstants.CUSTOM_SCENARIO_JAR_NAME;
      if (allowList.findIndex(containsCustomScenarioId) == -1) {
        return <Forbidden/>
      }
    }

    const i18nStrings = {
      stepNumberLabel: stepNumber => `Step ${stepNumber}`,
      collapsedStepsLabel: (stepNumber, stepsCount) =>
        `Step ${stepNumber} of ${stepsCount}`,
      cancelButton: 'Close',
      previousButton: 'Previous',
      nextButton: 'Next',
      submitButton: 'Save',
      optional: 'Optional'
    };

    let customScenarioRecords = getCustomTestRecords(this.state.customScenarios, this.viewOrEditButton, this.deleteCustomTestButton, this.summaryButton, this.logsDownloadButton);

    return (
      <div >
        { this.state.viewOrEditCustomTestInProgress ? (
          <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <h2>User Defined Test Wizard</h2>
          </div>
          {
            this.state.customTestWizardAlert.header && this.state.customTestWizardAlert.content && this.state.customTestWizardAlert.type &&
            (<div>
              <Alert
                header={ this.state.customTestWizardAlert.header }
                content={ this.state.customTestWizardAlert.content }
                type={ this.state.customTestWizardAlert.type }
              ></Alert>
            </div>)
          }
          <div>
            <Wizard steps={this.getCustomTestWizardSteps()} i18nStrings={i18nStrings} onSubmitButtonClick={this.handleCustomTestSubmit} onCancelButtonClick={this.handleCustomTestCancel}></Wizard>
          </div>
        </div>
        ) : (
          <div>
            <div>
              <Button icon="edit" variant='primary' text="Create User Defined Test"
                  onClick={ () => {
                    this.setState({viewOrEditCustomTestInProgress: true});
                    this.initCustomTestWizardDefaults();
                }}/>
              </div>
              <div>
              <Table className='awsui-util-mt-l'
              columnDefinitions={ CustomTestConstants.CUSTOM_TEST_COLUMNS }
              items={ customScenarioRecords }
              >
                <TableSorting sortableColumns={ CustomTestConstants.CUSTOM_TEST_SORTABLE_COLUMNS }/>
                    <TableFiltering
                      filteringPlaceholder='Search Custom Tests'
                    />
              </Table>
            </div>
            <div>
              { this.state.confirm.pop && (
                this.getConfirmation()
              )}
            </div>
          </div>

        )}
      </div>
    );
  }

}

export default connect(({ auth }) => ({ auth }), {
  getAllowList
})(CustomTests);
