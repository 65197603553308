import constants from '../constants/labs';

const initialState = {
  labs: {},
  loading: false,
  calibration: null,
  labToThingsMapping: {},
  isCalibrationInProgress: false,
  error: null
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let response = payload;
  if(typeof(payload) === "string") {
    try {
      response = JSON.parse(payload)
    } catch (e) {
      response = {};
    }
  }
  switch (type) {
    case constants.EXECUTE_ACTION_ON_THING_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
        isCalibrationInProgress: true
      };
    case constants.EXECUTE_ACTION_ON_THING_SUCCESS:

      return {
        ...state,
        loading: false,
        calibration: response,
        isCalibrationInProgress: false
      };
    case constants.EXECUTE_ACTION_ON_THING_FAILURE:
      return {
        ...state,
        loading: false,
        error: response,
        isCalibrationInProgress: false
      }
    case constants.FETCH_THINGS_FOR_LAB_PENDING:
      return {
        ...state,
        loading: true,
        error: null
      }
    case constants.FETCH_THINGS_FOR_LAB_SUCCESS:
      const thing = response && response.length > 0 ? response[0] : {};
      return {
        ...state,
        loading: false,
        labToThingsMapping: {
          ...state.labToThingsMapping,
          [thing.labId]: response
        }
      }
    case constants.FETCH_THINGS_FOR_LAB_FAILURE:
      return {
        ...state,
        loading: false,
        error: response
      }
    default:
      return state;
  }
};

export default reducer;
