import constants from '../constants/customTest';
import apiUtils from 'config/apiUtils';

/**
 * Fetch custom scenarios based on the filtering condition
 * @param {object} query Filter custom scenarios by sending query params
 * @param {number} limit Scenarios to fetch
 * @param {number} skip Number of Scenarios to skip while fetching
 */
export function fetchCustomScenarios(query = {}, limit = 20, skip = 0) {
  return (dispatch) => {
    const url = apiUtils.customScenarios();

    dispatch({
      type: [
        constants.FETCH_CUSTOM_SCENARIOS_PENDING,
        constants.FETCH_CUSTOM_SCENARIOS_SUCCESS,
        constants.FETCH_CUSTOM_SCENARIOS_FAILURE
      ],
      meta: {
        url,
        method: 'get',
        params: {
          q: JSON.stringify(query),
          limit,
          skip
        }
      }
    });
  }
}

export default {
  fetchCustomScenarios
}
