import React from 'react';
import './style.css'
import { logToConsole } from '../../Util';

const KEYS_FOR_DISPLAY = {
  'version': 'Version',
  'date': 'Date',
  'features': 'New Features',
  'fixes': 'Bug Fixes and Enhancements',
  'acoustic': 'Acoustic',
  'mobile': 'Mobile',
  'music': 'Music',
  'closetalk': 'Close Talk',
  'functional': 'Functional',
  'security': 'Security',
  'general': 'General',
  'miscellaneous': 'Miscellaneous'
}

const HISTORY_KEYS_FOR_DISPLAY = {
  'features': 'New Features',
  'fixes': 'Bug Fixes and Enhancements',
  'acoustic': 'Acoustic',
  'mobile': 'Mobile',
  'music': 'Music',
  'closetalk': 'Close Talk',
  'functional': 'Functional',
  'security': 'Security',
  'general': 'General',
  'miscellaneous': 'Miscellaneous'
}

const SPECIAL_NOTE_ITEM_KEY='Note:';

function isSpecialNote(key) {
  if (key == null) {
    return false;
  }
  let endIndex = SPECIAL_NOTE_ITEM_KEY.length;
  let trimmedFromKeyForComparison = key.trim().substring(0,endIndex);
  return trimmedFromKeyForComparison == SPECIAL_NOTE_ITEM_KEY;
}

function putNotesInfront(obj) {
  // put notes section in front
  // deal with the issue that dynamoDB object keys are automatically sorted
  if (obj == null) {
    return null
  }
  if (obj.constructor != Object) {
    // function only applies to json objects
    return obj;
  }
  let newObj = {};
  // put in items with special_note_item key
  Object.keys(obj).map(key => {
      if (isSpecialNote(key)) {
        newObj[key] = obj[key];
      }
  })
  // put in items with other keys
  Object.keys(obj).map(key => {
    if (!isSpecialNote(key)) {
      newObj[key] = obj[key];
    }
  })
  return newObj;
}

/**
   * Method to display release notes in Release Notes tab in UI in proper formatted way
   */
export function prettyPrintReleaseNotes(releaseNotesObj, majorVersion) {
  if (!releaseNotesObj) {
    return (
      <div>
      </div>
    )
  }

  if (releaseNotesObj.constructor === String) {
    return (
      <div>
        {releaseNotesObj}
      </div>
    )
  }
  releaseNotesObj = putNotesInfront(releaseNotesObj);
  return (
    <div>
      {releaseNotesObj.constructor !== Array ? (
        Object.keys(releaseNotesObj).map(key => {
          let processedKey = key.toLowerCase();
          let keyToDisplay = "";
          var displayValue = KEYS_FOR_DISPLAY.hasOwnProperty(processedKey);
          if (displayValue) {
            keyToDisplay = KEYS_FOR_DISPLAY[processedKey];
          } else {
            keyToDisplay = key;
          }
          // Display special Key
          if (isSpecialNote(key)) {
            keyToDisplay = key;
            displayValue = true;
          }
          return (
            <div key={key}>
              {
                (
                  <div>
                    {majorVersion ? (
                      <h2 className='awsui-util-t-l awsui-util-mt-xl blue-color-text'>
                        {keyToDisplay}
                      </h2>
                    ) : (
                        <h4 className='awsui-util-ml-l'>
                          {keyToDisplay}
                        </h4>
                      )
                    }
                    {prettyPrintReleaseNotes(releaseNotesObj[key], false)}
                  </div>
                )
              }
            </div>
          )
        })
      ) : (
          releaseNotesObj.map((value, index) => {
            return (
              <div key={value}>
                <span className='awsui-util-ml-xxl'>
                  {(index + 1) + '.'}
                  <span className='awsui-util-ml-xs'>
                    {value}
                  </span>
                </span>
              </div>
            )
          })
        )}
    </div>
  )

}

/**
 * Method to display Single release notes content
 * @param {*} releaseNotesObj
 * @param {*} index
 */
export function showHistoryNoteItem(singleHistoryNote, majorVersion) {
  if (!singleHistoryNote) {
    return (
      <div>
      </div>
    )
  }
  logToConsole("Single Note content is ");
  logToConsole(singleHistoryNote);

  if (singleHistoryNote.constructor === String) {
    return (
      <div>
        {singleHistoryNote}
      </div>
    )
  }

  return (
    <div>
      {singleHistoryNote.constructor !== Array ? (
        Object.keys(singleHistoryNote).map(key => {
          let processedKey = key.toLowerCase();
          let keyToDisplay = "";
          var displayValue = HISTORY_KEYS_FOR_DISPLAY.hasOwnProperty(processedKey);
          if (displayValue) {
            keyToDisplay = HISTORY_KEYS_FOR_DISPLAY[processedKey];
          } else {
            keyToDisplay = key;
          }
          // Display special Key
          if (isSpecialNote(key)) {
            keyToDisplay = key;
            displayValue = true;
          }
          return (
            <div className="historycontent">
              {
                (
                  <div>
                    {majorVersion ? (
                      <h4 className='awsui-util-t-l awsui-util-mt-xl blue-color-text'>
                        {keyToDisplay}
                      </h4>
                    ) : (
                        <h4 className='awsui-util-ml-l'>
                          {keyToDisplay}
                        </h4>
                      )
                    }
                    {showHistoryNoteItem(singleHistoryNote[key], false)}
                  </div>
                )
              }
            </div>
          )
        })
      ) : (
          singleHistoryNote.map((value, index) => {
            return (
              <div key={value}>
                <span className='awsui-util-ml-xxl'>
                  {(index + 1) + '.'}
                  <span className='awsui-util-ml-xs'>
                    {value}
                  </span>
                </span>
              </div>
            )
          })
        )}
    </div>
  )

}
