/**
 * This file contains helper methods to generate mapping object
 */
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import { decodeUnicodeString } from './Util';
import { ENABLE_DSNONLINESTATUS } from './FeatureSwitch';
import { get_resources_required_by_location } from '../../Util/AQTTestOptionsParser';

/**
 * Deletes value from a device map for a particular key
 * @param devicesMap Map of DUTs
 * @param dutKey DUT to delete value from
 * @param dutFieldId Key within DUT to delete value from e.g. 'amazonId'
 * @param isInputField Type of input field -> TextField or Dropdown
 * @return Updated Device map
 */
export function deleteValueFromDevicesMap(devicesMap, dutKey, dutFieldId) {
  if (devicesMap.hasOwnProperty(dutKey)) {
    let dutValues = devicesMap[dutKey];
    if (dutValues.hasOwnProperty(dutFieldId)) {
      delete dutValues[dutFieldId];
      if (dutFieldId === 'deviceType') {
        delete dutValues['amazonId'];
      }
      if (Object.keys(dutValues).length === 0) {
        delete devicesMap[dutKey];
      } else {
        devicesMap[dutKey] = dutValues;
      }
    }
  }
  return devicesMap;
}

/**
 * Deletes a particular DUT from Device mapping
 * @param {} deviceMap Existing device mapping
 * @param {*} dutKey DUT to be deleted
 * @returns Updated Device mapping after deleting the DUT
 */
export function deleteDUTFromDevicesMap(deviceMap, dutKey) {
  if (deviceMap.hasOwnProperty(dutKey)) {
    delete deviceMap[dutKey];
  }
  return deviceMap;
}

/**
 * Get value from device map
 * @param {*} devicesMap Existing device mapping
 * @param {*} dutKey DUT whose value needs to be returned
 * @param {*} dutFieldId Key within DUT to retrieve value from e.g. 'customerId'
 * @return field value, eg 'A1W1EEC56JQI6S', customerId value
 */
export function getValueFromDevicesMap(devicesMap, dutKey, dutFieldId) {
  if (devicesMap.hasOwnProperty(dutKey)) {
    let dutValues = devicesMap[dutKey];
    if (dutValues.hasOwnProperty(dutFieldId)) {
      return dutValues[dutFieldId];
    } else {
      return AppConstants.EMPTY;
    }
  }
  return AppConstants.EMPTY;
}

/**
 * Validates deviceMapping nefore submitting the test
 * @param devicesMap Map of DUTs
 * @return Error message based on validation result
 */
export function validateDeviceMapping(devicesMap) {
  var dsnList = [];
  for (var dutKey in devicesMap) {
    var dutValues = devicesMap[dutKey];
    if (Object.keys(dutValues).length > 0) {
      if (!dutValues.hasOwnProperty('dsn')) {
        return AppConstants.MISSING_DSN_ERROR;
      }
      if (!dutValues.hasOwnProperty('buildInfo')
        || dutValues['buildInfo'] === AppConstants.EMPTY) {
        return AppConstants.MISSING_BUILD_INFO_ERROR;
      }
      let dsn = dutValues['dsn'];
      if (dsnList.includes(dsn)) {
        return AppConstants.DUPLICATE_DSN_ERROR;
      }
      dsnList.push(dsn);
    }
  }
  return AppConstants.EMPTY;
}

/**
 * Adds or Removes key-value pairs from Device mapping
 * @param devicesMap Existing device mapping
 * @param id Key to be added, removed or modified from device mapping
 * @param index Index of the optional DUT
 * @param value Value to be added, removed or updated for a particular key
 * @returns Device mapping
 */
export function generateDeviceMapping(devicesMap, id, index, value) {
  let dutKey = 'DUT_' + index;
  let dut = {};
  if (devicesMap.hasOwnProperty(dutKey)) {
    dut = devicesMap[dutKey];
    if (id === 'customerId') {
      // When customer ID is changed, reset DSN & Amazon ID as well
      if (dut.hasOwnProperty('dsn')) {
        delete dut['dsn'];
      }
      if (dut.hasOwnProperty('deviceType')) {
        delete dut['deviceType'];
      }
      if (dut.hasOwnProperty('amazonId')) {
        delete dut['amazonId'];
      }
      if (dut.hasOwnProperty('deviceName')) {
        delete dut['deviceName'];
      }
    }
  }
  if (value !== undefined && value !== AppConstants.EMPTY) {
    // Add key-value to the mapping only if value is non-empty.
    if (id === 'deviceType') {
      dut['amazonId'] = value;
    }
    dut[id] = value;
    devicesMap[dutKey] = dut;
  } else {
    // If value is empty for particular key, remove that entry from the mapping.
    devicesMap = deleteValueFromDevicesMap(devicesMap, dutKey, id);
  }
  return devicesMap;
}

/**
 * Gets DUT name in display friendly format
 * @param dutName Name of DUT to display
 * @returns Name of DUT in display friendly format
 */
export function getDUTNameDisplay(dutName) {
  return dutName && dutName !== AppConstants.EMPTY
    ? decodeUnicodeString(dutName)
    : AppConstants.UNKNOWN;
}

/**
 * Method to refresh DUT list in a dropdown to keep up-to date with free/ used DSNs when
 * scenario is applicable to multiple devices - FAR Custom
 * @param dutList DUT list for the selected DSN dropdown
 * @param index Index of the DUT
 * @param dutsInUse Current list of DUTs which are in use
 * @returns Refreshed list of DUTs based on which Devices are free/ in use
 */
export function refreshDUTList(dutList, index, dutsInUse) {
  // If the switch is enabled then use the new method that refresh the list based on online offline category
  if (ENABLE_DSNONLINESTATUS) {
    return refreshDUTListOnlineOffline(dutList, index, dutsInUse);
  } else {
    // Else use the traditional simple list
    return refreshDUTListSimple(dutList, index, dutsInUse);
  }
}

/**
 * OLD Method to refresh DUT list in a dropdown BEFORE DEVICE ONLINE OFFLINE WAS IN PLACE,
 * to keep up-to date with free/ used DSNs when
 * scenario is applicable to multiple devices - FAR Custom
 * @param dutList DUT list for the selected DSN dropdown
 * @param index Index of the DUT
 * @param dutsInUse Current list of DUTs which are in use
 * @returns Refreshed list of DUTs based on which Devices are free/ in use
 */
export function refreshDUTListSimple(dutList, index, dutsInUse) {
  if (dutList) {
    for (var i = 0; i < dutList.length; i++) {
      let dut = dutList[i];
      // Determine if DSN should be displayed as free or used in the dropdown
      dut.disabled = dut.id !== undefined && dutsInUse.includes(dut.id) &&
        dutsInUse.indexOf(dut.id) !== index ? true : false;
      let dutLabel = AppConstants.EMPTY;
      let dutName = getDUTNameDisplay(dut.name);
      if (dut.disabled) {
        dutLabel += dutName + AppConstants.DUT_IN_USE_PREFIX + dutsInUse.indexOf(dut.id) + ')';
      } else {
        dutLabel += dutName;
      }
      dut.label = dutLabel;
      dutList[i] = dut;
    }
  }
  return dutList;
}

/**
 * NEW Method to refresh DUT list in a dropdown AFTER DEVICE ONLINE OFFLINE came in,
 * to keep up-to date with free/ used DSNs when
 * scenario is applicable to multiple devices - FAR Custom
 * @param dutList DUT list for the selected DSN dropdown
 * @param index Index of the DUT
 * @param dutsInUse Current list of DUTs which are in use
 * @returns Refreshed list of DUTs based on which Devices are free/ in use
 */
export function refreshDUTListOnlineOffline(dutList, index, dutsInUse) {
  if (dutList) {
    // Scan through all categories
    for (var i = 0; i < dutList.length; i++) {
      // Scan through all duts in each category.
      let duts = dutList[i].options;
      refreshDUTListSimple(duts, index, dutsInUse);
    }
  }
  return dutList;
}

/**
 * TODO: remove it
 * Generates JSON required to submit multiple jobs at the same time
 * this one only being used for previous old music sequencing
 *
 * @param {*} selectedScenarioIds: actually refers to scenarioTypes, map
 * @param {*} marketPlace
 * @param {*} testOptionsOriginal: testOptions
 */
export function generateVariantJson(selectedScenarioIds, marketPlace, testOptionsOriginal) {
  let variantJson = [];
  let isErrorMusicScenarios = false;

  if (selectedScenarioIds && marketPlace && marketPlace !== AppConstants.EMPTY && !isErrorMusicScenarios) {
    Object.keys(selectedScenarioIds).forEach(scenarioId => {
      if (!isErrorMusicScenarios) {
        let musicScenarioValue = selectedScenarioIds[scenarioId];
        if (musicScenarioValue.hasOwnProperty('testType')) {
          let variantObject = {
            scenarioType: scenarioId,
            marketPlace: marketPlace,
            testType: musicScenarioValue.testType,
            testOptions: {
              ...testOptionsOriginal,
              scenarioType: scenarioId,
              resourcesRequired: get_resources_required_by_location(MusicConstants.MUSIC_SCENARIO_ID,
                scenarioId, musicScenarioValue.testType, {}, marketPlace)
            }
          }
          variantJson.push(variantObject);
        } else {
          // If either test type is not selected for a particular Music scenario, set error flag
          isErrorMusicScenarios = true;
        }
      }
    });
  }

  let response = {
    'variantJson': variantJson,
    'isErrorMusicScenarios': isErrorMusicScenarios,
    'isErrorMarketplace': marketPlace && marketPlace !== AppConstants.EMPTY ? false : true
  }

  return response;
}

/**
 * TODO: update it after testParams and testParamsArray being updated.
 * more error handling needed here
 * @param {*} testParamsArray: array of testParams
 * Assume common parts of testParamsArray are the same
 * @ return testSubmitParams that contains common info and variantJson array
 */
export function generateVariantJsonFromTestParamsArray(testParamsArray) {
  let testSubmitParams = {};
  if (testParamsArray.length > 0) {
    for (let key in testParamsArray[0]) {
      // eslint-disable-next-line no-prototype-builtins
      if (testParamsArray[0].hasOwnProperty(key) && !isIndividualItem(key)) {
        testSubmitParams[key] = testParamsArray[0][key];
      }
    }

    //calculate variantJson first.
    let variantJson = [];
    for (const testParams of testParamsArray) {
      let variantObject = {
        calculatedTestName: testParams.calculatedTestName,
        scenarioType: testParams.scenarioType,
        marketPlace: testParams.marketPlace,
        testType: testParams.testType,
        testOptions: {
          ...testParams.testOptions,
          scenarioType: testParams.scenarioType,
        },
        mapping: testParams.mapping,
        deviceMapping: testParams.deviceMapping
      }
      variantJson.push(variantObject);
    }
    testSubmitParams['variantJson'] = variantJson;
  }

  return testSubmitParams;
}

/**
 * Sequencing API usage
 * To Check whether testParams key value pair is common among
 * testParamsArray or is not common(individual)
 * @param {*} key
 * @return true if individual items
 *         false if not individual
 */
export function isIndividualItem(key) {
  const TESTPARAMS_INDIVIDUAL_FIELD_SET = ['calculatedTestName',
    'testOptions', 'isUtteranceRangeSet', 'mapping', 'userPreferenceMapping',
    'deviceMapping'];
  return TESTPARAMS_INDIVIDUAL_FIELD_SET.includes(key);
}
