/**
 * Component to render progress bar for Auto Local Search scenario
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import '../LiveFeedProgressBar.css';
import AutoLocalSearchTestConstants from '../../../_Constants/AutoLocalSearchTestConstants';
import { getMessagePretty } from '../../../Util';

// Progress bar filler component to display progress in green color
const Filler = (props) => {
  let fillerId = props.labJobId ? 'filler_' + props.labJobId : 'filler';
  return ( 
    <div className='filler' id={ fillerId } style={{ width: `${ props.percentage }%`}}/>
  )
}
 
// Progress bar component to display actual progress bar
const ProgressBar = (props) => {
  return (
    <div className='progress-bar'>
      <Filler percentage={ props.percentage }/>
    </div>
  )
}
 
/**
 * Component to display progress bar consisting of following states:
 * 1. Started
 * 2. In progress (with % of progress)
 * 3. Completed
 */
class AutoLocalSearchProgressBar extends React.Component {

  /**
   * Decides whether to display 'In progress' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayInProgressState = (testStatus) => {
    return AppConstants.IN_PROGRESS_STATES.includes(testStatus) || 
       AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
    * Decides whether to display 'Completed' state or not
    * @param testStatus Current status of test
    * @return Returns true only if test is completed.
    */
  shouldDisplayCompletedState = (testStatus) => {
    return AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
   * Gets text to display when test is in progress
   * @param testStatus Status of test
   * @param inProgressPercentage Percentage test case has completed
   * @return Text whether test is loading or In progress with percentage
   */
  getInProgressText = (testStatus, inProgressPercentage) => {
    let isTestInProgress = this.shouldDisplayInProgressState(testStatus);
    let isTestCompleted = this.shouldDisplayCompletedState(testStatus);
    if (isTestCompleted) {
      return 'In Progress (100%)';
    }
    if (isTestInProgress) {
      if (!inProgressPercentage || inProgressPercentage === 0) {
        return 'In Progress';
      } else {
        return 'In Progress (' + inProgressPercentage.toFixed(1) + '%)';
      }
    }
    // Return In Progress by default when testStatus does not match anything from controller
    return 'In Progress';
  }

  /**
   * Gets the percentage progress to display in progress bar
   * @param testStatus Current status of test
   * @param inProgressPercentage Percentage calculated base on current utterance/ total utterances (for In progress state)
   * @return Percentage value based on specific state & inProgressPercentage
   */
  getPercentage = (testStatus, inProgressPercentage) => {
    if (!AppConstants.IN_PROGRESS_STATES.includes(testStatus) && 
          !AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_STARTED_WIDTH;
    } else if (AppConstants.IN_PROGRESS_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_IN_PROGRESS_WIDTH + inProgressPercentage * AppConstants.PROGRESS_BAR_IN_PROGRESS_OFFSET;
    } else if (AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_COMPLETED_WIDTH;
    }
  }

  /**
   * Method to get display friendly text for reference mic status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Display friendly text (Online, Offline or Unavailable) based on refMicStatus value
   */
  getRefMicStatusText = (refMicStatus) => {
    if (refMicStatus !== undefined && AutoLocalSearchTestConstants.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return AutoLocalSearchTestConstants.REF_MIC_STATUS[refMicStatus].displayText;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      AutoLocalSearchTestConstants.REF_MIC_STATUS[AutoLocalSearchTestConstants.REF_MIC_STATUS_UNAVAILABLE].displayText:
      AutoLocalSearchTestConstants.REF_MIC_STATUS[AutoLocalSearchTestConstants.REF_MIC_STATUS_OFFLINE].displayText;
  }
 
  /**
   * Method to get color coding & style for ref mic based on its status
   * @param refMicStatus Reference mic status from custom payload
   * @returns Color coding & style for reference mic text based on its status
   */
  getRefMicDisplayStyle = (refMicStatus) => {
    if (refMicStatus !== undefined && AutoLocalSearchTestConstants.REF_MIC_STATUS.hasOwnProperty(refMicStatus)) {
      return AutoLocalSearchTestConstants.REF_MIC_STATUS[refMicStatus].displayStyle;
    }
    return this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
      AutoLocalSearchTestConstants.REF_MIC_STATUS[AutoLocalSearchTestConstants.REF_MIC_STATUS_UNAVAILABLE].displayStyle:
      AutoLocalSearchTestConstants.REF_MIC_STATUS[AutoLocalSearchTestConstants.REF_MIC_STATUS_OFFLINE].displayStyle;
  }

  /**
   * Method to render reference mic status
   * @param refMicStatus Reference mic status from payload
   * @returns Reference Mic UI element to render
   */
  getReferenceMic = (refMicStatus) => {
    // Retrieve reference mic status & style
    let referenceMicStatusText = this.getRefMicStatusText(refMicStatus);
    let referenceMicDisplayStyle = this.getRefMicDisplayStyle(refMicStatus);
    referenceMicDisplayStyle += ' awsui-util-ml-s';

    return (
      <div align='center' className='awsui-util-mt-xxl'>
        <h3 className='awsui-util-ml-xl awsui-util-mt-l awsui-util-mb-l ref-mic-status-style'>
          <span>
            {
              refMicStatus === AutoLocalSearchTestConstants.REF_MIC_STATUS_ONLINE && (
                <span>
                  <span className='ref-mic-recording-braces-style'>
                    [&nbsp;
                      </span>
                        <span className='ref-mic-recording-text-style'>
                          Rec
                        </span>
                        <b className='ref-mic-recording-dot-style'>
                          .
                        </b>
                      <span className='ref-mic-recording-braces-style'>
                    &nbsp;]
                  </span>
                </span>
               )
             }
             <b> Reference Mic Status: </b>
              <span className={ referenceMicDisplayStyle }>
              <AWSUI.Icon name='microphone'/>
              <span className='awsui-util-ml-xs'>
                { referenceMicStatusText }
              </span>
            </span>
          </span>
        </h3>
      </div>
    )
  }

  /**
   * Decides whether to display an informational message when all utterances have been executed (progress bar shows  100%)
   * but response is still QUEUED or IN_PROGRESS for some of the utterances
   */
  shouldDisplayWaitingMessage = (testStats, isCompleted) => {
    // If utterances are still being played, return false
    if (!isCompleted) {
      return false;
    }
    if (testStats && testStats.hasOwnProperty('testCases')) {
      let testCases = testStats.testCases;
      for (let i = 0; testCases && i < testCases.length; i++) {
        let currentUtterance = testCases[i];
        if (currentUtterance) {
          // If all utterances are played & either transcribeStatus or utteranceStatus is still QUEUED or IN_PROGRESS
          // display the message mentioning the same since progress bar would show 100%
          if (currentUtterance.hasOwnProperty('transcribeStatus')) {
            if (AutoLocalSearchTestConstants.STATUS_PENDING.includes(currentUtterance.transcribeStatus)) {
              return true;
            }
          }
          if (currentUtterance.hasOwnProperty('utteranceStatus')) {
            if (AutoLocalSearchTestConstants.STATUS_PENDING.includes(currentUtterance.utteranceStatus)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // Renders progress bar
  render() {
    let testStatus = this.props.params.testStatus ? this.props.params.testStatus.toLowerCase() : AppConstants.EMPTY;
    let percentage = this.props.params.testStats.percentage ? this.props.params.testStats.percentage : 0;
    let refMicStatus = this.props.params.testStats.refMicStatus;
    let isCompleted = this.shouldDisplayCompletedState(testStatus);
    let shouldDisplayWaitingMessage = this.shouldDisplayWaitingMessage(this.props.params.testStats, isCompleted);

    return (
      <div>
        <div className='breadcrumb-style'>
          <AWSUI.BreadcrumbGroup>
            <AWSUI.BreadcrumbItem text='Started'/>
            <AWSUI.BreadcrumbItem text={ this.getInProgressText(testStatus, percentage) }/>
            { isCompleted && (
              <AWSUI.BreadcrumbItem text='Completed'/>
            )}
          </AWSUI.BreadcrumbGroup>
        </div>
        <ProgressBar percentage={ this.getPercentage(testStatus, percentage) }
            labJobId={ this.props.params.labJobId }/>
        {
          shouldDisplayWaitingMessage && (
            getMessagePretty(AutoLocalSearchTestConstants.RESPONSE_PENDING_MESSAGE)
          )
        }
        {
          this.getReferenceMic(refMicStatus)
        }
      </div>
    )
  }
}
 
AutoLocalSearchProgressBar.propTypes = {
  params: PropTypes.object.isRequired
};
 
export default AutoLocalSearchProgressBar;