export const UPL_LIVE_SUMMARY_COLUMNS = [{
  id: 'id',
  header: () => '#',
  cell: item => item.id,
  minWidth: '40px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'utterance',
  header: () => 'Utterance',
  cell: item => item.utterance,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'totalAttempts',
  header: () => 'Total Attempts',
  cell: item => item.totalAttempts,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'averageUPLValue',
  header: () => 'Average UPL (ms)',
  cell: item => item.averageUPLValue,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'tp50',
  header: () => 'TP 50 (ms)',
  cell: item => item.tp50,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'tp90',
  header: () => 'TP 90 (ms)',
  cell: item => item.tp90,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
},  {
  id: 'result',
  header: () => 'Result',
  cell: item => item.result,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];