export const SYNC_PROGRESS_COLUMNS = [{
    id: 'resourceName',
    header: () => 'Name',
    cell: item => ( item.resourceName ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'status',
    header: () => 'Status',
    cell: item => ( item.status ),
    minWidth: '250px',
    allowLineWrap: false
}];

export const MANIFEST_STATUS_COLUMNS = [{
    id: 'resourceName',
    header: () => 'Resource Name',
    cell: item => ( item.resourceName ),
    minWidth: '300px',
    allowLineWrap: true
  }, {
    id: 'lastSyncd',
    header: () => 'Last Updated',
    cell: item => ( item.lastSyncd ),
    minWidth: '100px',
    allowLineWrap: false
}];
