/**
 * Component to render Mobile Functional Commands and Utterances under Utterances tab
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../../_Constants/AppConstants';
import MobileConstants from "../../../../_Constants/MobileConstants";
import FunctionalTestCases from '../../../../_Constants/FunctionalTestCasesConstants';
import { MOBILE_FUNCTIONAL_EXPANDABLE_COLUMNS,
  MOBILE_FUNCTIONAL_UTTERANCES_COLUMNS } from './MobileFunctionalTableConfig';
import '../../Functional/FunctionalLiveFeedStyles.css';
import {
  getTestStatusForValidationType, getUtterancesForValidationType, getUtteranceStatus,
  getExpandableSectionContent, setValueForExpandedSection }
  from '../../Functional/FunctionalLiveFeedUtil';
import {getLoadingMessage} from '../../../../Util';
import MobileFunctionalTestMetricsFeeds from "./MobileFunctionalTestMetricsFeeds";

class MobileFunctionalUtteranceDetails extends React.Component {
  state = {
    expandedSections: [],
    elapsedSeconds: 0
  };

  componentWillMount() {
    // While loading the component, check if it was navigated through clicking "Utterances"
    // button in Test metrics tab & if so, add appropriate expandable control to list of
    // expanded sections
    if (this.props.params.utteranceDetailsTestName !== AppConstants.EMPTY) {
      let expandedSectionsTemp = this.state.expandedSections;
      expandedSectionsTemp.push(this.props.params.utteranceDetailsTestName);
      this.setState({
        expandedSections: expandedSectionsTemp
      });
    }
    this.loadMobileFunctionalUtterancesData();
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ elapsedSeconds: this.state.elapsedSeconds + 1 }), 1000);
  }

  /**
   * Gets the expandable section for Functional test case
   * @param functionalTestCaseName Name of Functional Test case
   * @param cloudValidationStatus Cloud validation status for the test case
   * @param utterancesData Utterances Data for the test case
   * @returns Expandable section for test case
   */
  getExpandableSectionForTestCase = (functionalTestCaseName, cloudValidationStatus, utterancesData) => {
    let isTestCaseExpanded = this.state.expandedSections.includes(functionalTestCaseName);
    return (
      <AWSUI.ExpandableSection
        id = { functionalTestCaseName }
        header={
          getExpandableSectionContent(functionalTestCaseName, cloudValidationStatus, isTestCaseExpanded)
        }
        expanded={ isTestCaseExpanded }
        onChange={ event => { this.onSectionExpanded(functionalTestCaseName, event) } }
      >
        <AWSUI.Table
          columnDefinitions={ MOBILE_FUNCTIONAL_UTTERANCES_COLUMNS }
          items={ utterancesData }
          variant='borderless'
        >
        </AWSUI.Table>
      </AWSUI.ExpandableSection>
    )
  }

  /**
   * Event handler for expanding/ collapsing Functional test case section
   */
  onSectionExpanded = (id, event) => {
    let expandedSectionsIn = setValueForExpandedSection(id, event, this.state.expandedSections);
    this.setState({
      expandedSections: expandedSectionsIn
    });
    this.loadMobileFunctionalUtterancesData();
  }

  /**
   * Gets the back button to navigate back to Test Metrics tab
   * @param index Index of utterance -> Button will be displayed only once next to 1st utterance
   * @returns Back button which can be clicked to navigate to test metrics tab
   */
  getBackButton = (index) => {
    if (index === 0) {
      return (
        <div>
          <AWSUI.Button
            variant='normal'
            icon='caret-left'
            text='Test Metrics'
            onClick={ () => this.handleBackButtonClicked() }/>
        </div>
      )
    }
    return (<div></div>)
  }

  /**
   * Handler which sends data (Test Metrics tab) to parent FeedsContainer so that
   * Test Metrics tab can be navigated on clicking the Back button
   */
  handleBackButtonClicked = () => {
    this.props.onBackButtonClicked('testMetricsTab');
  }

  /**
   * Gets text to display in comments section, if any
   * @returns Comments text in display friendly format
   */
  getCommentsText = (utteranceValidationType, status, scoreFrr, questionPlayed) => {
    let commentsTextPre = AppConstants.EMPTY;
    let failedStatusText = AppConstants.EMPTY;
    let commentsTextPost = AppConstants.EMPTY;
    let questionPlayedText = AppConstants.EMPTY;
    if (utteranceValidationType !== FunctionalTestCases.UTTERANCE_REGEX_TYPE_VALIDATION
      && scoreFrr === 1
      && (status === FunctionalTestCases.FAILED || status === FunctionalTestCases.ERROR)) {
      commentsTextPre = 'DUT ';
      failedStatusText = 'Failed ';
      commentsTextPost = ' to invoke "';
      questionPlayedText = questionPlayed + '"';
    }
    return (
      <span className='utterance-comments-style'>
          { commentsTextPre }
        <span className='utterance-comments-failed-status-style'>
            <b>
              { failedStatusText }
            </b>
          </span>
        { commentsTextPost + questionPlayedText }
        </span>
    )
  }

  /**
   * Displays the mobile functional utterance details from the action list.
   */
  utteranceDisplay = (utterance, number, commandsAndUtterancesData) => {
    let utteranceValidationType = AppConstants.EMPTY;
    let questionPlayed;
    let dutHeard;
    let dutResponded;
    let validationType;
    let status;
    // Retrieve Utterance validation type for current utterance
    let utterValidationInfo = utterance.utterValidationInfo ? utterance.utterValidationInfo : {};
    if (utterValidationInfo
      && Object.keys(utterValidationInfo).length > 0
      && utterValidationInfo.hasOwnProperty('utterValidationType')) {
      utteranceValidationType = utterValidationInfo['utterValidationType'];
    }
    questionPlayed = utterance.questionPlayed ? utterance.questionPlayed : AppConstants.UNAVAILABLE;
    dutHeard = utterance.dutHeard ? utterance.dutHeard : AppConstants.UNAVAILABLE;
    dutResponded = utterance.dutResponded ? utterance.dutResponded : AppConstants.UNAVAILABLE;
    validationType = MobileConstants.ALEXA_CLOUD_VALIDATION;
    status = utterance.status;
    questionPlayed = number + '. ' + questionPlayed;
    commandsAndUtterancesData.push({
      questionPlayed: getUtteranceStatus(questionPlayed, status, 0, FunctionalTestCases.QUESTION_PLAYED,
        utteranceValidationType),
      dutHeard: getUtteranceStatus(dutHeard, status, utterance.scoreFalseRejectionRate,
        FunctionalTestCases.DUT_HEARD, utteranceValidationType),
      dutResponded: getUtteranceStatus(dutResponded, status, utterance.scoreResponseAccuracyRate,
        FunctionalTestCases.DUT_RESPONDED, utteranceValidationType),
      validationType: validationType,
      comments: this.getCommentsText(utteranceValidationType, status, utterance.scoreFalseRejectionRate,
        utterance.questionPlayed),
      backToTestMetrics: this.getBackButton(number)
    });
  }

  /**
   * Displays the mobile functional command details from the action list.
   */
  commandDisplay = (command, number, commandsAndUtterancesData) => {
    let utteranceValidationType = AppConstants.EMPTY;
    let questionPlayed;
    let dutHeard;
    let dutResponded;
    let validationType;
    let status;
    questionPlayed = command.commandPlayed ? command.commandPlayed : AppConstants.UNAVAILABLE;
    dutHeard = AppConstants.NOT_APPLICABLE;
    dutResponded = command.message ? command.message : AppConstants.UNAVAILABLE;
    validationType = MobileConstants.DEVICE_VALIDATION;
    status = command.status;
    questionPlayed = number + '. ' + questionPlayed;
    commandsAndUtterancesData.push({
      questionPlayed: getUtteranceStatus(questionPlayed, status, 0, FunctionalTestCases.QUESTION_PLAYED,
        utteranceValidationType),
      dutHeard: getUtteranceStatus(dutHeard, status, command.scoreFalseRejectionRate,
        FunctionalTestCases.DUT_HEARD, utteranceValidationType),
      dutResponded: getUtteranceStatus(dutResponded, status, command.scoreResponseAccuracyRate,
        FunctionalTestCases.DUT_RESPONDED, utteranceValidationType),
      validationType: validationType,
      comments: this.getCommentsText(utteranceValidationType, status, command.scoreFalseRejectionRate,
        command.questionPlayed),
      backToTestMetrics: this.getBackButton(number)
    });
  }

  /**
   * Loads the mobile functional utterances data
   * @returns Data for mobile functional utterances in tabular format
   */
  loadMobileFunctionalUtterancesData = () => {
    let functionalTestsDataIn = [];
    // Read functional test cases JSON from params
    let functionalTestsFromJson = this.props.params.functionalTestCases;

    if (functionalTestsFromJson && functionalTestsFromJson.length > 0) {
      // Iterate over list of functional test cases
      for (let i = 0; i < functionalTestsFromJson.length; i++) {
        let currentTestCase = functionalTestsFromJson[i];
        if (currentTestCase) {
          let functionalTestCaseName = currentTestCase.testCaseName;

          // Retrieve following information for the test:
          // 1. Cloud Validation Status
          // 2. List of utterances and commands
          let testStatusData = currentTestCase.testCaseData;
          let commands = getUtterancesForValidationType(testStatusData, MobileConstants.CUSTOM_MOBILE_VALIDATION);
          let cloudValidationStatus = getTestStatusForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let utterances = getUtterancesForValidationType(testStatusData, FunctionalTestCases.ALEXA_CLOUD_VALIDATION);
          let postUtterancesCommands =
            getUtterancesForValidationType(testStatusData.slice(2), MobileConstants.CUSTOM_MOBILE_VALIDATION);
          let commandsAndUtterances = utterances.concat(commands).concat(postUtterancesCommands);
          let commandsAndUtterancesData = [];

          let number = 0;
          if (commandsAndUtterances) {
              number += 1;
              //
              let comm = 0;
              let utt = 0;
              while(comm < commands.length && utt < utterances.length){
                if(commands[comm].actionSequenceNum > utterances[utt].actionSequenceNum){
                  // If this is an utterance to be ignored, skip it & don't display in UI
                  if (utterances[utt].hasOwnProperty('utteranceAttribute')
                    && utterances[utt].utteranceAttribute === FunctionalTestCases.UTTERANCE_IGNORE) {
                    continue;
                  }
                  this.utteranceDisplay(utterances[utt], number,commandsAndUtterancesData);
                  utt++;
                } else {
                  this.commandDisplay(commands[comm], number, commandsAndUtterancesData);
                  comm++;
                }
                number += 1;
              }
              while(comm < commands.length){
                this.commandDisplay(commands[comm], number, commandsAndUtterancesData);
                comm++;
                number += 1;
              }
              while(utt < utterances.length){
                if (utterances[utt].hasOwnProperty('utteranceAttribute')
                  && utterances[utt].utteranceAttribute === FunctionalTestCases.UTTERANCE_IGNORE) {
                  continue;
                }
                this.utteranceDisplay(utterances[utt], number,commandsAndUtterancesData);
                utt++;
                number += 1;
              }
          }

          functionalTestsDataIn.push({
            testCases: this.getExpandableSectionForTestCase(functionalTestCaseName, cloudValidationStatus, commandsAndUtterancesData)
          });
        }
      }
    }
    return functionalTestsDataIn;
  }

  render() {
    // Refresh functional stats table upon every render
    let mobileFunctionalTestsData = this.loadMobileFunctionalUtterancesData();
    return (
      mobileFunctionalTestsData && mobileFunctionalTestsData.length > 0 ? (
        <div>
          <MobileFunctionalTestMetricsFeeds
            params={this.props.params}
          />
          <div>
            <AWSUI.Table
              columnDefinitions={ MOBILE_FUNCTIONAL_EXPANDABLE_COLUMNS }
              items={ mobileFunctionalTestsData }
            >
            </AWSUI.Table>
          </div>
        </div>
      ) : (
        this.state.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
          ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
          getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
      )
    );
  }
}

MobileFunctionalUtteranceDetails.propTypes = {
  onBackButtonClicked: PropTypes.func.isRequired
};

export default MobileFunctionalUtteranceDetails;
