import React from 'react';
import { isEmpty } from 'lodash';
import AppConstants from '_Constants/AppConstants';
import CustomizeOptionsSubPage from '../CustomizeOptionsSubPage';
import TestTypeScenarioOptions from '../ScenarioCommon/TestTypeScenarioOptions';
import AWSUI from "@amzn/awsui-components-react";
import {getAutoSyncResourcesField, getDisablePlaybackSkill} from "../uiUtils";

class WakeResponseAccuracy extends React.Component {

  /**
   *
   * @param {*} selectedScenario
   * @param {*} selectedScenarioCallBack
   */
  getCheckboxOptionsForCT = (selectedScenario, selectedScenarioCallBack, lockFields) => {
    return (
      <AWSUI.ColumnLayout columns={2} borders='vertical'>
        <div data-awsui-column-layout-root='true'>
          {getAutoSyncResourcesField(selectedScenario.isAutoSyncEnabled,
            selectedScenario, selectedScenarioCallBack, lockFields)}
          {
            getDisablePlaybackSkill(selectedScenario, selectedScenarioCallBack)
          }
        </div>
      </AWSUI.ColumnLayout>
    )
  }

  render() {
    const { selectedScenario, selectedScenarioCallBack,
      selectedScenarioError, scenarioErrorCallBack, lockFields } = this.props.params;
    return (
      <div>
        {this.getCheckboxOptionsForCT(selectedScenario, selectedScenarioCallBack, lockFields)}
        <TestTypeScenarioOptions
          allowedTypes={[0, 1, 2, 3]}
          showDelayConfig={AppConstants.CUSTOMIZED === selectedScenario.testType}
          showAdminValidation={AppConstants.CUSTOMIZED === selectedScenario.testType}
          selectedScenario={selectedScenario}
          selectedScenarioError={selectedScenarioError}
          selectedScenarioCallBack={selectedScenarioCallBack}
          scenarioErrorCallBack={scenarioErrorCallBack}
        >
          {/* These components are specific to Clost Talk test */}
          {!isEmpty(selectedScenario) &&
            (<CustomizeOptionsSubPage params={{
              selectedScenario: selectedScenario,
              selectedScenarioCallBack: selectedScenarioCallBack,
              piLocations: AppConstants.closeTalkLocation,
              selectedScenarioError: selectedScenarioError,
              scenarioErrorCallBack: scenarioErrorCallBack
            }} />)
          }
        </TestTypeScenarioOptions>
      </div>
    )
  }

}

export default WakeResponseAccuracy;
