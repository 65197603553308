const MusicConstants = {
  MUSIC_SCENARIO_ID: 'MUSIC',
  MUSIC_SCENARIO_LABEL: 'Music Scenario',
  MUSIC_MSK: 'MUSIC_MSK',
  MUSIC_SCENARIOS: ['MUSIC_MSK_CUSTOM_STATION', 'MUSIC_MSK_LIVE_STATION', 'MUSIC_MSK_ON_DEMAND', 'MUSIC_IHRT_CUSTOM', 'MUSIC_IHRT_LIVE', 'MUSIC_TUNEIN_PODCAST',
    'MUSIC_TUNEIN_RADIO', 'MUSIC_AMAZON_MUSIC', 'AUDIBLE', 'KINDLE'],
  MUSIC_JAR_NAME: 'AVSCertMusic',
  MUSIC_IHRT_CUSTOM: 'MUSIC_IHRT_CUSTOM',
  MUSIC_IHRT_LIVE: 'MUSIC_IHRT_LIVE',

  /**
   * Constant for MSP & MSK types for Music Automation
  */
  MSP_TYPE: [{
    id: 'MUSIC_MSK_CUSTOM_STATION',
    label: 'MSK Custom Station',
    tags: ['Runs Music scenario for MSK Custom Station']
  }, {
    id: 'MUSIC_MSK_ON_DEMAND',
    label: 'MSK - On Demand',
    tags: ['Runs Music scenario for MSK On Demand']
  }, {
    id: 'MUSIC_MSK_LIVE_STATION',
    label: 'MSK - Live station',
    tags: ['Runs Music scenario for MSK Live station']
  }, {
    id: 'MUSIC_IHRT_CUSTOM',
    label: 'iHeartRadio Custom',
    tags: ['Runs Music scenario for iHeartRadio Custom']
  }, {
    id: 'MUSIC_IHRT_LIVE',
    label: 'iHeartRadio Live',
    tags: ['Runs Music scenario for iHeartRadio Live']
  }, {
    id: 'MUSIC_TUNEIN_RADIO',
    label: 'TuneIn - Radio',
    tags: ['Runs Music radio scenario for TuneIn']
  }, {
    id: 'MUSIC_TUNEIN_PODCAST',
    label: 'TuneIn - Podcast',
    tags: ['Runs Music Podcast scenario for TuneIn']
  }, {
    id: 'MUSIC_AMAZON_MUSIC',
    label: 'Amazon Music',
    tags: ['Runs Music scenario for Amazon Music']
    // }, {
    //   id: 'MUSIC_PANDORA',
    //   label: 'Pandora',
    //   tags: ['Runs Music scenario for Pandora'],
    //   allowedTestTypes: ['STANDARD']
  }, {
    id: 'AUDIBLE',
    label: 'Audible',
    tags: ['Runs Music scenario for Audible']
  }, {
    id: 'KINDLE',
    label: 'Kindle',
    tags: ['Runs Music scenario for Kindle']
  }],

  // Test types for Music scenarios
  MUSIC_TEST_TYPES: [{
    id: 'STANDARD',
    label: 'Standard',
    tags: ['Runs Music scenario for full set of utterances']
  }, {
    id: 'SMOKE',
    label: 'Smoke',
    tags: ['Runs Music scenario for limited set of utterances']
  }],

  /**
      * Deprecated Constants for MSP & MSK types for Music Automation to
      * support backward compatibility
      */
  MSP_TYPE_DEPRECATED: [{
    id: 'MSK',
    label: 'Music Skills',
    tags: ['Runs Music scenario for Music Skills']
  }, {
    id: 'IHRCustom',
    label: 'iHeartRadio Custom',
    tags: ['Runs Music scenario for iHeartRadio Custom']
  }, {
    id: 'IHRLive',
    label: 'iHeartRadio Live',
    tags: ['Runs Music scenario for iHeartRadio Live']
  }, {
    id: 'TuneIn',
    label: 'TuneIn',
    tags: ['Runs Music scenario for TuneIn']
  }, {
    id: 'AmazonMusic',
    label: 'Amazon Music',
    tags: ['Runs Music scenario for Amazon Music']
    // }, {
    //   id: 'MUSIC_PANDORA',
    //   label: 'Pandora',
    //   tags: ['Runs Music scenario for Pandora']
  }, {
    id: 'Audible',
    label: 'Audible',
    tags: ['Runs Music scenario for Audible']
  }, {
    id: 'Kindle',
    label: 'Kindle',
    tags: ['Runs Music scenario for Kindle']
  }],

  // SPECIFIC LOCALES FOR EACH MUSIC SUITE TEST TYPES - Add the locale if it is specific to a scenario type
  MUSIC_TEST_TYPE_MARKETPLACE_MAP: {
    "MUSIC_IHRT_CUSTOM": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }],
    "MUSIC_IHRT_LIVE": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }],
    "MUSIC_MSK_LIVE_STATION": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }],
    "MUSIC_MSK_CUSTOM_STATION": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }, {
      id: 'en_GB',
      label: 'en-GB',
      tags: ['English - United Kingdom']
    }, {
      id: 'de_DE',
      label: 'de-DE',
      tags: ['German - Germany']
    }],
    "MUSIC_MSK": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }, {
      id: 'en_GB',
      label: 'en-GB',
      tags: ['English - United Kingdom']
    }, {
      id: 'de_DE',
      label: 'de-DE',
      tags: ['German - Germany']
    }],
    "MUSIC_MSK_ON_DEMAND": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }, {
      id: 'en_GB',
      label: 'en-GB',
      tags: ['English - United Kingdom']
    }, {
      id: 'de_DE',
      label: 'de-DE',
      tags: ['German - Germany']
    }],
    "MUSIC_PANDORA": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }]
  },
}

export default {
  ...MusicConstants,
};
