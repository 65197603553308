import { FormSection, ColumnLayout, FormField, Select, Alert, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { API_STATUS } from '../../../../constants';
import { labConfigSelector } from '../../../../stores/newRun/acm/labConfigSlice';
import { customerIdSelector } from '../../../../stores/newRun/customerIdSlice';
import { labsSelector } from '../../../../stores/newRun/labSlice';
import { piSelector } from '../../../../stores/newRun/piSlice';
import { deviceAccSelector } from '../../../../stores/newRun/acm/labConfigSlice';
import AccountDeviceConfigView from './accountDeviceConfig';
import { handleLabSelection, setupInitialLabConfig } from '../../../../stores/newRun/acm/testCreationMiddleware';
import { get } from 'lodash';

export default () => {
  const {status: labFetchStatus, labs, error: labFetchErr} = useSelector(labsSelector);
  const {labInfo, error: labValidationError} = useSelector(labConfigSelector);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setupInitialLabConfig(labInfo.id));
  }, []);

  const handleLabChange = (e) => {
    const newLabInfo = e.detail.selectedOption.meta;
    dispatch(handleLabSelection(newLabInfo));
  }

  let labOptions = [];
  if(labFetchStatus === API_STATUS.SUCCESS) {
    labOptions = labs.map(lab=>({ id: lab.id, label: lab.name, meta: lab, disabled: lab.lock===1 }));
  }

  let selectedOption = {};
  if(labInfo.id) {
    selectedOption = {id: labInfo.id, label: labInfo.name}
  }

  const labInfoError = get(labValidationError, 'labInfo');
  const accountInfoError = get(labValidationError, 'account');
  let labFetchError;
  if(labFetchStatus === API_STATUS.ERROR) {
    labFetchError = <Alert type="error" key={1} className="awsui-util-mt-s awsui-util-mb-s">
        <div>{labFetchErr}</div>
      </Alert>
  }

  let accountValidationView;
  if(accountInfoError) {
    accountValidationView = <Alert type="error" key={3} className="awsui-util-mt-s awsui-util-mb-s">
         <div>{accountInfoError}</div>
       </Alert>
  }

  return (
    <>
      <FormSection header={<FormattedMessage id="LAB_INFO" />}>
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            {labFetchError}
            <FormField label={<FormattedMessage id="LAB" />} description={<FormattedMessage id="SELECT_A_LAB" />}>
              <Select
                options={labOptions}
                onChange={handleLabChange}
                selectedOption={selectedOption}
                loading={labFetchStatus === API_STATUS.PENDING}
                selectedLabel="Selected"
                invalid={!!labInfoError}
                ></Select>
                {
                  labInfoError? <div className='formErrMsg'>{labInfoError}</div> : null
                }
            </FormField>

          </div>
        </ColumnLayout>
      </FormSection>
      {accountValidationView}
      {
        labInfo && labInfo.id && <AccountConfigWrapper />
      }
    </>
  )
}


const AccountConfigWrapper = () => {
  const {status: customerIdLoadStatus, error: customerIdFetchError} = useSelector(customerIdSelector);
  const {status: piLoadStatus, pis, error: piFetchErr } = useSelector(piSelector);
  const { accounts, devices, deviceAccMap } = useSelector(deviceAccSelector);
  const { PENDING, ERROR, SUCCESS } = API_STATUS;
  if(customerIdLoadStatus === PENDING || piLoadStatus === PENDING) {
    return <div className="awsui-util-mt-l">
      <Spinner /> <FormattedMessage id="LOADING_RESOURCES" />
    </div>
  }

  const errView = []
  if(customerIdLoadStatus === ERROR) {
    errView.push(
      <Alert key={1} type="error" className="awsui-util-mt-s awsui-util-mb-s">
        <div>{customerIdFetchError}</div>
      </Alert>
    )
  }

  if(piLoadStatus === ERROR) {
    errView.push(
      <Alert key={2} type="error" className="awsui-util-mt-s awsui-util-mb-s">
        <div>{piFetchErr}</div>
      </Alert>
    )
  }

  if(errView.length) {
    return <>{errView}</>
  }

  if(piLoadStatus === SUCCESS && (!Array.isArray(pis) ||  pis.length < devices.length)) {
    if(Array.isArray(pis)) {
      if(pis.length < devices.length) {
        return <Alert type="error" className="awsui-util-mt-s awsui-util-mb-s"><FormattedMessage id="LAB_DOES_NOT_HAVE_DEVICES" /></Alert>
      }
    }
  }

  return <>
    {
      accounts
        .map((account, ind) => (
            <AccountDeviceConfigView
              key={ind}
              devAccMap={deviceAccMap[account]}
              account={account} />
          ))
    }
  </>

}
