import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import {
  Table,
  TablePagination,
  TableSelection,
} from '@amzn/awsui-components-react';

import {
  JOBLIST_COLUMNS,
} from './JobListTableConfig';
import { logToConsole } from '../../../Util';
import AppConstants from '../../../_Constants/AppConstants';
import { JobListHeader } from './JobListTableHeader';
import { paginationLabels } from './Utils';

// This option is only shown when testParamsArray is not empty.
class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJobs: [],
      filteringText: '',
      pageSize: 20,
      wrapLines: false
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  onFilteringChange = ({ detail: { filteringText } }) => {
    this.setState({
      filteringText
    });
  }

  onPaginationChange = ({ detail: { pageSize } }) => {
    this.setState({
      pageSize
    });
  }

  onContentSelectionChange = ({ detail }) => {
    const contentSelection = detail.contentSelection;
    const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
    this.setState({
      contentSelectorOptions: [
        {
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1
          }))
        }
      ]
    });
  }

  onWrapLinesChange = ({ detail }) => {
    this.setState({
      wrapLines: detail.value
    });
  }

  clearFilter = () => {
    this.setState({
      filteringText: ''
    });
  }

  getItems = (testParamsArray) => {
    let items = [];
    for (let index = 0; testParamsArray && index < testParamsArray.length; index++) {
      const testParams = testParamsArray[index];
      let item = {
        order: index + 1,
        uuid: testParams.uuid,
        calculatedTestName: testParams.calculatedTestName,
        scenarioType: testParams.scenarioType,
        testType: testParams.testType,
        locale: testParams.marketPlace,
        getMoreDetails: this.getMoreDetails,
        testOptions: testParams.testOptions
      };
      items.push(item); // be careful regarding to push order
    }
    return items;
  }

  getMoreDetails = (testOptions) => {
    let detailsDisplay = AppConstants.EMPTY;
    let resources = testOptions.resourcesRequired;
    Object.keys(resources).forEach(function (key) {
      detailsDisplay = detailsDisplay + key + ", ";
    })
    const finalDetailsDisPlay = detailsDisplay.slice(0, -2)
    return finalDetailsDisPlay;
  }

  getHeader = (items, headerText) => {
    const { testParamsArray, updateTestParamsArrayCallBack,
      labsInAccount, updateNewRunStateParameterCallBack,
      pageDisplayParams, updatePageDisplayParamsCallBack } = this.props.params;
    return (
      <JobListHeader
        selectedItems={this.state.selectedJobs}
        totalItems={items}
        updateTools={this.updateTools}
        headerText={headerText}
        testParamsArray={testParamsArray}
        updateTestParamsArrayCallBack={updateTestParamsArrayCallBack}
        labsInAccount={labsInAccount}
        pageDisplayParams={pageDisplayParams}
        updatePageDisplayParamsCallBack={updatePageDisplayParamsCallBack}
        updateNewRunStateParameterCallBack={updateNewRunStateParameterCallBack}
      />
    )
  }
  // TODO: remove it shortly if not needed
  updateTools = () => {
    // Do nothing should be enough
  }

  /**
   * Actions for select various jobs
   * @param {*} event
   */
  onSelectionChange = (event) => {
    this.setState({ selectedJobs: event.detail.selectedItems })
  }

  render() {
    const { testParamsArray } = this.props.params;
    let items = this.getItems(testParamsArray);
    let headerText = 'Job List: ' + testParamsArray[0].testSuite + ", " + testParamsArray[0].marketPlace;
    return (
      <Table
        columnDefinitions={JOBLIST_COLUMNS}
        items={items}
        stickyHeader={true}
        resizableColumns={true}
        header={
          this.getHeader(items, headerText)
        }
        wrapLines={this.state.wrapLines}
        onWrapLinesChange={this.onWrapLinesChange.bind(this)}
      >

        <TablePagination
          onPaginationChange={this.onPaginationChange}
          labels={paginationLabels}
          pageSize={this.state.pageSize}
        />

        <TableSelection
          selectedItems={this.state.selectedJobs}
          trackBy='calculatedTestName'
          onSelectionChange={event => this.onSelectionChange(event)}
        />
      </Table>
    );
  }
}


export default JobList;
