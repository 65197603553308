/**
 * Component to render Auto Local Search test cases
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import { AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS, AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS_ALEXA } 
  from './AutoLocalSearchUtterancesTableConfig';
import { getTextOrLoading, renderLabelElement, getLoadingElement, getLoadingMessage, getMessagePretty } 
  from '../../../Util';
import AutoLocalSearchTestConstants from '../../../_Constants/AutoLocalSearchTestConstants';
import './AutoLocalSearchLiveFeedStyles.css';

/**
 * Component to render Auto Local Search test cases
 * Displays Test Metrics & Utterance details for 10 utterances
 */
class AutoLocalSearchTestMetrics extends React.Component {

  componentWillMount() {
    this.loadUtterancesData();
  }

  /**
   * Gets question played to display in current utterance column
   */
  getQuestionPlayed = (questionPlayed) => {
    if (questionPlayed) {
      return (
        <span className='utterance_played_style'>
        { questionPlayed }
        </span>
      )
    }
    return getLoadingElement();
  }

  /**
   * Gets utterance played to display in utterance details table
   */
  getUtterancePlayed = (utteranceId, utterancePlayed) => {
    if (utterancePlayed) {
      if (utteranceId) {
        utterancePlayed = utteranceId + '. ' + utterancePlayed
      }
      return (
        <span className='utterance_played_style'>
        { utterancePlayed }
        </span>
      )
    }
    return (
      <span>
        { AppConstants.UNAVAILABLE }
      </span>
    )
  }

  /**
   * Gets response to display in response feed in test metrics
   * @param dutResponse Response from transcribe
   * @param transcribeStatus Status of transcribe
   * @param status Overall status for current utterance
   */
  getDUTResponse = (dutResponse, transcribeStatus, status) => {
    // If response is available and status or transcribe status is not Queued or In Progress,  return the same
    if (dutResponse
          && !(AutoLocalSearchTestConstants.STATUS_PENDING.includes(transcribeStatus)
                || AutoLocalSearchTestConstants.STATUS_PENDING.includes(status))) {
      // If response is NOT_AVAILABLE, simply don't display it
      if (dutResponse === AutoLocalSearchTestConstants.NOT_AVAILABLE) {
        dutResponse = AppConstants.EMPTY;
      }
      return (
        <span>
        { dutResponse }
        </span>
      )
    }
    return (
      <span>
        { AppConstants.EMPTY }
      </span>
    )
  }

  /**
   * Gets response to display in response feed in for current utterance
   * Since current utterance will never have transcribe response at the same moment, only rely on status
   * and response text to display this value
   * @param dutResponse Response from transcribe
   * @param status Overall status for current utterance
   */
  getDUTResponseCurrentUtterance = (dutResponse, status) => {
    // If response is available and status is not Queued or In progress,  return the same
    if (dutResponse && !AutoLocalSearchTestConstants.STATUS_PENDING.includes(status)) {
      // If response is NOT_AVAILABLE, simply don't display it
      if (dutResponse === AutoLocalSearchTestConstants.NOT_AVAILABLE) {
        dutResponse = AppConstants.EMPTY;
      }
      return (
        <span>
        { dutResponse }
        </span>
      )
    }
    return (
      <span>
        { AppConstants.EMPTY }
      </span>
    )
  }

  /**
   * Gets status for utterance in utterance details table
   */
  getStatus = (status) => {
    if (status && AutoLocalSearchTestConstants.UTTERANCE_STATUS_MAP.hasOwnProperty(status)) {
      return (
        <div>
        <AWSUI.Icon name={ AutoLocalSearchTestConstants.UTTERANCE_STATUS_MAP[status].testStatusIcon }
          variant={ AutoLocalSearchTestConstants.UTTERANCE_STATUS_MAP[status].variant }/>
            <span className = { AutoLocalSearchTestConstants.UTTERANCE_STATUS_MAP[status].testStatusTextStyle }>
              { AutoLocalSearchTestConstants.UTTERANCE_STATUS_MAP[status].testStatusValue }
            </span>
        </div>
      )
    }
    return (
      <span>
        { AppConstants.NOT_APPLICABLE }
      </span>
    )
  }

  /**
   * Renders utterance data for up to 10 utterances in tabular format
   */
  loadUtterancesData = () => {
    let testStats = this.props.params.testStats;
    let isDeviceVirtualAssistantAlexa = testStats 
      && testStats.deviceVirtualAssistant === AutoLocalSearchTestConstants.ALEXA;
    let utterancesData = [];
    if (testStats && testStats.hasOwnProperty('testCases')) {
      let testCases = testStats.testCases;
      for (let i = 0; testCases && i < testCases.length; i++) {
        let currentUtterance = testCases[i];
        if (currentUtterance) {
          utterancesData.push({
            utterancePlayedTranscript : this.getUtterancePlayed(currentUtterance.utteranceId, 
              currentUtterance.utterancePlayedTranscript),
            dutResponse : this.getDUTResponse(currentUtterance.dutResponse,
              currentUtterance.transcribeStatus, currentUtterance.status),
            status : this.getStatus(currentUtterance.status),
            ...(!isDeviceVirtualAssistantAlexa && {transcribeStatus: this.getStatus(currentUtterance.transcribeStatus)}),
            ...(isDeviceVirtualAssistantAlexa && {utteranceStatus: this.getStatus(currentUtterance.utteranceStatus)})
          });
        }
      }
    }
    return utterancesData;
  }

  /**
   * Renders test metrics feed for Auto Local Search scenario
   */
  getAutoLocalSearchTestMetrics = (testStats) => {
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-no-padding awsui-util-mb-n'>
        <AWSUI.ColumnLayout columns={ 4 } borders='all'>
          <div className='awsui-no-margin' data-awsui-column-layout-root='true'>
            { renderLabelElement('Utterance Count ','(Completed / Total number of Utterances)') }
            { getTextOrLoading(testStats.count) }
            { renderLabelElement('Current Utterance ', AppConstants.EMPTY) } 
            { this.getQuestionPlayed(testStats.played) } 
            { renderLabelElement('Start Time', AppConstants.EMPTY) }
            { getTextOrLoading(testStats.startTime) }
            { renderLabelElement('Response ', AppConstants.EMPTY) } 
            { this.getDUTResponseCurrentUtterance(testStats.responded, testStats.status) } 
            { renderLabelElement('Elapsed Time ', AppConstants.EMPTY) }
            { getTextOrLoading(testStats.elapsedTime) } 
            { renderLabelElement('Utterance Status ', AppConstants.EMPTY) } 
            { this.getStatus(testStats.status) } 
            { renderLabelElement('Location ', AppConstants.EMPTY) } 
            { getTextOrLoading(testStats.locationNoise) } 
            {
              testStats && testStats.deviceVirtualAssistant !== AutoLocalSearchTestConstants.ALEXA && (
                renderLabelElement('Transcribe Status ', AppConstants.EMPTY)
              )
            }
            {
              testStats && testStats.deviceVirtualAssistant !== AutoLocalSearchTestConstants.ALEXA && (
                this.getStatus(testStats.transcribeStatus)
              )
            }
            {
              testStats && testStats.deviceVirtualAssistant === AutoLocalSearchTestConstants.ALEXA && (
                renderLabelElement('DUT Response Status ', AppConstants.EMPTY)
              )
            }
            {
              testStats && testStats.deviceVirtualAssistant === AutoLocalSearchTestConstants.ALEXA && (
                this.getStatus(testStats.utteranceStatus)
              )
            }
            { renderLabelElement('ETA ', '(Total Estimated Time Remaining)') } 
            { getTextOrLoading(testStats.eta) }  
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }

  render() {
    let testStats = this.props.params.testStats;
    // Retrieve Device virtual assistant, set to Alexa by default if information not found
    let deviceVirtualAssistant = testStats && testStats.deviceVirtualAssistant ?
      testStats.deviceVirtualAssistant :
      AutoLocalSearchTestConstants.ALEXA;
    // For Alexa device virtual assistant, display only Utterance status, for other type of virtual assistants (Google
    // and Siri), display Transcribe status column as well
    let utteranceDataColumns = deviceVirtualAssistant === AutoLocalSearchTestConstants.ALEXA ?
      AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS_ALEXA:
      AUTO_LOCAL_SEARCH_UTTERANCES_COLUMNS;
    // Refresh utterances stats table upon every render

    let utterancesData = this.loadUtterancesData();
    return (
      utterancesData && utterancesData.length > 0 ? (
        <div>
          {
            this.getAutoLocalSearchTestMetrics(testStats)
          }
          {
            deviceVirtualAssistant !== AutoLocalSearchTestConstants.ALEXA && (
              getMessagePretty(AutoLocalSearchTestConstants.TRANSCRIBE_RESPONSE_MESSAGE)
            )
          }
          <h3 className='awsui-util-mt-l awsui-util-ml-m utterance-data-header-style'>
            <b>
              Utterance Data
            </b>
          </h3>
          <div className='awsui-util-mt-l'>
            <AWSUI.Table
              columnDefinitions={ utteranceDataColumns }
              items={ utterancesData } 
            >
            </AWSUI.Table>
          </div>
        </div>
      ) : (
        this.props.params.elapsedSeconds < AppConstants.MAX_WAIT_TIME ?
          ( <div align='center'><AWSUI.Spinner size='big' /></div> ) :
            getLoadingMessage(AppConstants.RETRIEVING_DATA_MESSAGE)
        )
    );
  }
}

AutoLocalSearchTestMetrics.propTypes = {
  onTabChange: PropTypes.func.isRequired
};

export default AutoLocalSearchTestMetrics;