import React, { PureComponent } from 'react';
import AWSUI from '@amzn/awsui-components-react';
import './index.css';

class CustomTestForbiddenPage extends PureComponent {
  state = {  }
  render() {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Access Restricted</h2>
        </div>
        <div>User defined test allows user to define their own set of rules to run a test.
          To know more details about it, please read the "AQT User Guide - User defined test addendum" document from
          <a href={"https://developer.amazon.com/alexa/console/avs/preview/resources/details/Alexa%20Qualification%20Tool"}> here</a>.
          <div className="awsui-util-pt-l">
            Please reach out to support-aqt@amazon.com to get access.
          </div>
        </div>
      </div>
    );
  }
}

export default CustomTestForbiddenPage;
