/**
 * Job List Table Column Definition
 */
export const JOBLIST_COLUMNS = [{
  id: 'order',
  header: () => 'Execution Order',
  cell: item => (item.order),
  minWidth: '200px',
  allowLineWrap: true
}, {
  id: 'testName',
  header: () => 'Test Name',
  cell: item => (item.calculatedTestName),
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'scenarioType',
  header: () => 'Scenario Type',
  cell: item => (item.scenarioType),
  minWidth: '100px',
  allowLineWrap: false
}, {
  id: 'testType',
  header: () => 'Test Type',
  cell: item => (item.testType),
  minWidth: '100px',
  allowLineWrap: true
}, {
  id: 'locale',
  header: () => 'Locale',
  cell: item => (item.locale),
  minWidth: '100px',
  allowLineWrap: true
}, {
  id: 'more details',
  header: () => 'More Details',
  cell: item => (item.getMoreDetails(item.testOptions)),
  minWidth: '550px',
  allowLineWrap: false
}];
