import React, { Component } from 'react';
import { connect } from 'react-redux'
import AWSUI from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';

import AppConstants from '_Constants/AppConstants';
import { logToConsole, networkError } from '../../../Util';
import { getThingsForLab } from '../controller';
import { executeActionOnThing } from 'redux/actions/labs';

class TestCalibration extends Component {
  static propTypes = {
    scenarioType: PropTypes.string.isRequired,
    testSuite: PropTypes.string.isRequired,
    testType: PropTypes.string.isRequired,
    labId: PropTypes.string.isRequired,
    lab: PropTypes.object.isRequired,
    spl: PropTypes.object.isRequired,

    handleSPLSelection: PropTypes.func.isRequired,
    executeActionOnThing: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      labToThingsMapping: {},
      speakerInCalibration: null
    };

    this.handleSPLSelection = this.handleSPLSelection.bind(this);
    this.toggleCalibration = this.toggleCalibration.bind(this);
  }

  componentDidMount() {
    // Get things for the given lab
    this.getThingsForLab();
  }

  componentDidUpdate(prevProps) {
    if (this.props.labId !== prevProps.labId) {
      this.getThingsForLab();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scenarioType !== this.props.scenarioType) {
      this.resetSPLState();
    }
  }

  /**
   * Get spl config based on the category (SILENCE, NOISE, DEVICE_PLAYBACK)
   * @param category
   */
  getSPLConfiguration = (category) => {
    const scenarioCalibrationMapping = {
      SILENCE: {
        parentSPL: 'Speech',
        Speech: [
          { label: 'Normal', level: 54, default: true },
          { label: 'Soft', level: 44 }
        ],
        units: 'dBA'
      },
      DEVICE_PLAYBACK: {
        parentSPL: 'DUT',
        DUT: [
          { label: 'Normal', level: 65, default: true },
          { label: 'Soft', level: 55 }
        ],
        Speech: [
          { parentLevel: 65, label: 'Normal', level: 64 },
          { parentLevel: 65, label: 'Soft', level: 54 },
          { parentLevel: 55, label: 'Normal', level: 59 },
          { parentLevel: 55, label: 'Soft', level: 49 }
        ],
        units: 'dBA'
      },
      NOISE: {
        parentSPL: 'Noise',
        Noise: [
          { label: 'Normal', level: 65, default: true },
          { label: 'Soft', level: 55 },
          { label: 'Soft', level: 45 }
        ],
        Speech: [
          { parentLevel: 65, label: 'Normal', level: 64 },
          { parentLevel: 65, label: 'Soft', level: 54 },
          { parentLevel: 55, label: 'Normal', level: 59 },
          { parentLevel: 55, label: 'Soft', level: 49 },
          { parentLevel: 45, label: 'Normal', level: 54 },
          { parentLevel: 45, label: 'Soft', level: 44 }
        ],
        units: 'dBA'
      }
    };

    return scenarioCalibrationMapping[category];
  }

  /**
   * Get things for the selected lab
   */
  getThingsForLab = async () => {
    let { labId, lab } = this.props;
    if (!labId && !lab) return;

    labId = lab.id || labId;

    if (!labId) return;
    // Get things for the given lab
    try {
      const { labToThingsMapping = {} } = this.state;

      // skip fetching if we had already fetched the data
      if (labToThingsMapping[labId] && labToThingsMapping[labId].length > 0) return;

      const res = await getThingsForLab(labId);
      // update state with lab info
      this.setState({
        labToThingsMapping: {
          ...labToThingsMapping,
          [labId]: res.data
        }
      });

    } catch (error) {
      logToConsole('Failed to fetch things for selected lab.', error);
    }
  }

  /**
   * Get rasPi info for the given location in a lab
   * @param location
   */
  getRaspiDetails = (location) => {
    const { labId, lab } = this.props;
    const { labToThingsMapping } = this.state;
    const things = labToThingsMapping[labId];
    const { preference } = lab;
    const defaultRaspiOrder = AppConstants.actorMappingKeys;

    // if there is no preference, then take default
    let mapping = {};
    things.forEach((thing, index) => {
      mapping[thing.id] = {
        name: thing.name,
        location: defaultRaspiOrder[index]
      };
    });

    try {
      const { actor_mapping_preference: mappingPreference } = JSON.parse(preference);
      if (
        mappingPreference &&
        Object.keys(mappingPreference).length === defaultRaspiOrder.length
      ) {
        mapping = mappingPreference;
      }
    } catch (error) {
      logToConsole('info', 'No preference set', error);
    }

    const id = Object.keys(mapping).find(key => (mapping[key].location === location));

    return {
      id,
      ...mapping[id]
    };
  }

  handleSPLSelection = (actor) => {
    return (event) => {
      if (!event) return;

      const { testSuite, scenarioType } = this.props;

      const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];
      const scenarioCategory = testSuiteInfo.CLASSIFICATION[scenarioType];

      const splConfig = this.getSPLConfiguration(scenarioCategory);

      const { selectedOption } = event.detail;
      let currentState = this.props.spl;

      // If this is the parent actor, then reset state
      if (splConfig.parentSPL === actor.type) {
        currentState = {};
      }

      this.props.handleSPLSelection({
        ...currentState,
        [actor.key]: selectedOption
      });
    };
  }

  /**
   * Play noise on the speaker for the given location to calibrate
   * @param {Object} actor
   */
  startCalibration = (actor) => {
    const {
      RAS_PI_CONTROL_NAME,
      HIFI_PI_CONTROL_NAME,
      PINK_NOISE_FILE_TIMEOUT,
      TEST_SUITES,
      rasPiAction
    } = AppConstants;

    const { labId, testSuite, scenarioType, labs } = this.props;
    const audioFiles = TEST_SUITES[testSuite].CALIBRATION_FILES;

    const { action } = rasPiAction['TALK'];

    const raspi = this.getRaspiDetails(actor.location);

    if (!raspi || !raspi.id) {
      logToConsole('Error while getting raspi details from lab');
      return
    }

    let controlName = RAS_PI_CONTROL_NAME;
    const hatsPiNameSuffix = "WITHDAC";
    if (raspi.name && raspi.name.toUpperCase().includes(hatsPiNameSuffix)) {
       controlName = HIFI_PI_CONTROL_NAME;
    }

    let audioFileName = actor.type === 'Speech' ? audioFiles.SPEECH : audioFiles[scenarioType];

    /**
     * For DUT, if calibration is in progress then send the admin stop command
     * to the default speech speaker to play the utterance
     */
    if (labs.isCalibrationInProgress && actor.type === 'DUT') {
      audioFileName = AppConstants.ADMIN_STOP_AUDIO_FILE;
    }

    const params = {
      controlName,
      waveFileName: audioFileName,
    };

    try {
      this.props.executeActionOnThing(labId, raspi.id, action, PINK_NOISE_FILE_TIMEOUT, params);
    } catch (error) {
      networkError(error, 'startCalibration');
      logToConsole('Error while calibrating the pi', error);
    }
  }

  /**
   * Stop playing noise on the speaker for the given location
   * @param {Object} actor
   */
  stopCalibration = async (actor) => {
    const { rasPiAction } = AppConstants;
    const { labId } = this.props;

    const { action } = rasPiAction['STOP'];

    const raspi = this.getRaspiDetails(actor.location);

    try {
      this.props.executeActionOnThing(labId, raspi.id, action);
    } catch (error) {
      networkError(error, 'startCalibration');
      logToConsole('Error while stopping the calibration of the pi', error);
    }
  }

  /**
   * Handler for calibration buttons
   * @param {Object} actor
   */
  toggleCalibration = (actor) => {
    return () => {
      const { labId, labs } = this.props;

      if (!labId) {
        logToConsole('Lab id is not sent to the component');
        return;
      }

      if (labs.isCalibrationInProgress && actor.type !== 'DUT') {
        this.stopCalibration(actor);
      } else {
        this.startCalibration(actor);
      }

      this.setState({
        speakerInCalibration: labs.isCalibrationInProgress ? null : actor
      });
    };
  }

  /**
   * Reset state when SPL level of controlling actor changes
   */
  resetSPLState = () => {
    this.setState({
      spl: {}
    });
  }

  renderCalibrationButton = (actor) => {
    let calibrationLabel = `Calibrate ${actor.type} speaker`;
    if (actor.location && actor.type !== 'DUT') {
      calibrationLabel = `${calibrationLabel} at ${actor.location}`;
    }
    const { labId, labs } = this.props;

    const {
      speakerInCalibration,
      labToThingsMapping
    } = this.state;

    const { STOP, CALIBRATE } = AppConstants.rasPiAction;

    const isThisPiInProgress = (
      labs.isCalibrationInProgress &&
      !!speakerInCalibration &&
      speakerInCalibration.location === actor.location &&
      speakerInCalibration.type === actor.type
    );

    const text = isThisPiInProgress ? STOP.name : CALIBRATE.name;
    const icon = isThisPiInProgress ? STOP.icon : CALIBRATE.icon;

    const disableButton = (
      // Disable calibration button if labId is missing!
      !labId ||
      !labToThingsMapping[labId] ||
      (
        labs.isCalibrationInProgress &&
        !!speakerInCalibration &&
        speakerInCalibration.location !== actor.location
      )
    );

    return (
      <div className="awsui-util-pt-s awsui-util-mb-s">
        <div><span>{calibrationLabel}</span></div>
        <div className="awsui-util-pt-s awsui-util-mb-s">
          <AWSUI.Button
            text={text}
            variant='normal'
            icon={icon}
            disabled={disableButton}
            onClick={this.toggleCalibration(actor)}
          />
        </div>
      </div>
    )
  }

  renderSPLCalibration = () => {
    const { scenarioType, spl, testSuite, actors } = this.props;

    if (!scenarioType) return;

    const testSuiteInfo = AppConstants.TEST_SUITES[testSuite];

    const scenarioCategory = testSuiteInfo.CLASSIFICATION[scenarioType];
    const splConfig = this.getSPLConfiguration(scenarioCategory);

    if (!actors || actors.length === 0) return;

    const calibrationSections = actors.map(actor => {
      const label = `${actor.type} SPL`;
      const hintText = `Select ${actor.type} sound level`;
      const options = splConfig[actor.type]
        .filter(option => {
          if (splConfig.parentSPL === actor.type) return true;

          return (
            spl[splConfig.parentSPL] &&
            option.parentLevel === spl[splConfig.parentSPL].value
          );
        })
        .map(option => {
          return {
            label: option.level,
            value: option.level,
            id: `option-${option.level}`,
            actor: actor.type.toLowerCase(),
            text: option.label,
            location: actor.location
          };
        });

      return (
        <div
          key={actor.key}
        >
          <AWSUI.FormField
            label={label}
            hintText={hintText}
            errorText={((spl[actor.key] && spl[actor.key].error) || '')}
          >
            <div
              style={{ maxWidth: '600px' }}
            >
            <AWSUI.Select
              options={options}
              selectedOption={spl && spl[actor.key]}
              onChange={this.handleSPLSelection(actor)}
              placeholder={AppConstants.EMPTY}
            >
            </AWSUI.Select>
            </div>
          </AWSUI.FormField>
          <div>
            {this.renderCalibrationButton(actor)}
          </div>
        </div>
      );
    });

    const columnsCount = actors.length > 2 ? 3 : actors.length;

    return (
      <AWSUI.FormSection header='Test Calibration'>
        <AWSUI.ColumnLayout columns={columnsCount} borders='none'>
          <div data-awsui-column-layout-root="true">
            {calibrationSections}
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormSection>
    );
  }

  render() {
    const { scenarioType } = this.props;

    if (!scenarioType) return (<div></div>);

    return (
      <div> {this.renderSPLCalibration()} </div>
    );
  }
}

export default connect(
  ({ labs }) => ({ labs }),
  { executeActionOnThing }
)(TestCalibration);
