import { combineReducers } from 'redux';

import auth from './auth';
import customTest from './customTest';
import labs from './labs';
import newRun from './newRun';

export default combineReducers({
  auth,
  customTest,
  labs,
  newRun,
});
