import React from 'react';
import { getReleaseNotes } from './controller';
import { prettyPrintReleaseNotes, showHistoryNoteItem } from './format';
import { logToConsole } from '../../Util';
import './style.css'
import AppConstants from '../../_Constants/AppConstants';

class ReleaseNotes extends React.Component {
  state = {
    releaseNotes: {},
    historyButtonClick: []
  }

  componentDidMount() {
    this.getReleaseNotes();
  }

  /**
   * Method to retrireve Release Notes from controller. 
   */
  getReleaseNotes = () => {
    return Promise.resolve(getReleaseNotes(AppConstants.RELEASE_NOTES_NUM_RECORDS).then(response => {
      if (!response.hasOwnProperty('error')) {
        logToConsole('Release notes from controller = ' + JSON.stringify(response));
        let initialButtonClickStatus = new Array(response.releaseNotes.length - 1);
        initialButtonClickStatus.fill(false);
        this.setState({
          releaseNotes: response.releaseNotes,
          historyButtonClick: initialButtonClickStatus
        });
      } else {
        logToConsole('Error retrieving release notes from controller');
      }
    }));
  }

  /**
   * Method to get release notes data from release notes JSON returned by controller
   */
  getReleaseNotesData = () => {
    let releaseNotesFromController = this.state.releaseNotes;
    let releaseNotesRecord = {};
    if (releaseNotesFromController && releaseNotesFromController.length > 0) {
      releaseNotesRecord = releaseNotesFromController[0];
    }
    let releaseNotes = {};
    if (releaseNotesRecord.hasOwnProperty('releasenotes')) {
      releaseNotes = releaseNotesRecord.releasenotes;
    }
    // Put the entries in the release notes JSON in specific order for display purposes
    var releaseNotesOrdered = {
      ...releaseNotes.version && { 'version': releaseNotes.version },
      ...releaseNotes.date && { 'date': releaseNotes.date },
      ...releaseNotes.features && { 'features': releaseNotes.features },
      ...releaseNotes.fixes && { 'fixes': releaseNotes.fixes }
    };
    return releaseNotesOrdered;
  }

  /**
   * Method to get historical release notes data from release notes JSON returned by controller
   */
  getReleaseNotesDataMultiple = () => {
    let releaseNotesFromController = this.state.releaseNotes;
    let releaseNotesOrdered = [];
    if (releaseNotesFromController && releaseNotesFromController.length > 0) {
      // Put the entries in the release notes JSON in specific order for display purposes
      // Display it as format of array
      let item;
      for (item = 0; item < releaseNotesFromController.length; item++) {
        let releaseNotes = releaseNotesFromController[item].releasenotes;
        let currentItem = {
          ...releaseNotes.version && { 'version': releaseNotes.version },
          ...releaseNotes.date && { 'date': releaseNotes.date },
          ...releaseNotes.features && { 'features': releaseNotes.features },
          ...releaseNotes.fixes && { 'fixes': releaseNotes.fixes }
        };
        releaseNotesOrdered[item] = currentItem;
      }

    }
    return releaseNotesOrdered;
  }

  /**
   * Toggle release notes history item button function
   */
  toggleButton(index) {
    let historyButtonClick = this.state.historyButtonClick;
    historyButtonClick[index] = !this.state.historyButtonClick[index];
    this.setState({
      historyButtonClick: historyButtonClick
    });
  }

  /**
 * Method to display release notes content in button collapse manner
 * @param {*} releaseNotesObj 
 * @param {*} index 
 */
  showReleaseNotesHistory = (releaseNotesObj, index) => {
    if (!releaseNotesObj || releaseNotesObj.length < index) {
      return (
        <div>
        </div>
      )
    }
    let releaseNotesPart = releaseNotesObj.slice(index);

    return (
      <div>
        {releaseNotesPart.map((notesContent, index) => {
          return (
            <div className={"row"} key={index}>
              <button type="button" className="collapse" onClick={this.toggleButton.bind(this, index)}>&#9656;&nbsp;&nbsp;<strong>{notesContent.version}</strong> - {notesContent.date}</button>
              {this.state.historyButtonClick[index] ? (
                showHistoryNoteItem(notesContent, true)
              ) : null
              }
            </div>
          )
        })
        }
      </div>
    )

  }

  render() {
    var releaseNotes = this.getReleaseNotesDataMultiple();
    if (releaseNotes.length > 0) {
      return (
        <div>
          <div className='awsui-util-spacing-v-s awsui-util-m-m'>
            {prettyPrintReleaseNotes(releaseNotes[0], true)}
          </div>
          {/* Display History of Release Notes */}
          <div className='awsui-util-spacing-v-s awsui-util-m-m'>
            <h2 className='awsui-util-t-l awsui-util-mt-xl blue-color-text'>
              {"Release History"}
            </h2>
            {this.showReleaseNotesHistory(releaseNotes, 1)}
          </div>
        </div>

      );
    } else {
      return (
        <div>
        </div>
      )
    }
  }
}

export default ReleaseNotes;