import { FormSection, ColumnLayout, Tiles, Select, Multiselect, Button, Alert, FormField, Checkbox } from '@amzn/awsui-components-react';
import React, {useEffect} from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_TEST_PLAN_MAP, TEST_PLAN_TAG_MAP } from '../../../../constants';
import {
  scenarioSliceSelector,
  customizationSelector,
  scenarioCategoryErrorSelector,
  setScenarioType, SCENARIO_TYPE
} from '../../../../stores/newRun/acm/scenarioSelectionSlice';
import { addCategoryScenarioMap, handleScenarioTypeSelection, removeCategoryScenarioMap, updateCategoryScenario } from '../../../../stores/newRun/acm/testCreationMiddleware';
import {deviceAttributesSliceSelector, setDeviceAttributes} from "../../../../stores/newRun/wwa/deviceAttributesSlice";
import {testInitSelector} from "../../../../stores/newRun/testInitSlice";

export default () => {
  const testInit = useSelector(testInitSelector);
  const scenarioType = useSelector(scenarioSliceSelector);
  const errors = useSelector(scenarioCategoryErrorSelector);

  useEffect(() => {
    dispatch(setScenarioType(SCENARIO_TYPE.WWA));
  }, []);

  const dispatch = useDispatch();
  const handleCustomization = (e) => {
    dispatch(
      handleScenarioTypeSelection(e.detail.value)
    );
  }
  const items = [
    {
      "label": <FormattedMessage id="WWA" />,
      "description": <FormattedMessage id="RUN_STANDARD_TESTS" />,
      "value": SCENARIO_TYPE.WWA
    }
  ]

  return <FormSection header={<FormattedMessage id="SCENARIO_SELECTION" />}>
    <ColumnLayout>
      <Tiles value={scenarioType} onChange={(handleCustomization)} items={items}></Tiles>
    </ColumnLayout>
    <CategoryScenarioMap errors={errors} />
    <DeviceAttributes />
  </FormSection>
}

const CategoryScenarioMap = ({errors}) => {
  const customizedScenarios = useSelector(customizationSelector);
  let errView;

  const dispatch = useDispatch();
  const onCatScenarioUpdate = ({category, scenarios, index}) => {
    dispatch(updateCategoryScenario({
      category,
      scenarios,
      index
    }))
  }

  const onRemove = ({index}) => {
    dispatch(removeCategoryScenarioMap({
      index
    }))
  }

  const onAdd = () => {
    dispatch(
      addCategoryScenarioMap()
    )
  }

  const MAX_CATEGORIES = Object.keys(CATEGORY_TEST_PLAN_MAP).length;

  const CATEGORY_LIST = Object.keys(CATEGORY_TEST_PLAN_MAP);


  const selectedCategories = customizedScenarios.map(({category}) => category);
  const catScenarioView = customizedScenarios.map(
    ({category, scenarios}, index) => {

      const categoryOptions = CATEGORY_LIST.filter(cat => !selectedCategories.includes(cat) && cat !== category);

      const scenarioOptions = CATEGORY_TEST_PLAN_MAP[category] || [];
      return <DrawCategoryScenarioRow
        key={index}
        categoryOptions={categoryOptions}
        scenarioOptions={scenarioOptions}
        index={index}
        selectedCategory={category}
        selectedScenarios={scenarios || []}
        onCatAndScenarioChange={onCatScenarioUpdate}
        canAdd={MAX_CATEGORIES !== index+1 && index === customizedScenarios.length - 1}
        onAdd={onAdd}
        canRemove={customizedScenarios.length > 1}
        onRemove={onRemove}
      />
    }
  )


  if(errors && typeof(errors["categoryScenarioMap"]) === "object") {
    errView = Object.entries(errors["categoryScenarioMap"])
      .map(
        (entry) => {
          const index = entry[0];
          const errorString = entry[1];
          return <Alert
            visible={true}
            key={index}
            className={"awsui-util-mt-s"}
            type="error"
          >
            {`${errorString} at index ${index}`}
          </Alert>
        }
      )
  }

  return <div>
    <div className='awsui-util-mt-xxl'>
      {errView}
    </div>
    <div className="awsui-grid awsui-util-mt-xxl">
      <div className="awsui-row">
        <div className="col-4"><FormattedMessage id="SELECT_DEVICE_CATEGORY" /></div>
        <div className="col-5"><FormattedMessage id="SELECT_TEST_PLAN" /></div>
        <div className="col-3"></div>
      </div>
      {catScenarioView}
    </div>
  </div>

}

const DeviceAttributes = () => {
  const dispatch = useDispatch();

  const deviceAttributes = useSelector(deviceAttributesSliceSelector);

  const handleDimmable = (e) => {
    dispatch(setDeviceAttributes({dimmable: e.detail.checked, irBlasters: deviceAttributes.irBlasters}))

  }

  const handleIRBlasters = (e) => {
    dispatch(setDeviceAttributes({irBlasters: e.detail.checked, dimmable: deviceAttributes.dimmable}))
  }

  return (
    <div className="awsui-grid awsui-util-mt-xxl">
      <div className="awsui-row">
        <div className="col-6">
          <FormField label={<FormattedMessage id="WWA_CHECKBOX_GROUP" />}>
            <Checkbox checked={deviceAttributes.dimmable} onChange={handleDimmable}>
              <FormattedMessage id="DIMMABLE" />
            </Checkbox>
            <Checkbox checked={deviceAttributes.irBlasters} onChange={handleIRBlasters}>
              <FormattedMessage id="I/R_BLASTERS" />
            </Checkbox>
          </FormField>
        </div>
      </div>
    </div>
  );
}


const DrawCategoryScenarioRow = ({ categoryOptions, scenarioOptions, selectedCategory, index, selectedScenarios, onCatAndScenarioChange, canAdd, onAdd, canRemove, onRemove }) => {

  const handleCategoryChange = (e) => {
    const category = e.detail.selectedId;
    onCatAndScenarioChange({category, index })
  }

  const handleScenarioSelection = (e) => {
    const scenarioOptions = e.detail.selectedOptions.map(({id})=>id);
    onCatAndScenarioChange({
      category: selectedCategory,
      scenarios: scenarioOptions,
      index
    });
  };

  const handleAdd = () => {
    onAdd({
      category: '',
      scenario: [],
    });
  }

  const handleRemove = () => {
    onRemove({
      category: selectedCategory,
      index
    });
  }

  return (
    <div className="awsui-row">
        <div className="col-4">
          <Select
            options={categoryOptions.map(category=> ({label: category, id: category}))}
            onChange={handleCategoryChange}
            selectedOption={{label: selectedCategory, id: selectedCategory}}
            selectedLabel="Selected" />
        </div>
        <div className="col-5">
          <Multiselect
            disabled={!selectedCategory}
            options={scenarioOptions.map(({scenarioId}) => ({label: TEST_PLAN_TAG_MAP[scenarioId]['scenario'], id: scenarioId}))}
            onChange={handleScenarioSelection}
            selectedOptions={selectedScenarios.map(scenarioId => ({label: TEST_PLAN_TAG_MAP[scenarioId]['scenario'], id: scenarioId}))}
            selectedLabel="Selected" />
        </div>
        <div className="col-3">
          {canRemove ? <Button onClick={handleRemove}><FormattedMessage id="REMOVE" /></Button> : null }
          {canAdd ? <Button onClick={handleAdd}><FormattedMessage id="ADD" /></Button> : null }
        </div>
    </div>

  )
}
