import {
  Button,
  ExpandableSection,
  Icon,
  Modal,
  Spinner,
  Tabs,
} from "@amzn/awsui-components-react";
import ld from "lodash";
import { TESTID_DEVICE_MAP } from "newSystem/constants/acmScenarios";
import React, { useCallback, useEffect, useState } from "react";
import { CardContainer, InfoContainer } from "./InfoContainer";
import ScenariosTable from "./ScenariosTable";
import StatementsTable from "./StatementsTable";
import "./styles.css";
import {TEST_PLAN_TAG_MAP} from "../../../newSystem/constants";
import {publishMessage} from "../../../Container/LiveRun/controller";

function ACMTestInfo({ testDetails }) {
  useEffect(() => {
    console.log(testDetails);
  }, []);
  const panesCount = 2;
  let panes = [];
  for (let i = 0; i < testDetails.length; i += testDetails.length / 2)
    panes.push(testDetails.slice(i, i + testDetails.length / 2));
  return (
    <div>
      <InfoContainer name="Test Info" panes={panes} />
    </div>
  );
}

function ACMDevicesInfo({ deviceMapping }) {
  let deviceDef = {
    header: (item) => item.deviceTag,
    sections: [
      {
        id: "customerId",
        header: "Customer Id",
        content: (item) => item.customerId,
      },
      {
        id: "dsn",
        header: "Device Serial No",
        content: (item) => item.dsn,
      },
      {
        id: "amazonId",
        header: "Amazon Id",
        content: (item) => item.amazonId,
      },
      {
        id: "deviceName",
        header: "Device Name",
        content: (item) => item.deviceName,
      },
    ],
  };
  let devicesList = [];
  Object.entries(deviceMapping).forEach(([deviceTag, device]) => {
    devicesList.push({ ...device, deviceTag: deviceTag });
  });
  return (
    <div className="">
      <CardContainer deviceDef={deviceDef} devices={devicesList} />
    </div>
  );
}

function getTestInfo(request) {
  const options = JSON.parse(request.options);
  const features = options.testOptions.features;
  const testSuite = options.testSuite;

  let scenarioNamesList = [];
  if (!ld.isNil(features)) {
    features.map((feature) => {
      let name;
      if (testSuite === 'WWA') {
        const tags = feature.split(' ')
        name = !ld.isEmpty(tags) && TEST_PLAN_TAG_MAP[tags[0]] && TEST_PLAN_TAG_MAP[tags[0]].scenario
      } else {
        name = TESTID_DEVICE_MAP[feature] && TESTID_DEVICE_MAP[feature].scenario
      }
      scenarioNamesList.push(name || feature);
    });
  }
  let scenarioNames = scenarioNamesList.join(", ");

  return [
    { Login: request.ownerEmail },
    { "Lab Name": request.labName },
    { "Test Suite": options.testSuite },
    { Scenarios: scenarioNames },
  ];
}

function getDevicesInfo(request) {
  return JSON.parse(request.mapping).deviceMapping;
}

function getScenarios(data) {
  let scenarios = ld.get(data, "payload");
  let items = [];
  if (!ld.isNil(scenarios)) {
    scenarios.map((scenario) => {
      items.push({
        name: scenario.name,
        status: scenario.state,
        id: scenario.id,
      });
    });
  }
  return items;
}

function getCurrentScenario(data) {
  let scenarios = getScenarios(data);
  let running = scenarios.filter((item) => item.status === "INPROGRESS");
  let failed = scenarios.filter((item) => item.status === "FAILED");
  if (!ld.isNil(running) && running.length > 0) {
    return running[0];
  } else if (!ld.isNil(failed) && failed.length >= 1) {
    return failed[0];
  } else if (scenarios.length > 0) {
    return scenarios[scenarios.length - 1];
  }
  return [];
}

function getStatements(scenario = [], data) {
  let items = [];
  if (scenario.length > 0) {
    let _scenario = ld.get(data, `${scenario[0].id}`);
    if (!ld.isNil(_scenario)) {
      let statements = _scenario.statements;
      statements.map((st) => {
        items.push({
          name: st.statement,
          status: st.state,
          response: ld.get(st, "response.report", {}),
        });
      });
    }
  }
  return items;
}

function getStatementStatus(statement) {
  switch (statement.status) {
    case "SUCCESS":
    case "COMPLETE":
      return "positive";
    case "FAILED":
      return "negative";
    case "INPROGRESS":
    default:
      return "info";
  }
}

function getScenarioDef() {
  return [
    {
      id: "scenarioName",
      header: "Scenario Name",
      cell: (item) => <div>{item.name}</div>,
    },
    {
      id: "scenarioStatus",
      header: "Status",
      cell: (item) => (
        <div className={`awsui-util-status-${getStatementStatus(item)}`}>
          {item.status}
        </div>
      ),
    },
  ];
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const capitalizeFirst = (str) => {
  if (!ld.isNil(str) && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

function RenderKeyValue({ data }) {
  const isUrl = isValidHttpUrl(data[1]);
  return (
    <div>
      <div className="awsui-util-label">{capitalizeFirst(data[0])}</div>
      <div>{isUrl && <a href={data[1]}>Download recording</a>}</div>
      <div>{!isUrl && capitalizeFirst(data[1])}</div>
    </div>
  );
}

function getStatementDef() {
  return [
    {
      id: "stName",
      header: "Statement Name",
      cell: (item) => (
        <div className="bddtext awsui-util-t-l awsui-util-label awsui-util-mv-xs">
          {item.name}
        </div>
      ),
    },
    {
      id: "stReportInfo",
      header: "Response",
      cell: (item) => (
        <ExpandableSection variant="navigation">
          {Object.entries(item.response).map((key, index) => (
            <RenderKeyValue data={key} />
          ))}
        </ExpandableSection>
      ),
    },
    {
      id: "stStatus",
      header: "Status",
      cell: (item) => (
        <div
          className={`flex-container awsui-util-status-${getStatementStatus(
            item
          )}`}
        >
          <Icon name={`status-${getStatementStatus(item)}`}></Icon>
          {item.status}
        </div>
      ),
    },
  ];
}

function ACMLiveRun({ request, data }) {
  const [currentScenario, setCurrentScenario] = useState([]);
  const [isTestPaused, setIsTestPaused] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");

  const [testStatus, setTestStatus] = useState("STARTING");

  useEffect(() => {
    const defaultScenario = [getCurrentScenario(data)];
    const defaultStatus = ld.get(data, "testStatus.status", "STARTING");

    setTestStatus(defaultStatus);

    showActionMessageInPopup();

    // Close popup once test has been terminated
    if (!ld.isEmpty(defaultScenario) && defaultScenario[0].status == 'TERMINATE') {
      setIsTestPaused(false);
    }

    console.log(">>scenario set", defaultScenario);
  }, [data]);

  const handleScenarioOnClick = useCallback(
    (selectedItem) => {
      console.log("handler invoked", selectedItem);
      if (!ld.isNil(selectedItem)) {
        setCurrentScenario(selectedItem);
        console.log(">>scenario changed", selectedItem);
      }
    },
    [currentScenario]
  );

  const showActionMessageInPopup = () => {
    const messageInfo =  ld.get(data, 'action_message_to_show_in_console', {});

    // If execution state as pause, show popup
    if (!ld.isNil(messageInfo) && ld.isEqual(messageInfo.executionState, 'PAUSE')) {
      setCurrentMessage(messageInfo);
      setIsTestPaused(true);
    }
  }

  const handleConfirm = async () => {
    const jobId = request.id;
    const payload = {message: "confirm", state: "complete"};
    publishMessage(jobId, currentMessage.id, payload);
    setIsTestPaused(false);

  }

  const handleCancel = () => {
    const payload = {message: "cancel", state: "failed"};
    publishMessage(request.id, currentMessage.id, payload);
    setIsTestPaused(false);
  }

  const confirmationModal = () => {
    const messageArr = ld.split(currentMessage.message, '\n')
    const content = messageArr.map(msg => (<p>{msg}</p>))

    return(
      <Modal
        content={content}
        visible={isTestPaused}
        header={"Following steps need be completed before continuing test"}
        expandToFit={true}
        footer={<span className='awsui-util-f-r'>
            <span className="awsui-util-mr-s">Are you sure you complete all steps?</span>
                <Button variant='link' text='Cancel'
                              onClick={handleCancel}>
                </Button>
                <Button variant='primary' text='Confirm'
                              onClick={handleConfirm}>
                </Button>
              </span>}
      />)
}

  console.log(">>rendering acm live run w ", currentScenario);
  return (
    <div>
      <Tabs
        tabs={[
          {
            label: "Test Info",
            id: "testInfo",
            content: <ACMTestInfo testDetails={getTestInfo(request)} />,
          },
          {
            label: "Device Info",
            id: "deviceInfo",
            content: <ACMDevicesInfo deviceMapping={getDevicesInfo(request)} />,
          },
        ]}
        activeTabId="testInfo"
      ></Tabs>
      {!ld.isNil(currentScenario) && testStatus !== "SUCCEEDED" && !ld.get(data, "payload") && (
        <div className="awsui-util-t-c">
          <div>
            <Spinner size="large"></Spinner>
          </div>
          <div>{testStatus.replace("_", " ")}</div>
        </div>
      )}

      {testStatus === "SUCCEEDED" && (
        <div className="awsui-util-status-info awsui-util-font-size-4 awsui-util-t-c">
          Test Completed
        </div>
      )}

      <ScenariosTable
        items={getScenarios(data)}
        defaultItem={currentScenario}
        columnDefinitions={getScenarioDef()}
        onClickHandler={handleScenarioOnClick}
      />
      <div>
        <br />
      </div>
      <StatementsTable
        items={getStatements(currentScenario, data)}
        columnDefinitions={getStatementDef()}
      />
      {confirmationModal()}
    </div>
  );
}

export default React.memo(ACMLiveRun);
