import React from "react";
import { logToConsole } from '../../Util';
import AppConstants from '../../_Constants/AppConstants';
import {
  OFFLINE_MESSAGE,
  PLUGIN_INSTALL_REQUIRED,
  PLUGIN_UPDATE_IN_PROGRESS,
  PLUGIN_UPDATE_CONFIG_IN_PROGRESS,
  PLUGIN_UPDATE_CONFIG_SUCCESS,
  PLUGIN_UPDATE_CONFIG_FAILED,
  PLUGIN_INSTALLED,
  PLUGIN_NOT_INSTALLED,
  RASPI_ONLINE,
  PLUGIN_INSTALL_DEFAULT_STATE,
  PLUGIN_INSTALL_SETUP,
  PLUGIN_INSTALL_IN_PROGRESS,
  PLUGIN_INSTALL_REBOOT,
  PLUGIN_INSTALL_COMPLETE,
  PLUGIN_INSTALL_SHOW_CONFIGS,
  START_HOTSPOT_PAYLOAD,
  STOP_HOTSPOT_PAYLOAD,
  HOTSPOT_STATUS_NONE,
  HOTSPOT_STATUS_ON,
  HOTSPOT_STATUS_OFF,
  PLUGIN_STOP_HOTSPOT,
  PLUGIN_START_HOTSPOT,
  REBOOT_PI_PAYLOAD,
  WIFI_BAND_RANGE,
  BG_CHANNEL_RANGE,
  A_CHANNEL_RANGE,
  INTERFACE
} from '../../_Constants/AQTHotspotConstants';
import AWSUI from '@amzn/awsui-components-react';
import {
  pingRasPi,
  installHotspotPluginOnRasPi,
  sleep,
  getHotspotConfigs,
  retryPing,
  createHotspot,
  commonOps,
  getConnectedDevices
} from './controller';
import ld from 'lodash';

// Color used to indicate Important Notes in execution
const styles = {
    color: '#eb5f07'
}


export default class HotspotController extends React.Component {

  constructor(props) {
    super(props);

    const companyId = this.props.params.labData.lab.companyId;
    let rasPis = this.props.params.labData.rasPis;
    let rasPiMapping = this.props.params.labData.preference.actor_mapping_preference;
    let rasPisDropdownData = []
    let rasPiLookupByName = {}

    // prepare data for raspi loc dropdown
    for (let rasPi of rasPis) {
      let rasPiName;
      if (rasPi.name.indexOf(companyId) !== -1) {
        rasPiName = rasPi.name.replace(companyId + '-', '');
      } else {
        rasPiName = rasPi.name;
      }
      logToConsole("Found raspi name: ", rasPiName);
      rasPisDropdownData.push({ 'label': rasPiName, 'id': rasPi.id, 'location': rasPiMapping[rasPi.id].location });
      rasPiLookupByName[rasPiName] = rasPi; // build raspi lookup map by name
    }

    // initializing states
    this.state = {
      rasPiLookup: rasPiLookupByName,  // raspi lookup table
      labId: this.props.params.labId,  // points to current lab under selection
      companyId,                       // companyId used to send mqtt message
      rasPis: rasPisDropdownData,      // list of all the available raspi from the lab
      rasPi: rasPisDropdownData[0],    // current rasPi
      hotspotStatus: HOTSPOT_STATUS_OFF,  // hotspot status
      isLoading: true, // loader
      onlineStatus: false,
      ssid: '', // ssid of current rasPi
      passphrase: '', // passphrase of current rasPi
      oldSSID: '',
      oldPassphrase: '',
      wifiBand: null,
      channel: '6',
      oldWifiBand: null,
      oldChannel: null,
      wifiInterface: '',
      toggleState: true, // toggle password
      pluginInstallRequired: false, // determines plugin install reqd on current rasPi
      pluginInstallState: PLUGIN_INSTALL_DEFAULT_STATE, // plugin state on current rasPi
      updateConfig: false, // display update option
      configsChanged: false, // decide if configs are updated or not
      connectedDevices: [],  // connected devices list
      openModal: false,  // open/close modal
      modalLoader: false, // loader  inside modal
      renderDUTStatus: false,
      isDUTConnected: false,
      mac_addr: ''
    }
    logToConsole(this.props);


  }

  componentDidMount() {
    this.startProbe(); // start hotspot discovery on 1st location from dropdown
  }

  /**
   * get ping status of the raspi;
   * this relies on the labData passed
   * on to this component as a prop to
   * detect the online/offline states
   */
  getPingStatus = async () => {
    const { labId, rasPi } = this.state;
    // Method - 1 is faster since it uses props passed from parent component
    // lookup for raspiObj using dropdown label as raspi name
    let rasPiObj = this.state.rasPiLookup[rasPi.label]
    this.setState({ onlineStatus: rasPiObj.rasPiStatus.status });
    // Methond - 2 is slower since it performs nw ops
    // but if pi goes offline after arriving to hotspot tab
    // then method 2 is preferred

    // const onlineStatus = await pingRasPi(labId, rasPi.id);
    // console.log('getPingStatus => ', onlineStatus);
    // this.setState({ onlineStatus });

    return new Promise(resolve => resolve(true));
  }

  /**
   * retrieve hotspot configs and update the states accordingly
   */
  getHotspotConfigsFromRaspi = async () => {
    let {labId, rasPi, companyId, onlineStatus, pluginInstallState, hotspotStatus } = this.state;
    switch (onlineStatus) {
      case RASPI_ONLINE:
      case AppConstants.ONLINE:
        logToConsole("Raspi location: ", rasPi.label, "is Online")
        break; // break the flow and continue
      default:
        logToConsole("Raspi location: ", rasPi.label, "is Offline")
        onlineStatus = false;
        this.setState({ isLoading: false, onlineStatus });
        return; // break the flow and return
    }

    this.setState({ pluginInstallState: PLUGIN_INSTALL_COMPLETE })
    // request for get hotspot config via controller api
    const response = await getHotspotConfigs(companyId, rasPi.label);

    logToConsole(response)
    let ssid = '';
    let passphrase = '';
    let oldSSID = '';
    let oldPassphrase = '';
    let wifiBand = "";
    let channel = "";
    let oldWifiBand = "";
    let oldChannel = "";
    let wifiInterface = "";
    let pluginInstallRequired = false;
    let status = response && response.status ? response.status : PLUGIN_NOT_INSTALLED;
    logToConsole('Response', status, response.data);
    switch (status) {
      case PLUGIN_NOT_INSTALLED:
        pluginInstallRequired = true;
        pluginInstallState = PLUGIN_INSTALL_DEFAULT_STATE;
        onlineStatus = true;
        pluginInstallState = PLUGIN_INSTALL_SETUP;
        break;
      case PLUGIN_INSTALLED: // success
        try {
          const piResponse = response.data.payload;
          oldSSID = ssid = piResponse.message.ssid;
          oldPassphrase = passphrase = piResponse.message.passphrase;
          if(piResponse.message && piResponse.message.wifi && piResponse.message.wifi.band){
            wifiBand = oldWifiBand = piResponse.message.wifi.band;
          }
          if(piResponse.message && piResponse.message.wifi && piResponse.message.wifi.channel){
            channel = oldChannel = piResponse.message.wifi.channel;
          }
          if(piResponse.message && piResponse.message && piResponse.message.device_info){
            wifiInterface = piResponse.message.device_info.interface;
          }
          hotspotStatus = piResponse.message.status === HOTSPOT_STATUS_ON ? HOTSPOT_STATUS_ON : HOTSPOT_STATUS_OFF
          pluginInstallState = PLUGIN_INSTALL_SHOW_CONFIGS;
          break;
        } catch (err) {
          logToConsole("Exception caught fetching raspi config", err);
          //continue below
        }
      case AppConstants.NETWORK_ERROR:
      case AppConstants.SERVERERR:
      default:
        pluginInstallRequired = false;
        onlineStatus = false;
        break;
    }
    // update states for raspi online status/ssid/passphrase/loader
    this.setState({
      isLoading: false,
      configsChanged: false,
      pluginInstallRequired,
      ssid,
      passphrase,
      oldSSID,
      oldPassphrase,
      wifiBand,
      oldWifiBand,
      channel,
      oldChannel,
      wifiInterface,
      onlineStatus,
      pluginInstallState,
      hotspotStatus
    });
  }

  /**
   * install hotspot plugin
   */
  installPlugin = async () => {
    let { labId, rasPi, onlineStatus, companyId, pluginInstallRequired, pluginInstallState } = this.state;
    this.setState({ isLoading: true, pluginInstallState: PLUGIN_INSTALL_IN_PROGRESS });  // start loader

    const status = await installHotspotPluginOnRasPi(labId, rasPi.id);
    logToConsole('plugin install status: ', status);

    if (status !== true) {
      await sleep(10000); // controller might have timed out

      pluginInstallState = PLUGIN_INSTALL_SETUP;
      pluginInstallRequired = true;

      this.setState({ isLoading: false, pluginInstallRequired, pluginInstallState });
    } else {
      // proceed with install
      this.setState({ pluginInstallState: PLUGIN_INSTALL_REBOOT });

      // wait for mdxirs to reboot after
      // install/update of plugin
      await sleep(10000);

      logToConsole('rebooting pi');
      await commonOps(companyId, rasPi.label, REBOOT_PI_PAYLOAD);

      onlineStatus = await retryPing(labId, rasPi.id, 5);
      logToConsole('raspi ping status: ', onlineStatus)

      if (!onlineStatus) {
        pluginInstallState = PLUGIN_INSTALL_DEFAULT_STATE;
        this.setState({ isLoading: false, onlineStatus, pluginInstallState });
      } else {
        pluginInstallState = PLUGIN_INSTALL_COMPLETE;
        this.setState({ onlineStatus, pluginInstallState });
        // start hotspot and update the configs
        await this.triggerHotspotOps('start_hotspot');
      }
    }
  }


  /**
   * render alert message
   */
  renderAlertMessage = (message, alertType) => {
    return (
      <div>
        <AWSUI.Alert header={message} type={alertType} />
      </div>
    );
  }

  /**
   * on raspi select update ui states
   */
  onRaspiDropDownChange = (event) => {
    let newRaspi = event.detail.selectedOption;
    // reset states for new raspi selection
    this.setState({
      ...this.state.rasPis,
      rasPi: newRaspi,
      isLoading: true,
      pluginInstallRequired: false,
      onlineStatus: false,
      pluginInstallState: PLUGIN_INSTALL_DEFAULT_STATE,
    });
    this.startProbe();
  }

  startProbe = () => {
    // check for ping status first and if the device is online;
    // then request for get hotspot config
    this.getPingStatus().then(response => {
      // logToConsole(response); // fake response to indicate ping is done
      this.getHotspotConfigsFromRaspi();  // chk state on ping status
    });
  }
  /**
   * render raspi location drop down ui component
   */
  renderRaspiLocationDropdownData = () => {
    let rasPisDropdownData = this.state.rasPis;

    return (
      <AWSUI.Select
        options={rasPisDropdownData}
        selectedOption={rasPisDropdownData[0]}
        selectedLabel=''
        onChange={this.onRaspiDropDownChange}
      />
    );
  }

  /**
   * toggle show/hide password
   */
  togglePasswordMask = () => {
    logToConsole("show password toggle state ", this.state.toggleState);
    const { toggleState } = this.state;
    this.setState({ toggleState: !toggleState });
  }

  triggerHotspotOps = async (event, refreshConfigs) => {
    const action = event.detail && event.detail.id || event;
    logToConsole("action received: ", action);
    const { labId, rasPi, companyId } = this.state;
    let response;

    switch (action) {
      case 'start_hotspot':
        this.setState({ isLoading: true, pluginInstallState: PLUGIN_START_HOTSPOT });
        response = await commonOps(companyId, rasPi.label, START_HOTSPOT_PAYLOAD);
        break;
      case 'stop_hotspot':
        this.setState({ isLoading: true, pluginInstallState: PLUGIN_STOP_HOTSPOT });
        response = await commonOps(companyId, rasPi.label, STOP_HOTSPOT_PAYLOAD);
        response = !response;
        break;
      case 'update_hotspot':
        this.setState({ isLoading: true, pluginInstallState: PLUGIN_UPDATE_IN_PROGRESS });
        const status = await installHotspotPluginOnRasPi(labId, rasPi.id);
        logToConsole('plugin install status: ', status);
        await sleep(5000);
      // do not break since we wanted to perform a reboot
      // after update since reboot is mandatory after every
      // update/install of the hotspot plugin
      case 'reboot_pi':
        this.setState({ isLoading: true, pluginInstallState: PLUGIN_INSTALL_REBOOT });
        await commonOps(companyId, rasPi.label, REBOOT_PI_PAYLOAD);
        const onlineStatus = await retryPing(labId, rasPi.id, 5);
        logToConsole('rasberry pi online status: ', onlineStatus);
        break;
      case 'get_connected_devices':
        this.setState({ openModal: true, modalLoader: true });
        let devices = [];
        let devicesResponse = await getConnectedDevices(
          companyId,
          rasPi.label
        );

        devicesResponse.data.forEach((device) => {
          if (device &&
            'name' in device &&
            'mac_addr' in device &&
            'ip' in device) {
            devices.push({
              label: device.name,
              macAddr: device.mac_addr,
              description: device.ip,
              value: device.ip,
            });
          }
        });

        this.setState({ modalLoader: false, connectedDevices: devices })
        return;
      default:
        // do nothing;
        break;
    }

    const hotspotStatus = response ? HOTSPOT_STATUS_ON : HOTSPOT_STATUS_OFF;

    this.setState({ hotspotStatus });

    // do not fetch configs unless refreshConfig parameter is not set or true
    if (ld.isNil(refreshConfigs) || refreshConfigs === true) {
      return this.getHotspotConfigsFromRaspi();
    }

  }

  /**
   * Config update event on ssid/password fields
   */
  onConfigsChangeEvent = configType => event => {
    const { oldSSID, oldPassphrase , oldWifiBand, oldChannel} = this.state;
    switch (configType) {
      case 'SSID':
        this.setState(
          {
            configsChanged: oldSSID !== event.detail.value,
            ssid: event.detail.value
          }
        );
        break;
      case 'PASSPHRASE':
        this.setState(
          {
            configsChanged: oldPassphrase !== event.detail.value,
            passphrase: event.detail.value
          }
        );
        break;
      case 'BAND':
        this.setState(
          {
            configsChanged: oldWifiBand !== event.detail.selectedOption.value,
            wifiBand: event.detail.selectedOption.value,
            channel: ""
          }
        );
        break;
      case 'CHANNEL':
        this.setState(
            {
              configsChanged: oldChannel !== event.detail.selectedOption.value,
              channel: event.detail.selectedOption.value
            }
        );
        break;

    }
  }

  /**
   * Trigger update ssid/password on the lab location
   */
  updateConfigAction = async () => {
    const { companyId, rasPi, ssid, passphrase, oldSSID, oldPassphrase, configsChanged } = this.state;
    const { wifiBand, channel, oldWifiBand, oldChannel, wifiInterface} = this.state;
    if (configsChanged === true) {
      this.setState({ isLoading: true, pluginInstallState: PLUGIN_UPDATE_CONFIG_IN_PROGRESS, updateConfig: false });  // start loader
      const status = await createHotspot(companyId, rasPi.label, ssid, passphrase, wifiBand, channel, wifiInterface);
      logToConsole('updated ssid/passphrase: ', status);

      // update loader message
      if (status === true) {
        this.setState({ pluginInstallState: PLUGIN_UPDATE_CONFIG_SUCCESS });
        await this.triggerHotspotOps('stop_hotspot', false);
        await sleep(2000);
        await this.triggerHotspotOps('start_hotspot');
      } else {
        this.setState({ pluginInstallState: PLUGIN_UPDATE_CONFIG_FAILED });
        this.getHotspotConfigsFromRaspi();
      }
    } else {
      this.setState({ updateConfig: false, ssid: oldSSID, passphrase: oldPassphrase });  // reset configs if no updates
      if(oldWifiBand && oldChannel){
        this.setState({ wifiBand: oldWifiBand, channel: oldChannel });
      }
    }

  }

  /**
   * Render connected devices
   */
  renderConnectedDevices = () => {
    const { connectedDevices } = this.state;
    logToConsole(connectedDevices);
    logToConsole(connectedDevices && connectedDevices.length > 0);

    const columnDefinitions = [
      {
        id: "deviceName",
        header: "Device name",
        cell: item => item.label || "-"
      },
      {
        id: "macAddr",
        header: "Mac Address",
        cell: item => item.macAddr || "-"
      },
      {
        id: "ip",
        header: "IP",
        cell: item => item.value || "-"
      }
    ]

    return (
      <AWSUI.Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={connectedDevices}
        header={
          <h2>
            Connected Devices
          </h2>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>
                No Devices
          </b>
            </div>
            <p className="awsui-util-mb-s">
              No connected devices to the hotspot.
          </p>
          </div>
        }
      ></AWSUI.Table>
    );
  };

  /**
   * render hotspot config with SSID/Passphrase
   */
  renderHotspotConfig = () => {
    const { toggleState, ssid, passphrase, wifiInterface, wifiBand, channel, hotspotStatus, updateConfig } = this.state;

    logToConsole("Hotspot status: ", hotspotStatus);
    logToConsole(wifiBand);
    let options = BG_CHANNEL_RANGE;
    let value = "";

    const hotspotRunStatus = hotspotStatus == HOTSPOT_STATUS_ON ? "status-positive" : "status-negative";
    const hotspotStatusIcon = hotspotStatus == HOTSPOT_STATUS_ON ? "awsui-util-status-positive" : "awsui-util-status-negative";

    if(wifiBand){
        if (wifiBand == "bg"){
            options = BG_CHANNEL_RANGE;
        } else{
            options = A_CHANNEL_RANGE;
        }
        let regex = /(wlx)\w+/g;
        if(wifiInterface.match(regex)){
            value = 'wlx';
        }
    }
    return (
      <div>
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-2 awsui-util-font-size-2">
              Hotspot Status
              </div>
            <div className="col-4">
              <AWSUI.Icon
                name={hotspotRunStatus}
                className={hotspotStatusIcon}
              />
              {" "}Hotspot {hotspotStatus == HOTSPOT_STATUS_ON ? "ON" : "OFF"}
            </div>
          </div>
          <div className="awsui-row">
            <div className="col-2 awsui-util-font-size-2">
              SSID
              </div>
            <div className="col-4">
              <AWSUI.Input
                value={ssid}
                disabled={!updateConfig}
                onInput={this.onConfigsChangeEvent('SSID')} />
            </div>
          </div>
          <div className="awsui-row">
            <div className="col-2 awsui-util-font-size-2">
              Passphrase
            </div>
            <div className="col-4">
              <AWSUI.Input
                value={passphrase}
                type={toggleState ? "password" : "text"}
                disabled={!updateConfig}
                onInput={this.onConfigsChangeEvent('PASSPHRASE')} />
            </div>
            <div className="col-1">
              <AWSUI.Button
                variant="link"
                icon={toggleState ? "unlocked" : "lock-private"}
                onClick={this.togglePasswordMask} />
            </div>
            {/* <IconButton
              aria-label="Show Password"
              onClick={this.togglePasswordMask}
            >
              {toggleState ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton> */}

          </div>

          {wifiInterface && (
            <div className="awsui-row">
              <div className="col-2 awsui-util-font-size-2">
                Wifi Interface
              </div>
              <div className="col-4">
                  <AWSUI.Select
                      options={INTERFACE}
                      selectedOption={this.findSelectedOption(INTERFACE, value)}
                      disabled
                  />
              </div>
            </div>)}
          {wifiBand && (
            <div>
            <div className="awsui-row">
              <div className="col-2 awsui-util-font-size-2">
              Wifi Band
              </div>
              <div className="col-4">
              <AWSUI.Select
                options={WIFI_BAND_RANGE}
                selectedOption={this.findSelectedOption(WIFI_BAND_RANGE, wifiBand)}
                disabled={!updateConfig}
                onChange={this.onConfigsChangeEvent('BAND')} />
              </div>
            </div>
              <div className="awsui-row">
                <div className="col-2 awsui-util-font-size-2">
                  Wifi Channel
                </div>
                <div className="col-4">
                  <AWSUI.Select
                    options={options}
                    selectedOption={this.findSelectedOption(options, channel)}
                    disabled={!updateConfig}
                    onChange={this.onConfigsChangeEvent('CHANNEL')} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  /**
   * show loader on action
   */
  renderLoader = () => {
    return (
      <div className="awsui-util-t-c">
        <AWSUI.Spinner size="large" />
      </div>
    )
  }

  /**
   * render hotspot parent component
   */
  renderHotspotInfoPane = () => {
    const { isLoading, onlineStatus, pluginInstallRequired, updateConfig, passphrase, configsChanged } = this.state;

    let wifiOps = [
      {
        text: "Start Hotspot",
        id: "start_hotspot",
        disabled: !onlineStatus,
      },
      {
        text: "Stop Hotspot",
        id: "stop_hotspot",
        disabled: !onlineStatus,
      },
      {
        text: "Reboot",
        id: "reboot_pi",
        disabled: !onlineStatus,
      },
      {
        text: "Connected Devices",
        id: "get_connected_devices",
        disabled: !onlineStatus,
      },
      {
        text: "Update Hotspot Software",
        id: "update_hotspot",
        disabled: !onlineStatus,
      },
    ];

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Hotspot Configuration</h2>
              <div className="awsui-util-container-header-description">
                Raspberry Pi Name: {this.state.rasPi.label}
              </div>
            </div>
            <div className="awsui-util-action-stripe-group">
              <AWSUI.ButtonDropdown
                items={wifiOps}
                onItemClick={this.triggerHotspotOps}>
                Hotspot Operations
              </AWSUI.ButtonDropdown>
            </div>
          </div>
        </div>
        <div>
          {isLoading ? this.renderLoader() : onlineStatus ? pluginInstallRequired ? this.renderAlertMessage(PLUGIN_INSTALL_REQUIRED, 'info') : this.renderHotspotConfig() : this.renderAlertMessage(OFFLINE_MESSAGE, 'error')}
          <br />
          {this.renderHotspotInstallationProgress()}
        </div>
        <div className="awsui-util-container-footer awsui-util-t-c">
          {onlineStatus && !isLoading && !updateConfig &&
            (<AWSUI.Button
              onClick={() => this.setState({ updateConfig: true, configsChanged: false })}>
              Edit Configuration
            </AWSUI.Button>)
          }
          {onlineStatus && !isLoading && updateConfig && (
            <div>
              <AWSUI.Button
                onClick={this.updateConfigAction}
                disabled={(configsChanged === false || passphrase.length < 8)}>Update</AWSUI.Button>
              <AWSUI.Button onClick={() => {
                const { oldSSID, oldPassphrase ,oldWifiBand, oldChannel} = this.state;
                this.setState({ ssid: oldSSID, passphrase: oldPassphrase, updateConfig: false }); // reset to old configs
                if(oldWifiBand && oldChannel){
                  this.setState({ wifiBand: oldWifiBand, channel: oldChannel });
                }
              }}>Cancel</AWSUI.Button>
            </div>
          )}
        </div>
        <div>
          {onlineStatus && !isLoading && !updateConfig &&
            (<div className='awsui-util-mr-s' style={styles}>
                <div>
                  <p>* NOTE: Please check Hotspot Operations -> Connected Devices to get latest connection status after connecting DUT with Hotspot.</p>
                </div>
            </div>)
          }
      </div>
      </div>
    );
  }
  findSelectedOption = ( options,  index) => {
    for (let wifiOption of options) {
      if(wifiOption.value == index)
        return wifiOption
    }
    return { value: "", label: '' }
  }

  renderHotspotInstallationProgress = () => {
    const { pluginInstallState } = this.state;
    switch (pluginInstallState) {
      case PLUGIN_INSTALL_IN_PROGRESS:
      case PLUGIN_UPDATE_IN_PROGRESS:
      case PLUGIN_UPDATE_CONFIG_IN_PROGRESS:
      case PLUGIN_STOP_HOTSPOT:
      case PLUGIN_START_HOTSPOT:
      case PLUGIN_INSTALL_REBOOT:
      case PLUGIN_INSTALL_COMPLETE:
        return (
          <div className="awsui-util-t-c">
            {pluginInstallState}
          </div>
        );
      case PLUGIN_INSTALL_DEFAULT_STATE:
      case PLUGIN_INSTALL_SHOW_CONFIGS:
        return (<div></div>);
      case PLUGIN_INSTALL_SETUP:
      default:
        return (
          <div className="awsui-util-t-c">
            <AWSUI.Button variant="primary" onClick={this.installPlugin}>Setup Hotspot</AWSUI.Button>
          </div>
        );
    }
  }

  /**
   * Modal to show connected devices
   */
  renderModal = () => {
    const { openModal, modalLoader } = this.state;
    return (
      <AWSUI.Modal
        header={this.state.oldSSID}
        expandToFit={true}
        visible={openModal}
        onDismiss={() => { this.setState({ openModal: false }) }}>
        {modalLoader ? this.renderLoader() : this.renderConnectedDevices()}
      </AWSUI.Modal>
    );
  }

  render() {
    return (
      <div className="awsui-grid">
        <div className="awsui-row">
          <div className="col-1 awsui-util-font-size-3">
            Location
          </div>
          <div className="col-3">
            {this.renderRaspiLocationDropdownData()}
          </div>
        </div>
        <br />
        {this.renderModal()}
        {this.renderHotspotInfoPane()}
      </div>
    );
  }
}
