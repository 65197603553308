const MobileConstants = {
  // List of tests to execute for Alexa Hands-Free Verification Test Standard scenario
  ALEXA_HF_VERIFICATION_STANDARD_TEST_CASES: [
    'Voice Deletion',
    'Retrain Voice Profile',
    'Multi-Turn',
    'Navigation',
    'Barge-In',
    'Music',
    'Verify Hands-Free',
    'Tap-to-Talk',
    'Alexa Widget',
    'Kill Hands-Free',
    'Force-stop Hands-Free',
    'Update DSP',
    'Quick-Settings Toggle'
  ],

  // List of tests to execute for Device Lock Hands-Free Verification Test scenario
  DEVICE_LOCK_HF_VERIFICATION_TEST_CASES: [
    'Responds while Locked Enabled',
    'Responds while Locked Disabled'
  ],

  // List of tests to execute for App Verification Test Standard scenario
  APP_VERIFICATION_STANDARD_TEST_CASES: [
    'Verify DSP apk is installed',
    'Alexa Login Screen Verification',
    'Verify mShop apk is installed',
    'mShop Tag Attribute Verification'
  ],

  // SPECIFIC LOCALES FOR EACH MOBILE SUITE SCENARIO - Add the locale if it is specific to a scenario type
  MOBILE_SCENARIO_MARKETPLACE_MAP: {
    "MOBILE_FUNCTIONAL": [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }]
  },
  CUSTOM_MOBILE_VALIDATION: "CUSTOM_MOBILE_VALIDATION",
  DEVICE_VALIDATION: "Device Validation",
  ALEXA_CLOUD_VALIDATION: "Alexa Cloud Validation",
  DEVICE_LOCK_HANDS_FREE_VERIFICATION: "DEVICE_LOCK_HANDS_FREE_VERIFICATION",
}

export default {
  ...MobileConstants,
};
