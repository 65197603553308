import {
  Alert,
  Button,
  ColumnLayout,
  ExpandableSection,
  FormField,
  FormSection,
  Select,
  Spinner,
} from "@amzn/awsui-components-react";
import { get, isEmpty } from "lodash";
import {
  testMappingSelector,
  addMapping,
} from "newSystem/stores/newRun/mappingSlice";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, UNNAMED_DEVICE } from "../../../../constants";
import {
  deviceAccSelector,
  labConfigSelector,
} from "../../../../stores/newRun/acm/labConfigSlice";
import {
  handleLabSelection,
  setupInitialLabConfig,
} from "../../../../stores/newRun/acm/testCreationMiddleware";
import { customerIdSelector } from "../../../../stores/newRun/customerIdSlice";
import {
  dutListSelector,
  fetchDevices,
} from "../../../../stores/newRun/deviceListSlice";
import { labsSelector } from "../../../../stores/newRun/labSlice";
import {
  piSelector,
  updatePiWithCustomNames,
} from "../../../../stores/newRun/piSlice";
import { testInitSelector } from "../../../../stores/newRun/testInitSlice";

export default () => {
  const {
    status: labFetchStatus,
    labs,
    error: labFetchErr,
  } = useSelector(labsSelector);
  const { labInfo, error: labValidationError } = useSelector(labConfigSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setupInitialLabConfig(labInfo.id));
  }, []);

  const handleLabChange = (e) => {
    const newLabInfo = e.detail.selectedOption.meta;
    dispatch(handleLabSelection(newLabInfo));
  };

  let labOptions = [];
  if (labFetchStatus === API_STATUS.SUCCESS) {
    labOptions = labs.map((lab) => ({
      id: lab.id,
      label: lab.name,
      meta: lab,
      disabled: lab.lock === 1,
    }));
  }

  let selectedOption = {};
  if (labInfo.id) {
    selectedOption = { id: labInfo.id, label: labInfo.name };
  }

  const labInfoError = get(labValidationError, "labInfo");
  const accountInfoError = get(labValidationError, "account");
  let labFetchError;
  if (labFetchStatus === API_STATUS.ERROR) {
    labFetchError = (
      <Alert type="error" key={1} className="awsui-util-mt-s awsui-util-mb-s">
        <div>{labFetchErr}</div>
      </Alert>
    );
  }

  let accountValidationView;
  if (accountInfoError) {
    accountValidationView = (
      <Alert type="error" key={3} className="awsui-util-mt-s awsui-util-mb-s">
        <div>{accountInfoError}</div>
      </Alert>
    );
  }

  return (
    <>
      <FormSection header={<FormattedMessage id="LAB_INFO" />}>
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            {labFetchError}
            <FormField
              label={<FormattedMessage id="LAB" />}
              description={<FormattedMessage id="SELECT_A_LAB" />}
            >
              <Select
                options={labOptions}
                onChange={handleLabChange}
                selectedOption={selectedOption}
                loading={labFetchStatus === API_STATUS.PENDING}
                selectedLabel="Selected"
                invalid={!!labInfoError}
              ></Select>
              {labInfoError ? (
                <div className="formErrMsg">{labInfoError}</div>
              ) : null}
            </FormField>
          </div>
        </ColumnLayout>
      </FormSection>
      {accountValidationView}
      {labInfo && labInfo.id && <FeatureFileMappingWrapper />}
    </>
  );
};

const FeatureFileMappingWrapper = () => {
  const { status: customerIdLoadStatus, error: customerIdFetchError } =
    useSelector(customerIdSelector);
  const {
    status: piLoadStatus,
    pis,
    error: piFetchErr,
  } = useSelector(piSelector);
  const { devices } = useSelector(deviceAccSelector);
  const { PENDING, ERROR, SUCCESS } = API_STATUS;

  const { testSuite } = useSelector(testInitSelector);

  let duts = [];
  let actors = [];
  if (testSuite) {
    duts = testSuite.mapping.map((m) => m.duts).flat();
    duts = [...new Set(duts)];

    actors = testSuite.mapping.map((m) => m.actors).flat();
    actors = [...new Set(actors)];
  }

  if (customerIdLoadStatus === PENDING || piLoadStatus === PENDING) {
    return (
      <div className="awsui-util-mt-l">
        <Spinner /> <FormattedMessage id="LOADING_RESOURCES" />
      </div>
    );
  }

  const errView = [];
  if (customerIdLoadStatus === ERROR) {
    errView.push(
      <Alert key={1} type="error" className="awsui-util-mt-s awsui-util-mb-s">
        <div>{customerIdFetchError}</div>
      </Alert>
    );
  }

  if (piLoadStatus === ERROR) {
    errView.push(
      <Alert key={2} type="error" className="awsui-util-mt-s awsui-util-mb-s">
        <div>{piFetchErr}</div>
      </Alert>
    );
  }

  if (errView.length) {
    return <>{errView}</>;
  }

  if (piLoadStatus === SUCCESS && (!Array.isArray(pis) || pis.length <= 0)) {
    if (Array.isArray(pis)) {
      if (pis.length < devices.length) {
        return (
          <Alert type="error" className="awsui-util-mt-s awsui-util-mb-s">
            <FormattedMessage id="LAB_DOES_NOT_HAVE_DEVICES" />
          </Alert>
        );
      }
    }
  }

  return (
    <>
      <FormSection header={<>Thing Configuration</>}>
        {actors.map((dut, ind) => (
          <PiConfigView key={ind} bddName={dut} configType="actor" />
        ))}
      </FormSection>
      <FormSection header={<>Device Configurations</>}>
        {duts.map((dut, ind) => (
          <DutConfigView key={ind} bddName={dut} configType="dut" />
        ))}
      </FormSection>
    </>
  );
};

const PiConfigView = ({ bddName, configType }) => {
  const { pis, status: pisStatus } = useSelector(piSelector);
  const { labInfo } = useSelector(labConfigSelector);
  const { mapping } = useSelector(testMappingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePiWithCustomNames(labInfo));
  }, []);

  let selectedPiOption;

  const idx = mapping.findIndex((m) => m.name === bddName);
  if (mapping[idx] && mapping[idx].thingId) {
    selectedPiOption = {
      id: mapping[idx].thingId,
      label: mapping[idx].piName,
    };
  }

  const handlePiChange = (e) => {
    const piInfo = e.detail.selectedOption.meta;
    const currMapping = {
      name: bddName,
      configType,
      piMeta: piInfo,
      thingId: piInfo.id,
      piName: piInfo.name,
    };
    dispatch(addMapping(currMapping));
  };

  return (
    <>
      <ExpandableSection header={bddName.toUpperCase()} expanded={true}>
        <FormField
          label={<FormattedMessage id="THING" />}
          description={<FormattedMessage id="SELECT_PI" />}
        >
          <Select
            options={pis.map((pi) => ({ label: pi.name, id: pi.id, disabled: !pi.connected, meta: pi }))}
            onChange={handlePiChange}
            loading={pisStatus === API_STATUS.PENDING}
            selectedLabel="Selected"
            selectedOption={selectedPiOption}
          />
        </FormField>
      </ExpandableSection>
    </>
  );
};

const DutConfigView = ({ bddName, configType }) => {
  const { customerIds, status: cidStatus } = useSelector(customerIdSelector);
  const {
    status: dutStatus,
    dutList,
    error: dutFetchError,
  } = useSelector(dutListSelector);

  const { mapping } = useSelector(testMappingSelector);

  const dispatch = useDispatch();
  const intl = useIntl();
  const { ERROR, SUCCESS, PENDING } = API_STATUS;

  const idx = mapping.findIndex((m) => m.name === bddName);

  let selectedCidOption;
  let selectedDsnOption;
  if (idx > -1) {
    selectedCidOption = {
      id: mapping[idx].cidMeta.customerId,
      label: mapping[idx].cidMeta.email,
    };
    if (!isEmpty(mapping[idx].dsnMeta)) {
      selectedDsnOption = {
        id: mapping[idx].dsnMeta.dsn,
        label: mapping[idx].dsnMeta.name || UNNAMED_DEVICE,
      };
    }
  }

  const handleRefresh = (e) => {
    dispatch(fetchDevices({ cid: mapping[idx].customerId, refresh: true }));
  };

  const handleCidChange = (e) => {
    const cIdInfo = e.detail.selectedOption.meta;
    const currMapping = {
      customerId: cIdInfo.customerId,
      name: bddName,
      configType,
      dsn: "",
      cidMeta: cIdInfo,
      dsnMeta: null,
      cidEmail: cIdInfo.email,
    };
    dispatch(addMapping(currMapping));
    dispatch(fetchDevices({ cid: cIdInfo.customerId }));
  };

  const handleDsnChange = (e) => {
    const dsnMeta = e.detail.selectedOption.meta;
    let currMapping = {
      customerId: mapping[idx].customerId,
      name: bddName,
      configType,
      dsn: dsnMeta.dsn,
      dsnMeta: dsnMeta,
      cidEmail: mapping[idx].cidMeta.email,
    };
    dispatch(addMapping(currMapping));
  };

  let dsns = [];
  if (dutStatus === API_STATUS.SUCCESS) {
    const idx = mapping.findIndex((m) => m.name === bddName);
    let deviceList = idx >= 0 && dutList[mapping[idx].customerId];
    if (deviceList) {
      dsns = deviceList.map((deviceObj) => ({
        label: deviceObj.name || UNNAMED_DEVICE,
        id: deviceObj.dsn,
        description: deviceObj.dsn,
        meta: deviceObj,
      }));
    }
  }

  let cIdOptions = [];
  if (cidStatus === API_STATUS.SUCCESS) {
    cIdOptions = customerIds.map((cId) => ({
      id: cId.customerId,
      label: cId.email,
      "tags": [cId.customerId],
      meta: cId,
    }));
  }

  let deviceErrView;
  if (dutStatus === ERROR) {
    deviceErrView = (
      <Alert type="error" className="awsui-util-mt-s">
        {dutFetchError}
      </Alert>
    );
  }

  return (
    <>
      <div className="awsui-util-mt-xl">
        <ExpandableSection header={bddName.toUpperCase()} expanded={true}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label={<FormattedMessage id="CUSTOMER_ID" />}
                description={<FormattedMessage id="SELECT_CUSTOMER_ID" />}
                secondaryControl={
                  <Button
                    variant="icon"
                    icon="refresh"
                    onClick={handleRefresh}
                  ></Button>
                }
              >
                <Select
                  options={cIdOptions}
                  onChange={handleCidChange}
                  loading={cidStatus === PENDING}
                  selectedLabel="Selected"
                  selectedOption={selectedCidOption}
                />
              </FormField>
              <FormField
                label={<FormattedMessage id="SELECT_DEVICE" />}
                description={<FormattedMessage id="SELECT_DSN" />}
              >
                <Select
                  options={dsns}
                  onChange={handleDsnChange}
                  loading={dutStatus === PENDING}
                  selectedLabel="Selected"
                  selectedOption={selectedDsnOption}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>
      </div>
    </>
  );
};
