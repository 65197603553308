import { Button, FormField, Select } from "@amzn/awsui-components-react";
import React, { useState } from "react";

function LabEnvSelector({ labId, onChange }) {
  const LAB_ENV_OPTS = [
    { label: "General", id: "1" },
    { label: "Automotive", id: "2" },
    { label: "Comms", id: "3" },
  ];

  const [selEnv, setSelEnv] = useState("1");

  const divSelectorStyle = {
    lineHeight: 10,
    padding: "10px",
    width: "25%",
  };

  return (
    <div style={divSelectorStyle}>
      <FormField
        label="Lab device mapping"
        secondaryControl={<Button icon="refresh"></Button>}
      >
        <Select
          options={LAB_ENV_OPTS}
          selectedId={selEnv}
          onChange={(e) => {
            setSelEnv(e.detail.selectedId);
            onChange(e.detail.selectedOption.label);
          }}
        ></Select>
      </FormField>
    </div>
  );
}

export default LabEnvSelector;
