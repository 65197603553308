import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '_Constants/AppConstants';
import TestType from './TestType';

/**
 * Use this component to show all the default and test type specific custom configuration
 * in New run page
 */
class TestTypeScenarioOptions extends Component {
  static propTypes = {
    showDelayConfig: PropTypes.bool,
    showDisableSkill: PropTypes.bool,
    showAdminValidation: PropTypes.bool,
    showRetry: PropTypes.bool,
    showProxySettings: PropTypes.bool,
    allowedTypes: PropTypes.arrayOf(PropTypes.number),
    selectedScenario: PropTypes.object.isRequired,
    children: PropTypes.node
  };

  static defaultProps = {
    showDelayConfig: true,
    showAdminValidation: false,
    showRetry: false,
    showProxySettings: false,
  };

  /**
   * Event handler to set delay between utterances value when user moves the slider to
   * set the value
   */
  onChangeDelayBetweenUtterances = (event) => {
    if (!event) return;

    this.props.selectedScenarioCallBack({
      ...this.props.selectedScenario,
      delayBetweenUtterances: parseInt(event.target.value, 10)
    });
  }

  /**
   * Handle delay config selection
   */
  handleSelection = (field) => {
    return (event) => {
      if (!event) return;

      this.props.selectedScenarioCallBack({
        ...this.props.selectedScenario,
        [field]: event.detail.checked
      });
    }
  }

  /**
   * Gets the slider component to set Custom delay between consecutive utterances in seconds.
   * Only CUSTOMIZED test has this feature.
   */
  getUtteranceDelayComponent = () => {
    const { selectedScenario, showDelayConfig } = this.props;
    if (!showDelayConfig) return;

    const { DELAY_BETWEEN_UTTERANCES_MIN: min, DELAY_BETWEEN_UTTERANCES_MAX: max } = AppConstants;

    const tooltipText = `Check this box to set delay between utterances using slider.
      Value is in seconds and can be in the range [${min}, ${max}].
    `;

    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={1} borders='none'>
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox label='Set delay between utterances'
                  checked={selectedScenario.isDelayBetweenUtterancesChecked}
                  onChange={this.handleSelection('isDelayBetweenUtterancesChecked')} />
                <AWSUI.Tooltip text={tooltipText} size='small' position='top'
                  className='awsui-util-no-margin tooltip-inner'>
                  <AWSUI.Icon name='status-info'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
            {
              selectedScenario.isDelayBetweenUtterancesChecked && (
                <div>
                  <div align='center'>
                    <span>
                      <b id='delayBetweenUtterancesValue'>
                        {selectedScenario.delayBetweenUtterances}
                      </b>
                    </span>
                    <span id='secondsText' className='awsui-util-ml-xs'>second(s)</span>
                  </div>
                  <input
                    type="range"
                    id='delayBetweenUtterancesSlider'
                    className='slider-width-style'
                    style={{ width: '100%' }}
                    min={min}
                    max={max}
                    defaultValue={AppConstants.DELAY_BETWEEN_UTTERANCES_DEFAULT}
                    value={selectedScenario.delayBetweenUtterances}
                    onChange={this.onChangeDelayBetweenUtterances}
                  />
                </div>
              )
            }
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  getDisableSkillCheckbox = () => {
    const { selectedScenario } = this.props;
    let tooltipText = 'Check this box to disable the AQT device playback skill. Device playback will use Amazon Music instead.'
    return (
      <AWSUI.FormField>
        <div style={{maxWidth: 600}}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={2} variant="default">
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox
                  className='blue-color-text'
                  label={<b>Disable AQT skill for DUT playback</b>}
                  checked={selectedScenario.disablePlaybackSkill}
                  onChange={this.handleSelection('disablePlaybackSkill')}/>
                <AWSUI.Tooltip text={tooltipText}
                               className='awsui-util-no-margin tooltip-inner'>
                  <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
      </AWSUI.FormField>
    )
  }

  /**
   * Display retry utts check box
   */
  getRetryCheckbox = () => {
    const { selectedScenario, showAdminValidation } = this.props;
    if (!showAdminValidation) return;
    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={2} variant="default">
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox
                  className='blue-color-text'
                  label={ <b>Retry utterances</b> }
                  checked={selectedScenario.retryEnabled}
                  onChange={this.handleSelection('retryEnabled')}
                />
                <AWSUI.Tooltip
                  text='Retry utterances on FRR/RAR failures. For debug only - certification void if enabled.'
                  className='awsui-util-no-margin tooltip-inner'
                >
                  <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
      </AWSUI.FormField>
    );
  }

  /**
   * Config to allow prerequisite utterance failures
   */
  getDisableAdminValidationCheckbox = () => {
    const { selectedScenario, showAdminValidation } = this.props;
    if (!showAdminValidation) return;
    return (
      <AWSUI.FormField>
        <div style={{ maxWidth: 600 }}>
          <div className='awsui-util-container'>
            <AWSUI.ColumnLayout columns={2} variant="default">
              <div data-awsui-column-layout-root='true'>
                <AWSUI.Checkbox
                  className='blue-color-text'
                  label={ <b>Allow prerequisite utterance failures</b> }
                  checked={selectedScenario.disableAdminCheck}
                  onChange={this.handleSelection('disableAdminCheck')}
                />
                <AWSUI.Tooltip
                  text='Check this box to continue test after failed prerequisite utterances.'
                  className='awsui-util-no-margin tooltip-inner'
                >
                  <AWSUI.Icon name='status-info' variant='link'></AWSUI.Icon>
                </AWSUI.Tooltip>
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
      </AWSUI.FormField>
    );
  }

  render() {
    const {showDelayConfig, showAdminValidation, showRetry, showDisableSkill} = this.props;
    return (
      <div className='awsui-util-mt-m'>
        <TestType
          allowedTypes={this.props.allowedTypes}
          selectedScenario={this.props.selectedScenario}
          selectedScenarioError={this.props.selectedScenarioError}
          selectedScenarioCallBack={this.props.selectedScenarioCallBack}
          scenarioErrorCallBack={this.props.scenarioErrorCallBack}
        />
        {/* Render Custom Options based on test suite */}
        {this.props.children}
        <AWSUI.ColumnLayout columns={2} borders='vertical'>
          <div data-awsui-column-layout-root="true">
            {/* Render other configuration options */}
            { showDelayConfig && this.getUtteranceDelayComponent()}
            { showAdminValidation && this.getDisableAdminValidationCheckbox()}
            { showRetry && this.getRetryCheckbox()}
            { showDisableSkill && this.getDisableSkillCheckbox()}
          </div>
        </AWSUI.ColumnLayout>
      </div>
    );
  }
}

export default TestTypeScenarioOptions;
