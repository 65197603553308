import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';

const EMPTY_USERNAME = 'Username cannot be empty';

const styles = theme => ({
  paperControl: theme.mixins.gutters({
    width: 300,
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  }),
  container: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 10,
  },
  formControl: {
    margin: theme.spacing.unit
  },
  textField: {
    width: 300,
    marginBottom: theme.spacing.unit,
  },
  logo: {
    width: 140,
    height: 60,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  }
});

class ForgotPassword extends React.Component {

  state = {
    username: AppConstants.EMPTY,
    error: AppConstants.EMPTY,
    showPassword: false
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, error: AppConstants.EMPTY });
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleBackToLogin = () => {
    this.props.backtologin({});
  }

  handleSendCode = () => {
    Auth.forgotPassword(this.state.username)
    .then(response => {
      logToConsole('Sent verification code successfully');
      this.props.sendcode(this.state.username);
    })
    .catch( err => {
      var message;
      if (typeof err === 'string') {
        message = err;
      } else {
        message = err.message;
      }
      this.setState({ error: message });
      logToConsole('Error sending verification code: ' + message);
    });
  }

  handleResetPasswordHasCode = () => {
    if (!this.state.username) {
      this.setState({ error: EMPTY_USERNAME });
    } else {
      this.props.resetpassword(this.state.username);
    }
  }

  render() {
    const { classes } = this.props;

    return(
      <div>
        <Grid className={classes.container} align="center">
          <Grid item xs={12}>
            <Paper className={classes.paperControl} elevation={4}>
              <Grid item xs={12}>
                <img className={classes.logo}
                  src="Resources/Amazon_Alexa_Stacked_RGB_Dark-Color.png"
                  alt="Amazon Alexa"
                />
              </Grid> 
              <Grid item xs={12}>
                <Typography variant='title'>
                   Forgot Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.textField}>
                  <InputLabel
                    className="login-input"
                    htmlFor="username"
                  >
                    Email
                  </InputLabel>
                  <Input
                    className="login-input"
                    id="username"
                    key="username"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange('username')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <AWSUI.Button
                    variant='primary'
                    className='awsui-util-pt-s'
                    text='Send Code'
                    onClick={this.handleSendCode}
                    >
                  </AWSUI.Button>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <div>
                    <a onClick={this.handleResetPasswordHasCode}>
                      Have Code?
                    </a>
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div>
                    <a onClick={this.handleBackToLogin}>
                      Back to Sign In
                    </a>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <AWSUI.FormField errorText = {this.state.error}/>
                </FormControl>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  backtologin: PropTypes.func.isRequired,
  sendcode: PropTypes.func.isRequired,
  resetpassword: PropTypes.func.isRequired
};

export default withStyles(styles, {params:{}})(ForgotPassword);