import { isEqual } from 'lodash'
/**
 * For all added jobs in testParamsArray, selectedItems contains one job that is selected by customer to view
 * This function is to return the full testParams that is selected by customer
 * @param {*} testParamsArray
 * @param {*} selectedItems : array with one item
 */
export function getSelectedItemToView(testParamsArray, selectedItems) {
  let resultParams = {};
  let selectedItem = selectedItems[0];
  for (const testParams of testParamsArray) {
    if (isTestParamsMatchSelected(testParams, selectedItem)) {
      resultParams = testParams;
      break;
    }
  }
  return resultParams;
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
};

/**
 * Remove corresponding testParams from testParamsArray, based on selected items
 * @param {*} testParamsArray
 * @param {*} selectedItems
 */
export function removeSelectedItemsFromTestQueue(testParamsArray, selectedItems) {
  let selectedItemMap = new Map();
  selectedItems.forEach(selectedItem => {
    selectedItemMap.set(selectedItem.uuid, selectedItem);
  })
  let resultParamsArray = [];
  for (const testParams of testParamsArray) {
    if (selectedItemMap.has(testParams.uuid)) {
      if (isTestParamsMatchSelected(testParams, selectedItemMap.get(testParams.uuid))) {
        continue;
      } else {
        resultParamsArray.push(testParams);
      }
    } else {
      resultParamsArray.push(testParams);
    }
  }
  return resultParamsArray;
}

/**
 * Check whether testParms matches single selectedItem
 * @param {*} testParams
 * @param {*} selectedItem
 * @return true if match
 */
export function isTestParamsMatchSelected(testParams, selectedItem) {
  let resultBoolean = false;
  if (testParams.uuid === selectedItem.uuid
    && testParams.calculatedTestName === selectedItem.calculatedTestName
    && testParams.scenarioType === selectedItem.scenarioType
    && testParams.testType ===  selectedItem.testType
    && isEqual(testParams.testOptions, selectedItem.testOptions)) {
      resultBoolean = true;
    }
  return resultBoolean;
}
