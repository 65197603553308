export const UPL_UTTERANCE_HISTORY_COLUMNS = [{
  id: 'id',
  header: () => '#',
  cell: item => item.id,
  minWidth: '40px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'domain',
  header: () => 'Domain',
  cell: item => item.domain,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'utterance',
  header: () => 'Utterance',
  cell: item => item.utterance,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'actualResponse',
  header: () => 'Device Response',
  cell: item => item.actualResponse,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'transcribeText',
  header: () => 'Transcribe',
  cell: item => item.transcribeText,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'finalUPLValue',
  header: () => 'UPL Result (ms)',
  cell: item => item.finalUPLValue,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}, {
  id: 'deviceStatus',
  header: () => 'Device Status',
  cell: item => item.deviceStatus,
  minWidth: '100px',
  allowLineWrap: true,
  resizableColumns: true
}];