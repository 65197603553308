
import { validate as validateTestInit, setError as setTestInitError } from "../testInitSlice";
import { setError as setLabConfigError, validate as validateLabConfig } from "./labConfigSlice";
import { setError as setScenarioSelError, validate as validateScenSelection } from "./scenarioSelectionSlice";
import { updateStep } from "./uiSlice";
import { GOTO_STEP } from "../../../constants";

// https://stackoverflow.com/questions/43246890/is-using-getstate-in-a-redux-thunk-good-practice
export const validateAndPass = (nextStep) =>  (dispatch, getState) => {

  switch(nextStep) {
    case GOTO_STEP.TEST_SUITE: {
      dispatch(updateStep(nextStep));
      break;
    }
    case GOTO_STEP.SCEN_SELECTION: {
      const errors = validateTestInit(getState().testInit);
      dispatch(setTestInitError(errors))
      if(!errors) {
        dispatch(updateStep(nextStep));
      }
      break;
    }
    case GOTO_STEP.LAB_CONFIG: {
      const testInitErr = validateTestInit(getState().testInit);
      const scenSelErr = validateScenSelection(getState().acmScenarioSlection);
      dispatch(setTestInitError(testInitErr))
      dispatch(setScenarioSelError(scenSelErr));
      if(!testInitErr && !scenSelErr) {
        dispatch(updateStep(nextStep));
      }
      break;
    }
    case GOTO_STEP.PREVIEW: {
      const testInitErr = validateTestInit(getState().testInit);
      const scenSelErr = validateScenSelection(getState().acmScenarioSlection);
      const labConfigErr = validateLabConfig(getState());
      dispatch(setTestInitError(testInitErr));
      dispatch(setScenarioSelError(scenSelErr));
      dispatch(setLabConfigError(labConfigErr));
      if(!testInitErr && !scenSelErr && !labConfigErr) {
        dispatch(updateStep(nextStep));
      }
      break;
    }
    default: break;
  }
}
