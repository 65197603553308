import { ColumnLayout, FormField, Input, Select, Alert, ExpandableSection } from '@amzn/awsui-components-react';
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UNNAMED_DEVICE } from '../../../../constants';
import { piSelector } from '../../../../stores/newRun/piSlice';
import { nanoid } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { handleDevicePiMapChange } from '../../../../stores/newRun/acm/testCreationMiddleware';

export default ({
  account,
  dutList,
  customerId,
  numberOfDevices,
  devicePiConfig,
  devAccMap,
  labValidationErrForAccount
}) => {

const deviceConfigValidationErr = get(labValidationErrForAccount, "deviceConfig");
const deviceConfigListErr = get(labValidationErrForAccount, "deviceConfigList");
const dispatch = useDispatch();
const handleDutConfigChange = (devicePiMap) => {
  dispatch(handleDevicePiMapChange(devicePiMap, account))
}
if(!Array.isArray(dutList[customerId]) || dutList[customerId].length < numberOfDevices) {
  return <Alert type="error" className="awsui-util-mt-s">
    <FormattedMessage id="THIS_ACCOUNT_DOES_NOT_HAVE_ENOUGH_DEVICES_MIN_NUM_DEVICES_IS" />
    {`: ${numberOfDevices}`}
  </Alert>
}

let deviceErr;
if(deviceConfigValidationErr) {
  deviceErr = <Alert type="error" className="awsui-util-mt-s"> {deviceConfigValidationErr} </Alert>
}

return (
    <>
      {deviceErr}
      <ExpandableSection header={<FormattedMessage id="DEVICE_CONFIG" />} className="awsui-util-mt-l" expanded={true}>
        {
          devAccMap.map(
            (devInfo, ind) => (
              <DutConfig
                key={nanoid()}
                account={account}
                labConfigError={deviceConfigListErr}
                deviceInfo={devInfo}
                devicePiConfig={devicePiConfig[ind]}
                index={ind}
                deviceList={dutList[customerId]}
                onChange={handleDutConfigChange}
              />
            )
          )
        }
      </ExpandableSection>
    </>
  );

}


const DutConfig = ({index, labConfigError, deviceInfo, deviceList, onChange, devicePiConfig = {}}) => {
  const {dsnInfo, pi, firmware} = devicePiConfig;
  const [selectedDsnInfo, setSelectedDsnInfo] = useState(dsnInfo);
  const [selectedPi, setSelectedPi] = useState(pi);
  const [selectedFirmware, setFirmware] = useState(firmware)
  const {pis} = useSelector(piSelector);
  const handleDeviceChange = (e) => {
    const { deviceId } = deviceInfo;
    setSelectedDsnInfo(e.detail.selectedOption.meta);
    dispatchChange({
      dsnInfo: {...e.detail.selectedOption.meta, deviceOrderId: deviceId },
      pi: selectedPi,
      firmware: selectedFirmware,
    });
  }
  const handlePiChange = (e) => {
    setSelectedPi(e.detail.selectedOption.meta);
    dispatchChange({
      dsnInfo: selectedDsnInfo,
      pi: e.detail.selectedOption.meta,
      firmware: selectedFirmware,
    });
  }

  const handleFirmwareChange = (e) => {
    setFirmware(e.detail.value);
    dispatchChange({
      dsnInfo: selectedDsnInfo,
      pi: selectedPi,
      firmware: e.detail.value,
    });
  }
  const dispatchChange = ({ dsnInfo, pi, firmware }) => {
    onChange({
      dsnInfo,
      pi,
      firmware,
      index,
    })
  }

  let selectDsnOption;
  let selectPiOption;
  if(selectedDsnInfo && selectedDsnInfo.dsn) {
    selectDsnOption = {
      id: selectedDsnInfo.dsn,
      label: selectedDsnInfo.name || UNNAMED_DEVICE
    };
  }
  if(selectedPi && selectedPi.id) {
    selectPiOption = {
      id: selectedPi.id,
      label: selectedPi.name
    };
  }


  const deviceMapErrView =  get(labConfigError, `${index}`, null);
  const { deviceType, deviceLabel } = deviceInfo;

  return (
    <div>
      <ColumnLayout columns={3}>
      <div data-awsui-column-layout-root="true">
        <FormField className='awsui-util-mt-s' label={`${deviceLabel} - ${deviceType}`} description={<FormattedMessage id="SELECT_DSN" />}>
          <Select
            options={deviceList.map(deviceObj=>({label:deviceObj.name || UNNAMED_DEVICE, id: deviceObj.dsn, description: deviceObj.dsn, meta: deviceObj}))}
            onChange={handleDeviceChange}
            selectedOption={selectDsnOption}
            selectedLabel="Selected" />
        </FormField>
        <FormField className='awsui-util-mt-s' label={<FormattedMessage id="PI" />} description={<><FormattedMessage id="SELECT_PI" /></>}>
          <Select
            options={pis.map(pi=>({label:pi.name, id: pi.id, disabled: !pi.connected, meta: pi}))}
            onChange={handlePiChange}
            selectedOption={selectPiOption}
            selectedLabel="Selected" />
        </FormField>
        <FormField className='awsui-util-mt-s' label={<FormattedMessage id="FIRMWARE" />} description={<FormattedMessage id="SELECT_FIRMWARE_FOR_DUT" />}>
          <Input onChange={handleFirmwareChange} value={selectedFirmware} />
        </FormField>
        </div>
      </ColumnLayout>
      {
        deviceMapErrView && <div className="formErrMsg">{deviceMapErrView}</div>
      }
    </div>
  )

}
