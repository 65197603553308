import React from 'react';
import AWSUI from '@amzn/awsui-components-react';

import { sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';
import { cloneDeep } from "lodash"
import AppConstants from '../../_Constants/AppConstants';
import TestOptionsDisplay from './TestOptionsDisplay';
import { logToConsole } from '../../Util';
import { shouldDisableRunButton, getUpdatedMapping, saveExecutionState } from './Util';
import NewRunStateSaveConstants from './NewRunStateSaveConstants';

const ADD_TEST_TO_QUEUE = 'Add Test';
const IDENTIFIER = 'AddTestConfirmationPage';

class AddTestConfirmationPage extends React.Component {

  state = {
    addTestInfoButtonState: shouldDisableRunButton(this.props.params), // disable add to test button based on pre-conditions
  }

  renderTestConfirmation = () => {
    // remove smoke options from original logic since there's no smoke test for now
    const { selectedTestGroupInfo, selectedScenario, labsInAccount,
      selectedScenarioError, scenarioErrorCallBack, otherParams,
      pageDisplayParams, updatePageDisplayParamsCallBack, testParams,
      testParamsArray, updateTestParamsArrayCallBack } = this.props.params;
    // TODO: remove it
    if (!pageDisplayParams.testOptionsDisplay) return;
    return (
      <div className='awsui-grid'>
        <div className='awsui-row'>
          <div className='col-12'>
            <AWSUI.ColumnLayout>
              <div data-awsui-column-layout-root='true'>
                <div>
                  <TestOptionsDisplay
                    // Why are we wrapping everything in params everywhere??
                    params={{
                      onConnectedDeviceChange: this.onConnectedDeviceChange,  // hotspot connected device onclick call back
                      testParams: testParams,
                      selectedScenario: selectedScenario,
                      selectedTestGroupInfo: selectedTestGroupInfo,
                      otherParams: otherParams,
                      labs: labsInAccount,
                      disabled: pageDisplayParams.disableBack,
                      back: () => { this.onBackButtonClicked(pageDisplayParams, updatePageDisplayParamsCallBack, selectedScenarioError, scenarioErrorCallBack) },
                    }}
                  />
                </div>
              </div>
            </AWSUI.ColumnLayout>
          </div>
        </div>
        <br />
        <div className='awsui-row'>
          <div className='col-12'>
            {this.addTestInfoButton(testParams, testParamsArray, updateTestParamsArrayCallBack, pageDisplayParams, updatePageDisplayParamsCallBack)}
          </div>
        </div>
      </div>
    );
  }


  /**
   * This is a callback function used for AVS Security test suite
   * at this point. Event handler to handle connected device change
   * event on radio options
   */
  onConnectedDeviceChange = (location, connectedDevice) => {
    let { testParams, addTestInfoButtonState, selectedScenario, otherParams } = this.props.params;
    logToConsole("Selected hotspot => ", location);
    logToConsole("Selected device => ", connectedDevice);

    let hotspotOptions = null;

    testParams.testOptions['hotspotOptions'] = hotspotOptions

    if (connectedDevice) {
      // this code block is executed only when a connected device is selected
      // hence it's assured location and connectedDevice are valid data
      hotspotOptions = {
        rasPi: location,
        connectedDevice: [connectedDevice],
      }

      selectedScenario.testOptions['hotspotOptions'] = hotspotOptions;
      testParams.testOptions = selectedScenario.testOptions; // mutate & add hotspotTestOptions into testOptions

      /*
      this function `onConnectedDeviceChange` is a callback function
      invoked from test confirmation page(TestOptionsDisplay.js)
      and mapping is already generated while navigating to test
      confirmation page; hence updating the mapping for security test
      suite based on the selected hotspot location.
      */
      testParams['mapping'] = getUpdatedMapping(selectedScenario, otherParams);
    }

    // decide whether to disable the `add to test` button
    addTestInfoButtonState = shouldDisableRunButton(this.props.params);

    // update the state
    this.setState({ addTestInfoButtonState });

  }

  /**
   * Action when Back Button is clicked
   */
  onBackButtonClicked = (pageDisplayParams, updatePageDisplayParamsCallBack, selectedScenarioError, scenarioErrorCallBack) => {
    //back: () => { this.setState({ testOptionsDisplay: false, error: { ...this.state.error, deviceMappingError: AppConstants.EMPTY } }) }
    let updatePageDisplayParams = pageDisplayParams;
    updatePageDisplayParams.testOptionsDisplay = false;
    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + " onBackButtonClicked", updatePageDisplayParams, updatePageDisplayParamsCallBack);
    let updatedSelectedScenarioError = selectedScenarioError;
    updatedSelectedScenarioError.deviceMappingError = AppConstants.EMPTY;
    sendRawInfoToNewRun(updatedSelectedScenarioError, scenarioErrorCallBack);
  }

  /**
   * Action to add current selected test into JobList
   * @param {*} testParams
   * @param {*} testParamsArray
   * @param {*} updateTestParamsArrayCallBack
   * @param {*} pageDisplayParams
   * @param {*} updatePageDisplayParamsCallBack
   */
  addTestInfoButton = (testParams, testParamsArray, updateTestParamsArrayCallBack, pageDisplayParams, updatePageDisplayParamsCallBack) => {
    const { addTestInfoButtonState } = this.state;
    return (
      <div className="awsui-util-pb-l" style={{ textAlign: 'center' }}>
        <AWSUI.Tooltip text='Click this button to add selected test job into queue for group execution.' size='small' position='top'
          className='awsui-util-no-margin tooltip-inner'>
          <AWSUI.Button text={ADD_TEST_TO_QUEUE}
            disabled={addTestInfoButtonState}
            variant='primary'
            onClick={() => {
              this.addTestInfoToJobList(testParams, testParamsArray, updateTestParamsArrayCallBack, pageDisplayParams, updatePageDisplayParamsCallBack)
            }}>
          </AWSUI.Button>
        </AWSUI.Tooltip>
      </div>
    );
  }

  /**
   *
   * @param {*} testParams
   * @param {*} testParamsArray
   * @param {*} updateTestParamsArrayCallBack
   * @param {*} pageDisplayParams
   * @param {*} updatePageDisplayParamsCallBack
   */
  addTestInfoToJobList = (testParams, testParamsArray, updateTestParamsArrayCallBack, pageDisplayParams, updatePageDisplayParamsCallBack) => {
    let updatedTestParamsArray = testParamsArray;
    const deepcopiedtestParams = cloneDeep(testParams);
    updatedTestParamsArray.push(deepcopiedtestParams);
    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + " addTestInfoToJobList", updatedTestParamsArray, updateTestParamsArrayCallBack);
    saveExecutionState(NewRunStateSaveConstants.TEST_PARAMS_ARRAY, updatedTestParamsArray);
    let updatePageDisplayParams = pageDisplayParams;
    updatePageDisplayParams.testOptionsDisplay = false; // navigate to previous page
    sendRawInfoToNewRunWithIdentifier(IDENTIFIER + " addTestInfoToJobList", updatePageDisplayParams, updatePageDisplayParamsCallBack);
  }

  render() {
    return (
      <div>
        {this.renderTestConfirmation()}
      </div>
    );
  }
}

export default AddTestConfirmationPage;
