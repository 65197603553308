/**
 * Component to render progress bar for Voice Enrollment Robustness scenario
 */
import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import AppConstants from '../../../_Constants/AppConstants';
import '../LiveFeedProgressBar.css';

// Progress bar filler component to display progress in green color
const Filler = (props) => {
  let fillerId = props.labJobId ? 'filler_' + props.labJobId : 'filler';
  return ( 
    <div className='filler' id={ fillerId } style={{ width: `${ props.percentage }%`}}/>
  )
}
 
// Progress bar component to display actual progress bar
const ProgressBar = (props) => {
  return (
    <div className='progress-bar'>
      <Filler percentage={ props.percentage }/>
    </div>
  )
}
 
/**
 * Component to display progress bar consisting of following states:
 * 1. Started
 * 2. In progress (with % of progress)
 * 3. Completed
 */
class VoiceEnrollmentRobustnessProgressBar extends React.Component {

  /**
   * Decides whether to display 'In progress' state or not
   * @param testStatus Current status of test
   * @return Returns true only if test is in progress or is completed.
   */
  shouldDisplayInProgressState = (testStatus) => {
    return AppConstants.IN_PROGRESS_STATES.includes(testStatus) || 
       AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
    * Decides whether to display 'Completed' state or not
    * @param testStatus Current status of test
    * @return Returns true only if test is completed.
    */
  shouldDisplayCompletedState = (testStatus) => {
    return AppConstants.COMPLETED_STATES.includes(testStatus);
  }

  /**
   * Gets text to display when test is in progress
   * @param testStatus Status of test
   * @param inProgressPercentage Percentage test case has completed
   * @return Text whether test is loading or In progress with percentage
   */
  getInProgressText = (testStatus, inProgressPercentage) => {
    let isTestInProgress = this.shouldDisplayInProgressState(testStatus);
    let isTestCompleted = this.shouldDisplayCompletedState(testStatus);
    if (isTestCompleted) {
      return 'In Progress (100%)';
    }
    if (isTestInProgress) {
      if (!inProgressPercentage || inProgressPercentage <= 0) {
        return 'In Progress';
      } else {
        return 'In Progress (' + Math.round(inProgressPercentage) + '%)';
      }
    }
    // Return In Progress by default when testStatus does not match anything from controller
    return 'In Progress';
  }

  /**
   * Gets the percentage progress to display in progress bar
   * @param testStatus Current status of test
   * @param inProgressPercentage Percentage calculated base on current utterance/ total utterances (for In progress state)
   * @return Percentage value based on specific state & inProgressPercentage
   */
  getPercentage = (testStatus, inProgressPercentage) => {
    if (!AppConstants.IN_PROGRESS_STATES.includes(testStatus) && 
          !AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_STARTED_WIDTH;
    } else if (AppConstants.IN_PROGRESS_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_IN_PROGRESS_WIDTH + inProgressPercentage * AppConstants.PROGRESS_BAR_IN_PROGRESS_OFFSET;
    } else if (AppConstants.COMPLETED_STATES.includes(testStatus)) {
      return AppConstants.PROGRESS_BAR_COMPLETED_WIDTH;
    }
  }

  // Renders progress bar
  render() {
    let testStatus = this.props.params.testStatus ? this.props.params.testStatus.toLowerCase() : AppConstants.EMPTY;
    let enrollmentFeed = this.props.params.testStats.hasOwnProperty('enrollmentFeed') ?
      this.props.params.testStats.enrollmentFeed : AppConstants.EMPTY;
    let percentage = (enrollmentFeed && enrollmentFeed.hasOwnProperty('completedPercentage')) ?
      enrollmentFeed.completedPercentage : 0;
    let isCompleted = this.shouldDisplayCompletedState(testStatus);

    return (
      <div>
        <div className='breadcrumb-style'>
          <AWSUI.BreadcrumbGroup>
            <AWSUI.BreadcrumbItem text='Started'/>
            <AWSUI.BreadcrumbItem text={ this.getInProgressText(testStatus, percentage) }/>
            { isCompleted && (
              <AWSUI.BreadcrumbItem text='Completed'/>
            )}
          </AWSUI.BreadcrumbGroup>
        </div>
        <ProgressBar percentage={ this.getPercentage(testStatus, percentage) }
            labJobId={ this.props.params.labJobId }/>
      </div>
    )
  }
}
 
VoiceEnrollmentRobustnessProgressBar.propTypes = {
  params: PropTypes.object.isRequired
};
 
export default VoiceEnrollmentRobustnessProgressBar;
