import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';

/*
 *   Data Format for details, please look at https://sim.amazon.com/issues/P22217092
 *    {
        "irs":{
            "version":"1.0.51",
            "resource_sync":{
              "queued":[
                  {
                    "name":"manifest name",
                    "md5":"md5",
                    "total":"int (number of files to be downloaded)",
                    "total_size":"int (in Bytes)"
                  }
              ],
              "failed":[
                  {
                    "name":"manifest name",
                    "md5":"md5",
                    "total":"int (number of files to be downloaded)",
                    "total_size":"int (in Bytes)"
                  }
              ],
              "complete":[
                  {
                    "name":"manifest name",
                    "md5":"md5",
                    "total":"int (number of files to be downloaded)",
                    "total_size":"int (in Bytes)"
                  }
              ],
              "state":"inprogress, complete, failed, canceled",
              "sync_inprogress":{
                  "name":"name of the manifest this queue is working on",
                  "md5":"md5 of the manifest that this queue is working on",
                  "base_url":"",
                  "status":{
                    "completed":"int",
                    "total":"int",
                    "pending":"int",
                    "failed":"int",
                    "download_speed":"double (in KB/s)",
                    "time_remaining":"double (in seconds)",
                    "size_remaining":"int (in Bytes)",
                    "total_size":"int (in Bytes)"
                  }
              }
            }
        }
      }

      {"80ec6d1a-325e-430c-8f2d-46989dc72899-location4":{"irs":{"resource_sync":{"state":"complete","sync_inprogress":{"name":"audioFiles_manifest.json","status":{"total":3712,"pending":0,"size_remaining":0,"failed":0,"download_speed":5360,"time_remaining":0,"completed":3712,"total_size":6782225261},"md5":"170b1767693d8fa8b0a9b4d5cc0da51d","base_url":"https://dtzsgmefzdeac.cloudfront.net/audioFiles"},"complete":[{"name":"BaseAudio_manifest_integration_test_small_2.json","md5":"9b5648bed9de4ae5b988143a376d6150","total_size":484420,"total":3,"state_change_date":"2019-04-23T21:48:56.900681"},{"name":"BaseAudio_manifest_integration_test_small_1.json","md5":"70ec52a59958bec97eb42f3c1ff61a06","total_size":515814,"total":3,"state_change_date":"2019-04-23T21:48:56.901086"},{"name":"audioFiles_manifest.json","md5":"170b1767693d8fa8b0a9b4d5cc0da51d","total_size":6782225261,"total":3712,"state_change_date":"2019-04-23T21:48:56.904383"}],"queued":[],"failed":[{"name":"BaseAudio_manifest_integration_test_small.json","md5":"f12c5075ad22d74b5e6ebff00d2ce805","total_size":1048742,"total":3,"state_change_date":"2019-04-23T21:48:56.905765"}]}}},"80ec6d1a-325e-430c-8f2d-46989dc72899-location3":{"irs":{"resource_sync":{"sync_inprogress":{"name":"audioFiles_manifest.json","base_url":"https://dtzsgmefzdeac.cloudfront.net/audioFiles","md5":"4909e2b594e9812ad4fad31816fece52","status":{"size_remaining":0,"total":3399,"time_remaining":0,"pending":0,"failed":0,"download_speed":1810,"completed":3399,"total_size":6547819328}},"state":"complete"}}},"80ec6d1a-325e-430c-8f2d-46989dc72899-location1":{"irs":{"resource_sync":{"state":"complete","sync_inprogress":{"md5":"4909e2b594e9812ad4fad31816fece52","base_url":"https://dtzsgmefzdeac.cloudfront.net/audioFiles","status":{"total_size":6547819328,"pending":0,"completed":3399,"failed":0,"size_remaining":0,"total":3399,"download_speed":4270,"time_remaining":0},"name":"audioFiles_manifest.json"}}}}}
 *
 */

export function setSocketHandlers(labJobId, jobSocket, updateRaspiStatus) {
    jobSocket.on('connect', () => {
        logToConsole('Socket opened for job : ' + labJobId);
    });
    jobSocket.on('connect_failed', error => {
        logToConsole('Socket connection failed for job : ' + labJobId + ' - ' + error);
    });
    jobSocket.on('error', error => {
        logToConsole('Socket error from server for job : ' + labJobId + ' - ' + error);
    });
    jobSocket.on('shadowStateChange', data => {
        // socket events emission defined by controller
        //TODO: need to consider about other state as well, maybe
        try {
            let piStatusData = parseRaspiShadowStateData(data);
            updateRaspiStatus(piStatusData)
        } catch (ex) {
            logToConsole('Error in parsing keys from server : ' + JSON.stringify(ex));
        }
    });
    jobSocket.on('disconnect', () => {
        logToConsole('Socket closed for job : ' + labJobId);
    });
}

export function parseRaspiShadowStateData(data) {
    logToConsole('Pi Sync data is: ' + JSON.stringify(data));
    let labDownloadStatus = JSON.parse(JSON.stringify(data));
    let piStatusData = {};
    for (var eachPi in labDownloadStatus) {
        logToConsole("Pi is " + eachPi);
        logToConsole("Object is ");
        logToConsole(labDownloadStatus[eachPi].irs.resource_sync);
        piStatusData[eachPi] = {};
        if (labDownloadStatus[eachPi].hasOwnProperty('irs') &&
            labDownloadStatus[eachPi].irs.hasOwnProperty('resource_sync') &&
            labDownloadStatus[eachPi].irs.resource_sync.hasOwnProperty('state')) {
            piStatusData[eachPi].state = labDownloadStatus[eachPi].irs.resource_sync.state;
            piStatusData[eachPi].shadowState = labDownloadStatus[eachPi].irs.resource_sync;

            if (piStatusData[eachPi].state == AppConstants.DOWNLOAD_STATUS.IN_PROGRESS) {
                piStatusData[eachPi].syncData = labDownloadStatus[eachPi].irs.resource_sync.sync_inprogress.status;
            } else {
                let status = labDownloadStatus[eachPi].irs.resource_sync.sync_inprogress.status;
                let completedFiles = {"total_size": 0, "total": 0}; // array get from complete array
                let queuedFiles = {"total_size": 0, "total": 0};
                let failedFiles = {"total_size": 0, "total": 0};

                let overallPending = status.hasOwnProperty('overall_pending') ? status['overall_pending'] : 0;
                let overallTotal = status.hasOwnProperty('overall_total') ? status['overall_total'] : 0;
                let overallFailed = status.hasOwnProperty('overall_failed') ? status['overall_failed'] : 0;
                let overallCompleted = overallTotal - overallPending;
                let overallCompletedSize = status.hasOwnProperty('overall_completed_size') ? status['overall_completed_size'] : 0;
                let overallFailedSize = status.hasOwnProperty('overall_completed_size') ? status['overall_completed_size'] : 0;
                let overallTotalSize = status.hasOwnProperty('overall_total_size') ? status['overall_total_size'] : 0;
                let overallQueuedSize = overallTotalSize - overallCompletedSize;

                completedFiles.total_size = overallCompletedSize >= 0 ? overallCompletedSize : 0;
                queuedFiles.total_size = overallQueuedSize >= 0 ? overallQueuedSize : 0;
                failedFiles.total_size = overallFailedSize >= 0 ? overallFailedSize : 0;

                completedFiles.total = overallCompleted >= 0 ? overallCompleted : 0;
                queuedFiles.total = overallPending >= 0 ? overallPending : 0;
                failedFiles.total = overallFailed >= 0 ? overallFailed : 0;

                let status_rebuilt = {
                    "overall_completed": 0,
                    "overall_failed": 0,
                    "overall_pending": 0,
                    "overall_total": 0,
                    "overall_total_size": 0,
                    "overall_completed_size": 0
                }
                status_rebuilt.overall_failed = failedFiles.total;
                status_rebuilt.overall_completed = completedFiles.total;
                status_rebuilt.overall_pending = queuedFiles.total;
                status_rebuilt.overall_total = failedFiles.total + completedFiles.total + queuedFiles.total;
                status_rebuilt.overall_completed_size = completedFiles.total_size;
                status_rebuilt.overall_total_size = failedFiles.total_size + completedFiles.total_size + queuedFiles.total_size;
                piStatusData[eachPi].syncData = status_rebuilt;
            }
        } else {
            logToConsole("Error, resource sync format has been changed, please notify AQT team");
        }
    }
    logToConsole("Finish displaying");
    logToConsole("piStatusData for shadow update is ");
    logToConsole(piStatusData);
    return piStatusData;
}
