import axios from 'axios';

/**
 * This redux middleware acts as gateway to communicate with backend service.
 * API calls will be made here based on the action type
 */
const gateway = ({ dispatch, getState }) => {
  return next => async (action) => {
    const { type = [], meta } = action;

    if (!meta || type.length < 2) {
      return next(action);
    }
    const [BEGIN, SUCCESS, FAIL] = type;

    // dispatch the begin action
    dispatch({
      type: BEGIN
    });

    const { url, method = 'get', headers = {}, includeToken = true, transform = [] } = meta;

    const { auth: { session = {}, environment = {} } } = getState();
    const host = environment.controllerEndpoint;

    if (!host) {
      dispatch({
        type: FAIL,
        payload: {
          error: 'API host is missing'
        }
      });

      return next(action);
    }

    const authHeader = {};
    if (includeToken) {
      authHeader['Authorization'] = `Bearer ${session.idToken.jwtToken}`;
    }

    try {
      const response = await axios({
        url: `${host}/${url}`,
        method,
        params: meta.params,
        data: meta.data,
        transformResponse: transform,
        headers: {
          ...headers,
          ...authHeader
        }
      });

      dispatch({
        type: SUCCESS,
        payload: response.data,
        meta
      })
    } catch (error) {
      console.log('Error occured while ', meta, error)
      dispatch({
        type: FAIL,
        payload: error.response
      });
    }

    return next(action);
  };
};

export default gateway;
