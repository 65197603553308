const enUS = {
  id: 'en_US',
  label: 'en-US',
  tags: ['English - United States']
};

const enCA = {
  id: 'en_CA',
  label: 'en-CA',
  tags: ['English - Canada']
};

const enAU = {
  id: 'en_AU',
  label: 'en-AU',
  tags: ['English - Australia']
};
const enGB = {
  id: 'en_GB',
  label: 'en-GB',
  tags: ['English - United Kingdom']
};
const enIN = {
  id: 'en_IN',
  label: 'en-IN',
  tags: ['English - India']
};
const hiIN = {
  id: 'hi_IN',
  label: 'hi-IN',
  tags: ['Hindi - India']
};
const deDE = {
  id: 'de_DE',
  label: 'de-DE',
  tags: ['German - Germany']
};
const jaJP = {
  id: 'ja_JP',
  label: 'ja-JP',
  tags: ['Japanese - Japan']
};
const frFR = {
  id: 'fr_FR',
  label: 'fr-FR',
  tags: ['French - France']
};
const frCA = {
  id: 'fr_CA',
  label: 'fr-CA',
  tags: ['French - Canada']
};
const esES = {
  id: 'es_ES',
  label: 'es-ES',
  tags: ['Spanish - Spain']
};
const esMX = {
  id: 'es_MX',
  label: 'es-MX',
  tags: ['Spanish - Mexico']
};
const esUS = {
  id: 'es_US',
  label: 'es-US',
  tags: ['Spanish - United States']
};
const itIT = {
  id: 'it_IT',
  label: 'it-IT',
  tags: ['Italian - Italy']
};
const ptBR = {
  id: 'pt_BR',
  label: 'pt-BR',
  tags: ['Portuguese - Brazil']
};

const arSA = {
  id: 'ar_SA',
  label: 'ar-SA',
  tags: ['Arabic - Saudi Arabia']
}


const AQTLocales = {
  enUS, enCA, enAU, enGB, enIN, hiIN, deDE, jaJP, frFR, frCA, esES, esMX, esUS, itIT, ptBR, arSA,
  US : [enUS, enCA, esMX, frCA, esUS, ptBR],
  EU : [deDE, enGB, enIN, hiIN, frFR, itIT, esES],
  JP : [jaJP, enAU]
};

export default {
  ...AQTLocales
};
