import {
  Alert,
  Button,
  ColumnLayout,
  FormField,
  FormSection,
  Select,
} from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, GOTO_STEP } from "../../../constants";
import { validateAndPass } from "../../../stores/newRun/acm/validationHandlerMiddleware";
import {
  fetchCustomScenarios,
  fetchScenarios,
  scenariosSelector,
} from "../../../stores/newRun/scenariosSlice";
import {
  setTestSuite,
  testInitSelector,
} from "../../../stores/newRun/testInitSlice";

export default ({ onCancel }) => {
  const { testSuite, error } = useSelector(testInitSelector);
  const {
    status,
    scenarioList,
    error: scenarioFetchError,
  } = useSelector(scenariosSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScenarios());
    dispatch(fetchCustomScenarios());
  }, []);

  const stdTests = scenarioList
    .filter((s) => s.category !== "UDT")
    .map((scenInfo) => ({
      label: scenInfo.displayName,
      id: scenInfo.id,
      meta: scenInfo,
    }));

  const udtTests = scenarioList
    .filter((s) => s.category === "UDT")
    .map((scenInfo) => ({
      label: scenInfo.displayName,
      id: scenInfo.id,
      meta: scenInfo,
    }));

  let dropDownOpts = [];
  if (stdTests.length > 0) {
    dropDownOpts.push({
      label: "Certification Tests",
      id: 0,
      options: stdTests,
    });
  }
  if (udtTests.length > 0) {
    dropDownOpts.push({ label: "OAK Tests", id: 1, options: udtTests });
  }

  const handleProceed = () => {
    dispatch(validateAndPass(GOTO_STEP.SCEN_SELECTION));
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleTestSuiteSelection = (e) => {
    const testInfo = e.detail.selectedOption.meta;
    dispatch(setTestSuite(testInfo));
  };

  let selectedOption;
  if (testSuite) {
    selectedOption = { label: testSuite.displayName, id: testSuite.id };
  }

  let fetchErrView;
  if (status === API_STATUS.ERROR) {
    fetchErrView = (
      <Alert type="error" className="awsui-util-mt-s awsui-util-mb-s">
        {scenarioFetchError}
      </Alert>
    );
  }

  return (
    <div>
      <FormSection header={<FormattedMessage id="INIT_TEST_CREATION" />}>
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            {fetchErrView}

            <FormField
              label={<FormattedMessage id="TEST_SUITE" />}
              description={<FormattedMessage id="SELECT_TEST_SUITE" />}
            >
              <Select
                options={dropDownOpts}
                selectedOption={selectedOption}
                onChange={handleTestSuiteSelection}
                selectedLabel="Selected"
                filteringType="auto"
                loading={status === API_STATUS.PENDING}
              ></Select>
              {error && error.testSuite ? (
                <div className="formErrMsg">{error.testSuite}</div>
              ) : null}
            </FormField>
          </div>
        </ColumnLayout>
      </FormSection>

      <div className="awsui-util-action-stripe-large">
        <div className="awsui-util-action-stripe-title" />
        <div className="awsui-util-action-stripe-group">
          <Button onClick={handleCancel}>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button variant="primary" onClick={handleProceed}>
            <FormattedMessage id="PROCEED" />
          </Button>
        </div>
      </div>
    </div>
  );
};
