import {  BreadcrumbGroup } from '@amzn/awsui-components-react';
import React from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uiStepSelector } from '../../../stores/newRun/acm/uiSlice';
import { cancelTestCreation } from '../../../stores/newRun/acm/testCreationMiddleware';
import Acm from './acm/index';
import Wwa from './wwa/index';
import CustomTest from './customTest/index';
import TestInit from './testSuiteSelection';
import { testInitSelector } from '../../../stores/newRun/testInitSlice';


export default () => {
  const { step } = useSelector(uiStepSelector);
  const { testSuite } = useSelector(testInitSelector);

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const items = [
    {
      "text": intl.formatMessage({id: "NEW_RUN_V2"}),
      "href": "/v2/run"
    },
    {
      "text": `${intl.formatMessage({id: "CREATE"})}${testSuite && testSuite.displayName ? ` ${testSuite.displayName} test`: ''}`,
      "href": "/v2/run/create"
    },
  ]

  const onCancel = () => {
    dispatch(cancelTestCreation());
    history.push("/v2/run");
  }

  const renderWizard = () => {
    if (testSuite && testSuite.displayName==='ACM') {
      console.log('Rendering ACM');
      return <Acm />
    } else if (testSuite && testSuite.displayName==='WWA') {
      console.log('Rendering WWA');
      return <Wwa />
    } else {
      console.log('Rendering CustomTest');
      return <CustomTest />
    }
  }

  return (
    <>
      <BreadcrumbGroup label="Breadcrumbs" items={items} />
      {
        step === -1 ? <TestInit onCancel={onCancel} /> : renderWizard()
      }
    </>
  )

}

