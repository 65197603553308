import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';

import AppConstants from '_Constants/AppConstants';
import { setTestTypeState } from '../ScenarioSelectionHelpers';

/**
 * Generic component to load test types with radio button based on the given props
 * test types: STANDARD, DEMO, SMOKE, CUSTOM
 */
class TestType extends Component {
  static propTypes = {
    /**
     * Its an array of testTypes that needs to be selected.
     * eg: [0, 3] will render STANDARD and CUSTOMIZED
     * Please refer AppConstants for all the testTypes that we support, as below
     * https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/blobs/b108f80e1cf6bd9a5c7eed39037d5c394421cb51/--/src/_Constants/AppConstants.js#L1115
     */
    allowedTypes: PropTypes.arrayOf(PropTypes.number),
    selectedScenario: PropTypes.object.isRequired,
    selectedScenarioCallBack: PropTypes.func.isRequired,
    selectedScenarioError: PropTypes.object.isRequired,
    scenarioErrorCallBack: PropTypes.func.isRequired
  };

  /**
   * On selection, update the test type
   */
  handleOnChangeTestType = (testType) => {
    const { selectedScenario, selectedScenarioCallBack, selectedScenarioError, scenarioErrorCallBack } = this.props;
    return (event) => {
      if (!event) return;

      setTestTypeState(
        selectedScenario.scenarioType,
        testType,
        selectedScenario,
        selectedScenarioCallBack,
        selectedScenarioError,
        scenarioErrorCallBack
      );
    }
  }


  render() {
    const { selectedScenario, selectedScenarioError, allowedTypes } = this.props;
    let currentTestTypeArr = [];
    if (isEmpty(selectedScenario)) {
      currentTestTypeArr = [false, false, false, false];
    } else {
      currentTestTypeArr = selectedScenario.testTypeArr;
    }
    // if not sent default to all test types
    let testTypes = [];
    if (!allowedTypes) {
      testTypes = AppConstants.TEST_TYPES;
    } else {
      testTypes = AppConstants.TEST_TYPES.filter(type => allowedTypes.includes(type.index))
    }
    const options = testTypes.map(item => (
      <div
        key={`type-${item.index}`}
      >
        <AWSUI.RadioButton
          label={item.label}
          checked={currentTestTypeArr[item.index]}
          onChange={this.handleOnChangeTestType(item.type)}
        />
      </div>
    ));

    const errorText = selectedScenarioError.testTypeArr
      ? AppConstants.TEST_TYPES_ERROR
      : AppConstants.EMPTY;

    return (
      <AWSUI.FormField
        errorText={errorText}
        label={<h3>Test Type</h3>}
        className='awsui-util-m-l'
      >
        <AWSUI.ColumnLayout columns={4} className='awsui-util-p-l'>
          <div data-awsui-column-layout-root='true'>
            {options}
          </div>
        </AWSUI.ColumnLayout>
      </AWSUI.FormField>
    );
  }
}

export default TestType;
