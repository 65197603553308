import { getWhitelistedScenarios } from '../RasPiTable/controller';
import {
  getDisplayNameForId, getDisplayTagForId, checkScenarioExistsInList, isMobileScenario,
  validateInput, validateInputOptional, logToConsole, getFunctionalScenarios, pluralization,
  isA4PCDevice, isAlphaEnvironment
}
  from '../../Util';

import { sendRawInfoToNewRun, sendRawInfoToNewRunWithIdentifier } from './NewRunDataTransferUtils';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';

/**
 *   TODO: change function name to retrievewhitelist scenarios
 *   should not in this helper, will move, need to decide where it should go
   * Method to retrieve whitelisted scenarios from controller
   */
export function setScenarios() {
  let whitelistedScenariosIn = [];
  return Promise.resolve(getWhitelistedScenarios().then(response => {
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('error')) {
      response.scenarios.forEach(whitelistedScenario => {
        if (AppConstants.WHITELISTED_SCENARIO_TYPES.includes(whitelistedScenario.name)
          && !checkScenarioExistsInList(whitelistedScenariosIn, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name])) {
          whitelistedScenariosIn.push({
            scenarioId: whitelistedScenario.id,
            id: AppConstants.TEST_SUITE_MAP[whitelistedScenario.name],
            label: getDisplayNameForId(AppConstants.WHITELISTED_SCENARIOS, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name]),
            tags: [getDisplayTagForId(AppConstants.WHITELISTED_SCENARIOS, AppConstants.TEST_SUITE_MAP[whitelistedScenario.name])]
          });
        }
      });

      // FAR is now a special scenario in acoustic that runs AQTEngine and has to be dealt specially. Hence preseving its scenarioId
      // so that we can override AVSCertSimulation jar with AVSCertFAR which is mirror.
      response.scenarios.forEach(whitelistedScenario => {
        if (whitelistedScenario.name === AppConstants.FAR_JAR_NAME) {
          logToConsole('Successfully retrieved FAR Scenario Id!');
          this.setState({
            testParams: {
              ...this.state.testParams,
              farScenarioId: whitelistedScenario.id
            }
          });
        }
      });

      if (whitelistedScenariosIn.length === 1) {
        this.setState({
          testParams: {
            ...this.state.testParams, testSuite: whitelistedScenariosIn[0].id,
            scenarioId: whitelistedScenariosIn[0].scenarioId
          }
        });
      }
      return { whitelistedScenarioId: whitelistedScenariosIn };
    } else {
      return { loadErr: response.error };
    }
  }));
}

/**
 * TODO: see whether needs to reset some errors
 * TODO: qual test need to be updated
 * function to reset CustomOptions Field
 * @param {*} selectedScenario
 * @param {*} updateSelectedScenarioCallBack
 * @param {*} selectedScenarioError
 * @param {*} updateSelectedScenarioErrorCallBack
 */
export function resetCustomOptionsState(selectedScenario, updateSelectedScenarioCallBack,
  selectedScenarioError, updateSelectedScenarioErrorCallBack) {
  const identifier = 'ScenarioSelectionHelpers, resetCustomOptionsState';
  let resetScenario = getScenariosWithResetCustomOptions(selectedScenario,false);
  sendRawInfoToNewRunWithIdentifier(identifier, resetScenario, updateSelectedScenarioCallBack);
  let updatedScenarioError = selectedScenarioError;
  updatedScenarioError.testOptions = false;
  updatedScenarioError.functionalTestCases = false;
  sendRawInfoToNewRunWithIdentifier(identifier, updatedScenarioError, updateSelectedScenarioErrorCallBack);
}

/**
  * Resets test options
  * @param {*} currentSelectedScenarios
  * @param {*} scenarioSelectCallBackFun
  */
export function resetTestOptions(currentSelectedScenarios, scenarioSelectCallBackFun) {
  let resetScenarios = getScenarioWithResetTestOptions(currentSelectedScenarios)
  sendRawInfoToNewRun(resetScenarios, scenarioSelectCallBackFun);
}

/**
 *
 * @param {*} currentSelectedScenarios
 */
export function getScenariosWithResetCustomOptions(currentSelectedScenarios, isCategorySelected) {
  let resetScenarios = currentSelectedScenarios;
  resetScenarios.customizedTestType = AppConstants.CUSTOM_STANDARD;
  resetScenarios.testOptions.customOptions = {};
  resetScenarios.farFieldCustomOptionsSelected = false;
  resetScenarios.nearFieldCustomOptionsSelected = false;
  resetScenarios.trainedMobileCustomOptionsSelected = false;
  resetScenarios.mobileVoiceRobustnessCustomOptionsSelected = false;
  resetScenarios.mobileFunctionalCustomOptionsSelected = false;
  resetScenarios.acousticWakewordCustomOptionsSelected = false;
  resetScenarios.closeTalkCustomOptionsSelected = false;
  resetScenarios.qualCustomOptionsSelected = false;
  resetScenarios.automotiveCustomOptionsSelected = false;
  resetScenarios.isDelayBetweenUtterancesChecked = false;
  resetScenarios.delayBetweenUtterances = AppConstants.DELAY_BETWEEN_UTTERANCES_NON_CUSTOM;
  resetScenarios.expandedSections = [];
  resetScenarios.functionalTestCases = [];
  if (isCategorySelected) {
    resetScenarios.testTypeArr = [false, false, false, false];
    resetScenarios.lockTypeArr = [false, false];
    resetScenarios.testType = AppConstants.EMPTY;
    //resetScenarios.lockType = AppConstants.EMPTY;
  }
  return resetScenarios;
}

export function getScenarioWithResetTestOptions(currentSelectedScenarios) {
  let testOptions = {
    Smoke: "false",
    scenarioType: currentSelectedScenarios.scenarioType,
    testSuite: currentSelectedScenarios.testSuite,
    customizedTestType: AppConstants.CUSTOM_STANDARD, // original, looks like this is redundant,
    //see https://code.amazon.com/packages/AlexaBehavioralEnvironmentSimulationStaticWebsite/commits/df387bd06045e778f19a3504940e01713f7d7eee#src/Container/NewRun/index.js
    customOptions: {}
  };
  let resetScenarios = currentSelectedScenarios;
  resetScenarios.testOptions = testOptions;
  return resetScenarios;
}

/**
 * Setup test type related state parameters
 * Applies to scenarios with TestType selection
 * @param {*} scenarioType  may not needed
 * @param {*} testType
 */
export function setTestTypeState(scenarioType, testType, selectedScenarios, scenarioSelectCallBackFun, selectedScenarioError, scenarioErrorCallBack) {
  let updatedScenarios = getScenariosWithResetCustomOptions(selectedScenarios,false);
  let updatedScenariosError = selectedScenarioError;
  let index = 0;
  switch (testType) {
    case AppConstants.STANDARD:
      index = 0;
      break;
    case AppConstants.QUICK:
      index = 1;
      break;
    case AppConstants.DEMO:
      index = 2;
      break;
    case AppConstants.CUSTOMIZED:
      index = 3;
      break;
    default:
      break;
  }
  updatedScenarios = setTestTypesRadioButtonValues(index, updatedScenarios);
  if (testType === AppConstants.CUSTOMIZED) {
    if (scenarioType === AppConstants.SCENARIO_TYPE[0].id) {
      updatedScenarios.farFieldCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.SCENARIO_TYPE[1].id) {
      updatedScenarios.nearFieldCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.MOBILE_SCENARIO_TYPE[0].id) {
      updatedScenarios.trainedMobileCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.MOBILE_SCENARIO_TYPE[1].id) {
      updatedScenarios.mobileVoiceRobustnessCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.MOBILE_SCENARIO_TYPE[5].id) {
      updatedScenarios.mobileFunctionalCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.WAKEWORD) {
      updatedScenarios.acousticWakewordCustomOptionsSelected = true;
    } else if (scenarioType === AppConstants.CLOSE_TALK_SCENARIO_TYPE[0].id) {
      updatedScenarios.closeTalkCustomOptionsSelected = true;
    } else if (updatedScenarios.testSuite === AppConstants.QUAL_SCENARIO_ID) {
      updatedScenarios.qualCustomOptionsSelected = true;
    }
  }

  if (isAutomotiveScenario(scenarioType)) {
    updatedScenarios.automotiveCustomOptionsSelected = true;
  }

  updatedScenarios.trainedUsers = [];
  updatedScenarios.noiseSelection = [];
  updatedScenariosError.testTypeArr = false;
  updatedScenariosError.trainedUsers = false;
  updatedScenariosError.noiseSelection = false;

  updatedScenarios.testType = testType;
  updatedScenarios.disableAdminCheck = false;
  updatedScenarios.retryEnabled = false;
  updatedScenarios.trainedUsers = [];
  sendRawInfoToNewRun(updatedScenarios, scenarioSelectCallBackFun);
  sendRawInfoToNewRun(updatedScenariosError, scenarioErrorCallBack);
}

/**
 * TODO: may need to used in other scenario types
 * Make it public
 * @param {*} index
 */
function setTestTypesRadioButtonValues(index, selectedScenarios) {
  let updatedScenarios = selectedScenarios;
  let testTypesArrIn = [false, false, false, false];
  testTypesArrIn[index] = true;
  updatedScenarios.testTypeArr = testTypesArrIn;
  return updatedScenarios;
}

/**
 * Checks whether this is a Music scenario
 * @param {*} scenarioType
 * @returns true if it's a Music scenario
 */
export function isMusicScenario(scenarioType) {
  if (scenarioType) {
    return MusicConstants.MUSIC_SCENARIOS.includes(scenarioType);
  }
  return false;
}

/**
 * Checks whether this is a Automotive scenario
 * @param {*} scenarioType
 * @returns true if it's a Music scenario
 */
export function isAutomotiveScenario(scenarioType) {
  if (scenarioType) {
    return AppConstants.AUTOMOTIVE_TEST_TYPES.includes(scenarioType);
  }
  return false;
}
